import quizTypes from "../types/quiz";
function getQuestions(payload, config, previousSession) {
  return {
    type: quizTypes.GET_QUESTIONS,
    payload,
    config,
    previousSession,
  };
}

function getCodingQuestions(payload, config, previousSession) {
  return {
    type: quizTypes.GET_CODING_QUESTIONS,
    payload,
    config,
    previousSession,
  };
}

function getQuestionsRequested() {
  return {
    type: quizTypes.GET_QUESTIONS_REQUESTED,
  };
}

function getL0Questions() {
  return {
    type: quizTypes.GET_L0_QUESTIONS,
  };
}

function getL1Questions(payload) {
  return {
    type: quizTypes.GET_L1_QUESTIONS,
    payload,
  };
}

function getL2Questions(payload) {
  return {
    type: quizTypes.GET_L2_QUESTIONS,
    payload,
  };
}

function getL3Questions(payload) {
  return {
    type: quizTypes.GET_L3_QUESTIONS,
    payload,
  };
}

function getL3PresentationQuestions(payload) {
  return {
    type: quizTypes.GET_L3_PRESENTATION,
    payload,
  };
}

function updatePrevCategory(payload) {
  return {
    type: quizTypes.UPDATE_PREV_CATEGORY,
    payload,
  };
}

function updateCurrentCategory(payload) {
  return {
    type: quizTypes.UPDATE_CURRENT_CATEGORY,
    payload,
  };
}

function updateLevel(payload) {
  return {
    type: quizTypes.UPDATE_LEVEL,
    payload,
  };
}

function updateShowSummary(payload) {
  return {
    type: quizTypes.UPDATE_SHOW_SUMMARY,
    payload,
  };
}

function updateSummary(payload) {
  return {
    type: quizTypes.UPDATE_SUMMARY,
    payload,
  };
}

function submitAnswers(payload) {
  return {
    type: quizTypes.SUBMIT_ANSWERS,
    payload,
  };
}

function getTracking(payload) {
  return {
    type: quizTypes.GET_TRACKING,
    payload,
  };
}

function getTrackingRequested(qTrackReq) {
  return {
    type: quizTypes.GET_TRACKING_REQUESTED,
    payload: qTrackReq,
  };
}

function calculateQuizSummery(payload) {
  return {
    type: quizTypes.CALCULATE_QUIZ_SUMMERY,
    payload,
  };
}

function resetQuizSummery() {
  return {
    type: quizTypes.RESET_QUIZ_SUMMERY,
  };
}

function resetQuizQuestions() {
  return {
    type: quizTypes.RESET_QUIZ_QUESTIONS,
  };
}

function createSession(payload) {
  return {
    type: quizTypes.CREATE_SESSION,
    payload,
  };
}

function createSessionRequested(request) {
  return {
    type: quizTypes.CREATE_SESSION_REQUESTED,
    payload: request,
  };
}

function submitCodingChallenge(payload) {
  return {
    type: quizTypes.SUBMIT_CODING_CHALLENGE,
    payload,
  };
}

function codingChallengeStatus(payload) {
  return {
    type: quizTypes.SUBMIT_CODING_CHALLENGE_STATUS,
    payload,
  };
}

function codingChallengeSubmitted(payload) {
  return {
    type: quizTypes.CODING_CHALLENGE_SUBMITTED,
    payload,
  };
}

function getCodingSubmissionDetail(payload) {
  return {
    type: quizTypes.GET_CODING_SUBMISSION_DETAIL,
    payload,
  };
}

function resetCodingSubmissionDetail() {
  return {
    type: quizTypes.RESET_CODING_SUBMISSION_DETAIL,
  };
}

function resetCoding() {
  return {
    type: quizTypes.RESET_CODING,
  };
}

function updateCodingSubmissionDetail(payload) {
  return {
    type: quizTypes.UPDATE_CODING_SUBMISSION_DETAIL,
    payload,
  };
}
function updateCodingSubmissionStatus(payload) {
  return {
    type: quizTypes.STATUS_CODING_SUBMISSION,
    payload,
  };
}

function submitTestResults(payload) {
  return {
    type: quizTypes.SUBMIT_TEST_RESULTS,
    payload,
  };
}

function testResultSubmitStatus(payload) {
  return {
    type: quizTypes.SUBMIT_RESULT_STATUS,
    payload,
  };
}
function submitCodingResults(payload) {
  return {
    type: quizTypes.SUBMIT_CODING_ANSWERS,
    payload,
  };
}

function testResultsSubmitted(payload) {
  return {
    type: quizTypes.TEST_RESULTS_SUBMITTED,
    payload,
  };
}

function setCodingText(payload) {
  return {
    type: quizTypes.SET_CODING_TEXT,
    payload,
  };
}

function setCodingInput(payload) {
  return {
    type: quizTypes.SET_CODING_INPUT,
    payload,
  };
}

function updateAnswers(payload) {
  return {
    type: quizTypes.UPDATE_SUBMITTED_ANSWER,
    payload,
  };
}

function pushAnswer(payload) {
  return {
    type: quizTypes.PUSH_SUBMITTED_ANSWER,
    payload,
  };
}

function popAnswer(payload) {
  return {
    type: quizTypes.POP_SUBMITTED_ANSWER,
    payload,
  };
}

function timerPlaying(payload) {
  return {
    type: quizTypes.TIMER_PLAYING,
    payload,
  };
}

function setTimer(payload) {
  return {
    type: quizTypes.SET_TIMER,
    payload,
  };
}

function resetTestResult(payload) {
  return {
    type: quizTypes.RESET_TEST_RESULT,
    payload,
  };
}

function clearState() {
  return {
    type: quizTypes.CLEAR_QUIZ_STATE,
  };
}

function recreateUserAndSubmitResults(testResult, userProfile, userMetaDataReq, targetRole) {
  return {
    type: quizTypes.RECREATE_USER_SUBMIT_RESULTS,
    testResult,
    userProfile,
    userMetaDataReq,
    targetRole,
  };
}

// function setProfile(payload){
//   return{
//     type:quizTypes.RECREATE_USER_SUBMIT_RESULTSUCCESS,
//     payload
//   }

// }
function quizSelectionTopic(payload) {
  return {
    type: quizTypes.QUIZ_SELECTION_TOPIC,
    payload,
  };
}

function quizSelectionPopupStatus(payload) {
  return {
    type: quizTypes.QUIZ_SELECTION_POPUP_STATUS,
    payload,
  };
}

function quizSelectionPopupDisable(payload) {
  return {
    type: quizTypes.QUIZ_SELECTION_POPUP_DISABLE,
    payload,
  };
}

function setElapsedTime(payload) {
  return {
    type: quizTypes.SET_ELAPSED_TIME,
    payload,
  };
}
function resetElapsedTime(payload) {
  return {
    type: quizTypes.RESET_ELAPSED_TIME,
    payload,
  };
}

function setUserMetaDataReq(payload) {
  return {
    type: quizTypes.SET_USER_METADATA_REQ,
    payload,
  };
}

function isFromEditProfile(payload) {
  return {
    type: quizTypes.SET_EDIT_PROFILE,
    payload,
  };
}

function updateLevelIndicator(payload) {
  return {
    type: quizTypes.UPDATE_LEVEL_INDICATOR,
    payload,
  };
}

function setPrevTime(payload) {
  return {
    type: quizTypes.SET_PREV_TIME,
    payload,
  };
}

function setSelectedQuesNdAns(payload) {
  return {
    type: quizTypes.SET_SELECTED_QUES_ND_ANS,
    payload,
  };
}

function setApiError(payload) {
  return {
    type: quizTypes.SET_API_ERROR,
    payload,
  };
}

function setL0TestResult(payload) {
  return {
    type: quizTypes.SET_L0_TEST_RESULT,
    payload,
  };
}

function updateCodingCompileAndRunTrackingDetails(payload) {
  return {
    type: quizTypes.UPDATE_CODING_COMPILE_AND_RUN_TRACKING_DETAILS,
    payload,
  };
}

function updateQuizTimeoutFlag(payload) {
  return {
    type: quizTypes.UPDATE_QUIZ_TIMEOUT_FLAG,
    payload,
  };
}

function exitProfileSession(payload) {
  return {
    type: quizTypes.EXIT_PROFILE_SESSION,
    payload,
  };
}

function candidateCameraCapture(payload) {
  return {
    type: quizTypes.CANDIDATE_CAMERA_CAPTURE,
    payload,
  };
}

function candidateCameraCaptureSuccess(payload) {
  return {
    type: quizTypes.CANDIDATE_CAMERA_CAPTURE_SUCCESS,
    payload,
  };
}
// eslint-disable-next-line
export default {
  resetCoding,
  pushAnswer,
  popAnswer,
  clearState,
  resetTestResult,
  updateAnswers,
  setCodingText,
  setCodingInput,
  getQuestions,
  getQuestionsRequested,
  getTracking,
  getTrackingRequested,
  getL0Questions,
  getL1Questions,
  getL2Questions,
  getL3Questions,
  getL3PresentationQuestions,
  updatePrevCategory,
  updateCurrentCategory,
  updateLevel,
  updateShowSummary,
  updateSummary,
  submitAnswers,
  calculateQuizSummery,
  resetQuizSummery,
  getCodingQuestions,
  createSessionRequested,
  createSession,
  submitCodingChallenge,
  codingChallengeStatus,
  codingChallengeSubmitted,
  getCodingSubmissionDetail,
  updateCodingSubmissionDetail,
  updateCodingSubmissionStatus,
  submitTestResults,
  testResultsSubmitted,
  testResultSubmitStatus,
  resetCodingSubmissionDetail,
  submitCodingResults,
  timerPlaying,
  setTimer,
  resetQuizQuestions,
  recreateUserAndSubmitResults,
  quizSelectionTopic,
  quizSelectionPopupStatus,
  quizSelectionPopupDisable,
  setElapsedTime,
  resetElapsedTime,
  setUserMetaDataReq,
  isFromEditProfile,
  updateLevelIndicator,
  setPrevTime,
  setSelectedQuesNdAns,
  setApiError,
  setL0TestResult,
  updateCodingCompileAndRunTrackingDetails,
  updateQuizTimeoutFlag,
  exitProfileSession,
  candidateCameraCapture,
  candidateCameraCaptureSuccess,
};
