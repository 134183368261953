import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  Chip,
  Alert,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
// import { theme } from "../../../styles/theme";
import Button from "../../../components/controls/Button";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../../redux/actions/auth";
import moment from "moment";
import SubscriptionModal from "./SubscriptionModal";
import ManagaSubscriptionModal from "./ManagaSubscriptionModal";
import OpenInNew from "@mui/icons-material/OpenInNew";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const useStyles = makeStyles({
  chip: {
    fontSize: "0.7rem !important",
  },

  alert: {
    width: "75%",
    padding: "0px 12px",
    margin: "10px 0px",
  },

  bold: {
    fontWeight: "bold !important",
  },
});

function BillingContent() {
  const classes = useStyles();
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [openManageSubscription, setOpenManageSubscription] = useState(false);
  // const invoiceHistoryListData = authState?.invoiceHistoryListSuccess?.data;
  const [invoiceHistoryListData, setInvoiceHistoryListData] = useState([]);
  const currencyMap = {
    usd: "US$",
    eur: "EUR€",
    inr: "INR₹",
    // Add more currency here
  };

  const handleStartSubscription = () => {
    setOpen(true);
  };

  const handleManageSubscription = () => {
    if (authState?.managementDetailsSuccess?.data?.billing?.plan === "enterprise") {
      dispatch(auth.createBillingPortal());
    } else {
      setOpenManageSubscription(true);
    }
  };

  useEffect(() => {
    const sessionUrl = authState?.createBillingPortalSuccess?.data?.sessionUrl;
    if (sessionUrl) {
      window.open(sessionUrl, "_self");
    }
  }, [authState.createBillingPortalSuccess]);

  const onClickBuyInviteCredits = () => {
    let payload = {
      product: "invitePacks",
    };
    dispatch(auth.createPaymentLink(payload));
    setFlag(true);
  };

  useEffect(() => {
    if (flag === true) {
      const paymentUrl = authState?.createPaymentLinkSuccess?.data?.paymentUrl;
      if (paymentUrl) {
        window.open(paymentUrl, "_self");
        setFlag(false);
      }
    }
  }, [authState?.createPaymentLinkSuccess]);

  useEffect(() => {
    dispatch(auth.BillingPagePlans());
    if (userDetails.roleSubType === "TeamAdmin") {
      dispatch(auth.apiBillingPortalForInvoiceHistory());
      dispatch(auth.invoiceHistoryList());
    }
  }, []);

  useEffect(() => {
    if (authState?.invoiceHistoryListSuccess?.data) {
      setInvoiceHistoryListData(authState?.invoiceHistoryListSuccess?.data);
    }
  }, [authState?.invoiceHistoryListSuccess]);

  const handleInvoiceHistory = () => {
    const invoiceHistoryUrl = authState?.apiBillingPortalForInvoiceHistorySuccess?.data?.sessionUrl;
    window.open(invoiceHistoryUrl, "_self");
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  // function to add .00 decimal on number
  function formatAmount(amount) {
    if (Number.isInteger(amount)) {
      // If the amount is an integer, add a decimal point and two zeroes
      amount += ".00";
    } else {
      // If the amount is already a decimal, round it to two decimal places
      amount = amount.toFixed(2);
    }
    return amount;
  }

  const handleHostedUrl = (hosted_invoice_url) => {
    window.open(hosted_invoice_url, "_blank");
  };

  const handleInvoicePdf = (invoice_pdf) => {
    window.open(invoice_pdf, "_self");
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ mt: 6 }}>
        Billing & Plans
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Your Plan: {authState?.managementDetailsSuccess?.data?.billing?.planName}{" "}
        {authState?.managementDetailsSuccess?.data?.billing?.subscriptionStatus === "ACTIVE" ? (
          <Chip className={classes.chip} variant="outlined" color="primary" label="ACTIVE" size="small" />
        ) : (
          <Chip
            className={classes.chip}
            variant="outlined"
            color="error"
            size="small"
            label={authState?.managementDetailsSuccess?.data?.billing?.subscriptionStatus}
          />
        )}
        {authState?.managementDetailsSuccess?.data?.billing?.reason &&
          authState?.managementDetailsSuccess?.data?.billing?.reason !== "" && (
            <Alert size="small" severity="error" className={classes.alert}>
              {authState?.managementDetailsSuccess?.data?.billing?.reason}
            </Alert>
          )}
        {/* <Link variant="caption" sx={{ color: "grey" }}>
          View plan detail
        </Link> */}
      </Typography>
      {/* <Typography sx={{ color: "grey" }}>Free Forever</Typography> */}
      <Typography>
        <span className={classes.bold} style={{ fontWeight: "bold" }}>
          {authState?.managementDetailsSuccess?.data?.billing?.features?.users}
        </span>{" "}
        {authState?.managementDetailsSuccess?.data?.billing?.features?.users > 1 ? "users" : "user"}
      </Typography>
      <Typography>
        <span className={classes.bold} style={{ fontWeight: "bold" }}>
          {authState?.managementDetailsSuccess?.data?.billing?.features?.invitesPerMonth}
        </span>{" "}
        invites per month
      </Typography>
      {userDetails.roleSubType === "TeamAdmin" &&
        authState?.managementDetailsSuccess?.data?.billing?.billingType === "online" && (
          <Button
            text={
              authState?.managementDetailsSuccess?.data?.billing?.type === "paid"
                ? "Manage Subscription"
                : "Start Subscription"
            }
            color="btn1"
            sx={{ mt: 3 }}
            onClick={
              authState?.managementDetailsSuccess?.data?.billing?.type === "paid"
                ? handleManageSubscription
                : handleStartSubscription
            }
          ></Button>
        )}
      <Divider sx={{ my: 4, width: "75%" }} />

      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Invites
      </Typography>
      <Typography>
        You have{" "}
        <span className={classes.bold}>
          {authState?.managementDetailsSuccess?.data?.billing?.monthlyInviteBalance} invites
        </span>{" "}
        left (Expires{" "}
        {moment(authState?.managementDetailsSuccess?.data?.billing.monthlyInviteExpiresOn).format("MMMM DD, YYYY")})
      </Typography>
      <Typography className={classes.bold} sx={{ mt: 2 }}>
        Add-ons:
      </Typography>
      <Typography>
        You have{" "}
        <span className={classes.bold}>
          {authState?.managementDetailsSuccess?.data?.billing?.purchasedInviteBalance} invite credits
        </span>{" "}
        (Does not expire)
      </Typography>
      {userDetails.roleSubType === "TeamAdmin" &&
      authState?.managementDetailsSuccess?.data?.billing?.billingType === "online" ? (
        <div>
          <Button text="Buy Invite Credits" color="btn1" sx={{ mt: 3 }} onClick={onClickBuyInviteCredits}></Button>
        </div>
      ) : null}
      {userDetails.roleSubType === "TeamAdmin" &&
        authState?.managementDetailsSuccess?.data?.billing?.billingType === "online" && (
          <>
            <Divider sx={{ my: 4, width: "75%" }} />
            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
              Invoices
            </Typography>
            <Typography>
              Your currency is set to{" "}
              <span className={classes.bold}>
                {authState?.managementDetailsSuccess?.data?.billing?.currency.toUpperCase()}
              </span>{" "}
              for transactions. Contact{" "}
              <a href="mailto:support@techscore.ai" style={{ color: "black" }}>
                support@techscore.ai
              </a>{" "}
              if you need to change this.
            </Typography>
            <Typography className={classes.bold} sx={{ mt: 2 }}>
              Latest Invoices:
            </Typography>
            {invoiceHistoryListData?.length === 0 ? (
              <Typography sx={{ mt: 1 }}>No invoices available yet.</Typography>
            ) : (
              <TableContainer sx={{ width: "60%" }}>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "none",
                      border: "none",
                      padding: "12px",
                    },
                    border: "none",
                    mt: 2,
                  }}
                >
                  <TableBody>
                    {invoiceHistoryListData?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          {moment(item.created).format("D MMM YYYY")}{" "}
                          <OpenInNew
                            style={{ cursor: "pointer" }}
                            fontSize="16px"
                            onClick={() => handleHostedUrl(item.hosted_invoice_url)}
                          />{" "}
                          <PictureAsPdfIcon
                            style={{ cursor: "pointer" }}
                            fontSize="16px"
                            onClick={() => handleInvoicePdf(item.invoice_pdf)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {currencyMap[item.currency] || item.currency}
                          {formatAmount(item.amount)}
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            label={capitalizeFirstLetter(item.status)}
                            size="small"
                            style={{
                              backgroundColor: "#d7f7c2",
                              borderRadius: 5,
                              color: "#117518",
                              fontWeight: 600,
                              border: "1px solid #a6eb84",
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{item.plan}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {invoiceHistoryListData?.length !== 0 && (
              <Button text="Invoice History" color="btn1" sx={{ mt: 3 }} onClick={handleInvoiceHistory}></Button>
            )}
          </>
        )}

      <SubscriptionModal
        authState={authState}
        open={open}
        handleClose={() => setOpen(false)}
        currency={authState?.managementDetailsSuccess?.data?.billing?.currency}
      />
      <ManagaSubscriptionModal
        authState={authState}
        open={openManageSubscription}
        handleClose={() => setOpenManageSubscription(false)}
      />
    </Box>
  );
}

export default BillingContent;
