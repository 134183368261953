import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../../home/home.css";
import QuizContainer from "../../../components/quiz/QuizContainter/QuizContainer";
import { useSelector, useDispatch } from "react-redux";
import quiz from "../../../redux/actions/quiz";
import AppConfig from "../../../constants/AppConfig";
import QuizSummarySideBar from "../../../components/QuizSummarySideBar";
import OnAppBlurDialog from "../../../components/OnAppBlurDialog";
import { theme } from "../../../styles/theme";
// import AlertDialog from "../../../components/AlertDialog";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    minHeight: "100vh",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

function QuizQuestions(props) {
  let locationRef = useLocation();
  let isFromQuizSummery = locationRef?.state?.isFromQuizSummery || false;
  let selectedQuesNdAns = useSelector((state) => state?.quiz?.selectedQuesNdAns);
  const classes = useStyles(props);
  const [value, setState] = useState({
    quesObject: {},
  });
  const [jumpQus, setJumpQus] = useState("");
  const [onAppFocus, setAppFocus] = useState(true);
  const [appFocusCount, setAppFocusCOunt] = useState(0);
  const [appUnexpectedBehaviourSubmit, setAppUnexpectedBehaviourSubmit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const routeParams = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const quesRes = useSelector((state) => state.quiz.questions);
  const quizState = useSelector((state) => state.quiz);
  const authState = useSelector((state) => state.auth);
  const handleContextmenu = (e) => e.preventDefault();
  // const [onResize, setResize] = useState(true);
  const proctoringDetails = JSON.parse(localStorage.getItem("proctoring"));

  onkeydown = (e) => {
    if (e.key === "r" && e.ctrlKey) {
      e.preventDefault();
      // console.log('ctrl + r')
    }
  };
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  let candidatelist = [];
  if (authState?.candidatelist) {
    let arr = (authState?.candidatelist || []).filter((item, i) => item.uid === userDetails.userId);
    candidatelist.push(arr[0]);
  }

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextmenu);
    document.onkeydown = function (e) {
      // disable F12 key
      if (e.keyCode === 123) {
        return false;
      }

      // disable I key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
        return false;
      }

      // disable J key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
        return false;
      }

      // disable U key
      if (e.ctrlKey && e.keyCode === 85) {
        return false;
      }
      // disable A key
      if (e.keyCode === 65 || e.keyCode === 97) {
        return false;
      }
    };
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    if (quizState && quizState.questions) {
      window.addEventListener("load", function () {
        // TARGET THIS SECTION ONLY
        var target = document.getElementById("no-copy");

        // PREVENT CONTEXT MENU FROM OPENING
        target.addEventListener(
          "contextmenu",
          function (evt) {
            evt.preventDefault();
          },
          false
        );

        // PREVENT CLIPBOARD COPYING
        target.addEventListener(
          "copy",
          function (evt) {
            // Change the copied text if you want
            evt.clipboardData.setData("text/plain", "Copying is not allowed on this webpage");
            // Prevent the default copy action
            evt.preventDefault();
          },
          false
        );
      });
    }
    // eslint-disable-next-line
  }, [quizState.questions]);
  useEffect(() => {
    if (
      candidatelist &&
      candidatelist?.user?.email &&
      routeParams.path === AppConfig.LEVEL0 &&
      routeParams.qIndex === "1"
    ) {
    }
    return () => {
      if (routeParams.path === AppConfig.LEVEL0) {
        let req = JSON.stringify(selectedQuesNdAns);
        dispatch(quiz.setUserMetaDataReq(req));
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (routeParams.path === AppConfig.LEVEL0 && +routeParams.qIndex - 1 === 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL0));
    }

    if (routeParams.path === AppConfig.LEVEL1 && +routeParams.qIndex - 1 === 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL1));
    }

    if (routeParams.path === AppConfig.LEVEL2 && +routeParams.qIndex - 1 === 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL2));
    }

    if (routeParams.path === AppConfig.LEVEL3 && +routeParams.qIndex - 1 === 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL3));
    }

    if (
      (routeParams.path === AppConfig.LEVEL0 ||
        routeParams.path === AppConfig.LEVEL1 ||
        routeParams.path === AppConfig.LEVEL2 ||
        routeParams.path === AppConfig.LEVEL3) &&
      quesRes.length !== 0
    ) {
      var idx = +routeParams.qIndex - 1;
      let quesObject = quesRes[idx];

      //do we need to load an opening screen for change in category?
      var category = quesObject.Category;
      // console.log(
      //   "Comparing categories: ",
      //   quizState.prevCategory,
      //   category,
      //   idx
      // );
      if (quizState.prevCategory !== "" && quizState.prevCategory !== category) {
        dispatch(quiz.updatePrevCategory(category));
        var transitionScreen = AppConfig.stepToWelcomeScreenMap[quizState.level][category];
        if (transitionScreen && !isFromQuizSummery) {
          history({
            pathname: transitionScreen,
            state: {
              category: category,
              next: `/QuizQuestions/${quizState.level}/${idx + 1}`,
            },
          });
          return;
        }
      }

      setState({
        ...value,
        quesObject: { ...quesObject },
      });
    }

    if (routeParams.path === "L1" && quesRes.length !== 0) {
      let quesObject = quesRes[+routeParams.qIndex - 1];
      setState({
        ...value,
        quesObject: { ...quesObject },
      });
    }
    return () => console.log("Will Unmounted");
    // eslint-disable-next-line
  }, [routeParams.qIndex]);

  useEffect(() => {
    if (quesRes) {
      if (
        routeParams.path === AppConfig.LEVEL0 ||
        routeParams.path === AppConfig.LEVEL1 ||
        routeParams.path === AppConfig.LEVEL2 ||
        routeParams.path === AppConfig.LEVEL3
      ) {
        let quesObject = quesRes[+routeParams.qIndex - 1];
        setState({
          ...value,
          quesObject: { ...quesObject },
        });
      }
    }
    return () => console.log("Will Unmounted");
    // eslint-disable-next-line
  }, [quesRes]);

  function onJumpQus(val) {
    // console.log('jumpqus',val)
    setJumpQus(val);
  }

  // User has switched back to the tab
  const handleFocusApp = () => {
    // console.log("Tab is in focus");
  };

  // User has switched away from the tab (AKA tab is hidden)
  const handleBlurApp = () => {
    setAppFocusCOunt((cnt) => cnt + 1);
    setAppFocus(false);
  };

  useEffect(() => {
    window.addEventListener("focus", handleFocusApp);
    window.addEventListener("blur", handleBlurApp);
    return () => {
      window.removeEventListener("focus", handleFocusApp);
      window.removeEventListener("blur", handleBlurApp);
    };
  }, []);

  // useEffect(() => {
  //    if(appFocusCount>4) {
  //     setAppUnexpectedBehaviourSubmit(true);
  //    }
  // }, [appFocusCount]);

  const handleCLickContinue = () => {
    setAppFocus(true);
  };

  const handleExitFullscreen = () => {
    // Check if the document is currently in fullscreen mode
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleAutoSubmit = () => {
    // setAppFocus(true);
    // console.log('auto submit');
    setAppUnexpectedBehaviourSubmit(true);
    handleExitFullscreen();
  };

  const onBackButtonEvent = (e) => {
    window.history.pushState(null, null, window.location.pathname);
    e.preventDefault();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  function handleOnSubmit(val) {
    setIsSubmitted(val);
  }

  // const handleResizeContinue = () => {
  //   // go full screen
  //   if (document.documentElement.requestFullscreen) {
  //     // Make the entire document go full screen
  //     document.documentElement.requestFullscreen();
  //   } else if (document.documentElement.mozRequestFullScreen) {
  //     // Firefox
  //     document.documentElement.mozRequestFullScreen();
  //   } else if (document.documentElement.webkitRequestFullscreen) {
  //     // Chrome, Safari and Opera
  //     document.documentElement.webkitRequestFullscreen();
  //   } else if (document.documentElement.msRequestFullscreen) {
  //     // IE/Edge
  //     document.documentElement.msRequestFullscreen();
  //   }

  //   setResize(true);
  // };

  // const handleResize = () => {
  //   setResize(false);
  // };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement === null) {
        // setResize(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <div style={{ userSelect: "none" }}>
      {quizState.level !== AppConfig.LEVEL0 && (
        <QuizSummarySideBar
          group={quizState.level === AppConfig.LEVEL1}
          className={classes.sidebar}
          level={routeParams.path}
          candidatelist={candidatelist}
          onJumpQus={onJumpQus}
        ></QuizSummarySideBar>
      )}
      <QuizContainer
        quizProp={value}
        routeParams={routeParams}
        qIndex={+routeParams.qIndex}
        candidatelist={candidatelist}
        onJumpQus={jumpQus}
        onAppFocusOut={onAppFocus}
        onUnexpectedBehaviour={appUnexpectedBehaviourSubmit}
        isSubmitted={handleOnSubmit}
      ></QuizContainer>

      {!onAppFocus && !isSubmitted ? (
        <OnAppBlurDialog
          open={!onAppFocus}
          isTimerOn={true}
          text={
            appFocusCount > proctoringDetails?.tabSwitchingLimit - 1
              ? "You are doing unexpected behaviour. This is last warning. Your quiz will submit automatically."
              : ""
          }
          action=""
          onCLickContinue={handleCLickContinue}
          onAutoSubmit={handleAutoSubmit}
          appFocusCount={appFocusCount}
        ></OnAppBlurDialog>
      ) : (
        <></>
      )}

      {/* {!onResize && !isSubmitted? <AlertDialog
        open={!onResize }
        // isTimerOn={true}
        text="Full screen mode is required please click continue to go back."
        action=""
        onCLickContinue={handleResizeContinue}
        // heading="Warning"
      ></AlertDialog>:<></>} */}
    </div>
  );
}
export default QuizQuestions;
