import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ToastSuccess from "../../assets/svg/Toastsuccess.svg";

export default function SimpleSnackbar(props) {
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={props.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          <div>
            <img src={ToastSuccess} className="Toastsuccess" alt="toast-success" />
            <span className="invitemsg">{props.text}</span>
          </div>
        }
        action={action}
      />
    </div>
  );
}
