import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, useMediaQuery } from "@mui/material";
import TextInput from "../../components/controls/TextField";
// import BayoneLogo from '../../assets/svg/bayonelogo.svg';
import * as Yup from "yup";
import { useFormik } from "formik";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import auth from "../../redux/actions/auth";
// import quiz from "../../redux/actions/quiz"
import { useDispatch, useSelector } from "react-redux";
import { getDomainDetails } from "../../services/globalService";

const useStyles = makeStyles({
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "10px",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    // fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "right",
    // paddingTop: '20px',
    // paddingBottom: '20px'
  },
  submit: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const search = useLocation().search;
  const paramsInviteid = new URLSearchParams(search).get("uid");
  const encodedUrlData = new URLSearchParams(search).get("d");
  const [domainDetails, setDomainDetails] = useState({});
  const logoUrl = process.env.REACT_APP_ASSETS_URL;
  let { inviteId } = useParams();

  // Handle the click of sign in .
  const onSubmit = (values) => {
    let payload = {
      uid: values.number,
      password: values.password,
    };

    dispatch(auth.LoginUser(payload));
  };

  useEffect(() => {
    let value = localStorage.getItem("token1");
    let userDetails = JSON.parse(localStorage.getItem("user_details"));
    let lastLocation = localStorage.getItem("lastLocation");

    if (userDetails?.redirectDomain) {
      setTimeout(() => {
        window.location.href = userDetails.url;
      }, 1000);
      localStorage.removeItem("token1");
      localStorage.removeItem("user_details");
      localStorage.removeItem("nextMaintenanceCheck");
    } else {
      if (value && userDetails.role !== "Candidate") {
        if (lastLocation) {
          navigate(lastLocation);
          localStorage.removeItem(lastLocation);
        } else {
          navigate("/home");
        }
      }
    }
  }, [localStorage.getItem("token1")]);

  const initialValues = {
    email: localStorage.getItem("lastUsedEmail") || "",
    number: localStorage.getItem("lastUsedEmail") ? localStorage.getItem("lastUsedEmail") : "",
    password: "",
  };
  // Validtion for login
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format"),
    number: Yup.string().required("This field cannot be empty"),
    password: Yup.string().required("This field cannot be empty"),
  });
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  // Extract subdomain info
  useEffect(() => {
    let domainName = getDomainDetails(window.location.href);
    dispatch(auth.GetLogo({ subdomain: domainName.subdomainFromUrl }));
    setDomainDetails(authStore.logoDetails);

    if (encodedUrlData) {
      let decodedData = atob(encodedUrlData);
      let urlData = JSON.parse(decodedData);
      if (urlData?.email) {
        formik.setFieldValue("number", urlData.email);
      }
    }
  }, []);

  useEffect(() => {
    setDomainDetails(authStore.logoDetails);
  }, [authStore.logoDetails]);

  return (
    <Grid>
      <div className={`${classes.center} ${classes.logocls}`}>
        {domainDetails && domainDetails?.logo !== "" ? (
          <>
            <img height="33" width="157" src={`${logoUrl}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
            <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
          </>
        ) : (
          <a href="https://techscore.ai" target="_self" rel="noreferrer">
            <img height="50" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
          </a>
        )}
      </div>
      <Grid container display={"flex"} justifyContent={"center"}>
        <Grid item xs={isNotMobile ? 4 : 10} style={{ marginTop: "40px" }}>
          <Typography align="center" className={classes.textsty}>
            Log In
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Typography className={`${classes.roletype}`}>Email</Typography>
            <TextInput
              name={"number"}
              placeholder="Enter User ID or Registered Email "
              className={classes.field}
              id={"number"}
              onChange={(e) => {
                formik.handleChange(e);
                const email = e.target.value;
                // Regular expression for email validation
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                // Check if the entered email matches the email format
                if (email.match(emailRegex)) {
                  localStorage.setItem("lastUsedEmail", email);
                } else {
                  localStorage.removeItem("lastUsedEmail");
                }
              }}
              error={formik.touched.number && formik.errors.number ? Boolean(formik.errors.number) : null}
              onBlur={formik.handleBlur}
              value={
                inviteId && !paramsInviteid
                  ? inviteId
                  : formik.values.number || localStorage.getItem("lastUsedEmail") || ""
              }
              disabled={inviteId && !paramsInviteid ? true : false}
            ></TextInput>
            {formik.touched.number && formik.errors.number ? (
              <div className="error">
                <Typography className={classes.errorty}>{formik.errors.number}</Typography>
              </div>
            ) : null}

            <Typography className={`${classes.roletype}`}>Password</Typography>
            <TextInput
              name="password"
              placeholder="Enter Password"
              className={`${classes.field} parentclassname`}
              id="password"
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password ? Boolean(formik.errors.password) : null}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              type={showPassword ? "text" : "password"}
              passwordText={"PasswordIcon"}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            ></TextInput>
            {formik.touched.password && formik.errors.password ? (
              <div className="error">
                <Typography className={classes.errorty}>{formik.errors.password}</Typography>
              </div>
            ) : null}
            <Typography className={classes.textview}>
              <Link style={{ textDecoration: "none" }} to="/forgotpassword">
                Forgot Password?
              </Link>
            </Typography>
            <Grid className={classes.submit}>
              <Button
                disabled={!(formik.isValid && formik.dirty && formik.values)}
                type="submit"
                data-rt-loginwithemail-signin="signin"
                color={!(formik.isValid && formik.dirty && formik.values) ? "disablebtn" : "btnPrimary"}
                width={"100%"}
                text="Sign In"
              ></Button>
            </Grid>
          </form>
          <Grid
            container
            className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
