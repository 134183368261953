import {
  Box,
  Checkbox,
  Button as MUIButton,
  Dialog,
  DialogContent,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { AddRounded } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
// import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import Button from "../../components/controls/Button";
import { useDispatch, useSelector } from "react-redux";
import role from "../../redux/actions/role";
import SimpleSnackbar from "../../components/controls/ToastMessage";
import CloseIcon from "@mui/icons-material/Close";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  checkbox: {
    padding: "0px 8px 0px 0px !important",
  },
  captionText: {
    fontSize: "12px !important",
    color: "#787878",
  },
  modalSideHeading: { fontWeight: "bold !important" },
  checkboxText: { display: "inline-block" },
});

const ConfigureReport = ({ open, onClose, fieldData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const reportList = roleState?.configSuccess?.data;
  // const newReportTitleRef = useRef(null);
  const [configureReports, setConfigureReports] = useState(reportList || []);
  const [openDialog, setOpenDialog] = useState(false);
  const [snacktext, setsnacktext] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [maskReport, setMaskReport] = useState(fieldData?.reports[0].maskPII);
  const [socialMediaLinks, setSocialMediaLinks] = useState(fieldData?.reports[0].socialMediaLinks);
  const [candidateEmail, setCandidateEmail] = useState(fieldData?.reports[0].email);
  const [candidatePhone, setCandidatePhone] = useState(fieldData?.reports[0].phone);
  // const [enableFullReportChecked, setEnableFullReportChecked] = useState(
  //   fieldData?.reports[0].fullReport.show
  // );
  const [jobRole, setJobRole] = useState(null);
  // const [presentation, setPresentation] = useState(
  //   fieldData?.reports[0].fullReport.presentation
  // );
  const [presentation, setPresentation] = useState(false);
  // const [coding, setCoding] = useState(fieldData?.reports[0].fullReport.coding);
  const [coding, setCoding] = useState(false);
  // const [technical, setTechnical] = useState(
  //   fieldData?.reports[0].fullReport.technical
  // );
  const [technical, setTechnical] = useState(false);
  const [candidateLocation, setCandidateLocation] = useState(fieldData?.reports[0].location);
  const [showCompanyBrand, setShowCompanyBrand] = useState(fieldData?.reports[0].logo);
  const [editedReportTitle, setEditedReportTitle] = useState(configureReports[tabValue]?.title || "");
  const [changesMade, setChangesMade] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [tracking, setTracking] = useState(false);

  // Use useEffect to update editedReportTitle when tabValue or configureReports changes
  useEffect(() => {
    if (configureReports.length > tabValue) {
      if (!isEditingTitle) {
        setEditedReportTitle(configureReports[tabValue].title);
      }
    }
  }, [tabValue, configureReports, isEditingTitle]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    // const report = configureReports[index] || {};
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ px: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    setJobRole(reportList?.roleTitle);
  }, [reportList?.roleTitle]);

  const handleJobRole = (event) => {
    const newJobRole = event?.target?.value;
    setJobRole(newJobRole);
    setChangesMade(true);
  };

  const handleSave = () => {
    const payload = {
      inviteId: fieldData.uid,
      title: configureReports[tabValue].title,
      logo: showCompanyBrand,
      location: candidateLocation,
      phone: candidatePhone,
      email: candidateEmail,
      socialMediaLinks,
      fullReport: {
        show: coding || technical || presentation,
        coding,
        technical,
        presentation,
      },
      maskPII: maskReport,
      roleTitle: jobRole,
      sessionTracking: tracking,
    };
    // Check edit or save
    if (configureReports[tabValue].token) {
      // If token then call update API
      dispatch(
        role.updateConfig({
          token: configureReports[tabValue].token,
          ...payload,
        })
      );
    } else {
      // If !token then create API
      dispatch(role.createConfig(payload));
    }
    setChangesMade(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    if (fieldData && reportList && reportList.length > newValue) {
      const currentReport = reportList[newValue];
      setMaskReport(currentReport.maskPII);
      setSocialMediaLinks(currentReport.socialMediaLinks);
      setCandidateEmail(currentReport.email);
      setCandidatePhone(currentReport.phone);
      // setEnableFullReportChecked(currentReport.fullReport.show);
      setPresentation(currentReport.fullReport.presentation);
      setCoding(currentReport.fullReport.coding);
      setTechnical(currentReport.fullReport.technical);
      setCandidateLocation(currentReport.location);
      setShowCompanyBrand(currentReport.logo);
      setEditedReportTitle(currentReport.title);
      setTracking(currentReport.sessionTracking);
      // setConfigureReportTitle(currentReport.title);
    }
    setChangesMade(false);
  };

  useEffect(() => {
    // Check if fieldData and reports exist
    if (fieldData && reportList && reportList.length > tabValue) {
      const currentReport = reportList[tabValue];

      // Check if currentReport has roleTitle
      if (currentReport && currentReport.roleTitle) {
        setJobRole(currentReport.roleTitle);
      }
    }
  }, [tabValue, fieldData, reportList]);

  const handleChange = (event) => {
    const fieldName = event?.target?.name;
    const fieldValue = event?.target?.checked;
    if (fieldName === "maskReport") {
      setMaskReport(fieldValue);
      if (fieldValue) {
        setSocialMediaLinks(false);
        setCandidateEmail(false);
        setCandidatePhone(false);
      }
    } else if (fieldName === "socialMediaLinks") {
      setSocialMediaLinks(fieldValue);
    } else if (fieldName === "candidateEmail") {
      setCandidateEmail(fieldValue);
    } else if (fieldName === "candidatePhone") {
      setCandidatePhone(fieldValue);
    } else if (fieldName === "candidateLocation") {
      setChangesMade(true);
      setCandidateLocation(fieldValue);
    } else if (fieldName === "logo") {
      setShowCompanyBrand(fieldValue);
      setChangesMade(true);
    } else if (fieldName === "technical") {
      setTechnical(fieldValue);
      setChangesMade(true);
    } else if (fieldName === "coding") {
      setCoding(fieldValue);
      setChangesMade(true);
    } else if (fieldName === "presentation") {
      setPresentation(fieldValue);
      setChangesMade(true);
    } else if (fieldName === "tracking") {
      setTracking(fieldValue);
      setChangesMade(true);
    }
    setChangesMade(true);
  };

  const handleAddNewReport = () => {
    const report = {
      title: "New Report",
      isDefault: false,
    };

    // Add the new report
    setConfigureReports((prevState) => [...prevState, report]);

    // Switch to the new report tab
    setTabValue(configureReports.length);

    // Set the editedReportTitle immediately to "New Report"
    setEditedReportTitle("New Report");

    // Set isEditingTitle to true
    setIsEditingTitle(true);

    // Set a timeout to focus on the title input field of the new report
    // setTimeout(() => {
    //   const newReportTitleInput = newReportTitleRef.current;
    //   if (newReportTitleRef.current) {
    //     newReportTitleRef.current.focus();
    //     newReportTitleInput.select();
    //   }
    // }, 100);
  };

  const handleEditConfigureReportTitle = () => {
    setIsEditingTitle(false);
    const newReports = configureReports.map((report, index) => {
      if (index === tabValue) {
        return {
          ...report,
          title: editedReportTitle,
        };
      }
      return report;
    });
    setConfigureReports(newReports);
    // Check if the title has actually changed
    if (configureReports[tabValue].title !== editedReportTitle) {
      setChangesMade(true);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(configureReports[tabValue].link);
    setOpenDialog(true);
    setsnacktext("Link copied successfully.");
  };

  const handleOpenLink = () => {
    window.open(configureReports[tabValue].link);
  };

  const handleDownloadLink = () => {
    let payload = {
      inviteId: fieldData?.uid,
      type: "report",
      // reportName: "invite_report",
      accessCode: configureReports[tabValue].token,
    };
    if (fieldData?.uid) {
      dispatch(role.downloadCR(payload));
    }
  };

  useEffect(() => {
    if (roleState?.createConfigSuccess) {
      setConfigureReports(roleState?.createConfigSuccess?.data);
    }
  }, [roleState?.createConfigSuccess?.data]);

  useEffect(() => {
    if (roleState?.updateConfigSuccess) {
      const { status, message } = roleState.updateConfigSuccess;
      if (status === 0) {
        setsnacktext("Report updated successfully.");
      } else {
        setsnacktext(`Failed to update report: ${message}`);
      }
      setOpenDialog(true);
    }
  }, [roleState?.updateConfigSuccess]);

  useEffect(() => {
    let payload = {
      uid: fieldData?.uid,
    };
    dispatch(role.config(payload.uid));
    setOpenDialog(false);
  }, [tabValue]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        sx: {
          width: "90%",
          // height: "435px", // with Include section
          height: "370px",
        },
      }}
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon color="action" />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="h5">Report</Typography>
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ display: "flex", alignItems: "flex-end", px: 3 }}>
              {tabValue !== 0 ? (
                <>
                  <TextField
                    // inputRef={newReportTitleRef}
                    id="input-with-sx"
                    variant="standard"
                    value={editedReportTitle}
                    onBlur={handleEditConfigureReportTitle}
                    onChange={(e) => setEditedReportTitle(e.target.value)}
                    sx={{ width: "300px", "& input": { fontWeight: "bold" } }}
                    inputProps={{ maxLength: 30 }}
                    autoFocus
                  />
                </>
              ) : (
                <Typography variant="h5">{configureReports[tabValue].title}</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} display={"flex"}>
            <Grid item xs={3}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider", height: "250px" }}
              >
                {configureReports.map((report, index) => (
                  <Tab
                    key={index}
                    sx={{ alignItems: "flex-start", textTransform: "none" }}
                    label={report.title}
                    {...a11yProps(index)}
                  />
                ))}
                <MUIButton
                  startIcon={<AddRounded />}
                  size="large"
                  onClick={handleAddNewReport}
                  style={{ textTransform: "none" }}
                >
                  Add New Report
                </MUIButton>
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              <TabPanel value={tabValue} index={tabValue} report={configureReports[tabValue]}>
                <Box>
                  <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                      sx={{
                        mt: 1,
                        color: tabValue === 0 ? "#787878" : undefined,
                      }}
                    >
                      <Checkbox
                        className={classes.checkbox}
                        disabled={tabValue === 0}
                        name="logo"
                        checked={showCompanyBrand}
                        onChange={handleChange}
                      />
                      Show company logo on report
                    </Typography>

                    <Grid sx={{ mt: 2 }}>
                      <Typography
                        className={classes.checkboxText}
                        sx={{ color: tabValue === 0 ? "#787878" : undefined }}
                      >
                        <Checkbox
                          name="maskReport"
                          disabled={tabValue === 0}
                          checked={maskReport}
                          className={classes.checkbox}
                          onChange={handleChange}
                        />
                        Mask report to remove bias
                      </Typography>
                      <Typography className={classes.captionText}>
                        Masking removes Personal Identifiable Information (PII).
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Grid sx={{ mt: 2 }}>
                      {tabValue !== 0 ? (
                        <TextField
                          id="jobRole"
                          variant="standard"
                          defaultValue={jobRole}
                          onBlur={handleJobRole}
                          // InputProps={{ disableUnderline: true }}
                          sx={{ width: "350px" }}
                          inputProps={{ maxLength: 50 }}
                        />
                      ) : (
                        <Typography>{fieldData?.jobRole?.name}</Typography>
                      )}
                      <Typography className={classes.captionText}>Edit role title to customize</Typography>
                    </Grid>
                    <Grid sx={{ mt: 2 }}>
                      <Typography
                        sx={{
                          mt: 1,
                          color: tabValue === 0 ? "#787878" : undefined,
                        }}
                      >
                        <Checkbox
                          className={classes.checkbox}
                          disabled={tabValue === 0}
                          name="tracking"
                          checked={tracking}
                          onChange={handleChange}
                        />
                        Include session tracking data
                      </Typography>
                      <Typography className={classes.captionText}>
                        Display candidate image, IP, location and device details.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography className={classes.modalSideHeading} sx={{ mt: 3 }}>
                    Show Properties
                  </Typography>
                  <Grid
                    sx={{
                      mt: 1,
                      gap: 1,
                      display: "flex",
                      color: tabValue === 0 ? "#787878" : undefined,
                    }}
                  >
                    <Typography className={classes.checkboxText}>
                      <Checkbox
                        className={classes.checkbox}
                        disabled={tabValue === 0}
                        name="candidateLocation"
                        checked={candidateLocation}
                        onChange={handleChange}
                      />
                      Candidate Location
                    </Typography>
                    <Typography
                      className={classes.checkboxText}
                      sx={maskReport ? { color: "#787878" } : { color: tabValue === 0 ? "#787878" : undefined }}
                    >
                      <Checkbox
                        name="candidatePhone"
                        checked={candidatePhone}
                        onChange={handleChange}
                        className={classes.checkbox}
                        disabled={maskReport || tabValue === 0}
                      />
                      Candidate Phone
                    </Typography>
                    <Typography
                      className={classes.checkboxText}
                      sx={maskReport ? { color: "#787878" } : { color: tabValue === 0 ? "#787878" : undefined }}
                    >
                      <Checkbox
                        name="candidateEmail"
                        checked={candidateEmail}
                        onChange={handleChange}
                        className={classes.checkbox}
                        disabled={maskReport || tabValue === 0}
                      />
                      Candidate Email
                    </Typography>
                    <Typography
                      className={classes.checkboxText}
                      sx={maskReport ? { color: "#787878" } : { color: tabValue === 0 ? "#787878" : undefined }}
                    >
                      <Checkbox
                        name="socialMediaLinks"
                        checked={socialMediaLinks}
                        onChange={handleChange}
                        className={classes.checkbox}
                        disabled={maskReport || tabValue === 0}
                      />
                      Social Media Links
                    </Typography>
                  </Grid>

                  {/* 
                  // Include Section 
                  <Typography
                    className={classes.modalSideHeading}
                    sx={{ mt: 3 }}
                  >
                    Include Sections
                  </Typography>
                  <Grid sx={{ mt: 1, gap: 3, display: "flex" }}>
                    <Typography className={classes.checkboxText} sx={{color: tabValue === 0 ? "#787878" : undefined,}}>
                      <Checkbox
                        name="technical"
                        className={classes.checkbox}
                        checked={technical}
                        onChange={handleChange}
                        disabled={tabValue === 0}
                      />
                      Technical
                    </Typography>
                    <Typography className={classes.checkboxText} sx={{color: tabValue === 0 ? "#787878" : undefined,}}>
                      <Checkbox
                        name="coding"
                        className={classes.checkbox}
                        checked={coding}
                        onChange={handleChange}
                        disabled={tabValue === 0}
                      />
                      Coding
                    </Typography>
                    <Typography className={classes.checkboxText} sx={{color: tabValue === 0 ? "#787878" : undefined,}}>
                      <Checkbox
                        name="presentation"
                        className={classes.checkbox}
                        checked={presentation}
                        onChange={handleChange}
                        disabled={tabValue === 0}
                      />
                      Behavioral
                    </Typography>
                  </Grid> */}
                </Box>
              </TabPanel>
              <Grid sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}>
                <Grid sx={{ display: "flex", gap: 4, pl: 3 }}>
                  <ContentCopyRoundedIcon
                    color={!configureReports[tabValue].token || changesMade ? "disabled" : "primary"}
                    onClick={!configureReports[tabValue].token || changesMade ? null : handleCopyLink}
                    sx={{ cursor: "pointer" }}
                    fontSize="large"
                  />
                  <OpenInNewRoundedIcon
                    color={!configureReports[tabValue].token || changesMade ? "disabled" : "primary"}
                    onClick={!configureReports[tabValue].token || changesMade ? null : handleOpenLink}
                    sx={{ cursor: "pointer" }}
                    fontSize="large"
                  />
                  <FileDownloadRoundedIcon
                    color={!configureReports[tabValue].token || changesMade ? "disabled" : "primary"}
                    onClick={!configureReports[tabValue].token || changesMade ? null : handleDownloadLink}
                    sx={{ cursor: "pointer" }}
                    fontSize="large"
                  />
                </Grid>
                {configureReports[tabValue].default === true ? (
                  <Typography className={classes.captionText} sx={{ mr: 4 }}>
                    Default report cannot be configured. To edit properties add a new report.
                  </Typography>
                ) : (
                  <Grid sx={{ display: "flex", gap: 3, mr: 4 }}>
                    <Button
                      color={changesMade ? "btn1" : "disablebtn1"}
                      text={configureReports[tabValue].token ? "Update" : "Save"}
                      onClick={handleSave}
                      disabled={!changesMade}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <SimpleSnackbar open={openDialog} setOpen={setOpenDialog} text={snacktext} />
    </Dialog>
  );
};

export default ConfigureReport;
