import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Tooltip, Grid } from "@mui/material";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import { useNavigate, useParams, generatePath } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { useFormik } from "formik";
import auth from "../../redux/actions/auth";
import moment from "moment";
import Footer from "../../components/Footer";
import DeactivateModel from "../../components/controls/DeactiviteModel";
import ReactiveModel from "../../components/controls/ReactiveModel";
import SimpleSnackbar from "../../components/controls/ToastMessage";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { TimeZonePT } from "../../services/globalService";
// import * as Yup from "yup";
import AlertDialog from "../../components/AlertDialog";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";

const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
  },
  field: {
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  textareasty: {
    width: "533px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
  textbreak: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    marginBottom: "15px",
  },
});
export default function RecruiterView() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [opendialog, setOpendialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [reactiveopen, setReactiveopen] = useState(false);
  const [snacktext, setsnacktext] = useState("");
  const [open, setOpen] = useState(false);
  const [deactivebtn, setDeactivebtn] = useState(false);
  const [activebtn, setActivebtn] = useState(false);
  // const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  useEffect(() => {
    if (authState?.resendInvitationSuccess) {
      setOpen(true);
      setsnacktext(authState?.resendInvitationSuccess);
      dispatch(auth.clearResendInvitationSuccess());
    }
  }, [authState?.resendInvitationSuccess]);

  //  on edit button, navigate to the edit recruiter page with the id of the recruiter.
  const recruiterEdit = () => {
    navigate(generatePath("/edituser/:id", { id }));
  };

  const OpenDeleteRecruiter = () => {
    setOpendialog(true);
  };
  const DeleteRecruiter = (values) => {
    // deactivate the recruiter
    let payload = {
      id: id,
      reason: values,
    };
    dispatch(auth.deleteRecruiter(payload));
    setDeactivebtn(true);
  };
  // recruiter button active or deactive
  useEffect(() => {
    if (authState?.recruiterDeactive === 0) {
      setOpendialog(false);
      setOpen(true);
      setsnacktext("User has been deactivated");
      setTimeout(() => {
        setDeactivebtn(false);
      }, 1000);
    } else if (authState?.recruiterDeactive === 1) {
      setDeactivebtn(false);
    }
  }, [authState?.recruiterDeactive]);

  const RecruiterActive = () => {
    dispatch(auth.checkSubscriptionStatusUser());

    if (authState?.checkSubscriptionStatusUserData?.maxLimit === 0) {
      setOpenAlertDialog(true);
    } else {
      setReactiveopen(true);
    }
  };

  const onResendInvite = () => {
    let payload = {
      id: id,
    };
    dispatch(auth.resendInvitation(payload.id));
  };

  const handleReactive = () => {
    // Activate the recruiter
    let payload = {
      id: id,
    };
    dispatch(auth.activateRecruiter(payload));
    setActivebtn(true);
  };

  // Checking if the recruiter is active or not. If the recruiter is active, it will set the active btn to false. If the recruiter is not active, it will set the active btn to false and open the snackbar.
  useEffect(() => {
    if (authState?.recruiteractive === 0) {
      setReactiveopen(false);
      setOpen(true);
      setsnacktext("User is now active");
      setTimeout(() => {
        setActivebtn(false);
      }, 1000);
    } else if (authState?.recruiteractive === 1) {
      setActivebtn(false);
    }
  }, [authState?.recruiteractive]);
  // first 30 characters of the string
  const truncate = (str) => {
    return str?.length > 30 ? str?.substring(0, 30) + "..." : str;
  };
  // string to 10 characters and adds "..." to the end.
  // const truncate1 = (str) => {

  //   return str?.length > 10 ? str?.substring(0, 10) + "..." : str;

  // };
  let CreatedBy = authState?.recruiterdetails?.createdBy;
  useEffect(() => {
    if (id) {
      dispatch(auth.recruiterDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (authState?.recruiterdetailsFailure?.status) {
      navigate("/pageNotFound", { state: { data: authState?.recruiterdetailsFailure } });
    }
  }, [authState?.recruiterdetailsFailure]);

  const status = authState?.recruiterdetails?.status;

  return (
    <div>
      <NavBar />
      <Container maxWidth="xl" className={classes.container}>
        <Grid style={{ marginTop: "130px" }}>
          <Grid style={{ display: "flex" }}>
            {/* <Typography className={classes.viewText}>
              Home / Team /{" "}
              <span className={classes.viewText} style={{ fontWeight: 700 }}>
                {authState?.recruiterdetails?.user?.firstName} {authState?.recruiterdetails?.user?.lastName}
              </span>
            </Typography> */}
            <CustomBreadcrumbs
              links={[
                { name: "Home", path: "/home" },
                { name: "Team", path: "/userlist" },
                {
                  name: ` ${authState?.recruiterdetails?.user?.firstName || ""} ${
                    authState?.recruiterdetails?.user?.lastName || ""
                  }`,
                },
              ]}
            />
          </Grid>
          <Grid container className="Home-hero" alignItems="center" style={{ marginTop: "30px" }}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Grid className={classes.spaceflex}>
                <Typography className={classes.roletext}>User Profile</Typography>
                <Grid>
                  <Grid style={{ display: "flex", gap: "14px" }}>
                    <Grid style={{ marginRight: "5px" }}>
                      {authState?.recruiterdetails?.status === "invited" ? (
                        <Button
                          color="btnCls"
                          text="Resend Invite"
                          onClick={onResendInvite}
                          width={199}
                          variant="outlined"
                        />
                      ) : (
                        <Button
                          disabled={
                            localStorage.getItem("userid") === authState?.recruiterdetails?.uid ||
                            authState?.recruiterdetails?.roleSubType === "TeamAdmin"
                          }
                          data-rt-recruiter-view="deactivateuser"
                          color="btnCls"
                          text={authState?.recruiterdetails?.status === "active" ? "Deactivate User" : "Activate User"}
                          type="submit"
                          // onClick={() => AddRoleView()}
                          onClick={
                            authState?.recruiterdetails?.status === "active" ? OpenDeleteRecruiter : RecruiterActive
                          }
                          width={199}
                          variant="outlined"
                        ></Button>
                      )}
                    </Grid>
                    <Button
                      disabled={
                        localStorage.getItem("userid") === authState?.recruiterdetails?.uid ||
                        authState?.recruiterdetails?.roleSubType === "TeamAdmin"
                      }
                      data-rt-recruiter-view="edituser"
                      color="btnPrimary"
                      //type='submit'
                      // width="100%"
                      text="Edit User"
                      width={199}
                      onClick={recruiterEdit}
                    ></Button>
                  </Grid>
                </Grid>
              </Grid>
              <p style={{ borderBottom: "1px solid #C4C4C4" }} />
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2.5}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>Status</Typography>
                      <Typography
                        className={classes.roletype}
                        style={{ color: status === "active" ? "#2F2F2F" : "#DD2E2E" }}
                      >
                        {status === "active" ? "Active" : status === "invited" ? "Invited" : "Inactive"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>User ID</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {authState?.recruiterdetails?.user?.uid}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>First Name</Typography>
                      <Tooltip title={authState?.recruiterdetails?.user?.firstName} placement="bottom-start">
                        <Typography
                          style={{
                            whiteSpace: "pre-wrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: "#2F2F2F",
                          }}
                          className={classes.roletype}
                        >
                          {authState?.recruiterdetails?.user?.firstName}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>Last Name</Typography>
                      <Tooltip title={authState?.recruiterdetails?.user?.lastName} placement="bottom-start">
                        <Typography
                          style={{
                            whiteSpace: "pre-wrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: "#2F2F2F",
                          }}
                          className={classes.roletype}
                        >
                          {authState?.recruiterdetails?.user?.lastName}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>User Type</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {authState?.recruiterdetails?.user?.roleSubType === "TeamAdmin"
                          ? "Team Admin"
                          : authState?.recruiterdetails?.user?.roleSubType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>Email Address</Typography>
                      <Tooltip title={authState?.recruiterdetails?.user?.email} placement="bottom-start">
                        <Typography
                          style={{
                            whiteSpace: "pre-wrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: "#2F2F2F",
                          }}
                          className={classes.roletype}
                        >
                          {truncate(authState?.recruiterdetails?.user?.email)}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid item xs={2.5}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>Added By</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {typeof CreatedBy === "string" ? CreatedBy : CreatedBy?.displayName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>Added On</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {moment(authState?.recruiterdetails?.user?.createdAt).format("MMM DD, YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={10}>
                    <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                      <Typography className={classes.roletype}>Internal Notes</Typography>
                      {(authState?.recruiterdetails?.notesLog || []).map((note, i) => {
                        return (
                          <div key={note}>
                            {note.msg && (
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                                {note.msg}
                              </Typography>
                            )}
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                              {note.what} by {note.who.displayName} on {TimeZonePT(note.when)}
                            </Typography>
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <p style={{ borderBottom: "1px solid #C4C4C4" }} />
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Grid>
      </Container>
      <DeactivateModel
        open={opendialog}
        setOpen={setOpendialog}
        DeleteListData={(values) => DeleteRecruiter(values)}
        Title={"Deactivate User"}
        Text={
          "An inactive User won’t be eligible to perform any actions. Reactivating the User can be done instantly. To continue, please provide a reason below."
        }
        Deactivebtn={deactivebtn}
      />
      <ReactiveModel
        open={reactiveopen}
        OnReactive={handleReactive}
        onClose={() => setReactiveopen(false)}
        Text={
          <span>
            Setting a user as active will enable all functionalities and actions again. Are you sure you want to
            activate?
          </span>
        }
        Activatebtn={activebtn}
      />
      <AlertDialog
        open={openAlertDialog}
        onCLickContinue={() => {
          setOpenAlertDialog(false);
        }}
        heading="Your Limit Reached"
        text="You have reached your usage limit for inviting a user or your subscription is not active."
        yesBtnWidth="100px"
      />

      <SimpleSnackbar open={open} setOpen={setOpen} text={snacktext} />
      <div style={{ marginTop: "165px" }}>
        <Footer />
      </div>
    </div>
  );
}
