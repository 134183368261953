import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Divider, Grid, TextField, Typography } from "@mui/material";
import { theme } from "../../../styles/theme";
import { makeStyles } from "@mui/styles";
import Button from "../../../components/controls/Button";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../../redux/actions/auth";
import SimpleSnackbar from "../../../components/controls/ToastMessage";

const useStyles = makeStyles({
  blueLink: {
    color: theme.palette.mainPaletteBlue,
    textDecoration: "none",
  },
});

const SupportContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const initialValues = {
    type: "",
    message: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    // console.log(values);
    dispatch(auth.raiseIssue(values));
    setLoading(true);
    resetForm();
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().nullable().required("Required"),
    message: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (authState?.raiseIssueSuccess) {
      setLoading(false);
      setOpen(true);
      setTimeout(() => {
        dispatch(auth.clearRaiseIssueSuccess());
      }, 3000);
    }
  }, [authState?.raiseIssueSuccess]);

  useEffect(() => {
    if (authState?.raiseIssueFailure) {
      setLoading(false);
    }
  }, [authState?.raiseIssueFailure]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ mt: 6 }}>
        Support
      </Typography>
      <Typography variant="body1" gutterBottom>
        For email support, you can write to us at{" "}
        <a className={classes.blueLink} href="mailto:support@techscore.ai">
          support@techscore.ai
        </a>
        .
      </Typography>
      <Divider sx={{ my: 4, width: "75%" }} />
      <Typography>Or, you can raise a ticket using the form below:</Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched, isValid, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <Typography fontWeight={500} sx={{ mt: 2 }}>
              Issue Type
            </Typography>
            <Autocomplete
              sx={{ width: "24%", mt: 1 }}
              size="small"
              options={["Bug", "Suggestion", "Other"]}
              onChange={(event, value) => setFieldValue("type", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  placeholder="Select an Issue"
                  id="type"
                  name="type"
                  error={touched.type && Boolean(errors.type)}
                  helperText={touched.type && errors.type}
                />
              )}
            />
            <Typography fontWeight={500} sx={{ mt: 2 }}>
              Please type your concern here
            </Typography>
            <Field
              as={TextField}
              sx={{ width: "50%", mt: 1 }}
              hiddenLabel
              placeholder="Type your concern here"
              multiline
              rows={4}
              variant="outlined"
              id="message"
              name="message"
              error={touched.message && Boolean(errors.message)}
              helperText={touched.message && errors.message}
            />
            <Grid>
              <Button
                type="submit"
                text="Submit"
                color={!isValid || !dirty || loading ? "disablebtn1" : "btn1"}
                sx={{ mt: 2 }}
                disabled={!isValid || !dirty || loading}
              />
            </Grid>
          </Form>
        )}
      </Formik>
      {authState?.raiseIssueSuccess?.totalCount?.ticketId && (
        <SimpleSnackbar
          open={open}
          setOpen={setOpen}
          text={`Ticket created successfully. Ticket ID ${authState?.raiseIssueSuccess?.totalCount?.ticketId}`}
        />
      )}
    </Box>
  );
};

export default SupportContent;
