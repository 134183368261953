import axios from "axios";
import store from "../redux/store";
import authAction from "../redux/actions/auth";
import { logout } from "../services/globalService";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  timeout: 60000,
});
axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.error && response?.data?.error === true) {
      store.dispatch(authAction.apiError({ message: response?.data?.message }));
    }
    if (response?.status > 200) {
      let tempMsg = response?.data?.message;
      if (tempMsg && typeof tempMsg == "object") {
        tempMsg = tempMsg.message || "Unknown API error";
      }
      store.dispatch(authAction.apiError({ message: tempMsg }));
    } else {
      if (response?.data?.error && response?.data?.message === "invalid signature") {
        store.dispatch(authAction.apiError({ message: response?.data?.message }));
        // console.log('LOGOUT')
        logout();
        return Promise.reject(response?.data?.message);
      } else {
        return response.data;
      }
    }
  },
  (error) => {
    let tempMsg = error?.response?.data?.message || error.message;
    if (error) {
      if (error.message.includes("timeout of")) {
        store.dispatch(
          authAction.apiError({
            message: "Sorry! It took longer than expected. Please try again.",
          })
        );
      } else if (error.message.includes("Network Error")) {
        store.dispatch(
          authAction.apiError({
            message: "Network error. Please check your connection and retry.",
          })
        );
      } else {
        if (error.response.data?.errorCode && error.response.data?.errorCode !== "") {
          tempMsg = `(${error.response.data?.errorCode}) ${error.response.data?.errMsg}. ${tempMsg}`;
        }
        store.dispatch(authAction.apiError({ message: tempMsg, status: error.response.status }));
      }
    }
    return Promise.reject({ statusText: error.response.statusText, status: error.response.status, message: tempMsg });
  }
);

export default axiosInstance;
