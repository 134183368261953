// disable eslint for this file
/* eslint-disable */
import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, TextareaAutosize, MenuItem, Menu } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import quiz from "../redux/actions/quiz";
import SwipeableViews from "react-swipeable-views";
import { getObject } from "../services/globalService";
import InputIcon from "@mui/icons-material/Input";
// import { config } from '../config';
// import auth from "../redux/actions/auth";

const useStyles = makeStyles((theme, props) => ({
  root: {},

  bottomToolbar: {
    "&:nth-child(0)": {
      marginRight: "12px",
    },
  },

  programOutput: {
    whiteSpace: "pre-wrap",
    padding: "10px",
    height: "13vh",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
  },
  inputText: {
    height: "13vh !important",
    width: "95%",
    border: "none",
    outline: "none",
    resize: "none",
    padding: "10px 0px 4px 12px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0em",
    borderRadius: "4px",
  },
  contGrid: {
    marginTop: "10px !important",
    height: 132,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.background.borderLight}`,
  },
  inputTab: {
    borderRight: `1px solid ${theme.palette.background.borderLight}`,
  },
  textArea: {
    // borderRight: `1px solid ${theme.palette.background.borderLight}`,
    border: `1px solid #EBEFF8`,
    borderRadius: "4px",
    height: "116px",
    overflow: "hidden",
  },
  lightColor: {
    backgroundColor: theme.palette.background.paper,
  },
  darkcolor: {
    backgroundColor: "#2F2F2F",
    //color:'white'
  },
  tabLabel: {
    textAlign: "left",
    textTransform: "capitalize",
    alignItems: "flex-end",
    paddingBottom: "0px",
    justifyContent: "flex-start",
  },
  codeEditor: {
    // height: "100% !important",
    width: "100% !important",
    border: "1px solid #EBEFF8",
    borderRadius: "4px",
  },
  editorCt: {
    borderRadius: "4px",
    //border:"1px solid #ddd"
  },
  valueText: {
    color: "#787878 !important",
    marginBottom: "10px",
    whiteSpace: "nowrap",
  },
  outValue: {
    height: "116px !important",
    //margin:"auto",
    //marginRight:'-10px'
  },
  InputText: {
    color: "#C4C4C4",
    "&::placeholder": {
      color: "#C4C4C4",
    },
  },
  darkcolorText: {
    color: "#C4C4C4",
  },
  TextValueMedium: theme.typography.b2Medium,
  menuItemEven: {
    backgroundColor: "#f0f0f0 !important",
    "&:hover": {
      backgroundColor: "#e0e0e0 !important",
    },
  },
  menuItemOdd: {
    backgroundColor: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#e0e0e0 !important", //
    },
  },
  menuItemHeader: {
    backgroundColor: "#f0f0f0 !important",
  },
}));

export default function CodeEditor(props) {
  const { question, config } = props;
  const qustionId = props.question.Question_ID;
  const classes = useStyles(props);

  // console.log("isFullSubmit: ", props.isFullSubmit);

  //Height calculation logic: innerHeight - header - footer - lang selection area - input/out - labels & gaps
  let editorHt = window.innerHeight - 80 - 80 - 48 - 120 - 92;

  const authState = useSelector((state) => state.auth);
  const quizRes = useSelector((state) => state.quiz);

  const [codingUserTemplates, setCodingUserTemplates] = useState({});

  const dispatch = useDispatch();
  const codingChallengeSubmissionId = useSelector((state) => state.quiz.codingChallengeSubmissionId);
  const codingChallengeSubmissionDetail = useSelector((state) => state.quiz.codingChallengeSubmissionDetail);

  const oldAns = getObject("Question_ID", question.Question_ID, quizRes.selectedAnswers);
  let userTpl, oldInput;

  //if we have an answer for the same language, use that code rather than the template returned by the API
  if (oldAns && authState.codingLang.cid == oldAns.compilerId) {
    userTpl = oldAns.source;
    oldInput = oldAns.input;
  }
  // const codeTpl = userTpl || question[AppConfig.compilerToTplFieldMap[authState.codingLang.lang]];
  const [codeTpl, setCodeTpl] = useState(userTpl || question[authState.codingLang.template]);

  // const input = quizRes.codingInput || oldInput || "";
  // const input = quizRes.codingInput || "";
  const [input, setInput] = useState(quizRes.codingInput || "");

  // console.log("setting codingInput: ", input);

  const [state, setState] = useState({
    input: input,
    cid: 10,
    lang: "java",
    langId: 10,
    theme: "vs-dark",
    themeId: "1",
    output: "",
    compilationStatus: "",
    codeTpl: userTpl || "",
  });

  function handleEditorWillMount(monaco) {
    // console.log("handleEditorWillMount"); // FK cleanup console july 2023
  }

  function handleEditorDidMount(editor, monaco) {
    props.monacoRef.current = editor;

    // Prevent from copy paste
    editor?.onKeyDown((event) => {
      const { keyCode, ctrlKey, metaKey } = event;
      if ((keyCode === 33 || keyCode === 52) && (metaKey || ctrlKey)) {
        event.preventDefault();
      }
    });
  }

  // Set and creat object for language history
  useEffect(() => {
    let qusData = { ...codingUserTemplates };
    if (!codingUserTemplates[qustionId]) {
      qusData[qustionId] = {};
      qusData[qustionId][authState.codingLang.lang] = question[authState.codingLang.template];
      setCodingUserTemplates(qusData);
    }
  }, []);

  // Update history obj and set edito code according to history object or new template code
  useEffect(() => {
    if (!codeTpl || codeTpl == "undefined" || codeTpl == "" || codeTpl == undefined) {
      setCodeTpl(question[authState.codingLang.template]);
    }

    let qusData = { ...codingUserTemplates };

    if (!codingUserTemplates[qustionId]) {
      qusData[qustionId] = {};
      qusData[qustionId][authState.codingLang.lang] = question[authState.codingLang.template];
      // console.log(qusData) // FK cleanup console july 2023
      setCodingUserTemplates(qusData);
    }

    if (codingUserTemplates[qustionId]) {
      if (
        codingUserTemplates[qustionId][authState.codingLang.lang] &&
        codingUserTemplates[qustionId][authState.codingLang.lang] != ""
      ) {
        let codeTplValue = codingUserTemplates[qustionId]
          ? codingUserTemplates[qustionId][authState.codingLang.lang]
          : question[authState.codingLang.template];
        // console.log(qustionId,codeTplValue) // FK cleanup console july 2023
        setCodeTpl(codeTplValue);
      } else {
        setCodeTpl(question[authState.codingLang.template]);
      }
    } else {
      setCodeTpl(question[authState.codingLang.template]);
    }
    setInput(quizRes.codingInput || "");
  }, [props.question.Question_ID]);

  // Set edito code according to history object or new template code
  useEffect(() => {
    if (authState.codingLang) {
      if (codingUserTemplates[qustionId]) {
        if (!codingUserTemplates[qustionId][authState.codingLang.lang]) {
          let qusData = { ...codingUserTemplates };
          qusData[qustionId][authState.codingLang.lang] = authState.codingLang.tpl;
          setCodingUserTemplates(qusData);
        }
      }

      if (codingUserTemplates[qustionId]) {
        if (
          codingUserTemplates[qustionId][authState.codingLang.lang] &&
          codingUserTemplates[qustionId][authState.codingLang.lang] != ""
        ) {
          let codeTplValue = codingUserTemplates[qustionId]
            ? codingUserTemplates[qustionId][authState.codingLang.lang]
            : question[authState.codingLang.template];
          // console.log(codeTplValue) // FK cleanup console july 2023
          setCodeTpl(codeTplValue);
        } else {
          setCodeTpl(question[authState.codingLang.template]);
        }
      } else {
        setCodeTpl(question[authState.codingLang.template]);
      }
    }
  }, [authState.codingLang.lang]);

  useEffect(() => {
    // console.log("setting codingInput[3]: ", input);  // FK cleanup console july 2023
    dispatch(quiz.setCodingInput(input));
  }, [input]);

  useEffect(() => {
    dispatch(quiz.setCodingText(codeTpl));
    // dispatch(quiz.setCodingText(state.codeTpl));   // Note: AR: Please ignore this comment
  }, [codeTpl || state.codeTpl]);
  // }, [state.codeTpl]); // Note: AR: Please ignore this comment

  useEffect(() => {
    if (authState.codingTheme) {
      setState({
        ...state,
        theme: authState.codingTheme.theme,
        themeId: authState.codingTheme.themeId,
      });
    }
  }, [authState.codingTheme]);

  useEffect(() => {
    if (authState.codingLang) {
      let codingLang = authState.codingLang.lang.toLowerCase();
      setState({
        ...state,
        lang: codingLang == "c++" ? "cpp" : codingLang == "c#" ? "csharp" : codingLang == "golang" ? "go" : codingLang,
        langId: authState.codingLang.langId,
        cid: authState.codingLang.cid,
      });
    }
  }, [authState.codingLang]);

  const onCodeChange = (newValue) => {
    // console.log(newValue);
    dispatch(quiz.updateCodingSubmissionDetail(null));
    setState({ ...state, codeTpl: newValue });

    // Update history obj
    let qusData = { ...codingUserTemplates };
    qusData[qustionId][authState.codingLang.lang] = newValue;
    setCodingUserTemplates(qusData);

    dispatch(quiz.setCodingText(newValue));
  };

  const onInputChange = (e) => {
    // setState({ ...state, input: e.target.value });
    setInput(e.target.value);
    dispatch(quiz.setCodingInput(e.target.value));
    dispatch(quiz.updateCodingSubmissionDetail(null));
  };

  useEffect(() => {
    if (codingChallengeSubmissionId) {
      setTimeout(() => {
        dispatch(
          quiz.getCodingSubmissionDetail({
            id: codingChallengeSubmissionId,
          })
        );
      }, 5000);
    }
  }, [codingChallengeSubmissionId]);

  useEffect(() => {}, [codingChallengeSubmissionDetail]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function updateDimensions() {
    editorHt = window.innerHeight - 80 - 80 - 48 - 120 - 92;
  }

  // functionallity to show menu item for input cases
  const [anchorEl, setAnchorEl] = useState(null);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (inputCase) => {
    setInput(inputCase);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={0}
      className={classes.root}
    >
      <Grid container className={classes.editorCt} alignItems="stretch">
        <Editor
          className={`${classes.codeEditor}`}
          height={editorHt}
          theme={state.theme}
          language={state.lang}
          value={codeTpl}
          onChange={onCodeChange}
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          options={{
            contextmenu: false,
            // minimap: {
            //   enabled: false,
            // },
            fontSize: 14,
            cursorStyle: "block",
            wordWrap: "on",
          }}
        />
      </Grid>
      <Grid container className={`${classes.contGrid}`} spacing={2}>
        {/* <Grid item xs={4}>
          <Typography variant2="b2Medium" className={`${classes.valueText} ${classes.TextValueMedium}`}>
            Input to your program (stdin)
          </Typography>
          <div className={`${classes.textArea} ${state.theme == "vs-dark" ? classes.darkcolor : classes.lightColor} `}>
            <TextareaAutosize
              rows={4}
              rowsMax={4}
              placeholder="Enter input value(s)"
              aria-label="input"
              onChange={onInputChange}
              className={`${classes.inputText} 
              ${state.theme == "vs-dark" ? classes.InputText : null}
                  ${state.theme == "vs-dark" ? classes.darkcolor : classes.lightColor} swiper`}
              value={input}
            ></TextareaAutosize>
          </div>
        </Grid> */}
        <Grid item xs={4}>
          <Typography
            variant="h6"
            className={`${classes.valueText} ${classes.TextValueMedium}`}
            sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            Input to your program (stdin)
            {question?.Input_Cases && question?.Input_Cases.length > 0 && (
              <>
                <InputIcon
                  style={{
                    color: "#1789FC",
                    marginLeft: "7px",
                    cursor: "pointer",
                    paddingRight: "2px",
                    fontSize: "20px",
                  }}
                  onClick={handleButtonClick}
                />
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <MenuItem className={classes.menuItemHeader}>
                    <b>Select Sample Input</b>
                  </MenuItem>
                  {question?.Input_Cases?.map((inputCase, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleMenuItemClick(inputCase)}
                      className={index % 2 === 0 ? classes.menuItemOdd : classes.menuItemEven}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {inputCase}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Typography>
          <div className={`${classes.textArea} ${state.theme === "vs-dark" ? classes.darkcolor : classes.lightColor}`}>
            <TextareaAutosize
              rows={4}
              rowsMax={4}
              placeholder="Enter input value(s)"
              aria-label="input"
              onChange={onInputChange}
              className={`${classes.inputText} ${state.theme === "vs-dark" ? classes.InputText : null} ${
                state.theme === "vs-dark" ? classes.darkcolor : classes.lightColor
              } swiper`}
              value={input}
            />
          </div>
        </Grid>
        <Grid item xs={8} className={classes.outValue}>
          <Typography variant2="b2Medium" className={`${classes.valueText} ${classes.TextValueMedium}`}>
            Output from your program (stdout)
          </Typography>
          <div>
            <SwipeableViews
              index={
                codingChallengeSubmissionDetail && codingChallengeSubmissionDetail.status === "compilation error"
                  ? 0
                  : 0
              }
              className={`${classes.textArea} ${state.theme == "vs-dark" ? classes.darkcolor : classes.lightColor}`}
            >
              <div
                className={`${classes.programOutput} ${state.theme == "vs-dark" ? classes.darkcolorText : null} swiper`}
              >
                {props?.quizState?.isquizLoading && !props?.isFullSubmit
                  ? "Compiling code..."
                  : codingChallengeSubmissionDetail && codingChallengeSubmissionDetail.output}
              </div>
            </SwipeableViews>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
