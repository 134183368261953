import * as React from "react";
import { useMemo, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Divider, Typography, Grid, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../styles/theme";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "../../../services/axios";
import moment from "moment";
import { TimeZonePT, setOptValue } from "../../../services/globalService";
import ColoredLinearProgress from "../../../components/controls/coloredLoadingProgress";
import { useSelector } from "react-redux";
// import fullSkills from "../../../config";
// import { useSelector } from "react-redux";

const useStyles = makeStyles({
  textValue: theme.typography.b1Regular,
  deactiveText: theme.typography.b1Medium,
  actionbtn: {
    paddingRight: "20px",
    paddingBottom: "6px",
    paddingTop: "6px",
  },
  root: {
    "& .MuiDialog-paper": {
      maxWidth: "1312px !important",
      maxHeight: "576px !important",
    },
  },
  field: {
    height: "48px !important",
    marginTop: "8px !important",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  textbreak: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    // marginBottom: '15px'
  },
  dialogSty: {
    padding: "16px 24px !important",
  },
  scoreMeter: {
    marginTop: -85,
    marginBottom: "5px",
  },
  scoreLeftArea: {
    padding: 15,
    width: "100%",
    "@media (min-width:1450px)": {
      padding: 36,
      width: "auto",
    },
  },
});
const CandidateViewModel = (props) => {
  // console.log(props?.candidateData, "candidate view data");
  // const theme = useTheme();
  const authState = useSelector((state) => state.auth);
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const matches = useMediaQuery("(max-width:850px)");
  const classes = useStyles();
  const [download, setDownload] = useState({});
  const [isdownLoading, setIsdownLoading] = useState(false);
  const handleClose = () => {
    props.setOpen(false);
  };
  const [rowData, setRowData] = useState();

  useEffect(() => {
    const candidateList = props?.candidateData?.skills || [];
    // const configList = Object.entries(fullSkills.config.skills);
    const configList = Object.entries(authState?.skillsConfig);
    const updatedSkillSet = new Set();
    const updatedSkills = candidateList.map((skill) => {
      const matchingConfig = configList.find(([configKey]) => configKey === skill.skill);

      if (matchingConfig) {
        return { ...skill, skill: matchingConfig[1].fullName };
      }

      return skill;
    });

    updatedSkills.forEach((skill) => {
      updatedSkillSet.add(skill);
    });
    setRowData(Array.from(updatedSkillSet));
    // eslint-disable-next-line
  }, [props?.candidateData?.skills?.length]);

  const getFileSize = (inBytes) => {
    var inKb = inBytes / 1024;
    if (inKb.toFixed(1) < 1000.0) {
      return inKb.toFixed(1) + " KB";
    }

    var inMb = inKb / 1024;
    return inMb.toFixed(1) + "MB";
  };
  // resume download using axios
  const Downloadresume = () => {
    setIsdownLoading(true);
    if (props?.candidateData?.resumeName) {
      let payloaddownload = props?.candidateData?.resumeUrl;

      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      };
      axios(`/candidate/downloadResume?resumeUrl=${payloaddownload}`, config).then((res) => {
        //   console.log("dasadasd",res);
        setDownload(res);
        setIsdownLoading(false);
      });
    }
  };
  useEffect(() => {
    if (download?.status === 0) {
      window.open(download?.data, "_self");
    }
    // eslint-disable-next-line
  }, [download?.status]);

  /* Sorting the array of objects by monthsUsed in descending order and then by skill in ascending order. */
  const handleSort = useMemo(() => {
    return rowData?.sort(function (a, b) {
      return b.monthsUsed - a.monthsUsed || a.skill.localeCompare(b.skill);
    });
    // eslint-disable-next-line
  }, [rowData?.length]);
  return (
    <>
      {matches ? (
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.root}
          >
            {isdownLoading === true && (
              <div className={classes.loader}>
                <ColoredLinearProgress />
              </div>
            )}
            <DialogTitle id="responsive-dialog-title">
              Viewing {props?.candidateData?.user?.firstName} {props?.candidateData?.user?.lastName}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  // color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent className={classes.dialogSty}>
              <Grid container display={"flex"} justifyContent={"center"}>
                <Grid item xs={12}>
                  <Typography className={classes.roletype} style={{ color: "#2F2F2F", marginBottom: "20px" }}>
                    Candidate Details{" "}
                  </Typography>
                  {/* <Grid item xs={12}> */}
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Status</Typography>
                        <Typography
                          className={classes.roletype}
                          style={{
                            color: props?.candidateData?.status === "active" ? "#2F2F2F" : "#DD2E2E",
                          }}
                        >
                          {props?.candidateData?.status === "active" ? "Active" : "Inactive"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Candidate ID</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.uid)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Firstname</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.user?.firstName)}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Lastname</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.user?.lastName)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Location</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.location)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Email</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.user?.email)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Phone</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.user?.phone)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Job Title</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.jobTitle)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Degree</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.degree)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Experience</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.yoe)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>LinkedIn</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F", overflowWrap: "anywhere" }}>
                          {setOptValue(props?.candidateData?.linkedInProfile)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Resume</Typography>
                        {/* Checking if the resumeName is present or not. If it is present, it will display the resumeName and if it is not present, it will display the value of resumeName */}
                        {props?.candidateData?.resumeName ? (
                          <div onClick={Downloadresume} style={{ cursor: "pointer" }}>
                            <Tooltip title={props?.candidateData?.resumeName}>
                              <Typography
                                className={classes.roletype}
                                style={{
                                  color: "#2F2F2F",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis !important",
                                }}
                              >
                                {props?.candidateData?.resumeName}
                              </Typography>
                            </Tooltip>
                            <span className={classes.roletype}>({getFileSize(props?.candidateData?.resumeSize)})</span>
                            <span>
                              <DownloadIcon
                                style={{
                                  verticalAlign: "bottom",
                                  color: "#1789FC",
                                }}
                              />{" "}
                            </span>
                          </div>
                        ) : (
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {setOptValue(props?.candidateData?.resumeName)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Added By</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.candidateData?.createdBy?.displayName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Added On</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {moment(props?.candidateData?.createdAt).format("MMM DD, YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <p
                    style={{
                      borderBottom: "1px solid #C4C4C4",
                      paddingTop: "10px",
                    }}
                  />
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype} sx={{ height: "45px" }}>
                            Skills
                          </Typography>
                          {props?.candidateData?.skills && props?.candidateData?.skills.length > 0 ? (
                            (handleSort || []).map((skill, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F", height: "45px" }}>
                                  {skill.skill}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F", height: "45px" }}>
                              None
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype} sx={{ height: "45px" }}>
                            Months Used
                          </Typography>
                          {props?.candidateData?.skills && props?.candidateData?.skills.length > 0 ? (
                            (handleSort || []).map((skill, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F", height: "45px" }}>
                                  {skill.monthsUsed}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F", height: "45px" }}>
                              None
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype} sx={{ height: "45px" }}>
                            Last Used
                          </Typography>
                          {props?.candidateData?.skills && props?.candidateData?.skills.length > 0 ? (
                            (handleSort || []).map((skill, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F", height: "45px" }}>
                                  {skill.lastUsed}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F", height: "45px" }}>
                              None
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <p
                    style={{
                      borderBottom: "1px solid #C4C4C4",
                      paddingTop: "10px",
                    }}
                  />
                  <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                    <Typography className={classes.roletype}>Internal Notes</Typography>
                    {(props?.candidateData?.notesLog || []).map((note, i) => {
                      return (
                        <Grid>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                            {note.msg}
                          </Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                            {note.what} by {note.who.displayName} on {TimeZonePT(note.when)}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <div>
          <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.root}
          >
            {isdownLoading === true && (
              <div className={classes.loader}>
                <ColoredLinearProgress />
              </div>
            )}
            <DialogTitle id="responsive-dialog-title">
              Viewing {props?.candidateData?.user?.firstName} {props?.candidateData?.user?.lastName}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  // color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent className={classes.dialogSty}>
              <Grid container display={"flex"} justifyContent={"center"}>
                <Grid item xs={0.7}></Grid>
                <Grid item xs={11.3}>
                  <Typography className={classes.roletype} style={{ color: "#2F2F2F", marginBottom: "20px" }}>
                    Candidate Details{" "}
                  </Typography>
                  {/* <Grid item xs={12}> */}
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <Grid container spacing={2}>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Status</Typography>
                        <Typography
                          className={classes.roletype}
                          style={{
                            color: props?.candidateData?.status === "active" ? "#2F2F2F" : "#DD2E2E",
                          }}
                        >
                          {props?.candidateData?.status === "active" ? "Active" : "Inactive"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Candidate ID</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.uid)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Firstname</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.user?.firstName)}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Lastname</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.user?.lastName)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Location</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.location)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Email</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.user?.email)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Phone</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.user?.phone)}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Job Title</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.jobTitle)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Degree</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.degree)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Experience</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {setOptValue(props?.candidateData?.yoe)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>LinkedIn</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F", overflowWrap: "anywhere" }}>
                          {setOptValue(props?.candidateData?.linkedInProfile)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={0.6}></Grid>

                    <Grid item xs={3}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Resume</Typography>
                        {/* Checking if the resumeName is present or not. If it is present, it will display the resumeName and if it is not present, it will display the value of resumeName */}
                        {props?.candidateData?.resumeName ? (
                          <div onClick={Downloadresume} style={{ cursor: "pointer" }}>
                            <Tooltip title={props?.candidateData?.resumeName}>
                              <Typography
                                className={classes.roletype}
                                style={{
                                  color: "#2F2F2F",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis !important",
                                }}
                              >
                                {props?.candidateData?.resumeName}
                              </Typography>
                            </Tooltip>
                            <span className={classes.roletype}>({getFileSize(props?.candidateData?.resumeSize)})</span>
                            <span>
                              <DownloadIcon
                                style={{
                                  verticalAlign: "bottom",
                                  color: "#1789FC",
                                }}
                              />{" "}
                            </span>
                          </div>
                        ) : (
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {setOptValue(props?.candidateData?.resumeName)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={0.6}></Grid>

                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Added By</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.candidateData?.createdBy?.displayName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Added On</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {moment(props?.candidateData?.createdAt).format("MMM DD, YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={0.7}></Grid>
                <Grid item xs={11.3}>
                  <p
                    style={{
                      borderBottom: "1px solid #C4C4C4",
                      paddingTop: "10px",
                    }}
                  />
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Skills</Typography>
                          {props?.candidateData?.skills && props?.candidateData?.skills.length > 0 ? (
                            (handleSort || []).map((skill, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                                  {skill.skill}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              None
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={0.6}></Grid>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Months Used</Typography>
                          {props?.candidateData?.skills && props?.candidateData?.skills.length > 0 ? (
                            (handleSort || []).map((skill, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                  {skill.monthsUsed}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              None
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Last Used</Typography>
                          {props?.candidateData?.skills && props?.candidateData?.skills.length > 0 ? (
                            (handleSort || []).map((skill, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                  {skill.lastUsed}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              None
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={3.6}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={0.7}></Grid>
                <Grid item xs={11.3}>
                  <p
                    style={{
                      borderBottom: "1px solid #C4C4C4",
                      paddingTop: "10px",
                    }}
                  />
                  <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                    <Typography className={classes.roletype}>Internal Notes</Typography>
                    {(props?.candidateData?.notesLog || []).map((note, i) => {
                      return (
                        <Grid key={i}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                            {note.msg}
                          </Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                            {note.what} by {note.who.displayName} on {TimeZonePT(note.when)}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} />
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default CandidateViewModel;
