import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "../../src/components/controls/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import CustomizedProgressBars from "../../src/components/controls/CustomizedProgressBars";
import useMediaQuery from "@mui/material/useMediaQuery";
import auth from "../redux/actions/auth";
import AppConfig from "../constants/AppConfig";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ImagebaseUrl } from "../services/globalService";
import { theme } from "../styles/theme";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    height: "100vh",
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    // paddinTop: "52px",
    height: "100px",
    // paddingBottom: "24px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
  },
  cardTwo: {
    marginTop: "24px",
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
    textAlign: "left",
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    marginLeft: "25%",
    marginRight: "25%",
  },
  cardFourH1: {
    width: "613px",
    height: "100px",
    margin: "auto",
  },
  info: {
    marginTop: "40px",
    display: "flex",
    alignItems: "center",
  },
  sectionInfo: {
    marginTop: "2%",
  },
  rightArrowIcon: {
    height: "10px",
    marginLeft: "2.4%",
    width: "16px",
  },
  statusIcon: {
    position: "absolute",
  },
  completeSection: {
    width: "426px",
    height: "56px",
    margin: "auto",
    background: "#FFFFFF",
    marginTop: "20px",
    borderRadius: "5px",
  },
  main: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "60%",
  },
  item1: {
    height: "100px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
  },
  item2: {
    marginTop: "32px",
  },
  item3: {
    marginTop: "32px",
  },
  item4: {
    marginTop: "16px",
  },
  answers: {
    marginTop: "24px",
  },
  item5: {
    height: "112px",
    marginTop: "48px",
    backgroundColor: theme.palette.background.light,
  },
  pageInfo: {
    height: "36px",
    width: "145px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  next: {
    "& button": {
      float: "right",
    },
  },
  progess: {
    color: theme.palette.btnPrimary.tertiaryText,
  },
  font16: {
    // fontSize: theme.typography.link3.fontSize,
  },
  questionArea: {
    display: "flex",
  },
  qIndex: {
    // flex: "10%"
  },
  qTitle: {
    flex: "90%",
  },
  sidebar: {
    width: 500,
  },
  actionLink: {
    marginTop: 16,
  },
  cloudUploadIcon: {
    width: "24px",
    height: "16px",
    margin: "auto",
    marginTop: "20px",
  },
  uploadedImageSection: {
    width: "100%",
    height: "120px",
    margin: "auto",
    marginTop: "40px",
    background: "#FFFFFF",
    borderRadius: "4px",
    [theme.breakpoints.up("tablet")]: {
      width: "646px",
    },
  },
  uploadImageSection: {
    border: "1px dashed #787878",
    width: "100%",
    height: "130px",
    margin: "auto",
    marginTop: "30px",
    position: "relative",
    boxSizing: "border-box",
    borderRadius: "4px",
    [theme.breakpoints.up("tablet")]: {
      //width: "646px",
    },
  },
  fileInput: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  uploadIconSection: {
    background: "#EBEFF8",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
  },
  errorIconSection: {
    background: "#DD2E2E",
    height: "84px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
    borderRadius: "4px 0px 0px 4px",
    marginLeft: "-1px",
    marginTop: "-1px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "56px",
    },
  },
  sucessIconSection: {
    background: "#61A2E4",
    height: "84px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
    borderRadius: "4px 0px 0px 4px",
    margin: "-1px",
    [theme.breakpoints.down("sm")]: {
      height: "56px",
      width: "57px",
    },
  },
  commonUploadSection: {
    width: "100%",
    margin: "auto",
    marginTop: "30px",
    background: "#FFFFFF",
    height: "81px",
    //height: "56px",

    /* display: flex; */
    /* padding: 10px; */
    borderRadius: "4px",
    [theme.breakpoints.up("tablet")]: {
      // width: "646px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "50%",
    },
  },
  uploadProgress: {
    width: "100%",
    margin: "auto",
    [theme.breakpoints.up("tablet")]: {
      // width: "646px",
    },
  },
  cennterItem: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      // width: "646px",
    },
  },
  marginTop20: {
    marginTop: "20px",
  },
  marginLeft30: {
    marginLeft: "15px",
    fontSize: theme.typography.link2.fontSize,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.b3Regular.fontSize,
    },
  },

  dangerIcon: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // marginRight: "3%",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
  },
  height100Percent: {
    //height: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-2px",
    },
  },
  alignBottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  colorGray: {
    color: "gray",
  },
  errorgray: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "-2px",
    },
  },
  cloudUploadIconSection: {
    margin: "auto",
    width: "100%",
    marginTop: "10px",
    lineHeight: "20px",
    fontFamily: "Roboto",
    fontWeight: 400,
    [theme.breakpoints.up("tablet")]: {
      width: "500px",
    },
  },
  uploadIconText: {
    color: "gray",
    marginTop: "6px",
  },
  nextBtn: {
    background: "rgba(235, 239, 248, 0.5)",
    height: "160px",
    width: "100%",
    position: "fixed",
    bottom: "0px",
    left: "0px",
    right: "0px",
    marginBottom: "0px",
  },
  reUploadFile: {
    color: "#1789FC",
    fontWeight: "700",
    textAlign: "center",
    marginTop: "6px",
    fontSize: "16px",
  },
  reUploadSection: {
    position: "relative",
    height: "35px",
    width: "200px",
    border: "1.5px solid #1789FC",
    borderRadius: "5px",
    marginTop: "35px",
  },
  actionBtn: {
    // marginTop: "24px",
    // marginRight:'13px',
    padding: "30px",
    // cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 12px 10px 10px",
    },
  },
  flexValue: {
    display: "flex",
  },
  mbtext: {
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
    },
  },
  successicon: {
    width: "13.3px",
    height: "13.3px",
    margin: "3px 0 0 7px",
  },
  imgUpload: {
    width: "36px",
    height: "42px",
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "34px",
    },
  },
  deleteImg: {
    width: "10px",
    height: "12px",
    cursor: "pointer",
    [theme.breakpoints.up("tablet")]: {
      width: "14px",
      height: "18px",
      cursor: "pointer",
    },
  },
  texth4: theme.typography.h4,
});

function ResumeUpload(props) {
  // const history = useNavigate();
  const classes = useStyles();

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const resumeName = useSelector((state) => state.auth.resumeName);
  const roleState = useSelector((state) => state.role);
  const [resumeSize, setResumeSize] = useState(0);
  const [errorText, setErrorText] = useState(`Error uploading your resume, please check your file format or 
  size`);

  const [isUploadClicked, setUploadClicked] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [open, setOpen] = useState(false);

  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [uploadStatus, setUploadStatus] = useState(
    roleState?.candidateDashboardDetails?.resumeName
      ? roleState?.candidateDashboardDetails?.resumeName
      : AppConfig.UploadStatus.INITIAL
  );
  // eslint-disable-next-line
  const [uploadPercentage, setPerCentageValue] = useState(0);
  const [fileObj, setFileDetail] = useState({
    fileName: "",
    fileSize: 0,
  });
  // let userDetails = JSON.parse(localStorage.getItem("user_details"));

  // Initial Render of Candidate details
  useEffect(() => {
    // dispatch(auth.candidateDetails(userDetails.userId))  // Old
    dispatch(auth.candidateDetails(roleState?.candidateDashboardDetails?.candidate?.user?.uid));
  }, []);

  // handle the delete Resume in profile section
  const onDeleteResume = () => {
    if (roleState?.candidateDashboardDetails?.candidate?.user?.email && roleState?.candidateDashboardDetails) {
      setUploadStatus(AppConfig.UploadStatus.INITIAL);
      dispatch(auth.deleteResumeRequested(roleState?.candidateDashboardDetails));
    }
  };

  // handle the delete Errored Resume in profile section it is not required api call
  const onDeleteResumeErrored = () => {
    if (roleState?.candidateDashboardDetails?.candidate?.user?.email && roleState?.candidateDashboardDetails) {
      setUploadStatus(AppConfig.UploadStatus.INITIAL);
    }
  };

  // Upload resume in profile section
  function uploadFile({ target: { files } }) {
    setUploadClicked(true);
    if (files && files[0]) {
      const fileSizeMb = files[0].size / 1024 / 1024;
      let ext = files[0].name.split(".").pop();
      ext = ext.toLowerCase();
      setFileDetail({
        ...fileObj,
        fileName: files[0].name,
        fileSize: files[0].size,
      });
      if (fileSizeMb > 5) {
        setUploadStatus(AppConfig.UploadStatus.ERRORED);
      } else if (!AppConfig.validExtension.includes(ext)) {
        setUploadStatus(AppConfig.UploadStatus.ERRORED);
      } else {
        setUploadStatus(AppConfig.UploadStatus.UPLOADING);
        let data = new FormData();
        let dpUploadedFileName = files[0].name;
        dpUploadedFileName = dpUploadedFileName.replaceAll(" ", "_");
        data.append("file", files[0], dpUploadedFileName);
        const payload = {
          data,
          // email: roleState?.candidateDashboardDetails?.candidate?.user?.email,
          inviteId: roleState?.candidateDashboardDetails?.uid,
        };
        dispatch(auth.resumeUpload(payload));
      }
    }
  }

  useEffect(() => {
    if (authState.resumeSize) {
      setResumeSize(authState.resumeSize);
    } else if (!authState.resumeSize && authState.resumeRequest && authState.resumeRequest.size) {
      setResumeSize(authState.resumeRequest.size);
    }
  }, [authState]);

  useEffect(() => {
    if (roleState?.candidateDashboardDetails?.resumeName) {
      setUploadStatus(
        roleState?.candidateDashboardDetails?.resumeName
          ? roleState?.candidateDashboardDetails?.resumeName
          : AppConfig.UploadStatus.INITIAL
      );
      setResumeSize(roleState?.candidateDashboardDetails?.resumeSize);
    }
  }, [roleState?.candidateDashboardDetails?.resumeName]);

  useEffect(() => {
    if (authState && authState.resumeUrlError && isUploadClicked) {
      setUploadStatus(AppConfig.UploadStatus.ERRORED);
      setErrorText(
        // "The Content of this file does not resemble a valid resume. please check and retry"
        " Error uploading your resume, please check your file format or size"
      );
    }
  }, [authState.resumeUrlError]);

  // Resume size
  const getFileSize = (inBytes) => {
    var inKb = inBytes / 1024;
    if (inKb.toFixed(1) < 1000.0) {
      return inKb.toFixed(1) + " KB";
    }

    var inMb = inKb / 1024;
    return inMb.toFixed(1) + "MB";
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (authState && authState.resumeScore < 10 && isUploadClicked) {
      setOpen(true);
    }
  }, [authState.resumeScore]);

  const truncate = (str) => {
    return str?.length > 35 ? str?.substring(0, 35) + "..." : str;
  };

  return (
    <div>
      <div className={`${classes.cennterItem}`}>
        <Typography
          // component={Text}
          variant2={isNotMobile ? "h4" : "h5"}
          className={`${classes.title} ${classes.texth4}`}
          align="left"
        >
          Complete your profile with your most recent resume
        </Typography>
        <Typography sx={{ mt: 1 }}>
          A resume is required for scoring. Even if it was submitted earlier, it can be deleted and replaced with a new
          one that's more relevant for this job role. Resumes that match the required skills may result in an improved
          candidate score.
        </Typography>
      </div>
      {uploadStatus === AppConfig.UploadStatus.INITIAL && (
        <div className={`${classes.uploadImageSection}`}>
          <div>
            <input
              type={"file"}
              accept=".pdf,.doc,.docx,.txt"
              onChange={uploadFile}
              className={`${classes.fileInput}`}
              title={fileObj.fileName ? fileObj.fileName : "No Choose File "}
            />
          </div>

          <div className={`${classes.cloudUploadIcon}`}>
            <img alt="cloudUploadIcon" src={`${ImagebaseUrl}assets/cloudUploadIcon.png`} />
          </div>
          <div className={`${classes.cloudUploadIconSection}`}>
            <Typography variant2="body3" align="center">
              Upload Your Resume or Drag File Here
            </Typography>
            <Typography
              // component={Text}
              variant2="body3"
              align="center"
              className={`${classes.uploadIconText}`}
            >
              Accepted file formats PDF, DOC, DOCX, TXT. File size max. 5 MB
            </Typography>
          </div>
        </div>
      )}

      {uploadStatus === roleState?.candidateDashboardDetails?.resumeName && (
        <div className={`${classes.commonUploadSection}`} style={{ border: "2px solid #61A2E4" }}>
          <Grid className={`${classes.height100Percent}`}>
            <Grid className={`${classes.flexValue}`}>
              <div className={`${classes.sucessIconSection}`}>
                <img
                  alt="uploadSucessIcon"
                  src={`${ImagebaseUrl}assets/uploadSuccessIcon.svg`}
                  className={classes.imgUpload}
                />
              </div>

              <Grid className={`${classes.mbtext}`}>
                <Grid className={`${classes.flexValue}`}>
                  {roleState?.candidateDashboardDetails?.resumeName ? (
                    <Typography className={`${classes.marginLeft30}`} variant2="agLink">
                      <Tooltip title={roleState?.candidateDashboardDetails?.resumeName} placement="bottom">
                        <span style={{ whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {truncate(roleState?.candidateDashboardDetails?.resumeName)} {getFileSize(resumeSize)}
                        </span>
                      </Tooltip>
                      <span
                        className={`${classes.dangerIcon}`}
                        //alignContent="flex-end"
                      >
                        <img
                          alt="successIcon"
                          src={`${ImagebaseUrl}assets/successIcon.svg`}
                          className={`${classes.successicon}`}
                        />
                      </span>
                    </Typography>
                  ) : (
                    <Typography className={`${classes.marginLeft30}`} variant2="agLink">
                      <Tooltip title={fileObj.fileName} placement="bottom">
                        <span style={{ whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {truncate(fileObj.fileName)} {getFileSize(fileObj.fileSize)}
                        </span>
                      </Tooltip>
                      <span
                        className={`${classes.dangerIcon}`}
                        //alignContent="flex-end"
                      >
                        <img
                          alt="successIcon"
                          src={`${ImagebaseUrl}assets/successIcon.svg`}
                          className={`${classes.successicon}`}
                        />
                      </span>
                    </Typography>
                  )}
                </Grid>

                <Typography className={`${classes.marginLeft30} ${classes.colorGray}`} variant2="agLink">
                  Uploaded on {moment(new Date()).format("LL") + ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ padding: "10px" }}>
              <IconButton aria-label="delete" size="small" style={{ margin: "13px" }} onClick={onDeleteResume}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )}

      {uploadStatus === AppConfig.UploadStatus.ERRORED && (
        <div className={`${classes.commonUploadSection}`} style={{ border: "2px solid #de3f2f" }}>
          <Grid className={`${classes.height100Percent}`}>
            <Grid className={`${classes.flexValue}`}>
              <div className={`${classes.errorIconSection}`}>
                {/* <img alt="uploadSucessIcon" src={uploadSucessIcon} /> */}
                <img
                  alt="uploadImageIcon"
                  src={`${ImagebaseUrl}assets/uploadErrorIcon.svg`}
                  className={classes.imgUpload}
                />
              </div>

              <Grid className={`${classes.mbtext}`}>
                <Grid className={`${classes.flexValue}`}>
                  <Typography className={`${classes.marginLeft30}`} variant2="agLink">
                    <Tooltip title={fileObj.fileName} placement="bottom">
                      <span style={{ whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {truncate(fileObj.fileName)} {getFileSize(fileObj.fileSize)}
                      </span>
                    </Tooltip>

                    <span
                      className={`${classes.dangerIcon}`}
                      //alignContent="flex-end"
                    >
                      <img
                        alt="dangerIcon"
                        src={`${ImagebaseUrl}assets/dangerIcon.svg`}
                        className={`${classes.successicon}`}
                      />
                    </span>
                  </Typography>
                </Grid>

                <Typography
                  className={`${classes.marginLeft30} ${classes.colorGray} ${classes.errorgray}`}
                  variant2="agLink"
                >
                  {errorText}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ padding: "10px" }}>
              <IconButton aria-label="delete" size="small" style={{ margin: "13px" }} onClick={onDeleteResumeErrored}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )}

      {uploadStatus === AppConfig.UploadStatus.UPLOADING && (
        <div>
          <Grid container className={`${classes.commonUploadSection}`}>
            <Grid className={classes.flexValue}>
              <div className={`${classes.uploadIconSection}`}>
                <img alt="uploadImageIcon" src={`${ImagebaseUrl}assets/uploadImageIcon.svg`} />
              </div>
              <Grid style={{ padding: "15px" }}>
                <Typography
                  className={`${classes.marginLeft30}`}
                  // component={Text}
                  variant2="subtitle"
                >
                  Uploading...
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.uploadProgress}>
            <CustomizedProgressBars
              value={uploadPercentage}
              active
              label={`${uploadPercentage}%`}
            ></CustomizedProgressBars>
          </div>
        </div>
      )}

      {uploadStatus === AppConfig.UploadStatus.UPLOADED && (
        <div className={`${classes.commonUploadSection}`} style={{ border: "2px solid #61A2E4" }}>
          <Grid className={`${classes.height100Percent}`}>
            <Grid className={`${classes.flexValue}`}>
              <div className={`${classes.sucessIconSection}`}>
                <img
                  alt="uploadSucessIcon"
                  src={`${ImagebaseUrl}assets/uploadSuccessIcon.svg`}
                  className={classes.imgUpload}
                />
              </div>

              <Grid className={`${classes.mbtext}`}>
                <Grid className={`${classes.flexValue}`}>
                  {resumeName || (authState.resumeRequest && authState.resumeRequest.originalname) ? (
                    <Typography className={`${classes.marginLeft30}`} variant2="agLink">
                      <Tooltip title={authState.resumeRequest.originalname} placement="bottom">
                        <span style={{ whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {truncate(resumeName || authState.resumeRequest.originalname)}
                          {getFileSize(resumeSize)}
                        </span>
                      </Tooltip>
                      <span
                        className={`${classes.dangerIcon}`}
                        //alignContent="flex-end"
                      >
                        <img
                          alt="successIcon"
                          src={`${ImagebaseUrl}assets/successIcon.svg`}
                          className={`${classes.successicon}`}
                        />
                      </span>
                    </Typography>
                  ) : (
                    <Typography className={`${classes.marginLeft30}`} variant2="agLink">
                      <Tooltip title={fileObj.fileName} placement="bottom">
                        <span style={{ whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {truncate(fileObj.fileName)} {getFileSize(fileObj.fileSize)}
                        </span>
                      </Tooltip>
                      <span
                        className={`${classes.dangerIcon}`}
                        //alignContent="flex-end"
                      >
                        <img
                          alt="successIcon"
                          src={`${ImagebaseUrl}assets/successIcon.svg`}
                          className={`${classes.successicon}`}
                        />
                      </span>
                    </Typography>
                  )}
                </Grid>

                <Typography className={`${classes.marginLeft30} ${classes.colorGray}`} variant2="agLink">
                  Uploaded on {moment(new Date()).format("LL") + ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ padding: "10px" }}>
              <IconButton aria-label="delete" size="small" style={{ margin: "13px" }} onClick={onDeleteResume}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackBar(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Dialog
        onClose={() => handleDialogClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
        onBackdropClick={() => handleDialogClose()}
        PaperProps={{
          style: {
            width: "350px",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <DialogContent dividers>
          <Typography variant2="b2Medium" align="center">
            The resume you uploaded does not appear to be correct. Please check and upload again if required..
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            data-rt-profile-uploadok="uploadok"
            color="btnPrimary"
            text="OK"
            width="120px"
            // height="30px"
            onClick={() => handleDialogClose()}
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ResumeUpload;
