import * as Yup from "yup";

export const updateUserProfileSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
});

export const resetPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please enter your old password"),
  newPassword: Yup.string()
    .required("Please enter a new password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z\d!@#$%^&*()\-_=+[{\]};:'",<.>/?\\|~`]).{12,}$/,
      "Password must be 12 characters long and contain at least one uppercase letter, number, or special character."
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please confirm your new password"),
});

// export const updateProfileFields = [{
//     name: "firstName",
//     placeHolder: "Enter Your First Name",
//     label: "First Name"
// }, {
//     name: "lastName",
//     placeHolder: "Enter Your Last Name",
//     label: "Last Name"
// }]
