import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Link, useMediaQuery } from "@mui/material";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { theme } from "../../styles/theme";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    //height: "100vh",
  },
  faqdiv: {
    marginTop: "100px",
    width: "100%",
  },
  textGrid: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  textGridlast: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  faqText: {
    marginTop: "20px",
    borderBottom: "1px solid #C4C4C4",
    marginBottom: "10px",
  },
  faqparagraph: {
    margin: "12px 0px",
    padding: "0px 10px 6px 10px",
  },
  faqques: {
    padding: "10px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    marginTop: 8,
    marginBottom: "15px",
    cursor: "pointer",
    [theme.breakpoints.up("tablet")]: {
      margin: "0px",
    },
  },
  mailto: {
    color: theme.palette.btnPrimary.main,
    // margin: 8,
    cursor: "pointer",
  },
  ImageBackround: {
    display: "flex",
    justifyContent: "center",
  },
  Imagest: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "auto",
    },
  },
  imagecolor: {
    // backgroundImage: "url(" + `${ImagebaseUrl}assets/svg/Imagebackground.svg` + ")",
    // height: "200px",
  },
  contactus: {
    marginTop: "20px",
  },
  contactText: {
    marginBottom: "20px",
  },
});

export default function AboutPage(props) {
  // const { location } = props; // FK cleanup unused july 2023

  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const handleBack = () => {
    // AppConfig.goBackButton = true;
    history(-1);
  };
  return (
    <div className={classes.root}>
      <NavBar></NavBar>
      <Grid className={classes.faqdiv}>
        <Grid container>
          {isNotMobile && <Grid item xs={3}></Grid>}
          <Grid item xs={isNotMobile ? 6 : 12} style={{ padding: "10px" }}>
            <Grid className={classes.textGrid}>
              <Typography className="about_sty">About Us</Typography>
              <Link onClick={handleBack} data-rt-about-backgo="backgo">
                <Typography className={classes.labelBlue}>Back</Typography>
              </Link>
            </Grid>
            <Typography className={classes.faqparagraph} variant2={isNotMobile ? "b1Regular" : "b2Regular"}>
              We don’t drive to a new destination without a GPS. Why drive blind into your career changes? TechScore
              believes better information can reduce the frustration in software engineering career changes. Our Career
              Score has been built with decades of industry experience, and with data from thousands of actual users
              just like you.{" "}
            </Typography>
            <div>
              <Typography
                // variant="h3"
                // className={classes.contactus}
                className={`about_sty ${classes.textGrid} ${classes.contactus}`}
              >
                Contact Us
              </Typography>
              <Typography variant2={isNotMobile ? "b1Regular" : "b2Regular"} className={classes.faqparagraph}>
                {" "}
                We appreciate your feedback. Please email{" "}
                <Link className={classes.mailto} href="mailto:info@techscore.ai">
                  info@techscore.ai
                </Link>{" "}
                with any questions or suggestions. Thank you!
              </Typography>
            </div>
          </Grid>
          {isNotMobile && <Grid item xs={3}></Grid>}
        </Grid>
      </Grid>
      <div className={classes.imagecolor}></div>
      <Footer></Footer>
    </div>
  );
}
