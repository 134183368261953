import React from "react";
import { useSelector } from "react-redux";
import ReusableDialog from "./controls/ReusableDialog";

export default function SubmitConfirmationDialog(props) {
  // eslint-disable-next-line
  const { open, text, succesBtnLabel, actionInfo, ...other } = props;
  // const classes = useStyles();
  const quizState = useSelector((state) => state.quiz);

  const handleExitFullscreen = () => {
    // Check if the document is currently in fullscreen mode
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const onClickSubmit = () => {
    if (quizState.isquizLoading == true) {
      return null;
    } else {
      props?.onSubmit(actionInfo);
    }
    handleExitFullscreen();
  };

  const closeModel = () => {
    if (navigator.onLine) {
      props.onClose();
    } else {
      props.TimeClose();
    }
  };
  return (
    <div>
      <ReusableDialog
        open={open}
        title={props?.heading && props?.heading != "" ? props?.heading : ""}
        content={text}
        actions={
          props.TimoutText === "Autosubmit"
            ? null
            : [
                { onClick: closeModel, text: "Cancel", color: "btnCls1", variant: "outlined" },
                { onClick: onClickSubmit, text: succesBtnLabel ? succesBtnLabel : "Confirm", color: "btn1" },
              ]
        }
      />
    </div>
  );
}
