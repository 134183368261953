module.exports.ga4config = {
  tracking: [
    {
      measurementId: "G-NV6JYEFPZB",
      subdomain: "bayone",
    },
    {
      measurementId: "G-24FDNYNDVZ",
      subdomain: "app",
    },
  ],
};
