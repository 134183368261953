import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Link from "@mui/material/Link";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "../../../components/controls/Button";
import ReactiveModel from "../../../components/controls/ReactiveModel";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none !important",
    overflow: "hidden",
    // marginBottom: theme.spacing(3),
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
}));

const ResumeProcessingCard = ({
  _id,
  imageURL,
  name,
  email,
  linkedinURL,
  status,
  firstName,
  lastName,
  suggestion,
  onDelete,
  onEdit,
  onAdd,
  degree,
  yoe,
  skillsCount,
  location,
  jobTitle,
  originalFileName,
  message,
  updatedAt,
  editStatus,
  deleted,
  added,
  manageMassage,
  candidateId,
  resError,
}) => {
  const classes = useStyles();

  const [reset, setReset] = useState(false);
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false);

  // const [isHovered, setIsHovered] = useState(false);

  const [dots, setDots] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        const newDots = prevDots.length < 3 ? prevDots + "." : "";
        return newDots;
      });
    }, 600);

    return () => clearInterval(intervalId);
  }, []);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  // const truncatedLocation = location?.length > 24 ? `${location?.slice(0, 24)}...` : location;

  // const [isClicked, setIsClicked] = useState(false);

  // const handleOnClick = () => {
  //   setIsClicked(!isClicked);
  // };

  // const [isClickedMsg, setIsClickedMsg] = useState(false);

  // const handleOnClickMsg = () => {
  //   setIsClickedMsg(!isClickedMsg);
  // };

  // const [isClickedFile, setIsClickedFile] = useState(false);

  // const handleOnClickFile = () => {
  //   setIsClickedFile(!isClickedFile);
  // };

  useEffect(() => {
    if (editStatus === true) {
      setDisableDeleteBtn(true);
    }
  }, [editStatus]);

  useEffect(() => {
    if (editStatus === false) {
      setDisableDeleteBtn(false);
    }
  }, [editStatus]);

  const handleDelete = (value) => {
    setReset(false);
    onDelete();
  };
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    // setIsModalOpen(true);
    setReset(true);
  };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  // time calculation

  let currentDateTime = new Date().toISOString();

  let updatedAtDate = new Date(updatedAt);
  let currentDateTimeDate = new Date(currentDateTime);

  let minuts = status === "incomplete" ? 15 : status === "complete" && 10;

  updatedAtDate.setMinutes(updatedAtDate.getMinutes() + minuts);

  let timeDifference = updatedAtDate - currentDateTimeDate;

  let timeDifferenceInMinutes = Math.floor(timeDifference / (minuts * 100 * 60));

  let formattedOutput;
  if (timeDifferenceInMinutes <= 1) {
    formattedOutput = "soon.";
  } else {
    formattedOutput = timeDifferenceInMinutes.toString().padStart(2, "0") + " minutes";
  }

  return (
    <>
      <Card
        variant="outlined"
        elevation={0}
        sx={{
          padding: "23px  16px 16px",
          paddingBottom: "8px",
          // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add box shadow
          marginBottom: "30px", // Add margin bottom
          borderRadius: "8px",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            //   padding: "16px",
          }}
        >
          <Grid item xs={2} md={2}>
            <img
              m={"auto"}
              src={imageURL || "https://yts-org-assets.s3.us-east-1.amazonaws.com/default_user.png"}
              alt={firstName}
              style={{
                width: "85%",
                height: "85%",
                margin: "auto",
                borderRadius: "50%",
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <CardContent
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "0 16px",
              }}
            >
              <div style={{ flex: 1 }}>
                {status === "pending" ? (
                  <Typography
                    // variant="h5"
                    // component="div"
                    style={{ textAlign: "left" }}
                    className={classes.roletype1}
                    color="text.secondary"
                  >
                    <span
                      style={{
                        marginRight: "8px",
                        fontWeight: 900,
                        color: "#5e5e62",
                      }}
                    >
                      {/* Processing... */}
                      Processing{dots}
                    </span>
                  </Typography>
                ) : (
                  <Typography
                    // variant="h5"
                    component="div"
                    style={{ textAlign: "left" }}
                    className={classes.roletype1}
                    color="text.secondary"
                  >
                    <span>
                      {firstName ? (
                        <span style={{ marginRight: "8px", fontWeight: 900, color: "#5e5e62" }}>{firstName}</span>
                      ) : (
                        <span style={{ marginRight: "8px", color: "red" }}>First Name Missing</span>
                      )}
                    </span>
                    <span>
                      {lastName ? (
                        <span style={{ fontWeight: 900, color: "#5e5e62" }}>{lastName}</span>
                      ) : (
                        <span style={{ color: "red" }}>Last Name Missing</span>
                      )}
                    </span>
                    {jobTitle ? `, ${jobTitle}` : ""}
                  </Typography>
                )}

                <Typography
                  variant="body2"
                  color="text.secondary"
                  mb={1}
                  mt={2}
                  style={{ textAlign: "left", fontSize: "16px" }}
                >
                  {status === "complete" ? (
                    <Link href={`mailto:${email}`} style={{ color: "#5e5e62" }}>
                      {email}
                    </Link>
                  ) : status === "pending" ? (
                    <div style={{ height: "25px", width: "100%", clear: "both" }}></div>
                  ) : status === "processing" ? (
                    <span style={{ color: "red", textDecoration: "none" }}>
                      {!email ? `No Email Available. Email is required.` : email}
                    </span>
                  ) : status === "duplicate-email" ? (
                    <span style={{ color: "red", textDecoration: "none" }}>
                      {!email ? `No Email Available. Email is required.` : email}
                    </span>
                  ) : status === "incomplete" || status === "missing-data" ? (
                    <>
                      {!email ? (
                        <span style={{ color: "red", textDecoration: "none" }}>
                          No Email Available. Email is required.
                        </span>
                      ) : (
                        <Link href={`mailto:${email}`} style={{ color: "#5e5e62" }}>
                          {email}
                        </Link>
                      )}
                    </>
                  ) : (
                    <span style={{ color: "red", textDecoration: "none" }}>{email}</span>
                  )}
                </Typography>
                {status === "pending" ? (
                  <div style={{ height: "25px", width: "100%", clear: "both" }}></div>
                ) : (
                  <Typography variant="body2" color="text.secondary" mb={2} style={{ textAlign: "left" }}>
                    {linkedinURL ? (
                      <Link
                        href={linkedinURL}
                        style={{
                          fontSize: "16px",
                          color: "black",
                          textDecoration: "none",
                        }}
                        target="_blank"
                      >
                        {linkedinURL}
                      </Link>
                    ) : (
                      <div
                        style={{
                          height: "25px",
                          fontSize: "16px",
                          width: "100%",
                          clear: "both",
                          color: "#ff9800",
                        }}
                      >
                        No LinkedIn Link Available.
                      </div>
                    )}
                  </Typography>
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "160px",
                    }}
                  >
                    <SchoolIcon color="action" sx={{ fontSize: 20, color: "#5e5e62" }} />
                    {status === "pending" ? (
                      <Typography variant="body2" sx={{ fontSize: "16px" }} color="text.secondary" ml={1}></Typography>
                    ) : (
                      <Typography variant="body2" sx={{ fontSize: "16px" }} color="text.secondary" ml={1}>
                        {degree ? degree : <span>&mdash;</span>}
                      </Typography>
                    )}
                  </div>

                  {status === "processing" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "160px",
                      }}
                    >
                      <WorkIcon color="action" sx={{ fontSize: 20, color: "#5e5e62" }} />
                      <Typography variant="body1" sx={{ fontSize: "16px" }} color="text.secondary" ml={1}>
                        {yoe ? `${yoe} Years` : <span>&mdash;</span>}
                      </Typography>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "160px",
                      }}
                    >
                      <WorkIcon color="action" sx={{ fontSize: 20, color: "#5e5e62" }} />
                      {status === "pending" ? (
                        <Typography variant="body1" sx={{ fontSize: "16px" }} color="text.secondary" ml={1}>
                          {/* {yoe} Years */}
                        </Typography>
                      ) : (
                        <Typography variant="body1" sx={{ fontSize: "16px" }} color="text.secondary" ml={1}>
                          {yoe ? `${yoe} Years` : <span>&mdash;</span>}
                        </Typography>
                      )}
                    </div>
                  )}

                  {status === "processing" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "160px",
                      }}
                    >
                      <StarIcon color="action" sx={{ fontSize: 20, color: "#5e5e62" }} />
                      <Typography variant="body2" sx={{ fontSize: "16px" }} color="text.secondary" ml={1}>
                        {skillsCount ? `${skillsCount} skills` : <span>&mdash;</span>}{" "}
                      </Typography>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "160px",
                      }}
                    >
                      <StarIcon color="action" sx={{ fontSize: 20, color: "#5e5e62" }} />
                      {status === "pending" ? (
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "16px" }}
                          color="text.secondary"
                          ml={1}
                        ></Typography>
                      ) : (
                        <Typography variant="body2" sx={{ fontSize: "16px" }} color="text.secondary" ml={1}>
                          {skillsCount ? `${skillsCount} skills` : <span>&mdash;</span>}
                        </Typography>
                      )}
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <LocationOnIcon color="action" sx={{ fontSize: 20, color: "#5e5e62" }} />

                    {status === "complete" ||
                    status === "incomplete" ||
                    status === "missing-data" ||
                    status === "processing" ||
                    status === "duplicate-email" ? (
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "16px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          // whiteSpace: isClicked ? "normal" : "nowrap", // Change whiteSpace on click
                          transition: "font-size 0.2s ease, white-space 0.2s ease",
                          // overflow: isClicked ? "visible" : "hidden",
                          // textOverflow: isClicked ? "initial" : "ellipsis",
                          cursor: "pointer",
                        }}
                        title={location}
                        // onClick={handleOnClick}
                        color="text.secondary"
                        ml={1}
                      >
                        {location}
                      </Typography>
                    ) : (
                      <Typography
                        component="div"
                        style={{ textAlign: "left" }}
                        className={classes.roletype1}
                        color="text.secondary"
                      ></Typography>
                    )}
                  </div>
                </div>
              </div>
              {/* <div style={{ textAlign: "right", marginTop: 10,span:2 }}>
            <Typography variant="body2" color="text.secondary">
              Suggestion: {suggestion}
            </Typography>
          </div> */}
            </CardContent>
          </Grid>
          {/* <Grid item xs={1}></Grid> */}
          {/* {deleted?()} */}
          {(status === "complete" && !deleted && !added) ||
          (status === "duplicate-email" && !deleted && !added) ||
          (status === "missing-data" && !deleted && !added) ||
          (status === "incomplete" && !deleted && !added) ? (
            <Grid item xs={1}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: "auto",
                  textAlign: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  disabled
                  style={{
                    textDecoration: "underline",
                    color: disableDeleteBtn ? "grey" : "#dd2e2e",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <Link
                    component="span"
                    underline="none"
                    onClick={disableDeleteBtn ? null : openModal}
                    style={{
                      color: disableDeleteBtn ? "grey" : "#dd2e2e",
                      margin: "auto",
                      cursor: disableDeleteBtn ? "default" : "pointer",
                    }}
                  >
                    Delete
                  </Link>
                </Typography>

                {(status === "complete" && editStatus === false) ||
                (status === "incomplete" && editStatus === false) ? (
                  <Typography style={{ textDecoration: "underline", color: "#1789fc", width: "100%", margin: "auto" }}>
                    <Link
                      component="span"
                      underline="none"
                      onClick={onAdd}
                      style={{ color: "#1789fc", margin: "auto", cursor: "pointer" }}
                    >
                      Add Now
                    </Link>
                  </Typography>
                ) : (
                  <Typography style={{ textDecoration: "underline", color: "gray", width: "100%", margin: "auto" }}>
                    <Link component="span" underline="none" style={{ color: "gray", margin: "auto" }}>
                      Add Now
                    </Link>
                  </Typography>
                )}

                <Button
                  variant="contained"
                  text="Edit"
                  color="btn1"
                  width="100%"
                  sx={{ fontSize: "16px !important", fontWeight: "200" }}
                  onClick={onEdit}
                />
              </div>
            </Grid>
          ) : status === "processing" && !deleted && !added ? (
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                height: "155px",
                width: "11%",
              }}
            >
              <Typography pb={"8px"}>Processing{dots}</Typography>
            </Grid>
          ) : (
            <Grid></Grid>
          )}
        </div>

        <div
          style={{
            textAlign: "right",
            height: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              p: 0,
              m: 0,
              ml: "18.4%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              // whiteSpace: isClickedFile ? "normal" : "nowrap",
              transition: "font-size 0.2s ease, white-space 0.2s ease",
              // overflow: isClickedFile ? "visible" : "hidden",
              // textOverflow: isClickedFile ? "initial" : "ellipsis",
              cursor: "pointer",
            }}
            title={originalFileName}
            // onClick={handleOnClickFile}
          >
            {originalFileName}
          </Typography>

          <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {status === "pending" ? (
              <Typography variant="caption" color="text.secondary" sx={{ p: 0, m: 0 }}>
                {/* Suggestion: {message} */}
              </Typography>
            ) : status === "missing-data" || status === "duplicate-email" ? (
              <Typography
                variant="caption"
                color="red"
                sx={{
                  p: 0,
                  m: 0,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  // whiteSpace: isClickedMsg ? "normal" : "nowrap",
                  transition: "font-size 0.2s ease, white-space 0.2s ease",
                  // overflow: isClickedMsg ? "visible" : "hidden",
                  // textOverflow: isClickedMsg ? "initial" : "ellipsis",
                  cursor: "pointer",
                }}
                title={message}
                // onClick={handleOnClickMsg}
              >
                {message}
              </Typography>
            ) : status === "incomplete" ? (
              <>
                <Typography
                  variant="caption"
                  color=""
                  sx={{
                    p: 0,
                    m: 0,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    // whiteSpace: isClickedMsg ? "normal" : "nowrap",
                    transition: "font-size 0.2s ease, white-space 0.2s ease",
                    // overflow: isClickedMsg ? "visible" : "hidden",
                    // textOverflow: isClickedMsg ? "initial" : "ellipsis",
                    cursor: "pointer",
                  }}
                  title={message}
                  // onClick={handleOnClickMsg}
                >
                  <span style={{ color: !resError ? "" : "red" }} sx={{ p: 0, m: 0 }}>
                    <span style={{ color: !added ? "#ff9800" : "" }}>{message}</span>
                  </span>
                  {added && candidateId ? (
                    <Link href={`/candidateview/${candidateId}`} target="_blank" ml={"5px"}>
                      {candidateId}
                    </Link>
                  ) : (
                    ""
                  )}
                </Typography>
              </>
            ) : status === "complete" ? (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  p: 0,
                  m: 0,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  // whiteSpace: isClickedMsg ? "normal" : "nowrap",
                  transition: "font-size 0.2s ease, white-space 0.2s ease",
                  // overflow: isClickedMsg ? "visible" : "hidden",
                  // textOverflow: isClickedMsg ? "initial" : "ellipsis",
                  cursor: "pointer",
                }}
                title={message}
                // onClick={handleOnClickMsg}
              >
                <span style={{ color: !resError ? "" : "red" }}>
                  {message}
                  {resError}
                </span>
                {!added && !deleted && !manageMassage ? (
                  <span>
                    {" "}
                    Will be auto-added {formattedOutput == "soon." ? "" : "in"} {formattedOutput}
                  </span>
                ) : (
                  <span></span>
                )}
                {added && candidateId ? (
                  <Link href={`/candidateview/${candidateId}`} target="_blank" ml={"5px"}>
                    {candidateId}
                  </Link>
                ) : (
                  ""
                )}
              </Typography>
            ) : (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  p: 0,
                  m: 0,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  // whiteSpace: isClickedMsg ? "normal" : "nowrap",
                  transition: "font-size 0.2s ease, white-space 0.2s ease",
                  // overflow: isClickedMsg ? "visible" : "hidden",
                  // textOverflow: isClickedMsg ? "initial" : "ellipsis",
                  cursor: "pointer",
                }}
                title={message}
                // onClick={handleOnClickMsg}
              >
                <span style={{ color: !resError ? "" : "red" }} sx={{ p: 0, m: 0 }}>
                  {message}
                  {resError}
                </span>
              </Typography>
            )}
          </div>
        </div>

        <ReactiveModel
          open={reset}
          OnReactive={handleDelete}
          onClose={() => setReset(false)}
          Text={"Are you sure you want to delete this item?"}
          textchange={"ResetTest"}
        />
      </Card>
    </>
  );
};

export default ResumeProcessingCard;
