import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../styles/theme";
// import Chip from "@mui/material/Chip";
import SwipeableTextStepper from "./SwipeableTextStepper";
import BlockIcon from "@mui/icons-material/Block";

const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "3rem",
  },
  viewText: {
    // FK cleanup july 2023
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    paddingTop: "10px",
  },
  roletype1: {
    // FK cleanup july 2023
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    paddingTop: "10px",
  },
  roletext: {
    // FK cleanup july 2023
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  roletext1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    // fontWeight: "300 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #787878",
    paddingTop: "10px",
    // marginLeft: "20px",
  },
  spaceflex: {
    // FK cleanup july 2023
    display: "flex",
    justifyContent: "space-between",
  },
  down_arrow: {
    // FK cleanup july 2023
    padding: "8px",
    marginTop: "10px",
  },
  btn: {
    // FK cleanup july 2023
    width: "240px !important",
    textTransform: "none !important",
    background: "#1789FC !important",
  },
  cancel_btn: {
    // FK cleanup july 2023
    width: "112px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#787878 !important",
    boxShadow: "none !important",
  },
  previous_btn: {
    // FK cleanup july 2023
    width: "240px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#1789FC !important",
    boxShadow: "none !important",
    border: "1px solid #C4C4C4 !important",
    marginRight: "10px !important",
  },
  field: {
    // FK cleanup july 2023
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  // score number style
  scoreLeftArea: {
    // FK cleanup july 2023
    // padding: 8,
    width: "100%",
    "@media (min-width:1450px)": {
      width: "442px",
    },
  },
  scoreText: {
    // FK cleanup july 2023
    padding: "20px",
  },
  scoreMeter: {
    top: "-90px !important",
    position: "relative !important",
  },
  threeLine4: {
    color: "#787878",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center !important",
    fontWeight: "400 !important",
    marginTop: "10px",
  },
  textareasty: {
    // FK cleanup july 2023
    width: "100%",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
  textdot: {
    color: "#787878",
    fontSize: "48px !important",
    lineHeight: "20px",
    textAlign: "center !important",
    fontWeight: "600 !important",
    marginTop: "10px",
  },
  grid_root: {
    marginTop: "0px !important",
    marginLeft: "0px !important",
  },
  linksty: {
    color: "#1789FC",
    fontWeight: "500 !important",
    cursor: "pointer",
    padding: "0px",
    fontSize: "16px !important",
  },
  linksty1: {
    color: "#787878",
    fontWeight: "500 !important",
    // cursor: 'pointer',
    padding: "0px",
    fontSize: "16px !important",
  },
  datepickersty: {
    // FK cleanup july 2023
    // "& .MuiInputBase-root":{
    //     width:'533px',
    //     backgroundColor:`${theme.palette.btnPrimary.light}`,
    //     marginTop:'10px'
    // },
    width: "0px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px !important",
    },
    "& .MuiInputAdornment-root ": {
      position: "absolute",
      top: "22px",
    },
  },
  grayText: {
    // FK cleanup july 2023
    color: "#787878",
  },
  link: {
    // FK cleanup july 2023

    fontFamily: "Roboto",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
    textDecoration: "none !important",
    color: "#1789FC!important",
    "& .css-1ps4owl-MuiTypography-root-MuiLink-root": {
      textDecoration: "none !important",
      color: "#1789FC !important",
    },
  },
  linkCursor: {
    // FK cleanup july 2023
    cursor: "pointer",
  },
  roundedCircle: {
    // FK cleanup july 2023
    height: "12px",
    width: "12px",
    margin: "2px 3px 2px 14px",
    border: "1px solid #C4C4C4",
    borderRadius: "50%",
  },
  redLabel: {
    // FK cleanup july 2023
    color: "#DD2E2E",
  },
  // graph meter container
  graphScoreMeterContainer: {
    position: "relative",
    height: "368px",
    border: "24px solid #FFFBF6",
    borderRadius: "4px",
  },
  // graph meter canvas
  graphScoreMeterCanvas: {
    position: "relative",
    height: "320px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // upto 900px mobile view container
  mobileGraphScoreMeterContainer: {
    // minWidth: "500px",
    position: "relative",
    border: "24px solid #FFFBF6",
    borderRadius: "4px",
  },
  // upto 900px mobile view canvas
  mobileGraphScoreMeterCanvas: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "350px",
  },
  leftSideContaint: {
    color: "#2F2F2F !important",
    fontStyle: "normal !important",
    fontWeight: "500  !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    marginTop: "8px !important",
  },
  leftSideContaint1: {
    color: "#2F2F2F !important",
    fontStyle: "normal !important",
    // fontWeight: "500  !important",
    fontSize: "14px !important",
    lineHeight: "28px",
    marginTop: "8px !important",
    fontFamily: "Roboto !important",
  },
  linkStyles: {
    textDecoration: "none !important",
    cursor: "pointer !important",
    color: "black",
    textOverflow: "ellipsis !important",
    overflow: "hidden",
    display: "block",
  },
  muiBtn: {
    textTransform: "none !important",
    color: "gray !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    borderRadius: "4px !important",
    // '&:hover': {
    //   backgroundColor: '#1789FC !important',
    // },
  },
  muiBtnActive: {
    textTransform: "none !important",
    color: "primary !important",
    fontSize: "20px !important",
    fontWeight: "500 !important",
    borderRadius: "4px !important",
  },
  notAvailable: {
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    padding: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: "20vh",
  },
});

const ExperienceCards = ({ weDetails, skillsMaster }) => {
  const classes = useStyles();
  const [newSkillData, setNewSkillData] = useState([]);

  // full name of skills from skillsMaster
  useEffect(() => {
    let newSkillDataArr = [];
    // eslint-disable-next-line
    weDetails?.map((position) => {
      let newSkillArr = [];
      position?.skills.forEach((skill) => {
        newSkillArr.push({
          skill,
          fullName: skillsMaster[skill]?.fullName,
          category: skillsMaster[skill]?.category,
        });
      });

      newSkillArr?.sort((a, b) => {
        // Convert both fullNames to lowercase to ensure case-insensitive sorting
        const fullNameA = a.fullName;
        const fullNameB = b.fullName;

        // Compare the fullNames and return the comparison result
        if (fullNameA < fullNameB) return -1;
        if (fullNameA > fullNameB) return 1;
        return 0;
      });

      position.skillArrObject = newSkillArr;
      newSkillDataArr.push(position);
    });
    setNewSkillData(newSkillDataArr);
  }, [weDetails, skillsMaster]);

  // colors to use for skills

  return weDetails?.length ? (
    <>
      <Grid
        item
        xs={12}
        style={{ marginTop: "25px", marginBottom: "25px", display: "flex", justifyContent: "space-between" }}
      >
        {/* <Typography variant="h6">Work Experience</Typography> */}
      </Grid>
      {newSkillData?.map((position) => (
        <Box
          style={{
            border: "solid 1px #C4C4C4",
            borderRadius: "8px",
            width: "100%",
            marginBottom: "25px",
          }}
        >
          <Grid container style={{ marginTop: "5px" }}>
            <Grid item xs={8} style={{ marginTop: "3px" }}>
              <Typography className={classes.roletext1} sx={{ marginLeft: "20px" }}>
                {position?.title !== "" ? `${position?.title},` : "Position Not Available,"}{" "}
                <span style={{ fontWeight: "500" }}>
                  {position?.company !== "" ? `${position?.company}` : "Company Not Available"}
                </span>
                {position?.location !== "" ? `, ${position?.location}` : "."}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ marginTop: "3px" }}>
              <Typography className={classes.roletext1} sx={{ textAlign: "center" }}>
                {position?.startDate !== "" ? position?.startDate : "Start Date NA"} -{" "}
                {position?.endDate !== "" ? position?.endDate : "End Date NA"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8} style={{ marginTop: "3px" }}>
              <Typography className={classes.leftSideContaint1} sx={{ marginLeft: "0px" }}>
                <ul>
                  {position?.itemList?.map((item) => (
                    <li style={{ lineHeight: "14px !important", marginTop: "7px" }}>{item} </li>
                  ))}
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={4} spacing={1} style={{ marginTop: "3px", height: "100% !importent" }}>
              {/* crousel for skills */}
              <SwipeableTextStepper skillArrObject={position?.skillArrObject} />
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  ) : (
    <div style={{ marginBottom: "25px" }}>
      <Grid
        item
        xs={12}
        style={{ marginTop: "25px", marginBottom: "25px", display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h6">Work Experience</Typography>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20px",
        }}
        className={classes.roletype}
      >
        <span>
          <BlockIcon />
        </span>{" "}
        <Typography sx={{ marginLeft: "5px", marginBottom: "13px" }} className={classes.roletype}>
          Work Experience Not Available.
        </Typography>
      </div>
    </div>
  );
};

export default ExperienceCards;
