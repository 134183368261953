import authTypes from "../types/auth";

function GetLogo(payload) {
  return {
    type: authTypes.GET_LOGO,
    payload,
  };
}

function LogoSuccess(payload) {
  return {
    type: authTypes.LOGO_SUCCESS,
    payload,
  };
}

function LoginUser(payload) {
  return {
    type: authTypes.LOGIN_USER,
    payload,
  };
}

function CandidateLogin(payload) {
  return {
    type: authTypes.CANDIDATE_LOGIN,
    payload,
  };
}

function LoginUserSuccess(payload) {
  return {
    type: authTypes.LOGIN_USER_SUCCESS,
    payload,
  };
}

function loginUserError(payload) {
  return {
    type: authTypes.LOGIN_USER_ERROR,
    payload,
  };
}

function SignUpUser(payload) {
  return {
    type: authTypes.SIGNUP_USER,
    payload,
  };
}

function SignUpUserSuccess(payload) {
  return {
    type: authTypes.SIGNUP_USER_SUCCESS,
    payload,
  };
}

function SignUpUserFailure(payload) {
  return {
    type: authTypes.SIGNUP_USER_FAILURE,
    payload,
  };
}

function AcceptInvitation(payload) {
  return {
    type: authTypes.ACCEPT_INVITATION,
    payload,
  };
}

function AcceptInvitationSuccess(payload) {
  return {
    type: authTypes.ACCEPT_INVITATION_SUCCESS,
    payload,
  };
}

function AcceptInvitationFailure(payload) {
  return {
    type: authTypes.ACCEPT_INVITATION_FAILURE,
    payload,
  };
}

function ForgetPassword(payload) {
  return {
    type: authTypes.FORGET_PASSWORD,
    payload,
  };
}

function ForgetPasswordSuccess(payload) {
  return {
    type: authTypes.FORGET_PASSWORD_SUCCESS,
    payload,
  };
}

function ForgetPasswordFailure(payload) {
  return {
    type: authTypes.FORGET_PASSWORD_FAILURE,
    payload,
  };
}

function ResetPassword(payload) {
  return {
    type: authTypes.RESET_PASSWORD,
    payload,
  };
}

function ResetPasswordSuccess(payload) {
  return {
    type: authTypes.RESET_PASSWORD_SUCCESS,
    payload,
  };
}

function ResetPasswordFailure(payload) {
  return {
    type: authTypes.RESET_PASSWORD_FAILURE,
    payload,
  };
}

function recruiturmentList(payload) {
  return {
    type: authTypes.RECRUITURMENT_LIST,
    payload,
  };
}

function recruiturmentListSuccess(payload) {
  return {
    type: authTypes.RECRUITURMENT_LIST_SUCCESS,
    payload,
  };
}

function recruiturmentListTotal(payload) {
  return {
    type: authTypes.RECRUITER_LIST_TOTAL,
    payload,
  };
}
function addRecruiturment(payload) {
  return {
    type: authTypes.ADD_RECRUITURMENT,
    payload,
  };
}

function addRecruiturmentSuccess(payload) {
  return {
    type: authTypes.ADD_RECRUITURMENT_SUCCESS,
    payload,
  };
}

function candidateList(payload) {
  return {
    type: authTypes.CANDIDATE_LIST,
    payload,
  };
}

function candidateListSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_LIST_SUCCESS,
    payload,
  };
}

function candidatelistRest(payload) {
  return {
    type: authTypes.CANDIDATE_LIST_RESET,
    payload,
  };
}

function addCandidate(payload) {
  return {
    type: authTypes.ADD_CANDIDATE,
    payload,
  };
}
function addCandidateSuccess(payload) {
  return {
    type: authTypes.ADD_CANDIDATE_SUCCESS,
    payload,
  };
}

function editCandidate(payload) {
  return {
    type: authTypes.EDIT_CANDIDATE,
    payload,
  };
}
function editCandidateSuccess(payload) {
  return {
    type: authTypes.EDIT_CANDIDATE_SUCCESS,
    payload,
  };
}
function editRecruiter(payload) {
  return {
    type: authTypes.EDIT_RECRUITER,
    payload,
  };
}
function editRecruiterSuccess(payload) {
  return {
    type: authTypes.EDIT_RECRUITER_SUCCESS,
    payload,
  };
}
function deleteCandidate(payload) {
  return {
    type: authTypes.DELETE_CANDIDATE,
    payload,
  };
}
function deleteCandidateSuccess(payload) {
  return {
    type: authTypes.DELETE_CANDIDATE_SUCCESS,
    payload,
  };
}

function deleteRecruiter(payload) {
  return {
    type: authTypes.DELETE_RECRUTER,
    payload,
  };
}

function deleteRecruiterSuccess(payload) {
  return {
    type: authTypes.DELETE_RECRUTER_SUCCESS,
    payload,
  };
}
function updateQuizLevel(payload) {
  return {
    type: authTypes.UPDATE_LEVEL,
    payload,
  };
}

function setCodingTheme(payload) {
  return {
    type: authTypes.SET_CODING_THEME,
    payload,
  };
}
function resumeUpload(payload) {
  return {
    type: authTypes.RESUME_UPLOAD,
    payload,
  };
}

function deleteResume(payload) {
  return {
    type: authTypes.DELETE_RESUME,
    payload,
  };
}

function resumeUploadRequested(resumeRequest) {
  return {
    type: authTypes.RESUME_UPLOAD_REQUESTED,
    payload: resumeRequest,
  };
}

function resumeUploadError(payload) {
  return {
    type: authTypes.RESUME_UPLOAD_FAILURE,
    payload,
  };
}
function deleteResumeRequested(payload) {
  return {
    type: authTypes.DELETE_RESUME_REQUESTED,
    payload,
  };
}

function deleteResumeSuccess(payload) {
  return {
    type: authTypes.DELETE_RESUME_SUCCESS,
    payload,
  };
}

function setCodingLang(payload) {
  return {
    type: authTypes.SET_CODING_LANG,
    payload,
  };
}
function testCompleted(payload) {
  return {
    type: authTypes.TEST_COMPLETED,
    payload,
  };
}
function testCompletedSuccess(payload) {
  return {
    type: authTypes.TEST_COMPLETED,
    payload,
  };
}

function candidateDetails(payload) {
  return {
    type: authTypes.CANDIDATE_DETAILS,
    payload,
  };
}
function candidateDetailsSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_DETAILS_SUCCESS,
    payload,
  };
}
function candidateDetailsFailure(payload) {
  return {
    type: authTypes.CANDIDATE_DETAILS_FAILURE,
    payload,
  };
}

function candidateProfile(payload) {
  return {
    type: authTypes.CANDIDATE_PROFILE,
    payload,
  };
}
function candidateProfileSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_PROFILE_SUCCESS,
    payload,
  };
}
function recruiterDetails(payload) {
  return {
    type: authTypes.RECRUITER_DETAILS,
    payload,
  };
}
function recruiterDetailsSuccess(payload) {
  return {
    type: authTypes.RECRUITER_DETAILS_SUCCESS,
    payload,
  };
}
function recruiterDetailsFailure(payload) {
  return {
    type: authTypes.RECRUITER_DETAILS_FAILURE,
    payload,
  };
}
function setProfile(payload) {
  return {
    type: authTypes.GOT_PROFILE,
    payload,
  };
}
function updateUserExists(payload) {
  return {
    type: authTypes.USER_EXISTS,
    payload,
  };
}
function activateCandidate(payload) {
  return {
    type: authTypes.ACTIVATE_CANDIDATE,
    payload,
  };
}
function activateCandidateSuccess(payload) {
  return {
    type: authTypes.ACTIVATE_CANDIDATE_SUCCESS,
    payload,
  };
}
function activateRecruiter(payload) {
  return {
    type: authTypes.ACTIVATE_RECRUITER,
    payload,
  };
}
function activateRecruiterSuccess(payload) {
  return {
    type: authTypes.ACTIVATE_RECRUITER_SUCCESS,
    payload,
  };
}

// function recruiturmentFilter(payload){
//   return {
//     type:authTypes.RECRUITER_FILTER,
//     payload
//   }
// }
// function recruiturmentFilterSuccess(payload){
//   return{
//     type:authTypes.RECRUITER_FILTER_SUCCESS,
//     payload
//   }
// }

function recruitmentDashboard(payload) {
  return {
    type: authTypes.RECRUITER_DASHBOARD,
    payload,
  };
}
function recruitmentDashboardSuccess(payload) {
  return {
    type: authTypes.RECRUITER_DASHBOARD_SUCCESS,
    payload,
  };
}

function recruiturmentRest(payload) {
  return {
    type: authTypes.RECRUITURMENT_LIST_RESET,
  };
}

function candidateTotalCount(payload) {
  return {
    type: authTypes.CANDIDATE_TOTAL_COUNT,
    payload,
  };
}
function candidateMyTotal(payload) {
  return {
    type: authTypes.CANDIDATE_MY_COUNT,
    payload,
  };
}
function candidateAllTotal(payload) {
  return {
    type: authTypes.CANDIDATE_ALL_COUNT,
    payload,
  };
}

// function candidateFilter(payload){
//   return{
//     type:authTypes.CANDIDATE_FILTER,
//     payload
//   }
// }
// function candidateFilterSuccess(payload){
//   return{
//     type:authTypes.CANDIDATE_FILTER_SUCCESS,
//     payload
//   }
// }

function candidateUploadResume(payload) {
  return {
    type: authTypes.CANDIDATE_UPLOAD_RESUME,
    payload,
  };
}
function candidateUploadResumeSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_UPLOAD_RESUME_SUCCESS,
    payload,
  };
}
function candidategenerateReport(payload) {
  return {
    type: authTypes.CANDIDATE_REPORT,
    payload,
  };
}
function candidategenerateReportSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_REPORT_SUCCESS,
  };
}
function candidateSearchByEmail(payload) {
  return {
    type: authTypes.CANDIDATE_SEARCH_BY_EMAIL,
    payload,
  };
}
function candidateSearchByEmailSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_SEARCH_BY_EMAIL_SUCCESS,
    payload,
  };
}
function candidateRemoveResume(payload) {
  return {
    type: authTypes.REMOVE_RESUME,
    payload,
  };
}
function candidateRemoveResumeSuccess(payload) {
  return {
    type: authTypes.REMOVE_RESUME_SUCCESS,
    payload,
  };
}

function downloadResume(payload) {
  return {
    type: authTypes.DOWNLOAD_RESUME,
    payload,
  };
}

function downloadResumeSuccess(payload) {
  return {
    type: authTypes.DOWNLOAD_RESUME_SUCCESS,
    payload,
  };
}

function uploadProfile(payload) {
  return {
    type: authTypes.UPLOAD_PROFILE,
    payload,
  };
}

function uploadProfileSuccess(payload) {
  return {
    type: authTypes.UPLOAD_PROFILE_SUCCESS,
    payload,
  };
}
function getAoSI(payload) {
  return {
    type: authTypes.GET_AOSI,
    payload,
  };
}

function updateAoSI(payload) {
  return {
    type: authTypes.GET_AOSI_SUCCESS,
    payload,
  };
}

function clearState() {
  return {
    type: authTypes.CLEAR_STATE,
  };
}
function apiError(payload) {
  return {
    type: authTypes.API_ERROR,
    payload,
  };
}

function getCandidateDPUrl(payload) {
  return {
    type: authTypes.CANDIDATE_DP,
    payload,
  };
}

function candidateDPUrlResponse(payload) {
  return {
    type: authTypes.CANDIDATE_DPURL_SUCCESS,
    payload,
  };
}

function resetCandidateDPUrl() {
  return {
    type: authTypes.CANDIDATE_DPURL_RESET,
  };
}

function fetchDetailsFromResume(payload) {
  return {
    type: authTypes.CANDIDATE_FETCH_RESUME_DETAILS,
    payload,
  };
}

function fetchDetailsFromResumeSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_FETCH_RESUME_DETAILS_SUCCESS,
    payload,
  };
}

function resumeScanDetails(payload) {
  return {
    type: authTypes.CANDIDATE_RESUME_SCAN_DETAILS,
    payload,
  };
}

function resumeScanDetailsSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_RESUME_SCAN_DETAILS_SUCCESS,
    payload,
  };
}

function checkInvitationStatus(payload) {
  return {
    type: authTypes.CHECK_INVITATION_STATUS,
    payload,
  };
}

function checkInvitationStatusSuccess(payload) {
  return {
    type: authTypes.CHECK_INVITATION_STATUS_SUCCESS,
    payload,
  };
}

function checkResetPasswordToken(payload) {
  return {
    type: authTypes.CHECK_RESET_PASSWORD_TOKEN,
    payload,
  };
}

function checkResetPasswordTokenSuccess(payload) {
  return {
    type: authTypes.CHECK_RESET_PASSWORD_TOKEN_SUCCESS,
    payload,
  };
}

function checkValidationStatus(payload) {
  return {
    type: authTypes.CHECK_VALIDATION_STATUS,
    payload,
  };
}

function checkValidationStatusSuccess(payload) {
  return {
    type: authTypes.CHECK_VALIDATION_STATUS_SUCCESS,
    payload,
  };
}

function createUserPassword(payload) {
  return {
    type: authTypes.CREATE_USER_PASSWORD,
    payload,
  };
}

function createUserPasswordSuccess(payload) {
  return {
    type: authTypes.CREATE_USER_PASSWORD_SUCCESS,
    payload,
  };
}

function createPassword(payload) {
  return {
    type: authTypes.CREATE_PASSWORD,
    payload,
  };
}

function createPasswordSuccess(payload) {
  return {
    type: authTypes.CREATE_PASSWORD_SUCCESS,
    payload,
  };
}

function validateEmail(payload) {
  return {
    type: authTypes.VALIDATE_EMAIL,
    payload,
  };
}

function validateEmailSuccess(payload) {
  return {
    type: authTypes.VALIDATE_EMAIL_SUCCESS,
    payload,
  };
}

function validateEmailFailure(payload) {
  return {
    type: authTypes.VALIDATE_EMAIL_FAILURE,
    payload,
  };
}

function AcceptValidation(payload) {
  return {
    type: authTypes.ACCEPT_VALIDATION,
    payload,
  };
}

function AcceptValidationSuccess(payload) {
  return {
    type: authTypes.ACCEPT_VALIDATION_SUCCESS,
    payload,
  };
}

function AcceptValidationFailure(payload) {
  return {
    type: authTypes.ACCEPT_VALIDATION_FAILURE,
    payload,
  };
}

function resendInvitation(payload) {
  return {
    type: authTypes.RESEND_INVITATION,
    payload,
  };
}

function resendInvitationSuccess(payload) {
  return {
    type: authTypes.RESEND_INVITATION_SUCCESS,
    payload,
  };
}

function clearResendInvitationSuccess(payload) {
  return {
    type: "CLEAR_RESEND_INVITATION_SUCCESS",
    payload,
  };
}

function resendInvitationFailure(payload) {
  return {
    type: authTypes.RESEND_INVITATION_FAILURE,
    payload,
  };
}

// function checkEntityStatus(payload) {
//   return {
//     type: authTypes.CHECK_ENTITY_STATUS,
//     payload
//   }
// }

// function checkEntityStatusSuccess(payload) {
//   return {
//     type: authTypes.CHECK_ENTITY_STATUS_SUCCESS,
//     payload
//   }
// }

function checkSubscriptionStatusCandidate(payload) {
  return {
    type: authTypes.CHECK_SUBSCRIPTION_STATUS_CANDIDATE,
    payload,
  };
}

function checkSubscriptionStatusCandidateSuccess(payload) {
  return {
    type: authTypes.CHECK_SUBSCRIPTION_STATUS_CANDIDATE_SUCCESS,
    payload,
  };
}

function checkSubscriptionStatusInvite(payload) {
  return {
    type: authTypes.CHECK_SUBSCRIPTION_STATUS_INVITE,
    payload,
  };
}

function checkSubscriptionStatusInviteSuccess(payload) {
  return {
    type: authTypes.CHECK_SUBSCRIPTION_STATUS_INVITE_SUCCESS,
    payload,
  };
}

function checkSubscriptionStatusRole(payload) {
  return {
    type: authTypes.CHECK_SUBSCRIPTION_STATUS_ROLE,
    payload,
  };
}

function checkSubscriptionStatusRoleSuccess(payload) {
  return {
    type: authTypes.CHECK_SUBSCRIPTION_STATUS_ROLE_SUCCESS,
    payload,
  };
}

function checkSubscriptionStatusUser(payload) {
  return {
    type: authTypes.CHECK_SUBSCRIPTION_STATUS_USER,
    payload,
  };
}

function checkSubscriptionStatusUserSuccess(payload) {
  return {
    type: authTypes.CHECK_SUBSCRIPTION_STATUS_USER_SUCCESS,
    payload,
  };
}

function changePassword(payload) {
  return {
    type: authTypes.CHANGE_PASSWORD,
    payload,
  };
}

function changePasswordSuccess(payload) {
  return {
    type: authTypes.CHANGE_PASSWORD_SUCCESS,
    payload,
  };
}

function changePasswordFailure(payload) {
  return {
    type: authTypes.CHANGE_PASSWORD_FAILURE,
    payload,
  };
}

function SignUpPlans(payload) {
  return {
    type: authTypes.SIGNUP_PLANS,
    payload,
  };
}

function SignUpPlansSuccess(payload) {
  return {
    type: authTypes.SIGNUP_PLANS_SUCCESS,
    payload,
  };
}

function SignUpPlansFailure(payload) {
  return {
    type: authTypes.SIGNUP_PLANS_FAILURE,
    payload,
  };
}

function resetChangePasswordSuccess() {
  return {
    type: authTypes.RESET_CHANGE_PASSWORD_SUCCESS,
  };
}

function createPaymentLink(payload) {
  return {
    type: authTypes.CREATE_PAYMENT_LINK,
    payload,
  };
}

function createPaymentLinkSuccess(payload) {
  return {
    type: authTypes.CREATE_PAYMENT_LINK_SUCCESS,
    payload,
  };
}

function createPaymentLinkFailure(payload) {
  return {
    type: authTypes.CREATE_PAYMENT_LINK_FAILURE,
    payload,
  };
}
function managementDetails(payload) {
  return {
    type: authTypes.MANAGEMENT_DETAILS,
    payload,
  };
}

function managementDetailsSuccess(payload) {
  return {
    type: authTypes.MANAGEMENT_DETAILS_SUCCESS,
    payload,
  };
}

function managementDetailsFailure(payload) {
  return {
    type: authTypes.MANAGEMENT_DETAILS_FAILURE,
    payload,
  };
}

function updateOrgDetails(payload) {
  return {
    type: authTypes.UPDATE_ORG_DETAILS,
    payload,
  };
}

function updateOrgDetailsSuccess(payload) {
  return {
    type: authTypes.UPDATE_ORG_DETAILS_SUCCESS,
    payload,
  };
}

function updateOrgDetailsFailure(payload) {
  return {
    type: authTypes.UPDATE_ORG_DETAILS_FAILURE,
    payload,
  };
}

function resetOrgDetailsSuccess() {
  return {
    type: authTypes.RESET_ORG_DETAILS_SUCCESS,
  };
}

function updateUserDetails(payload) {
  return {
    type: authTypes.UPDATE_USER_DETAILS,
    payload,
  };
}

function updateUserDetailsSuccess(payload) {
  return {
    type: authTypes.UPDATE_USER_DETAILS_SUCCESS,
    payload,
  };
}

function updateUserDetailsFailure(payload) {
  return {
    type: authTypes.UPDATE_USER_DETAILS_FAILURE,
    payload,
  };
}

function resetUserDetailsSuccess() {
  return {
    type: authTypes.RESET_USER_DETAILS_SUCCESS,
  };
}

function resendEmailValidation(payload) {
  return {
    type: authTypes.RESEND_EMAIL_VALIDATION,
    payload,
  };
}

function resendEmailValidationSuccess(payload) {
  return {
    type: authTypes.RESEND_EMAIL_VALIDATION_SUCCESS,
    payload,
  };
}

function resendEmailValidationFailure(payload) {
  return {
    type: authTypes.RESEND_EMAIL_VALIDATION_FAILURE,
    payload,
  };
}

function creatCheckoutSession(payload) {
  return {
    type: authTypes.CREATE_CHECKOUT_SESSION,
    payload,
  };
}

function creatCheckoutSessionSuccess(payload) {
  return {
    type: authTypes.CREATE_CHECKOUT_SESSION_SUCCESS,
    payload,
  };
}

function creatCheckoutSessionFailure(payload) {
  return {
    type: authTypes.CREATE_CHECKOUT_SESSION_FAILURE,
    payload,
  };
}

function createBillingPortal(payload) {
  return {
    type: authTypes.CREATE_BILLING_PORTAL,
    payload,
  };
}

function createBillingPortalSuccess(payload) {
  return {
    type: authTypes.CREATE_BILLING_PORTAL_SUCCESS,
    payload,
  };
}

function createBillingPortalFailure(payload) {
  return {
    type: authTypes.CREATE_BILLING_PORTAL_FAILURE,
    payload,
  };
}

function deleteInvitedUser(payload) {
  return {
    type: authTypes.DELETE_INVITED_USER,
    payload,
  };
}

function deleteInvitedUserSuccess(payload) {
  return {
    type: authTypes.DELETE_INVITED_USER_SUCCESS,
    payload,
  };
}

function deleteInvitedUserFailure(payload) {
  return {
    type: authTypes.DELETE_INVITED_USER_FAILURE,
    payload,
  };
}

function BillingPagePlans(payload) {
  return {
    type: authTypes.BILLING_PAGE_PLANS,
    payload,
  };
}

function BillingPagePlansSuccess(payload) {
  return {
    type: authTypes.BILLING_PAGE_PLANS_SUCCESS,
    payload,
  };
}

function BillingPagePlansFailure(payload) {
  return {
    type: authTypes.BILLING_PAGE_PLANS_FAILURE,
    payload,
  };
}

function apiBillingPortalForInvoiceHistory(payload) {
  return {
    type: authTypes.API_BILLING_PORTAL_FOR_INVOICE_HISTORY,
    payload,
  };
}

function apiBillingPortalForInvoiceHistorySuccess(payload) {
  return {
    type: authTypes.API_BILLING_PORTAL_FOR_INVOICE_HISTORY_SUCCESS,
    payload,
  };
}

function apiBillingPortalForInvoiceHistoryFailure(payload) {
  return {
    type: authTypes.API_BILLING_PORTAL_FOR_INVOICE_HISTORY_FAILURE,
    payload,
  };
}

function invoiceHistoryList(payload) {
  return {
    type: authTypes.INVOICE_HISTORY_LIST,
    payload,
  };
}

function invoiceHistoryListSuccess(payload) {
  return {
    type: authTypes.INVOICE_HISTORY_LIST_SUCCESS,
    payload,
  };
}

function invoiceHistoryListFailure(payload) {
  return {
    type: authTypes.INVOICE_HISTORY_LIST_FAILURE,
    payload,
  };
}

function getIpAddress(payload) {
  return {
    type: authTypes.GET_IP_ADDRESS,
    payload,
  };
}

function getIpAddressSuccess(payload) {
  return {
    type: authTypes.GET_IP_ADDRESS_SUCCESS,
    payload,
  };
}

function getIpAddressFailure(payload) {
  return {
    type: authTypes.GET_IP_ADDRESS_FAILURE,
    payload,
  };
}

function setSkillsConfig(payload) {
  return {
    type: authTypes.SET_SKILLS_CONFIG,
    payload,
  };
}

function getConfigSkills(payload) {
  return {
    type: authTypes.GET_CONFIG_SKILLS,
    payload,
  };
}

function getConfigSkillsFailure(payload) {
  return {
    type: authTypes.GET_CONFIG_SKILLS_FAILURE,
    payload,
  };
}

function bookademo(payload) {
  return {
    type: authTypes.BOOK_A_DEMO,
    payload,
  };
}

function bookademoSuccess(payload) {
  return {
    type: authTypes.BOOK_A_DEMO_SUCCESS,
    payload,
  };
}

function bookademoFailure(payload) {
  return {
    type: authTypes.BOOK_A_DEMO_FAILURE,
    payload,
  };
}

function editAndAddResume(payload) {
  return {
    type: authTypes.EDIT_AND_ADD_RESUME,
    payload,
  };
}

function editAndAddResumeSuccess(payload) {
  return {
    type: authTypes.EDIT_AND_ADD_RESUME_SUCCESS,
    payload,
  };
}

function editAndAddResumeFailure(payload) {
  return {
    type: authTypes.EDIT_AND_ADD_RESUME_FAILURE,
    payload,
  };
}

function addCompletedResume(payload) {
  return {
    type: authTypes.ADD_COMPLETED_RESUME,
    payload,
  };
}

function addCompletedResumeSuccess(payload) {
  return {
    type: authTypes.ADD_COMPLETED_RESUME_SUCCESS,
    payload,
  };
}

function addCompletedResumeFailure(payload) {
  return {
    type: authTypes.ADD_COMPLETED_RESUME_FAILURE,
    payload,
  };
}

function uploadBulkResume(payload) {
  return {
    type: authTypes.UPLOAD_BULK_RESUME,
    payload,
  };
}

function uploadBulkResumeSuccess(payload) {
  return {
    type: authTypes.UPLOAD_BULK_RESUME_SUCCESS,
    payload,
  };
}

function clearUploadBulkResumeSuccess() {
  return {
    type: authTypes.CLEAR_UPLOAD_BULK_RESUME_SUCCESS,
  };
}

function uploadBulkResumeFailure(payload) {
  return {
    type: authTypes.UPLOAD_BULK_RESUME_FAILURE,
    payload,
  };
}

function getPendingResumeList(payload) {
  return {
    type: authTypes.GET_PENDING_RESUME_LIST,
    payload,
  };
}

function getPendingResumeListSuccess(payload) {
  return {
    type: authTypes.GET_PENDING_RESUME_LIST_SUCCESS,
    payload,
  };
}

function getPendingResumeListFailure(payload) {
  return {
    type: authTypes.GET_PENDING_RESUME_LIST_FAILURE,
    payload,
  };
}

function deleteResumeDoc(payload) {
  return {
    type: authTypes.DELETE_RESUME_DOC,
    payload,
  };
}

function deleteResumeDocSuccess(payload) {
  return {
    type: authTypes.DELETE_RESUME_DOC_SUCCESS,
    payload,
  };
}

function deleteResumeDocFailure(payload) {
  return {
    type: authTypes.DELETE_RESUME_DOC_FAILURE,
    payload,
  };
}

function matchCandidates(payload) {
  return {
    type: authTypes.MATCH_CANDIDATES,
    payload,
  };
}

function matchCandidatesSuccess(payload) {
  return {
    type: authTypes.MATCH_CANDIDATES_SUCCESS,
    payload,
  };
}

function matchCandidatesFailure(payload) {
  return {
    type: authTypes.MATCH_CANDIDATES_FAILURE,
    payload,
  };
}

function closeInvite(payload) {
  return {
    type: authTypes.CLOSE_INVITE,
    payload,
  };
}

function closeInviteSuccess(payload) {
  return {
    type: authTypes.CLOSE_INVITE_SUCCESS,
    payload,
  };
}

function closeInviteFailure(payload) {
  return {
    type: authTypes.CLOSE_INVITE_FAILURE,
    payload,
  };
}

function candidateRoleListForNewInvite(payload) {
  return {
    type: authTypes.CANDIDATE_ROLE_LIST_FOR_NEW_INVITE,
    payload,
  };
}

function candidateRoleListForNewInviteSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_ROLE_LIST_FOR_NEW_INVITE_SUCCESS,
    payload,
  };
}

function candidateRoleListForNewInviteFailure(payload) {
  return {
    type: authTypes.CANDIDATE_ROLE_LIST_FOR_NEW_INVITE_FAILURE,
    payload,
  };
}

function getCandidateWeSummary(payload) {
  return {
    type: authTypes.GET_CANDIDATE_WE_SUMMARY,
    payload,
  };
}

function getCandidateWeSummarySuccess(payload) {
  return {
    type: authTypes.GET_CANDIDATE_WE_SUMMARY_SUCCESS,
    payload,
  };
}

function getCandidateWeSummaryFailure(payload) {
  return {
    type: authTypes.GET_CANDIDATE_WE_SUMMARY_FAILURE,
    payload,
  };
}

function candidateMatchingListForJobrole(payload) {
  return {
    type: authTypes.CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE,
    payload,
  };
}

function candidateMatchingListForJobroleSuccess(payload) {
  return {
    type: authTypes.CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE_SUCCESS,
    payload,
  };
}

function candidateMatchingListForJobroleFailure(payload) {
  return {
    type: authTypes.CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE_FAILURE,
    payload,
  };
}

function checkCandidateInviteForRole(payload) {
  return {
    type: authTypes.CHECK_CANDIDATE_INVITE_FOR_ROLE,
    payload,
  };
}

function checkCandidateInviteForRoleSuccess(payload) {
  return {
    type: authTypes.CHECK_CANDIDATE_INVITE_FOR_ROLE_SUCCESS,
    payload,
  };
}

function checkCandidateInviteForRoleFailure(payload) {
  return {
    type: authTypes.CHECK_CANDIDATE_INVITE_FOR_ROLE_FAILURE,
    payload,
  };
}

function getMaintenanceDetails(payload) {
  return {
    type: authTypes.GET_MAINTENANCE_DETAILS,
    payload,
  };
}

function getMaintenanceDetailsSuccess(payload) {
  return {
    type: authTypes.GET_MAINTENANCE_DETAILS_SUCCESS,
    payload,
  };
}

function getMaintenanceDetailsFailure(payload) {
  return {
    type: authTypes.GET_MAINTENANCE_DETAILS_FAILURE,
    payload,
  };
}

function createTicket(payload) {
  return {
    type: authTypes.CREATE_TICKET,
    payload,
  };
}

function createTicketSuccess(payload) {
  return {
    type: authTypes.CREATE_TICKET_SUCCESS,
    payload,
  };
}

function createTicketFailure(payload) {
  return {
    type: authTypes.CREATE_TICKET_FAILURE,
    payload,
  };
}

function clearRaiseIssueSuccess() {
  return {
    type: authTypes.CLEAR_RAISE_ISSUE_SUCCESS,
  };
}

function raiseIssue(payload) {
  return {
    type: authTypes.RAISE_ISSUE,
    payload,
  };
}

function raiseIssueSuccess(payload) {
  return {
    type: authTypes.RAISE_ISSUE_SUCCESS,
    payload,
  };
}

function raiseIssueFailure(payload) {
  return {
    type: authTypes.RAISE_ISSUE_FAILURE,
    payload,
  };
}

function validateFeatureAccess(payload) {
  return {
    type: authTypes.VALIDATE_FEATURE_ACCESS,
    payload,
  };
}

function validateFeatureAccessSuccess(payload) {
  return {
    type: authTypes.VALIDATE_FEATURE_ACCESS_SUCCESS,
    payload,
  };
}

function validateFeatureAccessFailure(payload) {
  return {
    type: authTypes.VALIDATE_FEATURE_ACCESS_FAILURE,
    payload,
  };
}

const auth = {
  GetLogo,
  LogoSuccess,
  LoginUser,
  CandidateLogin,
  LoginUserSuccess,
  loginUserError,
  SignUpUser,
  SignUpUserSuccess,
  SignUpUserFailure,
  AcceptInvitation,
  AcceptInvitationSuccess,
  AcceptInvitationFailure,
  ForgetPassword,
  ForgetPasswordSuccess,
  ForgetPasswordFailure,
  ResetPassword,
  ResetPasswordSuccess,
  ResetPasswordFailure,
  recruiturmentList,
  recruiturmentListSuccess,
  addRecruiturment,
  addRecruiturmentSuccess,
  candidateList,
  candidateListSuccess,
  addCandidate,
  addCandidateSuccess,
  editCandidate,
  editCandidateSuccess,
  editRecruiter,
  editRecruiterSuccess,
  deleteCandidate,
  deleteCandidateSuccess,
  deleteRecruiter,
  deleteRecruiterSuccess,
  updateQuizLevel,
  setCodingTheme,
  setCodingLang,
  resumeUpload,
  deleteResume,
  resumeUploadRequested,
  resumeUploadError,
  deleteResumeRequested,
  deleteResumeSuccess,
  testCompleted,
  testCompletedSuccess,
  candidateDetails,
  candidateDetailsSuccess,
  candidateDetailsFailure,
  candidateProfile,
  candidateProfileSuccess,
  recruiterDetails,
  recruiterDetailsSuccess,
  recruiterDetailsFailure,
  activateCandidate,
  activateCandidateSuccess,
  activateRecruiter,
  activateRecruiterSuccess,
  setProfile,
  updateUserExists,
  // recruiturmentFilter,
  // recruiturmentFilterSuccess,
  recruiturmentListTotal,
  recruitmentDashboard,
  recruitmentDashboardSuccess,
  recruiturmentRest,
  candidatelistRest,
  // candidateFilter,
  // candidateFilterSuccess,
  candidateUploadResume,
  candidateUploadResumeSuccess,
  candidategenerateReport,
  candidategenerateReportSuccess,
  candidateRemoveResume,
  candidateRemoveResumeSuccess,
  downloadResume,
  downloadResumeSuccess,
  uploadProfile,
  uploadProfileSuccess,
  getCandidateDPUrl,
  candidateDPUrlResponse,
  resetCandidateDPUrl,
  clearState,
  apiError,
  candidateTotalCount,
  candidateMyTotal,
  candidateAllTotal,
  candidateSearchByEmail,
  candidateSearchByEmailSuccess,
  getAoSI,
  updateAoSI,
  fetchDetailsFromResume,
  fetchDetailsFromResumeSuccess,
  resumeScanDetails,
  resumeScanDetailsSuccess,
  checkInvitationStatus,
  checkInvitationStatusSuccess,
  checkResetPasswordToken,
  checkResetPasswordTokenSuccess,
  checkValidationStatus,
  checkValidationStatusSuccess,
  createUserPassword,
  createUserPasswordSuccess,
  createPassword,
  createPasswordSuccess,
  validateEmail,
  validateEmailSuccess,
  validateEmailFailure,
  AcceptValidation,
  AcceptValidationSuccess,
  AcceptValidationFailure,
  resendInvitation,
  resendInvitationSuccess,
  resendInvitationFailure,
  clearResendInvitationSuccess,
  // checkEntityStatus,
  // checkEntityStatusSuccess,
  checkSubscriptionStatusCandidate,
  checkSubscriptionStatusCandidateSuccess,
  checkSubscriptionStatusInvite,
  checkSubscriptionStatusInviteSuccess,
  checkSubscriptionStatusRole,
  checkSubscriptionStatusRoleSuccess,
  checkSubscriptionStatusUser,
  checkSubscriptionStatusUserSuccess,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  SignUpPlans,
  SignUpPlansSuccess,
  SignUpPlansFailure,
  resetChangePasswordSuccess,
  createPaymentLink,
  createPaymentLinkSuccess,
  createPaymentLinkFailure,
  managementDetails,
  managementDetailsSuccess,
  managementDetailsFailure,
  updateOrgDetails,
  updateOrgDetailsSuccess,
  updateOrgDetailsFailure,
  resetOrgDetailsSuccess,
  updateUserDetails,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  resetUserDetailsSuccess,
  resendEmailValidation,
  resendEmailValidationSuccess,
  resendEmailValidationFailure,
  creatCheckoutSession,
  creatCheckoutSessionSuccess,
  creatCheckoutSessionFailure,
  createBillingPortal,
  createBillingPortalSuccess,
  createBillingPortalFailure,
  deleteInvitedUser,
  deleteInvitedUserSuccess,
  deleteInvitedUserFailure,
  BillingPagePlans,
  BillingPagePlansSuccess,
  BillingPagePlansFailure,
  apiBillingPortalForInvoiceHistory,
  apiBillingPortalForInvoiceHistorySuccess,
  apiBillingPortalForInvoiceHistoryFailure,
  invoiceHistoryList,
  invoiceHistoryListSuccess,
  invoiceHistoryListFailure,
  getIpAddress,
  getIpAddressSuccess,
  getIpAddressFailure,
  getConfigSkills,
  getConfigSkillsFailure,
  setSkillsConfig,
  bookademo,
  bookademoSuccess,
  bookademoFailure,
  editAndAddResume,
  editAndAddResumeSuccess,
  editAndAddResumeFailure,
  addCompletedResume,
  addCompletedResumeSuccess,
  addCompletedResumeFailure,

  uploadBulkResume,
  uploadBulkResumeSuccess,
  clearUploadBulkResumeSuccess,
  uploadBulkResumeFailure,
  getPendingResumeList,
  getPendingResumeListSuccess,
  getPendingResumeListFailure,
  deleteResumeDoc,
  deleteResumeDocSuccess,
  deleteResumeDocFailure,
  matchCandidates,
  matchCandidatesSuccess,
  matchCandidatesFailure,
  closeInvite,
  closeInviteSuccess,
  closeInviteFailure,
  candidateRoleListForNewInvite,
  candidateRoleListForNewInviteSuccess,
  candidateRoleListForNewInviteFailure,
  getCandidateWeSummary,
  getCandidateWeSummarySuccess,
  getCandidateWeSummaryFailure,
  candidateMatchingListForJobrole,
  candidateMatchingListForJobroleSuccess,
  candidateMatchingListForJobroleFailure,
  checkCandidateInviteForRole,
  checkCandidateInviteForRoleSuccess,
  checkCandidateInviteForRoleFailure,
  getMaintenanceDetails,
  getMaintenanceDetailsSuccess,
  getMaintenanceDetailsFailure,
  createTicket,
  createTicketSuccess,
  createTicketFailure,
  clearRaiseIssueSuccess,
  raiseIssue,
  raiseIssueSuccess,
  raiseIssueFailure,
  validateFeatureAccess,
  validateFeatureAccessSuccess,
  validateFeatureAccessFailure,
};

export default auth;
