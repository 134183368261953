import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, useMediaQuery } from "@mui/material";
import TextInput from "../../components/controls/TextField";
// import BayoneLogo from '../../assets/svg/bayonelogo.svg';
import * as Yup from "yup";
import { useFormik } from "formik";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import { useParams, useNavigate } from "react-router-dom";
import auth from "../../redux/actions/auth";
import quiz from "../../redux/actions/quiz";
import { useDispatch, useSelector } from "react-redux";
import { getDomainDetails } from "../../services/globalService";

const useStyles = makeStyles({
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "10px",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    // fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "right",
    // paddingTop: '20px',
    // paddingBottom: '20px'
  },
  submit: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

export default function CandidateLogin() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  //   const search = useLocation().search;
  //   const paramsInviteid = new URLSearchParams(search).get('uid');
  const [domainDetails, setDomainDetails] = useState({});
  const logoUrl = process.env.REACT_APP_ASSETS_URL;
  let { inviteId } = useParams();

  //   if(paramsInviteid) {
  //     inviteId = paramsInviteid;
  //     localStorage.setItem('loginMethod', 'old')
  //   }

  // Initial Render
  useEffect(() => {
    if (localStorage.getItem("uid")) {
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      //   let loginMethod = localStorage.getItem('loginMethod');
      let uid = localStorage.getItem("uid");
      if (localStorage.getItem("uid") && !localStorage.getItem("user_details")) {
        // Logout
        dispatch(auth.clearState());
        localStorage.removeItem("token1");
        localStorage.removeItem("userid");
        localStorage.removeItem("user_details");
        localStorage.removeItem("uid");
        localStorage.removeItem("loginMethod");
        localStorage.removeItem("logo");
        localStorage.removeItem("nextMaintenanceCheck");
        dispatch(quiz.clearState());

        navigate(`/invite/${uid}`);
      } else if (inviteId !== userDetails.inviteId) {
        // Logout
        dispatch(auth.clearState());
        localStorage.removeItem("token1");
        localStorage.removeItem("userid");
        localStorage.removeItem("user_details");
        localStorage.removeItem("uid");
        localStorage.removeItem("loginMethod");
        localStorage.removeItem("nextMaintenanceCheck");
        dispatch(quiz.clearState());

        if (userDetails.role === "Candidate") {
          navigate(`/invite/${uid}`);
        }
      } else {
        localStorage.setItem("uid", inviteId);
      }
    }
  }, []);

  // Handle the click of sign in .
  const onSubmit = (values) => {
    let payload = {
      uid: values.number,
      password: values.password,
      inviteId: inviteId,
      //   cl: paramsInviteid? 1:0
    };

    dispatch(auth.CandidateLogin(payload));
  };

  useEffect(() => {
    let value = localStorage.getItem("token1");
    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    if (userDetails?.redirectDomain) {
      setTimeout(() => {
        window.location.href = userDetails.url;
      }, 1000);
      localStorage.removeItem("token1");
      localStorage.removeItem("user_details");
      localStorage.removeItem("nextMaintenanceCheck");
    } else if (value && userDetails.role === "Candidate") {
      localStorage.setItem("uid", inviteId);
      setTimeout(() => {
        navigate("/candidatedashboard");
      }, 1000);
    }
    // eslint-disable-next-line
  }, [localStorage.getItem("token1")]);

  const initialValues = {
    email: "",
    number: "",
    password: "",
  };

  // Validtion for login
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format"),
    number: Yup.string().required("This field cannot be empty"),
    password: Yup.string().required("This field cannot be empty"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (inviteId) {
      formik.setFieldValue("number", inviteId);
    }
  }, []);

  // Extract subdomain info
  useEffect(() => {
    let domainName = getDomainDetails(window.location.href);
    dispatch(auth.GetLogo({ subdomain: domainName.subdomainFromUrl }));
    setDomainDetails(authStore.logoDetails);
  }, []);

  useEffect(() => {
    setDomainDetails(authStore.logoDetails);
  }, [authStore.logoDetails]);

  return (
    <Grid>
      <div className={`${classes.center} ${classes.logocls}`}>
        {domainDetails && domainDetails?.logo !== "" ? (
          <>
            <img height="40" width="157" src={`${logoUrl}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
            <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
          </>
        ) : (
          <>
            <img height="40" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
          </>
        )}
      </div>
      <Grid container display={"flex"} justifyContent={"center"}>
        <Grid item xs={isNotMobile ? 4 : 10} style={{ marginTop: "40px" }}>
          <Typography align="center" className={classes.textsty}>
            Log In
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Typography className={`${classes.roletype}`}>Invite ID</Typography>
            <TextInput
              name={"number"}
              placeholder="Invite ID"
              className={classes.field}
              id={"number"}
              onChange={formik.handleChange}
              error={formik.touched.number && formik.errors.number ? Boolean(formik.errors.number) : null}
              onBlur={formik.handleBlur}
              value={inviteId ? inviteId : formik.values.number}
              disabled={inviteId ? true : false}
            ></TextInput>
            {formik.touched.number && formik.errors.number ? (
              <div className="error">
                <Typography className={classes.errorty}>{formik.errors.number}</Typography>
              </div>
            ) : null}

            <Typography className={`${classes.roletype}`}>Access Code</Typography>
            <TextInput
              name="password"
              placeholder="Access Code"
              className={`${classes.field} parentclassname`}
              id="password"
              onChange={formik.handleChange}
              error={formik.touched.password && formik.errors.password ? Boolean(formik.errors.password) : null}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              type={showPassword ? "text" : "password"}
              passwordText={"PasswordIcon"}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            ></TextInput>
            {formik.touched.password && formik.errors.password ? (
              <div className="error">
                <Typography className={classes.errorty}>{formik.errors.password}</Typography>
              </div>
            ) : null}

            <Grid className={classes.submit}>
              <Button
                disabled={!(formik.isValid && formik.dirty && formik.values)}
                type="submit"
                data-rt-loginwithemail-signin="signin"
                color={!(formik.isValid && formik.dirty && formik.values) ? "disablebtn" : "btnPrimary"}
                width={"100%"}
                text="Sign In"
              ></Button>
            </Grid>
          </form>
          <Grid
            container
            className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
