import { call, put, takeLatest } from "redux-saga/effects";
import types from "../types/quiz";
import quiz from "../actions/quiz";
import auth from "../actions/auth";
import role from "../actions/role";
import axios from "../../services/axios";
import AppConfig from "../../constants/AppConfig";

function* getQuestions() {
  try {
    const data = yield call(axios.request, {
      url: `/qbank/?code=${AppConfig.code}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    const questions = data.data.qbank.filter((element) => {
      return element && element.Question_ID;
    });
    const config = data.data.config || "";
    const previousSession = data.data.previousSession || "";

    yield put(quiz.getQuestions(questions, config, previousSession));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* getL0Questions() {
  try {
    const data = yield call(axios.request, {
      url: `/qbank/?code=${AppConfig.l0QuestionCode}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    const questions = data.data.qbank.filter((element) => {
      return element && element.Question_ID;
    });

    const config = data.data.config || "";
    const previousSession = data.data.previousSession || "";

    yield put(quiz.getQuestions(questions, config, previousSession));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* getL1Questions({ payload }) {
  try {
    const data = yield call(axios.request, {
      url: `/qbank/?code=${AppConfig.l1QuestionCode}&inviteId=${payload}`,
      // params: {
      //   inviteId:payload
      // },
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    let questions = [];
    if (data.data.qbank) {
      questions = data.data.qbank.filter((element) => {
        return element && element.Question_ID;
      });
    }

    const config = data.data.config || "";
    const previousSession = data.data.previousSession || "";
    yield put(quiz.getQuestions(questions, config, previousSession));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* getL2Questions({ payload }) {
  try {
    const data = yield call(axios.request, {
      url: `/qbank/?code=${AppConfig.l2QuestionCode}&inviteId=${payload}`,
      // params: {
      //   inviteId:payload
      // },
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    const questions = data.data.qbank.filter((element) => {
      return element && element.Question_ID;
    });
    const config = data.data.config || "";
    const previousSession = data.data.previousSession || "";
    yield put(quiz.getQuestions(questions, config, previousSession));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* getL3PresentationQuestions({ payload }) {
  try {
    const data = yield call(axios.request, {
      url: `/qbank/?code=${AppConfig.l3QuestionCode}&inviteId=${payload}`,
      // params: {
      //   inviteId:payload
      // },
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    const questions = data.data.qbank.filter((element) => {
      return element && element.Question_ID;
    });
    const config = data.data.config || "";
    const previousSession = data.data.previousSession || "";

    yield put(quiz.getQuestions(questions, config, previousSession));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* getL3Questions({ payload }) {
  try {
    const data = yield call(axios.request, {
      url: `/qbank/?code=${AppConfig.l3QuestionCode}&inviteId=${payload}`,
      // params: {
      //   inviteId:payload
      // },
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    const questions = data.data.qbank.filter((element) => {
      return element && element.Question_ID;
    });

    const config = data.data.config || "";

    yield put(quiz.getCodingQuestions(questions, config));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* createSesssion({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: "/session",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(quiz.createSession(res.data));
    }
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* getTracking({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/tracking/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    // no need to wait for the response as per kenny
    if (res) {
      yield put(quiz.getTracking(res.data));
    }
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* submitCodingChallenge({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/codingChallenge/submit",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    if (res.status === 1) {
      // throw {message: res.message};
      yield put(quiz.codingChallengeStatus(res));
    }

    yield put(quiz.codingChallengeSubmitted(JSON.parse(res.data)["id"]));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* getCodingChallengeSubmissionDetail({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: "/codingChallenge/submit",
      params: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(quiz.updateCodingSubmissionDetail(res.data));
    if (res.status === 1) {
      // throw {message: res.message};
      yield put(quiz.updateCodingSubmissionStatus(res));
    }
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* submitTestResults({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "PUT",
      url: "/session/update",
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    yield put(quiz.testResultsSubmitted(res.data));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* submitCodingResults({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/codingChallenge/submitTestCases",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    yield put(quiz.testResultsSubmitted(res.data));
    if (res.status === 1) {
      yield put(quiz.testResultSubmitStatus(res));
    }
    yield put(role.inviteDetails(localStorage.getItem("uid")));
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* recreateUserAndSubmitResults({ testResult, userProfile, userMetaDataReq, targetRole }) {
  // debugger;
  let finalProfileData = {
    ...userProfile,
  };

  const payload = {
    email: userProfile.email,
    userMetaData: userMetaDataReq,
    userProfile: finalProfileData,
  };

  // console.log("recreateUserAndSubmitResults: ", arguments);
  const res = yield call(axios.request, {
    method: "POST",
    url: "/editProfileMajor",
    data: payload,
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token1"),
    },
  });
  yield put(auth.setProfile(res.data[0]));
}

function* updateCodingCompileAndRunDetails({ payload }) {
  try {
    yield call(axios.request, {
      method: "POST",
      url: "/tracking/updateCodingTrackingDetailsAfterCompileAndRun",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
  } catch (err) {
    console.log(err);
    yield put(quiz.setApiError(err));
  }
}

function* exitProfileSession({ payload }) {
  try {
    yield call(axios.request, {
      method: "PUT",
      url: "/session/exitFromProfileSection",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

function* candidateCameraCapture({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/session/candidateCameraCapture/${payload?.code}`,
        data: payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(quiz.candidateCameraCaptureSuccess(res.data));
    }
  } catch (err) {
    yield put(quiz.setApiError(err));
  }
}

// function* testAr({payload}) { // FK cleanup july 2023
//   try{
//     console.log(payload)
//   }
//   catch(err) {
//     console.log(err)
//   }
// }

function* quizSaga() {
  yield takeLatest(types.GET_QUESTIONS_REQUESTED, getQuestions);
  yield takeLatest(types.GET_L0_QUESTIONS, getL0Questions);
  yield takeLatest(types.GET_L1_QUESTIONS, getL1Questions);
  yield takeLatest(types.GET_L2_QUESTIONS, getL2Questions);
  yield takeLatest(types.GET_L3_QUESTIONS, getL3Questions);
  yield takeLatest(types.CREATE_SESSION_REQUESTED, createSesssion);
  yield takeLatest(types.GET_TRACKING_REQUESTED, getTracking);
  yield takeLatest(types.SUBMIT_CODING_CHALLENGE, submitCodingChallenge);
  yield takeLatest(types.GET_CODING_SUBMISSION_DETAIL, getCodingChallengeSubmissionDetail);
  yield takeLatest(types.SUBMIT_TEST_RESULTS, submitTestResults);
  yield takeLatest(types.GET_L3_PRESENTATION, getL3PresentationQuestions);
  yield takeLatest(types.SUBMIT_CODING_ANSWERS, submitCodingResults);
  yield takeLatest(types.RECREATE_USER_SUBMIT_RESULTS, recreateUserAndSubmitResults);
  yield takeLatest(types.UPDATE_CODING_COMPILE_AND_RUN_TRACKING_DETAILS, updateCodingCompileAndRunDetails);
  yield takeLatest(types.EXIT_PROFILE_SESSION, exitProfileSession);
  yield takeLatest(types.CANDIDATE_CAMERA_CAPTURE, candidateCameraCapture);
}

export { quizSaga };
