import React, { useState, useEffect } from "react";
import Plot from "./Plot";
import { useDispatch, useSelector } from "react-redux";
import chart from "../../../../redux/actions/chart";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { chartConfig } from "../chartConfig";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { analyticsEvent } from "../../../../services/ga4utility";

const CandidatesChart = () => {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const chartStore = useSelector((state) => state.chart);
  const [data, setData] = useState(chartStore?.CandidatesChartData);

  const createDataPoints = () => {
    const dataPoints = {};
    data?.forEach((entry) => {
      const date = entry.localDate;
      const displayName = entry.createdBy.displayName;

      if (!dataPoints[date]) {
        dataPoints[date] = {};
      }

      if (!dataPoints[date][displayName]) {
        dataPoints[date][displayName] = 0;
      }

      dataPoints[date][displayName]++;
    });

    return dataPoints;
  };

  const createTrace = (dataPoints) => {
    const dates = Object.keys(dataPoints);
    const displayNames = [...new Set(data?.map((entry) => entry.createdBy.displayName))];

    displayNames.sort();
    displayNames.reverse();
    const traces = [];

    displayNames?.forEach((displayName, index) => {
      const counts = dates?.map((date) => dataPoints[date][displayName] || 0);

      const formattedDates = [];
      const reorganizedCounts = [];

      let endDate = new Date();
      let startDate = new Date(endDate);
      startDate.setDate(endDate.getDate() - 30);
      // startDate.setMonth(endDate.getMonth() - 1);

      let currentDate = new Date(startDate);
      let previousYear = null;

      while (currentDate <= new Date(endDate)) {
        const dateStr = currentDate.toISOString().split("T")[0];
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const monthName = date.toLocaleString("en-US", { month: "short" });
        const day = date.getDate().toString().padStart(2, "0");

        if (year !== previousYear) {
          formattedDates.push(`${monthName} ${day}, ${year}`);
          previousYear = year;
        } else {
          formattedDates.push(`${monthName} ${day}`);
        }

        const index = dates.indexOf(dateStr);
        if (index !== -1) {
          reorganizedCounts.push(counts[index]);
        } else {
          reorganizedCounts.push(0);
        }

        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }

      traces.push({
        x: formattedDates,
        y: reorganizedCounts,
        // width: 2,
        name: displayName,
        type: "bar",
        marker: {
          color: chartConfig.customColors[index % chartConfig.customColors.length],
        },
        // hovertemplate: "Added On: <b>%{x}</b>" + "<br>Count: <b>%{y}</b><br>",
        hovertemplate: `Added On: <b>%{x}</b><br>Count: <b>%{y}</b><br>`,
      });
    });

    return traces;
  };

  const dataPoints = createDataPoints();

  const traces = createTrace(dataPoints);

  const updatedData = traces?.map((item) => ({
    ...item,
  }));

  useEffect(() => {
    let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let endDate = new Date();
    let startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 30);
    // startDate.setMonth(endDate.getMonth() - 1);

    let payload = {
      startDate,
      endDate,
      browserTimeZone,
    };
    dispatch(chart.CandidatesChartData(payload));
  }, []);

  useEffect(() => {
    if (chartStore?.candidatesChartData?.length) {
      setData(chartStore?.candidatesChartData);
    }
  }, [chartStore?.candidatesChartData?.length]);

  let showlegend;
  if (open === false) showlegend = false;
  else showlegend = true;

  let text;
  if (open === false) text = '<b>Daily Candidates Added</b><br><span style="font-size:9pt">(Last 30 days)</span>';
  else
    text =
      '<span style="font-size:14pt;font-weight:Bold">Daily Candidates Added</span><br><span style="font-size:9pt">(Last 30 days)</span>';

  let yaxis;
  if (open === false) yaxis = '<span style="font-size:.8rem; font-weight:Bold">Number of candidates</span>';
  else yaxis = '<span style="font-size:1rem;font-weight:Bold">Number of candidates</span>';

  let xaxis;
  if (open === false) xaxis = '<span style="font-size:.8rem; font-weight:Bold">Candidates added on</span>';
  else xaxis = '<span style="font-size:1rem;font-weight:Bold">Candidates added on</span>';

  const layout = {
    legend: {
      title: {
        text: '<span style="font-size:0.9rem; font-weight:Bold">&nbsp;&nbsp;&nbsp;User</span>',
      },
      font: { size: 14 },
    },
    autosize: true,
    categoryorder: "category ascending",

    showlegend: showlegend,

    barmode: "stack",
    title: {
      text: text,
      standoff: -50,
    },
    xaxis: {
      title: {
        text: xaxis,
        standoff: 20,
      },
      showline: true,
      tickangle: -90,
      tickheight: 100,
      ticks: "inside",
    },
    yaxis: {
      title: {
        text: yaxis,
        standoff: 25,
      },
      showline: true,
      tickheight: 100,
      ticks: "inside",
    },
    hoverlabel: {
      bgcolor: "#FFF",
    },
    font: chartConfig.font,
    hovermode: "closest",
  };

  return (
    <>
      {chartStore?.candidatesChartData?.length ? (
        <Plot
          data={updatedData}
          layout={layout}
          style={{ width: "95%", margin: "10px" }}
          containerId="candidates"
          config={{
            modeBarButtonsToAdd: [
              {
                name: "Full Screen",
                icon: chartConfig.fullScreenIcon,
                click: function (gd) {
                  handleOpen();
                  analyticsEvent("chart", "Daily Candidates Added Chart Open", "Daily Candidates Added");
                },
              },
            ],
            displaylogo: false,
            responsive: true,
            modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
            textAlign: "center",
          }}
        >
          <StackedBarChartIcon style={{ marginBottom: "5px" }} />
          <span style={{ marginLeft: "5px", marginBottom: "5px" }}>{chartConfig.MessageWhenNoData}</span>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        bg="red"
      >
        <Box sx={styleModal} style={{ width: "100%", height: "100%", margin: "auto" }}>
          <Plot
            data={updatedData}
            layout={layout}
            style={{ width: "100%", height: "100%" }}
            containerId="candidatesModal"
            config={{
              modeBarButtonsToAdd: [
                {
                  name: "Exit Full Screen",
                  icon: chartConfig.exitFullScreenIcon,
                  click: function () {
                    handleClose();
                  },
                },
              ],
              displaylogo: false,
              responsive: true,
              modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CandidatesChart;
