import React from "react";
import { DialogTitle, DialogContent, Typography, Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { theme } from "../../styles/theme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  dialogWrapper: {
    padding: "16px 8px",
    position: "absolute",
    top: theme.spacing(5),
  },
  closeButton: {
    position: "absolute !important",
    right: `${theme.spacing(1)}`,
    top: "8px",
    color: `${theme.palette.btnPrimary.tertiaryText} !important`,
    "& .MuiButtonBase-root ": {
      padding: "12px !important",
    },
  },
  content: {
    padding: "10px 30px 30px !important",
  },
  title: {},
  justifyActions: {
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "56px",
  },
  description: {
    paddingTop: "16px",
  },
  desContainer: {
    paddingLeft: "0px",
  },
  hint: {
    whiteSpace: "pre-line",
  },
});

export default function LOneExampleContent(props) {
  const { title, setOpenPopup, hint } = props;
  const classes = useStyles();

  return (
    <>
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => {
          setOpenPopup(false);
        }}
      >
        <div style={{ display: "flex" }}>
          {title}

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <Container className={classes.desContainer}>
            <div>
              <div>
                <Typography
                  variant="body2"
                  className={`${classes.title} ${classes.hint}`}
                  textAlign="justify"
                  id="no-copy"
                >
                  {hint}
                </Typography>
              </div>
            </div>
          </Container>
        </div>
      </DialogContent>
    </>
  );
}
