// import React, { useEffect } from 'react'
// import { FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles({
//   icon: {
//     color: "#2F2F2F !important"
//   },
//   menuPaper:{
//     "& .MuiPopover-paper":{
//       top:'122px !important'
//     }
//   }
// })
// export default function CustomPagination(props) {
//   const [rowValue, setRowValue] = React.useState(10);
//   const [pageValue, setPageValue] = React.useState(1);
//   const [pages, setPages] = React.useState([1]);
//   const classes = useStyles();

//   const setPageCounts = (value) => {
//     let pages = Math.ceil((props?.TotalCount || 0) / value);
//     let pagesvalue = pages?.toFixed();
//     let viewPages = [...Array(parseInt(pagesvalue))?.keys()]?.map((e, i) => e + 1);
//     if(pageValue !== 1 ){
//     setPageValue(pagesvalue ? 1 : pageValue);
//     props.Paginationstart(pagesvalue  ? 1 : pageValue)
//     }
//     setPages( viewPages)
//   }

//   const handleChangeRowsPerPage = (event) => {
//    //filter the pages in ui
//     const {
//       target: { value },
//     } = event;
//     setRowValue(value);
//     setPageCounts(value);
//     props.Paginationlimit(value)
//   };
//   const handleChangePage = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setPageValue(value);
//     props.Paginationstart(value)

//   };

//   const rows = [10, 20, 30, 40, 50]

//  // Render when total count is getting
//   useEffect(() => {
//     if( props.TotalCount ){
//     setPageCounts(rowValue);
//     }
//   }, [ props.TotalCount])
//   return (
//     <div style={{width: "380px"}}>
//       <span>Rows per page&nbsp;

//         <FormControl sx={{ width: 92 }} size="small">
//           <Select
//             displayEmpty
//             value={rowValue}
//             onChange={handleChangeRowsPerPage}
//             input={<OutlinedInput />}
//             renderValue={(selected) => {
//               if (selected?.length === 0) {
//                 return <><em >{rowValue}</em></>
//               }

//               return Array.isArray(selected) ? selected : rowValue;
//             }}
//             inputProps={{
//               'aria-label': 'Without label',
//               classes: {
//                 icon: classes.icon
//               }
//             }}
//             className={props.className}
//           >
//             {rows.map((name) => (
//               <MenuItem key={name} value={name}>
//                 <ListItemText primary={name} />
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>&nbsp;&nbsp;&nbsp;&nbsp;
//         View page&nbsp;
//         <FormControl sx={{ width: 80 }} size="small">
//           <Select
//             displayEmpty
//             value={pageValue}
//             onChange={handleChangePage}
//             input={<OutlinedInput />}
//             renderValue={(selected) => {
//               if (selected?.length === 0) {
//                 return <><em >1</em></>
//               }

//               return Array.isArray(selected) ? selected : pageValue;
//             }}
//             inputProps={{
//               'aria-label': 'Without label',
//               classes: {
//                 icon: classes.icon
//               }
//             }}
//             MenuProps={{
//               sx: {
//                   '& .MuiPopover-paper': {
//                     // top:'221px !important',
//                     maxHeight: "calc(100% - 242px) !important"
//                   },

//               }
//           }}

//             className={props.className}
//           >
//             {pages.map((name) => (
//               <MenuItem key={name} value={name} className={classes.menuPaper}>
//                 <ListItemText primary={name} />
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </span>
//     </div>
//   )
// }

import * as React from "react";
import TablePagination from "@mui/material/TablePagination";

export default function CustomPagination(props) {
  const {
    rows,
    tablePaginationPageNumber,
    handleChangeRowsPerPage,
    rowsPerPage,
    handleChangePage,
    nextIconButtonProps,
    backIconButtonProps,
  } = props;

  const totalRecords = React.useMemo(() => {
    return rows?.length || 0;
  }, [rows]);

  const rowsPerPageOptions = [15, 30, 50, 100];

  return (
    <TablePagination
      component="div"
      count={totalRecords}
      page={tablePaginationPageNumber}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPage={rowsPerPage}
      onPageChange={handleChangePage}
      rowsPerPageOptions={rowsPerPageOptions}
      nextIconButtonProps={nextIconButtonProps}
      backIconButtonProps={backIconButtonProps}
    />
  );
}
