import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
// import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from "@mui/material/LinearProgress";
// import { theme } from '../../styles/theme';

const BorderLinearProgress = withStyles((theme, props) => ({
  root: {
    height: 6,
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: theme.palette.btnPrimary.light,
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

// Inspired by the former Facebook spinners.
// const useStylesFacebook = makeStyles({
//   root: {
//     position: 'relative',
//   },
//   bottom: {
//     color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
//   },
//   top: {
//     color: '#1a90ff',
//     animationDuration: '550ms',
//     position: 'absolute',
//     left: 0,
//   },
//   circle: {
//     strokeLinecap: 'round',
//   },
// });

// function FacebookCircularProgress(props) { // FK cleanup unused variable july 2023
//   const classes = useStylesFacebook();

//   return (
//     <div className={classes.root}>
//       <CircularProgress
//         variant="determinate"
//         className={classes.bottom}
//         size={40}
//         thickness={4}
//         {...props}
//         value={100}
//       />
//       <CircularProgress
//         variant="indeterminate"
//         disableShrink
//         className={classes.top}
//         classes={{
//           circle: classes.circle,
//         }}
//         size={40}
//         thickness={4}
//         {...props}
//       />
//     </div>
//   );
// }

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  progressBar: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: (props) => {
        return props.color;
      },
    },
  },
});
export default function CustomizedProgressBars(props) {
  const classes = useStyles(props);
  // eslint-disable-next-line
  const { variant, value, ...other } = props;
  return (
    <div className={classes.root}>
      {/* <FacebookCircularProgress />
      <br /> */}
      <BorderLinearProgress
        variant={variant || "determinate"}
        value={value || 0}
        className={`${classes.progressBar}`}
      />
    </div>
  );
}
