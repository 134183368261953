import React from "react";
import { Button as MuiButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../styles/theme";

const useStyles = makeStyles({
  root: {
    // margin: theme.spacing(0.5),
  },
  label: {
    textTransform: "none",
  },
  btnPrimary: {
    backgroundColor: theme.palette.btnPrimary.main,
    height: `${theme.palette.btnPrimary.height} `,
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    lineHeight: "24px !important",
    letterSpacing: "0em !important",
    textAlign: "center !important",
    fontWeight: "500 !important",
    textTransform: "none !important",
    "& .MuiButton-label": {
      color: theme.palette.btnPrimary.contrastText,
      textTransform: "none !important",
      whiteSpace: "nowrap !important",
    },
    "&:hover": {
      backgroundColor: theme.palette.btnPrimary.mid,
      boxShadow: "none !important",
    },
    [theme.breakpoints.up("tablet")]: {
      height: "48px",
    },
  },
  disableactive: {
    background: `#C4C4C4 !important`,
    height: `${theme.palette.btnPrimary.height} `,
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    lineHeight: "24px !important",
    letterSpacing: "0em !important",
    textAlign: "center !important",
    fontWeight: "500 !important",
    textTransform: "none !important",
    "& .MuiButton-label": {
      color: theme.palette.btnPrimary.contrastText,
      textTransform: "none !important",
      whiteSpace: "nowrap !important",
    },
  },
  btnPrimary1: {
    backgroundColor: `${theme.palette.btnPrimary.main1} !important`,
    height: "40px !important",
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    lineHeight: "24px !important",
    letterSpacing: "0em !important",
    textAlign: "center !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    "& .MuiButton-label": {
      color: theme.palette.btnPrimary.contrastText,
      textTransform: "none !important",
      whiteSpace: "nowrap !important",
    },
    "&:hover": {
      backgroundColor: theme.palette.btnPrimary.mid,
      boxShadow: "none !important",
    },
    [theme.breakpoints.up("tablet")]: {
      height: "40px",
    },
  },
  btnSecondary: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    border: `1px solid ${theme.palette.btnPrimary.main} `,
    boxShadow: "none !important",
    height: theme.palette.btnPrimary.height,
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    lineHeight: "24px !important",
    letterSpacing: "0em !important",
    textAlign: "center !important",
    fontWeight: "500 !important",
    textTransform: "none !important",
    color: `${theme.palette.btnPrimary.main} `,
    "& .MuiButton-label": {
      color: `${theme.palette.btnPrimary.main} `,
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none !important",
    },
  },
  btnTertiaryTwo: {
    backgroundColor: `${theme.palette.primary.light}`,
    border: `1px solid ${theme.palette.primary.light} !important`,
    boxShadow: "none",
    height: theme.palette.btnPrimary.height,
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0em",
    lineHeight: "24px",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 500,
    "& .MuiButton-label": {
      color: `${theme.palette.btnPrimary.tertiaryText}`,
      textTransform: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
  btnLightBlue: {
    backgroundColor: theme.palette.btnPrimary.mid,
    height: theme.palette.btnPrimary.height,
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    fontWeight: 500,
    "& .MuiButton-label": {
      color: theme.palette.btnPrimary.contrastText,
      textTransform: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.btnPrimary.mid,
      boxShadow: "none",
    },
  },
  btnGray: {
    backgroundColor: "#C4C4C4",
    height: theme.palette.btnPrimary.height,
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
    fontWeight: 500,
    "& .MuiButton-label": {
      color: theme.palette.btnPrimary.contrastText,
      textTransform: "none",
    },
    "&:hover": {
      backgroundColor: "#C4C4C4",
      boxShadow: "none",
    },
  },
  // btnTranparent is taken from bootstrap for buttons styling
  btnTransparent: {
    backgroundColor: "#FFFFFF !important",
    border: `1px solid #C4C4C4 !important`,
    boxShadow: "none !important",
    height: theme.palette.btnPrimary.height,
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    lineHeight: "24px !important",
    letterSpacing: "0em !important",
    textAlign: "center !important",
    fontWeight: "500 !important",
    color: "#C4C4C4 !important",
    textTransform: "capitalize !important",
    "& .MuiButton-label": {
      color: "#C4C4C4 !important",
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#FFFFFF !important",
      boxShadow: "none !important",
    },
  },

  googleButton: {
    backgroundColor: "#FFFFFF",
    border: `none`,
    boxShadow: "2px 2px 4px rgb(47 47 47 / 12%)",
    height: theme.palette.btnPrimary.height,
    width: (props) => props.width || theme.palette.btnPrimary.width,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
    fontWeight: 500,
    "& .MuiButton-label": {
      color: "#787878",
      textTransform: "none",
    },
    "&:hover": {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
    },
  },

  btn: {
    //width: (props) => props.width ? `${props.width} !important` : '240px !important',
    textTransform: "none !important",
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    // height: "30px !important",
    height: "40px !important",
    background: `${theme.palette.mainPaletteBlue} !important`,
  },

  btnDelete: {
    textTransform: "none !important",
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    height: "40px !important",
    background: `${theme.palette.error.main} !important`,
  },

  cancel_btn: {
    // width:'112px !important',
    textTransform: "none !important",
    background: "transparent !important",
    color: "#787878 !important",
    boxShadow: "none !important",
  },
  btnCls: {
    // width: '100% !important',
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    // height: "30px !important",
    height: "40px !important",
    // backgroundColor: `${theme.palette.btnPrimary.main1} !important`,
    color: "#1789FC",
    border: "1px solid #C4C4C4 !important",
    padding: "0px !important",
  },
  btnCls2: {
    // width: '100% !important',
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    // height: "30px !important",
    height: "40px !important",
    // backgroundColor: `${theme.palette.btnPrimary.main1} !important`,
    color: "#C4C4C4 !important",
    border: "1px solid #C4C4C4 !important",
  },
  btnCls3: {
    // width: '100% !important',
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    // height: "30px !important",
    height: "40px !important",
    // backgroundColor: `${theme.palette.btnPrimary.main1} !important`,
    color: "#1789FC !important",
    border: "1px solid #1789FC",
  },
  disablebtn: {
    textTransform: "none !important",
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    // height: "30px !important",
    height: "40px !important",
    background: `#C4C4C4 !important`,
    color: `${theme.palette.black.lightWhite} important`,
    "& .MuiButtonBase-root-MuiButton-root.Mui-disabled": {
      color: `${theme.palette.black.lightWhite} important`,
    },
  },
  btn1: {
    //width: (props) => props.width ? `${props.width} !important` : '240px !important',
    textTransform: "none !important",
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    // height: "30px !important",
    height: "32px !important",
    background: `${theme.palette.mainPaletteBlue} !important`,
  },

  btn1Delete: {
    textTransform: "none !important",
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    height: "32px !important",
    background: `${theme.palette.error.main} !important`,
  },

  btnCls1: {
    // width: '100% !important',
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "16px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    // height: "30px !important",
    height: "32px !important",
    border: "1px solid #C4C4C4 !important",
  },
  disablebtn1: {
    //width: (props) => props.width ? `${props.width} !important` : '240px !important',
    textTransform: "none !important",
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    // height: "30px !important",
    height: "32px !important",
    background: `#C4C4C4 !important`,
    // color:`${theme.palette.black.lightWhite} important`,
    "& .MuiButtonBase-root-MuiButton-root.Mui-disabled": {
      color: `${theme.palette.black.lightWhite} important`,
    },
  },
});

export default function Button(props) {
  const { text, size, color, label, variant, onClick, width, ...other } = props;
  const classes = useStyles(props);

  return (
    <MuiButton
      sx={props.sx}
      variant={variant || "contained"}
      size={size || "large"}
      onClick={onClick}
      {...other}
      className={`${classes[color]}`}
      style={{ width: props.width }}
    >
      {text}
    </MuiButton>
  );
}
