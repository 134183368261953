import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Grid } from "@mui/material";
import GridTable from "../../components/Table";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import SearchBar from "../../components/controls/search";
import { useNavigate, generatePath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import role from "../../redux/actions/role";
import Footer from "../../components/Footer";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import CustomPagination from "../../components/controls/CustomPagination";
import CheckSelectMenu from "../../components/controls/Checkmenu";
import HeaderConfig from "../../constants/HeaderConfigs";
import ToggleButtonAll from "../../components/controls/ToggleButton";
import { sortJson, applySearch, transformOptions } from "../../services/globalService";
import Tour from "../../components/Tour";
// import TrialExpiryNotification from "../../components/TrialExpiryNotification";
import SelectWithCheckbox from "../../components/controls/SelectWithCheckbox";
import Notification from "../../components/AnnouncementNotification";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";

const invitedByMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      padding: 12,
      marginLeft: 44,
    },
  },
};

const statusMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 45,
    },
  },
};
const categoryMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 105,
    },
  },
};

const skillMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 50,
    },
  },
};

const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  allJob: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "24px !important",
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    color: "#2F2F2F",
  },
  btnCls: {
    width: 300,
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    height: "30px !important",
  },
  Allsty: {
    boxSizing: "border-box",

    /* Auto layout */

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: " center",
    gap: "10px",
    width: "71px",
    height: "40px",
    border: "1px solid #2F2F2F",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: "0px -1px !important",

    fontSize: "16px !important",
    fontWeight: 500,
    cursor: "pointer",
  },

  Alltext: {
    display: "flex",
  },
  Allbtn: {
    borderRadius: "4px 0px 0px 4px",
    color: "#FFFFFF",
    background: "#2F2F2F",
  },
  Mybtn: {
    borderRadius: "0px 4px 4px 0px",

    color: "#2F2F2F",
  },
  Allbtn1: {
    borderRadius: "0px 4px 4px 0px",
    color: "#FFFFFF",
    background: "#2F2F2F",
  },
  Mybtn1: {
    borderRadius: "4px 0px 0px 4px",

    color: "#2F2F2F",
  },
  filtersty: {
    width: "101px",
    height: "40px",
    border: "1px solid #787878",
    color: "#787878",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-evenly",
    cursor: "pointer",
  },
  filterText: {
    boxSizing: "border-box",
    fontSize: "16px",
    fontWeight: 500,
    margin: "0px -1px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noRowsText: {
    fontFamily: "Roboto !important",
    fontStyle: "Regular !important",
    fontSize: "16px !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    verticalAalign: "center !important",
  },
  Noresult: {
    position: "relative",
    bottom: "200px",
  },
});

const InviteList = () => {
  const getUserDetails = () => {
    // check if recruiterUserId needs to be set
    if ("recruiterUserId" in localStorage) {
      let recruiterUserId = localStorage.getItem("recruiterUserId");
      localStorage.setItem("userid", recruiterUserId);
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      userDetails.userId = recruiterUserId;
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      localStorage.removeItem("recruiterUserId");
    }
    return JSON.parse(localStorage.getItem("user_details"));
  };
  let userDetails = getUserDetails();

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const [searchText, setSearchText] = useState("");
  const [onlyMine, setOnlyMine] = useState(false);
  const [selectText, setSelectText] = useState("all");
  const [userid, setUserid] = useState(null);
  const [invitedBy, setInvitedBy] = useState([]);
  const [invitedBylength, setInvitedBylength] = useState(0);
  const [invitedBColor, setInvitedBColor] = useState("");
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState(["active", "inactive"]);
  const [statuslength, setStatuslength] = useState(0);
  const [statusBColor, setStatusBColor] = useState("");
  const [category, setCategory] = useState([]);
  const [categorylength, setCategorylength] = useState(0);
  const [categoryBColor, setCategoryBColor] = useState("");
  const [skill, setSkill] = useState([]);
  const [skilllength, setSkilllength] = useState(0);
  const [skillBColor, setSkillBColor] = useState("");
  const [showReset, setShowReset] = useState(false);
  const [uiSortModel, setUiSortModel] = useState([
    {
      field: "uid",
      sort: "desc",
    },
  ]);
  const [totalInvite, setTotalinvite] = useState(0);
  const [myTotal, setMyTotal] = useState(0);
  const [inviteview, setInviteview] = useState(false);
  const [inviteFilt, setInviteFilt] = useState(false);
  const [inviteList, setInviteList] = useState(roleState?.invitelist);
  const [showStepper, setShowStepper] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  // Load Invite List
  useEffect(() => {
    dispatch(role.inviteList({ userId: userDetails.userId }));
    setInviteFilt(true);
  }, []);

  //Set Invite List and Filters values
  useEffect(() => {
    setInviteList(roleState?.invitelist);
    // run updates once invitefilter data is populated
    if (inviteFilt && roleState?.invitelist?.length) {
      setInviteFilt(false);
      setInvitedBy(roleState?.invitefilter?.invitedBy);
      const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
      setCategory(roleCategories);
      setStatus(roleState?.invitefilter?.status);
      setSkill([]);
      setTotalinvite(roleState?.inviteAllTotal);
      setMyTotal(roleState?.inviteUserTotal);
    }
  }, [roleState?.invitelist]);

  // Navigae to invitecandidateview page on click candidate row
  useEffect(() => {
    if (inviteview && roleState?.invitedetails?.uid)
      window.open(generatePath("/invitecandidateview/:id", { id: userid }));
  }, [inviteview && roleState?.invitedetails?.uid]);

  // Apply filter
  useEffect(() => {
    if (roleState?.invitelist?.length) {
      setInviteFilteredList();
    }
  }, [roleState?.invitelist, invitedBy, status, category, skill]);

  // Search Value side effect
  useEffect(() => {
    if (roleState?.invitelist?.length) {
      setInviteFilteredList();
    }
  }, [searchText]);

  // Side effect for toggle mylist or all list
  useEffect(() => {
    setInviteFilteredList();
  }, [selectText]);

  /**
   *  Filter functions
   */

  // Appy search filter on data
  const applySearchFilter = (rawData) => {
    let visibleKeys = [
      "uid",
      "firstName",
      "lastName",
      "jobRoleId",
      "jobRoleName",
      "status",
      "dateFilter",
      "jobRoleTargetScore",
      "proportionalScore",
      "testsTakenCount",
      "email",
    ];

    let filteredData = applySearch(rawData, searchText, visibleKeys);
    return filteredData;
  };

  // Extract Filter Data
  const dataFilter = (rawData, filterData, key) => {
    // Make sure all filters are in lowercase

    filterData = filterData?.map((i) => i?.toLowerCase());
    // FK cleanup eval can be harmful july 2023
    return rawData.filter(
      // eslint-disable-next-line
      (item) => eval(`item.${key}`) && filterData.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
    );
  }; //End

  // Apply Filters on data
  const applyFilterOnData = () => {
    // Raw Data
    let rawData = [...roleState?.invitelist];
    if (rawData?.length) {
      // AddedBy Filter
      rawData = dataFilter(rawData, invitedBy, "inviteCreatedBy");

      // Status Filter
      rawData = dataFilter(rawData, status, "status");

      // Role Catagory Filter
      rawData = dataFilter(rawData, category, "jobRoleCategory");

      // Skills Filter
      let skills = skill?.length ? skill.map((s) => s.toLowerCase()) : [];
      rawData = rawData.filter((item) => {
        // Check if skillDetails exists for the current item
        if (item["jobRoleSkillsArray"]) {
          // Check if all selected skills are present in the skillDetails of the current item
          return skills.every((selectedSkill) =>
            item["jobRoleSkillsArray"].some((value) => value.toLowerCase().trim() === selectedSkill)
          );
        }
        return false; // If skillDetails doesn't exist, exclude the item
      });
    }
    return rawData;
  }; //End

  // Set Invite filtered List
  const setInviteFilteredList = () => {
    let filteredData = applyFilterOnData();

    filteredData = applySearchFilter(filteredData);
    // Set Total invite count
    setTotalinvite(filteredData?.length);
    // Set My Total Count
    if (onlyMine) {
      filteredData = filteredData?.filter((i) => i.inviteCreatedByUid === userDetails.userId);
    }
    setMyTotal(filteredData.filter((i) => i.inviteCreatedByUid === userDetails.userId).length);

    let fieldType = uiSortModel[0].field === "createdAt" ? "date" : "";
    let field = uiSortModel[0].field;

    if (uiSortModel[0].field === "testsTakenCount") {
      field = "testCompletedTuple";
      fieldType = "tuple";
    }

    filteredData = sortJson([...filteredData], uiSortModel[0].sort, field, fieldType);
    // removing year from dateFilter
    filteredData.map((filteredData) => {
      return (filteredData["dateFilter"] = filteredData["dateFilter"].slice(0, 6));
    });

    setInviteList(filteredData);
  }; //End

  const headerdata1 = HeaderConfig.inviteHeader();

  const handleSortChange = (sortInfo) => {
    let fieldType, field;

    fieldType = sortInfo.field === "createdAt" ? "date" : "";
    field = sortInfo.field || uiSortModel[0].field;

    if (sortInfo.field === "testsTakenCount") {
      field = "testCompletedTuple";
      fieldType = "tuple";
    }

    setUiSortModel([sortInfo]);
    let sortedOrder = sortJson([...inviteList], sortInfo.sort, field, fieldType);
    setInviteList(sortedOrder);
  };

  const AddInviteChange = () => {
    navigate("/inviteaddcandidates");
  };

  const handleChangeInvitedBy = (value) => {
    setInvitedBy(value);
    if (value.length === roleState?.invitefilter?.invitedBy?.length) {
      setInvitedBylength(0);
    } else {
      setInvitedBylength(value.length);
    }

    setInvitedBColor("#EBEFF8");
    if (value) {
      setShowReset(true);
    }
    setTablePaginationPageNumber(0);
  };

  const handleChangeStatus = (value) => {
    setStatus(value);
    if (value.length === roleState?.invitefilter?.status?.length) {
      setStatuslength(0);
    } else {
      setStatuslength(value.length);
    }

    setStatusBColor("#EBEFF8");
    if (value) {
      setShowReset(true);
    }
    setTablePaginationPageNumber(0);
  };

  const handleChangeCategory = (value) => {
    setCategory(value);
    if (transformOptions(roleState?.roleCategories).length === value.length) {
      setCategorylength(0);
    } else {
      setCategorylength(value.length);
    }

    setCategoryBColor("#EBEFF8");
    if (value) {
      setShowReset(true);
    }
    setTablePaginationPageNumber(0);
  };
  const handleChangeSkill = (value) => {
    setSkill(value);
    if (value.length === roleState?.invitefilter?.skills?.length) {
      setSkilllength(0);
    } else {
      setSkilllength(value.length);
    }
    setSkillBColor("#EBEFF8");
    if (value) {
      setShowReset(true);
    }
    setTablePaginationPageNumber(0);
  };

  const Paginationstart = (value) => {
    setStart(value);
  };
  const Paginationlimit = (value) => {
    setLimit(value);
  };

  const onRefresh = () => {
    dispatch(role.inviteList({ userId: userDetails.userId }));
  };

  const onClearAllFilters = () => {
    const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
    setCategory(roleCategories);
    setInvitedBy(roleState?.invitefilter?.invitedBy);
    setStatus(roleState?.invitefilter?.status);
    setSkill([]);
    setSearchText("");
    setShowReset(false);
    setStatuslength(0);
    setStatusBColor("");
    setInvitedBColor("");
    setInvitedBylength(0);
    setCategorylength(0);
    setCategoryBColor("");
    setSkilllength(0);
    setSkillBColor("");
    setInviteList(roleState?.invitelist);
    setTablePaginationPageNumber(0);
  };
  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    if (searchValue) {
      setShowReset(true);
    } else {
      setShowReset(false);
    }
    setSearchText(searchValue);
    setTablePaginationPageNumber(0);
  };

  const onSelectAllInvitedBy = () => {
    setInvitedBy(roleState?.invitefilter?.invitedBy);
    setInvitedBylength(0);
    setInvitedBColor("");
    setTablePaginationPageNumber(0);
  };
  const onClearAllInvitedBy = () => {
    setInvitedBy([]);
    setInvitedBylength(0);
    setInvitedBColor("");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };
  const onSelectAllstatus = () => {
    setStatus(roleState?.invitefilter?.status);
    setStatuslength(0);
    setStatusBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const onClearAllstatus = () => {
    setStatus([]);
    setStatuslength(0);
    setStatusBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };
  const onSelectAllCategory = () => {
    const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
    setCategory(roleCategories);
    setCategorylength(0);
    setCategoryBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const onClearAllCategory = () => {
    setCategory([]);
    setCategorylength(0);
    setCategoryBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };
  const onSelectAllSkill = () => {
    setSkill(roleState?.invitefilter?.skills.map((i) => i.id));
    setSkilllength((roleState?.invitefilter?.skills.map((i) => i.id)).length);
    setSkillBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const onClearAllSkill = () => {
    setSkill([]);
    setSkilllength(0);
    setSkillBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };

  const inviteView = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setUserid(id);
      setInviteview(true);
      dispatch(role.inviteDetails(id));
    } else {
      return null;
    }
  };

  const SelectBtn = (event, text) => {
    if (text === "my") {
      setOnlyMine(true);
      setSelectText("my");
      setTablePaginationPageNumber(0);
    } else {
      setOnlyMine(false);
      setSelectText("all");
      setTablePaginationPageNumber(0);
    }
  };

  //stepper
  const handleStepper = () => {
    setShowStepper((prevState) => !prevState);
  };

  //trial expiry notification
  // const trialExpiryApproaching = localStorage.getItem("trialExpiryApproaching");

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  // eslint-disable-next-line
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [tablePaginationPageNumber, setTablePaginationPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (_, newPage) => {
    setPaginationPageNumber(newPage);
    setTablePaginationPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePaginationPageNumber(0);
    setPaginationPageNumber(1);
  };

  return (
    <div>
      <NavBar />
      <Notification handleCloseNotification={handleCloseNotification} showNotification={showNotification} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid style={showNotification ? { marginTop: "30px" } : { marginTop: "130px" }}>
          <Grid style={{ display: "flex" }}>
            <CustomBreadcrumbs links={[{ name: "Home", path: "/home" }, { name: "Invites" }]} />
          </Grid>
          <div
            style={{ display: "flex", justifyContent: "space-between", lineHeight: "40px", padding: "25px 0px 20px" }}
          >
            <Grid style={{ display: "flex", alignItems: "flex-start", gap: "24px", flexDirection: "row" }}>
              <Button
                data-rt-invitecandidate-=" invitecandidate"
                color="btn"
                width={199}
                text=" Invite Candidate(s)"
                onClick={AddInviteChange}
              />
              <Button
                sx={{
                  textTransform: "none",
                  color: "#2F2F2F",
                  fontSize: "16px",
                  border: "1px solid #787878",
                  height: "40px",
                  padding: "0px",
                }}
                variant="outlined"
                startIcon={<RefreshIcon />}
                width={144}
                text="Refresh List"
                onClick={onRefresh}
              />
              <ToggleButtonAll
                handleAllAndMy={SelectBtn}
                selectText={selectText}
                Alltotal={totalInvite}
                Mytotal={myTotal}
              />
            </Grid>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "24px", flexDirection: "row" }}>
              <Grid>
                <CustomPagination
                  Paginationstart={(start) => Paginationstart(start)}
                  Paginationlimit={(limit) => Paginationlimit(limit)}
                  TotalCount={inviteList?.length}
                  rows={inviteList || []}
                  tablePaginationPageNumber={tablePaginationPageNumber}
                  setTablePaginationPageNumber={setTablePaginationPageNumber}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  handleChangePage={handleChangePage}
                />
              </Grid>
            </div>
          </div>
          <Grid style={{ display: "flex", gap: "14px", paddingBottom: "20px" }}>
            <SearchBar
              placeholdertitle={"Search invite"}
              onChange={onChangeSearch}
              value={searchText}
              // width={'310px'}
            />
            <CheckSelectMenu
              Title={"Invited By"}
              Data={roleState?.invitefilter?.invitedBy}
              HandleChangeValue={(value) => handleChangeInvitedBy(value)}
              MenuProps={invitedByMenuProps}
              width={invitedBylength === 0 ? 115 : 140}
              text={"Invitedby"}
              checkLength={invitedBylength}
              BgColor={invitedBylength !== 0 && invitedBColor}
              selectValue={invitedBy}
              OnSelectAll={onSelectAllInvitedBy}
              OnClearAll={onClearAllInvitedBy}
            />
            <CheckSelectMenu
              Title={"Status"}
              Data={roleState?.invitefilter?.status}
              HandleChangeValue={(value) => handleChangeStatus(value)}
              MenuProps={statusMenuProps}
              width={statuslength === 0 ? 90 : 115}
              checkLength={statuslength}
              BgColor={statuslength !== 0 && statusBColor}
              selectValue={status}
              OnSelectAll={onSelectAllstatus}
              OnClearAll={onClearAllstatus}
            />

            <SelectWithCheckbox
              title={"Role Category"}
              options={roleState?.roleCategories}
              handleSelectOption={(value) => handleChangeCategory(value)}
              MenuProps={categoryMenuProps}
              width={categorylength === 0 ? 145 : 167}
              checkLength={categorylength}
              BgColor={categorylength !== 0 && categoryBColor}
              defaultValue={category}
              handleSelectAll={onSelectAllCategory}
              handleClearAll={onClearAllCategory}
            />
            <SelectWithCheckbox
              title={"Required Skills"}
              options={roleState?.invitefilter?.skills}
              handleSelectOption={(value) => handleChangeSkill(value)}
              MenuProps={skillMenuProps}
              width={skilllength === 0 ? 153 : 173}
              checkLength={skilllength}
              BgColor={skilllength !== 0 && skillBColor}
              defaultValue={skill}
              handleSelectAll={onSelectAllSkill}
              handleClearAll={onClearAllSkill}
              disabledCapitalize
            />
            {showReset === true && (
              <div style={{ minWidth: "140px" }}>
                <Button
                  sx={{ float: "right", textTransform: "none", color: "#2F2F2F" }}
                  variant="text"
                  endIcon={<CloseIcon />}
                  text="Reset Filters"
                  onClick={onClearAllFilters}
                />
              </div>
            )}
          </Grid>
          <Grid>
            <GridTable
              columns={headerdata1}
              rows={inviteList || []}
              checkbox={false}
              height={window.innerHeight - 410}
              getRowId={(row) => row._id}
              // count={invitesTableRows?.length}
              dispatchValue={role.candidateinviteList}
              handleRowClick={inviteView}
              onSortChange={handleSortChange}
              onResetFilters={onClearAllFilters}
              sortModel={uiSortModel}
              pageStart={start}
              pageLimit={limit}
              showPagination
              tablePaginationPageNumber={tablePaginationPageNumber}
              setTablePaginationPageNumber={setTablePaginationPageNumber}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Container>
      <Tour isTourBtnShow={showStepper} handleStepper={handleStepper} />
      <Footer />
    </div>
  );
};

export default InviteList;
