import React, { useEffect, useState } from "react";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, Tooltip } from "@mui/material";
import { theme } from "../../../styles/theme";
import Button from "../../../components/controls/Button";
// import { useFormik } from "formik";
import { useNavigate, generatePath, useLocation, useParams } from "react-router-dom";
import SimpleSnackbar from "../../../components/controls/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
// import Footer from "../../../components/Footer";
import BlueArrow from "../../../assets/svg/BlueArrow.svg";
import { codingLanguageDisplay } from "../../../services/globalService";
import CustomBreadcrumbs from "../../../components/controls/CustomBreadcrumbs";

// component styles
const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
    marginTop: "15px",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
  },
  down_arrow: {
    padding: "8px",
    marginTop: "10px",
  },
  btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "#1789FC !important",
  },
  cancel_btn: {
    width: "112px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#787878 !important",
    boxShadow: "none !important",
  },
  previous_btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#1789FC !important",
    boxShadow: "none !important",
    border: "1px solid #C4C4C4 !important",
    marginRight: "10px !important",
  },
  field: {
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  scoreLeftArea: {
    padding: 8,
    width: "100%",
    "@media (min-width:1450px)": {
      padding: 36,
      width: "auto",
    },
  },
  scoreMeter: {
    marginTop: -85,
    marginBottom: "5px",
  },
  textareasty: {
    width: "513px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
});

// component
export default function NewNameNote(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  let pathName = location.pathname;
  let editpath = `/editJobRole/${id}`;
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const [snacktext, setsnacktext] = useState("");
  const [rolepublish, setRolepublish] = useState(false);
  const [statusview, setStatusview] = useState(false);
  const [statusid, setStatusid] = useState(null);
  const [editid, setEditid] = useState(null);
  const [editview, setEditview] = useState(false);
  const [savebtn, setSavebtn] = useState(false);
  const { values } = props;
  let payload = values.payload;
  const NameNoteChange = (value) => {
    setRolepublish(value);

    if (rolepublish === true) {
      dispatch(role.addRole(payload));
      setSavebtn(true);
    } else if (rolepublish === true && pathName === editpath) {
      let payloadeditpublic = {
        id: id,
        data: payload,
      };
      dispatch(role.editRole(payloadeditpublic));
      setSavebtn(true);
    } else if (pathName === editpath && rolepublish === false) {
      let payloadedit = {
        id: id,
        data: payload,
      };
      dispatch(role.editRole(payloadedit));
      setSavebtn(true);
    } else {
      dispatch(role.addRole(payload));
      setSavebtn(true);
    }
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
      /* you can also use 'auto' behaviour
               in place of 'smooth' */
    });
  };
  useEffect(() => {
    if (roleState?.addroles?.status === 0 && rolepublish === false) {
      setOpen(true);
      setsnacktext("Role has been saved");
      let add_id = roleState?.addroles?.data?.uid;
      setStatusview(true);
      setStatusid(add_id);
      dispatch(role.roleDetails(add_id));
      setTimeout(() => {
        setSavebtn(false);
      }, 1000);
    } else if (roleState?.addroles?.status === 1 && rolepublish === false) {
      setOpen(true);
      setsnacktext("Something went wrong");
      setSavebtn(false);
    } else if (roleState?.addroles?.status === 0 && rolepublish === true) {
      let add_id = roleState?.addroles?.data?.uid;
      let payload = {
        status: "active",
      };
      let statuspayload = {
        id: add_id,
        data: payload,
      };
      dispatch(role.roleStatus(statuspayload));
      setTimeout(() => {
        setSavebtn(false);
      }, 1000);
    }
  }, [roleState?.addroles?.status]);

  useEffect(() => {
    if (statusview && roleState?.roledetails?.uid) navigate(generatePath("/newRoleView/:id", { id: statusid }));
  }, [statusview && roleState?.roledetails?.uid]);

  useEffect(() => {
    if (roleState?.editrole?.status === 0 && rolepublish === false) {
      setOpen(true);
      setsnacktext("Role has been saved");
      setEditid(id);
      setEditview(true);
      dispatch(role.roleDetails(id));
      setTimeout(() => {
        setSavebtn(false);
      }, 1000);
    } else if (roleState?.editrole?.status === 1 && rolepublish === false) {
      setOpen(true);
      setsnacktext("Something went wrong");
      setSavebtn(false);
    } else if (roleState?.editrole?.status === 0 && rolepublish === true) {
      let edit_id = roleState?.editrole?.data?.uid;
      let payload1 = {
        status: "active",
      };
      let statuspayload1 = {
        id: edit_id,
        data: payload1,
      };
      dispatch(role.roleStatus(statuspayload1));
      setTimeout(() => {
        setSavebtn(false);
      }, 1000);
    }
  }, [roleState?.editrole?.status]);

  useEffect(() => {
    if (editview && roleState?.roledetails?.uid) {
      navigate(generatePath("/newRoleView/:id", { id: editid }));
    }
  }, [editview && roleState?.roledetails?.uid]);

  useEffect(() => {
    if (roleState?.rolestatus?.status === 0) {
      setOpen(true);
      setsnacktext("Published Role");
      let add_id = roleState?.rolestatus?.data?.uid;
      navigate(generatePath("/newRoleView/:id", { id: add_id }));
      setTimeout(() => {
        setSavebtn(false);
      }, 1000);
    } else if (roleState?.rolestatus?.status === 1) {
      setOpen(true);
      setsnacktext("Something went wrong");
      setSavebtn(false);
    }
  }, [roleState?.rolestatus?.status]);

  const previousBtn = (params) => {
    if (props.previousList !== undefined) {
      props.previousList(params);
    } else {
      return null;
    }
  };
  const cancelBtn = () => {
    navigate("/rolelist");
  };

  return (
    <>
      <div>
        <NavBar />
        <Container maxWidth="xl" className={classes.container}>
          <Grid style={{ marginTop: "130px" }}>
            <Grid style={{ display: "flex" }}>
              <CustomBreadcrumbs
                links={[
                  { name: "Home", path: "/home" },
                  { name: "Roles", path: "/rolelist" },
                  { name: pathName === editpath ? "Edit Role" : "Create New Role" },
                ]}
              />
            </Grid>
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Grid onClick={previousBtn} style={{ margin: "20px 0px" }}>
                  <Typography className={classes.roletype} style={{ color: "#1789FC", cursor: "pointer" }}>
                    <span>
                      <img src={BlueArrow} alt="BlueArrow" />
                    </span>{" "}
                    Back
                  </Typography>
                </Grid>
                <Grid className={classes.spaceflex}>
                  <Typography className={classes.roletext}>Role Summary</Typography>
                  {/* <Typography className={classes.roletext}> Role ID 0001</Typography> */}
                </Grid>
                <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Grid style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          Hiring Role Information
                        </Typography>
                      </Grid>

                      <Grid container>
                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Typography className={classes.roletype1}>Role Category</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {values.catagory}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Typography className={classes.roletype1}>Selectivity</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {values.selectivity}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Typography className={classes.roletype1}> Role Name</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {values.rolename}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Typography className={classes.roletype1}>Required Degree</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {values.degree}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Typography className={classes.roletype1}>Years of Experience</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {values.year}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: "20px" }}>
                          <Typography className={classes.roletype1}>Coding Languages</Typography>
                          <Tooltip title={values.selectCoding ? values?.selectCoding?.join(" / ") : []}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {values?.selectCoding?.length
                                ? codingLanguageDisplay(values.selectCoding)
                                : "No language selected."}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: "20px" }}>
                          <Typography className={classes.roletype1}>Internal Notes</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                            {values.textAreaValue && values.textAreaValue.length > 0 ? values.textAreaValue : "None"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                </Grid>
                <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                <Grid>
                  <Grid style={{ marginTop: "20px" }}>
                    <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                      Testing Requirements
                    </Typography>
                    <Typography className={classes.roletype1}>
                      {" "}
                      {values.isOverridePermitted === false ? (
                        <>
                          Override is <b>not</b> permitted on Sections to test
                        </>
                      ) : (
                        "Override permitted on Sections to test"
                      )}
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: "0px" }}>
                    <Grid item xs={3}>
                      <Typography className={classes.roletype1}>Sections to Test</Typography>
                      {(values.testingRequirement || []).map((item, i) => {
                        return (
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={item}>
                            {item === "Presentation" ? "Behavioral" : item}
                          </Typography>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.roletype1}>Skills to Test</Typography>
                      {(values.skillDetails || []).map((item, i) => {
                        return (
                          <>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                              {item.skill}
                            </Typography>
                          </>
                        );
                      })}
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.roletype1}>Assigned Weight</Typography>
                      {(values.skillDetails || []).map((item, i) => {
                        return (
                          <>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {item.weightage}%
                            </Typography>
                          </>
                        );
                      })}
                    </Grid>
                    {values?.testingRequirement?.includes("Presentation") && (
                      <Grid item xs={3}>
                        <Typography className={classes.roletype1}>
                          Behavioral Skills to test ({values?.payload?.behavior?.type === "video" ? "Video" : "Classic"}
                          )
                        </Typography>
                        {([...values?.payload?.behavior?.skills] || [])
                          .sort((a, b) => {
                            const nameA = roleState?.jobRoleDataSuccess?.behaviorDimension[a] || a;
                            const nameB = roleState?.jobRoleDataSuccess?.behaviorDimension[b] || b;
                            return nameA.localeCompare(nameB);
                          })
                          .map((item, i) => {
                            return (
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                                {roleState?.jobRoleDataSuccess?.behaviorDimension[item] || item}
                              </Typography>
                            );
                          })}
                        {/* {values?.payload?.behavior?.type === "video" && (
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {roleState?.jobRoleDataSuccess?.behaviorDimension["Communication"] ||
                              "Effective Communication"}
                          </Typography>
                        )} */}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                <Grid style={{ display: "flex", justifyContent: "space-between", paddingTop: "12px" }}>
                  <Button
                    data-rt-role-add="cancel"
                    color="cancel_btn"
                    text="Cancel"
                    // type="submit"
                    onClick={cancelBtn}
                    width={112}
                  ></Button>
                  <Grid style={{ display: "flex", gap: "14px" }}>
                    <Button
                      data-rt-recruiter-saverole="saverole"
                      color="btnCls"
                      text={"Save Role"}
                      type="submit"
                      onClick={() => (savebtn === true ? null : NameNoteChange(false))}
                      width={199}
                      variant="outlined"
                    ></Button>
                    <Button
                      color={savebtn === true ? "disablebtn" : "btn"}
                      width={199}
                      data-rt-role-add="PublishRole"
                      text={"Publish Role"}
                      type="submit"
                      onClick={() => (savebtn === true ? null : NameNoteChange(true))}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <SimpleSnackbar open={open} setOpen={setOpen} text={snacktext} />
    </>
  );
}
