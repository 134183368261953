import chartTypes from "../types/chart";

export const initialState = {
  isauthLoading: false,
  chartData: null,
  stackChartData: null,
  pieChartData: null,
  scatterChartData: null,
  candidatesChartData: null,
  roleChartData: null,
};

function chart(state = initialState, action) {
  switch (action.type) {
    case chartTypes.CHART_DATA:
      return { ...state, isauthLoading: false, chartData: action.payload };

    case chartTypes.CHART_DATA_SUCCESS:
      return { ...state, isauthLoading: false, chartData: action.payload };

    case chartTypes.STACK_CHART_DATA:
      return { ...state, isauthLoading: false, stackChartData: action.payload };

    case chartTypes.STACK_CHART_DATA_SUCCESS:
      return { ...state, isauthLoading: false, stackChartData: action.payload };

    case chartTypes.PIE_CHART_DATA:
      return { ...state, isauthLoading: false, pieChartData: action.payload };

    case chartTypes.PIE_CHART_DATA_SUCCESS:
      return { ...state, isauthLoading: false, pieChartData: action.payload };

    case chartTypes.SCATTER_CHART_DATA:
      return {
        ...state,
        isauthLoading: false,
        scatterChartData: action.payload,
      };

    case chartTypes.SCATTER_CHART_DATA_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        scatterChartData: action.payload,
      };

    case chartTypes.CANDIDATES_CHART_DATA:
      return {
        ...state,
        isauthLoading: false,
        candidatesChartData: action.payload,
      };

    case chartTypes.CANDIDATES_CHART_DATA_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        candidatesChartData: action.payload,
      };

    case chartTypes.ROLE_CHART_DATA:
      return {
        ...state,
        isauthLoading: false,
        roleChartData: action.payload,
      };

    case chartTypes.ROLE_CHART_DATA_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        roleChartData: action.payload,
      };

    default:
      return { ...state };
  }
}

export default chart;
