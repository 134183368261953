import { Typography } from "@mui/material";
import { compose, css, style, typography } from "@mui/system";
import { createTheme, styled } from "@mui/material";

const variant = style({
  prop: "variant2",
  cssProperty: false,
  themeKey: "typography",
});

const Text = styled(Typography)(css(compose(variant, typography)));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 380,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 380,
      tablet: 768,
      desktop: 1280,
    },
  },
  palette: {
    mainColor: "#FFFBF6",
    mainPaletteCOlor: "#1789FC",
    mainPaletteBlue: "#1789FC",
    black: {
      gray: "#F8F8F8",
      dark: "#2F2F2F",
      light: "#787878",
      lightWhite: "#FFFFFF",
      lightGray: "#C4C4C4",
    },
    background: {
      default: "#E5E5E5",
      level1: "rgba(235, 239, 248, 0.5)",
      level2: "rgba(248, 248, 248, 0.4)",
      paper: "#fff",
      light: "#EBEFF8",
      main: "#FFFBF6",
      borderLight: "#C4C4C4",
    },
    common: {
      black: "#000",
      white: "#fff",
      main: "#000",
      contrastText: "#fff",
      gray: "#2F2F2F",
      color: "#2F2F2F",
      fontFamily: "Roboto",
      fontSize: "12px;",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
    },
    type: "light",
    primary: {
      light: "#FFFBF6",
      main: "#FF941C",
      mid: "#61A2E4",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FFC5AD",
      main: "#FF941C",
      dark: "#FB9B73",
      contrastText: "#FF941C",
    },
    success: {
      contrastText: "#28BB3F",
      dark: "#28BB3F",
      main: "#28BB3F",
      light: "#28BB3F",
    },
    info: {
      contrastText: "#FFFFFF",
      dark: "#2F2F2F",
      main: "#2F2F2F",
      light: "#2F2F2F",
    },
    warning: {
      contrastText: "rgba(0, 0, 0, 0.87)",
      dark: "#FAC06A",
      main: "#FAC06A",
      light: "#FAC06A",
    },
    error: {
      contrastText: "#fff",
      dark: "#DD2E2E",
      main: "#DD2E2E",
      light: "#DD2E2E",
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      subtitle: "#757575",
    },
    btnPrimary: {
      light: "#EBEFF8 !important",
      main: "#1789FC !important",
      mid: "#61A2E4 !important",
      contrastText: "#fff",
      tertiaryText: "#787878",
      width: "313px",
      height: "40px !important",
      main1: "#1789FC",
    },
    // primary: { // FK cleanup july 2023
    //   light: "#FFFBF6",
    //   main: "#FF941C",
    //   mid: "#61A2E4",
    //   contrastText: "#fff",
    // },
  },

  font: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
  },
  typography: {
    dataViz1: {
      //Data Viz
      fontFamily: "Rational",
      fontSize: "80px",
      fontStyle: "normal",
      lineHeight: "64px",
      letterSpacing: "-0.02em",
      fontWeight: 600,
    },
    dataViz2: {
      //Data Viz
      fontFamily: "Rational",
      fontSize: "48px",
      fontStyle: "normal",
      lineHeight: "48px",
      letterSpacing: "-0.02em",
      fontWeight: 600,
      color: "#2F2F2F",
    },
    h1: {
      fontFamily: "Rational",
      fontSize: "40px",
      fontStyle: "normal",
      lineHeight: "48px",
      letterSpacing: "-0.02em !important",
      fontWeight: 600,
      color: "#2F2F2F",
    },
    h2: {
      fontFamily: "Rational",
      fontSize: "32px",
      fontStyle: "normal",
      lineHeight: "40px",
      letterSpacing: "-0.02em",
      fontWeight: 600,
      color: "#2F2F2F",
    },
    h3: {
      fontFamily: "Rational",
      fontSize: "24px",
      fontStyle: "normal",
      lineHeight: "28px",
      letterSpacing: "-0.02em",
      fontWeight: 600,
      color: "#2F2F2F",
    },
    h4: {
      fontFamily: "Rational !important",
      fontSize: "18px !important",
      fontStyle: "normal !important",
      lineHeight: "26px !important",
      letterSpacing: "-0.02em !important",
      fontWeight: "600 !important",
      color: "#2F2F2F !important",
    },
    h5: {
      fontFamily: "Rational !important",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      lineHeight: "20px !important",
      letterSpacing: "-0.02em !important",
      fontWeight: "600 !important",
      color: "#2F2F2F !important",
    },
    h6: {
      fontFamily: "Rational",
      fontSize: "18px",
      fontStyle: "normal",
      lineHeight: "26px",
      letterSpacing: "0.005em",
      fontWeight: 400,
      color: "#2F2F2F",
    },
    link1: {
      fontFamily: "Roboto !important",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      lineHeight: "24px !important",
      letterSpacing: "0em !important",
      //  color: "#1789FC !important",
      textDecoration: "none !important",
      "&:hover": {
        cursor: "pointer !important",
        textDecoration: "none !important",
      },
    },
    link2: {
      fontFamily: "Roboto !important",
      fontSize: "14px !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      lineHeight: "20px !important",
      letterSpacing: "0em !important",
      textalign: "left !important",
      textDecoration: "none !important",
      "&:hover": {
        cursor: "pointer !important",
        textDecoration: "none !important",
      },
      color: "#1789FC",
    },
    b1Medium: {
      fontFamily: "Roboto !important",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      fontWeight: "500 !important",
      lineHeight: "24px !important",
      letterSpacing: "0em !important",
      textalign: "left !important",
      color: "#2F2F2F !important",
    },
    b1Regular: {
      fontFamily: "Roboto !important",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      lineHeight: "24px !important",
      letterSpacing: "0em !important",
      textalign: "left !important",
      color: "#2F2F2F !important",
    },
    b2Medium: {
      color: "#2F2F2F ",
      fontFamily: "Roboto !important",
      fontSize: "14px !important",
      fontStyle: "normal !important",
      fontWeight: "500 !important",
      lineHeight: "21px !important",
      letterSpacing: "0em !important",
      textalign: "left !important",
    },
    b2Regular: {
      fontFamily: "Roboto !important",
      //color: "#2F2F2F !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontStyle: "normal !important",
      lineHeight: "21px !important",
      letterSpacing: "0em !important",
      textalign: "left !important",
      // color: "#2F2F2F !important"
    },
    b3Regular: {
      color: "#2F2F2F !important",
      fontFamily: "Roboto !important",
      fontSize: "12px !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      lineHeight: "18px !important",
      letterSpacing: "0.005em !important",
      textalign: "left !important",
      // color: "#2F2F2F !important"
    },
    input: {
      fontFamily: "Roboto !important",
      fontSize: "14px !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      lineHeight: "20px !important",
      letterSpacing: "0em !important",
      textalign: "left !important",
      color: "#2F2F2F !important",
      placeholderColor: "#787878 !important",
    },
    error: {
      fontFamily: "Roboto",
      fontSize: "14px !important;",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0em",
      textalign: "left",
      color: "#DD2E2E",
    },
  },
});

export { theme, Text };
