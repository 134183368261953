import * as React from "react";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "./Button";
import { Checkbox, ListItemText, Grid } from "@mui/material";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: window.innerHeight - 400,
    },
  },
};
const useStyles = makeStyles({
  noBorder: {
    border: "none !important",
  },
  selectMenu: {
    "& .css-vamk89-MuiFormControl-root .MuiFormControl-root": {
      width: "100% !important",
      margin: "0px !important",
    },
    // "&:hover": {
    //   border: "1px solid transparent"
    // },
    // // "&:focus-within": {
    // //   border: "1px solid transparent"
    // // },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid transparent",
      outline: "none !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid transparent",
    },
    // "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   border: "1px solid transparent",

    // },
    // "& .Mui-disabled": {

    // },
    // "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    //   border: "1px solid transparent"
    // }
  },
  selectText: {
    "& .MuiSelect-select": {
      background: "#EBEFF8 !important",
      border: "none !important",
      padding: "10px 12px !important",
      height: "40px",
    },
    "&.Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
        outline: "none !important",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none !important",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
    outline: "none !important",
    border: "none !important",
  },
  selectText1: {
    outline: "none !important",
    "& .MuiSelect-select": {
      background: "transparent !important",
      border: "1px solid #787878 !important",
      padding: "8px 12px !important",
      color: "#787878 !important",
      height: "40px",
    },
    "& .MuiOutlinedInput-root .Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
  },
  placeholdertext: {
    color: "#2F2F2F !important",
    fontSize: "16px !important",
    fontFamily: "Roboto !important",
    fontWeight: 500,
    fontStyle: "normal",
  },
  placeholdertext1: {
    color: "#787878 !important",
    fontSize: "16px !important",
    fontFamily: "Roboto !important",
    fontWeight: 400,
    fontStyle: "normal",
  },
  icon: {
    color: "#2F2F2F !important",
  },
  MenuItemCapitalize: {
    paddingLeft: "3px",
    background: "#FFFFFF!important",
    textTransform: "capitalize",
  },
  MenuItemNoneCapitalize: {
    paddingLeft: "3px",
    background: "#FFFFFF!important",
    textTransform: "none",
  },
});

export default function CheckSelectMenu(props) {
  const [selectvalue, setSelectvalue] = React.useState(props.selectValue || []);

  React.useEffect(() => {
    if (props?.selectValue !== undefined && props?.selectValue !== null) {
      if (props?.selectValue.length !== 0 && typeof props?.selectValue[0] === "object") {
        setSelectvalue(props?.selectValue?.map((i) => i?.value));
      } else {
        setSelectvalue(props?.selectValue !== undefined && props?.selectValue !== null ? props?.selectValue : "");
      }
    }
  }, [props?.selectValue]);

  // const names = props.selectvalue ? props.selectvalue : [] // FK cleanup unused variable july 2023
  const classes = useStyles();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectvalue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value?.split(",") : value
    );
    props.HandleChangeValue(typeof value === "string" ? value?.split(",") : value);
  };

  return (
    <div>
      <FormControl
        sx={{
          width: props.width,
          backgroundColor: props.BgColor ? props.BgColor : "transparent",
          border: props.BorColor ? props.BorColor : "none",
          outline: "none",
          height: props.height,
          borderRadius: "4px !important",
        }}
        size="small"
        className={classes.selectMenu}
      >
        <Select
          displayEmpty
          multiple
          value={selectvalue}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            return (
              <>
                {props.placeHolder && props.placeHolder === 2 ? (
                  <em className={props.checkLength === 0 ? classes.placeholdertext1 : classes.placeholdertext}>
                    {props.Title} {props.checkLength === 0 ? null : "("}
                    {props.checkLength === 0 ? null : props.checkLength}
                    {props.checkLength === 0 ? null : ")"}
                  </em>
                ) : (
                  <em className={classes.placeholdertext}>
                    {props.Title} {props.checkLength === 0 ? null : "("}
                    {props.checkLength === 0 ? null : props.checkLength}
                    {props.checkLength === 0 ? null : ")"}
                  </em>
                )}
              </>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{
            "aria-label": "Without label",
            classes: {
              icon: classes.icon,
            },
          }}
          className={props.checkLength === 0 ? classes.selectText1 : classes.selectText}
        >
          {props.Data.length != 0 && typeof props.Data[0] === "object"
            ? (props.Data || []).map((name) => (
                <MenuItem
                  key={name?.key}
                  value={name?.value}
                  className={props.disabledCapitalize ? classes.MenuItemNoneCapitalize : classes.MenuItemCapitalize}
                >
                  <Checkbox checked={selectvalue.indexOf(name?.value) > -1} style={{ color: "#1789FC" }} />
                  <ListItemText
                    primary={name?.key}
                    sx={{
                      ".MuiTypography-root": {
                        fontSize: "16px !important",
                        fontFamily: "Roboto !important",
                        color: "#2F2F2F !important",
                        fontStyle: "medium !important",
                        lineHeight: "24px !important",
                        fontWeight: 500,
                        align: "left",
                        verticalAlign: "center",
                        paddingLeft: "15px",
                      },
                    }}
                  />
                </MenuItem>
              ))
            : (props.Data || []).map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  className={props.disabledCapitalize ? classes.MenuItemNoneCapitalize : classes.MenuItemCapitalize}
                >
                  <Checkbox checked={selectvalue.indexOf(name) > -1} style={{ color: "#1789FC" }} />
                  <ListItemText
                    primary={name}
                    sx={{
                      ".MuiTypography-root": {
                        fontSize: "16px !important",
                        fontFamily: "Roboto !important",
                        color: "#2F2F2F !important",
                        fontStyle: "medium !important",
                        lineHeight: "24px !important",
                        fontWeight: 500,
                        align: "left",
                        verticalAlign: "center",
                        paddingLeft: "15px",
                      },
                    }}
                  />
                </MenuItem>
              ))}
          <Grid sx={{ paddingTop: "15px" }}>
            {props.OnSelectAll && (
              <Button sx={{ textTransform: "none" }} variant="text" text="Select All" onClick={props.OnSelectAll} />
            )}
            <Button
              sx={{ float: "right", textTransform: "none" }}
              variant="text"
              text="Clear All"
              onClick={props.OnClearAll}
            />
          </Grid>
        </Select>
      </FormControl>
    </div>
  );
}
