import React from "react";
import { Box, CssBaseline, Drawer, Toolbar, Typography, List, ListItem, ListItemButton, Grid } from "@mui/material";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import GettingStarted from "./gettingStarted/GettingStarted";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";

const useStyles = makeStyles({
  selectedItem: {
    fontWeight: "bold !important",
  },
});

const DocumentationContent = () => {
  const classes = useStyles();

  const [selectedSection, setSelectedSection] = React.useState("gettingStarted");
  const drawerWidth = "20%";

  const handleListItemClick = (section) => {
    setSelectedSection(section);
  };

  const drawer = (
    <div style={{ margin: "0px auto" }}>
      <div>
        <Grid sx={{ m: 2 }}>
          <CustomBreadcrumbs links={[{ name: "Home", path: "/home" }, { name: "Documentation" }]} />
          <Typography sx={{ mt: 2 }} variant="h5">
            Documentation
          </Typography>
        </Grid>
      </div>
      <List>
        <ListItem sx={{ paddingLeft: "0px" }} key={"gettingStarted"}>
          <ListItemButton onClick={() => handleListItemClick("gettingStarted")}>
            <Typography className={selectedSection === "gettingStarted" ? classes.selectedItem : ""}>
              Getting Started
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  let selectedComponent;
  switch (selectedSection) {
    case "gettingStarted":
      selectedComponent = <GettingStarted />;
      break;
    default:
      selectedComponent = <div>No section selected</div>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            marginTop: "16px",
            marginLeft: "auto",
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              // backgroundColor: "#fffbf6",
            },
          }}
        >
          {drawer}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {selectedComponent}
      </Box>
    </Box>
  );
};

export default DocumentationContent;
