/* eslint-disable */
// eslint warning removed on 2023-12-15 by FK for this file we will review it once we make significant changes
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Container, Grid, Link, useMediaQuery, Snackbar } from "@mui/material";
import "../home/home.css";
import { generatePath, useNavigate } from "react-router-dom";
import Button from "../../components/controls/Button";
import { theme, Text } from "../../styles/theme";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/controls/TextField";
import quiz from "../../redux/actions/quiz";
import AppConfig from "../../constants/AppConfig";
import ResumeUpload from "../../components/ResumeUpload";
import { getObject, removeAnswers } from "../../services/globalService";
// import { useTheme } from "@mui/material/styles";
import SubmitConfirmationDialog from "../../components/SubmitConfirmationDialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExitDialog from "../../components/Exitmodel";
// import BayoneLogo from "../../assets/svg/bayonelogo.svg";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "64px !important",
      paddingRight: "64px !important",
    },
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "100px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
  },
  cardTwo: {
    marginTop: "24px",
  },
  cardThree: {
    // marginTop: "68px",
    padding: 40,
  },
  card: {
    // marginTop: "48px",
    marginBottom: 24,
    textAlign: "left",
    [theme.breakpoints.up("tablet")]: {},
  },
  cardSix: {
    marginTop: "24px",
    // paddingBottom: "133px",
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
  },
  cardTwoH1: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardFourH1: {
    margin: "auto",
  },
  info: {
    marginTop: "40px",
    display: "flex",
    alignItems: "center",
  },
  sectionInfo: {
    marginTop: "2%",
  },
  rightArrowIcon: {
    height: "10px",
    marginLeft: "2.4%",
    width: "16px",
  },
  statusIcon: {
    position: "absolute",
  },
  main: {},
  item1: {
    marginBottom: "32px",
    height: "60px",
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    position: "sticky",
    top: "0px",
    zIndex: 1,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  item2: {
    marginTop: "32px",
  },
  item3: {
    marginTop: "32px",
  },
  item4: {
    marginTop: "16px",
  },
  answers: {
    marginTop: "24px",
  },
  item5: {
    height: "80px",
    // backgroundColor: "#ffffff",
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  next: {
    "& button": {
      height: "40px",
    },
  },
  progess: {
    color: theme.palette.btnPrimary.tertiaryText,
  },
  font16: {
    // fontSize: theme.typography.link3.fontSize,
  },
  questionArea: {
    display: "flex",
  },
  qIndex: {
    // flex: "10%"
  },
  qTitle: {
    flex: "90%",
  },
  sidebar: {
    width: 500,
  },
  actionLink: {
    marginTop: 16,
  },
  cloudUploadIcon: {
    width: "24px",
    height: "16px",
    margin: "auto",
    marginTop: "20px",
  },
  uploadedImageSection: {
    width: "100%",
    height: "120px",
    margin: "auto",
    marginTop: "40px",
    background: "#FFFFFF",
    borderRadius: "4px",
  },
  uploadImageSection: {
    border: "1px dotted gray",
    width: "100%",
    height: "120px",
    margin: "auto",
    marginTop: "40px",
    position: "relative",
  },
  fileInput: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  uploadIconSection: {
    background: "#EBEFF8",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
  },
  errorIconSection: {
    background: "#DD2E2E",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
  },
  sucessIconSection: {
    background: "#61A2E4",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "91px",
  },
  commonUploadSection: {
    width: "646px",
    margin: "auto",
    marginTop: "30px",
    background: "#FFFFFF",
    height: "91px",
  },
  uploadProgress: {
    width: "646px",
    margin: "auto",
  },
  marginTop20: {
    marginTop: "20px",
  },
  marginLeft30: {
    marginLeft: "30px",
  },

  dangerIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "3%",
  },
  height100Percent: {
    height: "100%",
  },
  alignBottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  colorGray: {
    color: "#787878 !important",
  },
  cloudUploadIconSection: {
    margin: "auto",
    width: "500px",
    marginTop: "10px",
    lineHeight: "20px",
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  uploadIconText: {
    color: "gray",
    marginTop: "6px",
  },
  nextBtn: {
    background: "rgba(235, 239, 248, 1)",
    height: "130px",
    width: "100%",
    position: "fixed",

    bottom: "0px",
    left: "0px",
    right: "0px",
    marginBottom: "0px",
  },
  reUploadFile: {
    color: "#1789FC",
    fontWeight: "700",
    textAlign: "center",
    marginTop: "6px",
    fontSize: "16px",
  },
  reUploadSection: {
    position: "relative",
    height: "35px",
    width: "200px",
    border: "1.5px solid #1789FC",
    borderRadius: "5px",
    marginTop: "35px",
  },
  actionBtn: {
    marginTop: "24px",
  },
  stickToBottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  resumeArea: {
    marginTop: "32px",
  },
  // labelBlue: { // FK cleanup duplicate css property
  //   color: theme.palette.btnPrimary.main,
  // },
  // cardFourH1: {
  //   marginLeft: "35%",
  //   marginRight: "35%",
  // },

  inputCt: {
    width: "100%",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    [theme.breakpoints.up("tablet")]: {
      //width: "646px",
    },
  },
  formControl: {
    marginTop: 25,
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.background.light,
  },
  submit: {
    marginTop: "48px",
    width: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },
  arrowImg: {
    marginLeft: 8,
  },
  backArrowImg: {
    marginRight: 8,
  },
  phoneErr: {
    marginLeft: "23%",
    marginTop: "2%",
  },
  fontSize24: {
    fontSize: "18px",
  },
  formCls: {
    paddingBottom: "140px",
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  exit: {
    color: "#787878 !important",
    //theme.palette.neutrals.grayDark,
  },
  pageInfo: {
    height: "36px",
    width: "145px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  submitText: {
    marginTop: "2rem !important",
    fontSize: theme.typography.b3Regular.fontSize,
  },
  policycolor: {
    color: theme.palette.btnPrimary.main,
    cursor: "pointer",
  },
  Loader: {
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: "50%",
  },
  link2: theme.typography.link2,
  b1Medium: theme.typography.b1Medium,
  error: theme.typography.error,
  texth4: theme.typography.h4,
});

function ProfileQuestions(props) {
  // const { location } = props;
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const resumeName = useSelector((state) => state.auth.resumeName);
  const quizState = useSelector((state) => state.quiz);
  const [exitopen, setExitopen] = useState(false);
  const roleState = useSelector((state) => state?.role);
  const [isShowSubmitDialog, setShowSubmitDialog] = useState(false);
  const { /*stepToWelcomeScreenMap*/ categoryTheme } = AppConfig;
  let category = "Profile";
  // const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(AppConfig.UploadStatus.INITIAL);
  const [uploadPercentage, setPerCentageValue] = useState(0);
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  const resumeDetails = {
    resumeName: authState.resumeName || roleState?.candidateDashboardDetails.resumeName,
    resumeSize: authState.resumeSize || roleState?.candidateDashboardDetails.resumeSize,
    resumeUrl: authState.resumeUrl || roleState?.candidateDashboardDetails.resumeUrl,
  };
  const [logoUrl, setLogoUrl] = React.useState(process.env.REACT_APP_ASSETS_URL);
  const [domainDetails, setDomainDetails] = useState({});

  useEffect(() => {
    if (resumeName || roleState?.candidateDashboardDetails.resumeName) {
      setPerCentageValue(100);
      setUploadStatus(AppConfig.UploadStatus.UPLOADED);
    }
  }, [resumeName || roleState?.candidateDashboardDetails.resumeName]);

  useEffect(() => {
    if (quizState.testResult) {
      // console.log("Fetching userProfile...");

      // let obj = { // FK cleanup unused variable
      //   level: (location && location.level && location.level) || "",
      //   ...formik.values,
      // };

      if (userDetails?.role === "Candidate") {
        history("/candidatedashboard");
      } else {
        history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
      }
    }
    // eslint-disable-next-line
  }, [quizState.testResult]);

  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => o1[p] === o2[p]);

  const handleExitFullscreen = () => {
    // Check if the document is currently in fullscreen mode
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const onSubmit = () => {
    const currentResumeDetails = {
      resumeName:
        authState.resumeName ||
        authState?.resumeRequest?.originalname ||
        roleState?.candidateDashboardDetails.resumeName,
      resumeSize:
        authState.resumeSize || authState?.resumeRequest?.size || roleState?.candidateDashboardDetails.resumeSize,
      resumeUrl: authState.resumeUrl || roleState?.candidateDashboardDetails.resumeUrl,
      //|| authState.userProfile.resumeUrl,
    };
    if (
      authState &&
      !authState.resumeUrl &&
      !authState.resumeName &&
      !authState.resumeSize &&
      // !authState.userProfile.resumeUrl &&
      !authState?.resumeRequest?.originalname &&
      !authState?.resumeRequest?.size &&
      !roleState?.candidateDashboardDetails.resumeName
    ) {
      setShowSubmitDialog(true);
      // createProfile(formik.values);
    } else if (!objectsEqual(initialValues, formik.values)) {
      createProfile(formik.values);
    } else if (!objectsEqual(resumeDetails, currentResumeDetails)) {
      createProfile(formik.values);
    } else {
      createProfile(formik.values);
    }
    handleExitFullscreen();
  };

  const createProfile = (data) => {
    var result = [];
    if (roleState?.candidateDashboardDetails.resumeUrl) {
      var o = getObject("Question_ID", 50001, quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: roleState?.candidateDashboardDetails.resumeUrl,
        });
      }
    }

    if (data.linkedInUrl) {
      //field value for linkedin
      // FK cleanup variable o is already defined july 2023
      var o = getObject("Question_Label", "LinkedIn Profile", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.linkedInUrl,
        });
      }
    } else {
      var o = getObject("Question_Label", "LinkedIn Profile", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.githubUrl) {
      // field value for githuburl
      var o = getObject("Question_Label", "GitHub", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.githubUrl,
        });
      }
    } else {
      // FK cleanup o already defined july 2023
      var o = getObject("Question_Label", "GitHub", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.stackoverflowUrl) {
      // field value for stackoverflow
      var o = getObject("Question_Label", "Stack Overflow", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.stackoverflowUrl,
        });
      }
    } else {
      var o = getObject("Question_Label", "Stack Overflow", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.LeetCode) {
      // field value for leetcode
      var o = getObject("Question_Label", "LeetCode", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.LeetCode,
        });
      }
    } else {
      var o = getObject("Question_Label", "LeetCode", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.HackerRank) {
      // field value for hacker rank
      var o = getObject("Question_Label", "HackerRank", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.HackerRank,
        });
      }
    } else {
      var o = getObject("Question_Label", "HackerRank", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.HackerEarth) {
      // field for hacker earth
      var o = getObject("Question_Label", "HackerEarth", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.HackerEarth,
        });
      }
    } else {
      var o = getObject("Question_Label", "HackerEarth", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    if (data.TopCoder) {
      // field value for topcoder
      var o = getObject("Question_Label", "TopCoder", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.TopCoder,
        });
      }
    } else {
      var o = getObject("Question_Label", "TopCoder", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }
    if (data.CodeForces) {
      // field value for codeforces
      var o = getObject("Question_Label", "CodeForces", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.CodeForces,
        });
      }
    } else {
      var o = getObject("Question_Label", "CodeForces", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }
    if (data.SPOJ) {
      // field value for spoj
      var o = getObject("Question_Label", "SPOJ", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: data.SPOJ,
        });
      }
    } else {
      var o = getObject("Question_Label", "SPOJ", quizState.questions);
      if (o) {
        result.push({
          Question_ID: o.Question_ID,
          User_Answers: "",
        });
      }
    }

    let payload = {
      code: quizState.sessionByLevel,
      email: roleState?.candidateDashboardDetails
        ? roleState?.candidateDashboardDetails?.candidate?.user?.email
        : "notregistered",
      result: result,
      //inviteId:roleState?.candidateDashboardDetails?.uid
    };
    let payload1 = {
      data: payload,
      inviteId: roleState?.candidateDashboardDetails?.uid,
    };
    // console.log("createProfile payload: ", payload);

    dispatch(quiz.submitTestResults(payload1));
  };

  useEffect(() => {
    if (quizState?.testResult !== null) {
      if (userDetails?.role === "Candidate") {
        history("/candidatedashboard");
      } else {
        history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
      }
    }
    // eslint-disable-next-line
  }, [quizState?.testResult]);

  const initialValues = {
    linkedInUrl: roleState?.candidateDashboardDetails?.LinkedIn_Profile || "",
    githubUrl: roleState?.candidateDashboardDetails?.GitHub || "",
    LeetCode: roleState?.candidateDashboardDetails?.LeetCode || "",
    HackerRank: roleState?.candidateDashboardDetails?.HackerRank || "",
    stackoverflowUrl: roleState?.candidateDashboardDetails?.Stack_Overflow || "",
    HackerEarth: roleState?.candidateDashboardDetails?.HackerEarth || "",
    TopCoder: roleState?.candidateDashboardDetails?.TopCoder || "",
    CodeForces: roleState?.candidateDashboardDetails?.CodeForces || "",
    SPOJ: roleState?.candidateDashboardDetails?.SPOJ || "",
  };

  const validationSchema = Yup.object().shape({
    linkedInUrl: Yup.string()
      .transform(function (value) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(linkedin\.com)\//, "Enter correct URL!"),

    githubUrl: Yup.string()
      .transform(function (value) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(github\.com|gitlab\.com)\//, "Enter correct URL!"),
    stackoverflowUrl: Yup.string()
      .transform(function (value) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(stackoverflow\.com)(\/users\/)/, "Enter correct url!"),
    LeetCode: Yup.string()
      .transform(function (value) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(leetcode\.com)\//, "Enter correct URL!"),
    HackerRank: Yup.string()
      .transform(function (value) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(hackerrank\.com)\//, "Enter correct URL!"),
    HackerEarth: Yup.string()
      .transform(function (value) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(hackerearth\.com)\//, "Enter correct URL!"),
    TopCoder: Yup.string()
      .transform(function (value) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(topcoder\.com)(\/members\/)/, "Enter correct URL!"),
    CodeForces: Yup.string()
      .transform(function (value, originalvalue) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(codeforces\.com)(\/profile\/)/, "Enter correct URL!"),
    SPOJ: Yup.string()
      .transform(function (value, originalvalue) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/(spoj\.com)(\/users\/)/, "Enter correct URL!"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const onConfirm = () => {
    createProfile(formik.values);
  };

  // AR - code commented on 2023-12-15
  // **
  // useEffect(() => {
  //   if (roleState && roleState?.candidateDashboardDetails) {
  //     let tempCount = 0;
  //     if (
  //       !roleState?.candidateDashboardDetails?.L3ProfileScore ||
  //       (roleState?.candidateDashboardDetails?.L3ProfileScore &&
  //         roleState?.candidateDashboardDetails?.L3ProfileScore === 0)
  //     ) {
  //       tempCount += 1;
  //     }
  //     if (
  //       !roleState?.candidateDashboardDetails?.L3CodingScore ||
  //       (roleState?.candidateDashboardDetails?.L3CodingScore &&
  //         roleState?.candidateDashboardDetails?.L3CodingScore === 0)
  //     ) {
  //       tempCount += 1;
  //     }
  //     if (
  //       !roleState?.candidateDashboardDetails?.L2TechnicalScore ||
  //       (roleState?.candidateDashboardDetails?.L2TechnicalScore &&
  //         roleState?.candidateDashboardDetails?.L2TechnicalScore === 0)
  //     ) {
  //       tempCount += 1;
  //     }
  //     if (
  //       !roleState?.candidateDashboardDetails?.L3PresentationScore ||
  //       (roleState?.candidateDashboardDetails?.L3PresentationScore &&
  //         roleState?.candidateDashboardDetails?.L3PresentationScore === 0)
  //     ) {
  //       tempCount += 1;
  //     }
  //     if (tempCount === 0) {
  //         history("/ProfileQuestions");
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [roleState?.candidateDashboardDetails]);

  const handleclick = () => {
    setExitopen(true);
  };

  const handleExit = () => {
    dispatch(quiz.exitProfileSession({ code: quizState.sessionByLevel }));
    removeAnswers();
    dispatch(quiz.setSelectedQuesNdAns([]));
    dispatch(quiz.clearState());
    if (userDetails?.role === "Candidate") {
      history("/candidatedashboard");
    } else {
      history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
    }
    setExitopen(false);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "auto",
        /* you can also use 'auto' behaviour
                  in place of 'smooth' */
      });
    }, 500);
  };

  useEffect(() => {
    if (authState.logoDetails?.logo == "") {
      setDomainDetails({ logo: localStorage.getItem("logo"), displayName: "" });
    }
  }, [localStorage.getItem("logo")]);

  return (
    <div>
      <div className={classes.root}>
        <div className={`${classes.item1} ${classes.center} `}>
          <Container
            maxWidth="xl"
            className={classes.container}
            //   style={{ width: "70%" }}
          >
            <div className={classes.main}>
              <Grid container className="Home-hero" alignItems="center">
                <Grid item xs={4} onClick={handleclick} style={{ cursor: "pointer" }}>
                  {/* <img src={BayoneLogo} alt={"Bayone Logo"} /> */}
                  <div>
                    {domainDetails && domainDetails?.logo !== "" ? (
                      <>
                        <img
                          height="33"
                          width="157"
                          src={`${logoUrl}${domainDetails?.logo}`}
                          alt={domainDetails?.displayName}
                        />
                        <br />
                        <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
                      </>
                    ) : (
                      <a href="https://techscore.ai" target="_self" rel="noreferrer">
                        <img height="50" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
                      </a>
                    )}
                  </div>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} className={classes.exitBtn}>
                  <Grid onClick={handleclick} style={{ textDecoration: "none !important", cursor: "pointer" }}>
                    <Typography
                      // component={Text}
                      variant2="link2"
                      align="right"
                      className={`${classes.exit} ${classes.link2}`}
                    >
                      Exit
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container maxWidth="xl" className={`${classes.container}`}>
          <Grid container justifyContent="center">
            {isNotMobile && <Grid item xs={1}></Grid>}
            <Grid item xs={isNotMobile ? 8 : 12}>
              <Grid container className={`${classes.section} ${classes.card}`} justifyContent="flex-start">
                <div
                  className={`${classes.section} ${classes.pageInfo} ${classes.center}`}
                  style={{
                    border: categoryTheme.hasOwnProperty(category)
                      ? `1px solid ${categoryTheme[category].bgColor}`
                      : "",
                  }}
                >
                  <Typography
                    // component={Text}
                    variant2="b2Medium"
                    align="center"
                    style={{
                      margin: "0px 10px",
                      color: categoryTheme.hasOwnProperty(category) ? `${categoryTheme[category].bgColor}` : "",
                    }}
                  >
                    {category} Section
                  </Typography>
                </div>
              </Grid>

              <ResumeUpload></ResumeUpload>
              <div className={`${classes.section} ${classes.cardTwo}`} style={{ marginTop: "35px" }}>
                <Typography
                  // component={Text}
                  variant2={isNotMobile ? "h4" : "h5"}
                  align="left"
                  className={`${classes.cardTwoH1} ${classes.title}  ${classes.texth4}`}
                  style={theme.typography.h4}
                >
                  Add your networking profile links
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  Social media profiles are optional, but recommended. These will be considered for scoring only where
                  links are provided. A strong social media presence may help improve the candidate score.
                </Typography>
              </div>
              <div className={`${classes.section}  ${classes.card}`}>
                <Typography variant="subtitle2" align="center" className={`${classes.cardFourH1}`}></Typography>
              </div>

              <form onSubmit={formik.handleSubmit} className={classes.formCls}>
                <Container className={classes.inputCt}>
                  <div className={classes.formControl}>
                    <label htmlFor="linkedInUrl">
                      <Typography
                        component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        LinkedIn
                      </Typography>
                    </label>
                    <TextInput
                      name="linkedInUrl"
                      placeholder="Ex. https://www.linkedin.com/in/jennydoe/"
                      className={classes.field}
                      id="linkedInUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.linkedInUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.linkedInUrl}
                    ></TextInput>
                    {formik.touched.linkedInUrl && formik.errors.linkedInUrl ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.linkedInUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.formControl}>
                    <label htmlFor="githubUrl">
                      <Typography
                        // component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        GitHub
                      </Typography>
                    </label>
                    <TextInput
                      name="githubUrl"
                      placeholder="Ex. https://github.com/jennydoe/"
                      className={classes.field}
                      id="githubUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.githubUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.githubUrl}
                      type="githubUrl"
                    ></TextInput>
                    {formik.touched.githubUrl && formik.errors.githubUrl ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.githubUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.formControl}>
                    <label htmlFor="stackoverflowUrl">
                      <Typography
                        // component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        Stack Overflow
                      </Typography>
                    </label>
                    <TextInput
                      name="stackoverflowUrl"
                      placeholder="Ex. https://stackoverflow.com/users/9999/jennydoe"
                      className={classes.field}
                      id="stackoverflowUrl"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.stackoverflowUrl)}
                      onBlur={formik.handleBlur}
                      value={formik.values.stackoverflowUrl}
                    ></TextInput>
                    {formik.touched.stackoverflowUrl && formik.errors.stackoverflowUrl ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.stackoverflowUrl}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.formControl}>
                    <label htmlFor="LeetCode">
                      <Typography
                        // component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        LeetCode
                      </Typography>
                    </label>
                    <TextInput
                      name="LeetCode"
                      placeholder="Ex. https://leetcode.com/jennydoe/"
                      className={classes.field}
                      id="LeetCode"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.LeetCode)}
                      onBlur={formik.handleBlur}
                      value={formik.values.LeetCode}
                    ></TextInput>
                    {formik.touched.LeetCode && formik.errors.LeetCode ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.LeetCode}
                        </Typography>
                      </div>
                    ) : null}
                  </div>

                  <div className={classes.formControl}>
                    <label htmlFor="HackerRank">
                      <Typography
                        // component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        HackerRank
                      </Typography>
                    </label>
                    <TextInput
                      name="HackerRank"
                      placeholder="Ex. https://www.hackerrank.com/jennydoe"
                      className={classes.field}
                      id="HackerRank"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.HackerRank)}
                      onBlur={formik.handleBlur}
                      value={formik.values.HackerRank}
                    ></TextInput>
                    {formik.touched.HackerRank && formik.errors.HackerRank ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.HackerRank}
                        </Typography>
                      </div>
                    ) : null}
                  </div>

                  <div className={classes.formControl}>
                    <label htmlFor="HackerEarth">
                      <Typography
                        // component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        HackerEarth
                      </Typography>
                    </label>
                    <TextInput
                      name="HackerEarth"
                      placeholder="Ex. https://www.hackerearth.com/jennydoe/"
                      className={classes.field}
                      id="HackerEarth"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.HackerEarth)}
                      onBlur={formik.handleBlur}
                      value={formik.values.HackerEarth}
                    ></TextInput>
                    {formik.touched.HackerEarth && formik.errors.HackerEarth ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.HackerEarth}
                        </Typography>
                      </div>
                    ) : null}
                  </div>

                  <div className={classes.formControl}>
                    <label htmlFor="TopCoder">
                      <Typography
                        // component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        Topcoder
                      </Typography>
                    </label>
                    <TextInput
                      name="TopCoder"
                      placeholder="Ex. https://www.topcoder.com/members/jennydoe"
                      className={classes.field}
                      id="TopCoder"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.TopCoder)}
                      onBlur={formik.handleBlur}
                      value={formik.values.TopCoder}
                    ></TextInput>
                    {formik.touched.TopCoder && formik.errors.TopCoder ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.TopCoder}
                        </Typography>
                      </div>
                    ) : null}
                  </div>

                  <div className={classes.formControl}>
                    <label htmlFor="CodeForces">
                      <Typography
                        // component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        Codeforces
                      </Typography>
                    </label>
                    <TextInput
                      name="CodeForces"
                      placeholder="Ex. https://codeforces.com/profile/jennydoe"
                      className={classes.field}
                      id="CodeForces"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.CodeForces)}
                      onBlur={formik.handleBlur}
                      value={formik.values.CodeForces}
                    ></TextInput>
                    {formik.touched.CodeForces && formik.errors.CodeForces ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.CodeForces}
                        </Typography>
                      </div>
                    ) : null}
                  </div>

                  <div className={classes.formControl}>
                    <label htmlFor="Sphere Online Judge">
                      <Typography
                        // component={Text}
                        variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                        align="left"
                        className={classes.b1Medium}
                      >
                        Sphere Online Judge
                      </Typography>
                    </label>
                    <TextInput
                      name="SPOJ"
                      placeholder="Ex. https://www.spoj.com/users/jennydoe"
                      className={classes.field}
                      id="SPOJ"
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.SPOJ)}
                      onBlur={formik.handleBlur}
                      value={formik.values.SPOJ}
                    ></TextInput>
                    {formik.touched.SPOJ && formik.errors.SPOJ ? (
                      <div className="error">
                        <Typography
                          // component={Text}
                          variant2="error"
                          align="left"
                          className={classes.error}
                        >
                          {formik.errors.SPOJ}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </Container>
                <Typography
                  // component={Text}
                  align="center"
                  className={classes.submitText}
                >
                  By submitting your profile information, you agree to our{" "}
                  <span>
                    <Link onClick={() => history("/privacypolicy")} className={classes.policycolor}>
                      Privacy Policy
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link className={classes.policycolor} onClick={() => history("/terms&conditions")}>
                      Terms of Use
                    </Link>
                  </span>
                  .
                </Typography>
              </form>
            </Grid>
            {isNotMobile && <Grid item xs={1}></Grid>}
          </Grid>
        </Container>

        <div className={`${classes.item5} ${classes.center} ${classes.stickToBottom}`}>
          <Container maxWidth="xl" className={classes.container}>
            <div className={classes.main}>
              <Grid
                container
                className="Home-hero"
                justifyContent="center"
                //className={classes.next}
              >
                <Button
                  sx={{ color: "#FFFF !important" }}
                  color={!formik.isValid ? "disablebtn" : "btnPrimary1"}
                  disabled={!formik.isValid}
                  text="Done"
                  width={isNotMobile ? "300px" : "300px"}
                  onClick={() => onSubmit()}
                  data-rt-profile-submit="submit"
                ></Button>
              </Grid>
            </div>
          </Container>
        </div>
      </div>

      <SubmitConfirmationDialog
        open={isShowSubmitDialog}
        text="Saving changes without a resume can significantly impact your scores. Do you want to continue with the changes?"
        action=""
        onSubmit={onConfirm}
        onClose={() => setShowSubmitDialog(false)}
        TimeClose={() => setOpenSnackBar(true)}
      ></SubmitConfirmationDialog>
      <ExitDialog
        open={exitopen}
        OnExit={handleExit}
        onClose={() => setExitopen(false)}
        category={category}
        TimeClose={() => setOpenSnackBar(true)}
      ></ExitDialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackBar(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default ProfileQuestions;
