import React from "react";
import ReusableDialog from "./controls/ReusableDialog";

export default function ExitDialog(props) {
  // eslint-disable-next-line
  const { open, text, succesBtnLabel, actionInfo, ...other } = props;

  const handleExitFullscreen = () => {
    // Check if the document is currently in fullscreen mode
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const onClickSubmit = () => {
    props?.OnExit();
    handleExitFullscreen();
  };
  const closeModel = () => {
    if (navigator.onLine) {
      props.onClose();
    } else {
      props.TimeClose();
    }
  };

  return (
    <div>
      <ReusableDialog
        open={open}
        // title="Sample Dialog"
        content={
          props.category === "Technical" || props.category === "Coding"
            ? "This section will be automatically submitted if you exit now.You will not be able to attempt it again.Do you wish to exit?"
            : props.category === "Presentation"
            ? "This section will be automatically saved if you exit now, and you can resume it later.Do you wish to exit?"
            : "Your answers will not be saved if you exit now. Do you wish to leave?"
        }
        actions={[
          { onClick: closeModel, text: "Cancel", color: "btnCls1", variant: "outlined" },
          { onClick: onClickSubmit, text: "Yes, exit", color: "btn1" },
        ]}
      />
    </div>
  );
}
