import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
// import { style } from "@mui/system";

const AutoPlaySwipeableViews = SwipeableViews;

// uncomment for auto swapping
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles({
  stepper: {
    "& .MuiMobileStepper-dot": {
      width: "5px",
      height: "5px",
    },
    "& .MuiMobileStepper-dotActive": {
      width: "5px",
      height: "5px",
    },
  },
});

// colors to use for skills
const skillColors = {
  "AI and Emerging Technologies": "rgba(255, 214, 91, 0.5)",
  "Database and Backend Development": "rgba(140, 240, 240, 0.5)",
  "Data and Analytics": "rgba(178, 224, 97, 0.5)",
  "DevOps and Cloud Computing": "rgba(193, 163, 224, 0.5)",
  "Networking and Security": "rgba(253, 204, 229, 0.5)",
  "Product and Program Management": "rgba(139, 211, 199, 0.5)",
  // Unknown: "rgba(212, 188, 168, 0.5)",
  "Programming Languages": "rgba(255, 160, 122, 0.5)",
  "Quality Engineering": "rgba(240, 139, 139, 0.5)",
  "Web and App Development": "rgba(184, 214, 240, 0.5)",
  CAT2: "rgba(144, 209, 176, 0.5)",
  Others: "rgba(223, 223, 223, 0.5)",
};

function SwipeableTextMobileStepper({ skillArrObject }) {
  const classes = useStyles();
  const groupSize = 10;
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  // handle next
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // handle back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  function chunkArray(data, chunkSize) {
    return data.reduce((acc, curr, index) => {
      const groupIndex = Math.floor(index / chunkSize);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex].push(curr);
      return acc;
    }, []);
  }

  const newkillArrObject = chunkArray(skillArrObject, groupSize);

  const maxSteps = newkillArrObject?.length;

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: 400,
        justifyContent: "space-between",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", flexGrow: 1 }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {newkillArrObject.map((skillArrObject, index) => (
            <>
              {skillArrObject?.map(({ fullName, skill, category }, index) => (
                <Chip
                  key={index}
                  label={fullName}
                  sx={{
                    backgroundColor: skillColors[category] || "#9e9e9e",
                    color: "black",
                    margin: "2px",
                    fontSize: "12px",
                  }}
                />
              ))}
            </>
          ))}
        </AutoPlaySwipeableViews>
      </div>

      {newkillArrObject?.length >= 1 && (
        <MobileStepper
          className={classes.stepper}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          }
        />
      )}
    </Box>
  );
}

export default SwipeableTextMobileStepper;
