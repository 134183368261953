// import ReactGA from "react-ga4";
// import { ga4config } from "../ga4config";

// const TRACKING_IDS = ga4config.trackingIds

// TRACKING_IDS.forEach((trackingId, index) => {
//   ReactGA.initialize(trackingId, {
//     gaOptions: { name: `tracker${index + 1}` },
//   });
// });

// TRACKING_IDS.forEach((trackingId, index) => {
//   ReactGA.send(
//     {
//       hitType: "pageview",
//       page: window.location.pathname + window.location.search,
//       title: "Home Page",
//     },
//     { name: `tracker${index + 1}` }
//   );
// });

// const analyticsEvent = (category, action, label) => {
//   ReactGA.event({
//     category,
//     action,
//     label,
//   });
// };

// const trackPageView = (pageName) => {
//   TRACKING_IDS.forEach((trackingId, index) => {
//     ReactGA.send(
//       {
//         hitType: "pageview",
//         page: pageName,
//       },
//       { name: `tracker${index + 1}` }
//     );
//   });
// };

// export { analyticsEvent, trackPageView };


///////////////////////////////////////////////////////

import ReactGA from "react-ga4";
import { ga4config } from "../ga4config";

const trackingConfigs = ga4config.tracking;

trackingConfigs.forEach((trackingConfig) => {
  const { measurementId, subdomain } = trackingConfig;

  ReactGA.initialize(measurementId, {
    gaOptions: { name: subdomain },
  });

  ReactGA.send(
    {
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Home Page",
    },
    { name: subdomain }
  );
});

const analyticsEvent = (category, action, label) => {
  trackingConfigs.forEach((trackingConfig) => {
    const { subdomain } = trackingConfig;
    
    ReactGA.event({
      category,
      action,
      label,
      name: subdomain, // Pass the subdomain as the 'name' option
    });
  });
};

const trackPageView = (pageName) => {
  trackingConfigs.forEach((trackingConfig) => {
    const { subdomain } = trackingConfig;

    ReactGA.send(
      {
        hitType: "pageview",
        page: pageName,
      },
      { name: subdomain }
    );
  });
};

export { analyticsEvent, trackPageView };
