import React from "react";
import { makeStyles } from "@mui/styles";
import { InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { theme } from "../../styles/theme";
const useStyles = makeStyles({
  noBorder: {
    border: "none !important",
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px !important",
      color: "#787878 !important",
    },
  },
  root: {
    "&.Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none",
    },
  },
  textField: {
    marginTop: "0px",
    marginBottom: "0px",
    minHeight: "30px",
  },
  input: (props) => ({
    fontFamily: theme.typography.input.fontFamily,
    fontSize: props.customFontSize ? "16px !important" : theme.typography.input.fontSize,
    fontStyle: theme.typography.input.fontStyle,
    fontWeight: theme.typography.input.fontWeight,
    lineHeight: theme.typography.input.lineHeight,
    letterSpacing: theme.typography.input.letterSpacing,
    textAlign: theme.typography.input.textalign,
    //  color: theme.appThemeColors.inputValuecolor,
    // background:theme.appThemeColors.white,
    borderRadius: "8px",
    padding: "14px !important",
    border: "none",
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder": {
      fontSize: "16px !important",
      color: "red !important",
    },
    "&:hover": {
      outline: "none",
    },
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #EBEFF8 inset",
    },
  }),
  IconVisible: {
    background: "#F8F8F8",
    padding: "14.9px",
    borderRadius: " 0px 4px 4px 0px",
    "& .MuiIconButton-root:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  IconColor: {
    color: "#C4C4C4",
  },
});

const TextInput = (props) => {
  const {
    name,
    value,
    error = null,
    onChangeText,
    placeholder,
    id,
    type,
    showPassword,
    defaultValue,
    customFontSize,
    ...other
  } = props;

  const handleClickShowPassword = () => {
    props?.setShowPassword(!showPassword);
  };
  const classes = useStyles(props);

  return (
    <>
      <TextField
        sx={{
          ".MuiInputBase-root-MuiOutlinedInput-root": {
            borderRadius: "0px !important",
          },
          //  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder": {
          //       fontSize: "16px !important",
          //       color: "black !important",
          //     }
        }}
        variant="outlined"
        margin="normal"
        //   autoComplete='none'
        fullWidth
        id={id}
        name={name}
        value={value}
        type={type}
        className={classes.textField}
        onChange={onChangeText}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...other}
        {...(error && { error: true, helperText: error })}
        InputProps={{
          className: classes.root,
          classes: { notchedOutline: classes.noBorder, input: classes.input, root: classes.customTextField },
          sx: {
            "& .MuiOutlinedInput-input::placeholder": {
              fontSize: "16px !important",
              color: "#787878 !important",
              opacity: 1,
            },
          },
          autoComplete: "new-password",
          endAdornment: props?.passwordText ? (
            <InputAdornment position="end">
              <IconButton
                className={classes.IconVisible}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                // onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? (
                  <Visibility style={{ color: theme.palette.mainColor }} />
                ) : (
                  <Visibility className={classes.IconColor} />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </>
  );
};

export default TextInput;
