import React, { useEffect, useState, Fragment } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, ToggleButtonGroup, ToggleButton, Skeleton } from "@mui/material";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
// import debouce from 'lodash.debounce';
import NavBar from "../../components/NavBar";
import Button from "../../components/controls/Button";
import GridTable from "../../components/Table";
import Popup from "../../components/Popup";
import LOneExampleContent from "../../components/dialogContent/LOneExampleContent";
import HeaderConfigs from "../../constants/HeaderConfigs";
import { theme } from "../../styles/theme";
import InfoIcon from "../../assets/svg/info.svg";
import role from "../../redux/actions/role";
import auth from "../../redux/actions/auth";
import { sortJson, applySearch } from "../../services/globalService";
import SearchBar from "../../components/controls/search";
import Footer from "../../components/Footer";
// import VerticalLinearStepper from "./VerticalLinearStepper";
import Tour from "../../components/Tour";
// import TrialExpiryNotification from "../../components/TrialExpiryNotification";
import Notification from "../../components/AnnouncementNotification";

const useStyles = makeStyles({
  welcome: {
    minWidth: "250px",
    flexGrow: 1,
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "32px !important",
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    color: `${theme.palette.black.dark} !important`,
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  text: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: 400,
    fontSize: "16px !important",
    lineHeight: "32px !important",
    /* identical to box height, or 150% */

    //marginTop: "10px",
    /* Neutrals/Black */

    color: `${theme.palette.black.dark} !important`,
  },
  cardContainer: {
    minWidth: "500px",
  },
  card: {
    // border: `2px solid ${theme.palette.black.dark} !important`,
    borderRadius: "8px",
    // width: `calc(100%/${(RolesData || []).length +1})`
    // width: "310px",
    height: " 120px",
    background: "#FFFBF6",
    cursor: "pointer",
  },
  cardtext: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `600 !important`,
    fontSize: "48px !important",
    lineHeight: "48px !important",
    /* identical to box height, or 100% */

    display: "flex",
    alignItems: "flex-start",
    // textAlign: "center",
    justifyContent: "center",
    letterSpacing: "-0.02em",

    color: `${theme.palette.mainPaletteBlue} !important`,
    //width: "31px",
    height: "48px",
    marginTop: "24px !important",
    paddingTop: "25px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
  cardtext1: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `500 !important`,
    fontSize: "16px",
    /* identical to box height, or 100% */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    letterSpacing: "-0.02em",

    color: `${theme.palette.black.dark} !important`,
  },
  tabletext: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `600 !important`,
    fontSize: "22px !important",
    lineHeight: "44px !important",
    minWidth: "200px",
    /* identical to box height, or 100% */

    display: "flex",
    alignItems: "center",
    // textAlign: "center",
    justifyContent: "left",
    letterSpacing: "-0.02em",
    marginTop: "20px !important",
    color: `${theme.palette.black.dark} !important`,
  },

  allMyToggleBtn: {
    width: "64px",
    height: "40px",
    padding: "8px 12px 8px 12px !important",
    color: "#2F2F2F !important",
    border: "1px solid #2F2F2F !important",
  },

  allMyToggleBtnSelected: {
    background: "#2F2F2F !important",
    color: "#FFFFFF !important",
  },
  mainButtonContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: "24px",
    gap: "20px",
  },
});

export default function Home() {
  const setUserDetails = () => {
    // check if recruiterUserId needs to be set
    if ("recruiterUserId" in localStorage) {
      let recruiterUserId = localStorage.getItem("recruiterUserId");
      localStorage.setItem("userid", recruiterUserId);
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      userDetails.userId = recruiterUserId;
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      localStorage.removeItem("recruiterUserId");
    }
    return JSON.parse(localStorage.getItem("user_details"));
  };
  let userDetails = setUserDetails();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const authState = useSelector((state) => state.auth);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [popupTitle, setPopupTitle] = React.useState("");
  const [popupDes, setPopupDes] = React.useState("");
  // const [status, setStatus] = useState([])
  const [inviteid, setInviteid] = useState(null);
  const [inviteview, setInviteview] = useState(false);
  const [candidateid, setCandidateid] = useState(null);
  const [candidateview, setCandidateview] = useState(false);
  const [roleid, setRoleid] = useState(null);
  const [roleview, setRoleview] = useState(false);
  const [onlyMine, setOnlyMine] = React.useState("all");
  // const [searchTerm, setSearchTerm] = React.useState('');
  const [uiInviteListSortModel, setUiInviteListSortModel] = React.useState([{ field: "uid", sort: "desc" }]);
  // const [apiInviteListSortModel, setApiInviteListSortModel] = React.useState({ uid: -1 });
  const [uiCandidateListSortModel, setUiCandidateListSortModel] = React.useState([{ field: "uid", sort: "desc" }]);
  // const [apiCandidateListSortModel, setApiCandidateListSortModel] = React.useState({ uid: -1 });
  const [uiJobRoleListSortModel, setUiJobRoleListSortModel] = React.useState([{ field: "uid", sort: "desc" }]);
  // const [apiJobRoleListSortModel, setApiJobRoleListSortModel] = React.useState({ uid: -1 });
  const [inviteList, setInviteList] = useState(authState?.recruiterdashboard?.recentInvites);
  const [candidateList, setCandidateList] = useState(authState?.recruiterdashboard?.recentCandidates);
  const [roleList, setRoleList] = useState(authState?.recruiterdashboard?.recentJobRoles);
  const [inviteCount, setInviteCount] = useState(0);
  const [candidateCount, setCandidateCount] = useState(0);
  const [roleCount, setRoleCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  // const [reset, setRest] = useState(false);
  // counts for Candidates Invited, Candidates Responded, Responses Completed, Target Score Achieved
  const [candidateInvited, setCandidateInvited] = useState(0);
  const [candidateRes, setCandidateRes] = useState(0);
  const [responseSubmitted, setResponseSubmitted] = useState(0);
  const [targetValue, setTargetValue] = useState(0);
  // for my data search
  const [myListData, setMyListData] = useState({});
  const [showStepper, setShowStepper] = useState(false);
  const [showNotification, setShowNotification] = useState(true);

  // Initial render of the page.
  useEffect(() => {
    const payload = { userId: userDetails.userId };
    dispatch(auth.recruitmentDashboard(payload));
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowStepper(true);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (authState?.recruiterdashboard) {
      setInviteList(authState?.recruiterdashboard?.recentInvites);
      setCandidateList(authState?.recruiterdashboard?.recentCandidates);
      setRoleList(authState?.recruiterdashboard?.recentJobRoles);
      // setting counts above tables
      setInviteCount(authState?.recruiterdashboard?.totalInvitesCnt);
      setCandidateCount(authState?.recruiterdashboard?.totalCandidatesCnt);
      setRoleCount(authState?.recruiterdashboard?.totalRolesCnt);
      // set candidates invite length
      setCandidateInvited(authState?.recruiterdashboard?.recentInvites?.length);
      // set Candidates Responded length
      const CandidatetestCompLenght = authState?.recruiterdashboard?.recentInvites?.filter(
        (e) => e.candidateResponded === true
      );
      setCandidateRes(CandidatetestCompLenght?.length);
      // set Responses Completed length
      const CandidatetastCompRespLenght = authState?.recruiterdashboard?.recentInvites?.filter(
        (e) => e.isCompleted === 1
      );
      setResponseSubmitted(CandidatetastCompRespLenght?.length);
      // set Target Score Achieved length
      const CandidateTargetLenght = authState?.recruiterdashboard?.recentInvites?.filter(
        (e) => e.jobRoleTargetScore <= e.proportionalScore
      );
      setTargetValue(CandidateTargetLenght?.length);
    }
  }, [authState?.recruiterdashboard]);

  // handle click All and My lists
  const handleDashboardAllAndMyToggle = (e) => {
    let newToggledAllOrMyVal = e.target.value;
    setOnlyMine(newToggledAllOrMyVal);

    if (newToggledAllOrMyVal === "all") {
      let inviteVisibleKeys = [
        "uid",
        "firstName",
        "lastName",
        "jobRoleId",
        "jobRoleName",
        "status",
        "dateFilter",
        "jobRoleTargetScore",
        "proportionalScore",
        "testsTakenCount",
        "email",
      ];
      let candidateVisibleKeys = [
        "uid",
        "firstName",
        "lastName",
        "status",
        "jobTitle",
        "degree",
        "yoe",
        "highestScore",
        "dateFilter",
        "email",
      ];
      let roleVisibleKeys = [
        "uid",
        "name",
        "dateFilter",
        "displayName",
        "status",
        "invited",
        "completed",
        "reachedTarget",
        "targetScore",
      ];

      const filteredInviteData = !!authState?.recruiterdashboard?.recentInvites
        ? applySearch(authState?.recruiterdashboard?.recentInvites, searchText, inviteVisibleKeys)
        : [];
      const filteredCandidateData = !!authState?.recruiterdashboard?.recentCandidates?.length
        ? applySearch(authState?.recruiterdashboard?.recentCandidates, searchText, candidateVisibleKeys)
        : [];
      const filteredRoleData = !!authState?.recruiterdashboard?.recentJobRoles?.length
        ? applySearch(authState?.recruiterdashboard?.recentJobRoles, searchText, roleVisibleKeys)
        : [];

      //Roles sort data by sort model
      let roleFieldType, roleField;
      roleFieldType = uiJobRoleListSortModel[0].field === "createdAt" ? "date" : "";
      roleField = uiJobRoleListSortModel[0].field;
      let roleFilterData = searchText ? filteredRoleData : authState?.recruiterdashboard?.recentJobRoles;
      roleFilterData = sortJson([...roleFilterData], uiJobRoleListSortModel[0].sort, roleField, roleFieldType);
      setRoleList(roleFilterData);

      //invited fillter sort data by sort model
      let invitedFieldType, invitedFeld;
      invitedFieldType = uiInviteListSortModel[0].field === "createdAt" ? "date" : "";
      invitedFeld = uiInviteListSortModel[0].field;
      if (uiInviteListSortModel[0].field === "testsTakenCount") {
        invitedFeld = "testCompletedTuple";
        invitedFieldType = "tuple";
      }

      let inviteFilteredData = searchText ? filteredInviteData : authState?.recruiterdashboard?.recentInvites;
      inviteFilteredData = sortJson(
        [...inviteFilteredData],
        uiInviteListSortModel[0].sort,
        invitedFeld,
        invitedFieldType
      );

      setInviteList(inviteFilteredData);

      //candidate fillter sort data by sort model
      let candidateFieldType, candidateField;
      candidateFieldType = uiCandidateListSortModel[0].field === "createdAt" ? "date" : "";
      candidateField = uiCandidateListSortModel[0].field;
      if (uiCandidateListSortModel[0].field === "jobRoleInvites") {
        candidateField = "inviteCountTuple";
        candidateFieldType = "tuple";
      }

      let candidateFilterData = searchText ? filteredCandidateData : authState?.recruiterdashboard?.recentCandidates;
      candidateFilterData = sortJson(
        [...candidateFilterData],
        uiCandidateListSortModel[0].sort,
        candidateField,
        candidateFieldType
      );

      setCandidateList(candidateFilterData);

      // setting counts above tables
      setInviteCount(searchText ? filteredInviteData?.length : authState?.recruiterdashboard?.totalInvitesCnt);
      setCandidateCount(searchText ? filteredCandidateData?.length : authState?.recruiterdashboard?.totalCandidatesCnt);
      setRoleCount(searchText ? filteredRoleData?.length : authState?.recruiterdashboard?.totalRolesCnt);
      // set candidates invite length on all
      setCandidateInvited(
        searchText ? filteredInviteData?.length : authState?.recruiterdashboard?.recentInvites?.length
      );
      // set Candidates Responded length on all
      let CandidatetestCompLenght = searchText
        ? filteredInviteData?.filter((e) => e.candidateResponded === true)
        : authState?.recruiterdashboard?.recentInvites?.filter((e) => e.candidateResponded === true);
      setCandidateRes(CandidatetestCompLenght?.length);
      // set Responses Completed length on all
      const CandidatetastCompRespLenght = searchText
        ? filteredInviteData?.filter((e) => e.isCompleted === 1)
        : authState?.recruiterdashboard?.recentInvites?.filter((e) => e.isCompleted === 1);
      setResponseSubmitted(CandidatetastCompRespLenght?.length);
      // set Target Score Achieved length on all
      const CandidateTargetLenght = searchText
        ? filteredInviteData?.filter((e) => e.jobRoleTargetScore <= e.proportionalScore)
        : authState?.recruiterdashboard?.recentInvites?.filter((e) => e.jobRoleTargetScore <= e.proportionalScore);
      setTargetValue(CandidateTargetLenght?.length);
    }
    if (newToggledAllOrMyVal === "my") {
      const inviteFilteredData = authState?.recruiterdashboard?.recentInvites?.filter(
        (i) => i.inviteCreatedByUid === userDetails.userId
      );
      const candidateFilteredData = authState?.recruiterdashboard?.recentCandidates?.filter(
        (i) => i.createdBy.uid === userDetails.userId
      );
      const roleFilteredData = authState?.recruiterdashboard?.recentJobRoles?.filter(
        (i) => i.createdBy.uid === userDetails.userId
      );

      let inviteVisibleKeys = [
        "uid",
        "firstName",
        "lastName",
        "jobRoleId",
        "jobRoleName",
        "status",
        "dateFilter",
        "jobRoleTargetScore",
        "proportionalScore",
        "testsTakenCount",
        "email",
      ];
      let candidateVisibleKeys = [
        "uid",
        "firstName",
        "lastName",
        "status",
        "jobTitle",
        "degree",
        "yoe",
        "highestScore",
        "dateFilter",
        "email",
      ];
      let roleVisibleKeys = [
        "uid",
        "name",
        "dateFilter",
        "displayName",
        "status",
        "invited",
        "completed",
        "reachedTarget",
        "targetScore",
      ];

      const filteredInviteData = !!inviteFilteredData?.length
        ? applySearch(inviteFilteredData, searchText, inviteVisibleKeys)
        : [];
      const filteredCandidateData = !!candidateFilteredData?.length
        ? applySearch(candidateFilteredData, searchText, candidateVisibleKeys)
        : [];
      const filteredRoleData = !!roleFilteredData?.length
        ? applySearch(roleFilteredData, searchText, roleVisibleKeys)
        : [];

      setMyListData({ inviteFilteredData });
      setMyListData((prevValues) => {
        return { ...prevValues, candidateFilteredData };
      });
      setMyListData((prevValues) => {
        return { ...prevValues, roleFilteredData };
      });

      //Roles sort data by sort model
      let roleFieldType, roleField;
      roleFieldType = uiJobRoleListSortModel[0].field === "createdAt" ? "date" : "";
      roleField = uiJobRoleListSortModel[0].field;
      let roleFilterData = searchText ? filteredRoleData : roleFilteredData;
      roleFilterData = sortJson([...roleFilterData], uiJobRoleListSortModel[0].sort, roleField, roleFieldType);

      //invited fillter sort data by sort model
      let invitedFieldType, invitedFeld;
      invitedFieldType = uiInviteListSortModel[0].field === "createdAt" ? "date" : "";
      invitedFeld = uiInviteListSortModel[0].field;
      if (uiInviteListSortModel[0].field === "testsTakenCount") {
        invitedFeld = "testCompletedTuple";
        invitedFieldType = "tuple";
      }

      let inviteSortedData = searchText ? filteredInviteData : inviteFilteredData;
      inviteSortedData = sortJson([...inviteSortedData], uiInviteListSortModel[0].sort, invitedFeld, invitedFieldType);

      //candidate fillter sort data by sort model
      let candidateFieldType, candidateField;
      candidateFieldType = uiCandidateListSortModel[0].field === "createdAt" ? "date" : "";
      candidateField = uiCandidateListSortModel[0].field;
      if (uiCandidateListSortModel[0].field === "jobRoleInvites") {
        candidateField = "inviteCountTuple";
        candidateFieldType = "tuple";
      }

      let candidateFilterData = searchText ? filteredCandidateData : candidateFilteredData;
      candidateFilterData = sortJson(
        [...candidateFilterData],
        uiCandidateListSortModel[0].sort,
        candidateField,
        candidateFieldType
      );

      setInviteList(inviteSortedData);
      setCandidateList(candidateFilterData);
      setRoleList(roleFilterData);
      setInviteCount(filteredInviteData?.length);
      setCandidateCount(filteredCandidateData?.length);
      setRoleCount(filteredRoleData?.length);
      // set candidates invite length on my
      setCandidateInvited(searchText ? filteredInviteData.length : inviteFilteredData?.length);
      // set Candidates Responded length on my
      const CandidatetestCompLenght = searchText
        ? filteredInviteData?.filter((e) => e.candidateResponded === true)
        : inviteFilteredData?.filter((e) => e.candidateResponded === true);
      setCandidateRes(CandidatetestCompLenght?.length);
      // set Responses Completed length on my
      const CandidatetastCompRespLenght = searchText
        ? filteredInviteData?.filter((e) => e.isCompleted === 1)
        : inviteFilteredData?.filter((e) => e.isCompleted === 1);
      setResponseSubmitted(CandidatetastCompRespLenght?.length);
      // set Target Score Achieved length on my
      const CandidateTargetLenght = searchText
        ? filteredInviteData?.filter((e) => e.jobRoleTargetScore <= e.proportionalScore)
        : inviteFilteredData?.filter((e) => e.jobRoleTargetScore <= e.proportionalScore);
      setTargetValue(CandidateTargetLenght?.length);
    }
  };

  // Appy search filter on invite data
  const inviteApplySearchFilter = () => {
    let rawData;
    if (onlyMine === "all") {
      rawData = [...authState?.recruiterdashboard?.recentInvites];
    }
    if (onlyMine === "my") {
      rawData = !!myListData?.inviteFilteredData?.length ? myListData?.inviteFilteredData : [];
    }
    let visibleKeys = [
      "uid",
      "firstName",
      "lastName",
      "jobRoleId",
      "jobRoleName",
      "status",
      "dateFilter",
      "jobRoleTargetScore",
      "proportionalScore",
      "testsTakenCount",
      "email",
    ];

    let filteredData = !!rawData?.length ? applySearch(rawData, searchText, visibleKeys) : [];
    return filteredData;
  };

  // Appy search filter on candidate data
  const candidateApplySearchFilter = () => {
    let rawData;
    if (onlyMine === "all") {
      rawData = [...authState?.recruiterdashboard?.recentCandidates];
    }
    if (onlyMine === "my") {
      rawData = !!myListData?.candidateFilteredData?.length ? myListData?.candidateFilteredData : [];
    }
    let visibleKeys = [
      "uid",
      "firstName",
      "lastName",
      "status",
      "jobTitle",
      "degree",
      "yoe",
      "highestScore",
      "dateFilter",
      "email",
    ];

    let filteredData = !!rawData?.length ? applySearch(rawData, searchText, visibleKeys) : [];

    return filteredData;
  };

  // Appy search filter on Role data
  const roleApplySearchFilter = () => {
    let rawData;
    if (onlyMine === "all") {
      rawData = [...authState?.recruiterdashboard?.recentJobRoles];
    }
    if (onlyMine === "my") {
      rawData = !!myListData?.roleFilteredData?.length ? myListData?.roleFilteredData : [];
    }
    let visibleKeys = [
      "uid",
      "name",
      "dateFilter",
      "displayName",
      "status",
      "invited",
      "completed",
      "reachedTarget",
      "targetScore",
    ];

    let filteredData = !!rawData?.length ? applySearch(rawData, searchText, visibleKeys) : [];
    return filteredData;
  };

  // Set Invite filtered List on search
  const setInviteFilteredList = () => {
    let inviteFilteredData;
    let candidateFilterData;
    let roleFilterData;
    inviteFilteredData = inviteApplySearchFilter(authState?.recruiterdashboard?.recentInvites);
    candidateFilterData = candidateApplySearchFilter(authState?.recruiterdashboard?.recentCandidates);
    roleFilterData = roleApplySearchFilter(authState?.recruiterdashboard?.recentJobRoles);

    //invited fillter sort data by arrow
    let invitedFieldType, invitedFeld;
    invitedFieldType = uiInviteListSortModel[0].field === "createdAt" ? "date" : "";
    invitedFeld = uiInviteListSortModel[0].field;
    if (uiInviteListSortModel[0].field === "testsTakenCount") {
      invitedFeld = "testCompletedTuple";
      invitedFieldType = "tuple";
    }
    inviteFilteredData = sortJson(
      [...inviteFilteredData],
      uiInviteListSortModel[0].sort,
      invitedFeld,
      invitedFieldType
    );

    //candidate fillter sort data by arrow
    let candidateFieldType, candidateField;
    candidateFieldType = uiCandidateListSortModel[0].field === "createdAt" ? "date" : "";
    candidateField = uiCandidateListSortModel[0].field;
    if (uiCandidateListSortModel[0].field === "jobRoleInvites") {
      candidateField = "inviteCountTuple";
      candidateFieldType = "tuple";
    }
    candidateFilterData = sortJson(
      [...candidateFilterData],
      uiCandidateListSortModel[0].sort,
      candidateField,
      candidateFieldType
    );

    //role fillter sort data by arrow
    let roleFieldType, roleField;
    roleFieldType = uiJobRoleListSortModel[0].field === "createdAt" ? "date" : "";
    roleField = uiJobRoleListSortModel[0].field;
    roleFilterData = sortJson([...roleFilterData], uiJobRoleListSortModel[0].sort, roleField, roleFieldType);

    // removing year from dateFilter
    inviteFilteredData.map((inviteFilteredData) => {
      return (inviteFilteredData["dateFilter"] = inviteFilteredData["dateFilter"].slice(0, 6));
    });
    candidateFilterData.map((candidateFilterData) => {
      return (candidateFilterData["dateFilter"] = candidateFilterData["dateFilter"].slice(0, 6));
    });
    roleFilterData.map((roleFilterData) => {
      return (roleFilterData["dateFilter"] = roleFilterData["dateFilter"].slice(0, 6));
    });

    setInviteList(inviteFilteredData);
    setCandidateList(candidateFilterData);
    setRoleList(roleFilterData);

    setInviteCount(inviteFilteredData?.length);
    setCandidateCount(candidateFilterData?.length);
    setRoleCount(roleFilterData?.length);
    // set candidates invite length on search
    setCandidateInvited(inviteFilteredData?.length);
    // set Candidates Responded length on search
    const CandidatetestCompLenght = inviteFilteredData?.filter((e) => e.candidateResponded === true);
    setCandidateRes(CandidatetestCompLenght?.length);
    // set Responses Completed length on search
    const CandidatetastCompRespLenght = inviteFilteredData?.filter((e) => e.isCompleted === 1);
    setResponseSubmitted(CandidatetastCompRespLenght?.length);
    // set Target Score Achieved length on search
    const CandidateTargetLenght = inviteFilteredData?.filter((e) => e.jobRoleTargetScore <= e.proportionalScore);
    setTargetValue(CandidateTargetLenght?.length);
  }; //End

  //  Search Value side effect
  useEffect(() => {
    let timeOut = setTimeout(() => {
      if (
        authState?.recruiterdashboard?.recentInvites?.length &&
        authState?.recruiterdashboard?.recentCandidates?.length &&
        authState?.recruiterdashboard?.recentJobRoles?.length
      ) {
        setInviteFilteredList();
      }
    }, 400);
    return () => {
      clearTimeout(timeOut);
    };
    // eslint-disable-next-line
  }, [searchText]);

  const showAllInvites = () => {
    navigate("/invitelist");
  };

  const showAllCandidates = () => {
    navigate("/candidatelist");
  };

  const showAllRoles = () => {
    navigate("/rolelist");
  };

  const handleInvitesDialog = (val) => {
    setPopupTitle("Recent Invites");
    setPopupDes("This is list of recent invites.");
    setOpenPopup(val);
  };

  const handleCandidatesDialog = (val) => {
    setPopupTitle("Recent Candidates");
    setPopupDes("This is list of recently added candidates.");
    setOpenPopup(val);
  };

  const handleRolesDialog = (val) => {
    setPopupTitle("Recent Roles");
    setPopupDes("This is list of recently created roles.");
    setOpenPopup(val);
  };

  const AddcandidateChange = () => {
    navigate("/addnewcandidate");
  };

  const AddRoleChange = () => {
    navigate("/CreateJobRole");
  };

  // const headerdata1 = HeaderConfigs.inviteHeader();
  const headerdata1 = HeaderConfigs.inviteHeader();
  // const headerdata2 = HeaderConfigs.candidateHeader();
  const headerdata2 = HeaderConfigs.candidateHeader();
  // const headerdata3 = HeaderConfigs.jobRoleHeader();
  const headerdata3 = HeaderConfigs.jobRoleHeader();

  // handle the sorting of recent invite list
  const handleInviteSortChange = (inviteListSortInfo) => {
    // let obj = {};
    // obj[inviteListSortInfo.field] = inviteListSortInfo.sort === "desc" ? -1 : 1;
    // setApiInviteListSortModel(obj);
    // setUiInviteListSortModel([inviteListSortInfo]);

    let fieldType, field;

    fieldType = inviteListSortInfo.field === "createdAt" ? "date" : "";
    field = inviteListSortInfo.field || uiInviteListSortModel[0].field;

    if (inviteListSortInfo.field === "testsTakenCount") {
      field = "testCompletedTuple";
      fieldType = "tuple";
    }

    setUiInviteListSortModel(inviteListSortInfo ? [inviteListSortInfo] : uiInviteListSortModel[0]);
    let sortedOrder = sortJson([...inviteList], inviteListSortInfo.sort, field, fieldType);
    setInviteList(sortedOrder);
  };

  // handle the sorting of recent candidate list
  const handleCanditateSortChange = (candidateListSortInfo) => {
    // let obj = {};
    // obj[candidateListSortInfo.field] = candidateListSortInfo.sort === "desc" ? -1 : 1;
    // setApiCandidateListSortModel(obj);
    // setUiCandidateListSortModel([candidateListSortInfo]);

    let fieldType, field;

    fieldType = candidateListSortInfo.field === "createdAt" ? "date" : "";
    field = candidateListSortInfo.field || uiCandidateListSortModel[0].field;

    if (candidateListSortInfo.field === "jobRoleInvites") {
      field = "inviteCountTuple";
      fieldType = "tuple";
    }

    setUiCandidateListSortModel([candidateListSortInfo]);
    let sortedOrder = sortJson([...candidateList], candidateListSortInfo.sort, field, fieldType);
    setCandidateList(sortedOrder);
  };

  // handle the sorting of recent role list
  const handleRoleSortChange = (jobRoleListSortInfo) => {
    // let obj = {};
    // obj[jobRoleListSortInfo.field] = jobRoleListSortInfo.sort === "desc" ? -1 : 1;
    // setApiJobRoleListSortModel(obj);
    setUiJobRoleListSortModel([jobRoleListSortInfo]);

    setUiJobRoleListSortModel([jobRoleListSortInfo]);
    let sortedOrder = sortJson(
      [...roleList],
      jobRoleListSortInfo.sort,
      jobRoleListSortInfo.field,
      jobRoleListSortInfo.field === "createdAt" ? "date" : ""
    );
    setRoleList(sortedOrder);
  };

  const handelCandidatesInvited = () => {
    navigate("/invitelist");
  };

  const handelCandidateResponses = () => {
    navigate("/invitelist");
  };
  // handle row click of recent invite list
  const recentInviteRecordView = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setInviteid(id);
      setInviteview(true);
      dispatch(role.inviteDetails(id));
    } else {
      return null;
    }
  };
  // Navigae to invite candidate page on click invite candidate row
  useEffect(() => {
    if (inviteview && roleState?.invitedetails?.uid) {
      window.open(generatePath("/invitecandidateview/:id", { id: inviteid }));
    }
    // eslint-disable-next-line
  }, [inviteview && roleState?.invitedetails?.uid]);

  // handle row click of recent candidate list
  const recentCandidateRecordView = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setCandidateid(id);
      setCandidateview(true);
      dispatch(auth.candidateDetails(id));
    } else {
      return null;
    }
  };

  // Navigae to candidate page on click candidate row
  useEffect(() => {
    if (candidateview && authState?.candidatedetails?.uid) {
      window.open(generatePath("/candidateview/:id", { id: candidateid }));
    }
    // eslint-disable-next-line
  }, [candidateview && authState?.candidatedetails?.uid]);

  // handle row click of recent role list
  const recentRoleRecordView = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setRoleid(id);
      setRoleview(true);
      dispatch(role.roleDetails(id));
    } else {
      return null;
    }
  };

  // Navigae to role page on click role row
  useEffect(() => {
    // handle to show the roleview details page.
    if (roleview && roleState?.roledetails?.uid) {
      window.open(generatePath("/newRoleView/:id", { id: roleid }));
    }
    // eslint-disable-next-line
  }, [roleview && roleState?.roledetails?.uid]);

  const AddInviteChange = () => {
    navigate("/inviteaddcandidates");
  };

  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    if (searchValue) {
      setSearchText(searchValue);
      // setRest(false);
    } else {
      setSearchText("");
      // setRest(true);
    }
  };

  // convert first letter and lastName first letter to capitalize
  const str = userDetails.name;
  const arr = str.split(" ");
  const capitalizedArr = arr.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  const userName = capitalizedArr.join(" ");

  //stepper
  const handleStepper = () => {
    setShowStepper((prevState) => !prevState);
  };

  //trial expiry notification
  // const trialExpiryApproaching = localStorage.getItem("trialExpiryApproaching");

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const SkeletonBtn = () => {
    return <Skeleton variant="rectangular" width={199} height={36} sx={{ borderRadius: "4px" }} />;
  };

  const SkeletonTable = () => {
    return <Skeleton variant="rectangular" width="100%" height={285} sx={{ borderRadius: "4px" }} />;
  };

  return (
    <Fragment>
      <NavBar />
      <Notification handleCloseNotification={handleCloseNotification} showNotification={showNotification} />
      {/* <TrialExpiryNotification handleCloseNotification={handleCloseNotification} showNotification={showNotification} /> */}
      <Container maxWidth="xl" className={classes.container}>
        <Grid sx={showNotification ? { marginTop: "30px" } : { marginTop: "130px" }}>
          <Grid display="flex" justifyContent="space-between" alignContent="flex-start">
            <Grid item display="flex" flexDirection="column">
              <Typography className={classes.welcome} align="left">
                Welcome, {userName}! 👋
              </Typography>
            </Grid>
            <Grid item display="flex">
              <ToggleButtonGroup color="standard" value={onlyMine} exclusive onChange={handleDashboardAllAndMyToggle}>
                <ToggleButton
                  classes={{ root: classes.allMyToggleBtn, selected: classes.allMyToggleBtnSelected }}
                  value="all"
                  selected={onlyMine === "all"}
                >
                  All
                </ToggleButton>
                <ToggleButton
                  classes={{ root: classes.allMyToggleBtn, selected: classes.allMyToggleBtnSelected }}
                  value="my"
                  selected={onlyMine === "my"}
                >
                  My
                </ToggleButton>
              </ToggleButtonGroup>
              <Grid item style={{ marginLeft: "15px" }}>
                <SearchBar
                  placeholdertitle={"Search Candidates, Roles, Invites"}
                  onChange={onChangeSearch}
                  value={searchText}
                  // width={'310px'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid display="flex" justifyContent="space-between" alignContent="flex-start">
            <Typography className={classes.text}>Summary of your recruitment efforts</Typography>
          </Grid>

          <Grid container spacing={2} className={classes.cardContainer}>
            <Grid item xs={3}>
              <Grid className={classes.card} onClick={handelCandidatesInvited}>
                <Typography className={classes.cardtext}>
                  {candidateInvited !== undefined && candidateInvited !== null ? (
                    candidateInvited
                  ) : (
                    <Skeleton width={50} />
                  )}
                </Typography>
                <Typography className={classes.cardtext1}> Candidates Invited</Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} onClick={handelCandidatesInvited}>
              <Grid className={classes.card}>
                <Typography className={classes.cardtext}>
                  {candidateRes !== undefined && candidateRes !== null ? candidateRes : <Skeleton width={50} />}
                </Typography>
                <Typography className={classes.cardtext1}> Candidates Responded </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} onClick={handelCandidateResponses}>
              <Grid className={classes.card}>
                <Typography className={classes.cardtext}>
                  {responseSubmitted !== undefined && responseSubmitted !== null ? (
                    responseSubmitted
                  ) : (
                    <Skeleton width={50} />
                  )}
                </Typography>
                <Typography className={classes.cardtext1}> Responses Completed </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid className={classes.card} onClick={handelCandidateResponses}>
                <Typography className={classes.cardtext}>
                  {targetValue !== undefined && targetValue !== null ? targetValue : <Skeleton width={50} />}
                </Typography>
                <Typography className={classes.cardtext1}> Target Score Achieved</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ display: "flex", paddingBottom: "20px", paddingTop: "35px" }}>
          <Typography className={classes.tabletext} style={{ width: "100%" }}>
            Invites ({inviteCount !== undefined && inviteCount !== null ? inviteCount : <Skeleton width={30} />})
            <img
              src={InfoIcon}
              alt={"Recent Invites Icon"}
              style={{ cursor: "pointer", paddingLeft: "5px" }}
              onClick={() => handleInvitesDialog(true)}
            />
          </Typography>
          {/* invites table */}
          {authState?.recruiterdashboard?.recentInvites !== undefined &&
          authState?.recruiterdashboard?.recentInvites !== null ? (
            <Grid className={classes.mainButtonContainer}>
              <Button
                style={{ border: "1px solid #1789FC" }}
                data-rt-recruiterdashboard-view=" Showall"
                color="btnCls3"
                text={" Show Full View"}
                onClick={showAllInvites}
                width={199}
                variant="outlined"
              ></Button>
              <Button
                data-rt-invitecandidate-=" invitecandidate"
                color="btn"
                width={199}
                text=" Invite Candidate(s)"
                onClick={AddInviteChange}
              />
            </Grid>
          ) : (
            <Grid className={classes.mainButtonContainer}>
              <SkeletonBtn />
              <SkeletonBtn />
            </Grid>
          )}
        </div>
        {authState?.recruiterdashboard?.recentInvites?.length === 0 ? (
          <Grid>
            <Grid style={{ textAlign: "center", backgroundColor: "#FFFBF6", padding: "15px", marginTop: "15px" }}>
              <Typography className={classes.roletype1} style={{ color: "#2F2F2F", paddingBottom: "15px" }}>
                Start adding roles and candidates to send invites
              </Typography>
              <Button
                data-rt-recruiter-dashboard="CreateRoles"
                color={
                  userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor" ? "btnCls2" : "btnCls"
                }
                text="Create Roles"
                // type="submit"
                onClick={AddRoleChange}
                disabled={userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor"}
                width={199}
                variant="outlined"
              ></Button>
            </Grid>
          </Grid>
        ) : authState?.recruiterdashboard?.recentInvites?.length > 0 ? (
          <div style={{ height: 285, overflow: "auto" }}>
            <GridTable
              columns={headerdata1}
              rows={inviteList || []}
              checkbox={false}
              height={"100%"}
              getRowId={(row) => row._id}
              dispatchValue={role.candidateinviteList}
              // custom={true}
              handleRowClick={recentInviteRecordView}
              onSortChange={handleInviteSortChange}
              sortModel={uiInviteListSortModel}
            />
          </div>
        ) : (
          <SkeletonTable />
        )}

        {/* Candidates Table */}
        <div style={{ display: "flex", paddingBottom: "20px", paddingTop: "35px" }}>
          <Typography className={classes.tabletext} style={{ width: "100%" }}>
            Candidates (
            {candidateCount !== undefined && candidateCount !== null ? candidateCount : <Skeleton width={30} />})
            <img
              src={InfoIcon}
              alt={"Recent Candidates Icon"}
              style={{ cursor: "pointer", paddingLeft: "5px" }}
              onClick={() => handleCandidatesDialog(true)}
            />
          </Typography>
          {authState?.recruiterdashboard?.recentCandidates !== undefined &&
          authState?.recruiterdashboard?.recentCandidates !== null ? (
            <Grid className={classes.mainButtonContainer}>
              <Button
                data-rt-recruiterdashboard-view=" Showall"
                color="btnCls3"
                text={" Show Full View"}
                onClick={showAllCandidates}
                width={199}
                variant="outlined"
              ></Button>
              <Button
                data-rt-role-addnew="addnewcandidate"
                color="btn"
                width={199}
                text="Add Candidate"
                onClick={AddcandidateChange}
              />
            </Grid>
          ) : (
            <Grid className={classes.mainButtonContainer}>
              <SkeletonBtn />
              <SkeletonBtn />
            </Grid>
          )}
        </div>
        {authState?.recruiterdashboard?.recentCandidates?.length === 0 ? (
          <Grid>
            <Grid style={{ textAlign: "center", backgroundColor: "#FFFBF6", padding: "15px", marginTop: "15px" }}>
              <Typography className={classes.roletype1} style={{ color: "#2F2F2F", paddingBottom: "15px" }}>
                Start adding new candidates for recruitment
              </Typography>
              <Button
                data-rt-recruiter-dashboard="AddCandidate"
                color="btnCls"
                text="Add Candidate"
                // type="submit"
                width={199}
                onClick={AddcandidateChange}
                variant="outlined"
              ></Button>
            </Grid>
          </Grid>
        ) : authState?.recruiterdashboard?.recentCandidates?.length > 0 ? (
          <div style={{ height: 285, overflow: "auto" }}>
            <GridTable
              columns={headerdata2}
              rows={candidateList || []}
              checkbox={false}
              getRowId={(row) => row._id}
              height={"100%"}
              //custom={true}
              // count={candidateTableRows?.length}
              dispatchValue={role.candidateinviteList}
              handleRowClick={recentCandidateRecordView}
              onSortChange={handleCanditateSortChange}
              sortModel={uiCandidateListSortModel}
            />
          </div>
        ) : (
          <SkeletonTable />
        )}

        {/* Roles Table */}
        <div style={{ display: "flex", paddingBottom: "20px", paddingTop: "35px" }}>
          <Typography className={classes.tabletext} style={{ width: "100%" }}>
            Roles ({roleCount !== undefined && roleCount !== null ? roleCount : <Skeleton width={30} />})
            <img
              src={InfoIcon}
              alt={"Recent Roles Icon"}
              style={{ cursor: "pointer", paddingLeft: "5px" }}
              onClick={() => handleRolesDialog(true)}
            />
          </Typography>
          {authState?.recruiterdashboard?.recentJobRoles !== undefined &&
          authState?.recruiterdashboard?.recentJobRoles !== null ? (
            <Grid className={classes.mainButtonContainer}>
              <Button
                data-rt-recruiterdashboard-view=" Showall"
                color="btnCls3"
                text={" Show Full View"}
                onClick={showAllRoles}
                width={199}
                variant="outlined"
              ></Button>
              <Button
                data-rt-role-addnew=" addnewrole"
                color={
                  userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor" ? "disablebtn" : "btn"
                }
                width={199}
                text="Create Role"
                disabled={userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor"}
                onClick={AddRoleChange}
              />
            </Grid>
          ) : (
            <Grid className={classes.mainButtonContainer}>
              <SkeletonBtn />
              <SkeletonBtn />
            </Grid>
          )}
        </div>
        {authState?.recruiterdashboard?.recentJobRoles?.length === 0 ? (
          <Grid>
            <Grid style={{ textAlign: "center", backgroundColor: "#FFFBF6", padding: "15px", marginTop: "15px" }}>
              <Typography className={classes.roletype1} style={{ color: "#2F2F2F", paddingBottom: "15px" }}>
                Start adding roles and candidates to send invites
              </Typography>
              <Button
                data-rt-recruiter-dashboard="CreateRoles"
                color={
                  userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor" ? "btnCls2" : "btnCls"
                }
                text=" Create Roles"
                // type="submit"
                width={199}
                onClick={AddRoleChange}
                disabled={userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor"}
                variant="outlined"
              ></Button>
            </Grid>
          </Grid>
        ) : authState?.recruiterdashboard?.recentJobRoles?.length > 0 ? (
          <div style={{ height: 285, overflow: "auto" }}>
            <GridTable
              columns={headerdata3}
              rows={roleList || []}
              checkbox={false}
              height={"100%"}
              //custom={true}
              getRowId={(row) => row._id || 0}
              count={authState?.recruiterdashboard?.recentJobRoles?.length}
              // dispatchValue={role.roleList}
              handleRowClick={recentRoleRecordView}
              onSortChange={handleRoleSortChange}
              sortModel={uiJobRoleListSortModel}
            />
          </div>
        ) : (
          <SkeletonTable />
        )}
      </Container>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <LOneExampleContent
          hint={popupDes}
          title={popupTitle}
          showButtonArea={false}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          id="no-copy"
        ></LOneExampleContent>
      </Popup>
      <Tour isTourBtnShow={showStepper} handleStepper={handleStepper} />
      <Footer />
    </Fragment>
  );
}
