import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, generatePath } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, useMediaQuery } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button as BaseButton } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NavBar from "../../../components/NavBar";
import Button from "../../../components/controls/Button";
import GridTable from "../../../components/Table";
import role from "../../../redux/actions/role";

// styles for this commponent
const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  profileDPInputBtn: {
    padding: "0 !important",
    textTransform: "unset !important",
    color: "#1789FC !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
  },
  iconSty: {
    color: "#1789FC !important",
  },
});
export default function InviteSuccess(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [inviteList, setInviteList] = React.useState([]);
  const dispatch = useDispatch();
  const [inviteView, setInviteView] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const matches = useMediaQuery("(max-width:850px)");

  const headerData = [
    {
      field: "uid",
      headerName: "Invite ID",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.7,
      align: "left",
      renderCell: (params) => {
        return <div>{params.row.uid}</div>;
      },
    },
    {
      field: "candidate.user.firstName",
      headerName: "Candidate",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "left",
      renderCell: (params) => {
        return (
          <div>
            {params.row.candidate?.user?.firstName} {params.row.candidate?.user?.lastName}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      disableColumnMenu: true,
      flex: 1.4,
      align: "left",
      renderCell: (params) => {
        return <div>{params.row.candidate?.user?.email}</div>;
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: (params) => {
        return (
          <div>
            <BaseButton
              className={classes.profileDPInputBtn}
              component="span"
              onClick={(event) => handleOnCandidateListCellClick(event, params)}
            >
              View Details <ArrowForwardIcon colorPrimary className={classes.iconSty} />
            </BaseButton>
          </div>
        );
      },
    },
  ];
  const roleState = useSelector((state) => state.role);

  useEffect(() => {
    if (roleState?.addinvite?.data?.length > 0) {
      //   console.log('invited list: ', roleState?.addinvite?.data);
      setInviteList(roleState?.addinvite?.data);
    }
  }, []);
  // navigate to invite candidate view
  useEffect(() => {
    if (inviteView && roleState && roleState?.invitedetails?.uid !== undefined) {
      navigate(generatePath("/invitecandidateview/:id", { id: userId }));
    }
  }, [inviteView && roleState?.invitedetails?.uid]);

  const BackInvite = () => {
    navigate("/invitelist");
  };
  const SendAnotherInvite = () => {
    navigate("/inviteaddcandidates");
  };

  const onInviteDetailRowClick = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setUserId(id);
      dispatch(role.inviteDetails(id));
      setInviteView(true);
    } else {
      return null;
    }
  };

  const handleOnCandidateListCellClick = (event, params) => {
    event.stopPropagation();
    onInviteDetailRowClick(params.row);
  };

  return (
    <>
      {matches ? (
        <div>
          <NavBar />
          <Container>
            <Grid sx={{ mt: 12 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    sx={{
                      textAlign: "center",
                      backgroundColor: "#FFFBF6",
                      py: 2,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} display="flex" justifyContent="center" flexDirection="column">
                        <Typography>
                          <CheckCircleOutlineIcon />
                        </Typography>
                        <Typography className={classes.roletext} style={{ color: "#2F2F2F", paddingBottom: "15px" }}>
                          Invite(s) sent successfully
                        </Typography>
                        <Typography className={classes.roletype1} style={{ color: "#2F2F2F" }}>
                          You may update each candidate's Profile (resume & professional links) and Behavioral Section
                          by clicking on the View Details link below.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }}>
                    <Grid xs={12}>
                      {inviteList && inviteList.length > 0 && (
                        <GridTable
                          columns={headerData}
                          rows={inviteList}
                          checkbox={false}
                          getRowId={(row) => row._id || 0}
                          disableMultipleSelection={false}
                          handleRowClick={onInviteDetailRowClick}
                          color={"#FFFBF6"}
                          custom={true}
                          height={"auto"}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} display="flex" gap="1%" sx={{ mt: 3 }}>
                      <Button
                        data-rt-candidate-view-invite="BacktoAllInvites"
                        color="btnCls"
                        text="Back to All Invites"
                        // type="submit"
                        onClick={BackInvite}
                        width="50%"
                        variant="outlined"
                      ></Button>
                      <Button
                        data-rt-candidate-view-invite="SendAnotherinvite"
                        color="btn"
                        text="Send Another Invite"
                        // type="submit"
                        onClick={SendAnotherInvite}
                        width="50%"
                      ></Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <div>
          <NavBar />
          <Container maxWidth="xl" className={classes.container}>
            <Grid style={{ marginTop: "100px" }}>
              <Grid container justifyContent="center">
                <Grid item xs={9}>
                  <Grid
                    style={{
                      textAlign: "center",
                      backgroundColor: "#FFFBF6",
                      padding: "40px 0px 40px 0px",
                      marginTop: "50px",
                      marginBottom: "15px",
                    }}
                  >
                    <Grid container display="flex" justifyContent="center">
                      <Grid item xs={5} display="flex" justifyContent="center" flexDirection="column">
                        <Typography>
                          <CheckCircleOutlineIcon />
                        </Typography>
                        <Typography className={classes.roletext} style={{ color: "#2F2F2F", paddingBottom: "15px" }}>
                          Invite(s) sent successfully
                        </Typography>
                        <Typography className={classes.roletype1} style={{ color: "#2F2F2F" }}>
                          You may update each candidate's Profile (resume & professional links) and Behavioral Section
                          by clicking on the View Details link below.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container display="flex" justifyContent="center">
                    <Grid item xs={8}>
                      {inviteList && inviteList.length > 0 && (
                        <GridTable
                          columns={headerData}
                          rows={inviteList}
                          checkbox={false}
                          getRowId={(row) => row._id || 0}
                          disableMultipleSelection={false}
                          handleRowClick={onInviteDetailRowClick}
                          color={"#FFFBF6"}
                          custom={true}
                          height={"auto"}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container display="flex" justifyContent="center">
                    <Grid item xs={5.5} display="flex" gap="20px" style={{ marginTop: "20px" }}>
                      <Button
                        data-rt-candidate-view-invite="BacktoAllInvites"
                        color="btnCls"
                        text="Back to All Invites"
                        // type="submit"
                        onClick={BackInvite}
                        width={199}
                        variant="outlined"
                      ></Button>
                      <Button
                        data-rt-candidate-view-invite="SendAnotherinvite"
                        color="btn"
                        text="Send Another Invite"
                        // type="submit"
                        onClick={SendAnotherInvite}
                        width={199}
                      ></Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );
}
