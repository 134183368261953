import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  Grid,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import Button from "../../../components/controls/Button";
import CloseIcon from "@mui/icons-material/Close";
import auth from "../../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../styles/theme";

const useStyles = makeStyles({
  dialogSty: {
    padding: "24px 36px !important",
  },
});

const ManageSubscriptionModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [buttonClicked, setButtonClicked] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const classes = useStyles();

  const handleContinue = () => {
    setButtonClicked(true);
    dispatch(auth.createBillingPortal());
  };

  useEffect(() => {
    const sessionUrl = authState?.createBillingPortalSuccess?.data?.sessionUrl;
    if (sessionUrl) {
      window.open(sessionUrl, "_self");
    }
  }, [authState.createBillingPortalSuccess]);

  return (
    <Dialog
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="responsive-dialog-title">
        Open Billing Portal to Manage Subscription
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent className={classes.dialogSty}>
        <Grid>
          <List sx={{ listStyleType: "disc" }}>
            <ListItem sx={{ display: "list-item", pl: "0px" }}>
              You will be redirected to the billing portal where you can upgrade or downgrade plans, update payment
              information or cancel plan.
            </ListItem>
            <ListItem sx={{ display: "list-item", pl: "0px" }}>
              Upgrading is prorated and effective immediately. Your monthly invite credits will also be prorated
              accordingly. Downgrading your plan will take effect at the end of the current cycle.
            </ListItem>
            <ListItem sx={{ display: "list-item", pl: "0px" }}>
              Cancelling paid plan will set your subscription to Free plan (
              <a href="https://www.techscore.ai/pricing" target="_blank" rel="noreferrer">
                view plans
              </a>
              ).
            </ListItem>
          </List>
        </Grid>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            text="Continue to Billing Portal"
            color={buttonClicked ? "disablebtn1" : "btn1"}
            sx={{ mt: 3 }}
            onClick={handleContinue}
            disabled={buttonClicked}
          ></Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ManageSubscriptionModal;
