import React, { useEffect } from "react";
import { CssBaseline, Container, Typography, Paper, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "../../components/Footer";
import { theme } from "../../styles/theme";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../redux/actions/auth";

const useStyles = makeStyles({
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 200px)",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const BookADemo = () => {
  const classes = useStyles();
  const logoUrl = process.env.REACT_APP_ASSETS_URL;
  const headerImageUrl = `${logoUrl}techscore.svg`;
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const { orgId, token } = useParams();

  useEffect(() => {
    let payload = {
      orgId,
      token,
    };
    dispatch(auth.bookademo(payload));
  }, []);

  return (
    <>
      <CssBaseline />
      <div className={classes.logocls}>
        <img height="50" width="157" src={headerImageUrl} alt="TechScore" />
      </div>
      {authState?.bookademoSuccess?.message === "Success" ? (
        <div className={`${classes.center}`}>
          <Container component="main" maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, borderRadius: 4, textAlign: "center" }}>
              <Box
                sx={{
                  borderRadius: "50%",
                  height: 150,
                  width: 150,
                  background: "#F8FAF5",
                  margin: "0 auto",
                }}
              >
                <Typography
                  component="i"
                  variant="h2"
                  sx={{
                    color: "#9ABC66",
                    fontSize: 80,
                    lineHeight: "150px",
                    marginLeft: "-15px",
                  }}
                >
                  ✓
                </Typography>
              </Box>
              <Typography variant="h5" component="h5" color="primary" sx={{ fontWeight: 600, my: 2 }}>
                Success
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ fontSize: 18, margin: 0 }}>
                {authState?.bookademoSuccess?.data?.message}
              </Typography>
            </Paper>
          </Container>
        </div>
      ) : (
        <div className={`${classes.center}`}>
          {authState?.bookademoFailure?.error ? (
            <h1>{authState?.bookademoFailure?.message}</h1>
          ) : (
            <h1>Page Not Found</h1>
          )}
        </div>
      )}
      <Footer />
    </>
  );
};

export default BookADemo;
