import React, { useState, useEffect } from "react";
import Plot from "./Plot";
import { useDispatch, useSelector } from "react-redux";
import chart from "../../../../redux/actions/chart";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { chartConfig } from "../../analytics/chartConfig";
import TimelineIcon from "@mui/icons-material/Timeline";
import { analyticsEvent } from "../../../../services/ga4utility";

const MultiLineScatterPlot = () => {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const chartStore = useSelector((state) => state.chart);
  const [data, setData] = useState(chartStore?.ScatterChartData);

  useEffect(() => {
    let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let endDate = new Date();
    let startDate = new Date(endDate);
    startDate.setMonth(endDate.getMonth() - 13);

    let payload = {
      startDate,
      endDate,
      chartType: "12 month chart",
      browserTimeZone,
    };
    dispatch(chart.ScatterChartData(payload));
    // dispatch(chart.ScatterChartDataError(""));
  }, []);

  useEffect(() => {
    if (chartStore?.scatterChartData) {
      setData(chartStore?.scatterChartData);
    }
  }, [chartStore?.scatterChartData]);

  // let endDate = new Date();
  // let startDate = new Date(endDate);
  // startDate.setMonth(endDate.getMonth() - 11);

  // console.log(startDate,endDate,"startDate")

  // // formate date and add 0 for empty month
  // if (data && data.xData && data.inviteData && data.completedData && data.scoreAchievedData && data.respondedData) {
  //   // Convert months to a readable format
  //   const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  //   // Loop through the months and add missing data with zeros
  //   while (startDate <= endDate) {
  //     const monthYear = `${months[startDate.getMonth()]} ${startDate.getFullYear()}`;

  //     const dataIndex = data.xData.indexOf(monthYear);

  //     if (dataIndex === -1) {
  //       // Month not found, add it with zeros
  //       data.xData.push(monthYear);
  //       data.inviteData.push(0);
  //       data.completedData.push(0);
  //       data.scoreAchievedData.push(0);
  //       data.respondedData.push(0);
  //     }

  //     // Move to the next month
  //     startDate.setMonth(startDate.getMonth() + 1);
  //   }

  //   // Sort xData and rearrange other arrays accordingly
  //   const sortedIndices = data.xData.map((item, index) => index).sort((a, b) => {
  //     const dateA = new Date(data.xData[a]);
  //     const dateB = new Date(data.xData[b]);
  //     return dateA - dateB;
  //   });

  //   data.xData = sortedIndices.map((index) => data.xData[index]);
  //   data.inviteData = sortedIndices.map((index) => data.inviteData[index]);
  //   data.completedData = sortedIndices.map((index) => data.completedData[index]);
  //   data.scoreAchievedData = sortedIndices.map((index) => data.scoreAchievedData[index]);
  //   data.respondedData = sortedIndices.map((index) => data.respondedData[index]);

  // }

  const inviteTrace = {
    x: data?.xData,
    y: data?.inviteData,
    mode: "lines+markers+text",
    text: data?.inviteData,
    textposition: "top",
    name: "Invites Sent",
    marker: {
      color: chartConfig.lineColors[0],
    },
    // hovertemplate: "<b>Invites Sent</b>" + "<br>Month-Year: <b>%{x}</b>" + "<br>Count: <b>%{y}</b><br>",
    hovertemplate: `<b>Invites Sent</b><br>Month-Year: <b>%{x}</b><br>Count: <b>%{y}</b><br>`,
  };

  const completedTrace = {
    x: data?.xData,
    y: data?.completedData,
    mode: "lines+markers+text",
    text: data?.completedData,
    textposition: "bottom",
    name: "Invites Completed",
    marker: {
      color: chartConfig.lineColors[3],
    },
    // hovertemplate: "<b>Invites Completed</b>" + "<br>Month-Year: <b>%{x}</b>" + "<br>Count: <b>%{y}</b><br>",
    hovertemplate: `<b>Invites Completed</b><br>Month-Year: <b>%{x}</b><br>Count: <b>%{y}</b><br>`,
  };

  const scoreAchievedTrace = {
    x: data?.xData,
    y: data?.scoreAchievedData,
    mode: "lines+markers+text",
    text: data?.scoreAchievedData,
    textposition: "top",
    name: "Target Score Achieved",
    marker: {
      color: chartConfig.lineColors[2],
    },
    // hovertemplate: "<b>Invites Completed</b>" + "<br>Month-Year: <b>%{x}</b>" + "<br>Count: <b>%{y}</b><br>",
    hovertemplate: `<b>Target Score Achieved</b><br>Month-Year: <b>%{x}</b><br>Count: <b>%{y}</b><br>`,
  };

  const respondedTrace = {
    x: data?.xData,
    y: data?.respondedData,
    mode: "lines+markers+text",
    text: data?.respondedData,
    textposition: "top",
    name: "Invites Responded",
    marker: {
      color: chartConfig.lineColors[1],
    },
    // hovertemplate: "<b>Invites Responded</b>" + "<br>Month-Year: <b>%{x}</b>" + "<br>Count: <b>%{y}</b><br>",
    hovertemplate: `<b>Invites Responded</b><br>Month-Year: <b>%{x}</b><br>Count: <b>%{y}</b><br>`,
  };
  let showlegend;
  if (open === false) showlegend = false;
  else showlegend = true;

  let text;
  if (open === false)
    text =
      '<span style="font-size:1rem; font-weight:Bold">Invite Statistics</span><br><span style="font-size:0.8rem">(Last 12 months)</span>';
  else
    text =
      '<span style="font-size:1.2rem;font-weight:Bold">Invite Statistics</span><br><span style="font-size:0.9rem">(Last 12 months)</span>';

  let yaxis;
  if (open === false) yaxis = '<span style="font-size:.8rem; font-weight:Bold">Number of invites</span>';
  else yaxis = '<span style="font-size:1rem;font-weight:Bold">Number of invites</span>';

  let xaxis;
  if (open === false) xaxis = '<span style="font-size:.8rem; font-weight:Bold">Month-Year</span>';
  else xaxis = '<span style="font-size:1rem;font-weight:Bold">Month-Year</span>';

  const layout = {
    // margin: {
    //   l: 50,
    //   r: 30,
    //   b: 80,
    //   t: 50,
    //   // pad: 4,
    // },
    autosize: true,
    // categoryorder: "category ascending",
    showlegend: showlegend,
    legend: {
      font: { size: 14 },
      title: {
        text: '<span style="font-size:0.9rem; font-weight:Bold">&nbsp;&nbsp;&nbsp;</span>',
      },
      // orientation: "h",
      // side: 'top',
      // x: 0,
      // y: 1.05,
    },

    title: {
      text: text,
      standoff: -50,
      // y: 0.85, // adjest legend location
    },
    xaxis: {
      showspikes: false,
      title: {
        text: xaxis,
        standoff: 20,
      },
      showline: true,
      tickangle: -90,
      tickheight: 100,
      ticks: "inside",
      // font: {
      //     size: 50,
      //     color: 'red'
      //     // face: 'Bold'
      // }
    },
    yaxis: {
      showspikes: false,
      rangemode: "tozero",
      title: {
        text: yaxis,
        standoff: 25,
      },
      showline: true,
      tickheight: 100,
      ticks: "inside",
    },
    hoverlabel: {
      bgcolor: "#FFF",
    },
    font: chartConfig.font,
    hovermode: "closest",
  };

  return (
    <>
      {chartStore?.scatterChartData?.xData?.length ? (
        <Plot
          data={[inviteTrace, completedTrace, scoreAchievedTrace, respondedTrace]}
          layout={layout}
          style={{ width: "95%", margin: "10px" }}
          containerId="multiline"
          config={{
            modeBarButtonsToAdd: [
              {
                name: "Full Screen",
                icon: chartConfig.fullScreenIcon,
                click: function (gd) {
                  handleOpen();
                  analyticsEvent("chart", "Invite Statistics Chart Open", "Invite Statistics");
                },
              },
            ],
            displaylogo: false,
            responsive: true,
            // modeBarButtonsToRemove: ['toImage', 'select2d', 'lasso2d', 'resetScale2d', 'zoom2d']
            modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
            textAlign: "center",
          }}
        >
          <TimelineIcon style={{ marginBottom: "5px" }} />
          <span style={{ marginLeft: "5px", marginBottom: "5px" }}>{chartConfig.MessageWhenNoData}</span>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        bg="red"
      >
        <Box sx={styleModal} style={{ width: "100%", height: "100%", margin: "auto" }}>
          <Plot
            data={[inviteTrace, respondedTrace, completedTrace, scoreAchievedTrace]}
            layout={layout}
            style={{ width: "100%", height: "100%" }}
            containerId="multilineModal"
            config={{
              modeBarButtonsToAdd: [
                {
                  name: "Exit Full Screen",
                  icon: chartConfig.exitFullScreenIcon,
                  click: function (gd) {
                    handleClose();
                  },
                },
              ],
              displaylogo: false,
              responsive: true,
              // modeBarButtonsToRemove: ['toImage', 'select2d', 'lasso2d', 'resetScale2d', 'zoom2d']
              modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default MultiLineScatterPlot;
