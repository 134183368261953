/**
 * App Config File
 */
localStorage.Authorization = localStorage.getItem("token1")
  ? localStorage.getItem("token1")
  : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHlvdXJ0ZWNoc2NvcmUuY29tIiwiYWlkIjoiNDYzNjQzMTIyODA2IiwidXNlcklkIjoiNjJjYmVhMDhkZjVkNDk0YTM0ZDRjNWY1IiwiaWF0IjoxNjU3NTQzOTA5fQ.gBYO_Yx78kJ46nXjIEbHtGWcUr1lmiRbFKBdQgN800w";
//  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.eGvoVVdzTNCE3KAn-2pbNxrL-LS0emm-e3juFpCabpA";
const AppConfig = {
  TimeValue: "false",
  code: "",
  l0QuestionCode: "",
  l1QuestionCode: "",
  l2QuestionCode: "",
  l3QuestionCode: "",

  LEVEL0: "L0",
  LEVEL1: "L1",
  LEVEL2: "L2",
  LEVEL3: "L3",
  LEVEL3_PRESENTATION: "L3-Presentation",
  LEVEL3_PROFILE: "L3-Profile",
  LEVEL3_CODING: "L3-Coding",
  LEVEL3_RESUME: "L3-Resume",

  goBackButton: false,
  goBackFlag: false,

  UploadStatus: {
    INITIAL: "1",
    UPLOADING: "2",
    UPLOADED: "3",
    ERRORED: "4",
  },

  TimeAlert: {
    value: "1:00",
  },

  TimeExceeds: {
    value: "0:00",
  },
  validExtension: ["doc", "docx", "txt", "pdf"],
  validProfilePhotoExtension: ["png", "jpeg", "jpg"],

  TimerExceedsSubmitted: "submitted",
  TimerExceedsExit: "exited",

  // Teal Theme Colors
  tealThemeColors: {
    primary: "#028484",
    secondary: "#d99830",
    success: "#00d41a",
    info: "#00d0bd",
    warning: "#ffb70f",
    error: "#e64c3d ",
    textPrimary: "#2F2F2F",
    textSecondary: "#717275",
    divider: "#e0e0e0",
    icon: "#717275",
    bgPaper: "#FFFFFF",
    bgDefault: "#f3f7fa",
    white: "#ffffff",
    black: "#000000",
  },

  compilerToTplFieldMap: {
    java: "Java_Template",
    python: "Python3_Template",
    javascript: "JavaScript_Template",
    go: "Golang_Template",
    c: "C_Template",
    csharp: "C#_Template",
    cpp: "C++_Template",
    scala: "Scala_Template",
    r: "R_Template",
    swift: "Swift_Template",
    kotlin: "Kotlin_Template",
  },

  stepToWelcomeScreenMap: {
    L0: {},
    L1: {
      // Technical: null,
      Profile: "/ProfileOpeningScreen",
      Coding: "/CodingEvaluation",
      Presentation: "/PresentationEvaluation",
    },
    L2: {},
    L3: {},
  },
  categoryTheme: {
    Technical: {
      bgColor: "#61A2E4",
      borderColor: "#61A2E4",
    },
    Profile: {
      bgColor: "#FF941C",
      borderColor: "#FF941C",
    },
    Coding: {
      bgColor: "#28BB3F",
      borderColor: "#28BB3F",
    },
    Presentation: {
      bgColor: "#C872FC",
      borderColor: "#C872FC",
    },
    "Profile & Presentation": {
      bgColor: "#C872FC",
      borderColor: "#C872FC",
    },
  },
  small: "8px", //- 0.5000em (all legeds)
  medium: "10px", //- 0.6250em (tables) (dropdowns)
  large: "13px",
};

export default AppConfig;
