import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Chip, Typography } from "@mui/material";
import { theme } from "../styles/theme";
import { useLocation } from "react-router-dom";
import auth from "../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { getDomainDetails } from "../services/globalService";

const useStyles = makeStyles({
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  statusAndText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "20px !important",
    color: `${theme.palette.black.dark}`,
    paddingTop: "20px",
    paddingBottom: "20px",
  },
});

export default function PageNotFound() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth);
  const [domainDetails, setDomainDetails] = useState({});
  const logoUrl = process.env.REACT_APP_ASSETS_URL;
  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  const location = useLocation();
  const { state } = location;

  if (state && state.data) {
    const failureData = state.data;
    console.log("Failure Data:", failureData);
  }

  // Extract subdomain info
  useEffect(() => {
    let domainName = getDomainDetails(window.location.href);
    dispatch(auth.GetLogo({ subdomain: domainName.subdomainFromUrl }));
    setDomainDetails(authStore.logoDetails);
  }, []);

  useEffect(() => {
    setDomainDetails(authStore.logoDetails);
  }, [authStore.logoDetails]);

  return (
    <Grid>
      <div className={`${classes.center} ${classes.logocls}`}>
        {domainDetails && domainDetails?.logo !== "" ? (
          <>
            <img height="40" width="157" src={`${logoUrl}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
            <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
          </>
        ) : (
          <>
            <img height="57" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
          </>
        )}
      </div>
      <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"} style={{ height: "80vh" }}>
        {state && state.data ? (
          <Grid className={classes.container}>
            <div>
              <h1 className={classes.statusAndText}>
                <Chip sx={{ mr: 2, fontSize: "24px", height: 40 }} label={state.data.status || "404"} color="error" />
                {state.data.statusText || "Not Found"}
              </h1>
              <h1>{state.data.message || "This resource is not available."}</h1>
            </div>{" "}
            {userDetails && userDetails?.userId ? (
              <Grid>
                <Typography className={classes.textview}>
                  <a href="/home">Back to Home page</a>
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <h1 className={classes.statusAndText}>
            <Chip sx={{ mr: 2, fontSize: "24px", height: 40 }} label={"404"} color="error" /> Page Not Found
          </h1>
        )}
      </Grid>
    </Grid>
  );
}
