import React from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
// import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../styles/theme";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    color: theme.palette.btnPrimary.tertiaryText,
    cursor: "pointer",
    "&:hover": {
      color: "#cd73ef",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  linkunderline: {
    "& .css-1ps4owl-MuiTypography-root-MuiLink-root": {
      textDecoration: "none !important",
    },
  },
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    // paddingBottom: "50px"
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("tablet")]: {
      height: 90,
      // position:'fixed',
      bottom: "0px",
      width: "100% !important",
      //right:'0px'
    },
  },
  footerRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  footerLeftAlign: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  copyRight: {
    //  float: "right",
    flexGrow: 1,
    color: theme.palette.btnPrimary.tertiaryText,
    textAlign: "end",
  },
  floatLeft: {
    float: "left",
  },
  aboutLeft: {
    marginRight: "40px",
    [theme.breakpoints.down("sm")]: {
      margin: "24px auto 10px auto",
    },
  },
  divider: {
    margin: "0 10px",
    color: "#787878",
  },
  containerCls: {
    paddingRight: "24px",
    paddingLeft: "24px",
    [theme.breakpoints.up("tablet")]: {
      paddingRight: "auto !important",
      paddingLeft: "64px !important",
    },
  },
  terms: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
      marginTop: "33px",
    },
  },
  policy: {
    marginBottom: "33px",
  },
  companyName: {
    marginBottom: "24px",
  },
  footerLeftDrawer: {
    position: "fixed",
    bottom: "48px",
    "& .MuiGrid-container": {
      justifyContent: "center",
    },
    "@media (orientation: landscape)": {
      position: "unset",
      marginBottom: "48px",
    },
  },
});

export default function Footer(props) {
  const { leftDrawer } = props;
  const classes = useStyles();
  // const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const d = new Date();
  let year = d.getFullYear();
  const navigate = useNavigate();
  return (
    <div className={classes.footer}>
      {/* {leftDrawer && (
     <Container maxWidth="xl"  className={`${classes.footerLeftDrawer} ${classes.containerCls}`}>
     <Grid container className="Home-hero">
       <Grid item >
         <div className={classes.footerLeftAlign}>
               <Link onClick={() => history.push("/FAQ")} data-rt-div-faq = "faqlink">
                 <Typography variant="body2" className={classes.title}>
                   FAQ
                 </Typography>
               </Link>
               <b className={classes.divider}>|</b>
         </div>
       </Grid>
       <Grid item >
           <div className={classes.footerRight}>
             <Link onClick={() => history.push("/Terms&Conditions")} data-rt-div-tc = "tclink">
               <Typography variant="body2" className={classes.title}>
                 Terms & Conditions
               </Typography>
             </Link>
         </div>
       </Grid>
       <Grid item >
           <div className={classes.footerRight}>
             <b className={classes.divider}>|</b>
             <Link onClick={() => history.push("/Privacypolicy")} data-rt-div-privacypolicy = "privacypolicylink">
               <Typography variant="body2" className={classes.title}>
               Privacy Policy
               </Typography>
             </Link>
         </div>
       </Grid>
     </Grid>
   </Container>
      )} */}

      {isNotMobile && !leftDrawer && (
        <Container maxWidth="xl" className={classes.container}>
          <Grid container style={{ paddingTop: "15px" }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={4}>
              <div className={classes.footerRight}>
                <Grid
                  onClick={() => navigate("/terms&conditions")}
                  data-rt-div-tc="tclink"
                  className={classes.linkunderline}
                >
                  <Typography variant="body2" className={classes.title}>
                    Terms & Conditions
                  </Typography>
                </Grid>
                <b className={classes.divider}>|</b>
                <Grid
                  onClick={() => navigate("/privacypolicy")}
                  data-rt-div-privacypolicy="privacypolicylink"
                  className={classes.linkunderline}
                >
                  <Typography variant="body2" className={classes.title}>
                    Privacy Policy
                  </Typography>
                </Grid>
              </div>
              <div className={classes.company}>
                <Typography variant="body2" className={`${classes.copyRight}`}>
                  Copyright &copy;{year} TechScore. All Rights Reserved.
                </Typography>
              </div>
              {/* </div> */}
            </Grid>
          </Grid>
        </Container>
      )}

      {!isNotMobile && !leftDrawer && (
        <Container maxWidth="xl" className={classes.container}>
          <Grid container className="Home-hero" style={{ paddingTop: "15px" }}>
            <Grid item xs={12}>
              <div className={classes.floatLeft}>
                <div className={classes.footerLeftAlign}>
                  <div className={`${classes.aboutLeft}  ${classes.terms}`}>
                    <Grid
                      //onClick={() => history.push("/Terms&Conditions")}
                      data-rt-div-tc="tclink"
                      className={classes.linkunderline}
                    >
                      <Typography variant="body2" className={classes.title}>
                        Terms & Conditions
                      </Typography>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.policy}>
                <Grid
                  // onClick={() => history.push("/Privacypolicy")}
                  data-rt-div-privacypolicy="privacypolicylink"
                  className={classes.linkunderline}
                >
                  <Typography variant="body2" className={classes.title}>
                    Privacy Policy
                  </Typography>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.companyName}>
                <Typography variant="body2">Copyright &copy;{year} TechScore. All Rights Reserved.</Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}
