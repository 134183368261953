import React, { useEffect } from "react";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, Tooltip, useMediaQuery } from "@mui/material";
import { theme } from "../../../styles/theme";
import Button from "../../../components/controls/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
import BlueArrow from "../../../assets/svg/BlueArrow.svg";
import moment from "moment";
import CustomBreadcrumbs from "../../../components/controls/CustomBreadcrumbs";

// styles of this component
const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
  },
  down_arrow: {
    padding: "8px",
    marginTop: "10px",
  },
  btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "#1789FC !important",
  },
  cancel_btn: {
    width: "112px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#787878 !important",
    boxShadow: "none !important",
  },
  previous_btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#1789FC !important",
    boxShadow: "none !important",
    border: "1px solid #C4C4C4 !important",
    marginRight: "10px !important",
  },
  field: {
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  scoreLeftArea: {
    padding: 8,
    width: "100%",
    "@media (min-width:1450px)": {
      padding: 36,
      width: "auto",
    },
  },
  scoreMeter: {
    marginTop: -85,
    marginBottom: "5px",
  },
  textareasty: {
    width: "513px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
});
export default function InviteSummary(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const [disablebtn, setDisablebtn] = React.useState(false);
  const matches = useMediaQuery("(max-width:850px)");
  const [categoryToDisplay, setCategoryToDisplay] = React.useState([]);
  const authState = useSelector((state) => state.auth);

  const { values } = props;
  // Creating an array of candidate uids .
  let candidatelist = (values.candidateSelect || []).map((item, i) => {
    return item.candidateId;
  });

  const previousBtn = (params) => {
    if (props.previousList !== undefined) {
      props.previousList(params);
    } else {
      return null;
    }
  };

  // It navigates to the invitelist page when the cancel button is clicked.
  const cancelBtn = () => {
    navigate("/invitelist");
  };

  const SendInvite = () => {
    let payload = {
      candidates: candidatelist,
      jobRole: {
        uid: values.roleSelect.uid,
      },
      selectedTestingRequirement: values.testingRequirement,
      useExistingInfoForProfileAndPresentation: values.uipp,
      shareResultsWithCandidates: values.src,
      testExpiresOn: values.testExpire,
      noteForCandidate: values.message,
      note: values.note,
    };

    dispatch(role.addInvite(payload));
    setDisablebtn(true);
  };

  // Checking if the status is 0, if it is, it will navigate to the invitesuccess page. If it is not, it will set the disablebtn to false.
  useEffect(() => {
    if (roleState?.addinvite?.status === 0) {
      navigate("/invitesuccess");
      setDisablebtn(false);
    } else {
      setDisablebtn(false);
    }
    // eslint-disable-next-line
  }, [roleState?.addinvite?.status]);

  useEffect(() => {
    if (roleState?.roleCategories && values.roleSelect.category) {
      const categoryMapping = roleState?.roleCategories;
      if (categoryMapping && values.roleSelect.category) {
        const categoryDisplayValue = categoryMapping[values.roleSelect.category];
        setCategoryToDisplay(categoryDisplayValue);
      }
    }
  }, [roleState?.roleCategories, values.roleSelect.category]);

  return (
    <>
      {matches ? (
        <div>
          <NavBar />
          <Container sx={{ textAlign: "center" }}>
            <Grid sx={{ mt: 10 }}>
              <Grid>
                <CustomBreadcrumbs
                  links={[
                    { name: "Home", path: "/home" },
                    { name: "Invites", path: "/invitelist" },
                    { name: "Create a New Invite" },
                  ]}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Grid onClick={previousBtn}>
                    <Typography className={classes.roletype} style={{ color: "#1789FC", cursor: "pointer" }}>
                      <span>
                        <img src={BlueArrow} alt="BlueArrow" />
                      </span>{" "}
                      Back
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "center", mt: 2 }}>
                    <Typography className={classes.roletext}>Invite Summary</Typography>
                  </Grid>
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <Grid>
                    <Grid style={{ marginBottom: "20px" }}>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        Role Selection
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Role ID</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.uid}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Role Name</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Role Category</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {categoryToDisplay?.length > 0 ? categoryToDisplay : []}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Required Degree</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.degree}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Years of Experience</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.yoe}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Target</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.targetScore}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}> Coding Language</Typography>
                        <Tooltip title={values.roleSelect.codingLang.join(" / ")}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {values.roleSelect.codingLang.join(" / ")}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}> Required Skills</Typography>
                        {(values.roleSelect.selectedSkills || []).map((skill, i) => {
                          return (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {/* {skill} */}
                              {authState?.skillsConfig[skill]?.fullName}
                            </Typography>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  </Grid>
                  <Grid>
                    <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                      Testing Requirements
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Sections to Test</Typography>
                        {(values.testingRequirement || []).map((test, i) => {
                          return (
                            <Typography className={classes.roletype} sx={{ color: "#2F2F2F" }}>
                              {test === "Presentation" ? "Behavioral" : test}
                            </Typography>
                          );
                        })}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>
                          Use existing Profile/Behavioral information
                        </Typography>
                        <Typography className={classes.roletype} sx={{ color: "#2F2F2F" }}>
                          {values.uipp === true ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Expiration Date</Typography>
                        <Typography className={classes.roletype} sx={{ color: "#2F2F2F" }}>
                          {moment(values.testExpire).format("MMM DD,YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Share results</Typography>
                        <Typography className={classes.roletype} sx={{ color: "#2F2F2F" }}>
                          {values.src === true ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  </Grid>
                  <Grid>
                    <Typography className={classes.roletype} sx={{ color: "#2F2F2F" }}>
                      Candidate Selection
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype}>Invite List</Typography>
                        {(values.candidateSelect || []).map((item, i) => {
                          return (
                            <Grid sx={{ height: "45px" }}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                {" "}
                                <span>
                                  {item.candidateId}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                  {item.firstName}&nbsp;
                                  {item.lastName}{" "}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype}>Email</Typography>
                        {(values.candidateSelect || []).map((item, i) => {
                          return (
                            <Grid sx={{ height: "45px" }}>
                              <Typography className={classes.roletype} sx={{ color: "#2F2F2F", textAlign: "center" }}>
                                {item.email}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4" }} />

                    <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                      Invite & Internal Notes
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Message (optional)</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.message && values.message.length > 0 ? values.message : "None"}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Internal Notes (optional)</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.note && values.note.length > 0 ? values.note : "None"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "12px",
                    }}
                  >
                    <Button
                      data-rt-invite-add="cancel"
                      color="cancel_btn"
                      text="Cancel"
                      // type="submit"
                      onClick={cancelBtn}
                      width={112}
                    ></Button>
                    <>
                      <Button
                        color={disablebtn === true ? "disablebtn" : "btn"}
                        width={240}
                        data-rt-invite-add="SendinvitetoList"
                        text={"Send Invite to List"}
                        type="submit"
                        onClick={disablebtn === true ? "" : SendInvite}
                      />
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <div>
          <NavBar />
          <Container maxWidth="xl" className={classes.container}>
            <Grid style={{ marginTop: "130px" }}>
              <Grid style={{ display: "flex" }}>
                <CustomBreadcrumbs
                  links={[
                    { name: "Home", path: "/home" },
                    { name: "Invites", path: "/invitelist" },
                    { name: "Create a New Invite" },
                  ]}
                />
              </Grid>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <Grid onClick={previousBtn}>
                    <Typography className={classes.roletype} style={{ color: "#1789FC", cursor: "pointer" }}>
                      <span>
                        <img src={BlueArrow} alt="BlueArrow" />
                      </span>{" "}
                      Back
                    </Typography>
                  </Grid>
                  <Grid className={classes.spaceflex}>
                    <Typography className={classes.roletext}>Invite Summary</Typography>
                  </Grid>
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <Grid>
                    <Grid style={{ marginBottom: "20px" }}>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        Role Selection
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography className={classes.roletype1}>Role ID</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.uid}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.roletype1}>Role Name</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.roletype1}>Role Category</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {categoryToDisplay?.length > 0 ? categoryToDisplay : []}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.roletype1}>Required Degree</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.degree}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.roletype1}>Years of Experience</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.yoe}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.roletype1}>Target</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.roleSelect.targetScore}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.roletype1}> Coding Language</Typography>
                        <Tooltip title={values.roleSelect.codingLang.join(" / ")}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {values.roleSelect.codingLang.join(" / ")}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.roletype1}> Required Skills</Typography>
                        {(values.roleSelect.selectedSkills || []).map((skill, i) => {
                          return (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {/* {skill} */}
                              {authState?.skillsConfig[skill]?.fullName}
                            </Typography>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  </Grid>
                  <Grid>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        Testing Requirements
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography className={classes.roletype1}>Sections to Test</Typography>
                        {(values.testingRequirement || []).map((test, i) => {
                          return (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {test === "Presentation" ? "Behavioral" : test}
                            </Typography>
                          );
                        })}
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.roletype1}>Expiration Date</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {moment(values.testExpire).format("MMM DD,YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.roletype1}>Share results</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.src === true ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.roletype1}>
                          Use existing Profile/Behavioral information
                        </Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.uipp === true ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  </Grid>
                  <Grid>
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography className={classes.roletype1}>Candidate Selection</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Invite List</Typography>
                        {(values.candidateSelect || []).map((item, i) => {
                          return (
                            <Grid>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                {" "}
                                <span>
                                  {item.candidateId}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                  {item.firstName}&nbsp;
                                  {item.lastName}{" "}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Email</Typography>
                        {(values.candidateSelect || []).map((item, i) => {
                          return (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {item.email}
                            </Typography>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4" }} />

                    <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                      Invite & Internal Notes
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Message (optional)</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.message && values.message.length > 0 ? values.message : "None"}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Internal Notes (optional)</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {values.note && values.note.length > 0 ? values.note : "None"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "12px",
                    }}
                  >
                    <Button
                      data-rt-invite-add="cancel"
                      color="cancel_btn"
                      text="Cancel"
                      // type="submit"
                      onClick={cancelBtn}
                      width={112}
                    ></Button>
                    <>
                      <Button
                        color={disablebtn === true ? "disablebtn" : "btn"}
                        width={240}
                        data-rt-invite-add="SendinvitetoList"
                        text={"Send Invite to List"}
                        type="submit"
                        onClick={disablebtn === true ? "" : SendInvite}
                      />
                    </>
                  </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </>
  );
}
