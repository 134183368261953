import { Field, Form, Formik } from "formik";
import { resetPasswordSchema } from "../../../schemas/userProfile";
import { IconButton, Typography, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import auth from "../../../redux/actions/auth";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "../../../components/controls/Button";

const useStyles = makeStyles({
  errorty: {
    color: "#DD2E2E",
  },
});

const ResetPassword = ({ toggleForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const handlePasswordSubmit = (values, { resetForm }) => {
    const { oldPassword, newPassword } = values;
    const payload = {
      oldPassword,
      uid: userDetails.userId,
      newPassword,
    };
    dispatch(auth.changePassword(payload));
  };

  return (
    <Formik initialValues={initialValues} validationSchema={resetPasswordSchema} onSubmit={handlePasswordSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
        <Form>
          <Typography fontWeight={500} sx={{ mt: 4 }}>
            Reset Password
          </Typography>
          <div style={{ width: "40%" }}>
            <Typography fontWeight={500} sx={{ mt: 2 }}>
              Old Password
            </Typography>
            <div style={{ position: "relative" }}>
              <Field
                type={showPasswordOld ? "text" : "password"}
                name="oldPassword"
                placeholder="Enter Your Old Password"
                onBlur={handleBlur}
                value={values.oldPassword}
                onChange={handleChange}
                as={TextField}
                sx={{ mt: 1 }}
                fullWidth
                hiddenLabel
                variant="outlined"
                size="small"
              />
              <IconButton
                onClick={() => setShowPasswordOld(!showPasswordOld)}
                style={{
                  position: "absolute",
                  top: "60%",
                  right: 0,
                  transform: "translateY(-50%)",
                }}
              >
                {showPasswordOld ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
            {touched.oldPassword && errors.oldPassword && (
              <div className="error">
                <Typography className={classes.errorty}>{errors.oldPassword}</Typography>
              </div>
            )}
          </div>

          <div style={{ width: "40%" }}>
            <Typography fontWeight={500} sx={{ mt: 2 }}>
              New Password
            </Typography>
            <div style={{ position: "relative" }}>
              <Field
                type={showPasswordNew ? "text" : "password"}
                name="newPassword"
                placeholder="Enter Your New Password"
                onBlur={handleBlur}
                value={values.newPassword}
                onChange={handleChange}
                as={TextField}
                sx={{ mt: 1 }}
                fullWidth
                hiddenLabel
                variant="outlined"
                size="small"
              />
              <IconButton
                onClick={() => setShowPasswordNew(!showPasswordNew)}
                style={{
                  position: "absolute",
                  top: "60%",
                  right: 0,
                  transform: "translateY(-50%)",
                }}
              >
                {showPasswordNew ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
            {touched.newPassword && errors.newPassword && (
              <div className="error">
                <Typography className={classes.errorty}>{errors.newPassword}</Typography>
              </div>
            )}
          </div>

          <div style={{ width: "40%" }}>
            <Typography fontWeight={500} sx={{ mt: 2 }}>
              Confirm New Password
            </Typography>
            <div style={{ position: "relative" }}>
              <Field
                type={showPasswordConfirm ? "text" : "password"}
                name="confirmNewPassword"
                placeholder="Confirm Your New Password"
                onBlur={handleBlur}
                value={values.confirmNewPassword}
                onChange={handleChange}
                as={TextField}
                sx={{ mt: 1 }}
                fullWidth
                hiddenLabel
                variant="outlined"
                size="small"
              />
              <IconButton
                onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                style={{
                  position: "absolute",
                  top: "60%",
                  right: 0,
                  transform: "translateY(-50%)",
                }}
              >
                {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
            {touched.confirmNewPassword && errors.confirmNewPassword && (
              <div className="error">
                <Typography className={classes.errorty}>{errors.confirmNewPassword}</Typography>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "40%",
            }}
          >
            <Button type="button" text="Cancel" color="cancel_btn" onClick={toggleForm} sx={{ mt: 2 }}></Button>
            <Button
              type="submit"
              text="Reset Password"
              color={isValid ? "btn1" : "disablebtn1"}
              sx={{ mt: 2 }}
            ></Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
