import { Tooltip } from "@mui/material";
import moment from "moment";
import { codingLanguageDisplay } from "../services/globalService";

const generateHeaders = (arr, sortable) => {
  (arr || []).forEach((item) => {
    if (!item.headerAlign) item.headerAlign = item.type === "number" || item.type === "date" ? "right" : "left";

    if (!item.align) item.align = item.type === "number" || item.type === "date" ? "right" : "left";

    if (!item.disableColumnMenu) item.disableColumnMenu = true;

    if (!item.disableReorder) item.disableReorder = true;

    if (!item.sortable) item.sortable = sortable;

    if (!item.flex) item.flex = 1;
  });
  return arr;
};
// Candidates Header  UI

function candidateHeader(sortable = true, additionalHeaders = null) {
  var headers = [
    {
      field: "uid",
      headerName: "Candidate ID",
      flex: 1.2,
      valueGetter: ({ value }) => value,
    },
    {
      field: "firstName",
      headerName: "First Name",
      valueGetter: (params) => params.row.user.firstName,
      renderCell: (params) => {
        return <div>{params.row.user.firstName}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      valueGetter: (params) => params.row.user.lastName,
      renderCell: (params) => {
        return <div>{params.row.user.lastName}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div style={{ textTransform: "capitalize" }}>{params.row.status}</div>;
      },
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      flex: 1.5,
      valueGetter: ({ value }) => value,
    },
    {
      field: "degree",
      headerName: "Degree",
      valueGetter: ({ value }) => value,
    },
    {
      field: "yoeDisplay",
      headerName: "Experience",
      valueGetter: ({ value }) => value && `${parseInt(value) >= 0 ? value + " years" : "--"}`,
    },
    {
      field: "createdAt",
      headerName: "Added On",
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div style={{ marginRight: "5px" }}>{moment(params.row.createdAt).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "jobRoleInvites",
      headerName: "Invites",
      valueGetter: (params) => params.row.jobRoleInvites.length,
      type: "number",
      align: "center",
      headerAlign: "center",
      flex: 0.9,
      renderCell: (params) => {
        if (params.row.jobRoleInvites?.length === 0) {
          return <div>--</div>;
        }
        return (
          <div style={{ marginRight: "5px" }}>
            <span>
              {params.row.completedCount} / {params.row.jobRoleInvites?.length}
            </span>
          </div>
        );
      },
    },
    {
      field: "highestScore",
      headerName: "Highest",
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (!params.row.highestScore || params.row.highestScore === 0) {
          return <div>--</div>;
        }
        return <div>{params.row.highestScore}</div>;
      },
    },
  ];

  if (additionalHeaders && additionalHeaders.length > 0) {
    headers = headers.concat(additionalHeaders);
  }
  let alignHeaders = generateHeaders(headers, sortable);
  return alignHeaders;
}

// candidate list page header
function candidateHeaderHome(sortable = true, additionalHeaders = null) {
  var headers = [
    {
      field: "uid",
      headerName: "Candidate ID",
      flex: 1.2,
      valueGetter: ({ value }) => value,
    },
    {
      field: "firstName",
      headerName: "First Name",
      valueGetter: (params) => params.row.user.firstName,
      renderCell: (params) => {
        return <div>{params.row.user.firstName}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      valueGetter: (params) => params.row.user.lastName,
      renderCell: (params) => {
        return <div>{params.row.user.lastName}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div style={{ textTransform: "capitalize" }}>{params.row.status}</div>;
      },
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      flex: 1.5,
      valueGetter: ({ value }) => value,
    },
    {
      field: "degree",
      headerName: "Degree",
      valueGetter: ({ value }) => value,
    },
    {
      field: "yoe",
      headerName: "Experience",
      valueGetter: ({ value }) => value && `${parseInt(value) >= 0 ? value + " years" : "--"}`,
    },
    {
      field: "createdAt",
      headerName: "Added On",
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div style={{ marginRight: "5px" }}>{moment(params.row.createdAt).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "jobRoleInvites",
      headerName: "Invites",
      valueGetter: (params) => params.row.jobRoleInvites.length,
      type: "number",
      align: "center",
      headerAlign: "center",
      flex: 0.9,
      renderCell: (params) => {
        if (params.row.jobRoleInvites?.length === 0) {
          return <div>--</div>;
        }
        return (
          <div style={{ marginRight: "5px" }}>
            <span>
              {params.row.completedCount} / {params.row.jobRoleInvites?.length}
            </span>
          </div>
        );
      },
    },
    {
      field: "highestScore",
      headerName: "Highest",
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (!params.row.highestScore || params.row.highestScore === 0) {
          return <div>--</div>;
        }
        return <div>{params.row.highestScore}</div>;
      },
    },
  ];

  if (additionalHeaders && additionalHeaders.length > 0) {
    headers = headers.concat(additionalHeaders);
  }
  let alignHeaders = generateHeaders(headers, sortable);
  return alignHeaders;
}

// InviteCreate Candidate component UI

function inviteCreateCandidateHeader(additionalHeaders = null) {
  var headers = [
    {
      field: "uid",
      headerName: "Candidate ID",
      flex: 1.1,
      valueGetter: ({ value }) => value && value,
    },
    {
      field: "firstName",
      headerName: "First Name",
      renderCell: (params) => {
        return <div>{params.row.user.firstName}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      renderCell: (params) => {
        return <div>{params.row.user.lastName}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Added On",
      type: "date",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div style={{ marginRight: "5px" }}>{moment(params.row.createdAt).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "degree",
      headerName: "Degree",
      valueGetter: ({ value }) => value && value,
    },
    {
      field: "yoeDisplay",
      headerName: "Experience",
      valueGetter: ({ value }) => value && `${parseInt(value) >= 0 ? value + " years" : "--"}`,
      // valueGetter: ({ value }) => (value && value.length > 0) && `${value} years`
    },
    {
      field: "jobRoleInvites",
      headerName: "Invites",
      type: "number",
      flex: 0.9,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.jobRoleInvites?.length === 0) {
          return <div>--</div>;
        }
        return (
          <div>
            <span>
              {params.row.completedCount} / {params.row.jobRoleInvites?.length}
            </span>
          </div>
        );
      },
    },
    {
      field: "highestScore",
      headerName: "Highest",
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.highestScore || params.row.highestScore === 0) {
          return <div>--</div>;
        }
        return <div>{params.row.highestScore}</div>;
      },
    },
  ];

  let alignHeaders = generateHeaders(headers, true);
  if (additionalHeaders && additionalHeaders.length > 0) {
    alignHeaders = alignHeaders.concat(additionalHeaders);
  }
  return alignHeaders;
}

function inviteCreateCandidateHeaderMobile(additionalHeaders = null) {
  var headers = [
    {
      field: "uid",
      headerName: "Candidate ID",
      flex: 1.1,
      valueGetter: ({ value }) => value && value,
    },
    {
      field: "firstName",
      headerName: "First Name",
      renderCell: (params) => {
        return <div>{params.row.user.firstName}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      renderCell: (params) => {
        return <div>{params.row.user.lastName}</div>;
      },
    },
  ];

  let alignHeaders = generateHeaders(headers, true);
  if (additionalHeaders && additionalHeaders.length > 0) {
    alignHeaders = alignHeaders.concat(additionalHeaders);
  }
  return alignHeaders;
}

// Invite list and home page header UI

function inviteHeader(sortable = true) {
  let headers = [
    {
      field: "uid",
      headerName: "Invite ID",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.uid}</div>;
      },
    },

    {
      field: "firstName",
      headerName: "First Name",
      valueGetter: (params) => params.row?.firstName,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.firstName}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      valueGetter: (params) => params.row.lastName,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.lastName}</div>;
      },
    },
    {
      field: "jobRoleId",
      headerName: "Role ID",
      valueGetter: (params) => params.row.jobRoleId,
      renderCell: (params) => {
        let RoleId = params.row.jobRoleId;
        return <div style={{ cursor: "pointer" }}>{RoleId}</div>;
      },
    },
    {
      field: "jobRoleName",
      headerName: "Role Name",
      flex: 1.6,
      valueGetter: (params) => params.row.jobRoleName,
      renderCell: (params) => {
        let roleName = params.row.jobRoleName;
        return (
          <Tooltip title={roleName}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {roleName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer", textTransform: "capitalize" }}>{params.row.status}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Invited On",
      type: "date",
      flex: 0.9,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{moment(params.row.createdAt).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "jobRoleTargetScore",
      headerName: "Target",
      valueGetter: (params) => params.row.jobRoleTargetScore,
      type: "number",
      flex: 0.9,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let roletarget = params.row.jobRoleTargetScore;
        return <div style={{ cursor: "pointer" }}>{roletarget}</div>;
      },
    },
    {
      field: "proportionalScore",
      headerName: "Score",
      type: "number",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.testsTakenCount === 0 && params.row.proportionalScore == 0) {
          params.row["proportionalScore"] = -1;
        }

        if (params.row.testsTakenCount === 0 || params.row.proportionalScore === undefined) {
          return <div>--</div>;
        } else if (params.row.proportionalScore < params.row.jobRoleTargetScore) {
          return <div style={{ color: "#FF941C" }}>{params.row.proportionalScore}</div>;
        } else if (params.row.proportionalScore >= params.row.jobRoleTargetScore) {
          return <div style={{ color: "#28BB3F" }}>{params.row.proportionalScore}</div>;
        }
      },
    },
    {
      field: "testsTakenCount",
      headerName: "Completed",
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.testsTakenCount} / {params.row.selectedTestingRequirement.length}
          </div>
        );
      },
    },
  ];
  let alignHeaders = generateHeaders(headers, sortable);
  return alignHeaders;
}

function inviteHeaderHome(sortable = true) {
  let headers = [
    {
      field: "uid",
      headerName: "Invite ID",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.uid}</div>;
      },
    },

    {
      field: "newFirstName",
      headerName: "First Name",
      valueGetter: (params) => params.row?.newFirstName,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row?.newFirstName}</div>;
      },
    },
    {
      field: "newLastName",
      headerName: "Last Name",
      valueGetter: (params) => params.row.newLastName,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.newLastName}</div>;
      },
    },
    {
      field: "jobRole.uid",
      headerName: "Role ID",
      valueGetter: (params) => params.row.jobRole.uid,
      renderCell: (params) => {
        let RoleId = params.row.jobRole;
        return <div style={{ cursor: "pointer" }}>{RoleId.uid}</div>;
      },
    },
    {
      field: "jobRole.name",
      headerName: "Role Name",
      flex: 1.6,
      valueGetter: (params) => params.row.jobRole.name,
      renderCell: (params) => {
        let roleName = params.row.jobRole;
        return (
          <Tooltip title={roleName.name}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {roleName.name}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer", textTransform: "capitalize" }}>{params.row.status}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Invited On",
      type: "date",
      flex: 0.9,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{moment(params.row.createdAt).format("MMM DD ")}</div>;
      },
    },
    {
      field: "jobRole.targetScore",
      headerName: "Target",
      valueGetter: (params) => params.row.jobRole.targetScore,
      type: "number",
      flex: 0.9,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let roletarget = params.row.jobRole;
        return <div style={{ cursor: "pointer" }}>{roletarget.targetScore}</div>;
      },
    },
    {
      field: "proportionalScore",
      headerName: "Score",
      type: "number",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        if (params.row.proportionalScore < 0 || params.row.proportionalScore == undefined) {
          return <div>--</div>;
        } else if (params.row.proportionalScore < params.row.jobRoleTargetScore) {
          return <div style={{ color: "#FF941C" }}>{params.row.proportionalScore}</div>;
        } else if (params.row.proportionalScore >= params.row.jobRoleTargetScore) {
          return <div style={{ color: "#28BB3F" }}>{params.row.proportionalScore}</div>;
        }
      },
    },
    {
      field: "testsTakenCount",
      headerName: "Completed",
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.testsTakenCount} / {params.row.selectedTestingRequirement.length}
          </div>
        );
      },
    },
  ];
  let alignHeaders = generateHeaders(headers, sortable);
  return alignHeaders;
}

// Inivte ==> CreateJ New Invites Header UI

function inviteCreateJobRoleHeader(additionalHeaders = null) {
  var headers = [
    {
      field: "uid",
      headerName: "Role ID",
      valueGetter: ({ value }) => value && value,
    },
    {
      field: "name",
      headerName: "Role Name",
      flex: 2,
      valueGetter: ({ value }) => value && value,
    },
    // {
    //   field: "degree",
    //   headerName: "Degree",
    //   valueGetter: ({ value }) => value && value,
    // },
    // {
    //   field: "yoe",
    //   headerName: "Experience",
    //   valueGetter: ({ value }) => value && `${value} years`,
    // },

    {
      field: "createdAt",
      headerName: "Created On",
      type: "date",
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value && moment(value).format("DD MMM 'YY"),
    },
    {
      field: "displayName",
      headerName: "Created By",
      valueGetter: (params) => params.row.createdBy.displayName,
    },
    {
      field: "codingLang",
      headerName: "Language",
      flex: 1,
      valueGetter: ({ value }) => value && codingLanguageDisplay(value, 2),
    },
    {
      field: "targetScore",
      headerName: "Target Score",
      type: "number",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div style={{ marginLeft: "25px" }}>{params.row.targetScore}</div>;
      },
    },
  ];

  let alignHeaders = generateHeaders(headers, true);
  if (additionalHeaders && additionalHeaders.length > 0) {
    alignHeaders = alignHeaders.concat(additionalHeaders);
  }
  return alignHeaders;
}

function inviteCreateJobRoleHeaderMobile(additionalHeaders = null) {
  var headers = [
    {
      field: "uid",
      headerName: "Role ID",
      valueGetter: ({ value }) => value && value,
    },
    {
      field: "name",
      headerName: "Role Name",
      flex: 2,
      valueGetter: ({ value }) => value && value,
    },
  ];

  let alignHeaders = generateHeaders(headers, true);
  if (additionalHeaders && additionalHeaders.length > 0) {
    alignHeaders = alignHeaders.concat(additionalHeaders);
  }
  return alignHeaders;
}

// Role list and home page header

function jobRoleHeader(sortable = true, additionalHeaders = null) {
  var headers = [
    {
      field: "uid",
      headerName: "Role ID",
      flex: 0.9,
      valueGetter: ({ value }) => value,
    },
    {
      field: "name",
      headerName: "Role Name",
      flex: 1.6,
      valueGetter: ({ value }) => value,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      type: "date",
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value && moment(value).format("DD MMM 'YY"),
    },
    {
      field: "displayName",
      sortable: true,
      headerName: "Created By",
      valueGetter: (params) => params.row.displayName,
      flex: 1.5,
      renderCell: (params) => {
        return <div>{params.row.displayName}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div style={{ textTransform: "capitalize" }}>{params.row.status}</div>;
      },
    },
    {
      field: "invited",
      headerName: "Invited",
      type: "number",
      flex: 0.9,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value,
    },
    {
      field: "completed",
      headerName: "Completed",
      type: "number",
      // flex: 0.9,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value,
    },
    {
      field: "reachedTarget",
      headerName: "Reached",
      type: "number",
      flex: 0.9,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value,
    },
    {
      field: "targetScore",
      headerName: "Target Score",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value,
    },
  ];

  if (additionalHeaders && additionalHeaders.length > 0) {
    headers = headers.concat(additionalHeaders);
  }
  let alignHeaders = generateHeaders(headers, sortable);
  return alignHeaders;
}

function jobRoleHeaderHome(sortable = true, additionalHeaders = null) {
  var headers = [
    {
      field: "uid",
      headerName: "Role ID",
      flex: 0.9,
      valueGetter: ({ value }) => value,
    },
    {
      field: "name",
      headerName: "Role Name",
      flex: 1.6,
      valueGetter: ({ value }) => value,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      type: "date",
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value && moment(value).format("MMM DD"),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      valueGetter: (params) => params.row.createdBy.displayName,
      flex: 1.5,
      renderCell: (params) => {
        let createdby = params.row.createdBy;
        return <div>{createdby.displayName}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => {
        return <div style={{ textTransform: "capitalize" }}>{params.row.status}</div>;
      },
    },
    {
      field: "invited",
      headerName: "Invited",
      type: "number",
      flex: 0.9,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value,
    },
    {
      field: "completed",
      headerName: "Completed",
      type: "number",
      // flex: 0.9,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value,
    },
    {
      field: "reachedTarget",
      headerName: "Reached",
      type: "number",
      flex: 0.9,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value,
    },
    {
      field: "targetScore",
      headerName: "Target Score",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value,
    },
  ];

  if (additionalHeaders && additionalHeaders.length > 0) {
    headers = headers.concat(additionalHeaders);
  }
  let alignHeaders = generateHeaders(headers, sortable);
  return alignHeaders;
}

// Recruiter Header UI

function recruiterHeader(sortable = true) {
  let headers = [
    {
      field: "uid",
      headerName: "User ID",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.uid}</div>;
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.firstName} style={{ cursor: "pointer" }}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.firstName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.lastName} style={{ cursor: "pointer" }}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.lastName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.8,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.email} style={{ cursor: "pointer" }}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.email}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.status === "active"
              ? "Active"
              : params.row.status === "inactive"
              ? "Inactive"
              : params.row.status === "invited"
              ? "Invited"
              : ""}
          </div>
        );
      },
    },
    {
      field: "roleSubType",
      headerName: "User Type",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.roleSubType === "TeamAdmin" ? "Team Admin" : params.row.roleSubType}
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Added On",
      type: "date",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{moment(params.row.createdAt).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "createdByDisplayName",
      headerName: "Added By",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.createdByDisplayName} style={{ cursor: "pointer" }}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.createdByDisplayName}
            </span>
          </Tooltip>
        );
      },
    },
  ];
  let alignHeaders = generateHeaders(headers, sortable);
  return alignHeaders;
}
// eslint-disable-next-line
export default {
  jobRoleHeader,
  jobRoleHeaderHome,
  inviteCreateJobRoleHeader,
  inviteCreateJobRoleHeaderMobile,
  recruiterHeader,
  inviteHeader,
  inviteHeaderHome,
  candidateHeader,
  candidateHeaderHome,
  inviteCreateCandidateHeader,
  inviteCreateCandidateHeaderMobile,
};
