import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
  img: {
    maxWidth: "100%",
    maxHeight: "85vh",
    display: "block",
    margin: "0 auto",
  },
});

const ImageModal = ({ open, imageUrl, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="100%">
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <img src={imageUrl} alt={imageUrl} className={classes.img} />
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;
