module.exports.chartConfig = {
  modeBarButtonsToRemove: [
    "toImage",
    "select2d",
    "lasso2d",
    "resetScale2d",
    "zoom2d",
    "showspikes",
    "toggleSpikelines",
    "hoverClosestCartesian",
    "hoverCompareCartesian",
  ],
  fullScreenIcon: {
    width: 6000,
    height: 400,
    path: "M3532 4701 c-210 -73 -229 -356 -30 -455 l52 -26 295 0 296 0 -516 -517 c-466 -467 -518 -523 -533 -567 -20 -63 -20 -110 1 -171 46 -136 212 -202 342 -136 23 12 243 225 545 528 l505 508 1 -286 c0 -321 5 -357 65 -426 19 -23 54 -51 78 -62 56 -27 155 -28 212 -2 51 23 118 98 133 149 9 28 12 212 12 654 0 666 0 659 -55 732 -14 18 -49 46 -77 62 l-53 29 -610 2 c-572 3 -613 2 -663 -16z M179 4576 c-88 -31 -144 -89 -169 -172 -8 -28 -10 -215 -8 -654 3 -608 3 -615 25 -655 100 -186 364 -177 452 15 13 29 17 88 21 326 l5 291 500 -494 c275 -272 517 -506 537 -520 45 -30 145 -42 201 -24 142 47 212 215 144 347 -19 36 -158 181 -526 549 l-501 500 307 5 c293 5 310 6 349 27 196 105 169 395 -42 459 -66 20 -1240 20 -1295 0z M3342 2015 c-122 -62 -171 -210 -109 -330 19 -37 156 -180 526 -550 l501 -500 -307 -5 c-293 -5 -310 -6 -349 -27 -203 -109 -164 -415 59 -463 36 -8 238 -10 662 -8 l610 3 53 30 c56 30 100 83 120 144 16 45 17 1218 2 1273 -28 99 -134 178 -239 178 -96 -1 -181 -53 -223 -138 -22 -45 -23 -59 -28 -338 l-5 -291 -500 494 c-275 272 -517 506 -538 520 -30 21 -53 27 -110 30 -63 4 -78 1 -125 -22z M1720 1907 c-19 -7 -44 -18 -55 -24 -11 -6 -248 -240 -527 -520 l-508 -508 0 286 c0 321 -5 357 -65 426 -19 23 -54 51 -78 62 -56 27 -155 28 -212 2 -51 -23 -118 -98 -133 -149 -9 -28 -12 -212 -12 -654 0 -666 0 -659 55 -732 14 -18 49 -46 77 -62 l53 -29 604 -3 c677 -3 676 -3 752 65 135 122 108 327 -53 407 l-52 26 -295 0 -296 0 516 518 c466 466 518 522 533 566 20 63 20 110 -1 171 -41 120 -187 193 -303 152z",
    ascent: 2500,
  },
  exitFullScreenIcon: {
    width: 3000,
    height: 200,
    path: "M71 2542 c-37 -20 -71 -73 -71 -110 0 -13 6 -35 14 -50 8 -15 139 -151 290 -302 152 -151 276 -278 276 -282 0 -5 -111 -8 -246 -8 -273 0 -289 -3 -319 -66 -19 -40 -19 -75 -1 -119 28 -65 27 -65 505 -65 l431 0 32 29 33 29 3 436 c3 486 3 484 -63 512 -44 18 -79 18 -119 -1 -63 -30 -66 -46 -66 -319 0 -135 -3 -246 -8 -246 -4 0 -131 124 -282 276 -151 151 -287 282 -302 290 -36 19 -66 18 -107 -4z M1603 2545 c-63 -27 -63 -28 -63 -506 l0 -431 34 -34 34 -34 431 0 c480 0 479 0 507 65 18 44 18 79 -1 119 -30 63 -46 66 -319 66 -135 0 -246 3 -246 7 0 4 128 136 284 293 191 191 287 294 292 313 23 96 -88 188 -172 144 -16 -9 -153 -140 -304 -291 -151 -152 -278 -276 -282 -276 -5 0 -8 111 -8 246 0 214 -2 250 -17 275 -32 53 -105 73 -170 44z M54 1001 c-72 -44 -69 -170 5 -214 25 -15 61 -17 275 -17 135 0 246 -3 246 -8 0 -4 -124 -131 -276 -282 -151 -151 -282 -287 -290 -302 -18 -35 -18 -62 3 -105 23 -50 67 -75 122 -70 42 5 53 15 330 291 157 157 289 286 294 286 4 0 7 -111 7 -246 0 -273 3 -289 66 -319 40 -19 75 -19 119 -1 65 28 65 27 65 507 l0 431 -34 34 -34 34 -434 0 c-408 0 -435 -1 -464 -19z M1574 986 l-34 -34 0 -431 c0 -480 0 -479 65 -507 44 -18 79 -18 119 1 63 30 66 46 66 319 0 135 3 246 8 246 4 0 133 -126 287 -280 153 -153 292 -284 307 -291 40 -16 92 -3 127 32 35 35 48 87 32 127 -7 15 -138 154 -291 307 -154 154 -280 283 -280 287 0 5 111 8 246 8 273 0 289 3 319 66 19 40 19 75 1 119 -28 65 -27 65 -507 65 l-431 0 -34 -34z",
    ascent: 1600,
  },
  // customColors: [
  //   "#fd7f6f",
  //   "#7eb0d5",
  //   "#b2e061",
  //   "#bd7ebe",
  //   "#ffb55a",
  //   "#beb9db",
  //   "#fdcce5",
  //   "#8bd3c7",
  //   "#63bff0",
  //   "#a7d5ed",
  //   "#e1a692",
  //   "#30BACE",
  //   "#74dfa2",
  //   "#1491ab",
  //   "#70a092",
  //   "#8bb6f0",
  //   "#ea5a13",
  //   "#D4BCA8",
  //   "#ffa8B6",
  //   "#9e7cf6", // Purple
  //   "#2cd1b0", // Turquoise
  //   "#f08b8b", // Coral
  //   "#6ea4c3", // Steel Blue
  //   "#ffca3a", // Yellow
  //   "#c1a3e0", // Lavender
  //   "#e08dcf", // Orchid
  //   "#86ceb6", // Mint Green
  //   "#47a7d4", // Sky Blue
  //   "#b8d6f0", // Baby Blue
  //   "#f0c5a1", // Peach
  // ],

  customColors: [
    "#fd7f6f", // Coral
    "#7eb0d5", // Steel Blue
    "#b2e061", // Mint Green
    "#bd7ebe", // Orchid
    "#ffb55a", // Peach
    "#beb9db", // Lavender
    "#fdcce5", // Orchid
    "#8bd3c7", // Mint Green
    "#63bff0", // Sky Blue
    "#a7d5ed", // Baby Blue
    "#e1a692", // Peach
    "#30BACE", // Turquoise
    "#74dfa2", // Mint Green
    "#1491ab", // Steel Blue
    "#70a092", // Mint Green
    "#8bb6f0", // Baby Blue
    "#ea5a13", // Coral
    "#D4BCA8", // Tan
    "#ffa8B6", // Salmon
    "#9e7cf6", // Purple
    "#2cd1b0", // Turquoise
    "#f08b8b", // Coral
    "#6ea4c3", // Steel Blue
    "#ffca3a", // Yellow
    "#c1a3e0", // Lavender
    "#e08dcf", // Orchid
    "#86ceb6", // Mint Green
    "#47a7d4", // Sky Blue
    "#b8d6f0", // Baby Blue
    "#f0c5a1", // Peach
    "#7fb5b5", // Light Blue
    "#ffa07a", // Salmon
    "#6b8e23", // Olive Green
    "#af4035", // Rust
    "#4b0082", // Indigo
    "#8b4513", // Brown
    "#ff69b4", // Hot Pink
    "#4682b4", // Steel Blue
    "#00ced1", // Dark Turquoise
    "#9932cc", // Dark Orchid
    "#ff4500", // Orange Red
    "#dda0dd", // Plum
    "#3cb371", // Medium Sea Green
    "#b8860b", // Dark Goldenrod
    "#2e8b57", // Sea Green
    "#8a2be2", // Blue Violet
    "#da70d6", // Orchid
    "#87cefa", // Light Sky Blue
    "#ff8c00", // Dark Orange
    "#e6e6fa", // Lavender
  ],
  lineColors: ["#bd7ebe", "#74dfa2", "#70a092", "#ea5a13"],
  QColors: ["#af4035", "#1491ab", "#2e8b57", "#8a2be2"],
  font: {
    family: "Roboto",
    // color: "#787878",
    color: "black",
    size: 10,
    face: "Bold",
  },
  MessageWhenNoData: "Not enough data to render the chart.",
  MessageWhenNoDataTreemap: "Not enough data to render the chart. Add some.",
  // MessageWhenNoData: "No data to show here. Add some..",
};
