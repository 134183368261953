import React from "react";
import { Dialog } from "@mui/material";
import { theme } from "../styles/theme";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  dialogWrapper: {
    minWidth: "600px",
    position: "relative",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  close: {
    color: theme.palette.common.black,
  },
});

export default function Popup(props) {
  // eslint-disable-next-line
  const { title, children, openPopup, setOpenPopup } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      maxWidth="md"
      classes={{ paper: classes.dialogWrapper }}
      onClose={() => {
        setOpenPopup(false);
      }}
      aria-labelledby="customized-dialog-title"
    >
      {children}
    </Dialog>
  );
}
