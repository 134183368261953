import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import auth from "../../redux/actions/auth";
import { getDomainDetails } from "../../services/globalService";
import { theme } from "../../styles/theme";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import Button from "../../components/controls/Button";
import Footer from "../../components/Footer";

const useStyles = makeStyles({
  centerContainer: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "center",
    paddingTop: "10px",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  submit: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

const AcceptInvitation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const token = useParams();
  const [loading, setLoading] = useState(true);
  const [domainDetails, setDomainDetails] = useState({});
  const logoUrl = process.env.REACT_APP_ASSETS_URL;
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [displayForm, setDisplayForm] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(auth.checkInvitationStatus(token.token));
    }
  }, []);

  useEffect(() => {
    if (authState.checkInvitationData?.redirect) {
      if (authState.checkInvitationData?.link && authState.checkInvitationData?.link === "/pageNotFound") {
        setPageNotFound(true);
      } else {
        navigate(authState.checkInvitationData?.link);
      }
    }

    if (authState.checkInvitationData?.redirect === false) {
      setLoading(false);
      setDisplayForm(true);
    }
  }, [authState.checkInvitationData]);

  useEffect(() => {
    if (authState.acceptInvitationSuccess?.redirect) {
      navigate(authState.acceptInvitationSuccess?.link);
    }
  }, [authState.acceptInvitationSuccess]);

  const onSubmit = () => {
    dispatch(auth.AcceptInvitation(token.token));
  };

  // Extract subdomain info
  useEffect(() => {
    let domainName = getDomainDetails(window.location.href);
    dispatch(auth.GetLogo({ subdomain: domainName.subdomainFromUrl }));
    setDomainDetails(authState.logoDetails);
  }, []);

  useEffect(() => {
    setDomainDetails(authState.logoDetails);
  }, [authState.logoDetails]);

  useEffect(() => {
    if (token) {
      dispatch(auth.AcceptInvitation(token.token));
    }
  }, []);

  return (
    <>
      <Grid>
        <div className={`${classes.center} ${classes.logocls}`}>
          {domainDetails && domainDetails?.logo !== "" ? (
            <>
              <img height="50" width="157" src={`${logoUrl}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
              <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
            </>
          ) : (
            <>
              <img height="50" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
            </>
          )}
        </div>
        <Grid container display={"flex"} justifyContent={"center"} minHeight="80vh">
          {displayForm && (
            <Grid item xs={isNotMobile ? 4 : 10} style={{ marginTop: "100px" }}>
              <div>
                {loading ? (
                  <div>Please wait ...</div>
                ) : (
                  <div>
                    <Typography variant="h2" className={classes.roletype}>
                      You are invited by {authState.checkInvitationData?.createdBy} to{" "}
                      {authState.checkInvitationData?.orgName} TechScore team. Accept the invitation to continue.
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button color={"btnPrimary"} text="Accept Invitation" onClick={() => onSubmit()}></Button>
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          )}
          {pageNotFound && (
            <div>
              <h1>404 Page Not Found</h1>
            </div>
          )}
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default AcceptInvitation;
