import React, { useLayoutEffect } from "react";
import { makeStyles } from "@mui/styles";
import AppConfig from "../../constants/AppConfig";
import { useSelector } from "react-redux";
import { theme } from "../../styles/theme";

const useStyles = makeStyles({
  baseTimer: {
    position: "relative",
  },
  svgBox: {
    transform: "scaleX(-1)",
  },
  circle: {
    fill: "none",
    stroke: "none",
  },
  elapsedPath: {
    stroke: "#EBEFF8",
  },
  remainingPath: {
    strokeLinecap: "round",
    transform: "rotate(90deg)",
    transformOrigin: "center",
    transition: "1s linear all",
    fillRule: "nonzero",
    stroke: "currentColor",
    "&.green": {
      color: "#28BB3F",
    },
    "&.orange": {
      color: "#FF941C",
    },
    "&.red": {
      color: "#DD2E2E",
    },
  },
  label: {
    position: "absolute",
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "18px",
    },
    "&.green": {
      color: "#28BB3F",
      fontSize: "12px", // 12
      [theme.breakpoints.up("tablet")]: {
        fontSize: "14px",
      },
      fontWeight: "700",
      lineHeight: "14px",
    },
    "&.orange": {
      color: "#FF941C",
      fontSize: "12px",
      [theme.breakpoints.up("tablet")]: {
        fontSize: "14px",
      },
      fontWeight: "700",
      lineHeight: "14px",
    },
    "&.red": {
      color: "#DD2E2E",
      fontSize: "12px",
      fontWeight: "500",
      [theme.breakpoints.up("tablet")]: {
        fontSize: "14px",
      },
      lineHeight: "24px",
    },
    fontFamily: theme.typography.h6.fontFamily,
    fontStyle: theme.typography.h6.fontStyle,
    letterSpacing: "0em",
    textAlign: theme.typography.h6.textAlign,
  },
});

var timerInterval = [];
var timeoutHandler = [];

function CircleCountdownTimer(props) {
  const {
    duration,
    delayedStartDuration,
    elapsedTime,
    thresholds,
    onThresholdStart,
    onTimeout,
    onElapsedTime,
    size,
    strokeWidth,
  } = props;

  const classes = useStyles();

  const DEFULT_SIZE = size || 100;
  const FULL_DASH_ARRAY = 2 * Math.PI * (DEFULT_SIZE / 2 - strokeWidth / 2);
  const WARNING_THRESHOLD = 10;
  const ALERT_THRESHOLD = 5;
  const quizState = useSelector((state) => state.quiz);

  const COLOR_CODES = {
    info: {
      color: "green",
    },
    warning: {
      color: "orange",
      threshold: thresholds?.warning || WARNING_THRESHOLD,
    },
    alert: {
      color: "red",
      threshold: thresholds?.alert || ALERT_THRESHOLD,
    },
  };

  const DELAYED_START_DURATION = delayedStartDuration || 0;
  const TIME_LIMIT = duration - (elapsedTime || 0);
  let timePassed = 0;
  let timeLeft = TIME_LIMIT;
  let remainingPathColor = COLOR_CODES.info.color;
  let labelColor = COLOR_CODES.info.color;

  let timeWhenInvisible = 0;

  const initialStartPos = (calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0);

  useLayoutEffect(() => {
    startTimer();
    if (quizState?.level == AppConfig.LEVEL1 && quizState?.prevCategory == "Presentation") {
      timerFn();
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      // eslint-disable-next-line
      timerInterval.map((a) => {
        clearInterval(a);
        timerInterval = [];
      });
      // eslint-disable-next-line
      timeoutHandler.map((a) => {
        clearTimeout(a);
        timeoutHandler = [];
      });
    };
  }, []);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      timeWhenInvisible = new Date().getTime();
      // console.log('CTIMER: Timer lost focus at: ', timeLeft, new Date(), timeWhenInvisible, timerInterval);
      // eslint-disable-next-line
      timerInterval.map((a) => {
        clearInterval(a);
        timerInterval = [];
      });
      // eslint-disable-next-line
      timeoutHandler.map((a) => {
        clearTimeout(a);
        timeoutHandler = [];
      });
    } else {
      var visibleAt = new Date().getTime();
      // console.log('CTIMER: Timer got focus at: ', timePassed, visibleAt, timeWhenInvisible, ((visibleAt - timeWhenInvisible) / 1000).toFixed(0));
      timePassed = timePassed * 1 + ((visibleAt - timeWhenInvisible) / 1000).toFixed(0) * 1;
      if (timePassed > 0) {
        // eslint-disable-next-line
        timerInterval.map((a) => {
          clearInterval(a);
          timerInterval = [];
        });
        // eslint-disable-next-line
        timeoutHandler.map((a) => {
          clearTimeout(a);
          timeoutHandler = [];
        });

        let interval = setInterval(timerFn, 1000);
        timerInterval.push(interval);
      } else {
        onTimesUp();
      }
    }
  };

  function onTimesUp() {
    // eslint-disable-next-line
    timerInterval.map((a) => {
      clearInterval(a);
      timerInterval = [];
    });
    // eslint-disable-next-line
    timeoutHandler.map((a) => {
      clearTimeout(a);
      timeoutHandler = [];
    });

    onTimeout();
  }

  function timerFn() {
    //if offline, do nothing
    if (!navigator.onLine) {
      return;
    }

    timePassed = timePassed * 1;
    timePassed = timePassed += 1;
    timeLeft = TIME_LIMIT - timePassed;

    if (timeLeft < 0) {
      timeLeft = 0;
    }

    if (document.getElementsByClassName("base-timer-label") && document.getElementsByClassName("base-timer-label")[0]) {
      document.getElementsByClassName("base-timer-label")[0].innerHTML = formatTime(timeLeft);
    }
    setCircleDasharray();
    setRemainingPathColor(timeLeft);

    if (timeLeft === 0) {
      onTimesUp();
    }

    if (onElapsedTime) {
      onElapsedTime(timePassed, timeLeft);
    }
  }

  function startTimer() {
    try {
      if (DELAYED_START_DURATION && DELAYED_START_DURATION > 0) {
        let timeout = setTimeout(() => {
          let interval = setInterval(timerFn, 1000);
          timerInterval.push(interval);
        }, DELAYED_START_DURATION * 1000);
        timeoutHandler.push(timeout);
      } else {
        let interval = setInterval(timerFn, 1000);
        timerInterval.push(interval);
      }
      // console.log('CTIMER: started at: ', timerInterval, new Date());
    } catch (err) {}
  }

  function formatTime(time) {
    let minutes = Math.floor((time % 3600) / 60);
    let seconds = time % 60;
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return `${minutes}:${seconds}`;
  }

  function setRemainingPathColor(timeLeft) {
    try {
      const { alert, warning, info } = COLOR_CODES;

      if (timeLeft == warning.threshold) {
        onThresholdStart("warning", timeLeft);
      }
      if (timeLeft == alert.threshold) {
        onThresholdStart("alert", timeLeft);
      }

      if (timeLeft <= alert.threshold) {
        document.getElementsByClassName("base-timer-path-remaining")[0].classList.remove(warning.color);
        document.getElementsByClassName("base-timer-path-remaining")[0].classList.add(alert.color);

        document.getElementsByClassName("base-timer-label")[0].classList.remove(warning.color);
        document.getElementsByClassName("base-timer-label")[0].classList.add(alert.color);
      } else if (timeLeft <= warning.threshold) {
        document.getElementsByClassName("base-timer-path-remaining")[0].classList.remove(info.color);
        document.getElementsByClassName("base-timer-path-remaining")[0].classList.add(warning.color);

        document.getElementsByClassName("base-timer-label")[0].classList.remove(info.color);
        document.getElementsByClassName("base-timer-label")[0].classList.add(warning.color);
      }
    } catch (err) {}
  }

  function calculateTimeFraction() {
    const rawTimeFraction = timeLeft / duration;
    return rawTimeFraction - (1 / duration) * (1 - rawTimeFraction);
  }

  function setCircleDasharray() {
    try {
      let val = (calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0);
      const circleDasharray = `${val} ${FULL_DASH_ARRAY}`;
      document.getElementsByClassName("base-timer-path-remaining")[0].setAttribute("stroke-dasharray", circleDasharray);
    } catch (err) {}
  }

  function getVBDimension() {
    return `0 0 ${DEFULT_SIZE} ${DEFULT_SIZE}`;
  }

  function getCtStyle() {
    return {
      width: DEFULT_SIZE,
      height: DEFULT_SIZE,
    };
  }

  function getTextStyle() {
    return {
      width: DEFULT_SIZE,
      height: DEFULT_SIZE,
    };
  }

  return (
    <div className={classes.baseTimer} style={getCtStyle()}>
      <svg className={classes.svgBox} viewBox={getVBDimension()} xmlns="http://www.w3.org/2000/svg">
        <g className={classes.circle}>
          <circle
            className={classes.elapsedPath} //"base-timer__path-elapsed"
            cx={DEFULT_SIZE / 2}
            cy={DEFULT_SIZE / 2}
            r={DEFULT_SIZE / 2 - strokeWidth / 2}
            style={{
              strokeWidth: strokeWidth,
            }}
          ></circle>
          <path
            strokeDasharray={`${initialStartPos} ${FULL_DASH_ARRAY}`}
            className={`base-timer-path-remaining ${classes.remainingPath} ${remainingPathColor}`}
            d={`
                            M ${DEFULT_SIZE / 2}, ${DEFULT_SIZE / 2}
                            m -${DEFULT_SIZE / 2 - strokeWidth / 2}, 0
                            a ${DEFULT_SIZE / 2 - strokeWidth / 2},${DEFULT_SIZE / 2 - strokeWidth / 2} 0 1,0 ${
              DEFULT_SIZE - strokeWidth
            },0
                            a ${DEFULT_SIZE / 2 - strokeWidth / 2},${DEFULT_SIZE / 2 - strokeWidth / 2} 0 1,0 -${
              DEFULT_SIZE - strokeWidth
            },0
                            `}
            style={{
              strokeWidth: strokeWidth,
            }}
          ></path>
        </g>
      </svg>
      <span className={`base-timer-label ${classes.label} ${labelColor}`} style={getTextStyle()}>
        {formatTime(timeLeft)}
      </span>
    </div>
  );
}
export default CircleCountdownTimer;
