import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import NavBar from "../../components/NavBar";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import OrganisationContent from "./organisation/OrganisationContent";
import BillingContent from "./billing/BillingContent";
// import SubscriptionContent from "./SubscriptionContent";
import ProfileContent from "./profile/ProfileContent";
import { useDispatch } from "react-redux";
import auth from "../../redux/actions/auth";
import ChartContent from "./analytics/ChartContent";
import { Grid } from "@mui/material";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";
import SupportContent from "./support/SupportContent";

const drawerWidth = "21%";

const useStyles = makeStyles({
  selectedItem: {
    fontWeight: "bold !important",
  },
});

export default function AccountSettings() {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = useStyles();
  const [selectedSection, setSelectedSection] = React.useState("profile");
  // const authState = useSelector((state) => state.auth);

  // Function to parse the URL and set the selected section
  const setSelectedSectionFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const section = searchParams.get("section");
    const paymentId = searchParams.has("paymentId");

    // Check if paymentId is present, set to "billing"
    if (paymentId) {
      setSelectedSection("billing");
    } else if (section === "billing" || section === "organisation" || section === "profile") {
      // Check if section is valid and update the state
      setSelectedSection(section);
    }
  };

  React.useEffect(() => {
    setSelectedSectionFromURL();
  }, [location.search]);

  const handleListItemClick = (section) => {
    setSelectedSection(section);
  };

  // Handle state sent from other pages
  React.useEffect(() => {
    const state = location.state;
    if (state && state.selectedSection) {
      setSelectedSection(state.selectedSection);
    }
  }, [location.state]);

  const drawer = (
    <div style={{ margin: "0px auto" }}>
      <div>
        <Grid sx={{ m: 2 }}>
          <CustomBreadcrumbs links={[{ name: "Home", path: "/home" }, { name: "Manage Account" }]} />
          <Typography sx={{ mt: 2 }} variant="h5">
            Manage Account
          </Typography>
        </Grid>
      </div>
      <List>
        <ListItem sx={{ paddingLeft: "0px" }} key={"profile"}>
          <ListItemButton onClick={() => handleListItemClick("profile")}>
            <Typography className={selectedSection === "profile" ? classes.selectedItem : ""}>Profile</Typography>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ paddingLeft: "0px" }} key={"organisation"}>
          <ListItemButton onClick={() => handleListItemClick("organisation")}>
            <Typography className={selectedSection === "organisation" ? classes.selectedItem : ""}>
              Organization
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ paddingLeft: "0px" }} key={"billing"}>
          <ListItemButton onClick={() => handleListItemClick("billing")}>
            <Typography className={selectedSection === "billing" ? classes.selectedItem : ""}>Billing</Typography>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ paddingLeft: "0px" }} key={"analytics"}>
          <ListItemButton onClick={() => handleListItemClick("analytics")}>
            <Typography className={selectedSection === "analytics" ? classes.selectedItem : ""}>Analytics</Typography>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ paddingLeft: "0px" }} key={"support"}>
          <ListItemButton onClick={() => handleListItemClick("support")}>
            <Typography className={selectedSection === "support" ? classes.selectedItem : ""}>Support</Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  let selectedComponent;
  switch (selectedSection) {
    case "profile":
      selectedComponent = <ProfileContent />;
      break;
    case "organisation":
      selectedComponent = <OrganisationContent />;
      break;
    case "billing":
      selectedComponent = <BillingContent />;
      break;
    case "analytics":
      selectedComponent = <ChartContent />;
      break;
    case "support":
      selectedComponent = <SupportContent />;
      break;
    default:
      selectedComponent = <div>No section selected</div>;
  }

  React.useEffect(() => {
    dispatch(auth.managementDetails());
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            marginTop: "16px",
            marginLeft: "auto",
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {selectedComponent}
      </Box>
    </Box>
  );
}
