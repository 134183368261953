import React from "react";
import { withStyles } from "@mui/styles";
import { LinearProgress } from "@mui/material";

//style component taken in material ui
function ColoredLinearProgress(props) {
  const { classes } = props;
  return (
    <LinearProgress
      {...props}
      classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
    />
  );
}

const styles = (props) => ({
  colorPrimary: {
    backgroundColor: "rgb(23, 137, 252)",
  },
  barColorPrimary: {
    backgroundColor: "#c7e5f3",
  },
});

export default withStyles(styles)(ColoredLinearProgress);
