import React, { useEffect, useState } from "react";
import { Typography, Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextInput from "../../components/controls/TextField";
import { getDomainDetails } from "../../services/globalService";
import { theme } from "../../styles/theme";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../components/controls/Button";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../redux/actions/auth";

const useStyles = makeStyles({
  centerContainer: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "10px",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  textviewLink: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    // paddingTop: '20px',
    paddingBottom: "20px",
  },
  submit: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

const ForgotPassword = () => {
  const classes = useStyles();
  const authStore = useSelector((state) => state.auth);
  const { forgetPasswordSuccess, forgetPasswordFailure } = useSelector((state) => state.auth);
  const [domainDetails, setDomainDetails] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const logoUrls = process.env.REACT_APP_ASSETS_URL;
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const dispatch = useDispatch();

  useEffect(() => {
    let domainName = getDomainDetails(window.location.href);
    dispatch(auth.GetLogo({ subdomain: domainName.subdomainFromUrl }));
    setDomainDetails(authStore.logoDetails);
  }, []);

  useEffect(() => {
    setDomainDetails(authStore.logoDetails);
  }, [authStore.logoDetails]);

  // handle password success
  useEffect(() => {
    if (forgetPasswordSuccess && forgetPasswordSuccess?.status === 0) {
      setShowSuccessMessage(true);
    }
  }, [forgetPasswordSuccess]);

  // handle password failure
  useEffect(() => {
    if (forgetPasswordFailure && forgetPasswordFailure?.status !== 0) {
      setShowSuccessMessage(false);
    }
  }, [forgetPasswordFailure]);

  // Validtion for login
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .typeError("Please enter valid email")
      .required("This field cannot be empty"),
  });

  const submitForgotPassword = (values) => {
    const { email } = values;
    let domainDetails = getDomainDetails(window.location.href);
    let payload = {
      email,
      domainName: domainDetails?.subdomainFromUrl,
    };

    dispatch(auth.ForgetPassword(payload));
  };

  const initialEmailValues = {
    email: localStorage.getItem("lastUsedEmail") ? localStorage.getItem("lastUsedEmail") : "",
  };

  return (
    <Grid>
      <div className={`${classes.center} ${classes.logocls}`}>
        {domainDetails && domainDetails?.logo !== "" ? (
          <>
            <img height="33" width="157" src={`${logoUrls}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
            <img height="17" width="157" src={`${logoUrls}powered.svg`} alt="powered" />
          </>
        ) : (
          <>
            <img height="50" width="157" src={`${logoUrls}techscore.svg`} alt="TechScore" />
          </>
        )}
      </div>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={isNotMobile ? 4 : 10} style={{ marginTop: "40px" }}>
          <Typography align="center" className={classes.textsty}>
            Reset Credentials
          </Typography>
          {showSuccessMessage ? (
            <div className={classes.centerContainer}>
              <Typography className={classes.textview} style={{ textAlign: "center" }}>
                Password reset link has been sent to the requested email id. Please check your inbox and follow the
                instructions.
                {/* Click <a href='/login'>here</a> to go back to the <a href='/login'>Login</a> page. */}
                <p style={{ marginTop: "40px" }}>
                  Click <a href="/login">here</a> to go to the <a href="/login">Login</a> page.
                </p>
              </Typography>
            </div>
          ) : (
            <Formik
              initialValues={initialEmailValues}
              validateOnChange
              validateOnBlur
              validationSchema={validationSchema}
              onSubmit={submitForgotPassword}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                <Form>
                  <Typography className={`${classes.roletype}`}>Email</Typography>
                  <TextInput
                    name={"email"}
                    placeholder="Enter Your Registered Email ID"
                    className={classes.field}
                    id={"email"}
                    onChange={(e) => {
                      handleChange(e);
                      const email = e.target.value;
                      // Regular expression for email validation
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      // Check if the entered email matches the email format
                      if (email.match(emailRegex)) {
                        localStorage.setItem("lastUsedEmail", email);
                      } else {
                        localStorage.removeItem("lastUsedEmail");
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                  ></TextInput>
                  {touched.email && errors.email && (
                    <div className="error">
                      <Typography className={classes.errorty}>{errors.email}</Typography>
                    </div>
                  )}
                  <Grid className={classes.submit}>
                    <Button
                      disabled={!isValid || !values.email}
                      type="submit"
                      color={!isValid || !values.email ? "disablebtn" : "btnPrimary"}
                      width={"100%"}
                      text="Request"
                      onClick={handleSubmit}
                    ></Button>
                  </Grid>
                  <Typography className={classes.textviewLink}>
                    Go back to <a href="/login">Login</a> page.
                  </Typography>
                </Form>
              )}
            </Formik>
          )}
          <Grid
            container
            className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
            direction="row"
            justifyContent="center"
            alignItems="center"
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
