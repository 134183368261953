import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "../../../components/controls/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useLocation, useNavigate, generatePath } from "react-router-dom";
import quiz from "../../../redux/actions/quiz";
import { useSelector, useDispatch } from "react-redux";
import auth from "../../../redux/actions/auth";
import AppConfig from "../../../constants/AppConfig";
import randomString from "random-string";
import { Text, theme } from "../../../styles/theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as globalService from "../../../services/globalService";
import Grid from "@mui/material/Grid";
import { ImagebaseUrl } from "../../../services/globalService";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import BayoneLogo from "../../../assets/svg/bayonelogo.svg";
import { UAParser } from "ua-parser-js";
import * as faceapi from "face-api.js";
import PermissionsDialog from "./PermissionsDialog";
import { checkCameraAndMicrophonePermission, checkGeolocationPermission } from "../../../services/globalService";
import role from "../../../redux/actions/role";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: theme.palette.primary.light,
    minHeight: "100vh",
    // [theme.breakpoints.up("tablet")]: {},
  },
  section: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  section1: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardOne: {
    height: "60px",
    zIndex: 1,
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  cardTwo: {
    marginTop: "64px",
    marginBottom: "40px",
    justifyContent: "center",
  },
  cardThree: {
    marginBottom: 24,
  },
  cardFour: {
    marginBottom: 40,
  },
  cardFive: {
    "&>button": {
      marginLeft: 16,
      marginRight: 16,
      [theme.breakpoints.up("tablet")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  cardSix: {
    marginTop: "24px",
    paddingBottom: "20px",
  },
  cardTwoH1: {
    lineHeight: "28px",
    [theme.breakpoints.up("tablet")]: {},
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      whiteSpace: "pre-wrap",
    },
  },
  cardtext: {
    [theme.breakpoints.up("tablet")]: {
      fontSize: "23px",
    },
  },
  openingScreenDescription: {
    fontSize: "14px",
    [theme.breakpoints.up("tablet")]: {
      fontSize: "16px",
    },
  },
  openingScreen: {
    maxWidth: 210,
    [theme.breakpoints.up("tablet")]: {
      maxWidth: 308,
    },
  },
  fontProfile: {
    [theme.breakpoints.up("tablet")]: {
      fontSize: "15.9px",
    },
  },
  minText: {
    //fontWeight: '500',
    marginBottom: "5px",
  },
  linkUnderline: {
    textDecoration: "none !important",
  },
  linktext: theme.typography.link2,
  b1Medium: theme.typography.b1Medium,
  b1Regular: theme.typography.b1Regular,
  h3sty: theme.typography.h3,

  loader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function OpeningScreen(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  let questionCode = "QuestionCode";
  let level = "";
  let levelInfo = "";
  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const quizState = useSelector((state) => state.quiz);
  const sessionByLevel = useSelector((state) => state.quiz.sessionByLevel);

  // const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const history = useNavigate();
  const classes = useStyles();
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  // const proctoringDetails = JSON.parse(localStorage.getItem("proctoring"));
  const proctoringDetails = useSelector((state) => state?.role?.candidateDashboardDetails?.proctoring);
  const uid = localStorage.getItem("uid") || roleState?.invitedetails?.uid;
  const [openDialog, setOpenDialog] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingFace, setLoadingFace] = useState(true);
  const [loadingVoice, setLoadingVoice] = useState(true);
  const [faceDetected, setFaceDetected] = useState(false);
  const [voiceDetected, setVoiceDetected] = useState(false);
  const [cameraPermission, setCameraPermission] = useState(false);
  const [browserDetails, setBrowserDetails] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [geoLocation, setGeolocation] = useState(false);
  const [faceCount, setFaceCount] = useState(0); // we are not using this
  // eslint-disable-next-line
  const [logoUrl, setLogoUrl] = React.useState(process.env.REACT_APP_ASSETS_URL);
  const [domainDetails, setDomainDetails] = useState({});

  useEffect(() => {
    dispatch(quiz.resetQuizSummery());
    dispatch(quiz.resetQuizQuestions());
    dispatch(quiz.setSelectedQuesNdAns([]));
    globalService.removeAnswers();
    dispatch(quiz.resetElapsedTime(0));
    sessionStorage.removeItem("remainigTime");
    dispatch(quiz.resetTestResult());
    localStorage.removeItem("currentTimeOutDuration");
    if (!proctoringDetails && userDetails?.role === "Candidate") {
      dispatch(role.candidateDashboardDetails(uid));
    }
  }, []);

  useEffect(() => {
    if (
      location &&
      location.pathname !== "/L3ProfileOpeningScreen" &&
      location.pathname !== "/L3VideoBehavioralOpeningScreen"
    ) {
      const loadModels = async () => {
        await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      };

      loadModels();
    }
  }, []);

  useEffect(() => {
    if (
      location &&
      location?.pathname === "/OpeningScreen" &&
      roleState?.candidateDashboardDetails &&
      roleState?.candidateDashboardDetails?.candidate?.user?.email &&
      quizState?.isFromEditProfile === true
    ) {
      dispatch(auth.userMetaDataReq({ email: roleState?.candidateDashboardDetails?.candidate?.user?.email }));
    }
    // eslint-disable-next-line
  }, [location, roleState?.candidateDashboardDetails]);

  useEffect(() => {
    if (
      roleState?.candidateDashboardDetails?.candidate?.user?.email &&
      authState?.userMetaData &&
      authState?.userMetaData[0]?.result &&
      authState?.userMetaData[0]?.result[0] &&
      quizState?.isFromEditProfile === true
    ) {
      dispatch(quiz.setSelectedQuesNdAns(JSON.parse(authState?.userMetaData[0]?.result[0])));
    }
    // eslint-disable-next-line
  }, [authState?.userMetaData, roleState?.candidateDashboardDetails]);

  useEffect(() => {
    if (location && sessionByLevel !== "" && sessionByLevel) {
      if (location.pathname === "/OpeningScreen") {
        dispatch(quiz.getL0Questions());
      }
      if (location.pathname === "/L1OpeningScreen") {
        dispatch(quiz.getL1Questions(roleState?.candidateDashboardDetails?.uid));
      }
      if (location.pathname === "/L2OpeningScreen") {
        // let payload = {
        //   // FK cleanup kuly 2023
        //   id: userDetails.userId,
        //   section: {
        //     section: "Technical Section",
        //   },
        // };
        dispatch(quiz.getL2Questions(roleState?.candidateDashboardDetails?.uid));
      }
      if (location.pathname === "/L3PresentationOpeningScreen") {
        // let payload = {
        //   // FK cleanup july 2023
        //   id: userDetails.userId,
        //   section: {
        //     section: "Presentation Section",
        //   },
        // };
        dispatch(quiz.getL3PresentationQuestions(roleState?.candidateDashboardDetails?.uid));
      }
      if (location.pathname === "/L3ProfileOpeningScreen" || location.pathname === "/L3CodingOpeningScreen") {
        // let sectionValue = location.pathname === "/L3ProfileOpeningScreen" ? "Profile Section" : "Coding Challenge";
        // let payload = {
        //   // FK cleanup july 2023
        //   id: userDetails.userId,
        //   section: {
        //     section: sectionValue,
        //   },
        // };
        dispatch(quiz.getL3Questions(roleState?.candidateDashboardDetails?.uid));
      }
      if (location.pathname === "/L3VideoBehavioralOpeningScreen") {
        const currentUrl = window.location.href;
        const urlSegments = currentUrl.split("/");
        urlSegments.pop();
        // making url for returning to dashboard
        urlSegments.push("candidatedashboard");
        const newUrl = urlSegments.join("/");
        const baseUrl = quizState?.behavioralLink;
        const urlWithParams = `${baseUrl}?rp=${newUrl}&gl=${proctoringDetails?.geoLocation}`;

        window.open(urlWithParams, "_self");
      }
    }
    // eslint-disable-next-line
  }, [sessionByLevel]);

  useEffect(() => {
    if (location && location.pathname === "/L3VideoBehavioralOpeningScreen") {
      setLoading(false);
      setLoadingFace(false);
      setLoadingVoice(false);
    }
  }, [location]);

  useEffect(() => {
    if (quizState.questions && quizState.questions.length > 0) {
      if (location && location.pathname === "/OpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL0));
        history("/QuizQuestions/" + AppConfig.LEVEL0 + "/1");
      }
      if (location && location.pathname === "/L1OpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL1));
        history("/QuizQuestions/" + AppConfig.LEVEL1 + "/1");
      }
      if (location && location.pathname === "/L2OpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL2));
        history("/QuizQuestions/" + AppConfig.LEVEL2 + "/1");
      }
      if (location && location.pathname === "/L3PresentationOpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL3_PRESENTATION));
        history("/QuizQuestions/" + AppConfig.LEVEL3 + "/1");
      }
      if (location && location.pathname === "/L3ProfileOpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL3_PROFILE));
        history("/ProfileQuestions/");
      }
      if (location && location.pathname === "/L3CodingOpeningScreen") {
        dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL3_CODING));
        history("/CodingQuestions/1");
      }
    }
    // eslint-disable-next-line
  }, [quizState.questions]);

  // useEffect(() => {
  if (location && location.pathname === "/OpeningScreen") {
    questionCode = "l0QuestionCode";
    level = "0";
    levelInfo = AppConfig.LEVEL0;
  }
  if (location && location.pathname === "/L1OpeningScreen") {
    questionCode = "l1QuestionCode";
    level = "1";
    levelInfo = AppConfig.LEVEL1 + " " + quizState.skills;
  }
  if (location && location.pathname === "/L2OpeningScreen") {
    questionCode = "l2QuestionCode";
    level = "2";
    // levelInfo = AppConfig.LEVEL2 + " " + quizState.skills;
    levelInfo = AppConfig.LEVEL2;
  }
  if (location && location.pathname === "/L3PresentationOpeningScreen") {
    questionCode = "l3QuestionCode";
    level = "3";
    levelInfo = "presentation";
  }
  if (location && location.pathname === "/L3VideoBehavioralOpeningScreen") {
    // questionCode = "l3QuestionCode";
    level = "3";
    levelInfo = "presentation";
  }
  if (location && location.pathname === "/L3ProfileOpeningScreen") {
    questionCode = "l3QuestionCode";
    level = "3";
    levelInfo = "profile";
  }
  if (location && location.pathname === "/L3CodingOpeningScreen") {
    questionCode = "l3QuestionCode";
    level = "3";
    levelInfo = "coding";
  }

  const handleClose = (event, reason) => {
    setOpenSnackBar(false);
  };

  const onIamReadyClick = async () => {
    setLoading(true);
    let cameraAndMicrophonePermissionStatus = {
      camera: false,
      mic: false,
    };
    let geolocationPermissionStatus = {
      permission: false,
      latitude: null,
      longitude: null,
    };

    console.log(cameraAndMicrophonePermissionStatus?.camera, "cameraPermissionStatus");
    console.log(cameraAndMicrophonePermissionStatus?.mic, "microphonePermissionStatus");
    console.log(geolocationPermissionStatus, "geolocationPermissionStatus");
    console.log(cameraAndMicrophonePermissionStatus, "cameraAndMicrophonePermissionStatus");

    // Request full-screen mode for the document
    if (
      location &&
      location.pathname !== "/L3ProfileOpeningScreen" &&
      location.pathname !== "/L3VideoBehavioralOpeningScreen"
    ) {
      // Check individual permissions
      if (proctoringDetails?.webcam !== "notRequired") {
        cameraAndMicrophonePermissionStatus = await checkCameraAndMicrophonePermission(false);
      }
      if (proctoringDetails?.geoLocation !== "notRequired") {
        geolocationPermissionStatus = await checkGeolocationPermission();
      }

      const documentElement = document.documentElement;
      if (documentElement.requestFullscreen) {
        documentElement.requestFullscreen();
      } else if (documentElement.mozRequestFullScreen) {
        documentElement.mozRequestFullScreen();
      } else if (documentElement.webkitRequestFullscreen) {
        documentElement.webkitRequestFullscreen();
      } else if (documentElement.msRequestFullscreen) {
        documentElement.msRequestFullscreen();
      }
    }

    let UID;
    if (roleState?.candidateDashboardDetails && roleState?.candidateDashboardDetails?.candidate?.user?.email) {
      UID = roleState?.candidateDashboardDetails?.candidate?.user?.email;
    } else if (roleState?.candidateDashboardDetails && roleState?.candidateDashboardDetails?.candidate?.user?.email) {
      UID = roleState?.candidateDashboardDetails?.candidate?.user?.email;
    } else {
      UID = "notregistered";
    }

    let code = randomString();
    AppConfig[questionCode] = code;
    AppConfig.code = code;
    let body = {
      level,
      code,
      uid: UID,
      email: roleState?.candidateDashboardDetails
        ? roleState?.candidateDashboardDetails?.candidate?.user?.email
        : "notregistered",
      levelInfo,
      inviteId: roleState?.candidateDashboardDetails?.uid || roleState?.invitedetails?.uid,

      deviceMetadata:
        proctoringDetails?.deviceTracking === false
          ? {
              ipMetaData: "",
              ipAddress: "",
              deviceName: "",
              browser: "",
              browserVersion: "",
              platform: "",
              userAgent: "",
              isMobile: "",
              isScreenExtended: "",
              fullScreenMode: "",
              geoLocation: {
                latitude: geolocationPermissionStatus?.latitude || "",
                longitude: geolocationPermissionStatus?.longitude || "",
              },
              geoLocationPermission: geolocationPermissionStatus?.permission || "",
              cameraPermission: "",
              micPermission: "",
              viewHeight: "",
              viewWidth: "",
              browserScreenWidth: "",
              browserScreenHeight: "",
            }
          : {
              ipMetaData: authState?.getIpAddressSuccess,
              ipAddress: authState?.getIpAddressSuccess?.ipAddress || "",
              deviceName: browserDetails?.deviceName,
              browser: browserDetails?.browserName,
              browserVersion: browserDetails?.browserVersion,
              platform: browserDetails?.platform,
              userAgent: navigator?.userAgent,
              isMobile: navigator?.userAgentData?.mobile,
              isScreenExtended: window.screen.isExtended,
              fullScreenMode: !isExceedHeight && !isExceedWidth,
              geoLocation: {
                latitude: geolocationPermissionStatus?.latitude || "",
                longitude: geolocationPermissionStatus?.longitude || "",
              },
              geoLocationPermission: geolocationPermissionStatus?.permission,
              cameraPermission: cameraAndMicrophonePermissionStatus?.camera,
              micPermission: cameraAndMicrophonePermissionStatus?.mic,
              viewHeight: window.screen.availHeight,
              viewWidth: window.screen.availWidth,
              browserScreenWidth: window.innerWidth,
              browserScreenHeight: window.innerHeight,
            },
    };
    dispatch(quiz.createSessionRequested(JSON.stringify(body)));

    // Navigate to the quiz screen
    if (location && location.pathname === "/OpeningScreen") {
      dispatch(quiz.updateLevelIndicator(AppConfig.LEVEL0));
      history("/QuizQuestions/" + AppConfig.LEVEL0 + "/1");
    }

    if (level === "3") {
      dispatch(auth.updateQuizLevel(AppConfig.LEVEL3));
    } else if (level === "1") {
      dispatch(auth.updateQuizLevel(AppConfig.LEVEL1));
    } else {
      dispatch(auth.updateQuizLevel(levelInfo));
    }
  };

  const networkToast = () => {
    if (userDetails?.role === "Candidate") {
      history("/candidatedashboard");
    } else {
      history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
    }
  };

  useEffect(() => {
    let mediaStream = null;
    let detectionInterval = null;
    let audioDetectionInterval = null;
    let timeoutId = null;
    let faceDetected = false;
    let voiceDetected = false;

    const requestPermissions = async () => {
      try {
        if (
          location &&
          location.pathname !== "/L3ProfileOpeningScreen" &&
          location.pathname !== "/L3VideoBehavioralOpeningScreen"
        ) {
          // if (proctoringDetails?.ipAddress) {
          //   dispatch(auth.getIpAddress());
          // }
          if (proctoringDetails?.geoLocation !== "notRequired") {
            try {
              await requestGeolocation();
            } catch (error) {
              // Handle geolocation permission error
              console.error("Error requesting geolocation:", error);
            }
          }

          // Always request camera and microphone permission
          try {
            if (proctoringDetails?.webcam !== "notRequired") {
              console.log("Requesting microphone and webcam access...");
              const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
              setCameraPermission(true);
              console.log(" ********\n Permission granted for microphone and webcam \n ********");

              mediaStream = stream;

              // Start video stream and face detection
              const video = document.createElement("video"); // Create video element dynamically
              video.srcObject = stream;
              video.muted = true; // Mute video feedback
              video.play();
              detectionInterval = setInterval(() => detectFaces(video), 1000); // Detect faces every second

              // Start audio detection
              audioDetectionInterval = setInterval(() => {
                if (!voiceDetected) {
                  detectVoice();
                } else {
                  clearInterval(audioDetectionInterval);
                }
              }, 1000); // Check for voice every second

              timeoutId = setTimeout(() => {
                try {
                  if (!faceDetected) {
                    console.log("No face detected, stopping face detection");
                    clearInterval(detectionInterval);
                    setLoadingFace(false);
                    setFaceDetected(false);
                  }
                  if (!voiceDetected) {
                    console.log("No voice detected, stopping audio detection");
                    clearInterval(audioDetectionInterval);
                    setLoadingVoice(false);
                    setVoiceDetected(false);
                  }
                } catch (error) {
                  console.error("Error stopping detection:", error);
                } finally {
                  console.log("Stopping detection");
                  stopFunction();
                }
              }, 10000);
            } else {
              setCameraPermission(false);
              setLoadingVoice(false);
              setLoadingFace(false);
              console.log("Camera and microphone permission not required");
            }
          } catch (error) {
            setCameraPermission(false);
            setLoadingVoice(false);
            setLoadingFace(false);
            // Handle microphone and webcam permission error
            console.error("Error requesting microphone and webcam:", error);
          } finally {
            console.log("Finished requesting microphone and webcam");
            setLoading(false);
          }
        }
      } catch (error) {
        // Handle any errors that may occur during permission requests
        console.error("Error requesting permission:", error);
        setLoading(false);
      }
    };

    const stopFunction = () => {
      if (mediaStream) {
        console.log("stopFunction called");
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };

    const detectFaces = async (video) => {
      console.log("detectFaces function called");
      const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions());
      console.log("******* face detected *********\n  ", detections.length > 0 ? true : false);
      setFaceCount(detections.length);

      if (detections.length > 0) {
        console.log("Face detected, stopping face detection");
        setLoadingFace(false);
        setFaceDetected(true);
        // clearTimeout(timeoutId);
        faceDetected = true;
        // stopFunction();
        clearInterval(detectionInterval);
      }
    };

    const detectVoice = () => {
      console.log("detectVoice function called");
      const audioContext = new AudioContext();
      const analyserNode = audioContext.createAnalyser();
      const microphoneStream = audioContext.createMediaStreamSource(mediaStream);
      microphoneStream.connect(analyserNode);
      analyserNode.fftSize = 2048;
      const bufferLength = analyserNode.fftSize;
      const dataArray = new Uint8Array(bufferLength);

      // Clear existing audioDetectionInterval before creating a new one
      clearInterval(audioDetectionInterval);
      audioDetectionInterval = setInterval(() => {
        analyserNode.getByteTimeDomainData(dataArray);
        const isSpeaking = Array.from(dataArray).some((value) => value > 150);
        console.log("******* audio detected *********\n  ", isSpeaking);

        if (isSpeaking) {
          console.log("Voice detected, stopping audio detection");
          clearInterval(audioDetectionInterval);
          voiceDetected = true;
          setLoadingVoice(false);
          setVoiceDetected(true);
        }
      }, 100);
    };

    // Call the requestPermissions function
    console.log("Calling requestPermissions function");
    requestPermissions();

    console.log("Logging state and props:");
    console.log("ipAddress:", authState.getIpAddressSuccess?.ipAddress);
    console.log("proxy:", authState.getIpAddressSuccess?.isProxy);
    console.log("browser details:", browserDetails);
    console.log("isExceedHeight:", !isExceedHeight);
    console.log("isExceedWidth:", !isExceedWidth);
    console.log(latitude, "latitude");
    console.log(longitude, "longitude");
    console.log(faceCount, "faceCount");

    return () => {
      console.log("Cleaning up on unmount");
      // Clear interval for face detection
      clearInterval(detectionInterval);
      clearInterval(audioDetectionInterval);
      clearTimeout(timeoutId);
      stopFunction();
    };
  }, []);

  // for getting browser details and ip address
  useEffect(() => {
    if (location && location.pathname !== "/L3ProfileOpeningScreen") {
      // Instantiate the parser
      const parser = new UAParser();

      // Parse the user agent string
      const result = parser.getResult();

      // Set the browser details in the state
      setBrowserDetails({
        browserName: result?.browser?.name,
        browserVersion: result?.browser?.version,
        platform: result?.os?.name,
        deviceName: result?.device?.type,
      });

      // Get the IP address for all pages except the profile page
      if (proctoringDetails?.ipAddress) {
        dispatch(auth.getIpAddress());
      }
    }
  }, []);

  // function for height
  function isInnerHeightLessThan90Percent() {
    // Get the available height and inner height of the browser
    const availableHeight = window.screen.availHeight;
    const innerHeight = window.innerHeight;

    // Calculate 90% of the available height
    const nintyPercentOfHeight = 0.9 * availableHeight;

    return innerHeight < nintyPercentOfHeight;
  }

  // functtion for width
  function isInnerWidthLessThan90Percent() {
    // Get the available height and inner height of the browser
    const availableWidth = window.screen.availWidth;
    const innerWidth = window.innerWidth;

    // Calculate 95% of the available Width
    const nintyPercentOfWidth = 0.95 * availableWidth;

    return innerWidth < nintyPercentOfWidth;
  }

  const isExceedHeight = isInnerHeightLessThan90Percent();
  const isExceedWidth = isInnerWidthLessThan90Percent();

  // made promise so we can await
  const requestGeolocation = () => {
    return new Promise((resolve, reject) => {
      if (
        location &&
        location.pathname !== "/L3ProfileOpeningScreen" &&
        location.pathname !== "/L3VideoBehavioralOpeningScreen"
      ) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              console.log("\n *********Geolocation obtained************", position);
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
              setGeolocation(true);
              resolve();
            },
            (error) => {
              console.error("Error getting geolocation:", error);
              setGeolocation(false);
              reject(error);
            }
          );
        } else {
          console.error("Geolocation is not supported in this browser.");
          setGeolocation(false);
          reject(new Error("Geolocation is not supported in this browser."));
        }
      } else {
        resolve();
      }
    });
  };
  // this is used to get the screen size on changing screen size and we are not using that info

  // const [screenSize, setScreenSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });

  // const handleResize = () => {
  //   setScreenSize({
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   });
  // };

  // useEffect(() => {
  //   if (location && location.pathname !== "/L3ProfileOpeningScreen") {
  //     // Add resize event listener
  //     window.addEventListener("resize", handleResize);

  //     // Remove event listener on component unmount
  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }
  // }, [screenSize]);

  const handleDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (authState.logoDetails?.logo == "") {
      setDomainDetails({ logo: localStorage.getItem("logo"), displayName: "" });
    }
  }, [localStorage.getItem("logo")]);

  const determinePermissions = (settings) => {
    const permissions = [];
    const requiredPermissions = [];

    if (settings?.geoLocation !== "notRequired") {
      permissions.push("geoLocation", "coordinates");
      if (settings?.geoLocation === "required") {
        requiredPermissions.push("geoLocation");
      }
    }

    if (settings?.webcam !== "notRequired") {
      permissions.push("camera", "faceDetection", "voiceDetection");
      if (settings?.webcam === "required") {
        requiredPermissions.push("camera");
      }
    }

    return { permissions, requiredPermissions };
  };

  const { permissions, requiredPermissions } = determinePermissions(proctoringDetails);

  return (
    <div className={classes.root}>
      <div
        className={`${classes.section1}   ${classes.cardOne}  ${classes.center}`}
        // onClick={() => networkToast()}
        style={{ cursor: "pointer" }}
      >
        {/* <img src={BayoneLogo} alt={"Bayone Logo"} /> */}

        <div align="center">
          {domainDetails && domainDetails?.logo !== "" ? (
            <>
              <img height="33" width="157" src={`${logoUrl}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
              <br />
              <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
            </>
          ) : (
            <a href="https://techscore.ai" target="_self" rel="noreferrer">
              <img height="50" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
            </a>
          )}
        </div>
      </div>

      <Grid container className={`${classes.section} ${classes.cardTwo}`}>
        {isNotMobile && <Grid item xs={3}></Grid>}
        <Grid item xs={isNotMobile ? 6 : 12}>
          {location && location.pathname === "/OpeningScreen" && (
            <Typography
              // component={Text}
              // variant2="h3"
              align="center"
              className={`${classes.cardTwoH1} ${classes.h3sty}`}
              style={theme.typography.h3}
            >
              We need a few things for your profile. {isNotMobile ? <br /> : null}
              Let’s start putting the pieces together for the job you’re seeking.
            </Typography>
          )}
          {location && location.pathname === "/L1OpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1} ${classes.cardtext}  ${classes.h3sty}`}
              style={theme.typography.h3}
            >
              Let’s get your initial evaluation! {isNotMobile ? <br /> : null}
              We’ll start with technical questions and basic background details about you.
            </Typography>
          )}
          {location && location.pathname === "/L2OpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1} ${classes.h3sty}`}
              style={theme.typography.h3}
            >
              You’re about to begin the technical evaluation! {""}
              {isNotMobile ? <br /> : null}
              This customized quiz covers topics relevant to your target role.
            </Typography>
          )}
          {location && location.pathname === "/L3PresentationOpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1} ${classes.h3sty}`}
              style={theme.typography.h3}
            >
              This section tests your work culture, work ethics, and job readiness. {isNotMobile ? <br /> : null}
              Are you a good fit for your next role?
            </Typography>
          )}
          {location && location.pathname === "/L3VideoBehavioralOpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1} ${classes.h3sty}`}
              style={theme.typography.h3}
            >
              This section tests your behavioral skills and job readiness. {isNotMobile ? <br /> : null}
              Are you a good fit for your next role?
            </Typography>
          )}
          {location && location.pathname === "/L3ProfileOpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1}  ${classes.h3sty}`}
              style={theme.typography.h3}
            >
              Update your resume and digital presence, including LinkedIn, GitHub, StackOverflow, LeetCode, HackerRank
              and others.
            </Typography>
          )}
          {location && location.pathname === "/L3CodingOpeningScreen" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1} ${classes.h3sty}`}
              style={theme.typography.h3}
            >
              This section tests your problem-solving skills using a few hands-on coding challenges.
              {isNotMobile ? <br /> : null}
              Ready your keyboard!
            </Typography>
          )}
          {location && location.pathname === "/CodingSectionUnavailable" && (
            <Typography
              component={Text}
              variant2="h3"
              align="center"
              className={`${classes.cardTwoH1} ${classes.h3sty}`}
              style={theme.typography.h3}
            >
              Sorry, this section is
              <br />
              unavailable for this device
            </Typography>
          )}
        </Grid>
        {isNotMobile && <Grid item xs={3}></Grid>}
      </Grid>

      <Grid container className={`${classes.section} ${classes.cardThree}`}>
        {isNotMobile && <Grid item xs={4}></Grid>}
        <Grid item xs={isNotMobile ? 4 : 12} className={classes.center}>
          {location && location.pathname === "/OpeningScreen" && (
            <img className={`${classes.openingScreen}`} alt="openingScreen" src={`${ImagebaseUrl}assets/profile.png`} />
          )}
          {location && location.pathname === "/L1OpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="l1image"
              src={`${ImagebaseUrl}assets/Videocall with product owner.png`}
            />
          )}
          {location && location.pathname === "/L2OpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="l2image"
              src={`${ImagebaseUrl}assets/Team Product research.png`}
            />
          )}
          {location &&
            (location.pathname === "/L3PresentationOpeningScreen" ||
              location.pathname === "/L3VideoBehavioralOpeningScreen") && (
              <img
                className={`${classes.openingScreen}`}
                alt="l3PresentationImage"
                src={`${ImagebaseUrl}assets/Packing product.png`}
              />
            )}
          {location && location.pathname === "/L3ProfileOpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="l3ProfileImage"
              src={`${ImagebaseUrl}assets/profile.png`}
            />
          )}
          {location && location.pathname === "/L3CodingOpeningScreen" && (
            <img
              className={`${classes.openingScreen}`}
              alt="openingScreen"
              src={`${ImagebaseUrl}assets/Group 160.png`}
            />
          )}
          {location && location.pathname === "/CodingSectionUnavailable" && (
            <img
              className={`${classes.openingScreen}`}
              alt="openingScreen"
              src={`${ImagebaseUrl}assets/Group 160.png`}
            />
          )}
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>

      <Grid container className={`${classes.section} ${classes.cardFour}`}>
        {isNotMobile && <Grid item xs={4}></Grid>}
        <Grid item xs={isNotMobile ? 4 : 12} className={classes.center}>
          {location && location.pathname === "/OpeningScreen" && (
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.openingScreenDescription} ${classes.b1Regular}`}
              style={theme.typography.b1Regular}
            >
              We will ask you a few simple questions to understand the job you’re aiming for and will give you a target
              score needed for that position. Are you ready to find out?
            </Typography>
          )}
          {location && location.pathname === "/L1OpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText} ${classes.b1Medium}`}
              >
                25 multiple-choice questions in 20 minutes
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription} ${classes.b1Regular}`}
                style={theme.typography.b1Regular}
              >
                Once the timer has started it cannot be paused. Do not use the browser navigation buttons (back, forward
                or refresh) during quiz. <strong>Do not move out of the quiz or it will automatically submit</strong>.
                Ready to begin?
              </Typography>
            </Grid>
          )}
          {location && location.pathname === "/L2OpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText} ${classes.b1Medium}`}
              >
                30 multiple-choice questions in 20 minutes
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription} ${classes.b1Regular}`}
                style={theme.typography.b1Regular}
              >
                Once the timer has started it cannot be paused. Do not use the browser navigation buttons (back, forward
                or refresh) during quiz. <strong>Do not move out of the quiz or it will automatically submit</strong>.
                Ready to begin?
              </Typography>
            </Grid>
          )}
          {location && location.pathname === "/L3PresentationOpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText} ${classes.b1Medium}`}
              >
                15 multiple-choice questions in 15 minutes
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription} ${classes.b1Regular}`}
                style={theme.typography.b1Regular}
              >
                Once the timer has started it cannot be paused. Do not use the browser navigation buttons (back, forward
                or refresh) during quiz. <strong>Do not move out of the quiz or it will automatically submit.</strong>{" "}
                Ready to begin?
              </Typography>
            </Grid>
          )}
          {location && location.pathname === "/L3VideoBehavioralOpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText} ${classes.b1Medium}`}
              >
                You will answer three behavioral questions in 15 minutes.
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription} ${classes.b1Regular}`}
                style={theme.typography.b1Regular}
              >
                Get ready for Lights, Camera, and Action! Make sure your face is well-lit, and your surroundings are
                formal.
                <br /> Once the test has started, it cannot be paused. Do not use the browser navigation buttons (back,
                forward or refresh) during test.{" "}
                <strong>
                  Do not move out of the test or it will automatically submit.
                  <br />
                </strong>{" "}
                Ready to begin?
              </Typography>
            </Grid>
          )}
          {location && location.pathname === "/L3ProfileOpeningScreen" && (
            <Typography
              component={Text}
              variant2={isNotMobile ? "b1Regular" : "b2Regular"}
              align="center"
              className={`${classes.cardFourH1} ${classes.openingScreenDescription} ${classes.fontProfile} ${classes.b1Regular}`}
              style={theme.typography.b1Regular}
            >
              We will be evaluating the quality of your resume, your professional network, and your contributions to
              online collaboration platforms. {isNotMobile ? <br /> : null}
              Ready to complete your profile?
            </Typography>
          )}
          {location && location.pathname === "/L3CodingOpeningScreen" && (
            <Grid>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Medium" : "b2Medium"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription}  ${classes.minText} ${classes.b1Medium}`}
                style={theme.typography.b1Medium}
              >
                3 programming challenges in 45 minutes
              </Typography>
              <Typography
                component={Text}
                variant2={isNotMobile ? "b1Regular" : "b2Regular"}
                align="center"
                className={`${classes.cardFourH1} ${classes.openingScreenDescription} ${classes.b1Regular}`}
                style={theme.typography.b1Regular}
              >
                Once the timer has started it cannot be paused. Do not use the browser navigation buttons (back, forward
                or refresh) during quiz. <strong>Do not move out of the quiz or it will automatically submit</strong>.
                Ready to begin?
              </Typography>
            </Grid>
          )}
        </Grid>
        {isNotMobile && <Grid item xs={4}></Grid>}
      </Grid>

      {location && location.pathname === "/CodingSectionUnavailable" ? (
        <Grid container className={`${classes.section} ${classes.cardFive}`}>
          {isNotMobile && <Grid item xs={4}></Grid>}
          <Grid item xs={isNotMobile ? 4 : 12} className={classes.center}>
            <Button
              onClick={() => {
                history("/Dashboard");
              }}
              color="btnPrimary"
              style={{
                width: "100%",
              }}
              text="Return to home"
              data-rt-opening-returnhome="returnhome"
            ></Button>
          </Grid>
          {isNotMobile && <Grid item xs={4}></Grid>}
        </Grid>
      ) : (
        <div>
          <Grid container className={`${classes.section} ${classes.cardFive}`}>
            {isNotMobile && <Grid item xs={4}></Grid>}
            <Grid
              item
              xs={isNotMobile ? 4 : 12}
              className={classes.center}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Button
                onClick={
                  location.pathname !== "/L3ProfileOpeningScreen" && (loading || loadingFace || loadingVoice)
                    ? null
                    : onIamReadyClick
                }
                color={
                  location.pathname !== "/L3ProfileOpeningScreen" && (loading || loadingFace || loadingVoice)
                    ? "disablebtn"
                    : "btnPrimary"
                }
                disabled={location.pathname !== "/L3ProfileOpeningScreen" && (loading || loadingFace || loadingVoice)}
                width={"100%"}
                style={{
                  width: "100%",
                }}
                text="Yes, I’m ready to get started!"
                data-rt-opening-start="start"
              ></Button>
              {/* {errorMessage && <div style={{ marginTop: '10px', color: 'red' }}>{errorMessage}</div>} */}
            </Grid>
            {isNotMobile && <Grid item xs={4}></Grid>}
          </Grid>
          <Grid container className={`${classes.section} ${classes.cardSix}`}>
            {isNotMobile && <Grid item xs={4}></Grid>}
            <Grid item xs={isNotMobile ? 4 : 12}>
              <Link
                onClick={networkToast}
                data-rt-opening-notreadytostart="notreadytostart"
                className={classes.linkUnderline}
              >
                <Typography
                  variant2={isNotMobile ? "link1" : "link2"}
                  align="center"
                  style={{ cursor: "pointer" }}
                  className={classes.linktext}
                >
                  I’m not ready to start, return to home
                </Typography>
              </Link>
            </Grid>
            {isNotMobile && <Grid item xs={4}></Grid>}
          </Grid>
        </div>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      {location.pathname !== "/L3ProfileOpeningScreen" &&
        location.pathname !== "/L3VideoBehavioralOpeningScreen" &&
        permissions.length > 0 && (
          <PermissionsDialog
            openDialog={openDialog}
            loading={loading}
            geoLocation={geoLocation}
            latitude={latitude}
            longitude={longitude}
            cameraPermission={cameraPermission}
            loadingFace={loadingFace}
            faceDetected={faceDetected}
            loadingVoice={loadingVoice}
            voiceDetected={voiceDetected}
            networkToast={networkToast}
            handleDialog={handleDialog}
            permissions={permissions}
            requiredPermissions={requiredPermissions}
          />
        )}
    </div>
  );
}
export default OpeningScreen;
