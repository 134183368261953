import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import { theme } from "../styles/theme";
import RecruitmentProgressIcon from "../assets/svg/recruitmentProgress.svg";
import RolesIcon from "../assets/svg/roles.svg";
import RecruitmentIcon from "../assets/svg/recruitment.svg";
import LogoutIcon from "../assets/svg/logout.svg";
import InvitIcon from "../assets/svg/normalinviteicon.svg";
import CandidateblueIcon from "../assets/svg/candidateblue.svg";
import InviteblueIcon from "../assets/svg/blueinviteicon.svg";
import RecruiterblueIcon from "../assets/svg/recruiterblueicon.svg";
import RoleblueIcon from "../assets/svg/roleblueicon.svg";
import HomeIcon from "../assets/svg/home.svg";
import HomeblueIcon from "../assets/svg/bluehome.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authState from "../redux/actions/auth";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableTemporaryDrawer from "../components/controls/SwipeableTemporaryDrawer";
import quiz from "../redux/actions/quiz";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import DescriptionIcon from "@mui/icons-material/Description";

const useStyles = makeStyles({
  toolBar: {
    paddingLeft: "0px",
    paddingRight: "0px",
    minHeight: "73px!important",
  },
  navBar: {
    borderBottom: `0.5px solid #C4C4C4`,
    backgroundColor: `${theme.palette.mainColor} !important`,
    height: "60px",
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  labelBlue: {
    //color: `${theme.palette.black.light} !important`,
    color: "#1789FC !important",
    fontWeight: "500 !important",
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
    cursor: "pointer",
  },
  labelGray: {
    color: `${theme.palette.black.light} !important`,
    fontWeight: "500 !important",
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
    cursor: "pointer",
  },
  logoCls: {
    top: "18px",
    height: "52.41px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  navIconCls: {
    top: "18px",
    height: "14px",
    width: "22px",
    cursor: "pointer",
    padding: "0px 0px 0px 4px",
    margin: "-2px 2px",
  },
  dashlink: {
    "& .MuiListItem-root .MuiTouchRipple-child": {
      backgroundColor: "rgba(36, 36, 36, 0.24) !important",
    },
  },
  containerCls: {
    [theme.breakpoints.up("tablet")]: {
      // paddingRight: "64px !important",
      // paddingLeft: "64px !important",
    },
  },
});

export default function NavBar(props) {
  const classes = useStyles();
  const location = useLocation();
  const authStore = useSelector((state) => state.auth);
  const isNotMobile = useMediaQuery(theme.breakpoints.up("928"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [domainDetails, setDomainDetails] = React.useState({});
  // eslint-disable-next-line
  const [logoUrl, setLogoUrl] = React.useState(process.env.REACT_APP_ASSETS_URL);

  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  const uid = localStorage.getItem("uid");
  const loginMethod = localStorage.getItem("loginMethod");

  // const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [avatarBgColor, setAvatarBgColor] = useState("#787878");

  const OnClickLogout = () => {
    dispatch(authState.clearState());
    localStorage.removeItem("token1");
    localStorage.removeItem("userid");
    localStorage.removeItem("user_details");
    localStorage.removeItem("logo");
    localStorage.removeItem("uid");
    localStorage.removeItem("loginMethod");
    localStorage.removeItem("nextMaintenanceCheck");
    dispatch(quiz.clearState());

    if (loginMethod && userDetails.role == "Candidate") {
      navigate(`/login?uid=${uid}`);
    } else if (userDetails.role == "Candidate") {
      navigate(`/invite/${uid}`);
    } else {
      navigate("/login");
    }
  };

  const ImagClick = () => {
    if (location.pathname !== "/candidatedashboard") {
      navigate("/home");
    } else {
      navigate("/candidatedashboard");
    }
  };

  // Set logo
  useEffect(() => {
    setDomainDetails(authStore.logoDetails);
  }, []);

  useEffect(() => {
    if (authStore.logoDetails?.logo == "") {
      setDomainDetails({ logo: localStorage.getItem("logo"), displayName: "" });
    }
  }, [localStorage.getItem("logo")]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setAvatarBgColor("#1789FC");
    if (!localStorage.getItem("token1")) {
      navigate("/login");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAvatarBgColor("#787878");
  };

  const onClickSettings = () => {
    navigate("/accountSettings");
  };
  const onClickDocumentation = () => {
    navigate("/documentation");
  };

  function stringAvatar(name) {
    const initials = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toUpperCase();

    return {
      sx: {
        width: "45px",
        height: "45px",
        bgcolor: avatarBgColor,
      },
      children: initials,
    };
  }

  return (
    <AppBar
      elevation={0}
      className={classes.navBar}
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      {isNotMobile && (
        <Container maxWidth="xl" style={{ padding: 5 }} className={classes.containerCls}>
          <Toolbar className={classes.toolBar}>
            <Grid onClick={ImagClick} style={{ cursor: "pointer" }}>
              {domainDetails && domainDetails?.logo !== "" ? (
                <>
                  <img
                    width="157"
                    height="40"
                    src={`${logoUrl}${domainDetails?.logo}`}
                    alt={domainDetails?.displayName}
                  />
                  <img width="157" height="17" src={`${logoUrl}powered.svg`} alt="techscore" />
                </>
              ) : (
                <>
                  <img height="40" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
                </>
              )}
            </Grid>
            <Grid container justifyContent="flex-end" spacing={4} alignItems="center">
              {userDetails.role !== "Candidate" ? (
                <>
                  <Grid item>
                    <Link to={"/home"} style={{ textDecoration: "none" }} className={classes.dashlink}>
                      <Grid container alignItems="center">
                        <Typography
                          align="center"
                          className={`${location.pathname === "/home" ? classes.labelBlue : classes.labelGray}`}
                          data-rt-header-home="home"
                        >
                          Home
                          {location.pathname === "/home" ? (
                            <img src={HomeblueIcon} className={classes.navIconCls} alt="home" />
                          ) : (
                            <img src={HomeIcon} className={classes.navIconCls} alt="home" />
                          )}
                        </Typography>
                      </Grid>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to={"/candidatelist"} style={{ textDecoration: "none" }} className={classes.dashlink}>
                      <Grid container alignItems="center">
                        <Typography
                          align="center"
                          className={`${
                            location.pathname === "/candidatelist" ? classes.labelBlue : classes.labelGray
                          }`}
                          data-rt-header-candidate="candidatebtn"
                        >
                          Candidates
                          {location.pathname === "/candidatelist" ? (
                            <img src={CandidateblueIcon} className={classes.navIconCls} alt={"Candidates"} />
                          ) : (
                            <img src={RecruitmentProgressIcon} className={classes.navIconCls} alt="recruitment-icon" />
                          )}
                        </Typography>
                      </Grid>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to={"/invitelist"} style={{ textDecoration: "none" }}>
                      <Grid container alignItems="center">
                        <Typography
                          align="center"
                          className={`${location.pathname === "/invitelist" ? classes.labelBlue : classes.labelGray}`}
                          data-rt-header-invite="invitebtn"
                        >
                          Invites
                          {location.pathname === "/invitelist" ? (
                            <img
                              src={InviteblueIcon}
                              className={classes.navIconCls}
                              style={{ margin: "-2px 2px" }}
                              alt="invite-icon"
                            />
                          ) : (
                            <img src={InvitIcon} className={classes.navIconCls} alt="invite-icon" />
                          )}
                        </Typography>
                      </Grid>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link to={"/rolelist"} style={{ textDecoration: "none" }}>
                      <Grid container alignItems="center">
                        <Typography
                          align="center"
                          className={`${location.pathname === "/rolelist" ? classes.labelBlue : classes.labelGray}`}
                          data-rt-header-role="rolebtn"
                        >
                          Roles
                          {location.pathname === "/rolelist" ? (
                            <img src={RoleblueIcon} className={classes.navIconCls} alt="role-icon" />
                          ) : (
                            <img src={RolesIcon} className={classes.navIconCls} alt="role-icon" />
                          )}
                        </Typography>
                      </Grid>
                    </Link>
                  </Grid>
                  {userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor" ? null : (
                    <Grid item>
                      <Link to={"/userlist"} style={{ textDecoration: "none" }}>
                        <Grid container alignItems="center">
                          <Typography
                            align="center"
                            className={`${location.pathname === "/userlist" ? classes.labelBlue : classes.labelGray}`}
                            data-rt-header-recruiter="recruiterbtn"
                          >
                            Team
                            {location.pathname === "/userlist" ? (
                              <img src={RecruiterblueIcon} className={classes.navIconCls} alt="recruitment-icon" />
                            ) : (
                              <img src={RecruitmentIcon} className={classes.navIconCls} alt="recruitment-icon" />
                            )}
                          </Typography>
                        </Grid>
                      </Link>
                    </Grid>
                  )}
                </>
              ) : null}
              <Grid item alignContent="center">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar {...stringAvatar(userDetails.name)} />
                </IconButton>
                <Menu
                  sx={{ mt: "50px" }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {userDetails.role === "Recruiter" ? (
                    <div>
                      <MenuItem onClick={onClickSettings}>
                        Manage Account{" "}
                        <ManageAccountsOutlinedIcon fontSize="small" color="action" className={classes.navIconCls} />
                      </MenuItem>
                      <MenuItem onClick={onClickDocumentation}>
                        Documentation <DescriptionIcon fontSize="small" color="action" className={classes.navIconCls} />
                      </MenuItem>
                      <Divider variant="middle" />
                    </div>
                  ) : null}
                  <MenuItem onClick={OnClickLogout}>
                    Log out <img src={LogoutIcon} alt="LogOut" className={classes.navIconCls} />
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      )}
      {!isNotMobile && (
        <Container className={`${classes.containerCls}`}>
          <SwipeableTemporaryDrawer></SwipeableTemporaryDrawer>
        </Container>
      )}
    </AppBar>
  );
}
