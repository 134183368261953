// disable eslint for this file
/* eslint-disable */
import React, { useEffect, useRef } from "react";
import { Chart, DoughnutController, ArcElement } from "chart.js";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ScoreChart from "./Scorechart";
import Tooltip from "@mui/material/Tooltip";

Chart.register(DoughnutController, ArcElement, ScoreChart);

// setting default grey color
const greyColor = "#787878";

const useStyles = makeStyles({
  scroreMeterGraph: {
    position: "relative",
    width: "100%",
    height: "250px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  scroreMeterGraphId: {
    height: "250px !important",
    position: "absolute",
  },
  scroreMeterGraphInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "300px",
    position: "absolute",
    bottom: "20px",
  },
  line1: {
    color: greyColor,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  line2: {
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "600 !important",
    fontSize: "32px !important",
    lineHeight: "32px !important",
    textAlign: "center",
    letterSpacing: "-0.02em !important",
    color: greyColor,
    margin: "16px 0 !important",
  },
  targetScore: {
    color: greyColor,
    fontWeight: "600 !important",
  },
});

const GraphScoreMeter = (props) => {
  const {
    threeLineWithLink,
    twoLineWithLink,
    link,
    twoLine,
    fourLine,
    line1,
    line2,
    line3,
    line4,
    targetScore,
    targetLabel,
    rangeMinScore,
    rangeMaxScore,
    custom,
    score,
  } = props;

  const classes = useStyles();

  const chart = useRef(null);

  // Handle the Garph chart of score and targetscore
  useEffect(() => {
    const totalScore = 800;
    const ctx = document.getElementById("scroreMeterGraphId");
    //const ctx = document.getElementsByTagName("canvas")[0];
    var gradient = ctx.getContext("2d").createLinearGradient(0, 0, 500, 0);
    gradient.addColorStop(0, "#FF941C");
    gradient.addColorStop(1, "#FFC5AD");

    var data = [totalScore],
      bg = ["#EBEFF8"];

    if (!targetScore) {
      data = [totalScore];
      bg = ["#EBEFF8"];
    }

    if (!score && targetScore) {
      data = [targetScore, totalScore - targetScore];
      var targetGradient = ctx.getContext("2d").createLinearGradient(0, 0, 500, 0);
      targetGradient.addColorStop(0, "#E1E0FF");
      targetGradient.addColorStop(1, "#61A2E4");
      bg = [targetGradient, "#EBEFF8"];
    }

    if (score) {
      data = [score, totalScore - score];
      if (score < targetScore) {
        bg = [gradient, "#EBEFF8"];
      } else {
        var highScoreGradient = ctx.getContext("2d").createLinearGradient(0, 0, 500, 0);
        highScoreGradient.addColorStop(0, "#28BB3F");
        highScoreGradient.addColorStop(1, "#1789FC");
        bg = [highScoreGradient, "#EBEFF8"];
      }
    }

    chart.current = new Chart(ctx, {
      // type: 'scorechart',
      data: {
        labels: ["Score"],
        datasets: [
          {
            type: "scorechart",
            label: "Target Score",
            data: data,
            backgroundColor: bg,
          },
        ],
      },
      options: {
        borderWidth: 0,
        cutout: "95%",
        circumference: 180,
        rotation: -90,
        totalScore: totalScore,
        targetScore: targetScore,
        targetLabel: targetLabel,
        //  rangeMinScore: rangeMinScore,
        // rangeMaxScore: rangeMaxScore,
        score: score,
        plugins: {
          tooltip: { enabled: false },
          legend: {
            display: false,
          },
        },
        layout: {
          padding: 15,
        },
      },
    });

    return () => {
      if (chart) {
        chart.current.destroy();
      }
    };
  }, [score, targetLabel, targetScore]);

  const truncate = (str) => {
    return str?.length > 30 ? str?.substring(0, 30) + "..." : str;
  };
  // threeLineWithLink is on roles page
  // twoLineWithLink is on invites page
  return (
    <div className={classes.scroreMeterGraph} style={{ minWidth: "470px" }}>
      <canvas id="scroreMeterGraphId" className={classes.scroreMeterGraphId} />
      {threeLineWithLink && (
        <div className={classes.scroreMeterGraphInfo}>
          <Typography className={classes.line1}>{line1}</Typography>
          <Typography variant="h1" className={classes.targetScore}>
            {targetScore}
          </Typography>
        </div>
      )}
      {twoLineWithLink && (
        <div className={classes.scroreMeterGraphInfo}>
          <Typography className={classes.line1}>{line1}</Typography>
          <Tooltip title={line2} placement="bottom">
            <Typography className={classes.line2}>{truncate(line2)}</Typography>
          </Tooltip>
          <Typography className={classes.line1}>{line3}</Typography>
          <Typography className={classes.line1}>{line4}</Typography>
        </div>
      )}
    </div>
  );
};

export default GraphScoreMeter;
