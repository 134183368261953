import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, useMediaQuery, Snackbar } from "@mui/material";
import Button from "./controls/Button";
import CustomizedProgressBars from "./controls/CustomizedProgressBars";
import quiz from "../redux/actions/quiz";
import { withStyles } from "@mui/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useNavigate, generatePath } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ImagebaseUrl } from "../services/globalService";

const useStyles = makeStyles((theme, props) => ({
  title: {
    marginTop: 32,
  },
  section: {
    width: "100%",
    marginBottom: 10,
  },
  questionText: {
    width: "253px",
    height: "40px",
    marginBottom: 24,
    "&:hover": {
      cursor: "pointer",
    },
    "@media screen and (min-width:360px) and (max-width:780px)": {
      width: "227px",
    },
  },
  answerText: {
    width: "80px",
    height: "40px",
    marginBottom: "24px !important",
    fontSize: "12px",
    color: "#787878 !Important",
  },
  unanswerText: {
    width: "80px",
    height: "40px",
    marginBottom: "24px !important",
    fontSize: "12px",
  },
  statusIcon: {
    marginLeft: 8,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  expectationWarning: {
    color: "#DD2E2E",
    paddingTop: 4,
    paddingBottom: 0,
  },
  progressArea: {
    marginBottom: 15,
    paddingRight: 24,
    marginTop: 15,
  },
  summaryArea: {
    height: "100%",
    paddingBottom: "45px",
    overflowY: "auto",
    overflowX: "hidden",
    //paddingRight: '24px'
  },
  submit: {
    marginBottom: 16,
  },
  progressBar: {
    marginTop: "10px !important",
  },
  expandIcon: {
    color: "#1789FC",
  },
  flatList: {
    "&:first-child": {
      borderTop: "1px solid #EBEFF8",
      marginTop: 24,
      paddingTop: 24,
    },
  },
  bottomArea: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    bottom: 0,
    paddingTop: 16,
    paddingBottom: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("tablet")]: {
      width: 400,
    },
  },
  quizSummery: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: "8px",
    paddingRight: 24,
  },
  closeDrawer: {
    width: "14px",
    height: "14px",
    marginLeft: "16px",
    marginTop: "24px",
  },
  sidebarContainer: {
    paddingRight: "0px",
  },
  font16: {
    color: "#787878 !important",
  },
  fontlink: theme.typography.link2,
  titleheader: theme.typography.h5,
  progessbody: theme.typography.b2Regular,
  textValue: theme.typography.b2Medium,
}));

const SwipeableDrawer = withStyles((theme) => ({
  paper: {
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: 400,
    },
  },
}))(MuiDrawer);

const Accordion = withStyles({
  root: {
    borderTop: "1px solid #EBEFF8",
    borderBottom: "1px solid #EBEFF8",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    backgroundColor: "#ffffff",
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    paddingBottom: "16px",
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

export default function SideBar(props) {
  // eslint-disable-next-line
  const { group, level, ...other } = props;
  const anchor = "right";
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });
  // const history = useNavigate(); // FK cleanup july 2023
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const quizState = useSelector((state) => state.quiz);
  // const roleState = useSelector((state) => state?.role);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));

  const data = quizState.quizSummery;
  const truncate = (str) => {
    if (isNotMobile) {
      return str.length > 60 ? str.substring(0, 60) + "..." : str;
    } else {
      return str.length > 54 ? str.substring(0, 54) + "..." : str;
    }
  };

  // handle to click the question to show particular summary question
  const onQuestionClick = (question) => {
    // console.log("Question clicked: ", question);

    props.onJumpQus(question.seq);
    dispatch(quiz.updateShowSummary(false));
  };

  // handle to show the questions of quiz section
  const generateCategorySummary = (questions) => {
    if (questions) {
      return (
        <div className={classes.section}>
          {questions.map((value) => (
            <Grid style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography
                variant2="b2Medium"
                className={`${classes.questionText} ${classes.textValue}`}
                align="left"
                id="no-copy"
                onClick={() => onQuestionClick(value)}
              >
                {value.seq}.{" "}
                <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}> {truncate(value.question)} </span>
              </Typography>
              {value.isAnswered && (
                <Typography
                  variant2="link2"
                  className={`${classes.answerText} ${classes.fontlink}`}
                  align="right"
                  onClick={() => onQuestionClick(value)}
                >
                  Answered
                </Typography>
              )}
              {!value.isAnswered && (
                <Typography
                  variant2="link2"
                  className={`${classes.unanswerText} ${classes.fontlink}`}
                  align="right"
                  onClick={() => onQuestionClick(value)}
                >
                  Unanswered
                </Typography>
              )}
            </Grid>
          ))}
        </div>
      );
    }
  };

  const generateSummary = (summary, group) => {
    return summary.map((value) => (
      <>
        {group && (
          <Accordion square>
            <AccordionSummary id={`${value.id}-header`} expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}>
              <Typography variant2="body3" className={classes.progessbody} align="left">
                {value.category} ({value.answeredQuestions}/{value.totalQuestions})
              </Typography>
              {value.status == "complete" && (
                <img src={`${ImagebaseUrl}assets/Vector.svg`} className={classes.statusIcon} alt="vector-icon" />
              )}
              {value.status == "incomplete" && (
                <img
                  src={`${ImagebaseUrl}assets/warning - icon.svg`}
                  className={classes.statusIcon}
                  alt="warning-icon"
                />
              )}
            </AccordionSummary>
            <AccordionDetails>{generateCategorySummary(value.questions)}</AccordionDetails>
          </Accordion>
        )}
        {!group && <div className={classes.flatList}>{generateCategorySummary(value.questions)}</div>}
      </>
    ));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    if (!open) {
      dispatch(quiz.updateShowSummary(false));
    }

    setState({ ...state, [anchor]: open });
  };

  const onSubmitClick = () => {
    dispatch(quiz.submitAnswers(true));
  };

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  return (
    <Container className={`${classes.footer} ${classes.containerCls}`}>
      <SwipeableDrawer
        style={{ userSelect: "none" }}
        anchor={anchor}
        open={quizState.showSummary}
        width={400}
        // onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        <Container className={classes.sidebarContainer}>
          <div className={classes.quizSummery}>
            <Typography
              //component={Text}
              variant2="h5"
              className={`${classes.title} ${classes.titleheader}`}
              align="left"
            >
              Quiz Summary
            </Typography>
            <img
              src={`${ImagebaseUrl}assets/closeDrawer.png`}
              className={classes.closeDrawer}
              onClick={toggleDrawer(anchor, false)}
              alt="closeDrawer"
            />
          </div>
          <Grid container className={classes.progressArea}>
            <Grid item xs={6}>
              <Typography variant2="body2" className={`${classes.progess} ${classes.progessbody}`} align="left">
                You’ve answered {data.answeredQuestions} of {data.totalQuestions}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.progressBar}>
              <CustomizedProgressBars
                value={(data.answeredQuestions / data.totalQuestions) * 100}
                color={data.expectedAnswers > data.answeredQuestions ? "#C4C4C4" : "#1a90ff"}
              ></CustomizedProgressBars>
            </Grid>
          </Grid>
          <div className={classes.summaryArea}>{generateSummary(data.summary, props.group)}</div>
        </Container>
        <div className={classes.bottomArea}>
          <div className={`${classes.section} ${classes.center} ${classes.actionLink} ${classes.submit}`}>
            <Button
              color="btnPrimary"
              text="Submit Answers"
              width="343px"
              style={{ marginTop: "3px" }}
              onClick={() => onSubmitClick()}
              data-rt-quizsummary-submitanswers="submitanswers"
            ></Button>
          </div>
        </div>
      </SwipeableDrawer>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Container>
  );
}
