import { call, put, takeLatest } from "redux-saga/effects";
import chartTypes from "../types/chart";
import chart from "../actions/chart";
import axios from "../../services/axios";
// import role from "../actions/role";
import auth from "../actions/auth";

function* ChartData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/chart/data",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status === 0) {
      yield put(chart.ChartDataSuccess(res.data));
    }
  } catch (err) {
    put(auth.apiError(err));
  }
}

function* StackChartData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/chart/data",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status === 0) {
      yield put(chart.StackChartDataSuccess(res.data));
    }
  } catch (err) {
    put(auth.apiError(err));
  }
}

function* PieChartData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/chart/data",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status === 0) {
      yield put(chart.PieChartDataSuccess(res.data));
    }
  } catch (err) {
    put(auth.apiError(err));
  }
}

function* ScatterChartData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/chart/data",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    // if (res.status === 0) {
    yield put(chart.ScatterChartDataSuccess(res.data));
    // }
  } catch (err) {
    put(auth.apiError("err"));
  }
}

function* CandidatesChartData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/chart/candidatesData",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status === 0) {
      yield put(chart.CandidatesChartDataSuccess(res.data));
    }
  } catch (err) {
    put(auth.apiError(err));
  }
}
function* RoleChartData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/chart/comparisonData",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    // if (res.status === 0) {
    yield put(chart.RoleChartDataSuccess(res.data));
    // }
  } catch (err) {
    // put(auth.apiError(err));
  }
}

function* chartSaga() {
  yield takeLatest(chartTypes.CHART_DATA, ChartData);
  yield takeLatest(chartTypes.STACK_CHART_DATA, StackChartData);
  yield takeLatest(chartTypes.PIE_CHART_DATA, PieChartData);
  yield takeLatest(chartTypes.SCATTER_CHART_DATA, ScatterChartData);
  yield takeLatest(chartTypes.CANDIDATES_CHART_DATA, CandidatesChartData);
  yield takeLatest(chartTypes.ROLE_CHART_DATA, RoleChartData);
}

export { chartSaga };
