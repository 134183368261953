const quizTypes = {
  GET_QUESTIONS_REQUESTED: "GET_QUESTIONS_REQUESTED",
  GET_QUESTIONS: "GET_QUESTIONS",
  GET_CODING_QUESTIONS: "GET_CODING_QUESTIONS",
  GET_TRACKING_REQUESTED: "GET_TRACKING_REQUESTED",
  GET_TRACKING: "GET_TRACKING",
  GET_L0_QUESTIONS: "GET_L0_QUESTIONS",
  GET_L1_QUESTIONS: "GET_L1_QUESTIONS",
  GET_L2_QUESTIONS: "GET_L2_QUESTIONS",
  GET_L3_QUESTIONS: "GET_L3_QUESTIONS",
  GET_L3_PRESENTATION: "GET_L3_PRESENTATION",
  UPDATE_PREV_CATEGORY: "UPDATE_PREV_CATEGORY",
  UPDATE_CURRENT_CATEGORY: "UPDATE_CURRENT_CATEGORY",
  UPDATE_LEVEL: "UPDATE_LEVEL",
  UPDATE_SHOW_SUMMARY: "UPDATE_SHOW_SUMMARY",
  UPDATE_SUMMARY: "UPDATE_SUMMARY",
  SUBMIT_ANSWERS: "SUBMIT_ANSWERS",
  CALCULATE_QUIZ_SUMMERY: "CALCULATE_QUIZ_SUMMERY",
  RESET_QUIZ_SUMMERY: "RESET_QUIZ_SUMMERY",
  CREATE_SESSION: "CREATE_SESSION",
  CREATE_SESSION_REQUESTED: "CREATE_SESSION_REQUESTED",
  SUBMIT_CODING_CHALLENGE: "SUBMIT_CODING_CHALLENGE",
  CODING_CHALLENGE_SUBMITTED: "CODING_CHALLENGE_SUBMITTED",
  GET_CODING_SUBMISSION_DETAIL: "GET_CODING_SUBMISSION_DETAIL",
  UPDATE_CODING_SUBMISSION_DETAIL: "UPDATE_CODING_SUBMISSION_DETAIL",
  RESET_CODING_SUBMISSION_DETAIL: "RESET_CODING_SUBMISSION_DETAIL",
  SUBMIT_TEST_RESULTS: "SUBMIT_TEST_RESULTS",
  TEST_RESULTS_SUBMITTED: "TEST_RESULTS_SUBMITTED",
  SET_CODING_INPUT: "SET_CODING_INPUT",
  CODING_INPUT_UPDATED: "CODING_INPUT_UPDATED",
  SET_CODING_TEXT: "SET_CODING_TEXT",
  CODING_TEXT_UPDATED: "CODING_TEXT_UPDATED",
  SET_CODING_OUTCOME: "SET_CODING_OUTCOME",
  CODING_OUTCOME_UPDATED: "CODING_OUTCOME_UPDATED",
  UPDATE_SUBMITTED_ANSWER: "UPDATE_SUBMITTED_ANSWER",
  SUBMIT_CODING_ANSWERS: "SUBMIT_CODING_ANSWERS",
  SUBMIT_CODING_ANSWERS_ERROR: "SUBMIT_CODING_ANSWERS_ERROR",
  TIMER_PLAYING: "TIMER_PLAYING",
  SET_TIMER: "SET_TIMER",
  SELECT_LANGUAGE: "SELECT_LANGUAGE",
  RESET_TEST_RESULT: "RESET_TEST_RESULT",
  CLEAR_QUIZ_STATE: "CLEAR_QUIZ_STATE",
  PUSH_SUBMITTED_ANSWER: "PUSH_SUBMITTED_ANSWER",
  POP_SUBMITTED_ANSWER: "POP_SUBMITTED_ANSWER",
  RESET_QUIZ_QUESTIONS: "RESET_QUIZ_QUESTIONS",
  RECREATE_USER_SUBMIT_RESULTS: "RECREATE_USER_SUBMIT_RESULTS",
  QUIZ_SELECTION_TOPIC: "QUIZ_SELECTION_TOPIC",
  QUIZ_SELECTION_POPUP_STATUS: "QUIZ_SELECTION_POPUP_STATUS",
  QUIZ_SELECTION_POPUP_DISABLE: "QUIZ_SELECTION_POPUP_DISABLE",
  SET_ELAPSED_TIME: "SET_ELAPSED_TIME",
  RESET_ELAPSED_TIME: "RESET_ELAPSED_TIME",
  SET_USER_METADATA_REQ: "SET_USER_METADATA_REQ",
  RESET_CODING: "RESET_CODING",
  SET_EDIT_PROFILE: "SET_EDIT_PROFILE",
  UPDATE_LEVEL_INDICATOR: "UPDATE_LEVEL_INDICATOR",
  SET_PREV_TIME: "SET_PREV_TIME",
  SET_SELECTED_QUES_ND_ANS: "SET_SELECTED_QUES_ND_ANS",
  SET_API_ERROR: "SET_API_ERROR",
  SET_L0_TEST_RESULT: "SET_L0_TEST_RESULT",
  SUBMIT_CODING_CHALLENGE_STATUS: "SUBMIT_CODING_CHALLENGE_STATUS",
  STATUS_CODING_SUBMISSION: "STATUS_CODING_SUBMISSION",
  SUBMIT_RESULT_STATUS: "SUBMIT_RESULT_STATUS",
  UPDATE_CODING_COMPILE_AND_RUN_TRACKING_DETAILS: "UPDATE_CODING_COMPILE_AND_RUN_TRACKING_DETAILS",
  UPDATE_QUIZ_TIMEOUT_FLAG: "UPDATE_QUIZ_TIMEOUT_FLAG",
  EXIT_PROFILE_SESSION: "EXIT_PROFILE_SESSION",
  CANDIDATE_CAMERA_CAPTURE: "CANDIDATE_CAMERA_CAPTURE",
  CANDIDATE_CAMERA_CAPTURE_SUCCESS: "CANDIDATE_CAMERA_CAPTURE_SUCCESS",
  // RECREATE_USER_SUBMIT_RESULTSUCCESS:"RECREATE_USER_SUBMIT_RESULTSUCCESS"
};

export default quizTypes;
