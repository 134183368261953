import * as React from "react";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { transformOptions } from "../../services/globalService";
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//     sx: {
//       //bgcolor: 'pink',
//       "& .MuiMenuItem-root": {
//         //padding: 2,
//         background: "transparent",
//       },
//       "& .Mui-selected:hover": {
//         background: "transparent",
//       },
//     },
//   },
// };

const useStyles = makeStyles({
  noBorder: {
    border: "none !important",
  },
  selectMenu: {
    "& .css-vamk89-MuiFormControl-root .MuiFormControl-root": {
      width: "100% !important",
      margin: "0px !important",
    },
    // "&:hover": {
    //   border: "1px solid transparent"
    // },
    // // "&:focus-within": {
    // //   border: "1px solid transparent"
    // // },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid transparent",
      outline: "none !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid transparent",
    },
    // "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   border: "1px solid transparent",

    // },
    // "& .Mui-disabled": {

    // },
    // "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    //   border: "1px solid transparent"
    // }
  },
  selectText: {
    "& .MuiSelect-select": {
      background: "#EBEFF8 !important",
      border: "none !important",
      padding: "12.5px 16px !important",
      height: "49px",
    },
    "&.Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
        outline: "none !important",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none !important",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
    outline: "none !important",
    border: "none !important",
  },
  selectText1: {
    outline: "none !important",
    "& .MuiSelect-select": {
      background: "transparent !important",
      border: "1px solid #787878 !important",
      padding: "12.5px 16px !important",
      color: "#787878 !important",
      height: "49px",
    },
    "& .MuiOutlinedInput-root .Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
  },
  selectHighlight: {
    outline: "none !important",
    "& .MuiSelect-select": {
      background: "transparent !important",
      border: "1px solid #ff9153 !important",
      padding: "12.5px 16px !important",
      color: "#787878 !important",
      height: "49px",
    },
    "& .MuiOutlinedInput-root .Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
  },
  placeholdertext: {
    color: "#787878",
    fontSize: "16px !important",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
  },
  icon: {
    color: "#2F2F2F !important",
  },
});

export default function SelectMenu(props) {
  const { selectvalue, selectHandleChange, prevSelectedValue, pals } = props;

  const [options, setOptions] = React.useState([]);
  const [selectedvalue, setSelectedvalue] = React.useState(prevSelectedValue);
  const classes = useStyles();

  React.useEffect(() => {
    if (selectvalue) {
      const updatedOptions = transformOptions(selectvalue);
      setOptions(updatedOptions);
    }
  }, [selectvalue]);

  React.useEffect(() => {
    if (prevSelectedValue) {
      setSelectedvalue(prevSelectedValue);
    }
  }, [prevSelectedValue]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const find = options.find((option) => option.id === value);
    setSelectedvalue(find);
    selectHandleChange(find);
  };

  return (
    <div>
      <FormControl
        sx={{ m: 1, width: 300, mt: 3 }}
        style={{ width: props.width ? props.width : "100%", marginTop: "17px", marginLeft: "3px" }}
        className={classes.selectMenu}
      >
        <Select
          //multiple
          disabled={props.disabled}
          displayEmpty
          value={selectedvalue?.id}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (!selectedvalue?.id || (Array.isArray(selected) && selected.length === 0) || props.fileObj === null) {
              return <em className={classes.placeholdertext}>{pals || "Choose one option"}</em>;
            }

            return Array.isArray(selected) ? selected.join(",") : selectedvalue?.displayName;
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                // bgcolor: 'pink',
                "& .MuiMenuItem-root": {
                  // padding: 2,
                  background: "transparent !important",
                  fontWeight: "500 !important",
                },
                "& .Mui-selected:hover": {
                  background: "transparent !important",
                  border: "none !important",
                },
                "& .Mui-selected": {
                  background: "transparent !important",
                },
                "& .MuiMenuItem-root:hover": {
                  background: "transparent !important",
                  border: "none !important",
                },
              },
            },
          }}
          inputProps={{
            "aria-label": "Without label",
            classes: { notchedOutline: classes.noBorder, icon: classes.icon },
          }}
          className={
            selectedvalue?.id ? classes.selectText : props.highlight ? classes.selectHighlight : classes.selectText1
          }
        >
          {options?.map((option, index) => (
            <MenuItem key={option.id} value={option.id}>
              {option.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
