import React, { useEffect, useState } from "react";
import { Alert, IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import auth from "../redux/actions/auth";
import { addDays } from "../services/globalService";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  stack: {
    "& > *:not(style)": {
      marginTop: "2px !important",
    },
  },
});

const AnnouncementNotification = ({ showNotification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const [showTrialExpiryNotification, setShowTrialExpiryNotification] = useState(true);
  const [showMaintenanceNotification, setShowMaintenanceNotification] = useState(true);
  const [maintenanceMessage, setMaintenanceMessage] = useState(null);

  const trialExpiryApproaching = localStorage.getItem("trialExpiryApproaching");
  const expiryDate = localStorage.getItem("expiryDate");
  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  const currentDate = new Date();
  const expiryDateObj = new Date(expiryDate);

  const classes = useStyles();

  useEffect(() => {
    if (currentDate > expiryDateObj && userDetails.role !== "Candidate") {
      dispatch(auth.checkSubscriptionStatusInvite());
    }
    // dispatch(auth.getMaintenanceDetails());
  }, []);

  // console.log(authState?.getMaintenanceDetailsSuccess?.data?.startDate, "start");
  // console.log(currentDate.toISOString(), "start11");

  useEffect(() => {
    let maintenanceData = authState?.getMaintenanceDetailsSuccess?.data;
    if (maintenanceData) {
      sessionStorage.setItem("maintenanceData", JSON.stringify(maintenanceData));
    } else if (sessionStorage.getItem("maintenanceData")) {
      maintenanceData = JSON.parse(sessionStorage.getItem("maintenanceData"));
    }

    if (maintenanceData) {
      const startDate = new Date(maintenanceData.startDate);
      const endDate = new Date(maintenanceData.endDate);
      // this variable will use in message
      let date = startDate.toDateString(); // do not delete this line
      let startTime = moment(maintenanceData.startDate).format("HH:mm A"); // do not delete this line
      let endTime = moment(maintenanceData.endDate).format("HH:mm A"); // do not delete this line
      if (endDate > currentDate && endDate <= addDays(currentDate, 3)) {
        const message =
          userDetails.role === "Candidate" ? maintenanceData?.candidate?.message : maintenanceData?.user?.message;
        // eslint-disable-next-line
        setMaintenanceMessage(eval(message));
      }
      console.log(`Maintenance will start on ${date} at ${startTime} and end at ${endTime}`);
    }
  }, [authState?.getMaintenanceDetailsSuccess]);

  const handleCloseTrialExpiry = () => {
    setShowTrialExpiryNotification(false);
  };

  const handleCloseMaintenance = () => {
    setShowMaintenanceNotification(false);
  };

  return (
    <Stack className={classes.stack} sx={{ width: "100%", marginTop: "80px" }} spacing={2}>
      {showNotification && showTrialExpiryNotification && trialExpiryApproaching === "true" && (
        <Alert
          sx={{ bgcolor: "#FF941C", padding: "0px 16px" }}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleCloseTrialExpiry}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          variant="filled"
          severity="warning"
          style={{ color: "black" }}
        >
          Your trial {currentDate > expiryDateObj ? "expired" : "is expiring"} on{" "}
          {moment(expiryDate).format("ddd MMM DD, YYYY")}.
          {userDetails.role === "Recruiter" && userDetails.roleSubType === "TeamAdmin" ? (
            <>
              <span> Please upgrade for uninterrupted service.</span>
              <a
                href="/accountSettings"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/accountSettings", {
                    state: { selectedSection: "billing" },
                  });
                }}
                style={{ marginLeft: "10px", color: "black" }}
              >
                Click here to Upgrade
              </a>{" "}
            </>
          ) : (
            <span> Please contact Team Admin to upgrade.</span>
          )}
        </Alert>
      )}
      {showMaintenanceNotification && maintenanceMessage && (
        <Alert
          sx={{ bgcolor: "#FF941C", padding: "0px 16px" }}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleCloseMaintenance}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          variant="filled"
          severity="info"
          style={{ color: "black" }}
        >
          {maintenanceMessage}
        </Alert>
      )}
    </Stack>
  );
};

export default AnnouncementNotification;
