import React, { useEffect, useState } from "react";
import { useRoutes, Navigate, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import RolesList from "./pages/Roles/Rolelist";
// import CreateNewRole from "./pages/Roles/Createnewrole";
import RecruiterView from "./pages/recruiter/Recruiterview";
import Login from "./pages/login/Login";
import CandidateLogin from "./pages/login/CandidateLogin";
import CandidateList from "./pages/candidate/CandidateList";
import AddNewCandidates from "./pages/candidate/AddNewCandidate";
// import CandidateView from "./pages/candidate/CandidateView";
import NewCandidateView from "./pages/candidate/NewCandidateView";
import InviteList from "./pages/candidate/InviteList";
import RecruiterList from "./pages/recruiter/RecruiterList";
import AddRecruiters from "./pages/recruiter/AddRecruiter";
import InviteCandidateView from "./pages/candidate/InviteCandidateView";
import ColoredLinearProgress from "./components/controls/coloredLoadingProgress";
import { useDispatch, useSelector } from "react-redux";
import AboutPage from "./pages/legalpages/AboutUs";
import PrivacyPolicy from "./pages/legalpages/privacy";
import TermsConditions from "./pages/legalpages/Terms&condidtions";
import authAction from "./redux/actions/auth";
import CandidateDashboard from "./pages/candidate/Candidatelanding";
import ErrorSnackbar from "./components/controls/Errormessage";
// import TransitionScreen from "./components/TransitionScreen/TransitionScreen";
import QuizQuestions from "./pages/quiz/QuizQuestions/QuizQuestions";
import CodingQuestions from "./pages/quiz/CodingQuestions/CodingQuestions";
// import ScoreScreen from './pages/quiz/ScoreScreen/ScoreScreen'
// import LNextSection from "./pages/quiz/LNextSection";
import OpeningScreen from "./pages/quiz/OpeningScreen/OpeningScreen";
import ProfileQuestions from "./pages/quiz/ProfileQuestions";
// import TimeOutCaptureScreen from "./pages/TimeOutCaptureScreen";
// import NewAddRole from "./pages/Roles/AddRole/NewAddrole";
// import NewRoleView from "./pages/Roles/AddRole/NewRoleView";
import InviteCandidate from "./pages/candidate/Invited/InviteCandidate";
import InviteSuccess from "./pages/candidate/Invited/InviteSuccess";
import auth from "./redux/actions/auth";
import quiz from "./redux/actions/quiz";
// import chart from "./redux/actions/chart";
import ForgotPassword from "./pages/login/ForgotPassword";
import LoginRedirect from "./pages/login/LoginRedirect";
import PageNotFound from "./components/PageNotFound";
import ResetPassword from "./pages/login/ResetPassword";
import SignUp from "./pages/login/SignUp";
import AcceptInvitation from "./pages/login/AcceptInvitation";
import ValidateUser from "./pages/login/ValidateUser";
import { getDomainDetails, addDays, isSameDay } from "../src/services/globalService";
import AccountSettings from "./pages/manageAccounts/AccountSettings";
// import ChangePassword from "./pages/login/ChangePassword";
// import ChartContent from "./pages/manageAccounts/analytics/ChartContent";
import DocumentationContent from "./pages/documentation/DocumentationContent";
import BookADemo from "./pages/bookademo/BookADemo";
import EditAndAddNewCandidate from "./pages/candidate/EditAndAddNewCandidate";
import ImportBulkResumes from "./pages/candidate/ImportBulkResumes";
import CreateJobRole from "./pages/Roles/AddRole/CreateJobRole";
import RoleView from "./pages/Roles/AddRole/RoleView";
import ContactUs from "./pages/contact-us-form/ContactUs";

// import AddNewCandidatesTemp from "./pages/candidate/AddNewCandidate";
const AppRouter = (props) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const quizState = useSelector((state) => state.quiz);
  const chartState = useSelector((state) => state.chart);
  let apiError = useSelector((state) => state?.auth?.apiError);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  let navigate = useNavigate();
  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  const location = useLocation();
  useEffect(() => {
    if (apiError) {
      if (apiError?.response?.status === 401 || apiError?.status === 401) {
        dispatch(auth.clearState());
        localStorage.removeItem("token1");
        localStorage.removeItem("userid");
        localStorage.removeItem("user_details");
        localStorage.removeItem("uid");
        localStorage.removeItem("logo");
        localStorage.removeItem("nextMaintenanceCheck");
        dispatch(quiz.clearState());
        navigate("/login");
      }
      if (apiError?.response?.data?.message === "Authentication failed. Invalid user or password.") {
        setToastMessage("Authentication failed. Invalid User ID or Password.");
      } else if (apiError?.response?.data?.message === "Authentication failed. User is not active.") {
        setToastMessage(apiError?.response?.data?.message);
      } else if (apiError === "invalid signature") {
        setToastMessage("Authentication expired, please login again.");
      } else if (apiError?.response?.data?.errors?.[0]?.msg) {
        if (apiError?.response?.data?.errors?.[0]?.msg === "roleSubType must not be empty.") {
          setToastMessage("Role Type must not be empty.");
        } else if (apiError?.response?.data?.errors?.[0]?.msg === "type must not be empty.") {
          setToastMessage("Role Type must not be empty.");
        } else if (apiError?.response?.data?.errors?.[0]?.msg === "name must not be empty.") {
          setToastMessage("Role Title must not be empty.");
        } else {
          setToastMessage(apiError?.response?.data?.errors?.[0]?.msg);
        }
      } else if (apiError?.message) {
        setToastMessage(apiError?.message);
      } else if (typeof apiError == "string") {
        setToastMessage(apiError);
      } else {
        setToastMessage("Unexpected error, Please try again.");
      }
      setOpenToast(true);
    }
    // eslint-disable-next-line
  }, [apiError]);

  // useEffect for maintenance details
  useEffect(() => {
    let token = localStorage.getItem("token1");
    token = token?.split(" ");
    // console.log("useEffect for maintenance details");
    const fetchMaintenanceDetails = () => {
      // console.log("fetchMaintenanceDetails function called");
      if (
        !excludedLocations.some((excludedPath) => location.pathname.startsWith(excludedPath)) &&
        token &&
        token[0] == "Bearer" &&
        token.length == 2
      ) {
        dispatch(auth.getMaintenanceDetails());
        const nextFetchDate = addDays(new Date(), 1);
        localStorage.setItem("nextMaintenanceCheck", nextFetchDate.toISOString());
      }
    };

    const checkAndFetch = () => {
      // console.log("checkAndFetch function called");
      const nextFetchDate = localStorage.getItem("nextMaintenanceCheck");
      const sessionMaintenanceData = sessionStorage.getItem("maintenanceData");
      const today = new Date().toISOString();

      // console.log("checkAndFetch", nextFetchDate, today);
      // console.log(isSameDay(new Date(nextFetchDate), today), "isSameDay");

      if (!nextFetchDate || isSameDay(new Date(nextFetchDate), today) || !sessionMaintenanceData) {
        fetchMaintenanceDetails();
        // console.log("calling fetchMaintenanceDetails");
      }
    };

    // Check immediately on load
    checkAndFetch();

    // Set interval to check every hour (3600000 ms)
    // const intervalId = setInterval(checkAndFetch, 10000);

    // return () => clearInterval(intervalId);
  }, [location]);

  // paths that should not be saved in local storage
  const excludedLocations = [
    "/login",
    "/signup",
    "/forgotPassword",
    "/resetPassword",
    "/createPassword",
    "/createUserPassword",
    "/acceptInvitation",
    "/validateUser",
    "/pageNotFound",
    "/bookademo",
    "/forms/contactus",
    "/invite/", // CandidateLogin
  ];

  if (
    !excludedLocations.some((excludedPath) => location.pathname.toLowerCase().startsWith(excludedPath.toLowerCase()))
  ) {
    localStorage.setItem("lastLocation", location.pathname);
  }

  useEffect(() => {
    if (
      location.pathname.includes("signup") ||
      location.pathname.includes("forgotPassword") ||
      location.pathname.includes("resetPassword") ||
      location.pathname.includes("createPassword") ||
      location.pathname.includes("createUserPassword")
    ) {
      let domainName = getDomainDetails(window.location.href);

      if (domainName.subdomainFromUrl !== "app" && window.location.hostname !== "localhost") {
        window.location.replace(`https://app.${process.env.REACT_APP_FRONTEND_URL}${location.pathname}`);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/login") {
      // localStorage.clear()
      localStorage.removeItem("token1");
      localStorage.removeItem("userid");
      localStorage.removeItem("user_details");
      localStorage.removeItem("Authorization");
      localStorage.removeItem("logo");
      localStorage.removeItem("nextMaintenanceCheck");

      // Clear sessionStorage
      sessionStorage.clear();
    }
  }, [location.pathname]);

  useEffect(() => {
    let token = localStorage.getItem("token1");
    token = token?.split(" ");

    // if (Object.keys(authState.skillsConfig).length === 0 && userDetails && localStorage.getItem("token1")) {
    if (Object.keys(authState.skillsConfig).length === 0 && token && token[0] == "Bearer" && token.length == 2) {
      dispatch(auth.getConfigSkills());
    }
  }, []);

  function setOpenFunc() {
    setOpenToast(false);
    dispatch(authAction.apiError(""));
  }
  let routes = useRoutes([
    // { path: "/signup", element: <SignUp /> },
    { path: "/acceptInvitation/:token", element: <AcceptInvitation /> },
    { path: "/validateUser/:token", element: <ValidateUser /> },
    { path: "/", element: <Navigate to="/home" /> },
    { path: "/home", element: userDetails ? <Home /> : <Navigate to="/login" /> },
    { path: "/login", element: <Login /> },
    { path: "/invite/:inviteId", element: <CandidateLogin /> },
    { path: "/redirectToHome/:userData", element: <LoginRedirect /> },
    { path: "/rolelist", element: userDetails ? <RolesList /> : <Navigate to="/login" /> },
    { path: "/createnewrole", element: userDetails ? <PageNotFound /> : <Navigate to="/login" /> },
    { path: "/addrolesjob", element: userDetails ? <PageNotFound /> : <Navigate to="/login" /> },
    { path: "/CreateJobRole", element: userDetails ? <CreateJobRole /> : <Navigate to="/login" /> },
    { path: "/editJobRole/:id", element: userDetails ? <CreateJobRole /> : <Navigate to="/login" /> },
    // { path: "/roleview/:id", element: userDetails ? <NewRoleView /> : <Navigate to="/login" /> },
    { path: "/newRoleView/:id", element: userDetails ? <RoleView /> : <Navigate to="/login" /> },
    { path: "/editrole/:id", element: userDetails ? <PageNotFound /> : <Navigate to="/login" /> },
    { path: "/addrolestype", element: userDetails ? <PageNotFound /> : <Navigate to="/login" /> },
    { path: "/forgotPassword", element: <ForgotPassword /> },
    { path: "/resetPassword/:token", element: <ResetPassword activity="ResetPassword" /> },
    { path: "/createPassword/:token", element: <ResetPassword activity="CreatePassword" /> },
    { path: "/createUserPassword/:token", element: <ResetPassword activity="CreateUserPassword" /> },
    {
      path: "/userview/:id",
      element: userDetails ? (
        userDetails?.role === "Recruiter" && userDetails?.roleSubType === "Contributor" ? null : (
          <RecruiterView />
        )
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/userlist",
      element: userDetails ? (
        userDetails?.role === "Recruiter" && userDetails?.roleSubType === "Contributor" ? null : (
          <RecruiterList />
        )
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/adduser",
      element:
        userDetails?.role === "Recruiter" && userDetails?.roleSubType === "Contributor" ? null : <AddRecruiters />,
    },
    {
      path: "/edituser/:id",
      element:
        userDetails?.role === "Recruiter" && userDetails?.roleSubType === "Contributor" ? null : <AddRecruiters />,
    },
    {
      path: "/accountSettings",
      element: userDetails ? (
        userDetails?.role === "Recruiter" ? (
          <AccountSettings />
        ) : userDetails?.role === "Candidate" ? (
          <Navigate to="/candidatedashboard" />
        ) : (
          <Navigate to="/home" />
        )
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/documentation",
      element: userDetails?.role === "Recruiter" ? <DocumentationContent /> : <Navigate to="/login" />,
    },
    { path: "/candidatelist", element: userDetails ? <CandidateList /> : <Navigate to="/login" /> },
    { path: "/candidatedashboard", element: userDetails ? <CandidateDashboard /> : <Navigate to="/login" /> },
    { path: "/inviteaddcandidates", element: userDetails ? <InviteCandidate /> : <Navigate to="/login" /> },
    { path: "/invitesuccess", element: userDetails ? <InviteSuccess /> : <Navigate to="/login" /> },
    { path: "/addnewcandidate", element: userDetails ? <AddNewCandidates /> : <Navigate to="/login" /> },
    // { path: "/candidateview/:id", element: userDetails ? <CandidateView /> : <Navigate to="/login" /> },
    // { path: "/oldcandidateview/:id", element: userDetails ? <CandidateView /> : <Navigate to="/login" /> },
    { path: "/candidateview/:id", element: userDetails ? <NewCandidateView /> : <Navigate to="/login" /> },
    { path: "/editcandidate/:id", element: userDetails ? <AddNewCandidates /> : <Navigate to="/login" /> },
    { path: "/invitelist", element: userDetails ? <InviteList /> : <Navigate to="/login" /> },
    { path: "/invitecandidateview/:id", element: userDetails ? <InviteCandidateView /> : <Navigate to="/login" /> },
    { path: "/about", element: userDetails ? <AboutPage /> : <Navigate to="/login" /> },
    { path: "/terms&conditions", element: <TermsConditions /> },
    { path: "/privacypolicy", element: <PrivacyPolicy /> },

    // { path: "/GeneratingProfile", element: userDetails ? <TransitionScreen /> : <Navigate to="/login" /> },
    { path: "/QuizQuestions/:path/:qIndex", element: userDetails ? <QuizQuestions /> : <Navigate to="/login" /> },
    { path: "/CodingQuestions/:qIndex", element: userDetails ? <CodingQuestions /> : <Navigate to="/login" /> },
    // { path: "/ScoreScreen", element: userDetails ? <ScoreScreen /> : <Navigate to="/login" /> },
    // { path: "/GeneratingProfile", element: userDetails ? <TransitionScreen /> : <Navigate to="/login" /> },
    // { path: "/CalculatingScore", element: userDetails ? <TransitionScreen /> : <Navigate to="/login" /> },
    // { path: "/UpdatingDashboard", element: userDetails ? <TransitionScreen /> : <Navigate to="/login" /> },
    // { path: "/PresentationEvaluation", element: userDetails ? <TransitionScreen /> : <Navigate to="/login" /> },
    // { path: "/TechnicalEvaluation", element: userDetails ? <TransitionScreen /> : <Navigate to="/login" /> },
    // { path: "/CodingEvaluation", element: userDetails ? <TransitionScreen /> : <Navigate to="/login" /> },
    // { path: "/LNextSection", element: userDetails ? <LNextSection /> : <Navigate to="/login" /> },
    { path: "/L3CodingOpeningScreen", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/CodingSectionUnavailable", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    // { path: "/TimeOutCaptureScreen", element: userDetails ? <TimeOutCaptureScreen /> : <Navigate to="/login" /> },
    { path: "/OpeningScreen", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/L1OpeningScreen", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/L2OpeningScreen", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/L3ProfileOpeningScreen", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/L3CodingOpeningScreen", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/CodingSectionUnavailable", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/L3PresentationOpeningScreen", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/L3VideoBehavioralOpeningScreen", element: userDetails ? <OpeningScreen /> : <Navigate to="/login" /> },
    { path: "/ProfileQuestions", element: userDetails ? <ProfileQuestions /> : <Navigate to="/login" /> },
    // { path: "/ProfileOpeningScreen", element: userDetails ? <TransitionScreen /> : <Navigate to="/login" /> },
    { path: "/pageNotFound", element: <PageNotFound /> },
    // { path: "/chart", element: <ChartContent /> },
    { path: "/bookademo/:orgId/:token", element: <BookADemo /> },
    { path: "/forms/contactus", element: <ContactUs /> },
    {
      path: "/editandaddnewcandidate/:docId",
      element: userDetails ? <EditAndAddNewCandidate /> : <Navigate to="/login" />,
    },
    // { path: '/changePassword', element: <ChangePassword /> },
    { path: "/importBulkResumes", element: userDetails ? <ImportBulkResumes /> : <Navigate to="/login" /> },
    { path: "*", element: <Navigate to="/login" /> },
  ]);
  return (
    <>
      {routes}
      <div>
        {authState.isauthLoading ? (
          <div className={props.loader}>
            <ColoredLinearProgress />
          </div>
        ) : null}
        {quizState.isquizLoading ? (
          <div className={props.loader}>
            <ColoredLinearProgress />
          </div>
        ) : null}
        {roleState.isLoading || roleState.isroleLoading ? (
          <div className={props.loader}>
            <ColoredLinearProgress />
          </div>
        ) : null}
        {chartState.isauthLoading ? (
          <div className={props.loader}>
            <ColoredLinearProgress />
          </div>
        ) : null}
      </div>
      <ErrorSnackbar
        open={openToast}
        setOpen={() => setOpenFunc()}
        vertical={"bottom"}
        horizontal={"left"}
        severity="error"
        message={toastMessage}
      />
    </>
  );
};

export default AppRouter;
