import React from "react";
import Grid from "@mui/material/Grid";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  allMyToggleBtn: {
    width: "auto",
    padding: "6px 6px 6px 6px !important",
    color: "#2F2F2F !important",
    border: "1px solid #2F2F2F !important",
    textTransform: "capitalize !important",
    height: "40px",
  },
  toggleButtonContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
  },
  allMyToggleBtnSelected: {
    background: "#2F2F2F !important",
    color: "#FFFFFF !important",
    textTransform: "capitalize !important",
  },
});
export default function ToggleButtonAll(props) {
  const { handleAllAndMy, selectText, Alltotal, Mytotal } = props;
  const classes = useStyles();
  return (
    <Grid item display="flex">
      <ToggleButtonGroup color="standard" value={selectText} exclusive onChange={handleAllAndMy}>
        <ToggleButton
          classes={{ root: classes.allMyToggleBtn, selected: classes.allMyToggleBtnSelected }}
          value="all"
          selected={selectText === "all"}
        >
          <span className={classes.toggleButtonContent}>All ({Alltotal})</span>
        </ToggleButton>
        <ToggleButton
          classes={{ root: classes.allMyToggleBtn, selected: classes.allMyToggleBtnSelected }}
          value="my"
          selected={selectText === "my"}
        >
          <span className={classes.toggleButtonContent}>My ({Mytotal})</span>
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}
