import React from "react";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { theme } from "../../styles/theme";
import Chip from "@mui/material/Chip";
// import Stack from "@mui/material/Stack";
import BlockIcon from "@mui/icons-material/Block";

const useStyles = makeStyles({
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  roletext1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    color: " #787878",
    paddingTop: "10px",
  },
  leftSideContaint: {
    color: "#2F2F2F !important",
    fontStyle: "normal !important",
    fontWeight: "500  !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    marginTop: "8px !important",
  },
  leftSideContaint2: {
    color: "#2F2F2F !important",
    fontStyle: "normal !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
  },
});

const EducationCards = ({ education, skillsMaster }) => {
  const classes = useStyles();

  // color for degree
  const skillColors = {
    Doctorate: "rgba(127, 89, 155)",
    Masters: "rgba(204, 118, 80)",
    Bachelors: "rgba(67, 156, 144)",
    Diploma: "rgba(118, 169, 195)",
    Certificate: "rgba(204, 88, 88)",
    School: "#9E9E9E",
    Other: "rgba(153, 125, 104)",
  };

  // sort education by type
  function compareEducation(a, b) {
    return Object.keys(skillColors).indexOf(a.type) - Object.keys(skillColors).indexOf(b.type);
  }

  return education?.length ? (
    <>
      <Grid
        item
        xs={12}
        style={{
          marginTop: "25px",
          marginBottom: "25px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Education</Typography>
      </Grid>
      {education?.sort(compareEducation).map((position) => (
        <Box
          style={{
            // border: "solid 1px rgba(224, 224, 224, 1)",
            borderRadius: "5px",
            //   height: "515px",
            width: "100%",
            // padding: "20px",
            // margin: "20px",
            // marginLeft: "0px",
            marginBottom: "20px",
            // display: isGridVisible ? "none" : "block",
          }}
        >
          {/* <Grid container style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Grid item xs={1} ms={2} style={{ alignItems: "center" }}>
              <Typography className={classes.roletext1}>
                <Chip
                  sx={{ width: "100px" }}
                  label={position?.type}
                  style={{ backgroundColor: skillColors[position?.type] || "#9e9e9e", color: "#fff", margin: "3px" }}
                />
              </Typography>
            </Grid>
            <Grid item xs={11} ms={2} alignItems="center" style={{ marginTop: "10px" }}>
              <Typography className={classes.leftSideContaint1} sx={{ margin: "auto", marginLeft: "5%" }}>
                {position?.programName}: {position?.institution}, {position?.location}.{" "}
                {position?.year ? `(${position?.year})` : ""}
              </Typography>
            </Grid>
          </Grid> */}
          <div style={{ display: "flex", gap: "20px", width: "100%", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center", lineHeight: "20px" }}>
              {" "}
              <Typography className={classes.roletext1} style={{ paddingTop: "0px" }}>
                {/* {position?.type} */}
                <Chip
                  // size="100px"
                  // width="100%"
                  sx={{ width: "120px", fontSize: "16px" }}
                  label={position?.type}
                  style={{ backgroundColor: skillColors[position?.type] || "#9e9e9e", color: "#fff" }}
                />
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", lineHeight: "20px" }}>
              <Typography
                className={classes.leftSideContaint2}
                sx={{ margin: "auto", marginTopTop: "0px !important", width: "100%" }}
              >
                <span>
                  {position?.text} {position?.year ? `(${position?.year})` : ""}
                </span>
              </Typography>
            </div>
          </div>
        </Box>
      ))}
    </>
  ) : (
    <>
      <Grid
        item
        xs={12}
        style={{ marginTop: "25px", marginBottom: "25px", display: "flex", justifyContent: "space-between" }}
      >
        <Typography variant="h6">Education</Typography>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20px",
        }}
        className={classes.roletype}
      >
        <span>
          <BlockIcon />
        </span>{" "}
        <Typography sx={{ marginLeft: "5px", marginBottom: "13px" }} className={classes.roletype}>
          Education Not Available.
        </Typography>
      </div>
    </>
  );
};

export default EducationCards;
