import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../styles/theme";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none !important",
    color: "#000 !important",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
  text: {
    color: "#000 !important",
    fontFamily: theme.typography.link1.fontFamily,
    fontWeight: "700 !important",
  },
}));

const CustomBreadcrumbs = ({ links }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {links.map((link, index) => {
        if (index !== links.length - 1) {
          return (
            <Link color="inherit" to={link.path} key={index} className={classes.link}>
              {link.name}
            </Link>
          );
        } else {
          return (
            <Typography color="textPrimary" key={index} className={classes.text}>
              {link.name}
            </Typography>
          );
        }
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
