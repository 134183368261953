import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ImageModal from "./ImageModal";
import LinkIcon from "@mui/icons-material/Link";
import ScrollButton from "../../../components/controls/ScrollButton";
import { useEffect } from "react";

const useStyles = makeStyles({
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px auto",
  },
  img: {
    width: "60%",
    border: "2px solid #787878",
    borderRadius: "0.2rem",
    cursor: "zoom-in",
  },
  tipContainer: {
    background: "#f3f0ff",
    display: "flex",
    borderRadius: "0.2rem",
  },
  tip: {
    lineHeight: "2rem !important",
  },
  orderList: {
    lineHeight: "2rem !important",
  },
  unorderList: {
    lineHeight: "2rem !important",
  },
  anchor: {
    textDecoration: "none",
    color: "inherit",
  },
});
const GettingStarted = () => {
  const classes = useStyles();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  // const newRole = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/newRole.png";
  // const requiredSkill = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/requiredSkill.png";
  // const nameNotes = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/name&Note.png";
  // const roleSummary = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/roleSummary.png";
  // const roleDetail = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/roleDetails.png";
  const newCandidate = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/newCandidate.png";
  const createNewInvite =
    "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/createNewInvite.png";
  const testRequirments =
    "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/testRequirments.png";
  const candidateSelection =
    "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/candidateSelection.png";
  const inviteInternalNotes =
    "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/inviteInternalNotes.png";
  const inviteSummary = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/inviteSummary.png";
  const inviteList = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/inviteListPage.png";
  const assesmentInvite =
    "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/assesmentInvite.png";
  const summaryLink = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/summaryLink.png";
  const sampleSummary = "https://yts-org-assets.s3.amazonaws.com/documentation/gettingStarted/img/sampleSummary.png";

  const roleDetails = "https://yts-org-assets.s3.amazonaws.com/RoleDetails.png";
  const roleSummary = "https://yts-org-assets.s3.amazonaws.com/RoleSummary.png";
  const sectionsIncludedInTest = "https://yts-org-assets.s3.amazonaws.com/SectionsIncludedInTest.png";
  const codingLanguagesAndTechnicalSkills =
    "https://yts-org-assets.s3.amazonaws.com/CodingLanguagesAndTechnicalSkills.png";
  const roleTitle = "https://yts-org-assets.s3.amazonaws.com/RoleTitle.png";
  const createNewRole = "https://yts-org-assets.s3.amazonaws.com/CreateNewRole.png";

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
    setSelectedImage("");
  };

  const handleScroll = (event, id) => {
    event.preventDefault();
    const scroll = document.getElementById(id);
    if (scroll) {
      const offset = 100;
      const scrollToPosition = scroll.offsetTop - offset;
      window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash) {
        const scroll = document.getElementById(hash);
        if (scroll) {
          const offset = 100;
          const scrollToPosition = scroll.offsetTop - offset;
          window.scrollTo({ top: scrollToPosition, behavior: "smooth" });

          // Remove the hash from the URL after scrolling
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      }
    };

    // Listen for hash changes
    window.addEventListener("hashchange", handleHashChange);

    // Scroll to section if hash is present on initial load
    handleHashChange();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <>
      <Grid container width="80%">
        <Grid item width="100%">
          <Typography variant="h5" sx={{ mt: 6 }}>
            Getting Started
          </Typography>
          <Typography sx={{ mt: 3 }}>
            TechScore's AI engine makes assessing a <b>candidate</b> for a <b>job role</b> effortless by handling all
            the complexity behind the scenes. Get started with TechScore in 5 easy steps:
          </Typography>
          <Grid xs={12}>
            <ol className={classes.orderList}>
              <li>
                <a className={classes.anchor} href="#createRole" onClick={(event) => handleScroll(event, "createRole")}>
                  Create a <b>job role</b>. <LinkIcon sx={{ mb: "-6px" }} color="primary" />
                </a>
              </li>
              <li>
                <a
                  className={classes.anchor}
                  href="#addCandidate"
                  onClick={(event) => handleScroll(event, "addCandidate")}
                >
                  Add a <b>Candidate</b>. <LinkIcon sx={{ mb: "-6px" }} color="primary" />
                </a>
              </li>
              <li>
                <a className={classes.anchor} href="#sendInvite" onClick={(event) => handleScroll(event, "sendInvite")}>
                  Send an <b>assessment invite</b> to the <b>candidate</b> for the <b>job role</b>.{" "}
                  <LinkIcon sx={{ mb: "-6px" }} color="primary" />
                </a>
              </li>
              <li>
                <a
                  className={classes.anchor}
                  href="#completedInvite"
                  onClick={(event) => handleScroll(event, "completedInvite")}
                >
                  The candidate completes the comprehensive <b>assessment invite</b>.{" "}
                  <LinkIcon sx={{ mb: "-6px" }} color="primary" />
                </a>
              </li>
              <li>
                <a
                  className={classes.anchor}
                  href="#downloadReport"
                  onClick={(event) => handleScroll(event, "downloadReport")}
                >
                  Download the <b>candidate evaluation report</b> and share it with your recruitment team.{" "}
                  <LinkIcon sx={{ mb: "-6px" }} color="primary" />
                </a>
              </li>
            </ol>
          </Grid>
          <Grid>
            <Typography>Repeat these steps, and let the magic happen.</Typography>
          </Grid>
        </Grid>
        <Grid item width="100%">
          <Typography id="createRole" variant="h5" sx={{ mt: 4 }}>
            1. Create a Role
          </Typography>
          <Grid>
            <Typography sx={{ mt: 1 }}>
              You need a <b>job role</b> to comprehensively assess a candidate against a standard benchmark.
            </Typography>
            <ul className={classes.unorderList}>
              <li>
                Navigate to the <b>Roles</b> page.
              </li>
              <li>
                Click on <b>Create Role</b> to navigate to <b>Create a New Role</b> page.
              </li>
              <li>
                In the text area, <b>Paste a Job Description,</b> or from the <b>Role Description</b> dropdown select a
                readily available job description from over thirty predefined technical roles.
              </li>
              <Grid className={classes.tipContainer} sx={{ p: 1, my: 1 }}>
                <TipsAndUpdatesIcon sx={{ m: 1, color: "#8270db" }} />
                <Typography className={classes.tip}>
                  <b>Pro Tip:</b> If you don’t have a job description handy, choose a technical role from the{" "}
                  <b>Role Description</b> dropdown that is the closest match to your role. Skim through this
                  standardized job description and edit any requirements or skills as per your need. Our AI engine will
                  parse the job description to determine all the required attributes.
                </Typography>
              </Grid>
              <li>
                Click on the <b>Generate Role</b> button to let our AI pull out all the relevant information from the
                role and fill out the form for you.
              </li>
              <div className={classes.imgContainer}>
                <img
                  src={createNewRole}
                  alt="createNewRole"
                  className={classes.img}
                  onClick={() => handleImageClick(createNewRole)}
                ></img>
              </div>
              <li>
                As our AI fills in the form, validate the role attributes and edit if required. <b>Role Title</b> needs
                to be unique. If its not unique an error message will be presented. You will need to edit so that the
                title is unique.
              </li>
              <Grid className={classes.tipContainer} sx={{ p: 1, my: 1 }}>
                <TipsAndUpdatesIcon sx={{ m: 1, color: "#8270db" }} />
                <Typography className={classes.tip}>
                  <b>Pro Tip:</b> Be descriptive in naming the job role. Here are some examples:{" "}
                  <b>Database Admin (DBA) Q2 2024, HP Database Administrator Q2’24</b>
                </Typography>
              </Grid>

              <div className={classes.imgContainer}>
                <img
                  src={roleTitle}
                  alt="roleTitle"
                  className={classes.img}
                  onClick={() => handleImageClick(roleTitle)}
                ></img>
              </div>
              <Grid className={classes.tipContainer} sx={{ p: 1, my: 1 }}>
                <TipsAndUpdatesIcon sx={{ m: 1, color: "#8270db" }} />
                <Typography className={classes.tip}>
                  <b>Pro Tip:</b> Choose <b>Selectivity</b> value to determine how stringent you want to keep your
                  selection criteria. A <b>Selectivity</b> of 20% means that the candidate those who cross the target
                  score will belong to the global pool of top 20% candidates.
                </Typography>
              </Grid>
              <li>
                Next verify the <b>Coding Languages</b> selection. AI will pick the coding languages found in the job
                description. You can choose more than one coding language. This is the set of coding languages you make
                available to the candidate during the coding test. Candidate will be able to choose any one of these
                languages while solving the coding problems. If no <b>Coding Languages</b> are selected then test will
                not contain a <b>Coding Section.</b>
              </li>
              <div className={classes.imgContainer}>
                <img
                  src={codingLanguagesAndTechnicalSkills}
                  alt="codingLanguagesAndTechnicalSkills"
                  className={classes.img}
                  onClick={() => handleImageClick(codingLanguagesAndTechnicalSkills)}
                ></img>
              </div>

              <li>
                In the <b>Technical Skills</b> section, you will find recommendation for skills to be tested in the{" "}
                <b>assessment invite</b> based on job description. The left column contains up to 5{" "}
                <b>Selected Skills</b> with weightage for each skill. Candidate’s profile and technical skills
                evaluation will be based on the skills listed here. The right column contains{" "}
                <b>Other Relevant Skills.</b> You can unselect skills from the left column and select skills from the
                right column, if you want to make changes to AI recommendations. Weightage must add up to 100%.
              </li>
              <Grid className={classes.tipContainer} sx={{ p: 1, my: 1 }}>
                <TipsAndUpdatesIcon sx={{ m: 1, color: "#8270db" }} />
                <Typography className={classes.tip}>
                  <b>Pro Tip:</b> We offer about 50 different technical skills but the two columns only show the skills
                  that were present in the job description and are supported by <b>TechScore.</b> You can see the
                  complete list of skills that we support by clicking on{" "}
                  <b>Click here to see the complete skill set.</b> You may select more skills from this list and add to
                  Other Relevant Skills before making a final decision.
                </Typography>
              </Grid>

              <li>
                Scroll down to the <b>Sections Included in the Test</b> section. Here you decide which sections will go
                into your assessment invite for this job role. The <b>Profile Section</b> is mandatory and requires only
                a resume. At least one of the <b>Coding Section</b> and the <b>Technical Section</b> is also mandatory.
                We recommend keeping <b>all four sections selected</b> for the most comprehensive assessment and report.
              </li>
              <div className={classes.imgContainer}>
                <img
                  src={sectionsIncludedInTest}
                  alt="sectionsIncludedInTest"
                  className={classes.img}
                  onClick={() => handleImageClick(sectionsIncludedInTest)}
                ></img>
              </div>
              <Grid className={classes.tipContainer} sx={{ p: 1, my: 1 }}>
                <TipsAndUpdatesIcon sx={{ m: 1, color: "#8270db" }} />
                <Typography className={classes.tip}>
                  <b>Pro Tip:</b> Turn on Override permitted if you want to change testing sections at the time of
                  sending an invite.
                </Typography>
              </Grid>
              <li>
                Add an optional note for any internal purposes in the <b>Internal Notes</b> text area. Only your team
                can see this note.
              </li>
              <li>
                Click <b>Continue to Review</b> to preview the <b>Role Summary</b> before publishing it.
              </li>

              <div className={classes.imgContainer}>
                <img
                  src={roleSummary}
                  alt="roleSummary"
                  className={classes.img}
                  onClick={() => handleImageClick(roleSummary)}
                ></img>
              </div>
              <li>
                You can click on <b>Save Role</b> to save the role in <b>Draft</b> mode, or you can click on{" "}
                <b>Publish Role</b> to publish and activate the role. You can only send assessment invites for active
                roles. Roles saved as drafts can be edited but once published cannot be edited.{" "}
              </li>
              <li>
                Once your job role is published and active it is ready for inviting candidates for a comprehensive
                assessment.
              </li>
              <li>
                Our AI engine calculates the standardized <b>Target Score</b> for this role, which sets the{" "}
                <b>benchmark</b> for a candidate’s performance. Candidates, who achieve the <b>Target Score</b> in
                TechScore’s comprehensive <b>assessment invites</b> are highly likely to perform in the targeted job
                role.
              </li>
              <div className={classes.imgContainer}>
                <img
                  src={roleDetails}
                  alt="roleDetails"
                  className={classes.img}
                  onClick={() => handleImageClick(roleDetails)}
                ></img>
              </div>
            </ul>
          </Grid>
        </Grid>
      </Grid>
      <Grid container width="80%">
        <Grid item width="100%">
          <Typography id="addCandidate" variant="h5" sx={{ mt: 3 }}>
            2. Add a Candidate
          </Typography>
          <Typography sx={{ mt: 1 }}>
            You need to add a candidate before you can invite them to assessments for the job roles.
          </Typography>
          <ul className={classes.unorderList}>
            <li>
              Navigate to the <b>Candidates</b> page.
            </li>
            <li>
              Click on the <b>Add Candidate</b> button to navigate to the <b>New Candidate</b> page.
            </li>
            <li>
              Upload a resume by clicking on the <b>Candidate Resume</b> box. Our AI engine will fetch and fill in all
              the information for you. You may correct any field if required.
            </li>
            <div className={classes.imgContainer}>
              <img
                src={newCandidate}
                alt="newCandidate"
                className={classes.img}
                onClick={() => handleImageClick(newCandidate)}
              ></img>
            </div>
            <li>
              The Technical Skills table shows all the skills captured from the resume. We support over 50 technical
              skills. Scroll down the table to update or add Month Used and Last Used values.
            </li>
            <Grid className={classes.tipContainer} sx={{ p: 1, my: 1 }}>
              <TipsAndUpdatesIcon sx={{ m: 1, color: "#8270db" }} />
              <Typography className={classes.tip}>
                <b>Pro Tip:</b> Filling in all the fields and maintaining a complete skill record will ensure you have a
                good candidate pool to refer to in the future.
              </Typography>
            </Grid>
            <li>
              Add any optional <b>Internal Notes</b> for your team’s reference and click on the <b>Add Candidate</b>{" "}
              button to save the candidate.
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container width="80%">
        <Grid item width="100%">
          <Typography id="sendInvite" variant="h5" sx={{ mt: 3 }}>
            3. Send an Assessment Invite
          </Typography>
          <Typography sx={{ mt: 1 }}>
            If you have created a <b>job role</b> and added a <b>candidate</b> on TechScore, you are ready to{" "}
            <b>invite</b> the candidate for a comprehensive assessment.
          </Typography>
          <ul className={classes.unorderList}>
            <li>
              Navigate to the <b>Invites</b> page.
            </li>
            <li>
              Click on the <b>Invite Candidate(s)</b> button to go to the <b>Create New Invite</b> page.
            </li>
            <li>
              From the <b>Roles</b> table select a role by clicking anywhere on the row. To see role details click on
              the 3-dots icon after the last column.
            </li>
            <div className={classes.imgContainer}>
              <img
                src={createNewInvite}
                alt="createNewInvite"
                className={classes.img}
                onClick={() => handleImageClick(createNewInvite)}
              ></img>
            </div>
            <li>
              Click <b>Continue</b> to jump to the <b>Test Requirements</b> section.
            </li>
            <li>
              Make any changes if necessary. The default <b>Test Expiration Date</b> is set to 7 days from now.
            </li>
            <Grid className={classes.tipContainer} sx={{ p: 1, my: 1 }}>
              <TipsAndUpdatesIcon sx={{ m: 1, color: "#8270db" }} />
              <Typography className={classes.tip}>
                <b>Pro Tip:</b> Selecting all four sections will yield the most dependable <b>Invite Report</b>.
              </Typography>
            </Grid>
            <div className={classes.imgContainer}>
              <img
                src={testRequirments}
                alt="testRequirments"
                className={classes.img}
                onClick={() => handleImageClick(testRequirments)}
              ></img>
            </div>
            <li>
              Click <b>Continue</b> to move to the <b>Candidate Selection</b> section.
            </li>
            <li>
              Click on <b>Candidate</b> rows to add them to the <b>Invite List</b>. You can invite up to 10 candidates
              in one batch of invites, subject to the available invite credits.
            </li>
            <div className={classes.imgContainer}>
              <img
                src={candidateSelection}
                alt="candidateSelection"
                className={classes.img}
                onClick={() => handleImageClick(candidateSelection)}
              ></img>
            </div>
            <li>
              Click <b>Continue</b> to move to the <b>Invite & Internal Notes</b> section. Fill in any optional{" "}
              <b>Message</b> to the candidate which will be added to the <b>Invite</b> email. Add any{" "}
              <b>Internal Notes</b> for your team.
            </li>
            <div className={classes.imgContainer}>
              <img
                src={inviteInternalNotes}
                alt="inviteInternalNotes"
                className={classes.img}
                onClick={() => handleImageClick(inviteInternalNotes)}
              ></img>
            </div>
            <li>
              Click <b>Continue to Review</b> to verify your selections. Finally, click the <b>Send Invite to List</b>{" "}
              button to invite all the candidates for the assessment.
            </li>
            <div className={classes.imgContainer}>
              <img
                src={inviteSummary}
                alt="inviteSummary"
                className={classes.img}
                onClick={() => handleImageClick(inviteSummary)}
              ></img>
            </div>
            <li>
              Candidates receive <b>invites</b> in their email inbox with a link and an access code. We also send them
              an SMS, if a valid US phone number was provided when adding the candidate. Emails are sent from{" "}
              <span style={{ color: "blue" }}>no.reply@techscore.ai</span>.
            </li>
            <li>
              Click on <b>Back to All Invites</b>. You can see the sent invites on the Invites page.
            </li>
            <div className={classes.imgContainer}>
              <img
                src={inviteList}
                alt="inviteList"
                className={classes.img}
                onClick={() => handleImageClick(inviteList)}
              ></img>
            </div>
            <li>
              Now you can click on any invite row to monitor its progress, see the assessment result, send reminders, or
              extend the expiry date.
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container width="80%">
        <Grid item width="100%">
          <Typography id="completedInvite" variant="h5" sx={{ mt: 3 }}>
            4. Candidate completes Assessment Invite
          </Typography>
          <Typography sx={{ mt: 1 }}>
            Just sit back and relax while the candidate completes the invite. You can monitor their progress and send
            them reminders if necessary. To monitor candidate activity, navigate to the <b>Invites</b> page and then
            click on the invite row on the table.
          </Typography>
          <div className={classes.imgContainer}>
            <img
              src={assesmentInvite}
              alt="assesmentInvite"
              className={classes.img}
              onClick={() => handleImageClick(assesmentInvite)}
            ></img>
          </div>
        </Grid>
      </Grid>
      <Grid container width="80%">
        <Grid item width="100%">
          <Typography id="downloadReport" variant="h5" sx={{ mt: 3 }}>
            5. Download Candidate Evaluation Report
          </Typography>
          <Typography sx={{ mt: 1 }}>
            After the candidate completes the assessment you will see all sections marked with the <b>Last Updated</b>{" "}
            date along with the red, yellow, and green dots against each of the section attributes depending on their
            performance. Now you can download the PDF report or share a report link with your team members and hiring
            managers.
          </Typography>
          <ul className={classes.unorderList}>
            <li>
              Scroll to the bottom of the invite page. On the right, you have various options for the invite report. You
              can open or copy a shareable link for the report or download a PDF of the one-page summary report.
            </li>
            <div className={classes.imgContainer}>
              <img
                src={summaryLink}
                alt="summaryLink"
                className={classes.img}
                onClick={() => handleImageClick(summaryLink)}
              ></img>
            </div>
            <li>Enterprise customers can also download and share custom configured reports.</li>
            <div style={{ height: "40vh", width: "25vw" }} className={classes.imgContainer}>
              <img
                src={sampleSummary}
                alt="sampleSummary"
                className={classes.img}
                onClick={() => handleImageClick(sampleSummary)}
              ></img>
            </div>
            <Typography sx={{ display: "flex", justifyContent: "center" }}>A sample summary report</Typography>
          </ul>
        </Grid>
      </Grid>
      <ScrollButton />
      <ImageModal open={openImageModal} imageUrl={selectedImage} onClose={handleCloseImageModal} />
    </>
  );
};

export default GettingStarted;
