import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { Typography, TextField, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../styles/theme";
import Divider from "@mui/material/Divider";
import Button from "../../../components/controls/Button";
import { Formik, Form } from "formik";

import { useDispatch, useSelector } from "react-redux";
import auth from "../../../redux/actions/auth";
import SimpleSnackbar from "../../../components/controls/ToastMessage";
import { updateUserProfileSchema } from "../../../schemas/userProfile";
import ResetPassword from "./ResetPassword";

const useStyles = makeStyles({
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    "& label": {
      color: "#000000",
      marginBottom: ".5rem",
      fontWeight: 500,
    },
  },
  fieldWrapper: {
    display: "flex",
    gap: "1rem",
    marginBottom: "1.5rem",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "10px",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    // fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "right",
    // paddingTop: '20px',
    // paddingBottom: '20px'
  },
  submit: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

function ProfileContent() {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  // const [isFirstNameChanged, setIsFirstNameChanged] = useState(false);
  // const [isLastNameChanged, setIsLastNameChanged] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  // const [snacktext, setsnacktext] = useState('')
  // const [open, setOpen] = useState(false)
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const changePasswordSuccess = useSelector((state) => state.auth.changePasswordSuccess);
  const updateUserDetailsSuccess = useSelector((state) => state.auth.updateUserDetailsSuccess);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const initialValues = useMemo(() => {
    return {
      firstName: authState?.managementDetailsSuccess?.data?.user?.firstName || "",
      lastName: authState?.managementDetailsSuccess?.data?.user?.lastName || "",
    };
  }, [authState?.managementDetailsSuccess]);

  const handleSubmitSave = (values, { resetForm }) => {
    const { firstName, lastName } = values;
    const payload = {
      firstName,
      lastName,
    };
    dispatch(auth.updateUserDetails(payload));
    // ...
  };

  const handleResetPasswordSuccess = () => {
    dispatch(auth.resetChangePasswordSuccess());
  };

  const handleResetUserDetailsSuccess = () => {
    dispatch(auth.resetUserDetailsSuccess());
  };

  useEffect(() => {
    if (changePasswordSuccess) {
      setShowForm(false);
    }
  }, [changePasswordSuccess]);

  useEffect(() => {
    if (updateUserDetailsSuccess) {
      dispatch(auth.managementDetails());
    }
    //reset showing success message
    handleResetPasswordSuccess();
    handleResetUserDetailsSuccess();
  }, []);

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ mt: 6 }}>
        Profile Settings
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={updateUserProfileSchema}
        onSubmit={handleSubmitSave}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, dirty, isSubmitting, setSubmitting }) => (
          <Form>
            <div className={classes.fieldWrapper}>
              <div className={classes.field}>
                <InputLabel>First Name</InputLabel>
                <TextField
                  type="text"
                  name="firstName"
                  placeholder="Enter Your First Name"
                  onBlur={handleBlur}
                  value={values.firstName}
                  onChange={(e) => {
                    handleChange(e);
                    // setIsFirstNameChanged(true);
                    // Reset isSubmitting to false when first name changes
                    setSubmitting(false);
                    setIsDisabled(false);
                  }}
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                {touched.firstName && errors.firstName && (
                  <Typography className={classes.errorty}>{errors.firstName}</Typography>
                )}
              </div>
              <div className={classes.field}>
                <InputLabel>Last Name</InputLabel>
                <TextField
                  type="text"
                  name="lastName"
                  placeholder="Enter Your Last Name"
                  onBlur={handleBlur}
                  value={values.lastName}
                  onChange={(e) => {
                    handleChange(e);
                    // setIsLastNameChanged(true);
                    // Reset isSubmitting to false when last name changes
                    setSubmitting(false);
                    setIsDisabled(false);
                  }}
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  inputProps={{
                    maxLength: 50,
                  }}
                />
                {touched.lastName && errors.lastName && (
                  <Typography className={classes.errorty}>{errors.lastName}</Typography>
                )}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.field}>
                <InputLabel>Work Email</InputLabel>
                <TextField
                  disabled
                  fullWidth
                  hiddenLabel
                  value={authState?.managementDetailsSuccess?.data?.user?.email}
                  // variant="filled"
                  size="small"
                />
              </div>
              <div className={classes.field}>
                <InputLabel>Role Type</InputLabel>
                <TextField
                  disabled
                  fullWidth
                  hiddenLabel
                  id="filled-disabled"
                  // value={userDetails.roleSubType.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  value={authState?.managementDetailsSuccess?.data?.user?.role.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  // variant="filled"
                  size="small"
                />
              </div>
            </div>
            <Button
              disabled={!isValid || isSubmitting || isDisabled}
              type="submit"
              submitting={isSubmitting}
              text="Save"
              color={!isValid || isSubmitting || isDisabled ? "disablebtn1" : "btn1"}
              // color='disablebtn1'
              // sx={{ mt:  }}
            />
          </Form>
        )}
      </Formik>

      <Divider sx={{ my: 4, width: "75%" }} />

      {showForm ? (
        <ResetPassword toggleForm={toggleForm} />
      ) : (
        <>
          <Typography fontWeight={500} sx={{ mt: 4 }}>
            Reset Password
          </Typography>
          <Typography sx={{ mt: 2 }}>
            You can change your password here. It is recommended to change your password every few months.
          </Typography>

          {/* <Button
                sx={{
                    background: 'lightGrey', color: 'black', boxShadow: 'none', textTransform: 'none', fontSize: "14px !important",
                    lineHeight: "24px",
                    borderRadius: "4px",
                    // height: "30px",
                    height: '32px',
                }}
                text='Reset Password Now'
            // color='btn1'
            ></Button> */}
          <Button text="Reset Password Now" color="btn1" sx={{ mt: 3 }} onClick={() => toggleForm()}></Button>
        </>
      )}
      {changePasswordSuccess && (
        <SimpleSnackbar
          open={changePasswordSuccess}
          setOpen={handleResetPasswordSuccess}
          text={"Password changed successfully!"}
        />
      )}
      {updateUserDetailsSuccess && (
        <SimpleSnackbar
          open={updateUserDetailsSuccess}
          setOpen={handleResetUserDetailsSuccess}
          text={"Details updated successfully!"}
        />
      )}
    </Box>
  );
}

export default ProfileContent;
