// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
// import throttle from "lodash/throttle";

import createSagaMiddleware from "redux-saga";
import rootSaga from "../redux/sagas/saga";
const sagaMiddleware = createSagaMiddleware();

const Store = configureStore({
  reducer: rootReducer,
  middleware: () => [sagaMiddleware, createLogger()],
});

sagaMiddleware.run(rootSaga);

export default Store;

/* // Fk cleanup comments july 2023
// convert object to string and store in localStorage
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // Ignore write errors.
  }
}

// load string from localStarage and convert back in to an Object
// invalid output must be undefined
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

const store = createStore(
  rootReducer,
  loadFromLocalStorage(),
  composeWithDevTools(applyMiddleware(sagaMiddleware, logger))
);

sagaMiddleware.run(rootSaga);

store.subscribe(
  throttle(() => {
    saveToLocalStorage(store.getState());
  }, 1000)
);

export default store;
*/
