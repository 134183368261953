var summeryObj = {
  totalQuestions: 0,
  answeredQuestions: 0,
  expectedAnswers: 10,
  summary: [
    {
      id: 1,
      category: "Technical",
      type: "Technical",
      status: "incomplete",
      totalQuestions: 0,
      answeredQuestions: 0,
      questions: [],
    },
    {
      id: 2,
      category: "Presentation",
      type: "Presentation",
      status: "incomplete",
      totalQuestions: 0,
      answeredQuestions: 0,
      questions: [],
    },
    {
      id: 3,
      category: "Profile",
      type: "Profile",
      status: "incomplete",
      totalQuestions: 0,
      answeredQuestions: 0,
      questions: [],
    },
    {
      id: 4,
      category: "Coding",
      type: "Coding",
      status: "incomplete",
      totalQuestions: 0,
      answeredQuestions: 0,
      questions: [],
    },
  ],
};

function setSummeryObj(payload) {
  // console.log("hsdsgdfsd",payload)
  //summeryObj = payload;
}

function resetFunc() {
  const quizSummerReset = {
    totalQuestions: 0,
    answeredQuestions: 0,
    expectedAnswers: 0,
    summary: [
      {
        id: 1,
        category: "Technical",
        type: "Technical",
        status: "incomplete",
        totalQuestions: 0,
        answeredQuestions: 0,
        questions: [],
      },
      {
        id: 2,
        category: "Presentation",
        type: "Presentation",
        status: "incomplete",
        totalQuestions: 0,
        answeredQuestions: 0,
        questions: [],
      },
      {
        id: 3,
        category: "Profile",
        type: "Profile",
        status: "incomplete",
        totalQuestions: 0,
        answeredQuestions: 0,
        questions: [],
      },
      {
        id: 4,
        category: "Coding",
        type: "Coding",
        status: "incomplete",
        totalQuestions: 0,
        answeredQuestions: 0,
        questions: [],
      },
    ],
  };

  return quizSummerReset;
}

function sortArray(arr) {
  if (arr && arr.length > 0) {
    arr.sort((a, b) => (a.seq < b.seq ? -1 : a.seq > b.seq ? 1 : 0));
  }
  return arr;
}

function setQuestions(payload, summaryIndex, totalSummaryQues) {
  let question = {
    seq: payload.questionNo,
    question: payload.quesObject.Question_Description,
    isAnswered:
      payload.userAns.ansObj && payload.userAns.ansObj.answer && payload.userAns.ansObj.answer.length !== 0
        ? true
        : false,
    questionNo: payload.questionNo,
    questionID: payload.quesObject.Question_ID,
    ansObj: payload.userAns.ansObj,
  };

  if (summeryObj.summary[summaryIndex] && summeryObj.summary[summaryIndex].questions.length !== 0) {
    summeryObj.summary[summaryIndex].questions = summeryObj.summary[summaryIndex].questions.filter((obj, i) => {
      return obj.questionID !== question.questionID;
    });
  }

  summeryObj.summary[summaryIndex].questions.push(question);

  summeryObj.summary[summaryIndex].questions = sortArray(summeryObj.summary[summaryIndex].questions);

  let answeredQuestions = summeryObj.summary[summaryIndex].questions.filter((obj, i) => {
    return obj.isAnswered === true;
  });

  summeryObj.summary[summaryIndex].answeredQuestions = answeredQuestions.length;

  summeryObj.summary[summaryIndex].totalQuestions = totalSummaryQues.length;

  summeryObj.summary[summaryIndex].status =
    summeryObj.summary[summaryIndex].totalQuestions === summeryObj.summary[summaryIndex].answeredQuestions
      ? "complete"
      : "incomplete";
  let totAnsweredQuestions = calAnsweredQuestions(4);
  summeryObj["answeredQuestions"] = totAnsweredQuestions;
}

function calAnsweredQuestions(arrayLength) {
  let totalAnswers = 0;

  for (let i = 0; i < arrayLength; i++) {
    if (summeryObj.summary[i]) {
      totalAnswers += (summeryObj.summary[i].answeredQuestions && summeryObj.summary[i].answeredQuestions) || 0;
    }
  }

  return totalAnswers;
}

function getQuizSummery(payload, categorySequence) {
  if (payload === "reset") {
    summeryObj = resetFunc();
    return summeryObj;
  } else if (payload.quesObject.Level || payload.quesObject.Level === 0) {
    summeryObj["totalQuestions"] = payload.questions.length;
    summeryObj["expectedAnswers"] = payload.quizState.Min_Questions;

    let totalSummaryQues;

    if (payload.quesObject.Category === "Profile") {
      totalSummaryQues = payload.questions.filter((obj, i) => {
        return obj.Category === "Profile";
      });

      setQuestions(payload, 0, totalSummaryQues);
    }

    if (payload.quesObject.Category === "Presentation") {
      totalSummaryQues = payload.questions.filter((obj, i) => {
        return obj.Category === "Presentation";
      });

      setQuestions(payload, 1, totalSummaryQues);
    }

    if (payload.quesObject.Category === "Technical") {
      totalSummaryQues = payload.questions.filter((obj, i) => {
        return obj.Category === "Technical";
      });

      setQuestions(payload, 0, totalSummaryQues);
    }

    if (payload.quesObject.Category === "Coding") {
      totalSummaryQues = payload.questions.filter((obj, i) => {
        return obj.Category === "Coding";
      });

      setQuestions(payload, 3, totalSummaryQues);
    }

    if (payload.quesObject.Level === 0) {
      summeryObj.summary = summeryObj.summary.filter((smry) => {
        return ["Profile"].includes(smry.type);
      });
    } else if (categorySequence.length > 0) {
      summeryObj.summary = summeryObj.summary.filter((smry) => {
        return categorySequence.includes(smry.type);
      });
    }

    return summeryObj;
  } else {
    return summeryObj;
  }
}

function mapQuestion(payload, totalPayload, previousSession) {
  let qpayload = [];
  qpayload = payload.map((item, i) => {
    let indexValue = -1;
    if (previousSession?.result !== undefined && previousSession?.result?.length > 0) {
      indexValue =
        previousSession?.result.findIndex(
          (e) => e.Question_ID === item.Question_ID && e.User_Answers !== undefined && e.User_Answers.length > 0
        ) || [];
    }
    let index = totalPayload.findIndex((val, i) => val.Question_ID === item.Question_ID);
    return {
      seq: index + 1,
      question: item.Question_Description,
      isAnswered: indexValue !== -1 && item.Category === "Presentation" ? true : false,
      questionNo: qpayload.length + 1,
      questionID: item.Question_ID,
      ansObj: {},
    };
  });
  return qpayload;
}
function getInitialQuizSummery(arg, categorySequence, previousSession) {
  let payload = arg;
  if (payload === "reset") {
    summeryObj = resetFunc();
    return summeryObj;
  } else if (payload && payload.length > 0 && (payload[0].Level || payload[0].Level === 0)) {
    summeryObj["totalQuestions"] = payload.length;

    payload.ProfileQuestions = payload.filter((obj, i) => {
      return obj.Category === "Profile";
    });
    if (payload.ProfileQuestions && payload.ProfileQuestions.length > 0) {
      payload.ProfileQuestions = mapQuestion(payload.ProfileQuestions, payload);
      if (summeryObj.summary && summeryObj.summary[1]) {
        summeryObj.summary[2].totalQuestions = payload.ProfileQuestions.length;
        summeryObj.summary[2].questions = payload.ProfileQuestions;
      }
    }

    payload.PresentationQuestions = payload.filter((obj, i) => {
      return obj.Category === "Presentation";
    });
    if (payload.PresentationQuestions && payload.PresentationQuestions.length > 0) {
      payload.PresentationQuestions = mapQuestion(payload.PresentationQuestions, payload, previousSession);
      if (summeryObj.summary && summeryObj.summary[3]) {
        summeryObj.summary[1].totalQuestions = payload.PresentationQuestions.length;
        summeryObj.summary[1].questions = payload.PresentationQuestions;
      }
    }

    payload.TechnicalQuestions = payload.filter((obj, i) => {
      return obj.Category === "Technical";
    });
    if (payload.TechnicalQuestions && payload.TechnicalQuestions.length > 0) {
      payload.TechnicalQuestions = mapQuestion(payload.TechnicalQuestions, payload);
      if (summeryObj.summary && summeryObj.summary[0]) {
        summeryObj.summary[0].totalQuestions = payload.TechnicalQuestions.length;
        summeryObj.summary[0].questions = payload.TechnicalQuestions;
      }
    }

    payload.CodingQuestions = payload.filter((obj, i) => {
      return obj.Category === "Coding";
    });
    if (payload.CodingQuestions && payload.CodingQuestions.length > 0) {
      payload.CodingQuestions = mapQuestion(payload.CodingQuestions, payload);
      if (summeryObj.summary && summeryObj.summary[2]) {
        summeryObj.summary[3].totalQuestions = payload.CodingQuestions.length;
        summeryObj.summary[3].questions = payload.CodingQuestions;
      }
    }

    if (payload[0].Level === 0) {
      summeryObj.summary = summeryObj.summary.filter((smry) => {
        return ["Profile"].includes(smry.type);
      });
    } else if (categorySequence.length > 0) {
      summeryObj.summary = summeryObj.summary.filter((smry) => {
        return categorySequence.includes(smry.type);
      });
    }

    return summeryObj;
  } else {
    return summeryObj;
  }
}

export { summeryObj, setSummeryObj, getQuizSummery, getInitialQuizSummery };
