import { useEffect, useState } from "react";
import { transformOptions } from "../../services/globalService";
import { Checkbox, FormControl, Grid, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "./Button";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: window.innerHeight - 400,
    },
  },
};
const useStyles = makeStyles({
  noBorder: {
    border: "none !important",
  },
  selectMenu: {
    "& .css-vamk89-MuiFormControl-root .MuiFormControl-root": {
      width: "100% !important",
      margin: "0px !important",
    },
    // "&:hover": {
    //   border: "1px solid transparent"
    // },
    // // "&:focus-within": {
    // //   border: "1px solid transparent"
    // // },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid transparent",
      outline: "none !important",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid transparent",
    },
    // "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   border: "1px solid transparent",

    // },
    // "& .Mui-disabled": {

    // },
    // "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    //   border: "1px solid transparent"
    // }
  },
  selectText: {
    "& .MuiSelect-select": {
      background: "#EBEFF8 !important",
      border: "none !important",
      padding: "10px 12px !important",
      height: "40px",
    },
    "&.Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
        outline: "none !important",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none !important",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
    outline: "none !important",
    border: "none !important",
  },
  selectText1: {
    outline: "none !important",
    "& .MuiSelect-select": {
      background: "transparent !important",
      border: "1px solid #787878 !important",
      padding: "8px 12px !important",
      color: "#787878 !important",
      height: "40px",
    },
    "& .MuiOutlinedInput-root .Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
  },
  placeholdertext: {
    color: "#2F2F2F !important",
    fontSize: "16px !important",
    fontFamily: "Roboto !important",
    fontWeight: 500,
    fontStyle: "normal",
  },
  placeholdertext1: {
    color: "#787878 !important",
    fontSize: "16px !important",
    fontFamily: "Roboto !important",
    fontWeight: 400,
    fontStyle: "normal",
  },
  icon: {
    color: "#2F2F2F !important",
  },
  MenuItemCapitalize: {
    paddingLeft: "3px",
    background: "#FFFFFF!important",
    textTransform: "capitalize",
  },
  MenuItemNoneCapitalize: {
    paddingLeft: "3px",
    background: "#FFFFFF!important",
    textTransform: "none",
  },
});

const SelectWithCheckbox = (props) => {
  const classes = useStyles();
  // eslint-disable-next-line
  const { title, options, defaultValue, handleSelectOption, handleSelectAll, handleClearAll } = props;

  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (options) {
      if (Array.isArray(options)) {
        setSelectOptions(options);
      } else {
        const updatedOptions = transformOptions(options);
        setSelectOptions(updatedOptions);
      }
    }
  }, [options]);

  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    handleSelectOption?.(value);
  };

  return (
    <FormControl
      sx={{
        minWidth: props.width,
        backgroundColor: props.BgColor ? props.BgColor : "transparent",
        outline: "none",
        height: props.height,
        borderRadius: "4px !important",
      }}
      size="small"
      className={classes.selectMenu}
    >
      <Select
        displayEmpty
        multiple
        input={<OutlinedInput />}
        value={defaultValue}
        onChange={handleSelect}
        renderValue={(selected) => (
          <em className={classes.placeholdertext}>
            {props.title} {props.checkLength === 0 ? null : "("}
            {props.checkLength === 0 ? null : props.checkLength}
            {props.checkLength === 0 ? null : ")"}
          </em>
        )}
        inputProps={{
          "aria-label": "Without label",
          classes: {
            icon: classes.icon,
          },
        }}
        MenuProps={MenuProps}
        className={props.checkLength === 0 ? classes.selectText1 : classes.selectText}
      >
        {selectOptions.map(({ id, displayName }) => (
          <MenuItem
            key={id}
            value={id}
            className={props.disabledCapitalize ? classes.MenuItemNoneCapitalize : classes.MenuItemCapitalize}
          >
            <Checkbox checked={defaultValue?.indexOf(id) > -1} style={{ color: "#1789FC" }} />
            <ListItemText
              primary={displayName}
              sx={{
                ".MuiTypography-root": {
                  fontSize: "16px !important",
                  fontFamily: "Roboto !important",
                  color: "#2F2F2F !important",
                  fontStyle: "medium !important",
                  lineHeight: "24px !important",
                  fontWeight: 500,
                  align: "left",
                  verticalAlign: "center",
                  paddingLeft: "15px",
                },
              }}
            />
          </MenuItem>
        ))}

        <Grid sx={{ paddingTop: "15px", display: "flex", justifyContent: "space-between" }}>
          {handleSelectAll && (
            <Button sx={{ textTransform: "none" }} variant="text" text="Select All" onClick={handleSelectAll} />
          )}
          <Button sx={{ textTransform: "none" }} variant="text" text="Clear All" onClick={handleClearAll} />
        </Grid>
      </Select>
    </FormControl>
  );
};

export default SelectWithCheckbox;
