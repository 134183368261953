import * as React from "react";
import Button from "./Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from '@mui/material/styles';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextInput from "../../components/controls/TextField";
import { Divider, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../styles/theme";
import { useFormik } from "formik";
const useStyles = makeStyles({
  textValue: theme.typography.b1Regular,
  deactiveText: theme.typography.b1Medium,
  actionbtn: {
    paddingRight: "20px",
    paddingBottom: "6px",
    paddingTop: "6px",
  },
  root: {
    "& .MuiDialog-paper": {
      maxWidth: "646px !important",
      maxHeight: "313px !important",
    },
  },
  field: {
    height: "48px !important",
    marginTop: "8px !important",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
  },
  dialogSty: {
    padding: "16px 24px !important",
  },
});
export default function DeactivateModel(props) {
  //   const [open, setOpen] = React.useState(false);
  // const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const [deactive,setDeactive] = React.useState('')
  const classes = useStyles();

  const initialValues = {
    deactive: "",
  };
  const handleClickDelete = () => {
    props.DeleteListData(formik.values.deactive);
    setTimeout(() => {
      handleClose();
    }, 1500);
  };
  const handleClose = () => {
    props.setOpen(false);
    formik.resetForm();
  };

  // let Deactiveselect = ["Not required", "Not required"];

  const formik = useFormik({
    initialValues,
    // onSubmit,
    //validationSchema,
  });

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
        className={classes.root}
      >
        <DialogTitle id="responsive-dialog-title">
          {props.Title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider></Divider>
        <DialogContent className={classes.dialogSty}>
          <DialogContentText>
            <Typography className={classes.textValue}>{props.Text} </Typography>
          </DialogContentText>
          <Grid style={{ marginTop: "16px" }}>
            <Typography className={classes.deactiveText}>
              {props.textvalue ? props.textvalue : "Enter a reason for deactivation"}
            </Typography>
            <TextInput
              name={"deactive"}
              placeholder={props.deactiveText ? props.deactiveText : "Why are you deactiving this candidate?"}
              className={classes.field}
              id={"deactive"}
              onChange={formik.handleChange}
              error={formik.touched.deactive && formik.errors.deactive ? Boolean(formik.errors.deactive) : null}
              onBlur={formik.handleBlur}
              value={formik.values.deactive || formik.values.deactive}
            ></TextInput>
          </Grid>
        </DialogContent>
        <Grid className={classes.actionbtn}>
          <DialogActions>
            {/* <Grid style={{ marginRight: "24px" }}> */}
            <Button
              data-rt-delete-cancel="cancel"
              color="btnCls1"
              text="Cancel"
              // type="submit"
              onClick={handleClose}
              // width={200}
              // height={32}
              variant="outlined"
            ></Button>
            {/* </Grid> */}
            {formik.values.deactive === "" ? (
              <Button
                color="disablebtn1"
                disabled
                text={props.sendreminder ? props.sendreminder : "Deactivate"}
                // width={200}
                data-rt-delete-deactivate="deactivate"
              ></Button>
            ) : (
              <Button
                data-rt-delete-deactivate="deactivate"
                color={props.Deactivebtn === true ? "disablebtn1" : "btn1"}
                // width="100%"
                text={props.sendreminder ? props.sendreminder : "Deactivate"}
                // width={200}
                // height={32}
                onClick={props.Deactivebtn === true ? null : handleClickDelete}
              ></Button>
            )}
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
}
