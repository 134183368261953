import React from "react";
import StackBarChart from "../../../pages/manageAccounts/analytics/charts/StackBarChart";
import DonutChart from "../../../pages/manageAccounts/analytics/charts/DonutChart";
import MultiLineScatterPlot from "../../../pages/manageAccounts/analytics/charts/MultiLineScatterPlot";
import { Card, Grid } from "@mui/material";
import CandidatesChart from "./charts/CandidatesChart";

const ChartContent = () => {
  return (
    <Grid sx={{ mt: 6 }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          marginBottom: "20px",
        }}
      >
        <Card variant="outlined" elevation={0} sx={{ width: "40%", height: "500px" }}>
          <MultiLineScatterPlot />
        </Card>
        <Card variant="outlined" elevation={0} sx={{ width: "40%", height: "500px" }}>
          <DonutChart />
        </Card>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          marginBottom: "20px",
          // height: "same-as-width",
        }}
      >
        <Card variant="outlined" elevation={0} sx={{ width: "40%", height: "500px" }}>
          <StackBarChart />
        </Card>
        <Card variant="outlined" elevation={0} sx={{ width: "40%", height: "500px" }}>
          <CandidatesChart />
        </Card>
      </div>
    </Grid>
  );
};

export default ChartContent;
