import React from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Button from "../../../components/controls/Button";

const useStyles = makeStyles({
  roletype1: {
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    paddingTop: "10px",
  },
});

const SkillsDialog = ({
  open,
  handleClose,
  allSkills,
  selectedFromAllSkills,
  selectedSkillsAndWightages,
  relevantSkills,
  handleSkillClick,
  handleResetSelection,
  handleAddSkills,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} PaperComponent={Paper} fullWidth maxWidth="lg">
      <DialogTitle>
        <Grid container>
          <Typography variant="h6">Select Additional Relevant Skills</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 12,
              top: 4,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container>
          {allSkills &&
            Object.keys(allSkills).map((category) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={category} className={classes.roletype1}>
                <Typography variant="h6" sx={{ mt: 1, fontSize: "18px" }}>
                  {category}
                </Typography>
                <Grid container spacing={1} sx={{ mb: 2 }}>
                  {allSkills[category].map((skill) => {
                    const isSelectedSkill = selectedFromAllSkills.some(
                      (selectedSkill) => selectedSkill.skill === skill.skillName
                    );

                    const isSelectedSkills = selectedSkillsAndWightages.some(
                      (redSkill) => redSkill.skill === skill.skillName
                    );

                    const isRelevantSkill = relevantSkills.some(
                      (relevantSkill) => relevantSkill.skill === skill.skillName
                    );

                    return (
                      <Grid item xs={12} key={skill.skillKey} sx={{ pt: 0, pb: 0, height: "25px" }}>
                        <FormControlLabel
                          label={<Typography variant="body2">{skill.skillName}</Typography>}
                          control={
                            <Checkbox
                              size="small"
                              checked={isSelectedSkill || isSelectedSkills || isRelevantSkill}
                              onChange={() =>
                                !isSelectedSkills &&
                                !isRelevantSkill &&
                                handleSkillClick(skill.skillName, skill.weightage, skill.skillKey)
                              }
                              disabled={isSelectedSkills || isRelevantSkill}
                              sx={{
                                color: isSelectedSkills || isRelevantSkill ? "black" : "default",
                                "&.Mui-disabled": {
                                  color: "black",
                                },
                              }}
                            />
                          }
                          sx={{ margin: 0 }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <Grid display={"flex"} justifyContent={"space-between"} sx={{ my: 1 }}>
        <Button color="btn1" sx={{ mx: 8, my: 2 }} text="Reset Selection" onClick={handleResetSelection} />
        <Button color="btn1" sx={{ mx: 8, my: 2 }} text="Update Relevant Skill Set" onClick={handleAddSkills} />
      </Grid>
    </Dialog>
  );
};

export default SkillsDialog;
