import React from "react";
import Popup from "../../components/Popup";
import LOneExampleContent from "../../components/dialogContent/LOneExampleContent";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const ExplainationModal = ({ openPopup, setOpenPopup, treeMapData, candidateDetails }) => {
  const [data, setData] = useState("");
  console.log(data, "datatatata");
  const authState = useSelector((state) => state.auth);
  // console.log(data,"data before plot============")

  function calcScoreOpacity(obj) {
    // if (obj.monthsUsed > 0 && obj.lastUsed != "") {
    let factor1 = 1 / (1 + Math.E ** -((obj.monthsUsed - 28) / 14));
    factor1 = obj.monthsUsed === 0 ? 0 : factor1;
    let factor2 = 0.7 / (1 + Math.E ** ((obj.lastUsedInMonths - 30) / 12)) + 0.3;
    factor2 = obj.lastUsedInMonths <= 3 ? 1.0 : factor2;
    const score = Math.round(factor1 * factor2 * 100);
    obj.score = score;
    obj.opacity = score <= 25 ? 0.25 : score / 100;
    return obj;
    // }
  }

  const mapDataToCategoryArray = (mappedData, skills) => {
    return mappedData?.map((item) => {
      const skill = skills[item.skill];
      return {
        ...skill,
        skill: item.skill,
        score: item.score,
        opacity: item.opacity,
        lastUsedInMonths: item.lastUsedInMonths,
        monthsUsed: item.monthsUsed,
        category: skill?.category || "Others",
        lastUsed: item.lastUsed,
      };
    });
  };

  function mapCategoryData(categoryArray) {
    const mappedData = {};
    categoryArray?.forEach((item) => {
      // const { category, fullName, monthsUsed, opacity } = item;
      if (!mappedData[item.category]) {
        mappedData[item.category] = [];
      }

      mappedData[item.category].push({
        // skill: item.fullName,
        skill: item.category === "Others" ? item.skill : item.fullName,
        // score: item.monthsUsed === 0 ? 0 : item.score,
        score: item?.score,
        opacity: item.opacity,
        lastUsedInMonths: item.lastUsedInMonths,
        monthsUsed: item.monthsUsed,
        lastUsed: item.lastUsed,
      });
    });

    if ("Database" in mappedData) {
      mappedData["Databases"] = mappedData["Database"];
      delete mappedData["Database"];
    }
    return mappedData;
  }

  useEffect(() => {
    const mappedData = treeMapData?.map(calcScoreOpacity);
    if (authState?.skillsConfig && mappedData?.length) {
      const categoryArray = mapDataToCategoryArray(mappedData, authState?.skillsConfig);
      const finalMappedData = mapCategoryData(categoryArray);
      setData(finalMappedData);
    }
  }, [treeMapData, authState?.skillsConfig]);

  console.log(treeMapData, "treeMapDatatreeMapData");
  console.log(candidateDetails, "candidateDetails");

  function getTopCategoriesAndSkills(data) {
    let categoryScores = {};
    let topSkills = [];
    for (let category in data) {
      let categoryScore = 0;
      for (let skill of data[category]) {
        categoryScore += skill?.score;
        topSkills.push(skill);
      }
      categoryScores[category] = categoryScore;
    }

    // console.log(categoryScores);

    // Sort categories by score in descending order
    let sortedCategories = Object.keys(categoryScores).sort((a, b) => categoryScores[b] - categoryScores[a]);

    // Find top 2 categories
    let topCategories = sortedCategories.slice(0, 2);

    // Sort topSkills by score in descending order
    topSkills.sort((a, b) => b?.score - a?.score);

    // Find at least 3 top skills
    let topNSkills = [];
    let minTopScore = topSkills[2]?.score;
    let i = 0;
    while (i < topSkills.length && topSkills[i]?.score >= minTopScore) {
      topNSkills.push(topSkills[i].skill);
      i++;
    }

    // console.log(topCategories);
    // console.log(topNSkills);

    return { topCategories, topNSkills };
  }

  return (
    <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
      <LOneExampleContent
        title="Skill Tree Map"
        hint={
          <span>
            In the tree map, {candidateDetails?.firstName}'s skills are organized within skill categories. The strongest
            skills and categories appear towards the top and left. The bigger the size of a skill and the deeper the
            color, the stronger the skill.
            <br />
            <br /> For {candidateDetails?.firstName} {candidateDetails?.lastName}, the strongest skill category is{" "}
            <strong>{getTopCategoriesAndSkills(data).topCategories[0]}</strong> followed by{" "}
            <strong>{getTopCategoriesAndSkills(data).topCategories[1]}</strong>. Their strongest skills are{" "}
            <strong>{getTopCategoriesAndSkills(data).topNSkills.slice(0, -1).join(", ")}</strong>
            {getTopCategoriesAndSkills(data).topNSkills.length > 1 ? ", and " : ""}
            <strong>{getTopCategoriesAndSkills(data).topNSkills.slice(-1)}</strong> .
          </span>
        }
        showButtonArea={false}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        id="no-copy"
      ></LOneExampleContent>
    </Popup>
  );
};

export default ExplainationModal;
