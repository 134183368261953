import React from "react";
import { FormControl, Select as MuiSelect, MenuItem, FormHelperText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { theme } from "../../styles/theme";

const useStyles = makeStyles({
  root: {
    margin: theme.spacing(0.5),
    "& .MuiMenuItem-root.Mui-selected": {
      fontWeight: "700 !important",
    },
  },
  label: {
    textTransform: "none",
  },
  selectCls: {
    width: 160,
    height: 32,
    color: "#787878 !important",
    backgroundColor: "white !important",
    "& div": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },

    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    "& li.Mui-selected": {
      fontWeight: "700 !important",
    },
  },
  formControlCls: {
    marginLeft: "8px !important",
  },
  labelCls: {
    color: "#787878",
  },
  menuIt: {
    // fontWeight:"700 !important",
    "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
      fontWeight: "700 !important",
      backgroundColor: "rgb(28 29 29 / 8%)",
    },
  },
});

export default function Select(props) {
  // eslint-disable-next-line
  const { name, label, value, error = null, onChange, options, showNone, displayField, disable } = props;
  const classes = useStyles(props);
  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className} />;
  };
  const menuProps = {
    classes: {
      list: {
        paddingTop: 0,
        paddingBottom: 0,
        background: "white",
        "& li.Mui-selected": {
          fontWeight: "700 !important",
        },
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl className={classes.formControlCls} variant="outlined" {...(error && { error: true })}>
      <MuiSelect
        // label={label}
        style={props.style}
        name={name}
        value={value}
        onChange={onChange}
        IconComponent={iconComponent}
        className={classes.selectCls}
        MenuProps={menuProps}
        //classes={{ root: borderSelectClasses.select, }}
        disabled={disable}
      >
        {showNone && <MenuItem value="">None</MenuItem>}
        {options.map((item) => (
          <MenuItem className={classes.menuIt} key={item.id} value={item.id}>
            {item[displayField] || item.value}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
