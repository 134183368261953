import { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Badge, Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TourStepper from "./TourStepper";

const useStyles = makeStyles({
  tourWrapper: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 1,
    width: "100%",
  },
  tourBadge: {
    display: "block !important",
    "& .MuiBadge-badge": {
      position: "absolute",
      top: "-10px",
      right: "-10px",
    },
  },
});

const tourButtonStyle = {
  position: "absolute",
  right: 0,
  bottom: "16px",
  background: "#101418 !important",
  color: "#f7f7f7 !important",
  "&:hover": { background: "#2f2f2f !important" },
};

const Tour = ({ isTourBtnShow, handleStepper }) => {
  const classes = useStyles();
  const { recentInvites, recentJobRoles, recentCandidates } = useSelector((state) => state.auth?.recruiterdashboard);
  const [stepperDisappear, setStepperDisappear] = useState("");
  const [stepperDisappearText, setStepperDisappearText] = useState("");

  const getUserId = () => {
    // check if recruiterUserId needs to be se
    if ("recruiterUserId" in localStorage) {
      let recruiterUserId = localStorage.getItem("recruiterUserId");
      localStorage.setItem("userid", recruiterUserId);
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      userDetails.userId = recruiterUserId;
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      localStorage.removeItem("recruiterUserId");
    }
    const { userId } = JSON.parse(localStorage.getItem("user_details"));
    return userId;
  };

  const conditionToShowStepper = useCallback((inviteList) => {
    const date = new Date(); // Get the current date and time
    let sortedArray = inviteList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    let filteredArray = sortedArray.filter((i) => i.status === "active" && i.inviteCreatedByUid === getUserId());
    if (filteredArray.length === 0) {
      return 100;
    }
    let firstDate = new Date(filteredArray[0]?.createdAt);
    // Add 72 hours to first invite created date
    firstDate = new Date(firstDate.setHours(firstDate.getHours() + 72));

    return (firstDate - date) / (60 * 60 * 1000);
  }, []);

  useEffect(() => {
    if (recentInvites && recentInvites.length != 0) {
      let stepperResult = conditionToShowStepper([...recentInvites]);

      let disappearText =
        stepperResult > 3
          ? `This guide will disappear in ${Math.floor(stepperResult)} hours`
          : `This guide will disappear soon.`;

      setStepperDisappear(stepperResult < 0 ? 1 : 0);
      setStepperDisappearText(disappearText);
    }
  }, [conditionToShowStepper, recentInvites]);

  const roleStepperCount = useMemo(() => {
    return recentJobRoles?.filter((i) => i.status === "active" && i.createdBy?.uid === getUserId()).length;
  }, [recentJobRoles]);

  const inviteStepperCount = useMemo(() => {
    return recentInvites?.filter((i) => i.status === "active" && i.inviteCreatedByUid === getUserId()).length;
  }, [recentInvites]);

  const candidateStepperCount = useMemo(() => {
    return recentCandidates?.filter((i) => i.status === "active" && i.createdBy?.uid === getUserId()).length;
  }, [recentCandidates]);

  // Check if any of the counts is greater than zero
  const isTourStepperOpen = useMemo(() => {
    return roleStepperCount === 0 || inviteStepperCount === 0 || candidateStepperCount === 0 || stepperDisappear === 0;
  }, [roleStepperCount, inviteStepperCount, candidateStepperCount, stepperDisappear]);

  // Calculate the badge count based on the number of counts that are zero
  const badgeCount = useMemo(() => {
    return (
      (roleStepperCount === 0 ? 1 : 0) + (inviteStepperCount === 0 ? 1 : 0) + (candidateStepperCount === 0 ? 1 : 0)
    );
  }, [roleStepperCount, inviteStepperCount, candidateStepperCount]);

  const tourBadgeCount = useMemo(() => Math.min(badgeCount, 3), [badgeCount]);

  return (
    <div className={classes.tourWrapper}>
      {isTourBtnShow && isTourStepperOpen ? (
        <TourStepper
          roleCount={roleStepperCount}
          inviteCount={inviteStepperCount}
          candidateCount={candidateStepperCount}
          stepperDisappearText={stepperDisappearText}
        />
      ) : null}
      {isTourStepperOpen ? (
        <Fab variant="extended" onClick={handleStepper} sx={tourButtonStyle}>
          <Badge badgeContent={tourBadgeCount} color="error" className={classes.tourBadge}>
            {isTourBtnShow ? "Close" : "Getting Started"}
          </Badge>
        </Fab>
      ) : null}
    </div>
  );
};

export default Tour;
