import React from "react";
import ReusableDialog from "./controls/ReusableDialog";

export default function TimeoutDialog(props) {
  // eslint-disable-next-line
  const { open, onClose, onSubmit, text, ...other } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <ReusableDialog
        open={open}
        onClose={handleClose}
        title={props?.heading && props?.heading != "" ? props?.heading : ""}
        content={text}
      />
    </div>
  );
}
