import React, { useState, useEffect } from "react";
import Plot from "./Plot";
import { useDispatch, useSelector } from "react-redux";
import chart from "../../../../redux/actions/chart";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { chartConfig } from "../../analytics/chartConfig";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { analyticsEvent } from "../../../../services/ga4utility";

const StackBarChart = () => {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const chartStore = useSelector((state) => state.chart);
  const [data, setData] = useState(chartStore?.StackChartData);

  useEffect(() => {
    let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let endDate = new Date();
    let startDate = new Date(endDate);
    // startDate.setDate(endDate.getDate() - 30);
    startDate.setMonth(endDate.getMonth() - 1);

    let payload = {
      startDate,
      endDate,
      chartType: "30 day stack",
      browserTimeZone,
    };
    dispatch(chart.StackChartData(payload));
  }, []);

  useEffect(() => {
    if (chartStore?.stackChartData?.length) {
      setData(chartStore?.stackChartData);
    }
  }, [chartStore?.stackChartData?.length]);

  const updatedData = data?.map((item, index) => ({
    ...item,
    marker: {
      color: chartConfig.customColors[index % chartConfig.customColors.length],
    },
  }));

  let showlegend;
  if (open === false) showlegend = false;
  else showlegend = true;

  let text;
  if (open === false) text = '<b>Daily Invites Sent</b><br><span style="font-size:0.8rem">(Last 30 days)</span>';
  else
    text =
      '<span style="font-size:14pt;font-weight:Bold">Daily Invites Sent</span><br><span style="font-size:0.9rem">(Last 30 days)</span>';

  let yaxis;
  if (open === false) yaxis = '<span style="font-size:.8rem; font-weight:Bold">Number of invites</span>';
  else yaxis = '<span style="font-size:1rem;font-weight:Bold">Number of invites</span>';

  let xaxis;
  if (open === false) xaxis = '<span style="font-size:.8rem; font-weight:Bold">Invites sent on</span>';
  else xaxis = '<span style="font-size:1rem;font-weight:Bold">Invites sent on</span>';

  const layout = {
    legend: {
      title: {
        text: '<span style="font-size:0.9rem; font-weight:Bold">&nbsp;&nbsp;&nbsp;User</span>',
      },
      font: { size: 14 },

      // x:1,
      // y:1,
      // traceorder:'normal',
    },
    autosize: true,
    categoryorder: "category ascending",

    showlegend: showlegend,

    barmode: "stack",
    title: {
      text: text,
      standoff: -50,
    },
    xaxis: {
      title: {
        text: xaxis,
        standoff: 20,
      },
      showline: true,
      tickangle: -90,
      tickheight: 100,
      ticks: "inside",
    },
    yaxis: {
      title: {
        text: yaxis,
        standoff: 25,
      },
      showline: true,
      tickheight: 100,
      ticks: "inside",
    },
    hoverlabel: {
      bgcolor: "#FFF",
    },
    font: chartConfig.font,

    // autosize: true,
    // // margin: {
    // //     l: 2,
    // //     r: 2,
    // //     b: 2,
    // //     t: 1,
    // // },
    // bargroupgap: 0.95,
    // barmode: 'stack',
    hovermode: "closest",
  };

  // added to formate the date
  let endDate = new Date();
  let startDate = new Date(endDate);
  startDate.setMonth(endDate.getMonth() - 1);

  function formatDate(dateString, isFirstDate, prevYear) {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    if (isFirstDate) {
      options.year = "numeric"; // Add year only for the first date
    }
    // const day = date.getDate();
    // const formattedDay = day < 10 ? "0" + day : day; // Add leading zero if day is a single digit
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate.replace(/\b\d\b/g, "0$&"); // Replace single digits with leading zero
  }

  // Function to add missing dates and update data
  function addMissingDates(entry, startDate, endDate) {
    let currentDate = new Date(startDate);
    const formattedDates = entry.x.map((date) => new Date(date).toISOString().slice(0, 10));

    const updatedX = [];
    const updatedY = [];
    let prevYear;

    // Loop through dates from start to end
    while (currentDate <= endDate) {
      const formattedDate = currentDate.toISOString().slice(0, 10);
      const dataIndex = formattedDates.indexOf(formattedDate);

      // formate start date to match with the entry
      let year = startDate.getFullYear();
      let month = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      let day = String(startDate.getDate()).padStart(2, "0");
      let formattedStartDate = `${year}-${month}-${day}`;

      // If the date exists, copy data from the original entry
      if (formattedDate === formattedStartDate) {
        updatedX.push(formatDate(formattedDate, true, prevYear));
        updatedY.push(0);
      } else if (entry.x[dataIndex] === formattedStartDate) {
        updatedX.push(formatDate(entry.x[dataIndex], dataIndex === 0, prevYear));
        updatedY.push(entry.y[dataIndex]);
        prevYear = new Date(entry.x[dataIndex]).getFullYear();
      } else if (dataIndex !== -1) {
        updatedX.push(
          // formatDate(entry.x[dataIndex], dataIndex === 0, prevYear)
          formatDate(entry.x[dataIndex], false, prevYear)
        );
        updatedY.push(entry.y[dataIndex]);
        prevYear = new Date(entry.x[dataIndex]).getFullYear();
      } else {
        // If the date is missing, add it with count 0

        updatedX.push(formatDate(formattedDate, false, prevYear));
        updatedY.push(0);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    entry.x = updatedX;
    entry.y = updatedY;
  }

  updatedData?.forEach((entry) => {
    addMissingDates(entry, startDate, endDate);
  });

  return (
    <>
      {chartStore?.stackChartData?.length ? (
        <Plot
          data={updatedData}
          // data={traces}
          layout={layout}
          style={{ width: "95%", margin: "10px" }}
          containerId="DailyInvites"
          config={{
            modeBarButtonsToAdd: [
              {
                name: "Full Screen",
                icon: chartConfig.fullScreenIcon,
                click: function (gd) {
                  handleOpen();
                  analyticsEvent("chart", "Daily Invites Sent Chart Open", "Daily Invites Sent");
                },
              },
            ],
            displaylogo: false,
            responsive: true,
            modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
            textAlign: "center",
          }}
        >
          <StackedBarChartIcon style={{ marginBottom: "5px" }} />
          <span style={{ marginLeft: "5px", marginBottom: "5px" }}>{chartConfig.MessageWhenNoData}</span>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        bg="red"
      >
        <Box sx={styleModal} style={{ width: "100%", height: "100%", margin: "auto" }}>
          <Plot
            data={updatedData}
            layout={layout}
            style={{ width: "100%", height: "100%" }}
            containerId="DailyInvitesModals"
            config={{
              modeBarButtonsToAdd: [
                {
                  name: "Exit Full Screen",
                  icon: chartConfig.exitFullScreenIcon,
                  click: function (gd) {
                    handleClose();
                  },
                },
              ],
              displaylogo: false,
              responsive: true,
              modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default StackBarChart;
