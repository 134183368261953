import React, { useEffect, useState } from "react";
import ResumeProcessingCard from "./ResumeProcessingCard";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../../redux/actions/auth";

const ResumeProcessingQueue = ({ processingResumes }) => {
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth);
  const [processingResumesQueue, setProcessingResumesQueue] = useState([...processingResumes]);
  useEffect(() => {
    setProcessingResumesQueue(processingResumes);
  }, [processingResumes, processingResumesQueue, authStore?.deleteResumeDocSuccess]);

  useEffect(() => {
    if (authStore.deleteResumeDocSuccess) {
      let listData = [...processingResumesQueue];
      // eslint-disable-next-line
      listData.map((i) => {
        if (i._id == authStore?.deleteResumeDocSuccess?.docId) {
          i["message"] = authStore?.deleteResumeDocSuccess?.message;
          i["deleted"] = authStore?.deleteResumeDocSuccess?.success == true ? true : false;
        }
      });
      setProcessingResumesQueue(listData);
    }
  }, [authStore?.deleteResumeDocSuccess]);

  useEffect(() => {
    if (authStore.addCompletedResumeSuccess) {
      let listData = [...processingResumesQueue];
      // eslint-disable-next-line
      listData.map((i) => {
        if (i._id == authStore?.addCompletedResumeSuccess?.docId) {
          i["message"] = authStore?.addCompletedResumeSuccess?.message;
          i["added"] = authStore?.addCompletedResumeSuccess?.error == false ? true : false;
          i["manageMassage"] = authStore?.addCompletedResumeSuccess?.error == false ? false : true;
          i["resError"] = authStore?.addCompletedResumeSuccess?.error == true ? true : false;
          i["candidateId"] = authStore?.addCompletedResumeSuccess?.candidateId;
        }
      });
      setProcessingResumesQueue(listData);
    }
  }, [authStore?.addCompletedResumeSuccess]);

  //DELETE
  const handleDelete = (data, index) => {
    let { _id } = data;
    let payload = {
      docId: _id,
    };
    dispatch(auth.deleteResumeDoc(payload));
  };

  //EDIT
  const handleEdit = (data, index) => {
    let { _id } = data;
    window.open(`/editandaddnewcandidate/${_id}`, "_blank");

    let listData = [...processingResumesQueue];
    // eslint-disable-next-line
    listData?.map((item) => {
      if (item._id == _id) {
        item.editStatus = true;
      }
    });
    setProcessingResumesQueue(listData);
  };

  //ADD NOW
  const handleAdd = (data, index) => {
    let { _id } = data;
    dispatch(auth.addCompletedResume(_id));
  };

  return (
    <div>
      {processingResumesQueue?.map((data, index) => (
        <>
          <ResumeProcessingCard
            key={index}
            {...data}
            onDelete={() => handleDelete(data, index)}
            onEdit={() => handleEdit(data, index)}
            onAdd={() => handleAdd(data, index)}
          />
        </>
      ))}
    </div>
  );
};

export default ResumeProcessingQueue;
