import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "../../assets/svg/iconsearch.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: (props) => (props.width ? "422px" : "310px"),
  height: "42px!important",
  border: "1px solid #787878",
  // borderRadius: 4,
  boxSizing: "border-box",
  padding: 0,
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(1),
    //  width: 'auto',
  },
  "& .MuiInputBase-root": {
    // top:'-12px !important'
    padding: "0px",
    position: "absolute",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "0px 0px 0px 16px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 16,
  color: "rgba(36, 36, 36, 0.24)",

  //border : "0.5px solid #000000"
}));
// const SearchClose = styled("div")(({ theme }) => ({
//   padding: "0px 0px 0px 8px",
//   height: "100%",
//   position: "absolute",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   fontSize: 16,
//   color: "rgba(36, 36, 36, 0.24)",
//   //border : "0.5px solid #000000"
//   right: "0px",
//   cursor: "pointer",
//   top: "0px",
// }));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  // '& .MuiInputBase-root':{
  //   top:'-12px !important'
  // },
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3.5)})`,
    transition: theme.transitions.create("width"),
    // width: '100%',

    font: "normal 300 12px Nunito",
    //  color : '#000000',
    [theme.breakpoints.up("sm")]: {
      // width: '392px',
      //width:'310px',
      "&:focus": {
        // width: '310px',
        // width: '392px',
      },
    },
    color: "#787878 !important",
    fontFamily: "Roboto !important",
    fontStyle: "Regular !important",
    fontSize: "16px !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    verticalAlign: "center !important",
  },
  "& ::placeholder": {
    color: "#787878 !important",
    fontFamily: "Roboto !important",
    fontStyle: "Regular !important",
    fontSize: "16px !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    verticalAalign: "center !important",
    opacity: "1 !important",
  },
}));

export default function SearchBar(props) {
  const searchRef = React.useRef();

  return (
    <Box>
      <Search
        onChange={props.onChange}
        //onKeyUp={props.onKeyUp}
        style={{ width: props.width ? props.width : "310px" }}
      >
        <SearchIconWrapper>
          <img src={SearchIcon} style={{ opacity: 0.5 }} alt="search" />
        </SearchIconWrapper>

        <StyledInputBase
          placeholder={props.placeholdertitle}
          inputProps={{ maxLength: 50, "aria-label": "search" }}
          ref={searchRef}
          value={props.value}
          style={{ width: props.width ? "422px" : "310px" }}
        />
      </Search>
    </Box>
  );
}
