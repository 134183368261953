// import authTypes from "../types/auth";
import roleTypes from "../types/role";

function roleList(payload) {
  return {
    type: roleTypes.ROLE_LIST,
    payload,
  };
}
function roleListSuccess(payload) {
  return {
    type: roleTypes.ROLE_LIST_SUCCESS,
    payload,
  };
}
function roleListReset(payload) {
  return {
    type: roleTypes.ROLE_LIST_RESET,
  };
}

function referenceData(payload) {
  return {
    type: roleTypes.REFERENCE_DATA,
  };
}
function referenceDataSuccess(payload) {
  return {
    type: roleTypes.REFERENCE_DATA_SUCCESS,
    payload,
  };
}
function addRole(payload) {
  return {
    type: roleTypes.ADD_ROLE,
    payload,
  };
}
function addRoleSuccess(payload) {
  return {
    type: roleTypes.ADD_ROLE_SUCCESS,
    payload,
  };
}

function editRole(payload) {
  return {
    type: roleTypes.UPDATE_ROLE,
    payload,
  };
}

function editRoleSuccess(payload) {
  return {
    type: roleTypes.UPDATE_ROLE_SUCCESS,
    payload,
  };
}
function deleteRole(payload) {
  return {
    type: roleTypes.DELETE_ROLE,
    payload,
  };
}

function deleteRoleSuccess(payload) {
  return {
    type: roleTypes.DELETE_ROLE_SUCCESS,
    payload,
  };
}

function inviteList(payload) {
  return {
    type: roleTypes.INVITE_LIST,
    // payload
  };
}
function inviteListSuccess(payload) {
  return {
    type: roleTypes.INVITE_LIST_SUCCESS,
    payload,
  };
}
function invitelistReset(payload) {
  return {
    type: roleTypes.INVITE_LIST_RESET,
  };
}
function addInvite(payload) {
  return {
    type: roleTypes.ADD_INVITE,
    payload,
  };
}
function addInviteSuccess(payload) {
  return {
    type: roleTypes.ADD_INVITE_SUCCESS,
    payload,
  };
}
function editInvite(payload) {
  return {
    type: roleTypes.UPDATE_INVITE,
    payload,
  };
}
function editInviteSuccess(payload) {
  return {
    type: roleTypes.UPDATE_INVITE_SUCCESS,
    payload,
  };
}
function deleteInvite(payload) {
  return {
    type: roleTypes.DELETE_INVITE,
    payload,
  };
}
function deleteInviteSuccess(payload) {
  return {
    type: roleTypes.DELETE_INVITE_SUCCESS,
    payload,
  };
}

function sendInviteReminder(payload) {
  return {
    type: roleTypes.SEND_INVTE_REMINDER,
    payload,
  };
}

function sendInviteReminderSuccess(payload) {
  return {
    type: roleTypes.SEND_INVTE_REMINDER_SUCCESS,
    payload,
  };
}

function extendExpirationDate(payload) {
  return {
    type: roleTypes.EXTEND_EXPIRATION_DATA,
    payload,
  };
}

function extendExpirationDateSuccess(payload) {
  return {
    type: roleTypes.EXTEND_EXPIRATION_DATA_SUCCESS,
    payload,
  };
}

function extendExpirationReset(payload) {
  return {
    type: roleTypes.EXTEND_EXPIRATION_RESET,
  };
}

function roleDetails(payload) {
  return {
    type: roleTypes.ROLE_DETAILS,
    payload,
  };
}
function roleDetailsSuccess(payload) {
  return {
    type: roleTypes.ROLE_DETAILS_SUCCESS,
    payload,
  };
}
function roleDetailsFailure(payload) {
  return {
    type: roleTypes.ROLE_DETAILS_FAILURE,
    payload,
  };
}

function candidateinviteList(payload) {
  return {
    type: roleTypes.CANDIDATE_INVITE,
    payload,
  };
}

function candidateinviteListSuccess(payload) {
  return {
    type: roleTypes.CANDIDATE_INVITE_SUCCESS,
    payload,
  };
}

function generateProfile(payload) {
  return {
    type: roleTypes.GENERATE_PROFILE,
    payload,
  };
}
function generateProfileSuccess(payload) {
  return {
    type: roleTypes.GENERATE_PROFILE_SUCCESS,
    payload,
  };
}

function inviteCandidateDetails(payload) {
  return {
    type: roleTypes.INVITE_CANDIDATE_DETAILS,
    payload,
  };
}

function inviteCandidateDetailsSuccess(payload) {
  return {
    type: roleTypes.INVITE_CANDIDATE_DETAILS_SUCCESS,
    payload,
  };
}

function candidateinviteDetails(payload) {
  return {
    type: roleTypes.DETAIL_INVITE_CANDIDATE,
    payload,
  };
}

function candidateinviteDetailsSuccess(payload) {
  return {
    type: roleTypes.DETAIL_INVITE_CANDIDATE_SUCCESS,
    payload,
  };
}

// function roleFilter(payload){
//   return {
//     type:roleTypes.FILTER_ROLE,
//     payload
//   }
// }
// function roleFilterSuccess(payload){
//   return {
//     type:roleTypes.FILTER_ROLE_SUCCESS,
//     payload
//   }
// }

function roleStatus(payload) {
  return {
    type: roleTypes.ROLE_STATUS,
    payload,
  };
}
function roleStatusSuccess(payload) {
  return {
    type: roleTypes.ROLE_STATUS_SUCCESS,
    payload,
  };
}

function roleTotalCount(payload) {
  return {
    type: roleTypes.ROLE_TOTAL_COUNT,
    payload,
  };
}
function roleMyCount(payload) {
  return {
    type: roleTypes.ROLE_MY_COUNT,
    payload,
  };
}

function roleAllCount(payload) {
  return {
    type: roleTypes.ROLE_ALL_COUNT,
    payload,
  };
}

// function inviteFilter(payload){
//   return{
//     type:roleTypes.INVITE_FILTER,
//     payload
//   }
// }

// function inviteFilterSuccess(payload){
//   return{
//     type:roleTypes.INVITE_FILTER_SUCCESS,
//     payload
//   }
// }

function inviteTotalCount(payload) {
  return {
    type: roleTypes.INVITE_TOTAL_COUNT,
    payload,
  };
}

function inviteMyTotal(payload) {
  return {
    type: roleTypes.INVITE_MY_COUNT,
    payload,
  };
}

function inviteAllTotal(payload) {
  return {
    type: roleTypes.INVITE_ALL_COUNT,
    payload,
  };
}

function inviteDetails(payload) {
  return {
    type: roleTypes.INVITE_DETAILS,
    payload,
  };
}
function inviteDetailsSuccess(payload) {
  return {
    type: roleTypes.INVITE_DETAILS_SUCCESS,
    payload,
  };
}
function inviteDetailsFailure(payload) {
  return {
    type: roleTypes.INVITE_DETAILS_FAILURE,
    payload,
  };
}

function candidateDashboardDetails(payload) {
  return {
    type: roleTypes.CANDIDATE_DASHBOARD_DETAILS,
    payload,
  };
}
function candidateDashboardDetailsSuccess(payload) {
  return {
    type: roleTypes.CANDIDATE_DASHBOARD_DETAILS_SUCCESS,
    payload,
  };
}

function roleGenerateSkill(payload) {
  return {
    type: roleTypes.ROLE_GENERATE_SKILL,
    payload,
  };
}
function roleGenerateSkillSuccess(payload) {
  return {
    type: roleTypes.ROLE_GENERATE_SKILL_SUCCESS,
    payload,
  };
}

function deactivateActiveStatus(payload) {
  return {
    type: roleTypes.INVITE_STATUS,
    payload,
  };
}
function deactivateActiveStatusSuccess(payload) {
  return {
    type: roleTypes.INVITE_STATUS_SUCCESS,
    payload,
  };
}

function roleMatching(payload) {
  return {
    type: roleTypes.ROLESKILL_MATCHING,
    payload,
  };
}

function roleMatchingSuccess(payload) {
  return {
    type: roleTypes.ROLESKILL_MATCHING_SUCCESS,
    payload,
  };
}

function restTestInvite(payload) {
  return {
    type: roleTypes.RESET_TEST,
    payload,
  };
}

function restTestInviteSuccess(payload) {
  return {
    type: roleTypes.RESET_TEST_SUCCESS,
    payload,
  };
}

function downloadCR(payload) {
  return {
    type: roleTypes.DOWNLOAD_CR,
    payload,
  };
}
function downloadCRSuccess(payload) {
  return {
    type: roleTypes.DOWNLOAD_CR_SUCCESS,
    payload,
  };
}

function downloadReportStatus(payload) {
  return {
    type: roleTypes.DOWNLOAD_REPORT_STATUS,
  };
}

// function updateInviteParams(payload) {
//   return {
//     type: roleTypes.UPDATE_INVITE_PARAMS,
//     payload,
//   };
// }

// function updateInviteParamsSuccess(payload) {
//   return {
//     type: roleTypes.UPDATE_INVITE_PARAMS_SUCCESS,
//     payload,
//   };
// }

function createConfig(payload) {
  return {
    type: roleTypes.CREATE_CONFIG,
    payload,
  };
}

function createConfigSuccess(payload) {
  return {
    type: roleTypes.CREATE_CONFIG_SUCCESS,
    payload,
  };
}

function createConfigFailure(payload) {
  return {
    type: roleTypes.CREATE_CONFIG_FAILURE,
    payload,
  };
}

function updateConfig(payload) {
  return {
    type: roleTypes.UPDATE_CONFIG,
    payload,
  };
}

function updateConfigSuccess(payload) {
  return {
    type: roleTypes.UPDATE_CONFIG_SUCCESS,
    payload,
  };
}

function updateConfigFailure(payload) {
  return {
    type: roleTypes.UPDATE_CONFIG_FAILURE,
    payload,
  };
}

function config(payload) {
  return {
    type: roleTypes.CONFIG,
    payload,
  };
}

function configSuccess(payload) {
  return {
    type: roleTypes.CONFIG_SUCCESS,
    payload,
  };
}

function configFailure(payload) {
  return {
    type: roleTypes.CONFIG_FAILURE,
    payload,
  };
}

function setSkillsConfig(payload) {
  return {
    type: roleTypes.SET_SKILLS_CONFIG,
    payload,
  };
}

function jobRoleData(payload) {
  return {
    type: roleTypes.JOB_ROLE_DATA,
    payload,
  };
}

function jobRoleDataSuccess(payload) {
  return {
    type: roleTypes.JOB_ROLE_DATA_SUCCESS,
    payload,
  };
}

function jobRoleDataFailure(payload) {
  return {
    type: roleTypes.JOB_ROLE_DATA_FAILURE,
    payload,
  };
}

function getRoleFeatures(payload) {
  return {
    type: roleTypes.GET_ROLE_FEATURES,
    payload,
  };
}

function getRoleFeaturesSuccess(payload) {
  return {
    type: roleTypes.GET_ROLE_FEATURES_SUCCESS,
    payload,
  };
}

function getRoleFeaturesFailure(payload) {
  return {
    type: roleTypes.GET_ROLE_FEATURES_FAILURE,
    payload,
  };
}

function getRoleSkills(payload) {
  return {
    type: roleTypes.GET_ROLE_SKILLS,
    payload,
  };
}

function getRoleSkillsSuccess(payload) {
  return {
    type: roleTypes.GET_ROLE_SKILLS_SUCCESS,
    payload,
  };
}

function getRoleSkillsFailure(payload) {
  return {
    type: roleTypes.GET_ROLE_SKILLS_FAILURE,
    payload,
  };
}

function getBehavioralSkills(payload) {
  return {
    type: roleTypes.GET_BEHAVIORAL_SKILLS,
    payload,
  };
}

function getBehavioralSkillsSuccess(payload) {
  return {
    type: roleTypes.GET_BEHAVIORAL_SKILLS_SUCCESS,
    payload,
  };
}

function getBehavioralSkillsFailure(payload) {
  return {
    type: roleTypes.GET_BEHAVIORAL_SKILLS_FAILURE,
    payload,
  };
}

function getCandidateJobRoleProfileToken(payload) {
  return {
    type: roleTypes.GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN,
    payload,
  };
}

function getCandidateJobRoleProfileTokenSuccess(payload) {
  return {
    type: roleTypes.GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN_SUCCESS,
    payload,
  };
}

function getCandidateJobRoleProfileTokenFailure(payload) {
  return {
    type: roleTypes.GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN_FAILURE,
    payload,
  };
}
// eslint-disable-next-line
export default {
  roleList,
  roleListSuccess,
  roleListReset,
  referenceData,
  referenceDataSuccess,
  addRole,
  addRoleSuccess,
  editRole,
  editRoleSuccess,
  deleteRole,
  deleteRoleSuccess,
  inviteList,
  inviteListSuccess,
  invitelistReset,
  addInvite,
  addInviteSuccess,
  editInvite,
  editInviteSuccess,
  deleteInvite,
  deleteInviteSuccess,
  sendInviteReminder,
  sendInviteReminderSuccess,
  extendExpirationDate,
  extendExpirationDateSuccess,
  extendExpirationReset,
  roleDetails,
  roleDetailsSuccess,
  roleDetailsFailure,
  candidateinviteList,
  candidateinviteListSuccess,
  candidateinviteDetails,
  candidateinviteDetailsSuccess,
  generateProfile,
  generateProfileSuccess,
  inviteCandidateDetails,
  inviteCandidateDetailsSuccess,
  // roleFilter,
  // roleFilterSuccess,
  roleStatus,
  roleStatusSuccess,
  // inviteFilter,
  // inviteFilterSuccess,
  inviteDetails,
  inviteDetailsSuccess,
  inviteDetailsFailure,
  candidateDashboardDetails,
  candidateDashboardDetailsSuccess,
  roleTotalCount,
  roleMyCount,
  roleAllCount,
  inviteTotalCount,
  inviteMyTotal,
  inviteAllTotal,
  roleGenerateSkill,
  roleGenerateSkillSuccess,
  deactivateActiveStatus,
  deactivateActiveStatusSuccess,
  roleMatching,
  roleMatchingSuccess,
  restTestInvite,
  restTestInviteSuccess,
  downloadCR,
  downloadCRSuccess,
  downloadReportStatus,
  // updateInviteParams,
  // updateInviteParamsSuccess,
  createConfig,
  createConfigSuccess,
  createConfigFailure,
  updateConfig,
  updateConfigSuccess,
  updateConfigFailure,
  config,
  configSuccess,
  configFailure,
  setSkillsConfig,
  jobRoleData,
  jobRoleDataSuccess,
  jobRoleDataFailure,
  getRoleFeatures,
  getRoleFeaturesSuccess,
  getRoleFeaturesFailure,
  getRoleSkills,
  getRoleSkillsSuccess,
  getRoleSkillsFailure,
  getBehavioralSkills,
  getBehavioralSkillsSuccess,
  getBehavioralSkillsFailure,
  getCandidateJobRoleProfileToken,
  getCandidateJobRoleProfileTokenSuccess,
  getCandidateJobRoleProfileTokenFailure,
};
