import React, { useEffect, useMemo, useState } from "react";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, TextField, useMediaQuery } from "@mui/material";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import ViewIcon from "../../assets/svg/viewicon.svg";
// import CopyIcon from '../../assets/svg/copy-icon.svg';
import GraphScoreMeter from "../../components/GraphScoreMeter/GraphScoreMeter";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer";
import role from "../../redux/actions/role";
import moment from "moment";
import SimpleSnackbar from "../../components/controls/ToastMessage";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import auth from "../../redux/actions/auth";
import { DatePicker } from "@mui/x-date-pickers";
import UpdateIcon from "@mui/icons-material/Update";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TimeZonePT, codingLanguageDisplay } from "../../services/globalService";
import DeactivateModel from "../../components/controls/DeactiviteModel";
import ReactiveModel from "../../components/controls/ReactiveModel";
import QuizSection from "../../components/quizsections";
import {
  // Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Tooltip,
} from "@mui/material";
import ConfigureReport from "./ConfigureReport";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ReusableDialog from "../../components/controls/ReusableDialog";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";

// FK cleanup comments july 2023
// styles for this component
const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
  },
  down_arrow: {
    padding: "8px",
    marginTop: "10px",
  },
  btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "#1789FC !important",
  },
  cancel_btn: {
    width: "112px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#787878 !important",
    boxShadow: "none !important",
  },
  previous_btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#1789FC !important",
    boxShadow: "none !important",
    border: "1px solid #C4C4C4 !important",
    marginRight: "10px !important",
  },
  field: {
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  // score number style
  scoreLeftArea: {
    // padding: 8,
    width: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   padding: 0,
    //   width: "100%",
    // },
    //background:'#ddd',
    "@media (min-width:1450px)": {
      // padding: 36,
      width: "442px",
    },
  },
  scoreText: {
    padding: "20px",
  },
  scoreMeter: {
    // marginTop: -120,

    // [theme.breakpoints.down("sm]")]:{
    //   marginTop: -68,
    //   '@media (orientation: landscape)': {
    //     marginTop:-112
    //   }
    // }
    // marginTop: -115,
    // marginBottom: "20px"
    // [theme.breakpoints.down('xs')]: {
    //   marginTop: -68,
    //   '@media (orientation: landscape)':{
    //     marginTop:-112
    //     }
    // },
    top: "-90px !important",
    position: "relative !important",
  },
  threeLine4: {
    color: "#787878",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center !important",
    fontWeight: "400 !important",
    marginTop: "10px",
    // [theme.breakpoints.up('tablet')]: {
    //   fontSize: "16px",
    //   lineHeight: "24px",
    // }
  },
  textareasty: {
    width: "100%",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
  textdot: {
    color: "#787878",
    fontSize: "48px !important",
    lineHeight: "20px",
    textAlign: "center !important",
    fontWeight: "600 !important",
    marginTop: "10px",
  },
  grid_root: {
    marginTop: "0px !important",
    marginLeft: "0px !important",
  },
  linksty: {
    color: "#1789FC",
    fontWeight: "500 !important",
    cursor: "pointer",
    padding: "0px",
    fontSize: "16px !important",
  },
  linksty1: {
    color: "#787878",
    fontWeight: "500 !important",
    // cursor: 'pointer',
    padding: "0px",
    fontSize: "16px !important",
  },
  datepickersty: {
    // "& .MuiInputBase-root":{
    //     width:'533px',
    //     backgroundColor:`${theme.palette.btnPrimary.light}`,
    //     marginTop:'10px'
    // },
    width: "0px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px !important",
    },
    "& .MuiInputAdornment-root ": {
      position: "absolute",
      top: "22px",
    },
  },
  grayText: {
    color: "#787878",
  },
  link: {
    fontFamily: "Roboto",
    fontSize: "14px !important",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
    textDecoration: "none !important",
    color: "#1789FC!important",
    "& .css-1ps4owl-MuiTypography-root-MuiLink-root": {
      textDecoration: "none !important",
      color: "#1789FC !important",
    },
  },
  linkCursor: {
    cursor: "pointer",
  },
  roundedCircle: {
    height: "12px",
    width: "12px",
    margin: "2px 3px 2px 14px",
    border: "1px solid #C4C4C4",
    borderRadius: "50%",
  },
  redLabel: {
    color: "#DD2E2E",
  },
  // graph meter container
  graphScoreMeterContainer: {
    position: "relative",
    height: "368px",
    border: "24px solid #FFFBF6",
    borderRadius: "4px",
  },
  // graph meter canvas
  graphScoreMeterCanvas: {
    position: "relative",
    height: "320px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // upto 900px mobile view container
  mobileGraphScoreMeterContainer: {
    minWidth: "500px",
    position: "relative",
    border: "24px solid #FFFBF6",
    borderRadius: "4px",
  },
  // upto 900px mobile view canvas
  mobileGraphScoreMeterCanvas: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "350px",
  },
  reportPanel: {
    border: "solid 1px #c4c4c4",
    backgroundColor: "#fffbf6",
    padding: "10px",
    borderRadius: "8px",
    marginTop: "-10px",
    height: "45px",
  },
  redText: {
    color: "#DD2E2E",
    fontSize: "14px !important",
    lineHeight: "24px",
    fontWeight: "500",
  },
  overrideContainer: {
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-8px",
  },
});
export default function InviteCandidateView() {
  const classes = useStyles();
  // const navigate = useNavigate()
  const { id } = useParams();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const isAuthLoading = useSelector((state) => state.auth.isauthLoading);
  const isInvitePageLoading = useSelector((state) => state.role.isCandidateRoleLoading);
  const [snacktext, setsnacktext] = useState("");
  const [open, setOpen] = useState(false);
  const [configureReportDialog, setConfigureReportDialog] = React.useState(false);
  const [opendialog, setOpendialog] = useState(false);
  const [reactiveopen, setReactiveopen] = useState(false);
  const [deactive, setDeactive] = useState(false);
  const [opensend, setOpensend] = useState(false);
  const [deactivebtn, setDeactivebtn] = useState(false);
  const [activebtn, setActivebtn] = useState(false);
  const [inviteLastUpdatedDate, setInviteLastUpdatedDate] = useState("");
  const [sectionDisable, setSectionDisable] = useState(false);
  const [isAllSectionScored, setIsAllSectionScored] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calenderMinDate, setCalenderMinDate] = useState(null);
  const [calenderMaxDate, setCalenderMaxDate] = useState(null);
  const [categoryToDisplay, setCategoryToDisplay] = useState([]);
  const [showReportConfig, setShowReportConfig] = useState(false);
  const [openDialogOverride, setOpenDialogOverride] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();

  let color = "#1789FC";
  const [fieldsData, setFieldsData] = useState({});
  const [showCodingLang, setShowCodingLang] = useState("");

  const handleConfigureDialog = () => {
    // FK cleanup july 2023
    setConfigureReportDialog((prevState) => !prevState);
    dispatch(role.config(id));
  };

  const sendReminder = () => {
    setOpensend(true);
  };

  // Handle the click of send remainder API
  const sendReminderDialogAction = (val) => {
    let data = {
      queryInviteId: roleState?.invitedetails?.uid,
      reminderMessage: val,
    };
    dispatch(role.sendInviteReminder(data));
    setDeactivebtn(true);
  };

  // View the Candidate dashboard
  const onViewCandidateDashbaord = () => {
    localStorage.setItem("uid", roleState?.invitedetails?.uid);
    let recruiterUserId = localStorage.getItem("userid");
    localStorage.setItem("recruiterUserId", recruiterUserId);
    localStorage.setItem("userid", roleState?.invitedetails?.candidate?.user.uid);
    let userDetails = JSON.parse(localStorage.getItem("user_details"));
    userDetails.userId = roleState?.invitedetails?.candidate?.user.uid;
    localStorage.setItem("user_details", JSON.stringify(userDetails));

    window.open("/candidatedashboard", "_blank");
  };

  // Download Report
  const onDownloadReport = () => {
    let payload = {
      inviteId: roleState?.invitedetails?.uid,
      type: "summary",
      // reportName: "invite_summary",
      accessCode: defaultReport?.token,
    };
    if (roleState?.invitedetails?.uid) {
      dispatch(role.downloadCR(payload));
    }
  };

  useEffect(() => {
    if (roleState?.downloadcr?.status === 0) {
      window.open(roleState?.downloadcr.data, "_self");
    }
    return () => dispatch(role.downloadReportStatus(null));
    // eslint-disable-next-line
  }, [roleState?.downloadcr?.status]);

  useEffect(() => {
    if (roleState?.sendinvite?.status === 0) {
      setOpen(true);
      setsnacktext("Reminder has been sent");
      setOpensend(false);
      setTimeout(() => {
        setDeactivebtn(false);
      }, 1000);
    } else if (roleState?.sendinvite?.status === 1) {
      setOpen(true);
      setDeactivebtn(false);
      setsnacktext("Something went wrong");
    }
  }, [roleState?.sendinvite?.status]);

  useEffect(() => {
    if (roleState?.invitedetails?.testExpiresOn) {
      setSelectedDate(roleState?.invitedetails?.testExpiresOn);
      let today = new Date();
      today.setDate(today.getDate() + 1);

      let maxDate = new Date(fieldsData?.createdAt);
      maxDate.setDate(maxDate.getDate() + 30);

      if (maxDate > new Date()) {
        setCalenderMinDate(today);
        setCalenderMaxDate(maxDate);
      }
    }
  }, [roleState?.invitedetails, fieldsData]);

  // handle the expire date
  const handleDateChange = (newValue) => {
    let val = moment(newValue);
    // setValue(val);
    setSelectedDate(val);
    let testExpiresOn = {
      testExpiresOn: val,
    };
    let payload = {
      id: id,
      data: testExpiresOn,
    };
    dispatch(role.extendExpirationDate(payload));
  };
  // handle test date
  useEffect(() => {
    if (roleState?.expireDate?.status === 0) {
      setOpen(true);
      setsnacktext("Test date has been updated");
    }

    return () => dispatch(role.extendExpirationReset(null));
    // eslint-disable-next-line
  }, [roleState?.expireDate?.status]);
  const OpenDeleteCandidate = () => {
    setOpendialog(true);
  };

  const handleReactive = () => {
    setReactiveopen(true);
  };

  // handle the Deactive status of Invite
  const DeativateInvite = (values) => {
    let deletepayload = {
      status: "inactive",
      note: values,
    };
    let payload2 = {
      id: id,
      data: deletepayload,
    };
    dispatch(role.deactivateActiveStatus(payload2));
    setDeactive(true);
    setDeactivebtn(true);
  };

  //Handle the Active status of Invite
  const ActivateInvite = () => {
    let activatepayload = {
      status: "active",
    };
    let payload1 = {
      id: id,
      data: activatepayload,
    };
    dispatch(role.deactivateActiveStatus(payload1));
    setDeactive(false);
    setActivebtn(true);
  };

  // Check if all sections are scored
  useEffect(() => {
    if (authState?.areaOfStrengthsAndImprovements?.sessionStatus) {
      let flag = true;
      for (let val of Object.values(authState?.areaOfStrengthsAndImprovements?.sessionStatus)) {
        if (val !== "") {
          if (val.status !== "scored") {
            flag = false;
          }
        }
      }
      setIsAllSectionScored(flag);
    }
  }, [authState?.areaOfStrengthsAndImprovements]);

  // Checking the status of the invite and then setting the state of the button.
  useEffect(() => {
    if (deactive === true && roleState?.invitestatus?.status === 0) {
      setOpendialog(false);
      setOpen(true);
      setsnacktext("Invite has been deactivated");
      setSectionDisable(true);
      setTimeout(() => {
        setDeactivebtn(false);
      }, 1000);
    } else if (deactive === false && roleState?.invitestatus?.status === 0) {
      setReactiveopen(false);
      setOpen(true);
      setsnacktext("Invite is now active");
      setSectionDisable(false);
      setTimeout(() => {
        setActivebtn(false);
      }, 1000);
    } else if (roleState?.invitestatus?.status === 1) {
      setActivebtn(false);
      setDeactivebtn(false);
    }
    // eslint-disable-next-line
  }, [roleState?.invitestatus?.status]);

  useEffect(() => {
    if (
      (roleState?.invitedetails && roleState?.invitedetails?.jobRole?.status === "inactive") ||
      (roleState?.invitedetails && roleState?.invitedetails?.status === "inactive")
    ) {
      // console.log('setting section disable true as inactive');
      setSectionDisable(true);
    } else {
      // console.log('setting section disable false');
      setSectionDisable(false);
    }

    //check for expired invite
    if (roleState?.invitedetails && moment(roleState?.invitedetails?.testExpiresOn).isBefore(new Date())) {
      // console.log('setting section disable true as expired');
      setSectionDisable(true);
    }
  }, [roleState?.invitedetails]);

  useEffect(() => {
    if (id) {
      dispatch(role.inviteDetails(id));
      dispatch(auth.getAoSI(id));
      setInviteLastUpdatedDate("");
      checkAndSetLastUpdatedDateOfInvite();
    }
    dispatch(role.config(id));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (roleState?.invitedetailsFailure?.status) {
      navigate("/pageNotFound", { state: { data: roleState.invitedetailsFailure } });
    }
  }, [roleState?.invitedetailsFailure]);

  const checkAndSetLastUpdatedDateOfInvite = () => {
    let inviteData = roleState?.invitedetails;
    if (inviteData?.L3ProfileUpdatedDate) {
      if (inviteLastUpdatedDate === "") {
        setInviteLastUpdatedDate(inviteData?.L3ProfileUpdatedDate);
      } else {
        if (moment(inviteData.L3ProfileUpdatedDate).isAfter(moment(inviteLastUpdatedDate))) {
          setInviteLastUpdatedDate(inviteData?.L3ProfileUpdatedDate);
        }
      }
    }
    if (inviteData?.L2TechnicalScoreUpdatedDate) {
      if (inviteLastUpdatedDate === "") {
        setInviteLastUpdatedDate(inviteData?.L2TechnicalScoreUpdatedDate);
      } else {
        if (moment(inviteData.L2TechnicalScoreUpdatedDate).isAfter(moment(inviteLastUpdatedDate))) {
          setInviteLastUpdatedDate(inviteData?.L2TechnicalScoreUpdatedDate);
        }
      }
    }
    if (inviteData?.L3CodingUpdatedDate) {
      if (inviteLastUpdatedDate === "") {
        setInviteLastUpdatedDate(inviteData?.L3CodingUpdatedDate);
      } else {
        if (moment(inviteData.L3CodingUpdatedDate).isAfter(moment(inviteLastUpdatedDate))) {
          setInviteLastUpdatedDate(inviteData?.L3CodingUpdatedDate);
        }
      }
    }
    if (inviteData?.L3PresentationUpdatedDate) {
      if (inviteLastUpdatedDate === "") {
        setInviteLastUpdatedDate(inviteData?.L3PresentationUpdatedDate);
      } else {
        if (moment(inviteData.L3PresentationUpdatedDate).isAfter(moment(inviteLastUpdatedDate))) {
          setInviteLastUpdatedDate(inviteData?.L3PresentationUpdatedDate);
        }
      }
    }
    // console.log(inviteLastUpdatedDate);
  };

  useEffect(() => {
    checkAndSetLastUpdatedDateOfInvite();
  }, [roleState?.invitedetails]);

  useEffect(() => {
    if (roleState?.invitedetails) {
      let codingLang =
        roleState?.invitedetails?.isAttemptedCoding == 0
          ? [...roleState?.invitedetails?.jobRole?.codingLang]
          : roleState?.invitedetails?.isAttemptedCoding == 1
          ? roleState?.invitedetails?.codingLang
          : [];
      if (codingLang.length > 1) {
        setShowCodingLang(codingLanguageDisplay(codingLang));
      } else {
        setShowCodingLang(codingLang.toString());
      }
      setFieldsData(roleState?.invitedetails);
    }
  }, [roleState?.invitedetails]);
  const checkNullOrUndefined = (val) => {
    if (val !== undefined && val !== null) {
      return val;
    } else {
      return "";
    }
  };

  /* Sorting the array of objects by weightage in descending order and then by skill in ascending order. */

  const handleSort = useMemo(() => {
    return fieldsData?.jobRole?.skillDetails?.slice().sort(function (a, b) {
      // First, sort by weightage
      const weightageDiff = b.weightage - a.weightage;
      if (weightageDiff !== 0) {
        return weightageDiff;
      } else {
        // If weightages are the same, sort by fullName
        const fullNameA = authState?.skillsConfig[a.skill]?.fullName || "";
        const fullNameB = authState?.skillsConfig[b.skill]?.fullName || "";
        return fullNameA.localeCompare(fullNameB);
      }
    });
  }, [fieldsData?.jobRole?.skillDetails, authState?.skillsConfig]);

  // check default true in report array
  const defaultReport = roleState?.invitedetails?.reports?.find((report) => report.default);

  const copyDownloadLink = () => {
    navigator.clipboard.writeText(defaultReport?.link);
    setOpen(true);
    setsnacktext("Link copied successfully.");
  };
  const openDownloadLink = () => {
    window.open(defaultReport?.link);
  };

  useEffect(() => {
    if (roleState?.roleCategories && fieldsData?.jobRole?.category) {
      const categoryMapping = roleState?.roleCategories;
      if (categoryMapping && Array.isArray(fieldsData?.jobRole?.category)) {
        const categoryDisplayValue = fieldsData?.jobRole?.category.map((category) => categoryMapping[category]);
        setCategoryToDisplay(categoryDisplayValue);
      }
    }
  }, [roleState?.roleCategories, fieldsData?.jobRole?.category]);

  useEffect(() => {
    if (fieldsData?.isCompleted === 1 && isAllSectionScored) {
      setShowReportConfig(true);
    }
  }, [fieldsData?.isCompleted, isAllSectionScored, roleState?.expireDate]);

  const handleOverride = () => {
    setOpenDialogOverride(true);
  };

  const handleCloseOverride = () => {
    setOpenDialogOverride(false);
  };

  const handleConfirmOverride = () => {
    setShowReportConfig(true);
    setOpenDialogOverride(false);
  };

  return (
    <div>
      <NavBar />
      <Container maxWidth="xl" className={classes.container}>
        <Grid style={{ marginTop: "130px" }}>
          <Grid style={{ display: "flex", marginBottom: "20px" }}>
            <CustomBreadcrumbs
              links={[
                { name: "Home", path: "/home" },
                { name: "Invites", path: "/invitelist" },
                {
                  name: ` ${fieldsData?.candidate?.user?.firstName || ""} ${
                    fieldsData?.candidate?.user?.lastName || ""
                  } Invite`,
                },
              ]}
            />
          </Grid>
          {!isAuthLoading && !isInvitePageLoading ? (
            <Grid container className="Home-hero" alignItems="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Grid className={classes.spaceflex}>
                  <Typography className={classes.roletext}>
                    {fieldsData?.candidate?.user?.firstName} {fieldsData?.candidate?.user?.lastName} Invite
                  </Typography>
                  <Grid>
                    <Grid style={{ display: "flex", gap: "14px" }}>
                      <Button
                        data-rt-candidate-view-invite="deactivateinvite"
                        color="btnCls"
                        text={fieldsData?.status === "active" ? "Deactivate Invite" : "Activate Invite"}
                        // type="submit"
                        onClick={fieldsData?.status === "active" ? OpenDeleteCandidate : handleReactive}
                        width={199}
                        variant="outlined"
                      ></Button>
                      {fieldsData?.status === "active" && (
                        <Button
                          data-rt-candidate-view-invite="sendreminder"
                          color={moment(fieldsData?.testExpiresOn).isBefore(new Date()) ? "disablebtn" : "btn"}
                          text="Send Reminder"
                          // type="submit"
                          onClick={sendReminder}
                          width={199}
                          disabled={moment(fieldsData?.testExpiresOn).isBefore(new Date())}
                        ></Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <p style={{ borderBottom: "1px solid #C4C4C4" }} />

                <Grid style={{ display: "flex" }}>
                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Invite Status</Typography>
                      <Typography
                        className={classes.roletype}
                        style={{
                          color: checkNullOrUndefined(fieldsData?.status)
                            ? fieldsData?.status === "active"
                              ? "#2F2F2F"
                              : "#DD2E2E"
                            : null,
                        }}
                      >
                        {checkNullOrUndefined(fieldsData?.status)
                          ? fieldsData?.status === "active"
                            ? "Active"
                            : "Inactive"
                          : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Invite ID</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {fieldsData?.uid}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Required Degree</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {fieldsData?.jobRole?.degree}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Experience</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {checkNullOrUndefined(fieldsData?.jobRole?.yoe) ? `${fieldsData?.jobRole?.yoe} years` : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Test Expiration</Typography>
                      <Grid style={{ display: "flex" }}>
                        <Typography
                          className={classes.roletype}
                          style={{
                            color: moment(fieldsData?.testExpiresOn).isBefore(new Date()) ? "#DD2E2E" : "#2F2F2F",
                          }}
                        >
                          {checkNullOrUndefined(fieldsData?.testExpiresOn)
                            ? moment(fieldsData?.testExpiresOn).format("MMM DD , YYYY")
                            : null}
                        </Typography>
                        {fieldsData?.createdAt &&
                          selectedDate !== null &&
                          calenderMaxDate !== null &&
                          calenderMinDate !== null && (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                // open={isForcePickerOpen}
                                // onClose={() => setIsOpen(false)}
                                components={{
                                  OpenPickerIcon: UpdateIcon,
                                }}
                                //  minDate={new Date()}
                                renderInput={(props) => (
                                  <div>
                                    <TextField {...props} sx={{ svg: { color } }} className={classes.datepickersty} />
                                  </div>
                                )}
                                // label="DateTimePicker"
                                value={selectedDate}
                                onChange={handleDateChange}
                                minDate={calenderMinDate}
                                maxDate={calenderMaxDate}
                              />
                            </LocalizationProvider>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid style={{ display: "flex" }}>
                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Coding languages</Typography>
                      <Tooltip
                        title={
                          fieldsData?.isAttemptedCoding == 0
                            ? fieldsData?.jobRole?.codingLang.sort().join(" / ")
                            : fieldsData?.isAttemptedCoding == 1
                            ? fieldsData?.codingLang.sort().join(" / ")
                            : "--"
                        }
                      >
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {showCodingLang ? showCodingLang : "--"}{" "}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Invited By</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {fieldsData?.createdBy?.displayName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Invited On</Typography>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        {checkNullOrUndefined(fieldsData?.createdAt)
                          ? moment(fieldsData?.createdAt).format("MMM DD , YYYY")
                          : null}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid style={{ marginTop: "10px" }}>
                      <Typography className={classes.roletype}>Invite Notes</Typography>
                      {(fieldsData?.notesLog || []).map((note, i) => {
                        return (
                          <Grid key={i}>
                            {note.msg && (
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                {note.msg}
                              </Typography>
                            )}
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {note.what} by {note.who.displayName} on {TimeZonePT(note.when)}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={9.5} marginTop="32px">
                  {/* Material UI media query for screen more than 900px  */}
                  {matches ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.graphScoreMeterContainer}
                    >
                      <Grid item xs={12} sm={12} md={8} lg={7} className={classes.graphScoreMeterCanvas}>
                        <GraphScoreMeter
                          twoLineWithLink={true}
                          line1={`Target role : ID ${checkNullOrUndefined(fieldsData?.jobRole?.uid)}`}
                          line2={checkNullOrUndefined(fieldsData?.jobRole?.name)}
                          line3={"Role category:"}
                          line4={checkNullOrUndefined(categoryToDisplay.length > 0 ? categoryToDisplay : [])}
                          targetScore={checkNullOrUndefined(fieldsData?.jobRole?.targetScore)}
                          score={checkNullOrUndefined(fieldsData?.proportionalScore)}
                          scoreMeterCls={classes.scoreMeter}
                          className={`${classes.scoreMeter}`}
                        ></GraphScoreMeter>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={5}
                        className={classes.graphScoreMeterCanvas}
                        flexDirection="column"
                      >
                        <Typography className={classes.threeLine4}>Candidate Score</Typography>
                        <Typography
                          className={classes.textdot}
                          style={{
                            color: fieldsData?.proportionalScore
                              ? fieldsData?.proportionalScore >= fieldsData?.jobRole?.targetScore
                                ? "#4087F4"
                                : "#FF941C"
                              : "#C4C4C4",
                          }}
                        >
                          {inviteLastUpdatedDate ? fieldsData?.proportionalScore : "---"}
                        </Typography>
                        {inviteLastUpdatedDate !== "" ? (
                          <Typography className={classes.threeLine4}>
                            As of {moment(fieldsData?.testCompletionOn).format("MMM DD, YYYY")}
                          </Typography>
                        ) : (
                          <Typography className={classes.threeLine4}>--</Typography>
                        )}
                        <hr
                          style={{
                            borderBottom: "1px solid #EBEFF8",
                            width: "75%",
                          }}
                        />
                        <Typography className={classes.threeLine4}>Target Score</Typography>
                        <Typography className={classes.textdot}>{fieldsData?.jobRole?.targetScore}</Typography>
                        <Typography className={classes.threeLine4}>
                          Generated on {moment(fieldsData?.jobRole?.createdAt).format("MMM DD, YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    // Material UI media query for screen less than 900px
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.mobileGraphScoreMeterContainer}
                    >
                      <Grid item xs={11} sm={11} md={8} lg={7} className={classes.mobileGraphScoreMeterCanvas}>
                        <GraphScoreMeter
                          twoLineWithLink={true}
                          line1={`Target role : ID ${checkNullOrUndefined(fieldsData?.jobRole?.uid)}`}
                          line2={checkNullOrUndefined(fieldsData?.jobRole?.name)}
                          line3={"Role category:"}
                          line4={checkNullOrUndefined(categoryToDisplay.length > 0 ? categoryToDisplay : [])}
                          targetScore={checkNullOrUndefined(fieldsData?.jobRole?.targetScore)}
                          score={checkNullOrUndefined(fieldsData?.proportionalScore)}
                          scoreMeterCls={classes.scoreMeter}
                          className={`${classes.scoreMeter}`}
                        ></GraphScoreMeter>
                      </Grid>
                      <Grid
                        item
                        xs={11}
                        sm={11}
                        md={4}
                        lg={5}
                        className={classes.mobileGraphScoreMeterCanvas}
                        flexDirection="column"
                      >
                        <Typography className={classes.threeLine4}>Candidate Score</Typography>
                        <Typography
                          className={classes.textdot}
                          style={{
                            color: fieldsData?.proportionalScore
                              ? fieldsData?.proportionalScore >= fieldsData?.jobRole?.targetScore
                                ? "#4087F4"
                                : "#FF941C"
                              : "#C4C4C4",
                          }}
                        >
                          {inviteLastUpdatedDate ? fieldsData?.proportionalScore : "---"}
                        </Typography>
                        {inviteLastUpdatedDate !== "" ? (
                          <Typography className={classes.threeLine4}>
                            As of {moment(fieldsData?.testCompletionOn).format("MMM DD, YYYY")}
                          </Typography>
                        ) : (
                          <Typography className={classes.threeLine4}>--</Typography>
                        )}
                        <hr
                          style={{
                            borderBottom: "1px solid #EBEFF8",
                            width: "75%",
                          }}
                        />
                        <Typography className={classes.threeLine4}>Target Score</Typography>
                        <Typography className={classes.textdot}>{fieldsData?.jobRole?.targetScore}</Typography>
                        <Typography className={classes.threeLine4}>
                          Generated on {moment(fieldsData?.jobRole?.createdAt).format("MMM DD, YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {/* Section dots display */}
                <Grid item xs={10}>
                  <Grid container className={classes.grid_root}>
                    <Grid item xs={11.4}>
                      <QuizSection id={id} disabled={sectionDisable} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={2.5}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Sections to Test</Typography>
                        {(fieldsData?.selectedTestingRequirement || []).map((item, i) => {
                          return (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                              {item === "Presentation" ? "Behavioral" : item}
                            </Typography>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Override Permitted</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {checkNullOrUndefined(fieldsData?.jobRole?.isOverridePermitted) === true ? "Yes" : "No"}
                          <br />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Grid display={"flex"} justifyContent={"space-between"} gap="20px">
                          <Typography className={classes.roletype}>Skills to Test</Typography>
                        </Grid>
                        {(handleSort || []).map((skill, i) => {
                          return (
                            <Grid display={"flex"} justifyContent={"space-between"} gap="20px">
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                {/* {skill.skill} */}
                                {authState?.skillsConfig[skill.skill]?.fullName}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                        <Typography className={classes.roletype}>Assigned Weight</Typography>

                        {(handleSort || []).map((skill, i) => {
                          return (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {skill.weightage}%
                            </Typography>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <p style={{ borderBottom: "1px solid #C4C4C4" }} />

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid className={classes.textbreak}>
                        <Typography className={classes.roletype}>Relevant Experience</Typography>
                        <Typography sx={{ mt: 1, textAlign: "justify" }}>
                          {fieldsData?.weSummary ? fieldsData?.weSummary : "Not Available"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <p style={{ borderBottom: "1px solid #C4C4C4", paddingTop: "10px" }} />

                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "12px",
                  }}
                >
                  <Grid onClick={onViewCandidateDashbaord} style={{ width: "25%" }}>
                    <Typography className={classes.linksty}>
                      View Candidate Dashboard{" "}
                      <span>
                        <img src={ViewIcon} alt="ViewIcon" />
                      </span>
                    </Typography>
                  </Grid>

                  {showReportConfig ? (
                    <Grid
                      style={{
                        display: "flex",
                        width: "75%",
                        gap: "10px",
                        justifyContent: "end",
                      }}
                    >
                      <div
                        style={{ display: "flex", gap: 5, height: "auto" }}
                        className={classes.reportPanel}
                        onClick={handleConfigureDialog}
                      >
                        <Typography style={{ color: "#1789FC", fontWeight: "500", cursor: "pointer" }}>
                          Reports
                        </Typography>
                        <span style={{ color: "#1789FC", fontWeight: "500", cursor: "pointer" }}>
                          <SettingsRoundedIcon />
                        </span>
                      </div>
                      <Grid
                        className={classes.reportPanel}
                        style={{
                          height: "auto",
                          display: "flex",
                          width: "220px",
                          gap: "20px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid style={{ width: "120px" }}>
                          <Grid container>
                            <Grid item xs={5} style={{ textAlign: "center" }}>
                              <Typography style={{ color: "#1789FC", fontWeight: "500" }}>Link</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography className={classes.linksty}>
                                <span onClick={copyDownloadLink}>
                                  <ContentCopyOutlinedIcon
                                    style={{
                                      verticalAlign: "bottom",
                                      color: "#1789FC",
                                    }}
                                  />
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              &nbsp;
                            </Grid>
                            <Grid item xs={3}>
                              <Typography className={classes.linksty}>
                                <span onClick={openDownloadLink}>
                                  <OpenInNewOutlinedIcon
                                    style={{
                                      verticalAlign: "bottom",
                                      color: "#1789FC",
                                    }}
                                  />
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid style={{ width: "155px", textAlign: "end" }}>
                          <Typography className={classes.linksty} onClick={onDownloadReport}>
                            Summary{" "}
                            <span>
                              <DownloadIcon
                                style={{
                                  verticalAlign: "bottom",
                                  color: "#1789FC",
                                }}
                              />{" "}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Configure dialog */}
                      {configureReportDialog && (
                        <ConfigureReport
                          open={configureReportDialog}
                          onClose={handleConfigureDialog}
                          fieldData={fieldsData}
                          setFieldsData={setFieldsData}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grid
                      style={{
                        display: "flex",
                        width: "75%",
                        gap: "10px",
                        justifyContent: "end",
                      }}
                    >
                      {isAllSectionScored &&
                      roleState?.candidateDashboardDetails?.testCompleted ===
                        roleState?.candidateDashboardDetails?.selectedTestingRequirement?.length &&
                      moment(new Date()) < moment(roleState?.candidateDashboardDetails?.testExpiresOn) ? (
                        <Grid className={classes.overrideContainer}>
                          <Typography className={classes.redText}>
                            Report will be available once invite is submitted.
                          </Typography>
                          <Typography className={`${classes.link} ${classes.linkCursor}`} onClick={handleOverride}>
                            Download report anyway
                          </Typography>
                        </Grid>
                      ) : null}

                      <div style={{ display: "flex", gap: 5, height: "auto" }} className={classes.reportPanel}>
                        <Typography style={{ color: "#787878", fontWeight: "500", cursor: "pointer" }}>
                          Reports
                        </Typography>
                        <span style={{ color: "#787878", fontWeight: "500", cursor: "pointer" }}>
                          <SettingsRoundedIcon />
                        </span>
                      </div>

                      <Grid
                        className={classes.reportPanel}
                        style={{
                          height: "auto",
                          display: "flex",
                          width: "220px",
                          gap: "20px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid style={{ width: "120px" }}>
                          <Grid container>
                            <Grid item xs={5} style={{ textAlign: "center" }}>
                              <Typography style={{ color: "#787878", fontWeight: "500" }}>Link</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography className={classes.linksty1}>
                                <ContentCopyOutlinedIcon
                                  style={{
                                    verticalAlign: "bottom",
                                    color: "#787878",
                                  }}
                                />
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              &nbsp;
                            </Grid>
                            <Grid item xs={3}>
                              <Typography className={classes.linksty1}>
                                <OpenInNewOutlinedIcon
                                  style={{
                                    verticalAlign: "bottom",
                                    color: "#787878",
                                  }}
                                />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid style={{ width: "155px", textAlign: "end" }}>
                          <Typography className={classes.linksty1}>
                            Summary{" "}
                            <span>
                              <DownloadIcon
                                style={{
                                  verticalAlign: "bottom",
                                  color: "#787878",
                                }}
                              />{" "}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          ) : null}
        </Grid>
      </Container>
      {!isAuthLoading && !isInvitePageLoading ? <Footer /> : null}
      <DeactivateModel
        open={opendialog}
        setOpen={setOpendialog}
        DeleteListData={(values) => DeativateInvite(values)}
        Title={"Deactivate Invite"}
        Text={
          "An inactive candidate will not show up on the invite list and cannot be invited to take new tests. To continue, please provide a reason below."
        }
        deactiveText={"Why are you deactiving this invite?"}
        Deactivebtn={deactivebtn}
      />
      <ReactiveModel
        open={reactiveopen}
        OnReactive={ActivateInvite}
        onClose={() => setReactiveopen(false)}
        Text={
          "Setting an invite as active allows the candidate to continue with the test. Are you sure you want to activate?"
        }
        Activatebtn={activebtn}
      />
      <DeactivateModel
        open={opensend}
        setOpen={setOpensend}
        DeleteListData={(values) => sendReminderDialogAction(values)}
        Title={"Send Reminder"}
        Text={"Include a message in the reminder for the candidate to take the test before the expiration date."}
        deactiveText={"Ex. Remember to complete your test in 3 days"}
        textvalue={"Message to the candidate"}
        sendreminder={"Send Reminder"}
        Deactivebtn={deactivebtn}
      />
      <SimpleSnackbar open={open} setOpen={setOpen} text={snacktext} />
      <ReusableDialog
        open={openDialogOverride}
        onClose={handleCloseOverride}
        title="Confirm"
        content={
          <Typography>
            Candidate has not submitted the invite. The final report may change. Are you sure you want to download the
            report now?
          </Typography>
        }
        actions={[
          { onClick: handleCloseOverride, color: "btnCls1", variant: "outlined", text: "Cancel" },
          { onClick: handleConfirmOverride, color: "btn1", autoFocus: true, text: "Confirm" },
        ]}
      />
      {/* <Dialog open={openDialogOverride} onClose={handleCloseOverride}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className={classes.textValue}>
              Candidate has not submitted the invite. The final report may change. Are you sure you want to download the
              report now?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOverride} text="Cancel" color="btnCls1" variant="outlined" />
          <Button onClick={handleConfirmOverride} text="Confirm" color="btn1" />
        </DialogActions>
      </Dialog> */}
    </div>
  );
}
