import React from "react";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import BulkResumes from "./ImportBulkResumes/BulkResumes";
import Footer from "../../components/Footer";

const useStyles = makeStyles({
  container: {
    minHeight: "calc(100vh - 100px)",
    width: "70%",
    marginTop: "135px",
  },
});

const ImportBulkResumes = () => {
  const classes = useStyles();

  return (
    <div>
      <NavBar />
      <Container maxWidth="xl" className={classes.container}>
        <BulkResumes />
      </Container>
      <Footer />
    </div>
  );
};

export default ImportBulkResumes;
