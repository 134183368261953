import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import chevronright from "../../assets/svg/chevronright.svg";

const useStyles = makeStyles({
  // scrollbar styling
  btnscroll: {
    position: "fixed",
    width: "100%",
    left: "85%",
    bottom: "100px",
    height: "20px",
    fontSize: "3rem",
    zIndex: "1",
    cursor: "pointer",
  },
  whiteBackground: {
    width: "40px",
    height: "40px",
    borderRadius: "30px",
    backgroundColor: "#FFFBF6",
    marginTop: "-46px",
    boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.06)",
  },
  imgScroll: {
    position: "fixed",
    padding: "14px 0px 6px 12px",
  },
});

const ScrollButton = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div onClick={scrollToTop} className={classes.btnscroll} style={{ display: visible ? "inline" : "none" }}>
      <div className={classes.whiteBackground}>
        <img src={chevronright} alt="chevronright" className={classes.imgScroll} />
      </div>
    </div>
  );
};

export default ScrollButton;
