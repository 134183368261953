const chartTypes = {
  CHART_DATA: "CHART_DATA",
  CHART_DATA_SUCCESS: "CHART_DATA_SUCCESS",

  STACK_CHART_DATA: "STACK_CHART_DATA",
  STACK_CHART_DATA_SUCCESS: "STACK_CHART_DATA_SUCCESS",

  PIE_CHART_DATA: "PIE_CHART_DATA",
  PIE_CHART_DATA_SUCCESS: "PIE_CHART_DATA_SUCCESS",

  SCATTER_CHART_DATA: "SCATTER_CHART_DATA",
  SCATTER_CHART_DATA_SUCCESS: "SCATTER_CHART_DATA_SUCCESS",

  CANDIDATES_CHART_DATA: "CANDIDATES_CHART_DATA",
  CANDIDATES_CHART_DATA_SUCCESS: "CANDIDATES_CHART_DATA_SUCCESS",

  ROLE_CHART_DATA: "ROLE_CHART_DATA",
  ROLE_CHART_DATA_SUCCESS: "ROLE_CHART_DATA_SUCCESS",
};

export default chartTypes;
