import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import quiz from "../redux/actions/quiz";
import { useDispatch, useSelector } from "react-redux";
import { checkCameraAndMicrophonePermission, checkGeolocationPermission } from "../services/globalService";
import role from "../redux/actions/role";

// Define the duration of each video segment in milliseconds
const SEGMENT_DURATION = 60 * 1000;

function SessionTracking(props) {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const componentUnmounted = useRef(false);
  const videoRef = useRef(null);
  const chunksRef = useRef([]);
  const [chunkCount, setChunkCount] = useState(1);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [mainStream, setMainStream] = useState(null);
  const dispatch = useDispatch();
  const [browserScreenWidth, setBrowserScreenWidth] = useState(window.innerWidth);
  const [browserScreenHeight, setBrowserScreenHeight] = useState(window.innerHeight);
  // const proctoringDetails = JSON.parse(localStorage.getItem("proctoring"));

  const proctoringDetails = useSelector((state) => state.role.candidateDashboardDetails.proctoring);
  const uid = localStorage.getItem("uid");

  // This function checks the status of the camera, microphone, and geolocation
  const getImageMicGeoDeviceInfo = async () => {
    let cameraAndMicrophoneStatus = {
      camera: false,
      mic: false,
    };

    let geolocationPermissionStatus = {
      permission: false,
      latitude: null,
      longitude: null,
    };
    if (proctoringDetails?.webcam !== "notRequired") {
      cameraAndMicrophoneStatus = await checkCameraAndMicrophonePermission(false);
    }
    if (proctoringDetails?.geoLocation !== "notRequired") {
      geolocationPermissionStatus = await checkGeolocationPermission();
    }
    setBrowserScreenWidth(window.innerWidth);
    setBrowserScreenHeight(window.innerHeight);
    // console.log(window.innerWidth, "browserScreenWidth");
    // console.log(window.innerHeight, "browserScreenHeight");

    const initialImage = captureImage();
    if (initialImage === "") {
      console.log(initialImage, "getting initialImage is empty");
    }

    console.log("======cameraAndMicrophoneStatus======\n", cameraAndMicrophoneStatus);

    if (
      props?.sessionCode &&
      initialImage !== "" &&
      cameraAndMicrophoneStatus.camera !== false &&
      proctoringDetails?.candidateImageCapture
    ) {
      dispatch(
        quiz.candidateCameraCapture({
          code: props?.sessionCode,
          data: {
            imageDataSource: initialImage,
            geoLocationPermission: geolocationPermissionStatus?.permission,
            cameraPermission: cameraAndMicrophoneStatus?.camera,
            micPermission: cameraAndMicrophoneStatus?.mic,
            browserScreenWidth,
            browserScreenHeight,
          },
        })
      );
      console.log(initialImage, "initialImage imageDataSource");
    }
  };

  // This useEffect hook initializes the media stream when the component mounts
  useEffect(() => {
    if (!proctoringDetails) {
      dispatch(role.candidateDashboardDetails(uid));
    }
    // console.log("Executing first useEffect hook");
    async function init() {
      try {
        setMainStream(await navigator.mediaDevices.getUserMedia({ video: true, audio: true }));
      } catch (e) {
        console.error("navigator.getUserMedia error:", e);
      }
    }
    // no need of creating media stream while webcam is not required
    if (proctoringDetails?.webcam !== "notRequired") {
      init();
    }
  }, []);

  // This useEffect hook starts recording when the mainStream is available
  useEffect(() => {
    console.log("mainStream changes------");
    if (mainStream !== null) {
      displayVideoStream(mainStream);
      // startRecording();

      // Call the function to get the camera, mic and geolocation info initially
      setTimeout(() => {
        startRecording();
      }, 500);
    }
  }, [mainStream]);

  /**
   * Tracks the session and performs various operations related to session recording.
   *
   * @param {string} media - The media type (e.g., "video", "audio").
   * @param {string[]} types - An array of supported media types (e.g., ["mp4", "webm", "ogg"]).
   * @param {string[]} codecs - An array of supported codecs.
   * @returns {string[]} - An array of supported mime types.
   */
  // This function checks for supported mime types
  function getSupportedMimeTypes(media, types, codecs) {
    const isSupported = MediaRecorder.isTypeSupported;
    const supported = [];
    types.forEach((type) => {
      const mimeType = `${media}/${type}`;
      codecs.forEach((codec) =>
        [`${mimeType};codecs=${codec}`].forEach((variation) => {
          if (isSupported(variation)) supported.push(variation);
        })
      );
      if (isSupported(mimeType)) supported.push(mimeType);
    });
    return supported;
  }

  const videoTypes = ["mp4", "webm", "ogg", "x-matroska"];
  // const audioTypes = ["webm", "ogg", "mp3", "x-matroska"];
  const codecs = [
    "should-not-be-supported",
    "avc1.424028, mp4a.40.2",
    "avc1.424028, opus",
    "avc1.42e01e,opus",
    "vp9,opus",
    "vp9.0",
    "vp90",
    "vp8,opus",
    "vp8.0",
    "vp80",
    "avc1",
    "av1",
    "av01",
    "hev1",
    "hvc1",
    "h265",
    "h.265",
    "h264",
    "h.264",
    "opus",
    "pcm",
    "aac",
    "mpeg",
    "mp4a",
    "mp4v,opus",
    "theora,vorbis",
  ];

  const supportedVideos = getSupportedMimeTypes("video", videoTypes, codecs);

  // This function starts the recording process
  async function recordingStart() {
    // console.log("Executing recordingStart function");
    try {
      console.log("recordingStart");
      const mediaRecorderOptions = {
        mimeType: supportedVideos[0],
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 250000,
      };
      mediaRecorderRef.current = new MediaRecorder(mainStream, mediaRecorderOptions);
      mediaRecorderRef.current.ondataavailable = onDataAvailable;
      mediaRecorderRef.current.onstop = onRecordingStop;
      mediaRecorderRef.current.start();
      setTimeout(() => {
        mediaRecorderRef.current.stop();
      }, SEGMENT_DURATION);

      // Get the camera, mic and geolocation info
      getImageMicGeoDeviceInfo();
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  }

  // This function captures an image from the video stream
  function captureImage() {
    if (videoRef) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext("2d").drawImage(videoRef.current, 0, 0);
      const dataUrl = canvas.toDataURL("image/jpeg", 0.7); // we can change "image/png" to "image/jpeg" and add quality parameter
      return dataUrl;
    } else {
      return "";
    }
  }

  // This function handles the availability of data in the event
  function onDataAvailable(event) {
    console.log("onDataAvailable", event.data);
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  }

  // This function handles the stop of recording
  async function onRecordingStop() {
    // console.log("Executing onRecordingStop function");
    try {
      // console.log(isRecording, "isRecording on stop");
      console.log("onRecordingStop", chunksRef.current);

      const mimeType = supportedVideos[0].split(";")[0];
      const recordedChunks = chunksRef.current;
      const recordedBlob = new Blob(recordedChunks, { type: mimeType });
      let fileName = `${props.inviteId}_${props.sessionCode}_${new Date().getTime()}_${chunkCount}.${
        mimeType.split("/")[1]
      }`;

      chunksRef.current = [];
      setChunkCount(chunkCount + 1);

      setRecordedBlob({ fileName, blob: recordedBlob });

      if (componentUnmounted.current == true) {
        console.log("Last video chunk------");
        await uploadChunkOnS3(fileName, recordedBlob);
      }

      // downloadChunk(recordedBlob,fileName);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Uploads a chunk of data to S3.
   *
   * @param {string} fileName - The name of the file.
   * @param {Blob} blobData - The chunk of data to upload.
   * @returns {Promise<void>} - A promise that resolves when the chunk is uploaded successfully.
   */
  async function uploadChunkOnS3(fileName, blobData) {
    // console.log("Executing uploadChunkOnS3 function");
    console.log("Upload chunk------", fileName);
    let signedUrl = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/sessionTracking/signedUrlToUploadChunk`,
      data: { fileName: fileName },
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    var requestOptions = {
      method: "PUT",
      body: blobData,
      redirect: "follow",
    };

    fetch(signedUrl?.data?.data?.url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log(result);
        // console.log("Chunk uploaded successfully" + fileName);
        if (componentUnmounted.current == true) {
          // console.log("Stoped media tracks------");
          mainStream.getTracks().forEach((track) => track.stop());
        }
      })
      .catch((error) => console.log("error", error));
  }

  // This useEffect hook uploads the video to the server when recordedBlob changes
  useEffect(() => {
    // console.log("Executing third useEffect hook");
    if (recordedBlob) {
      console.log("\nUploaded chunk on s3------");
      uploadChunkOnS3(recordedBlob.fileName, recordedBlob.blob);
    }
  }, [recordedBlob]);

  // Download the video chunk
  // function downloadChunk(blob,fileName) {
  //   try {
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.style.display = 'none';
  //     a.href = url;
  //     const extension = blob.type.split('/')[1];
  //     console.log('Extension:', extension);
  //     a.download = fileName;
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   }
  //   catch(err) {
  //     console.log(err);
  //   }
  // }

  // const stopRecording = () => {
  // console.log(mainStream, 'mainStream188')

  //   console.log("stopRecordingCalled")
  //   setIsRecording(false);
  // };

  // This function starts the recording process
  function startRecording() {
    // console.log("Executing startRecording function");
    setIsRecording(true);
  }

  // This useEffect hook starts a new recording when chunkCount changes
  useEffect(() => {
    // console.log("Executing fourth useEffect hook");
    if (chunkCount > 1 && isRecording) {
      recordingStart();
    }
  }, [chunkCount]);

  // This useEffect hook starts a new recording when isRecording changes
  useEffect(() => {
    // console.log("Executing fifth useEffect hook");
    if (isRecording) {
      recordingStart();
    }
  }, [isRecording]);

  // This useEffect hook stops the recording when the component unmounts
  useEffect(() => {
    // console.log("Executing sixth useEffect hook");
    return async () => {
      console.log("***Component unmounted-----1");
      if (mediaRecorderRef.current) {
        console.log("***Component unmounted-----2");
        componentUnmounted.current = true;
        mediaRecorderRef.current?.stop();
      }
    };
  }, []);

  // This function displays the video stream in the video element
  function displayVideoStream(mainStream) {
    // console.log("Executing displayVideoStream function");
    const gumVideo = document.querySelector("video#gum");
    gumVideo.srcObject = mainStream;
    gumVideo.muted = true;
  }

  return (
    <div>
      <video id="gum" ref={videoRef} autoPlay muted style={{ width: "160px", height: "120px" }}></video>
    </div>
  );
}

export default SessionTracking;
