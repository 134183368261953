import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { List, ListItem, ListItemText, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
// import BayoneLogo from "../../assets/svg/bayonelogo.svg";
import authState from "../../redux/actions/auth";
import quiz from "../../redux/actions/quiz";
// import EditIcon from "@mui/icons-material/Edit";
// import FeedbackIcon from "@mui/icons-material/Feedback";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import Button from "./Button";
// import SimpleSnackbar from "../controls/ToastMessage";
// import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../styles/theme";
import MenuIcon from "@mui/icons-material/Menu";

import HomeIcon from "../../assets/svg/home.svg";
import RolesIcon from "../../assets/svg/roles.svg";
import RecruitmentProgressIcon from "../../assets/svg/recruitmentProgress.svg";
import RecruitmentIcon from "../../assets/svg/recruitment.svg";
import LogoutIcon from "../../assets/svg/logout.svg";
import InvitIcon from "../../assets/svg/normalinviteicon.svg";
import Divider from "@mui/material/Divider";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
// import SyncLockOutlinedIcon from "@mui/icons-material/SyncLockOutlined";
// import auth from "../../redux/actions/auth";

const useStyles = makeStyles({
  list: {
    width: "100%",
  },
  fullList: {
    width: "auto",
  },
  title: {
    flexGrow: 1,
    width: 240,
  },
  login: {
    marginRight: "16px",
  },
  toolBar: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  navBar: {
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    backgroundColor: theme.palette.primary.light,
  },
  containerCls: {
    [theme.breakpoints.up("tablet")]: {
      paddingRight: "64px !important",
      paddingLeft: "64px !important",
    },
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    marginTop: 12,
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  topNavIcon: {
    height: "8px",
    width: "16px",
    color: "#000",
  },
  swipeableDrawer: {
    width: "100%",
    zIndex: "99999999999999999 !important",
    "& .MuiDrawer-paperAnchorLeft": {
      width: "100%",
      backgroundColor: theme.palette.primary.light,
    },
    "& .MuiDrawer-root": {
      zIndex: "99999999999999999 !important",
    },
  },
  closeDrawer: {
    width: "14px",
    height: "14px",
    marginLeft: "16px",
    marginTop: "24px",
  },
  drawerContent: {
    margin: "24px auto",
    height: "fit-content",
    maxWidth: "min-content",
    whiteSpace: "nowrap",
    "@media (orientation: landscape)": {
      minHeight: "400px",
    },
  },
  editTcon: {
    width: "18px",
    height: "18px",
    color: `${theme.palette.btnPrimary.main}`,
  },
  listItemText: {
    justifyContent: "center !important",
    "& .MuiTypography-root": {
      fontFamily: theme.typography.subtitle2.fontFamily,
      fontSize: theme.typography.b1Medium.fontSize,
      fontStyle: theme.typography.subtitle2.fontStyle,
      fontWeight: theme.typography.b1Medium.fontWeight,
      lineHeight: `${theme.typography.b1Medium.lineHeight} !important`,
      letterSpacing: theme.typography.subtitle2.letterSpacing,
      textalign: "left",
      color: `${theme.palette.btnPrimary.main}`,
    },
    "& .MuiListItemText-root": {
      flex: `none !important`,
    },
  },
  editTconDark: {
    width: "18px",
    height: "18px",
    color: `${theme.palette.btnPrimary.tertiaryText}`,
  },
  listItemTextDark: {
    justifyContent: "center !important",
    "& .MuiTypography-root": {
      fontFamily: theme.typography.subtitle2.fontFamily,
      fontSize: theme.typography.b1Medium.fontSize,
      fontStyle: theme.typography.subtitle2.fontStyle,
      fontWeight: theme.typography.b1Medium.fontWeight,
      lineHeight: `${theme.typography.b1Medium.lineHeight} !important`,
      letterSpacing: theme.typography.subtitle2.letterSpacing,
      textalign: "left",
      color: `${theme.palette.btnPrimary.tertiaryText}`,
    },
    "& .MuiListItemText-root": {
      flex: `none !important`,
    },
  },
  toolSt: {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
});

export default function SwipeableTemporaryDrawer(props) {
  // const { children } = props;

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [logoUrl, setLogoUrl] = React.useState(process.env.REACT_APP_ASSETS_URL);
  const [domainDetails, setDomainDetails] = useState({});
  // const theme = useTheme();
  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onClickLogout = () => {
    dispatch(authState.clearState());
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    dispatch(quiz.clearState());
    navigate("/");
  };

  const navigateToRoute = (navurl) => {
    switch (navurl) {
      case "home":
        navigate("/home");
        break;
      case "candidates":
        navigate("/candidatelist");
        break;
      case "invites":
        navigate("/invitelist");
        break;
      case "roles":
        navigate("/rolelist");
        break;
      case "recruiters":
        navigate("/userlist");
        break;
      case "manageAccount":
        navigate("/accountSettings");
        break;
      case "documentation":
        navigate("/documentation");
        break;
      default:
        navigate("/home");
        break;
      // case 'changePassword':
      //     navigate('/changePassword')
      //     break;
    }
  };

  const list = (anchor) => (
    <>
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div>
          <div>
            {/* <img
              src={`${ImagebaseUrl}assets/closeDrawer.png`}
              className={classes.closeDrawer}
              onClick={toggleDrawer(anchor, false)}
            /> */}
            <CloseIcon className={classes.closeDrawer} onClick={toggleDrawer(anchor, false)} />
          </div>
          <div className={classes.drawerContent}>
            {/* {!auth.user && ( */}
            {/* <List>
                <ListItem button key={"Log In"}   className={classes.listItemText}>
            
                  <Button
                onClick={() => NavButtons("/Login")}
                  color="btnSecondary"
                  width="140px"
                  text="Log In"
                ></Button>
                </ListItem>
                <ListItem
                  button
                  key={"Join Now"}
                  onClick={() => NavButtons("/CreateAccount")}
                  className={classes.listItemTextDark}
                  
                >
                  <ListItemText primary={"Join Now"}  />
                </ListItem>
              </List> */}
            {/* )}

            {auth.user && ( */}
            {userDetails.role !== "Candidate" ? (
              <List>
                <ListItem button key={"Home"}>
                  <ListItemText primary={"Home"} onClick={() => navigateToRoute("home")} />
                  <IconButton>
                    {/* <EditIcon className={classes.editTcon} /> */}
                    <img src={HomeIcon} alt="home-icon" />
                  </IconButton>
                </ListItem>

                <ListItem button key={"Candidates"} onClick={() => navigateToRoute("candidates")}>
                  <ListItemText primary={"Candidates"} />
                  <IconButton>
                    {/* <EditIcon className={classes.editTcon} /> */}
                    <img src={RecruitmentProgressIcon} alt="progress-icon" />
                  </IconButton>
                </ListItem>

                <ListItem button key={"Invites"} onClick={() => navigateToRoute("invites")}>
                  <ListItemText primary={"Invites"} />
                  <IconButton>
                    {/* <MailOutlineIcon className={classes.editTcon} /> */}
                    <img src={InvitIcon} alt="invite-icon" />
                  </IconButton>
                </ListItem>

                <ListItem button key={"Roles"} onClick={() => navigateToRoute("roles")}>
                  <ListItemText primary={"Roles"} />
                  <IconButton>
                    {/* <FeedbackIcon className={classes.editTcon} /> */}
                    <img src={RolesIcon} alt="roles-icon" />
                  </IconButton>
                </ListItem>

                <ListItem button key={"Recruiters"} onClick={() => navigateToRoute("recruiters")}>
                  <ListItemText primary={"Team"} />
                  <IconButton>
                    {/* <FeedbackIcon className={classes.editTcon} /> */}
                    <img src={RecruitmentIcon} alt="recruitment-icon" />
                  </IconButton>
                </ListItem>
                <Divider variant="middle" />
                {userDetails.role === "Recruiter" ? (
                  <div>
                    <ListItem button key={"manageAccount"} onClick={() => navigateToRoute("manageAccount")}>
                      <ListItemText primary={"Manage Account"} />
                      <IconButton>
                        {/* <FeedbackIcon className={classes.editTcon} /> */}
                        <ManageAccountsOutlinedIcon fontSize="small" color="action" />
                      </IconButton>
                    </ListItem>
                  </div>
                ) : null}
                {userDetails.role === "Recruiter" ? (
                  <div>
                    <ListItem button key={"documentation"} onClick={() => navigateToRoute("documentation")}>
                      <ListItemText primary={"Documentation"} />
                      <IconButton>
                        <DescriptionIcon fontSize="small" color="action" />
                      </IconButton>
                    </ListItem>
                  </div>
                ) : null}

                <ListItem button key={"Log Out"} onClick={() => onClickLogout()}>
                  <ListItemText primary={"Log Out"} />
                  <IconButton>
                    {/* <ExitToAppIcon className={classes.editTconDark} /> */}
                    <img src={LogoutIcon} alt="logout-icon" />
                  </IconButton>
                </ListItem>
              </List>
            ) : (
              <List>
                <ListItem button key={"Log Out"} onClick={() => onClickLogout()}>
                  <ListItemText primary={"Log Out"} />
                  <IconButton>
                    {/* <ExitToAppIcon className={classes.editTconDark} /> */}
                    <img src={LogoutIcon} alt="logout-icon" />
                  </IconButton>
                </ListItem>
              </List>
            )}
            {/* )} */}
          </div>
          {/* <div className={classes.drawerFooter}>
            <Footer leftDrawer={true}></Footer>
          </div> */}
        </div>
      </div>
    </>
  );

  const toolbar = (anchor) => (
    <>
      <Toolbar className={classes.toolSt}>
        <Grid container>
          <Grid item xs={4}>
            {/* <img
              src={`${ImagebaseUrl}assets/top-nav-icon.png`}
              className={classes.topNavIcon}
              onClick={toggleDrawer(anchor, true)}
            /> */}
            <MenuIcon onClick={toggleDrawer(anchor, true)} className={classes.topNavIcon} />
          </Grid>
          <Grid item xs={4} className={`${classes.toolBar} ${classes.center}`}>
            {/* <img src={BayoneLogo} className={classes.logoCls} /> */}
            <div>
              {domainDetails && domainDetails?.logo !== "" ? (
                <>
                  <img
                    height="33"
                    width="157"
                    src={`${logoUrl}${domainDetails?.logo}`}
                    alt={domainDetails?.displayName}
                  />
                  <br />
                  <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
                </>
              ) : (
                <a href="https://techscore.ai" target="_self" rel="noreferrer">
                  <img height="50" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
                </a>
              )}
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Toolbar>
    </>
  );

  useEffect(() => {
    setDomainDetails({ logo: localStorage.getItem("logo"), displayName: "" });
  }, [localStorage.getItem("logo")]);

  return (
    <div>
      {["left"].map((anchor) => (
        <div key={anchor}>
          {toolbar(anchor)}
          <SwipeableDrawer
            className={`${classes.swipeableDrawer}`}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </div>
      ))}
      {/* <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackBar(false)}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClose={() => setOpenSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      /> */}
    </div>
  );
}
