import React, { Fragment, useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Grid } from "@mui/material";
import GridTable from "../../components/Table";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import SearchBar from "../../components/controls/search";
import { useNavigate, generatePath } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import auth from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer";
import CustomPagination from "../../components/controls/CustomPagination";
import CheckSelectMenu from "../../components/controls/Checkmenu";
import HeaderConfig from "../../constants/HeaderConfigs";
import { sortJson, applySearch } from "../../services/globalService";
import Tour from "../../components/Tour";
// import TrialExpiryNotification from "../../components/TrialExpiryNotification";
import Notification from "../../components/AnnouncementNotification";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";

const addedByMenuProps = {
  PaperProps: {
    style: {
      // width: 194,
      //height: 300,
      padding: 12,
      marginLeft: 40,
    },
  },
};

const statusMenuProps = {
  PaperProps: {
    style: {
      // width: 194,
      // height: 188,
      padding: 12,
      marginLeft: 45,
    },
  },
};

const recruiterMenuProps = {
  PaperProps: {
    style: {
      // width: 194,
      //height: 188,
      padding: 12,
      marginLeft: 18,
    },
  },
};
const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
    // paddingBottom: "50px"
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  allJob: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "24px !important",
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    color: "#2F2F2F",
  },
  btnCls: {
    width: 300,
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    height: "30px !important",
    // height: '40px' //duplicte
  },
  Allsty: {
    boxSizing: "border-box",

    /* Auto layout */

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: " center",
    padding: "8px 12px",
    gap: "10px",
    width: "48px",
    height: "40px",
    border: "1px solid #787878",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: "0px -1px",

    fontSize: "16px",
    fontWeight: 500,
    cursor: "pointer",
  },
  Alltext: {
    display: "flex",
    //flexDirection: "column",
  },
  Allbtn: {
    borderRadius: "4px 0px 0px 4px",
    color: "#FFFFFF",
    background: "#787878",
  },
  Mybtn: {
    borderRadius: "0px 4px 4px 0px",
    color: "#787878",
  },
  filtersty: {
    width: "101px",
    height: "40px",
    border: "1px solid #787878",
    color: "#787878",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-evenly",
    cursor: "pointer",
  },
  selectSty: {
    width: "101px",
    height: "50px",
    border: "1px solid #787878",
    color: "#787878",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-evenly",
    cursor: "pointer",
  },
  filterText: {
    boxSizing: "border-box",
    fontSize: "16px",
    fontWeight: 500,
    margin: "0px -1px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchPlaceholderText: {
    "& .MuiInputBase-root": {
      top: "0px !important",
    },
  },
  placeholdertext: {
    color: "#2F2F2F !important",
    fontSize: "16px !important",
    fontFamily: "Roboto !important",
    fontWeight: 500,
    fontStyle: "normal",
  },
  listItemText: {
    color: "#2F2F2F !important",
    fontSize: "16px !important",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
  },
  filterSelect: {
    "& .css-6hp17o-MuiList-root-MuiMenu-list": {
      width: "198px !important",
      height: "188px!important",
      // left:'100px'
    },
  },
  noRowsText: {
    fontFamily: "Roboto !important",
    fontStyle: "Regular !important",
    fontSize: "16px !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    verticalAalign: "center !important",
  },
  Noresult: {
    // position: "absolute",
    // top: "340px",
    // right: "35%",
    // left: '38%'
    position: "relative",
    bottom: "200px",
  },
  selectmenu: {
    "& .MuiSelect-select": {
      border: "1px solid #787878 !important",
    },
    "& .MuiOutlinedInput-root .Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
  },
  icon: {
    color: "#2F2F2F !important",
  },
});

const RecruiterList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [searchText, setSearchText] = React.useState("");

  const [addedBy, setAddedBy] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [recruiterType, setRecruiterType] = React.useState([]);

  const [statusBColor, setStatusBColor] = React.useState("");

  const [recruiterBColor, setRecruiterBColor] = React.useState("");

  const [addedByBColor, setAddedByBColor] = React.useState("");
  const [statuslength, setStatuslength] = React.useState(0);
  const [createlength, setCreatelength] = React.useState(0);
  const [recruiterlength, setRecruiterlength] = React.useState(0);
  const [showReset, setShowReset] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(true);
  const [recruiterList, setRecruiterList] = React.useState(authState?.recruiterList);
  const [uiSortModel, setUiSortModel] = React.useState([
    {
      field: "uid",
      sort: "desc",
    },
  ]);
  const setUserDetails = () => {
    // check if recruiterUserId needs to be set
    if ("recruiterUserId" in localStorage) {
      let recruiterUserId = localStorage.getItem("recruiterUserId");
      localStorage.setItem("userid", recruiterUserId);
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      userDetails.userId = recruiterUserId;
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      localStorage.removeItem("recruiterUserId");
    }
    return JSON.parse(localStorage.getItem("user_details"));
  };
  let userDetails = setUserDetails();

  const [start, setStart] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [userid, setUserid] = React.useState(userDetails.userId || null);
  const [recruiterview, setRecruiterview] = React.useState(false);
  const [recruiterfilt, setRecruiterfilt] = React.useState(false);
  const [showStepper, setShowStepper] = React.useState(false);

  var addedByFilter = (authState?.recruiterfilter?.addedBy)
    .map((item, i) => {
      return item.displayName;
    })
    .sort();

  // Load Recruiter List
  useEffect(() => {
    dispatch(auth.recruiturmentList({ userId: userDetails.userId }));
    setRecruiterfilt(true);
  }, []);

  //Set Recruiter List and Filters values
  useEffect(() => {
    // run updates once candidatefilter data is populated
    if (recruiterfilt && authState?.recruiterList?.length) {
      setRecruiterfilt(false);
      setAddedBy(addedByFilter);
      setStatus(authState?.recruiterfilter?.status);
      setRecruiterType(authState?.recruiterfilter?.recruiterType);
    }
    // eslint-disable-next-line
  }, [authState?.recruiterList]);

  // Navigate to recruiter page on click recruiter row
  useEffect(() => {
    if (recruiterview && authState?.recruiterdetails?.uid) window.open(generatePath("/userview/:id", { id: userid }));
    // eslint-disable-next-line
  }, [recruiterview && authState?.recruiterdetails?.uid]);

  // Apply filter
  useEffect(() => {
    if (authState?.recruiterList?.length) {
      setRecruiterFilteredList();
    }
    // eslint-disable-next-line
  }, [authState?.recruiterList, addedBy, recruiterType, status]);

  useEffect(() => {
    // let timeOut = setTimeout(() => {
    if (authState?.recruiterList?.length) {
      setRecruiterFilteredList();
    }
    // eslint-disable-next-line
  }, [searchText]);

  const headerdata1 = HeaderConfig.recruiterHeader();
  const AddRecruiterChange = () => {
    navigate("/adduser");
  };

  /**
   *  Filter functions
   */

  // Appy search filter on data
  const applySearchFilter = (rawData) => {
    let visibleKeys = [
      "uid",
      "firstName",
      "lastName",
      "status",
      "jobTitle",
      "degree",
      "yoe",
      "highestScore",
      "dateFilter",
      "roleSubType",
    ];
    let filteredData = applySearch(rawData, searchText, visibleKeys);
    return filteredData;
  };

  // Extract Filter Data
  const dataFilter = (rawData, filterData, key) => {
    // Make sure all filters are in lowercase
    filterData = filterData?.map((i) => i.toLowerCase());
    // FK cleanup eval july 2023
    return rawData.filter(
      // eslint-disable-next-line
      (item) => eval(`item.${key}`) && filterData.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
    );
  }; //End

  // Apply Filters on data
  const applyFilterOnData = () => {
    let rawData = [...authState?.recruiterList];
    if (rawData?.length) {
      // AddedBy Filter
      rawData = dataFilter(rawData, addedBy, "createdBy.displayName");
      // Status Filter
      rawData = dataFilter(rawData, status, "status");
      // Reecruiter TYpe Filter
      if (recruiterType.length != 0 && typeof recruiterType[0] === "object") {
        rawData = dataFilter(
          rawData,
          recruiterType.map((i) => i.value),
          "roleSubType"
        );
      } else {
        rawData = dataFilter(rawData, recruiterType, "roleSubType");
      }
    }
    return rawData;
  }; //End

  // Set Recruiter filtered List
  const setRecruiterFilteredList = () => {
    let filteredData = applyFilterOnData();
    filteredData = applySearchFilter(filteredData);
    filteredData = sortJson(
      [...filteredData],
      uiSortModel[0].sort,
      uiSortModel[0].field,
      uiSortModel[0].field === "createdAt" ? "date" : ""
    );
    setRecruiterList(filteredData);
  }; //End

  const handleSortChange = (sortInfo) => {
    setUiSortModel([sortInfo]);

    let sortedOrder = sortJson(
      [...recruiterList],
      sortInfo.sort,
      sortInfo.field,
      sortInfo.field === "createdAt" ? "date" : ""
    );
    setRecruiterList(sortedOrder);
  };

  //  If the recruiter id is not undefined, then set the user id to the recruiter id, and then dispatch the recruiter details action with the recruiter id.
  const recruiterProfile = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setUserid(id);
      dispatch(auth.recruiterDetails(id));
      setRecruiterview(true);
    } else {
      return null;
    }
  };

  // search functionality for table.
  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.replace(/,/g, "");
    if (searchValue) {
      setShowReset(true);
    }
    setSearchText(searchValue);
    setTablePaginationPageNumber(0);
  };

  const handleSelectStatus = (value) => {
    setStatus(value);
    setShowReset(true);
    if (value.length === authState?.recruiterfilter?.status?.length) {
      setStatuslength(0);
    } else {
      setStatuslength(value.length);
    }
    setStatusBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onSelectAllstatus = () => {
    setStatus(authState?.recruiterfilter?.status);
    setStatuslength(0);
    setStatusBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onClearAllstatus = () => {
    setStatus([]);
    setStatuslength(0);
    setStatusBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };

  // set the state of recruiterType to the value of the checkbox that is checked.
  const handleRecruiterTypeChange = (value) => {
    setRecruiterType(value);
    if (value.length === authState?.recruiterfilter?.roleSubType?.length) {
      setRecruiterlength(0);
    } else {
      setRecruiterlength(value.length);
    }
    setRecruiterBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };

  const onSelectAllRecruiter = () => {
    setRecruiterType(authState?.recruiterfilter?.recruiterType);
    setRecruiterlength(0);
    setRecruiterBColor("");
    setTablePaginationPageNumber(0);
  };

  const onClearAllRecruiter = () => {
    setRecruiterType([]);
    setRecruiterlength(0);
    setRecruiterBColor("#EBEFF8");
    setCreatelength(0);
    setRecruiterlength("");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };

  const handleAddedBy = (value) => {
    setAddedBy(value);
    setShowReset(true);
    if (value.length === addedBy?.length) {
      setCreatelength(0);
    } else {
      setCreatelength(value.length);
    }
    setAddedByBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };
  const onSelectAllCreate = () => {
    setAddedBy(addedByFilter);
    setCreatelength(0);
    setAddedByBColor("");
  };
  const onClearAllCreate = () => {
    setAddedBy([]);
    setCreatelength(0);
    setAddedByBColor("");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };

  const onRefresh = () => {
    dispatch(auth.recruiturmentList({ userId: userDetails.userId }));
  };
  // clears all the filters and sets the default values.
  const onClearAllFilters = () => {
    setRecruiterType(authState?.recruiterfilter?.recruiterType);
    setStatus(authState?.recruiterfilter?.status);
    setAddedBy(addedByFilter);
    setSearchText("");
    setStatusBColor("");
    setRecruiterBColor("");
    setAddedByBColor("");
    setShowReset(false);
    setRecruiterlength(0);
    setStatuslength(0);
    setCreatelength(0);
    setRecruiterList(authState?.recruiterList);
    // dispatch(auth.recruiturmentFilter());
    setTablePaginationPageNumber(0);
  };
  // pagination
  const Paginationstart = (value) => {
    setStart(value);
  };
  const Paginationlimit = (value) => {
    setLimit(value);
  };

  //stepper
  const handleStepper = () => {
    setShowStepper((prevState) => !prevState);
  };

  //trial expiry notification
  // const trialExpiryApproaching = localStorage.getItem("trialExpiryApproaching");

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  // eslint-disable-next-line
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [tablePaginationPageNumber, setTablePaginationPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (_, newPage) => {
    setPaginationPageNumber(newPage);
    setTablePaginationPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePaginationPageNumber(0);
    setPaginationPageNumber(1);
  };

  return (
    <Fragment>
      <NavBar />
      {/* <TrialExpiryNotification handleCloseNotification={handleCloseNotification} showNotification={showNotification} /> */}
      <Notification handleCloseNotification={handleCloseNotification} showNotification={showNotification} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid style={showNotification ? { marginTop: "30px" } : { marginTop: "130px" }}>
          <Grid>
            <CustomBreadcrumbs links={[{ name: "Home", path: "/home" }, { name: "Team" }]} />
            {/* <Typography className={classes.viewText}>
              Home /{" "}
              <span className={classes.viewText} style={{ fontWeight: 700 }}>
                {" "}
                Team
              </span>
            </Typography> */}
          </Grid>

          <div
            style={{ display: "flex", justifyContent: "space-between", lineHeight: "40px", padding: "25px 0px 20px" }}
          >
            <div style={{ display: "flex", alignItems: "flex-start", gap: "24px", flexDirection: "row" }}>
              <Grid style={{ display: "flex" }} item xs={4}>
                <Button
                  data-rt-recruiter-addnew=" addnewrecruiter"
                  color="btn"
                  width={199}
                  text="Invite New User"
                  onClick={AddRecruiterChange}
                />
              </Grid>

              <Button
                sx={{
                  textTransform: "none",
                  color: "#2F2F2F",
                  fontSize: "16px",
                  border: "1px solid #787878",
                  height: "40px",
                }}
                variant="outlined"
                startIcon={<RefreshIcon />}
                width={165}
                text="Refresh List"
                onClick={onRefresh}
              />
            </div>
            <Grid>
              <CustomPagination
                className={classes.selectmenu}
                iconColor={classes.icon}
                Paginationstart={(start) => Paginationstart(start)}
                Paginationlimit={(limit) => Paginationlimit(limit)}
                TotalCount={recruiterList?.length}
                rows={recruiterList || []}
                tablePaginationPageNumber={tablePaginationPageNumber}
                setTablePaginationPageNumber={setTablePaginationPageNumber}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleChangePage={handleChangePage}
              />
            </Grid>
          </div>
          <Grid style={{ display: "flex", gap: "14px", paddingBottom: "20px" }}>
            <SearchBar
              placeholdertitle={"Search User"}
              // width='422px'
              onChange={onChangeSearch}
              value={searchText}
            />
            <CheckSelectMenu
              Title={"Added By"}
              Data={addedByFilter}
              HandleChangeValue={(value) => handleAddedBy(value)}
              MenuProps={addedByMenuProps}
              width={createlength === 0 ? 112 : 135}
              BgColor={createlength !== 0 && addedByBColor}
              checkLength={createlength}
              selectValue={addedBy}
              OnSelectAll={onSelectAllCreate}
              OnClearAll={onClearAllCreate}
            />
            <CheckSelectMenu
              Title={"Status"}
              Data={authState?.recruiterfilter?.status}
              HandleChangeValue={(value) => handleSelectStatus(value)}
              MenuProps={statusMenuProps}
              width={statuslength === 0 ? 90 : 115}
              BgColor={statuslength !== 0 && statusBColor}
              checkLength={statuslength}
              OnSelectAll={onSelectAllstatus}
              OnClearAll={onClearAllstatus}
              selectValue={status}
            />
            <CheckSelectMenu
              Title={"User Type"}
              Data={authState?.recruiterfilter?.recruiterType}
              HandleChangeValue={(value) => handleRecruiterTypeChange(value)}
              MenuProps={recruiterMenuProps}
              width={recruiterlength === 0 ? 115 : 140}
              BgColor={recruiterlength !== 0 && recruiterBColor}
              checkLength={recruiterlength}
              OnSelectAll={onSelectAllRecruiter}
              OnClearAll={onClearAllRecruiter}
              selectValue={recruiterType}
            />

            {showReset === true && (
              <div style={{ minWidth: "140px" }}>
                <Button
                  sx={{ float: "right", textTransform: "none", color: "#2F2F2F" }}
                  variant="text"
                  endIcon={<CloseIcon />}
                  text="Reset Filters"
                  onClick={onClearAllFilters}
                />
              </div>
            )}
          </Grid>
          <Grid>
            <GridTable
              height={window.innerHeight - 410}
              columns={headerdata1}
              rows={recruiterList || []}
              checkbox={false}
              getRowId={(row) => row?._id || []}
              count={recruiterList?.length}
              handleRowClick={recruiterProfile}
              hideFooterPagination={true}
              // dispatchValue={auth.recruiturmentList}
              pageStart={start}
              pageLimit={limit}
              onSortChange={handleSortChange}
              onResetFilters={onClearAllFilters}
              sortModel={uiSortModel}
              showPagination
              tablePaginationPageNumber={tablePaginationPageNumber}
              setTablePaginationPageNumber={setTablePaginationPageNumber}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Container>
      <Tour isTourBtnShow={showStepper} handleStepper={handleStepper} />
      <Footer />
    </Fragment>
  );
};

export default RecruiterList;
