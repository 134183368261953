import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
// import TextInput from "../../../components/controls/TextField";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../styles/theme";
// import Divider from "@mui/material/Divider";
import Button from "../../../components/controls/Button";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import auth from "../../../redux/actions/auth";
import SimpleSnackbar from "../../../components/controls/ToastMessage";

const useStyles = makeStyles({
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: "white",
    border: "1px solid black",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "10px",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    // fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "right",
    // paddingTop: '20px',
    // paddingBottom: '20px'
  },
  submit: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

function OrganisationContent() {
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const updateOrgDetailsSuccess = useSelector((state) => state.auth.updateOrgDetailsSuccess);

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    timeZone: Yup.string().required("Time Zone is required"),
  });

  useEffect(() => {
    dispatch(auth.managementDetails());
  }, [authState?.updateOrgDetailsSuccess]);

  useEffect(() => {
    handleResetOrgDetailsSuccess();
  }, []);

  const handleResetOrgDetailsSuccess = () => {
    dispatch(auth.resetOrgDetailsSuccess());
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ mt: 6 }}>
        Organization Settings
      </Typography>
      <Formik
        initialValues={{
          companyName: authState?.managementDetailsSuccess?.data?.org?.orgName || "",
          timeZone: authState?.managementDetailsSuccess?.data?.org?.timeZone || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const payload = {
            orgName: values.companyName,
            timeZone: values.timeZone,
          };
          dispatch(auth.updateOrgDetails(payload));
        }}
        enableReinitialize
      >
        {({ values, errors, touched, isValid, dirty, setFieldValue, isSubmitting, handleChange, setSubmitting }) => (
          <Form>
            <div style={{ display: "flex", gap: "1%" }}>
              <div style={{ width: "20%" }}>
                <Typography fontWeight={500} sx={{ mt: 2 }}>
                  Company Name
                </Typography>
                <Field
                  name="companyName"
                  value={values.companyName}
                  disabled={userDetails.roleSubType !== "TeamAdmin"}
                  as={TextField}
                  onChange={(e) => {
                    handleChange(e);
                    setSubmitting(false);
                    setIsDisabled(false);
                  }}
                  sx={{ mt: 1 }}
                  fullWidth
                  hiddenLabel
                  id="filled-hidden-label-small"
                  variant="outlined"
                  size="small"
                />
                {touched.companyName && errors.companyName && (
                  <div className="error">
                    <Typography className={classes.errorty}>{errors.companyName}</Typography>
                  </div>
                )}
              </div>
            </div>
            <div style={{ display: "flex", gap: "1%" }}>
              <div style={{ width: "20%" }}>
                <Typography fontWeight={500} sx={{ mt: 4 }}>
                  Time Zone
                </Typography>
                <div style={{ marginTop: "8px" }}>
                  <Field
                    name="timeZone"
                    isDisabled={userDetails.roleSubType !== "TeamAdmin"}
                    as={TimezoneSelect}
                    value={values.timeZone}
                    onChange={(selectedTimezone) => {
                      setFieldValue("timeZone", selectedTimezone.value);
                      setSubmitting(false);
                      setIsDisabled(false);
                    }}
                    timezones={{
                      ...allTimezones,
                      "America/Lima": "Pittsburgh",
                      "Europe/Berlin": "Frankfurt",
                    }}
                  />
                  {touched.timeZone && errors.timeZone && (
                    <div className="error">
                      <Typography className={classes.errorty}>{errors.timeZone}</Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {userDetails.roleSubType === "TeamAdmin" ? (
              <Button
                disabled={!isValid || isSubmitting || isDisabled}
                submitting={isSubmitting}
                text="Save"
                color={!isValid || isSubmitting || isDisabled ? "disablebtn1" : "btn1"}
                sx={{ mt: 4 }}
                type="submit"
              />
            ) : null}
          </Form>
        )}
      </Formik>
      {updateOrgDetailsSuccess && (
        <SimpleSnackbar
          open={updateOrgDetailsSuccess}
          setOpen={handleResetOrgDetailsSuccess}
          text={"Details updated successfully!"}
        />
      )}
    </Box>
  );
}

export default OrganisationContent;
