import React, { useState, useEffect } from "react";
import { Box, Typography, Stepper, Step, StepLabel, StepContent } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Button from "./controls/Button";

const useStyles = makeStyles({
  tourStepper: {
    position: "absolute",
    width: "400px",
    bottom: "65px",
    right: "0px",
    // zIndex: 1,
    backgroundColor: "#101418",
    color: "#f7f7f7",
    padding: "20px",
    borderRadius: "10%",
    "& h5": {
      textAlign: "center",
      fontWeight: 600,
    },
  },
  customStepContent: {
    "&.MuiStepContent-root": {
      borderLeft: "none", // Remove the left border
    },
  },
  stepperLabel: {
    cursor: "pointer",
    "& .MuiStepLabel-label": {
      color: "#f7f7f7 !important",
      fontSize: "18px",
      fontWeight: 600,
    },
  },
  completedText: {
    fontSize: "12px",
    textAlign: "center",
  },
});

const steps = [
  {
    label: "Create a Job Role",
    description:
      "From the Roles page, you can create job roles for which you need to screen candidates. Try creating a job role now. Make sure to publish your role before use.",
    linkTo: "/CreateJobRole",
    completedDiscription:
      "You can use job roles to invite candidates for a comprehensive assessment. Candidates that achieve the target score are your ideal candidates for further consideration.",
  },
  {
    label: "Add a Candidate",
    description:
      "You need to add candidates prior to sending them any assessments. From the Candidates page, you can add and edit your candidates. Try adding a candidate now by simply uploading a resume or filling in their basic details. You could also add yourself as a candidate if you wish to view the assessment experience.",
    linkTo: "/addnewcandidate",
    completedDiscription:
      "Assessment invites can be sent to all Candidates in the system. Those who achieve their target scores are the ones to consider for your required job roles.",
  },
  {
    label: "Send a Comprehensive Assessment Invite",
    description:
      "From the Invites page, you can send and edit invites. Send an assessment invite now to your target candidates for the job role you've created. The candidates will receive the assessment details via email.",
    linkTo: "/inviteaddcandidates",
    completedDiscription:
      "Go to the Invites page to track each candidate's progress and download their comprehensive assessment reports.",
  },
];

const TourStepper = ({ roleCount, candidateCount, inviteCount, stepperDisappearText }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [activeSteps, setActiveSteps] = useState([]);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false);
  const classes = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepClick = (step) => {
    if (roleCount === 0 && step === 0) {
      return;
    }
    if (roleCount === 0 && candidateCount === 0 && step === 1) {
      return;
    }
    if (roleCount === 0 && candidateCount === 0 && inviteCount === 0 && step === 2) {
      return;
    }
    setActiveSteps((prevActiveSteps) => {
      if (prevActiveSteps.includes(step)) {
        // If the step is already expanded, collapse it
        return [];
      } else {
        // If the step is not expanded, expand it
        return [step];
      }
    });
  };

  const getStepLabel = (stepIndex, label, roleCount, candidateCount, inviteCount) => {
    switch (stepIndex) {
      case 0:
        return roleCount === 0 ? label : "Your Job Role is ready";
      case 1:
        return candidateCount === 0 ? label : "Your Candidate has been added";
      default:
        return inviteCount === 0 ? label : "Your Assessment Invite has been sent";
    }
  };

  const getStepContent = (stepIndex, step, roleCount, candidateCount, inviteCount) => {
    switch (stepIndex) {
      case 0:
        return roleCount === 0 ? (
          <Box>
            <Typography variant="body1">{step.description}</Typography>
            <Link to={step.linkTo}>
              <Button color="btn1" text="Create Role" sx={{ mt: 1, mr: 1 }} onClick={handleNext} />
            </Link>
          </Box>
        ) : (
          <Box>{step.completedDiscription}</Box>
        );
      case 1:
        return candidateCount === 0 ? (
          <Box>
            <Typography variant="body1">{step.description}</Typography>
            <Link to={step.linkTo}>
              <Button color="btn1" text="Add Candidate" sx={{ mt: 1, mr: 1 }} onClick={handleNext} />
            </Link>
          </Box>
        ) : (
          <Box>{step.completedDiscription}</Box>
        );
      default:
        return inviteCount === 0 ? (
          <Box>
            <Typography variant="body1">{step.description}</Typography>
            <Link to={step.linkTo}>
              <Button color="btn1" text="Send Invite" sx={{ mt: 1, mr: 1 }} onClick={handleNext} />
            </Link>
          </Box>
        ) : (
          <Box>{step.completedDiscription}</Box>
        );
    }
  };

  useEffect(() => {
    if (roleCount > 0) {
      setActiveStep(1);
    }
    if (roleCount > 0 && candidateCount > 0) {
      setActiveStep(2);
    }
    if (roleCount > 0 && candidateCount > 0 && inviteCount > 0) {
      setActiveStep(3);
      setAllStepsCompleted(true);
    }
  }, [activeStep, roleCount, inviteCount, candidateCount]);

  return (
    <Box className={classes.tourStepper}>
      <Typography variant="h5" my={2}>
        Getting Started
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} expanded={activeStep === index || activeSteps.includes(index)}>
            <StepLabel onClick={() => handleStepClick(index)} className={`${classes.stepperLabel}`}>
              {getStepLabel(index, step.label, roleCount, candidateCount, inviteCount)}
            </StepLabel>
            {(activeStep === index || activeSteps.includes(index)) && (
              <StepContent className={classes.customStepContent}>
                {getStepContent(index, step, roleCount, candidateCount, inviteCount)}
              </StepContent>
            )}
          </Step>
        ))}
        {allStepsCompleted && (
          <Typography variant="body1" className={classes.completedText}>
            {stepperDisappearText}
          </Typography>
        )}
      </Stepper>
    </Box>
  );
};

export default TourStepper;
