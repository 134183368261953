import React, { Fragment, useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { makeStyles } from "@mui/styles";
import { theme } from "../../styles/theme";
import { Container, Grid } from "@mui/material";
import GridTable from "../../components/Table";
import Button from "../../components/controls/Button";
import SearchBar from "../../components/controls/search";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomPagination from "../../components/controls/CustomPagination";
import auth from "../../redux/actions/auth";
import ToggleButtonAll from "../../components/controls/ToggleButton";
import { useNavigate, generatePath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckSelectMenu from "../../components/controls/Checkmenu";
import CloseIcon from "@mui/icons-material/Close";
import HeaderConfig from "../../constants/HeaderConfigs";
import { sortJson, applySearch } from "../../services/globalService";
// import VerticalLinearStepper from "../home/VerticalLinearStepper";
import Tour from "../../components/Tour";
// import TrialExpiryNotification from "../../components/TrialExpiryNotification";
import SelectWithCheckbox from "../../components/controls/SelectWithCheckbox";
import Notification from "../../components/AnnouncementNotification";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";

const addedByMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      padding: 12,
      marginLeft: 46,
    },
  },
};

const statusMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 45,
    },
  },
};
const degreeMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 43,
    },
  },
};
const expMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 30,
    },
  },
};

const skillMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 50,
    },
  },
};

const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  allJob: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "24px !important",
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    color: "#2F2F2F",
  },
  btnCls: {
    width: 300,
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    height: "30px !important",
    //   height: '40px' // FK cleanup july 2023
  },
  Allsty: {
    boxSizing: "border-box",

    /* Auto layout */

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: " center",
    // padding: "8px 12px",
    gap: "10px",
    width: "71px",
    height: "40px",
    border: "1px solid #2F2F2F",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: "0px -1px !important",

    fontSize: "16px !important",
    fontWeight: 500,
    cursor: "pointer",
  },
  Alltext: {
    display: "flex",
    //flexDirection: "column",
  },
  Allbtn: {
    borderRadius: "4px 0px 0px 4px",
    color: "#FFFFFF",
    background: "#2F2F2F",
  },
  Mybtn: {
    borderRadius: "0px 4px 4px 0px",

    color: "#2F2F2F",
  },
  Allbtn1: {
    borderRadius: "0px 4px 4px 0px",
    color: "#FFFFFF",
    background: "#2F2F2F",
  },
  Mybtn1: {
    borderRadius: "4px 0px 0px 4px",

    color: "#2F2F2F",
  },
  filtersty: {
    width: "101px",
    height: "40px",
    border: "1px solid #787878",
    color: "#787878",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-evenly",
    cursor: "pointer",
  },
  filterText: {
    boxSizing: "border-box",
    fontSize: "16px",
    fontWeight: 500,
    margin: "0px -1px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paginationRootCls: {
    top: "-422px",
    left: "100px",
  },
  noRowsText: {
    fontFamily: "Roboto !important",
    fontStyle: "Regular !important",
    fontSize: "16px !important",
    fontWeight: 400,
    lineHeight: "24px !important",
    verticalAalign: "center !important",
  },
  Noresult: {
    position: "relative",
    bottom: "200px",
  },
});

const CandidateListNew = (props) => {
  const setUserDetails = () => {
    // check if recruiterUserId needs to be set
    if ("recruiterUserId" in localStorage) {
      let recruiterUserId = localStorage.getItem("recruiterUserId");
      localStorage.setItem("userid", recruiterUserId);
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      userDetails.userId = recruiterUserId;
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      localStorage.removeItem("recruiterUserId");
    }
    return JSON.parse(localStorage.getItem("user_details"));
  };
  let userDetails = setUserDetails();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [userid, setUserid] = useState(userDetails.userId || null);
  const [candidateview, setCandidateview] = useState(false);
  const [candidateList, setCandidateList] = useState(authState?.candidatelist);
  const [selectText, setSelectText] = useState("all");
  const [onlyMine, setOnlyMine] = useState(false);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  // Mapping the array of objects and returning the displayName property of each object
  var addedByFilter = (authState?.candidatefilter?.createdBy)
    .map((item, i) => {
      return item.displayName;
    })
    .sort();
  const [totalCandidate, setTotalCandidate] = useState(0);
  const [myTotal, setMyTotal] = useState(0);
  const [addedBy, setAddedBy] = useState([]);
  const [addedBylength, setAddedBylength] = useState(0);
  const [addedBColor, setAddedBColor] = useState("");
  const [status, setStatus] = useState([]);
  const [statuslength, setStatuslength] = useState(0);
  const [statusBColor, setStatusBColor] = useState("");
  const [degree, setDegree] = useState([]);
  const [degreelength, setDegreelength] = useState(0);
  const [degreeBColor, setDegreeBColor] = useState("");
  const [experience, setExperience] = useState([]);
  const [explength, setExplength] = useState(0);
  const [expBColor, setExpBColor] = useState("");
  const [skill, setSkill] = useState([]);
  const [skilllength, setSkilllength] = useState(0);
  const [skillBColor, setSkillBColor] = useState("");
  const [showReset, setShowReset] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [uiSortModel, setUiSortModel] = useState([{ field: "uid", sort: "desc" }]);
  const headerdata1 = HeaderConfig.candidateHeader();
  const [candidatefilt, setCandidatefilt] = React.useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  const [orgId, setOrgId] = useState(null);

  const handleStepper = () => {
    setShowStepper((prevState) => !prevState);
  };

  // Load Candidate List
  useEffect(() => {
    dispatch(auth.candidateList({ userId: userDetails.userId }));
    setCandidatefilt(true);
  }, []);

  //Set Candidate List and Filters values
  useEffect(() => {
    setCandidateList(authState?.candidatelist);

    if (authState?.candidatelist?.length != 0) {
      setOrgId(authState?.candidatelist[0]?.orgId);
    }
    // run updates once candidatefilter data is populated
    if (candidatefilt && authState?.candidatelist.length != 0) {
      setCandidatefilt(false);
      setAddedBy(addedByFilter);
      setStatus(authState?.candidatefilter?.status);
      setDegree(authState?.candidatefilter?.degree);
      setExperience(authState?.candidatefilter?.yoe);
      setSkill([]);
      setTotalCandidate(authState?.candidateListAllTotal);
      setMyTotal(authState?.candidateListMyTotal);
    }
    // eslint-disable-next-line
  }, [authState?.candidatelist]);

  // Navigae to candidate page on click candidate row
  useEffect(() => {
    if (candidateview && authState?.candidatedetails?.uid)
      window.open(generatePath("/candidateview/:id", { id: userid }));
    // eslint-disable-next-line
  }, [candidateview && authState?.candidatedetails?.uid]);

  // Apply filter
  useEffect(() => {
    if (authState?.candidatelist.length !== 0) {
      setCandidateFilteredList();
    }
    // eslint-disable-next-line
  }, [authState?.candidatelist, addedBy, status, degree, experience, skill]);

  // Search Value side effect
  useEffect(() => {
    // let timeOut = setTimeout(()=>{
    if (authState?.candidatelist.length !== 0) {
      setCandidateFilteredList();
    }
    // },400)
    // return () => {
    // clearTimeout(timeOut)
    // }
    // eslint-disable-next-line
  }, [searchText]);

  // Side effect for toggle mylist or all list
  useEffect(() => {
    setCandidateFilteredList();
    // eslint-disable-next-line
  }, [selectText]);

  /**
   *  Filter functions
   */

  // Appy search filter on data
  const applySearchFilter = (rawData) => {
    let visibleKeys = [
      "uid",
      "firstName",
      "lastName",
      "status",
      "jobTitle",
      "degree",
      "yoe",
      "highestScore",
      "dateFilter",
      "email",
    ];

    let filteredData = applySearch(rawData, searchText, visibleKeys);

    return filteredData;
  };

  // Extract Filter Data
  const dataFilter = (rawData, filterData, key) => {
    // Make sure all filters are in lowercase
    filterData = filterData.map((i) => i.toLowerCase());
    // FK cleanup eval july 2023
    return rawData.filter(
      // eslint-disable-next-line
      (item) => eval(`item.${key}`) && filterData.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
    );
  }; //End

  // Apply Filters on data
  const applyFilterOnData = () => {
    // Raw Data
    let rawData = [...authState?.candidatelist];

    if (rawData.length !== 0) {
      // AddedBy Filter
      rawData = dataFilter(rawData, addedBy, "createdBy.displayName");

      // Status Filter
      rawData = dataFilter(rawData, status, "status");

      // Degree Filter
      rawData = dataFilter(rawData, degree, "degree");

      // Experience Filter
      let yoeList = [];

      experience.forEach((exp) => {
        switch (exp) {
          case "0-3 Years":
            yoeList = [...yoeList, "0", "1", "2", "3"];
            break;
          case "4-6 Years":
            yoeList = [...yoeList, "4", "5", "6"];
            break;
          case "7 Years & Above":
            for (let i = 7; i < 51; i++) {
              yoeList = [...yoeList, `${i}`];
            }
            break;
          case "Unknown":
            yoeList = [...yoeList, "Unknown", ""];
            break;
          default:
            console.log("default");
            break;
        }
      });

      rawData = dataFilter(rawData, yoeList, "yoe");

      // Skills Filter

      let skills = skill?.length ? skill.map((s) => s.toLowerCase().trim()) : [];
      rawData = rawData.filter((item) => {
        // Check if skillDetails exists for the current item
        if (item["skillsArray"]) {
          // Check if all selected skills are present in the skillDetails of the current item
          return skills.every((selectedSkill) =>
            item["skillsArray"].some((value) => value.toLowerCase().trim() === selectedSkill)
          );
        }
        return false; // If skillDetails doesn't exist, exclude the item
      });

      // let skills = skill.length!==0 ? skill.map(s => s.toLowerCase()) : [];
      // rawData = rawData.filter(item => item['skillsArray'].filter(value => skills.includes(value.toLowerCase().trim())).length > 0 );
    }

    return rawData;
  }; //End

  // Set Candidate filtered List
  const setCandidateFilteredList = () => {
    let filteredData = applyFilterOnData();

    filteredData = applySearchFilter(filteredData);

    // Set Total Candidate count
    setTotalCandidate(filteredData.length);

    // Set My Total Count
    if (onlyMine) {
      filteredData = filteredData.filter((i) => i.createdBy.uid === userDetails.userId);
    }
    setMyTotal(filteredData.filter((i) => i.createdBy.uid === userDetails.userId).length);

    let fieldType, field;

    fieldType = uiSortModel[0].field === "createdAt" ? "date" : "";
    field = uiSortModel[0].field;

    if (uiSortModel[0].field === "jobRoleInvites") {
      field = "inviteCountTuple";
      fieldType = "tuple";
    }

    filteredData = sortJson([...filteredData], uiSortModel[0].sort, field, fieldType);
    // removing year from dateFilter
    filteredData.map((filteredData) => {
      return (filteredData["dateFilter"] = filteredData["dateFilter"].slice(0, 6));
    });

    setCandidateList(filteredData);
  }; //End

  const AddCandidateChange = () => {
    navigate("/addnewcandidate");
  }; //End

  const AddBulkResumeChange = () => {
    navigate("/importBulkResumes");
  }; //End

  const onRefresh = () => {
    dispatch(auth.candidateList({ userId: userDetails.userId }));
  }; //End

  const SelectBtn = (event, text) => {
    if (text === "my") {
      setOnlyMine(true);
      setSelectText("my");
      setTablePaginationPageNumber(0);
    } else {
      setOnlyMine(false);
      setSelectText("all");
      setTablePaginationPageNumber(0);
    }
  }; //End

  const Paginationstart = (value) => {
    setStart(value);
  }; //End

  const Paginationlimit = (value) => {
    setLimit(value);
  }; //End

  const handleChangeAddedBy = (value) => {
    setAddedBy(value);
    setShowReset(true);
    if (value.length === addedBy?.length) {
      setAddedBylength(0);
    } else {
      setAddedBylength(value.length);
    }

    setAddedBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const handleChangeStatus = (value) => {
    setStatus(value);
    setShowReset(true);
    if (value.length === authState?.candidatefilter?.status?.length) {
      setStatuslength(0);
    } else {
      setStatuslength(value.length);
    }
    setStatusBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const handleChangeDegree = (value) => {
    setDegree(value);
    setShowReset(true);

    if (value.length === degree?.length) {
      setDegreelength(0);
    } else {
      setDegreelength(value.length);
    }

    setDegreeBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const handleChangeExprerience = (value) => {
    setExperience(value);
    setShowReset(true);
    if (value.length === experience?.length) {
      setExplength(0);
    } else {
      setExplength(value.length);
    }
    setExpBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const handleChangeSkill = (value) => {
    setSkill(value);
    setShowReset(true);
    if (value.length === skill?.length) {
      setSkilllength(0);
    } else {
      setSkilllength(value.length);
    }

    setSkillBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.replace(/,/g, "");
    // searchValue = searchValue.replace(/  +/g, ' ').trim();

    if (searchValue) {
      setShowReset(true);
    }

    setSearchText(searchValue);
    setTablePaginationPageNumber(0);
  }; //End

  const onSelectAllAddedBy = () => {
    setAddedBy(addedByFilter);
    setAddedBylength(0);
    setAddedBColor("");
    setShowReset(false);
    setTablePaginationPageNumber(0);
  }; //End

  const onClearAllAddedBy = () => {
    setAddedBy([]);
    setAddedBylength(0);
    setAddedBColor("");
    setTablePaginationPageNumber(0);
    // setShowReset(true);
  }; //End

  const onSelectAllstatus = () => {
    setStatus(authState?.candidatefilter?.status);
    setStatuslength(0);
    setStatusBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const onClearAllstatus = () => {
    setStatus([]);
    setStatuslength(0);
    setStatusBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  }; //End

  const onSelectAllDegree = () => {
    setDegree(authState?.candidatefilter?.degree);
    setDegreelength(0);
    setDegreeBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const onClearAllDegree = () => {
    setDegree([]);
    setDegreelength(0);
    setDegreeBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
    // setShowReset(true);
  }; //End

  const onSelectAllExp = () => {
    setExperience(authState?.candidatefilter?.yoe);
    setExplength(0);
    setExpBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const onClearAllExp = () => {
    setExperience([]);
    setExplength(0);
    setExpBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
    // setShowReset(true);
  }; //End

  const onSelectAllSkill = () => {
    setSkill(authState?.candidatefilter?.skills.map((i) => i.id));
    setSkilllength(0);
    setSkillBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const onClearAllSkill = () => {
    setSkill([]);
    setSkilllength(0);
    setSkillBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  }; //End

  const onResetAllFilters = () => {
    setAddedBy(addedByFilter);
    setStatus(authState?.candidatefilter?.status);
    setDegree(authState?.candidatefilter?.degree);
    setExperience(authState?.candidatefilter?.yoe);
    setSkill([]);
    setSearchText("");
    setShowReset(false);
    setAddedBColor("");
    setAddedBylength(0);
    setStatusBColor("");
    setStatuslength(0);
    setDegreeBColor("");
    setDegreelength(0);
    setExpBColor("");
    setExplength(0);
    setSkillBColor("");
    setSkilllength(0);
    setCandidateList(authState?.candidatelist);
    setTablePaginationPageNumber(0);
  }; //End

  const candidateProfile = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setUserid(id);
      dispatch(auth.candidateDetails(id));
      setCandidateview(true);
    } else {
      return null;
    }
  }; //End

  const handleSortChange = (sortInfo) => {
    let fieldType, field;

    fieldType = sortInfo.field === "createdAt" ? "date" : "";
    field = sortInfo.field || uiSortModel[0].field;

    if (sortInfo.field === "jobRoleInvites") {
      field = "inviteCountTuple";
      fieldType = "tuple";
    }

    setUiSortModel([sortInfo]);
    let sortedOrder = sortJson([...candidateList], sortInfo.sort, field, fieldType);
    setCandidateList(sortedOrder);
  };

  //trial expiry notification
  // const trialExpiryApproaching = localStorage.getItem("trialExpiryApproaching");

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  // eslint-disable-next-line
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [tablePaginationPageNumber, setTablePaginationPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (_, newPage) => {
    setPaginationPageNumber(newPage);
    setTablePaginationPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePaginationPageNumber(0);
    setPaginationPageNumber(1);
  };

  // orgId=0
  return (
    <Fragment>
      <NavBar />
      <Notification handleCloseNotification={handleCloseNotification} showNotification={showNotification} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid style={showNotification ? { marginTop: "30px" } : { marginTop: "130px" }}>
          <Grid>
            <CustomBreadcrumbs links={[{ name: "Home", path: "/home" }, { name: "Candidates" }]} />
          </Grid>
          <div
            style={{ display: "flex", justifyContent: "space-between", lineHeight: "40px", padding: "25px 0px 20px" }}
          >
            <Grid style={{ display: "flex", alignItems: "flex-start", gap: "24px", flexDirection: "row" }}>
              <Button
                data-rt-role-addnew="addnewcandidate"
                color="btn"
                width={199}
                text="Add Candidate"
                onClick={AddCandidateChange}
              />

              {/* {[0, 1, 2, 70].includes(orgId) && orgId != null ? ( */}
              <Button
                variant="outlined"
                color="btnCls3"
                data-rt-role-addnew="addnewcandidate"
                width={199}
                text="Import Resumes"
                onClick={AddBulkResumeChange}
              />
              {/* ) : (
                ""
              )} */}
              <Button
                sx={{
                  textTransform: "none",
                  color: "#2F2F2F",
                  fontSize: "16px",
                  border: "1px solid #787878",
                  height: "40px",
                }}
                variant="outlined"
                startIcon={<RefreshIcon />}
                width={165}
                text="Refresh List"
                onClick={onRefresh}
              />

              <ToggleButtonAll
                handleAllAndMy={SelectBtn}
                selectText={selectText}
                Alltotal={totalCandidate}
                Mytotal={myTotal}
              />
            </Grid>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "24px", flexDirection: "row" }}>
              <Grid>
                <CustomPagination
                  Paginationstart={(start) => Paginationstart(start)}
                  Paginationlimit={(limit) => Paginationlimit(limit)}
                  // TotalCount={authState?.candidateListTotal}
                  TotalCount={candidateList.length}
                  rows={candidateList || []}
                  tablePaginationPageNumber={tablePaginationPageNumber}
                  setTablePaginationPageNumber={setTablePaginationPageNumber}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  handleChangePage={handleChangePage}
                />
              </Grid>
            </div>
          </div>
          <Grid style={{ display: "flex", gap: "14px", paddingBottom: "20px" }}>
            <SearchBar
              placeholdertitle={"Search candidate"}
              onChange={onChangeSearch}
              value={searchText}
              // width={'310px'}
            />
            <CheckSelectMenu
              Title={"Added By"}
              Data={addedByFilter}
              HandleChangeValue={(value) => handleChangeAddedBy(value)}
              MenuProps={addedByMenuProps}
              width={addedBylength === 0 ? 115 : 140}
              text={"Addedby"}
              checkLength={addedBylength}
              BgColor={addedBylength !== 0 && addedBColor}
              selectValue={addedBy}
              OnSelectAll={onSelectAllAddedBy}
              OnClearAll={onClearAllAddedBy}
            />
            <CheckSelectMenu
              Title={"Status"}
              Data={authState?.candidatefilter?.status}
              HandleChangeValue={(value) => handleChangeStatus(value)}
              MenuProps={statusMenuProps}
              width={statuslength === 0 ? 90 : 113}
              checkLength={statuslength}
              BgColor={statuslength !== 0 && statusBColor}
              selectValue={status}
              OnSelectAll={onSelectAllstatus}
              OnClearAll={onClearAllstatus}
            />
            <CheckSelectMenu
              Title={"Degree"}
              Data={authState?.candidatefilter?.degree}
              HandleChangeValue={(value) => handleChangeDegree(value)}
              MenuProps={degreeMenuProps}
              width={degreelength === 0 ? 95 : 118}
              checkLength={degreelength}
              BgColor={degreelength !== 0 && degreeBColor}
              selectValue={degree}
              OnSelectAll={onSelectAllDegree}
              OnClearAll={onClearAllDegree}
            />
            <CheckSelectMenu
              Title={"Experience"}
              Data={authState?.candidatefilter?.yoe}
              HandleChangeValue={(value) => handleChangeExprerience(value)}
              MenuProps={expMenuProps}
              width={explength === 0 ? 120 : 145}
              checkLength={explength}
              BgColor={explength !== 0 && expBColor}
              selectValue={experience}
              OnSelectAll={onSelectAllExp}
              OnClearAll={onClearAllExp}
            />
            <SelectWithCheckbox
              title={"Skills"}
              options={authState?.candidatefilter?.skills}
              handleSelectOption={(value) => handleChangeSkill(value)}
              MenuProps={skillMenuProps}
              width={skilllength === 0 ? 83 : 113}
              checkLength={skilllength}
              BgColor={skilllength !== 0 && skillBColor}
              defaultValue={skill}
              handleSelectAll={onSelectAllSkill}
              handleClearAll={onClearAllSkill}
              disabledCapitalize
            />
            {showReset === true && (
              <div style={{ minWidth: "140px" }}>
                <Button
                  sx={{ float: "right", textTransform: "none", color: "#2F2F2F" }}
                  variant="text"
                  endIcon={<CloseIcon />}
                  text="Reset Filters"
                  onClick={onResetAllFilters}
                />
              </div>
            )}
          </Grid>
          <Grid>
            <GridTable
              height={window.innerHeight - 410}
              columns={headerdata1}
              rows={candidateList || []}
              checkbox={false}
              getRowId={(row) => row._id || 0}
              count={candidateList.length}
              handleRowClick={candidateProfile}
              onSortChange={handleSortChange}
              onResetFilters={onResetAllFilters}
              sortModel={uiSortModel}
              pageStart={start}
              pageLimit={limit}
              //loading={authState?.isauthLoading || false}
              showPagination
              tablePaginationPageNumber={tablePaginationPageNumber}
              setTablePaginationPageNumber={setTablePaginationPageNumber}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Container>
      <Tour isTourBtnShow={showStepper} handleStepper={handleStepper} />
      <Footer />
    </Fragment>
  );
};

export default CandidateListNew;
