import React, { useEffect, useState } from "react";
import { Typography, Grid, Checkbox, useMediaQuery, Box, Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextInput from "../../components/controls/TextField";
import Button from "../../components/controls/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { theme } from "../../styles/theme";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../redux/actions/auth";
import { getDomainDetails } from "../../services/globalService";
import { Skeleton } from "@mui/material";
import ResendEmailValidation from "./ResentEmailValidation";

const useStyles = makeStyles({
  centerContainer: {
    marginTop: "40px",
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "10px",
  },
  cardFeature: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    // textAlign: 'center',
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  textviewLink: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    // paddingTop: '20px',
    paddingBottom: "20px",
  },
  submit: {
    marginTop: "16px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

const SignUp = () => {
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const authStore = useSelector((state) => state.auth);
  const logoUrls = process.env.REACT_APP_ASSETS_URL;
  const { signUpUserSuccess } = useSelector((state) => state.auth);
  const [domainDetails, setDomainDetails] = useState({});
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [userTimeZone, setUserTimeZone] = useState("");
  const [userDomain, setUserDomain] = useState("");
  // const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const url = new URL(window.location.href);
  const planName = url.searchParams.get("plan");
  const currency = url.searchParams.get("currency");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currencyPlan, setCurrencyPlan] = useState(null);
  const [emailValues, setEmailValues] = useState("");
  // eslint-disable-next-line
  const [freePlan, setFreePlan] = useState(null);
  const queryParams = {
    email: url.searchParams.get("email") || "",
    firstName: url.searchParams.get("firstname") || "",
    lastName: url.searchParams.get("lastname") || "",
  };
  // eslint-disable-next-line
  const [initialValues, setInitialValues] = useState(queryParams);
  const matches = useMediaQuery("(max-width:850px)");

  // Validation schema for sign up form
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    agreeToTerms: Yup.boolean().oneOf([true], "You must agree to the Terms of Conditions and Privacy Policy."),
    email: Yup.string()
      .email("Please enter a valid email")
      .typeError("Please enter a valid email")
      .required("Email is required"),
  });

  // Handle form submission
  const submitSignUp = (values) => {
    const { email, firstName, lastName } = values;
    let payload = {
      email,
      firstName,
      lastName,
      orgName: userDomain,
      timeZone: userTimeZone,
      plan: selectedPlan?.key,
      currency: currencyPlan.currency,
    };
    setEmailValues(email);
    dispatch(auth.SignUpUser(payload));
  };

  // handle signup success
  useEffect(() => {
    if (signUpUserSuccess && signUpUserSuccess?.status === 0) {
      setSignUpSuccess(true);
    }
  }, [signUpUserSuccess]);

  useEffect(() => {
    let domainName = getDomainDetails(window.location.href);
    dispatch(auth.GetLogo({ subdomain: domainName.subdomainFromUrl }));
    setDomainDetails(authStore.logoDetails);
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimeZone(browserTimeZone);

    dispatch(auth.SignUpPlans());
  }, []);

  const handleEmailValidation = (event) => {
    const email = event.target.value;
    if (email) {
      const domainName = email.split("@")[1];
      if (domainName) {
        const domain = domainName.split(".")[0];

        setUserDomain(domain);

        const payload = { email };
        dispatch(auth.validateEmail(payload));
      }
    }
  };

  function findPlanByName(plans, planName) {
    return plans.find((plan) => plan.key === planName);
  }

  function findPriceByCurrency(prices, currency) {
    return prices.find((price) => price.currency === currency);
  }

  useEffect(() => {
    if (authStore.signUpPlansSuccess && authStore.signUpPlansSuccess.data) {
      const plans = authStore.signUpPlansSuccess?.data?.plans;
      const [freePlan] = plans?.filter((plan) => plan.key === "free");

      const selectedPlan = findPlanByName(plans, planName ? planName.replace(/"/g, "") : "free");
      if (selectedPlan) {
        setSelectedPlan(selectedPlan);
      } else {
        setSelectedPlan(freePlan);
      }
    }
  }, [authStore.signUpPlansSuccess, planName]);

  useEffect(() => {
    if (selectedPlan || freePlan) {
      const prices = selectedPlan?.price || freePlan?.price;
      const currencyPlan = findPriceByCurrency(prices, currency ? currency.replace(/"/g, "") : "usd");
      if (currencyPlan) {
        setCurrencyPlan(currencyPlan);
      } else {
        const [freePlanCurrency] = prices?.filter((price) => price.currency === "usd");
        setCurrencyPlan(freePlanCurrency);
      }
    }
  }, [selectedPlan, freePlan]);

  return (
    <>
      <div
        className={`${classes.center} ${classes.logocls}`}
        style={matches ? { padding: "5px", position: "fixed", width: "100%" } : {}}
      >
        {domainDetails && domainDetails?.logo !== "" ? (
          <>
            <img height="50" width="157" src={`${logoUrls}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
            <img height="17" width="157" src={`${logoUrls}powered.svg`} alt="powered" />
          </>
        ) : (
          <a href="https://techscore.ai/pricing/" target="_self" rel="noreferrer">
            <img height="50" width="157" src={`${logoUrls}techscore.svg`} alt="TechScore" />
          </a>
        )}
      </div>
      {signUpSuccess ? (
        <>
          <ResendEmailValidation emailValues={emailValues} />
        </>
      ) : (
        <>
          {matches ? (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid>
                  <div>
                    <Typography
                      className={classes.roletype}
                      variant="h6"
                      sx={{ textAlign: "center", mb: "12px", mt: 8 }}
                    >
                      Your Plan
                    </Typography>
                  </div>
                  <div>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      // style={{ height: "100%" }}
                    >
                      {(selectedPlan && currencyPlan) || freePlan ? (
                        <Box>
                          <Card
                            variant="outlined"
                            sx={{
                              borderTop: "8px solid #cd73ef",
                              borderRadius: "8px",
                              padding: "4px 16px",
                              backgroundColor: "#f9fafb",
                            }}
                          >
                            <CardContent>
                              <Typography variant="h5" fontWeight="bold">
                                {selectedPlan?.name || freePlan?.name}
                              </Typography>
                              <Typography variant="h3" fontWeight="bold" sx={{ mt: 2 }}>
                                <span style={{ fontSize: "20px" }}>{currencyPlan?.currencySymbol}</span>
                                {currencyPlan?.amount}
                              </Typography>
                              <Typography variant="caption">
                                {currencyPlan?.amount === "0"
                                  ? "Free forever"
                                  : "Billed " + selectedPlan?.billingFrequency || freePlan?.billingFrequency}
                              </Typography>
                              <div style={{ marginTop: "20px" }}>
                                {selectedPlan?.features.map((feature, index) => (
                                  <Typography key={index} className={`${classes.cardFeature}`}>
                                    {feature}
                                  </Typography>
                                ))}
                                {freePlan?.features.map((feature, index) => (
                                  <Typography key={index} className={`${classes.cardFeature}`}>
                                    {feature}
                                  </Typography>
                                ))}
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      ) : (
                        <Card
                          variant="outlined"
                          sx={{
                            borderTop: "8px solid #cd73ef",
                            borderRadius: "8px",
                          }}
                        >
                          <div style={{ padding: "24px " }}>
                            <div>
                              <Skeleton variant="text" width={170} height={40} animation="wave" />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <Skeleton variant="text" width={80} height={40} animation="wave" />
                            </div>
                            <div>
                              <Skeleton variant="text" width={60} height={30} animation="wave" />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <Skeleton variant="text" width={170} height={30} animation="wave" />
                              <Skeleton variant="text" width={170} height={30} animation="wave" />
                              <Skeleton variant="text" width={170} height={30} animation="wave" />
                            </div>
                          </div>
                        </Card>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid item sx={{ margin: "auto 10%" }}>
                <Typography align="center" className={classes.textsty} style={{ textAlign: "center" }}>
                  Sign up for a new account
                </Typography>

                <Grid>
                  <Formik
                    initialValues={initialValues}
                    validateOnChange
                    validateOnBlur
                    validationSchema={validationSchema}
                    onSubmit={submitSignUp}
                  >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                      <Form>
                        <Grid spacing={2} style={{ marginTop: "20px" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography className={`${classes.roletype}`}>First Name</Typography>
                              <TextInput
                                autoFocus
                                name="firstName"
                                placeholder="Enter Your First Name"
                                className={classes.field}
                                id="firstName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                              ></TextInput>
                              {touched.firstName && errors.firstName && (
                                <div className="error">
                                  <Typography className={classes.errorty}>{errors.firstName}</Typography>
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={`${classes.roletype}`}>Last Name</Typography>
                              <TextInput
                                name="lastName"
                                placeholder="Enter Your Last Name"
                                className={classes.field}
                                id="lastName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                              ></TextInput>
                              {touched.lastName && errors.lastName && (
                                <div className="error">
                                  <Typography className={classes.errorty}>{errors.lastName}</Typography>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                          <Grid xs={12} style={{ marginTop: "15px" }}>
                            <Typography className={`${classes.roletype}`}>Work Email (Login ID)</Typography>
                            <TextInput
                              name="email"
                              placeholder="Enter Your Work Email ID"
                              className={classes.field}
                              id="email"
                              onChange={handleChange}
                              onBlur={handleEmailValidation}
                              value={values.email}
                            ></TextInput>
                            {authStore.validateEmailSuccess &&
                              authStore.validateEmailSuccess.data &&
                              !authStore.validateEmailSuccess.data.valid && (
                                <div className="error">
                                  <Typography className={classes.errorty}>
                                    {authStore.validateEmailSuccess.data.message}
                                  </Typography>
                                </div>
                              )}
                          </Grid>
                          <div style={{ marginTop: "20px" }}>
                            <label style={{ fontSize: "14px" }}>
                              <Checkbox
                                type="checkbox"
                                name="agreeToTerms"
                                checked={agreeToTerms}
                                onChange={(e) => setAgreeToTerms(e.target.checked)}
                              />
                              I agree with the{" "}
                              <a href="/terms&conditions" target="_self">
                                Terms
                              </a>{" "}
                              and the{" "}
                              <a href="/privacypolicy" target="_self">
                                Privacy Policy
                              </a>
                              .
                            </label>
                            {touched.agreeToTerms && errors.agreeToTerms && (
                              <div className="error">
                                <Typography className={classes.errorty}>{errors.agreeToTerms}</Typography>
                              </div>
                            )}
                          </div>

                          <Grid xs={12} className={classes.submit}>
                            <Button
                              disabled={!isValid || !agreeToTerms}
                              type="submit"
                              color={!isValid || !agreeToTerms ? "disablebtn" : "btnPrimary"}
                              width={"100%"}
                              text="Sign Up"
                              // onClick={submitSignUp}
                            ></Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container>
              <Grid item xs={6}>
                <Grid container display={"flex"} justifyContent={"right"}>
                  <Grid item xs={10} s style={{ marginTop: "120px" }}>
                    <Typography align="center" className={classes.textsty} style={{ textAlign: "center" }}>
                      Sign up for a new account
                    </Typography>

                    <Grid>
                      <Formik
                        initialValues={initialValues}
                        validateOnChange
                        validateOnBlur
                        validationSchema={validationSchema}
                        onSubmit={submitSignUp}
                      >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
                          <Form>
                            <Grid spacing={2} style={{ marginTop: "20px" }}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography className={`${classes.roletype}`}>First Name</Typography>
                                  <TextInput
                                    autoFocus
                                    name="firstName"
                                    placeholder="Enter Your First Name"
                                    className={classes.field}
                                    id="firstName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                  ></TextInput>
                                  {touched.firstName && errors.firstName && (
                                    <div className="error">
                                      <Typography className={classes.errorty}>{errors.firstName}</Typography>
                                    </div>
                                  )}
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography className={`${classes.roletype}`}>Last Name</Typography>
                                  <TextInput
                                    name="lastName"
                                    placeholder="Enter Your Last Name"
                                    className={classes.field}
                                    id="lastName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                  ></TextInput>
                                  {touched.lastName && errors.lastName && (
                                    <div className="error">
                                      <Typography className={classes.errorty}>{errors.lastName}</Typography>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid xs={12} style={{ marginTop: "15px" }}>
                                <Typography className={`${classes.roletype}`}>Work Email (Login ID)</Typography>
                                <TextInput
                                  name="email"
                                  placeholder="Enter Your Work Email ID"
                                  className={classes.field}
                                  id="email"
                                  onChange={handleChange}
                                  onBlur={handleEmailValidation}
                                  value={values.email}
                                ></TextInput>
                                {authStore.validateEmailSuccess &&
                                  authStore.validateEmailSuccess.data &&
                                  !authStore.validateEmailSuccess.data.valid && (
                                    <div className="error">
                                      <Typography className={classes.errorty}>
                                        {authStore.validateEmailSuccess.data.message}
                                      </Typography>
                                    </div>
                                  )}
                              </Grid>
                              <div style={{ marginTop: "20px" }}>
                                <label style={{ fontSize: "14px" }}>
                                  <Checkbox
                                    type="checkbox"
                                    name="agreeToTerms"
                                    checked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                  />
                                  I agree with the{" "}
                                  <a href="/terms&conditions" target="_self">
                                    Terms
                                  </a>{" "}
                                  and the{" "}
                                  <a href="/privacypolicy" target="_self">
                                    Privacy Policy
                                  </a>
                                  .
                                </label>
                                {touched.agreeToTerms && errors.agreeToTerms && (
                                  <div className="error">
                                    <Typography className={classes.errorty}>{errors.agreeToTerms}</Typography>
                                  </div>
                                )}
                              </div>

                              <Grid xs={12} className={classes.submit}>
                                <Button
                                  disabled={!isValid || !agreeToTerms}
                                  type="submit"
                                  color={!isValid || !agreeToTerms ? "disablebtn" : "btnPrimary"}
                                  width={"100%"}
                                  text="Sign Up"
                                  // onClick={submitSignUp}
                                ></Button>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* Right Section */}
                <Grid style={{ marginTop: "165px", marginRight: "170px" }}>
                  <div>
                    <Typography className={classes.roletype} variant="h6" sx={{ textAlign: "left", mb: "12px" }}>
                      Your Plan
                    </Typography>
                  </div>
                  <div>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      style={{ height: "100%" }}
                    >
                      {(selectedPlan && currencyPlan) || freePlan ? (
                        <Box>
                          <Card
                            variant="outlined"
                            sx={{
                              borderTop: "8px solid #cd73ef",
                              borderRadius: "8px",
                              padding: "4px 16px",
                              backgroundColor: "#f9fafb",
                            }}
                          >
                            <CardContent>
                              <Typography variant="h5" fontWeight="bold">
                                {selectedPlan?.name || freePlan?.name}
                              </Typography>
                              <Typography variant="h3" fontWeight="bold" sx={{ mt: 2 }}>
                                <span style={{ fontSize: "20px" }}>{currencyPlan?.currencySymbol}</span>
                                {currencyPlan?.amount}
                              </Typography>
                              <Typography variant="caption">
                                {currencyPlan?.amount === "0"
                                  ? "Free forever"
                                  : "Billed " + selectedPlan?.billingFrequency || freePlan?.billingFrequency}
                              </Typography>
                              <div style={{ marginTop: "20px" }}>
                                {selectedPlan?.features.map((feature, index) => (
                                  <Typography key={index} className={`${classes.cardFeature}`}>
                                    {feature}
                                  </Typography>
                                ))}
                                {freePlan?.features.map((feature, index) => (
                                  <Typography key={index} className={`${classes.cardFeature}`}>
                                    {feature}
                                  </Typography>
                                ))}
                              </div>
                            </CardContent>
                          </Card>
                        </Box>
                      ) : (
                        <Card
                          variant="outlined"
                          sx={{
                            borderTop: "8px solid #cd73ef",
                            borderRadius: "8px",
                          }}
                        >
                          <div style={{ padding: "24px " }}>
                            <div>
                              <Skeleton variant="text" width={170} height={40} animation="wave" />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <Skeleton variant="text" width={80} height={40} animation="wave" />
                            </div>
                            <div>
                              <Skeleton variant="text" width={60} height={30} animation="wave" />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <Skeleton variant="text" width={170} height={30} animation="wave" />
                              <Skeleton variant="text" width={170} height={30} animation="wave" />
                              <Skeleton variant="text" width={170} height={30} animation="wave" />
                            </div>
                          </div>
                        </Card>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default SignUp;
