import React, { useState, useEffect } from "react";

const LoadingMessage = (props) => {
  let [message, setMessage] = useState("");

  const waitFor = (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  };

  const setMsg = async (msgList) => {
    for (let msg of Object.keys(msgList)) {
      setMessage(msgList[msg].msg);
      await waitFor(msgList[msg].ms);
    }
  };

  useEffect(() => {
    if (props.message) {
      setMsg(props.message);
    }
  }, []);

  // console.log(props.message) // FK cleanup console july 2023

  return (
    <>
      <p style={{ "margin-top": "15rem" }}>{message}...</p>
    </>
  );
};

export default LoadingMessage;

//{/* <LoadingMessage message={[{msg:'Compiling',ms:2500},{msg:'Running',ms:2500},{msg:'Verifying',ms:2500}]}></LoadingMessage> */}
