import React from "react";
import ReusableDialog from "./controls/ReusableDialog";
// import Button from "./controls/Button";
// import MuiDialog from "@mui/material/Dialog";
// import MuiDialogContent from "@mui/material/DialogContent";
// import MuiDialogActions from "@mui/material/DialogActions";
// import { withStyles, makeStyles } from "@mui/styles";
// import { Grid, Typography } from "@mui/material";

// const useStyles = makeStyles({
//   next: {
//     "& button": {
//       height: "40px",
//     },
//     marginLeft: "10px",
//   },
//   error: {
//     color: "#DD2E2E",
//   },
// });

// const Dialog = withStyles((theme) => ({
//   root: {
//     padding: 0,
//   },
// }))(MuiDialog);

// const DialogContent = withStyles((theme) => ({
//   root: {
//     paddingTop: "24px !important",
//   },
// }))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     paddingBottom: "24px !important",
//   },
// }))(MuiDialogActions);

export default function AlertDialog(props) {
  // const classes = useStyles();

  const onClickContinue = () => {
    props.onCLickContinue();
  };

  return (
    <div>
      <ReusableDialog
        open={props.open}
        title="Alert!!"
        content={props.text}
        actions={[{ onClick: onClickContinue, text: "Ok", color: "btn1" }]}
      />
      {/* <Dialog
        open={props.open}
        // onClose={props.onClose}
        onBackdropClick="false"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: `${props?.width || "343px"}`,
            height: "auto",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <DialogContent>
          <Typography variant2="b1Regular" className="" align="center">
            <h3>Alert!! {props.heading}</h3>
            {props.text}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Grid className={classes.next}>
            <Button
              color="btnPrimary"
              text="Ok"
              width={`${props?.yesBtnWidth || "100px"}`}
              onClick={onClickContinue}
              data-rt-confirmation-submitalert="submitalert"
            ></Button>
          </Grid>
        </DialogActions>
      </Dialog> */}
    </div>
  );
}
