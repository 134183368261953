import React from "react";
import AppRouter from "./AppRouter";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import store from "./redux/store";
import { theme } from "./styles/theme";
import { ThemeProvider } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { trackPageView } from "./services/ga4utility";

const useStyles = makeStyles({
  loader: {
    position: "fixed",
    top: "60px",
    width: "100%",
    zIndex: 1400,
    [theme.breakpoints.up("tablet")]: {
      top: "80px",
      zIndex: 1,
    },
  },
});

// const TRACKING_ID = "G-VRVZMVY4YC";
// ReactGA.initialize(TRACKING_ID);

function App() {
  const classes = useStyles();

  // const TRACKING_IDS = [
  //   process.env.REACT_APP_TRACKING_ID_1,
  //   process.env.REACT_APP_TRACKING_ID_2
  // ];

  // const TRACKING_IDS = ["G-VRVZMVY4YC", "G-X9WE5R5F8Y"];

  // TRACKING_IDS.forEach((trackingId, index) => {
  //   ReactGA.initialize(trackingId, {
  //     gaOptions: { name: `tracker${index + 1}` },
  //   });
  // });

  useEffect(() => {
    trackPageView("Home Page");
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <AppRouter loader={classes.loader} />
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
