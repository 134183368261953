import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Link, useMediaQuery } from "@mui/material";
// import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
// import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ScrollButton from "../../components/controls/ScrollButton";
import { theme } from "../../styles/theme";
import { getDomainDetails } from "../../services/globalService";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../redux/actions/auth";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
  },
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  faqdiv: {
    // marginTop: "100px",
    width: "100%",
  },
  textGrid: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  textGridlast: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "10px",
    // [theme.breakpoints.down("sm")]: {
    //   display: "inline",
    // },
  },
  faqText: {
    marginTop: "10px",
    //marginBottom: '10px'
  },
  faqparagraph: {
    margin: "0px 0px",
    padding: "0px 10px 6px 10px",
  },
  faqques: {
    padding: "10px",
  },
  textcontact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4px",
    },
  },
  labelBlue: {
    color: theme.palette.btnPrimary.main,
    marginTop: 8,
    marginBottom: "15px",
    cursor: "pointer",
    [theme.breakpoints.up("tablet")]: {
      margin: "0px",
    },
  },
  privacyText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    /* or 143% */

    /* Neutrals/Black */

    color: "#2F2F2F",

    /* Inside Auto Layout */

    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: "12px 0px",
  },
  textsty: theme.typography.b2Regular,
  textMedium: theme.typography.b1Medium,
});

export default function PrivacyPolicy(props) {
  // const { location } = props;

  const navigate = useNavigate();
  const classes = useStyles();
  // const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [domainDetails, setDomainDetails] = useState({});
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth);
  const logoUrls = process.env.REACT_APP_ASSETS_URL;

  useEffect(() => {
    let domainName = getDomainDetails(window.location.href);
    dispatch(auth.GetLogo({ subdomain: domainName.subdomainFromUrl }));
    setDomainDetails(authStore.logoDetails);
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
      /* you can also use 'auto' behaviour
               in place of 'smooth' */
    });
  };
  const handleBackButton = () => {
    navigate(-1);
  };
  return (
    <div className={classes.root}>
      <div className={`${classes.center} ${classes.logocls}`}>
        {domainDetails && domainDetails?.logo !== "" ? (
          <>
            <img height="50" width="157" src={`${logoUrls}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
            <img height="17" width="157" src={`${logoUrls}powered.svg`} alt="powered" />
          </>
        ) : (
          <>
            <img height="50" width="157" src={`${logoUrls}techscore.svg`} alt="TechScore" />
          </>
        )}
      </div>
      <Grid className={classes.faqdiv}>
        <Grid container>
          {isNotMobile && <Grid item xs={3}></Grid>}
          <Grid
            item
            xs={isNotMobile ? 6 : 12}
            style={isNotMobile ? { padding: "10px 10px 40px" } : { padding: "10px" }}
          >
            <Grid className={classes.textGrid}>
              <Typography className="about_sty">Privacy Policy</Typography>
              <Grid onClick={handleBackButton} data-rt-privacy-backgo="backgo">
                <Typography className={classes.labelBlue}>Back</Typography>
              </Grid>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                This Privacy Policy describes how your personal information is collected, used, and shared when you
                visit or make a purchase from techscore.ai (the “website”).
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                PERSONAL INFORMATION WE COLLECT
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                {" "}
                When you visit the website, we automatically collect certain information about your device, including
                information about your web browser, IP address, time zone, and some of the cookies that are installed on
                your device. Additionally, as you browse the website, we collect information about the individual web
                pages or products that you view, what websites or search terms referred you to the website, and
                information about how you interact with the website. We refer to this automatically-collected
                information as “Device Information.”
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                {" "}
                We collect Device Information using the following technologies:
              </Typography>
              <ul>
                <li className={classes.privacyText}>
                  {" "}
                  “Cookies” are data files that are placed on your device or computer and often include an anonymous
                  unique identifier. For more information about cookies, and how to disable cookies, visit
                  http://www.allaboutcookies.org.
                </li>

                <li className={classes.privacyText}>
                  “Log files” track actions occurring on the website, and collect data including your IP address,
                  browser type, Internet service provider, referring/exit pages, and date/time stamps.
                </li>

                <li className={classes.privacyText}>
                  “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you
                  browse the website.
                </li>
              </ul>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                {" "}
                When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device
                Information and Order Information.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                HOW DO WE USE YOUR PERSONAL INFORMATION?
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                We use the Device Information that we collect to help us screen for potential risk and fraud (in
                particular, your IP address), and more generally to improve and optimize our website (for example, by
                generating analytics about how our customers browse and interact with the website, and to assess the
                success of our marketing and advertising campaigns).
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                SHARING YOUR PERSONAL INFORMATION
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                {" "}
                We share your Personal Information with third parties to help us use your Personal Information, as
                described above. For example, we use Google Analytics to help us understand how our customers use the
                website--you can read more about how Google uses your Personal Information here:
                https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here:
                https://tools.google.com/dlpage/gaoptout.
              </Typography>

              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                Finally, we may also share your Personal Information to comply with applicable laws and regulations, to
                respond to a subpoena, search warrant or other lawful request for information we receive, or to
                otherwise protect our rights.
              </Typography>
            </Grid>

            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                {" "}
                BEHAVIOURAL ADVERTISING
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                As described above, we use your Personal Information to provide you with targeted advertisements or
                marketing communications we believe may be of interest to you. For more information about how targeted
                advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at
                http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                {" "}
                DO NOT TRACK
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                Please note that we do not alter our website’s data collection and use practices when we see a Do Not
                Track signal from your browser.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                {" "}
                YOUR RIGHTS
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                If you are a European resident, you have the right to access personal information we hold about you and
                to ask that your personal information be corrected, updated, or deleted. If you would like to exercise
                this right, please contact us through the contact information below. Additionally, if you are a European
                resident we note that we are processing your information in order to fulfill contracts we might have
                with you (for example if you make an order through the website), or otherwise to pursue our legitimate
                business interests listed above. Additionally, please note that your information will be transferred
                outside of Europe, including to Canada and the United States.
              </Typography>
            </Grid>

            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                {" "}
                DATA RETENTION
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                {" "}
                When you place an order through the website, we will maintain your Order Information for our records
                unless and until you ask us to delete this information.
              </Typography>
            </Grid>

            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                {" "}
                MINORS
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                {" "}
                The website is not intended for individuals under the age of 18 years.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                {" "}
                CHANGES
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                {" "}
                We may update this privacy policy from time to time in order to reflect, for example, changes to our
                practices or for other operational, legal or regulatory reasons.
              </Typography>
            </Grid>
            <Grid className={classes.faqText}>
              <Typography variant2="b1Medium" className={`${classes.faqques} ${classes.textMedium}`}>
                {" "}
                CONTACT US
              </Typography>
              <Typography className={`${classes.faqparagraph} ${classes.textsty}`} variant2="b2Regular">
                {" "}
                For more information about our privacy practices, if you have questions, or if you would like to make a
                complaint, please contact us by e-mail at{" "}
                <Link href="mailto:info@techscore.ai" className={classes.labelBlue} sx={{ textDecoration: "none" }}>
                  info@techscore.ai
                </Link>
                .{" "}
              </Typography>
            </Grid>

            <Grid className={classes.textGridlast}>
              <Grid onClick={handleBackButton} data-rt-privacy-back="back">
                <Typography className={classes.labelBlue}>Back</Typography>
              </Grid>
            </Grid>
            <ScrollButton />
          </Grid>
          {isNotMobile && <Grid item xs={3}></Grid>}
        </Grid>
      </Grid>
      <Footer></Footer>
    </div>
  );
}
