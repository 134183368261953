import React, { useEffect } from "react";
import Button from "../../../components/controls/Button";
import CloseIcon from "@mui/icons-material/Close";
import auth from "../../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Divider, Grid, Modal, Box, Typography, Card, CardContent, IconButton } from "@mui/material";

const useStyles = makeStyles({
  heading: {
    display: "flex",
    justifyContent: "left",
    fontWeight: 500,
  },
  icon: {
    position: "absolute !important",
    right: 8,
    top: 8,
  },
  mainGrid: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 30px",
  },
  card: {
    borderTop: "8px solid #cd73ef !important",
    borderRadius: "8px !important",
    padding: "4px 16px ",
    backgroundColor: "#f9fafb !important",
    width: "250px",
    height: "auto",
  },
  bold: {
    fontWeight: "bold !important",
  },
});

const SubscriptionModal = ({ open, handleClose, currency }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const classes = useStyles();

  const style = {
    minWidth: "600px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
  };

  const handleSubscribeBtn = (card) => {
    let payload = {
      plan: card.key,
    };
    dispatch(auth.creatCheckoutSession(payload));
  };

  useEffect(() => {
    const checkoutUrl = authState?.creatCheckoutSessionSuccess?.data?.checkoutSessionUrl;
    if (checkoutUrl) {
      window.open(checkoutUrl, "_self");
    }
  }, [authState.creatCheckoutSessionSuccess]);

  const setPrice = (price) => {
    const selectedPrice = price.find((p) => p.currency === currency);
    return {
      symbol: selectedPrice?.currencySymbol || "",
      amount: selectedPrice?.amount || "",
    };
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      width="100%"
    >
      <Box sx={style}>
        <Grid>
          <Typography className={classes.heading} variant="h6" sx={{ m: 2 }}>
            {authState?.BillingPagePlansSuccess?.data?.plans.length > 1 ? "Select a plan" : "Confirm plan"}
          </Typography>
        </Grid>
        <IconButton className={classes.icon} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Divider></Divider>
        <Grid className={classes.mainGrid}>
          {authState?.BillingPagePlansSuccess?.data?.plans
            // .filter((card) => card.name !== "Free")
            .map((card, index) => (
              <Card key={index} variant="outlined" className={classes.card} sx={{ m: 2 }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    className={classes.bold}
                    sx={{
                      height: "65px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {card.name}
                  </Typography>
                  <Typography variant="h3" className={classes.bold} sx={{ mt: 2 }}>
                    <span style={{ fontSize: "20px" }}>{setPrice(card.price).symbol}</span>
                    {setPrice(card.price).amount}
                  </Typography>
                  <Typography>Billed {card.billingFrequency}</Typography>
                  <div style={{ marginTop: "20px" }}>
                    <Typography>{card.features[0]}</Typography>
                    <Typography>{card.features[1]}</Typography>
                    {/* <Typography>{card.features[2]}</Typography> */}
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Button
                      sx={{ marginTop: "5px" }}
                      width="100%"
                      color="btn1"
                      text="Subscribe"
                      onClick={() => handleSubscribeBtn(card)}
                    ></Button>
                  </div>
                </CardContent>
              </Card>
            ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export default SubscriptionModal;
