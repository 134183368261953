import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "../../../components/controls/Button";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const useStyles = makeStyles((theme) => ({
  dialogPoints: {
    display: "flex",
    gap: "20px !important",
    marginTop: "30px",
    alignItems: "center",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "18px !important",
    color: `${theme.palette.text.primary} !important`,
  },
  successIcon: {
    color: "green",
    fontSize: "30px",
  },
  errorIcon: {
    color: "red",
    fontSize: "30px",
  },
}));

const PermissionsDialog = ({
  openDialog,
  loading,
  geoLocation,
  latitude,
  longitude,
  cameraPermission,
  loadingFace,
  faceDetected,
  loadingVoice,
  voiceDetected,
  networkToast,
  handleDialog,
  permissions,
  requiredPermissions,
}) => {
  const classes = useStyles();

  const renderPermission = (permission) => {
    switch (permission) {
      case "geoLocation":
        return (
          <Grid className={classes.dialogPoints} key="geoLocation">
            {loading ? (
              <CircularProgress size={24} />
            ) : geoLocation ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>Getting geolocation permission.</Typography>
          </Grid>
        );
      case "coordinates":
        return (
          <Grid className={classes.dialogPoints} key="coordinates">
            {loading ? (
              <CircularProgress size={24} />
            ) : latitude !== null && longitude !== null ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>Getting coordinates and location.</Typography>
          </Grid>
        );
      case "camera":
        return (
          <Grid className={classes.dialogPoints} key="camera">
            {loading ? (
              <CircularProgress size={24} />
            ) : cameraPermission ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>Getting camera and mic permissions.</Typography>
          </Grid>
        );
      case "faceDetection":
        return (
          <Grid className={classes.dialogPoints} key="faceDetection">
            {loadingFace ? (
              <CircularProgress size={24} />
            ) : faceDetected ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>Detecting human face. Please wave at the camera.</Typography>
          </Grid>
        );
      case "voiceDetection":
        return (
          <Grid className={classes.dialogPoints} key="voiceDetection">
            {loadingVoice ? (
              <CircularProgress size={24} />
            ) : voiceDetected ? (
              <CheckCircleIcon className={classes.successIcon} />
            ) : (
              <CancelIcon className={classes.errorIcon} />
            )}
            <Typography className={classes.viewText}>
              Detecting human voice. Say "Hello! I am ready for this test".
            </Typography>
          </Grid>
        );
      default:
        return null;
    }
  };

  const getMissingPermissions = () => {
    return requiredPermissions.filter((permission) => {
      switch (permission) {
        case "geoLocation":
          return !geoLocation;
        case "coordinates":
          return latitude === null || longitude === null;
        case "camera":
          return !cameraPermission;
        case "faceDetection":
          return !faceDetected;
        case "voiceDetection":
          return !voiceDetected;
        default:
          return false;
      }
    });
  };

  const hasMissingPermissions = permissions.some((permission) => {
    switch (permission) {
      case "geoLocation":
        return !geoLocation;
      case "coordinates":
        return latitude === null || longitude === null;
      case "camera":
        return !cameraPermission;
      case "faceDetection":
        return !faceDetected;
      case "voiceDetection":
        return !voiceDetected;
      default:
        return false;
    }
  });

  const missingPermissions = getMissingPermissions();

  // format permission name to show in dialog
  const formatPermission = (permission) => {
    switch (permission) {
      case "camera":
        return "Camera";
      case "geoLocation":
        return "Geo Location";
      default:
        return permission;
    }
  };

  // // generate dialog title based on required permissions
  // const generateDialogTitle = () => {
  //   const hasCameraOrMic = permissions.includes("camera");
  //   const hasLocation = permissions.includes("geoLocation");

  //   if (hasCameraOrMic && hasLocation) {
  //     return "Camera, Mic and Location";
  //   } else if (hasCameraOrMic) {
  //     return "Camera and Mic";
  //   } else if (hasLocation) {
  //     return "Location";
  //   } else {
  //     return "Permissions";
  //   }
  // };

  return (
    <Dialog open={openDialog} maxWidth="md" PaperProps={{ style: { width: "80%" } }}>
      {/* <DialogTitle>{generateDialogTitle()}</DialogTitle> */}
      <DialogTitle>Permissions</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {permissions.map((permission) => renderPermission(permission))}
          {loading === false && loadingFace === false && loadingVoice === false && hasMissingPermissions ? (
            <>
              <Typography sx={{ color: "red", mt: 5, ml: "45px" }}>
                We couldn't capture some of the required information. It is{" "}
                {missingPermissions.length > 0 ? "required" : "recommended"} to fix them before proceeding.
                {/* You may continue but it may affect your test evaluation. */}
              </Typography>
              {missingPermissions.length > 0 ? (
                <Typography sx={{ color: "red", mt: 1, ml: "45px" }}>
                  Missing required permissions:{" "}
                  <b>
                    {missingPermissions.map((permission, index) => (
                      <span key={permission}>
                        {formatPermission(permission)}
                        {index < missingPermissions.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </b>
                </Typography>
              ) : null}
            </>
          ) : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent:
            loading === false && loadingFace === false && loadingVoice === false && hasMissingPermissions
              ? "space-between"
              : "flex-end",
        }}
      >
        {loading === false && loadingFace === false && loadingVoice === false && hasMissingPermissions ? (
          <Grid sx={{ my: 2, mx: 2 }}>
            <Link
              onClick={networkToast}
              data-rt-opening-notreadytostart="notreadytostart"
              className={classes.linkUnderline}
            >
              <Typography variant2={"link1"} align="center" style={{ cursor: "pointer" }} className={classes.linktext}>
                Go back to fix permissions
              </Typography>
            </Link>
          </Grid>
        ) : null}
        <Button
          sx={{ my: 2, mx: 2 }}
          onClick={handleDialog}
          text="Continue to Instructions"
          color={loading || loadingFace || loadingVoice || missingPermissions.length > 0 ? "disablebtn1" : "btn1"}
          disabled={loading || loadingFace || loadingVoice || missingPermissions.length > 0}
        />
      </DialogActions>
    </Dialog>
  );
};

export default PermissionsDialog;
