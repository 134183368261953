import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import auth from "../../redux/actions/auth";
// import quiz from "../../redux/actions/quiz";
// import { useDispatch, useSelector } from "react-redux";

export default function LoginRedirect() {
  const navigate = useNavigate();
  let { userData } = useParams();

  useEffect(() => {
    let userDetails = JSON.parse(atob(userData));

    localStorage.setItem("token1", `Bearer ${userDetails.token}`);
    localStorage.setItem("userid", userDetails.userId);
    localStorage.setItem("logo", userDetails.logo);
    localStorage.setItem("user_details", atob(userData));

    if (userDetails?.role !== "Candidate") {
      setTimeout(() => {
        navigate("/home");
      }, 400);
    } else if (userDetails?.role === "Candidate") {
      localStorage.setItem("uid", userDetails?.inviteId);
      setTimeout(() => {
        navigate("/candidatedashboard");
      }, 400);
    }
  }, [userData]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h4>Redirecting. Please wait ...</h4>
    </div>
  );
}
