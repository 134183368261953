import { all, fork } from "redux-saga/effects";
import { authSaga } from "./auth";
import { roleSaga } from "./role";
import { quizSaga } from "./quiz";
import { chartSaga } from "./chart";

function* rootSaga() {
  yield all([authSaga, roleSaga, quizSaga, chartSaga].map(fork));
}

export default rootSaga;
