import React, { useState, useEffect } from "react";
// import Plot from "react-plotly.js";
import Plot from "./Plot";
import { useDispatch, useSelector } from "react-redux";
import chart from "../../../../redux/actions/chart";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { chartConfig } from "../../analytics/chartConfig";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import { analyticsEvent } from "../../../../services/ga4utility";

const DonutChart = () => {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const chartStore = useSelector((state) => state.chart);
  const [data, setData] = useState(chartStore?.PieChartData);

  const jobRoleCounts = {};
  data?.map((item) => {
    const jobRoleName = item.jobRoleName;
    jobRoleCounts[jobRoleName] = (jobRoleCounts[jobRoleName] || 0) + 1;
    return item;
  });

  const jobRoleNames = Object.keys(jobRoleCounts);
  const counts = Object.values(jobRoleCounts);

  const chartData = [
    {
      labels: jobRoleNames,
      values: counts,
      type: "pie",
      hole: 0.6,
      // hovertemplate: "Job Role: <b>%{label}</b>" + "<br>Count: <b>%{value}</b><br>" + "Percent: <b>%{percent}</b>",
      hovertemplate: `Job Role: <b>%{label}</b><br>Count: <b>%{value}</b><br>Percent: <b>%{percent}</b>`,
      hoverinfo: "text+value",
      textinfo: "value",
      insidetextfont: { size: 14 },
      name: "",
      direction: "clockwise",
      sort: true,
      marker: {
        colors: chartConfig.customColors,
      },
    },
  ];

  let showlegend;
  if (open === false) showlegend = false;
  else showlegend = true;

  let text;
  if (open === false)
    text =
      '<span style="font-size:1rem; font-weight:Bold">Job Role Distribution</span><br><span style="font-size:0.8rem">(Last 30 days)</span>';
  else
    text =
      '<span style="font-size:1.2rem;font-weight:Bold">Job Role Distribution</span><br><span style="font-size:0.9rem">(Last 30 days)</span>';

  const layout = {
    legend: {
      title: {
        text: '<span style="font-size:0.9rem; font-weight:Bold">&nbsp;&nbsp;&nbsp;Job Role</span>',
      },
      font: { size: 14 },
      // x:1,
      // y:1,
      // traceorder:'normal',
    },
    margin: {
      b: 10,
    },
    autosize: true,
    showlegend: showlegend,
    title: {
      text: text,
    },
    xaxis: {
      title: {
        text: "<b>Invited on</b>",
        standoff: 20,
      },
    },
    yaxis: {
      title: {
        text: "<b>Count of invites</b>",
        // standoff: 40,
      },
      // showline: true,
      // tickheight: 100,
      // ticks: 'inside'
    },
    hoverlabel: {
      bgcolor: "#FFF",
    },
    font: chartConfig.font,
  };

  useEffect(() => {
    let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let endDate = new Date();
    let startDate = new Date(endDate);
    // startDate.setDate(endDate.getDate() - 30);
    startDate.setMonth(endDate.getMonth() - 1);

    let payload = {
      startDate,
      endDate,
      browserTimeZone,
    };
    dispatch(chart.PieChartData(payload));
  }, []);

  useEffect(() => {
    if (chartStore?.pieChartData?.length) {
      setData(chartStore?.pieChartData);
    }
  }, [chartStore?.pieChartData?.length]);

  return (
    <>
      {chartStore?.pieChartData?.length ? (
        <Plot
          data={chartData}
          layout={layout}
          style={{ width: "95%", margin: "10px" }} // Adjust the size as needed
          containerId="JobRole"
          config={{
            modeBarButtonsToAdd: [
              {
                name: "Full Screen",
                icon: chartConfig.fullScreenIcon,
                click: function (gd) {
                  handleOpen();
                  analyticsEvent("chart", "Job Role Distribution Chart Open", "Job Role Distribution");
                },
              },
            ],
            displaylogo: false,
            responsive: true,
            // modeBarButtonsToRemove: ['toImage', 'select2d', 'lasso2d', 'resetScale2d', 'zoom2d']
            modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "450px",
            textAlign: "center",
          }}
        >
          <DonutSmallIcon style={{ marginBottom: "5px" }} />
          <span style={{ marginLeft: "5px", marginBottom: "5px" }}>{chartConfig.MessageWhenNoData}</span>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        bg="red"
      >
        <Box sx={styleModal} style={{ width: "100%", height: "100%", margin: "auto" }}>
          <Plot
            data={chartData}
            layout={layout}
            style={{ width: "100%", height: "100%" }}
            containerId="JobRoleModal"
            config={{
              modeBarButtonsToAdd: [
                {
                  name: "Exit Full Screen",
                  icon: chartConfig.exitFullScreenIcon,
                  click: function (gd) {
                    handleClose();
                  },
                },
              ],
              displaylogo: false,
              responsive: true,
              modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default DonutChart;
