import React from "react";
import ReusableDialog from "./ReusableDialog";

export default function ReactiveModel(props) {
  // eslint-disable-next-line
  const { open, text, succesBtnLabel, actionInfo, ...other } = props;
  // const classes = useStyles();

  const onClickSubmit = () => {
    props?.OnReactive();
  };
  const closeModel = () => {
    props.onClose();
  };

  return (
    <div>
      <ReusableDialog
        open={open}
        onClose={props.onClose}
        // title="Sample Dialog"
        content={props.Text}
        actions={[
          {
            onClick: closeModel,
            text: props.textchange === "ResetTest" ? "No" : "Cancel",
            color: "btnCls1",
            variant: "outlined",
          },
          {
            onClick: () => (props.Activatebtn === true ? null : onClickSubmit()),
            text: props.textchange === "ResetTest" ? "Yes" : "Yes, activate",
            color: props.Activatebtn === true ? "disablebtn1" : "btn1",
          },
        ]}
      />
    </div>
  );
}
