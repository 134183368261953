import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, useMediaQuery } from "@mui/material";
import TextInput from "../../components/controls/TextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import { useParams, useNavigate } from "react-router-dom";
import auth from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { getDomainDetails } from "../../services/globalService";
import Footer from "../../components/Footer";

const useStyles = makeStyles({
  centerContainer: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "10px",
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  submit: {
    marginTop: "24px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

export default function ResetPassword(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [domainDetails, setDomainDetails] = useState({});
  const logoUrl = process.env.REACT_APP_ASSETS_URL;
  const [resetSuccess, setResetSuccess] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  // const [resetError, setResetError] = useState(false);
  const { token } = useParams();
  const { resetpasswordSuccess, resetpasswordFailure } = useSelector((state) => state.auth);
  const [pageNotFound, setPageNotFound] = useState(false);
  // const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const matches = useMediaQuery("(max-width:850px)");

  useEffect(() => {
    if (props.activity === "CreateUserPassword") {
      dispatch(auth.checkInvitationStatus(token));
    }

    if (props.activity === "CreatePassword") {
      if (!authStore.acceptValidationSuccess || authStore.acceptValidationSuccess === null) {
        if (token) {
          dispatch(auth.AcceptValidation(token));
        }
      }
    }

    if (props.activity === "ResetPassword") {
      dispatch(auth.checkResetPasswordToken(token));
    }
  }, []);

  // Check invitation token success or failure
  useEffect(() => {
    if (props.activity === "CreateUserPassword") {
      if (authStore.checkInvitationData?.redirect) {
        if (authStore.checkInvitationData?.link) {
          navigate(authStore.checkInvitationData?.link);
          setDisplayForm(true);
        }
      }

      if (authStore.checkInvitationData?.redirect === false) {
        setDisplayForm(false);
      }
    }
  }, [authStore.checkInvitationData]);

  // Check reset password token if correct
  useEffect(() => {
    if (props.activity === "ResetPassword") {
      if (authStore.checkResetPasswordTokenData?.redirect) {
        if (authStore.checkResetPasswordTokenData?.link) {
          navigate(authStore.checkResetPasswordTokenData?.link);
        }
      }

      if (authStore.checkResetPasswordTokenData?.redirect === false) {
        setDisplayForm(true);
      }
    }
  }, [authStore.checkResetPasswordTokenData]);

  useEffect(() => {
    if (authStore.checkValidationData?.redirect) {
      if (authStore.checkValidationData?.link) {
        navigate(authStore.checkValidationData?.link);
      }
    }

    if (authStore.checkValidationData?.redirect === false) {
      setDisplayForm(false);
    }
  }, [authStore.checkValidationData]);

  // Accept validation success and Create Password Token Validation
  useEffect(() => {
    if (props.activity === "CreatePassword") {
      if (authStore.acceptValidationSuccess?.redirect === true) {
        // setDisplayForm(true)
        if (authStore.acceptValidationSuccess && authStore.acceptValidationSuccess?.link === "/pageNotFound") {
          setPageNotFound(true);
          setDisplayForm(false);
          navigate(authStore.acceptValidationSuccess?.link);
        }

        let createPwdLink = `/createPassword/${token}`;

        if (authStore.acceptValidationSuccess?.link && authStore.acceptValidationSuccess?.link === createPwdLink) {
          setDisplayForm(true);
          setPageNotFound(false);
        }
      }
    }
  }, [authStore.acceptValidationSuccess]);

  // handle password success
  useEffect(() => {
    if (resetpasswordSuccess && resetpasswordSuccess?.status === 0) {
      setResetSuccess(true);
    }
  }, [resetpasswordSuccess]);

  // handle password failure
  useEffect(() => {
    if (resetpasswordFailure && resetpasswordFailure?.status !== 0) {
      setResetSuccess(false);
    }
  }, [resetpasswordFailure]);

  // Handle the click of submit .
  const onSubmit = (values) => {
    // Check if passwords match before resetting the password
    if (values.password !== values.confirmPassword) {
      // setResetError(true);
      return;
    }

    let payload = {
      token: token,
      newPassword: values.password,
    };
    setPassword(values.password);
    if (props.activity === "ResetPassword") {
      // Dispatch the action to reset the password
      dispatch(auth.ResetPassword(payload))
        .then((response) => {
          // Password reset success
          setResetSuccess(true);
        })
        .catch((error) => {
          // Password reset failed
          // setResetError(true);
        });
    }

    if (props.activity === "CreatePassword") {
      // Dispatch the action to reset the password
      dispatch(auth.createPassword({ data: { newPassword: values.password }, token: token }))
        .then((response) => {
          // Password reset success
          // setResetSuccess(true)
        })
        .catch((error) => {
          // Password reset failed
          // setResetError(true);
        });
    }

    if (props.activity === "CreateUserPassword") {
      // Dispatch the action to reset the password
      setPassword(values.password);
      dispatch(auth.createUserPassword({ data: { newPassword: values.password }, token: token }))
        .then((response) => {
          // Password reset success
          // setResetSuccess(true);
        })
        .catch((error) => {
          // Password reset failed
          // setResetError(true);
        });
    }
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const initialFormValues = {
    ...initialValues,
    number: token || "",
  };

  // Validtion for login
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("This field cannot be empty")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z\d!@#$%^&*()\-_=+[{\]};:'",<.>/?\\|~`]).{12,}$/,
        "Password must be 12 characters long and contain at least one uppercase letter, number, or special character."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("This field cannot be empty"),
  });

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    let value = localStorage.getItem("token1");
    let userDetails = JSON.parse(localStorage.getItem("user_details"));

    if (userDetails?.redirectDomain) {
      setTimeout(() => {
        window.location.href = userDetails.url;
      }, 1000);
      localStorage.removeItem("token1");
      localStorage.removeItem("user_details");
      localStorage.removeItem("nextMaintenanceCheck");
    } else {
      if (value && userDetails.role !== "Candidate") {
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      }
    }
  }, [localStorage.getItem("token1")]);

  // Create USer Password
  useEffect(() => {
    if (props.activity === "CreateUserPassword") {
      if (authStore.createUserPasswordSuccessData) {
        if (authStore.createUserPasswordSuccessData?.success) {
          // setResetSuccess(true);
          let payload = {
            uid: authStore.createUserPasswordSuccessData?.userId,
            password: password,
          };
          dispatch(auth.LoginUser(payload));
        }
      }
    }
  }, [authStore.createUserPasswordSuccessData]);

  // Create Password Success
  useEffect(() => {
    if (props.activity === "CreatePassword") {
      if (authStore.createPasswordSuccessData) {
        if (authStore.createPasswordSuccessData?.success) {
          // setResetSuccess(true);
          let payload = {
            uid: authStore.createPasswordSuccessData?.userId,
            password: password,
          };
          dispatch(auth.LoginUser(payload));
        }
      }
    }
  }, [authStore.createPasswordSuccessData]);

  // Extract subdomain info
  useEffect(() => {
    let domainName = getDomainDetails(window.location.href);
    dispatch(auth.GetLogo({ subdomain: domainName.subdomainFromUrl }));
    setDomainDetails(authStore.logoDetails);
  }, []);

  useEffect(() => {
    setDomainDetails(authStore.logoDetails);
  }, [authStore.logoDetails]);

  return (
    <>
      <Grid>
        <div
          className={`${classes.center} ${classes.logocls}`}
          style={matches ? { padding: "5px", position: "fixed", width: "100%" } : {}}
        >
          {domainDetails && domainDetails?.logo !== "" ? (
            <>
              <img height="50" width="157" src={`${logoUrl}${domainDetails?.logo}`} alt={domainDetails?.displayName} />
              <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
            </>
          ) : (
            <>
              <img height="50" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
            </>
          )}
        </div>
        <Grid container display={"flex"} justifyContent={"center"} minHeight="80vh">
          {displayForm && (
            <Grid item xs={isNotMobile ? 4 : 10} style={matches ? { marginTop: "60px" } : { marginTop: "40px" }}>
              {props.activity === "CreatePassword" && !resetSuccess ? (
                <Typography className={classes.textview} style={{ textAlign: "center" }}>
                  Email successfully verified. Please set up your password.
                </Typography>
              ) : null}
              <Typography align="center" sx={matches ? { pt: 0 } : {}} className={classes.textsty}>
                {props.activity === "ResetPassword"
                  ? "Reset Password"
                  : props.activity === "CreateUserPassword"
                  ? "Create User Password"
                  : "Create Password"}
              </Typography>
              {resetSuccess ? (
                <div className={classes.centerContainer}>
                  <Typography className={classes.textview} style={{ textAlign: "center" }}>
                    {props.activity === "ResetPassword"
                      ? "Password reset successfully. "
                      : "Password created successfully. "}
                    Click <a href="/login">here</a> to go to the <a href="/login">Login</a> page.
                  </Typography>
                </div>
              ) : (
                <form onSubmit={formik.handleSubmit}>
                  <Typography className={`${classes.roletype}`}>New Password</Typography>
                  <TextInput
                    name="password"
                    placeholder="Enter New Password"
                    className={classes.field}
                    id="password"
                    onChange={formik.handleChange}
                    error={formik.touched.password && formik.errors.password ? Boolean(formik.errors.password) : null}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    type={showPassword ? "text" : "password"}
                    passwordText={"PasswordIcon"}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  ></TextInput>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error">
                      <Typography className={classes.errorty}>{formik.errors.password}</Typography>
                    </div>
                  ) : null}

                  <Typography className={`${classes.roletype}`}>Confirm Password</Typography>
                  <TextInput
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    className={`${classes.field} parentclassname`}
                    id="confirmPassword"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword && formik.errors.confirmPassword
                        ? Boolean(formik.errors.confirmPassword)
                        : null
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    type={showPasswordConfirm ? "text" : "password"}
                    passwordText={"PasswordIcon"}
                    showPassword={showPasswordConfirm}
                    setShowPassword={setShowPasswordConfirm}
                  ></TextInput>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="error">
                      <Typography className={classes.errorty}>{formik.errors.confirmPassword}</Typography>
                    </div>
                  ) : null}
                  <Grid className={classes.submit}>
                    <Button
                      disabled={!(formik.isValid && formik.dirty && formik.values)}
                      type="submit"
                      data-rt-loginwithemail-signin="signin"
                      color={!(formik.isValid && formik.dirty && formik.values) ? "disablebtn" : "btnPrimary"}
                      width={"100%"}
                      text={props.activity === "ResetPassword" ? "Reset Password" : "Create Password"}
                    ></Button>
                  </Grid>

                  {props.activity === "CreatePassword" || props.activity === "CreateUserPassword" ? null : (
                    <Typography className={classes.textviewLink}>
                      Go back to <a href="/login">Login</a> page.
                    </Typography>
                  )}
                </form>
              )}
              {/* <Grid
                            container
                            className={`${classes.section} ${classes.center}  ${classes.cardSix}`}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                        </Grid> */}
            </Grid>
          )}
          {pageNotFound && (
            <div>
              <h1>404 Page Not Found</h1>
            </div>
          )}
        </Grid>
      </Grid>
      {props.activity === "CreateUserPassword" ? <Footer /> : null}
    </>
  );
}
