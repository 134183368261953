import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid } from "@mui/material";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import TextInput from "../../components/controls/TextField";
import { useFormik } from "formik";
import { useLocation, useParams, useNavigate, generatePath } from "react-router-dom";
import SelectMenu from "../../components/controls/SelectMenu";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../redux/actions/auth";
import SimpleSnackbar from "../../components/controls/ToastMessage";
import role from "../../redux/actions/role";
import Footer from "../../components/Footer";
import * as Yup from "yup";
import { transformOptions } from "../../services/globalService";
import ReusableDialog from "../../components/controls/ReusableDialog";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";

// styles for this component
const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878",
    },
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
  },
  field: {
    height: "48px !important",
    marginTop: "8px ",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
  },
  field1: {
    // height: 48,
    marginTop: 8,
    borderRadius: "5px !important",
    // width: '533px !important',
    //border:'1px solid !important',
    backgroundColor: theme.palette.btnPrimary.light,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  textareasty: {
    width: "98.2%",
    // background: "#EBEFF8",
    borderRadius: "4px",
    //  border: "1px solid #787878",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  textareasty1: {
    width: "98.2%",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },

  messageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  textview: {
    marginRight: "100px",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "20px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  errorty: theme.typography.error,
});

export default function AddRecruiters() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [editList, setEditList] = useState([])
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const authstate = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const [snacktext, setsnacktext] = useState("");
  const [emailErrMsg, setEmailErrMsg] = useState("");
  const [addview, setAddview] = useState(false);
  const [useraddid, setUseraddid] = useState(null);
  // const [savebtn, setSavebtn] = useState(false);
  const pathName = location.pathname;
  const { id } = useParams();
  const editpathName = `/edituser/${id}`;
  const [selectrole, setSelectrole] = useState("");
  const [recruiterType, setRecruiterType] = useState("");
  const isTeamAdmin = pathName === editpathName && authstate?.recruiterdetails?.roleSubType === "TeamAdmin";
  let userDetails = JSON.parse(localStorage.getItem("user_details"));

  // initial render for reference data
  useEffect(() => {
    if (pathName !== editpathName) {
      dispatch(auth.checkSubscriptionStatusUser());
    }
    dispatch(role.referenceData());
    // eslint-disable-next-line
  }, []);

  const AddRecruiterChange = (values) => {
    // handle the click of Add and edit recruiter
    // setSavebtn(true);
    // let userid = localStorage.getItem("userid") // FK cleanup unused variable july 2023
    let payload = {
      firstName: formik.values.givenName,
      lastName: formik.values.familyName,
      email: formik.values.email,
      note: formik.values.textAreaValue,
      roleSubType: selectrole ? selectrole?.id : formik.values.selectrole,
      role: "Recruiter",
    };
    if (pathName === editpathName) {
      let payload1 = {
        data: payload,
        id: id,
      };
      dispatch(auth.editRecruiter(payload1));
    } else {
      dispatch(auth.addRecruiturment(payload));
    }
  };

  useEffect(() => {
    // run after add recruiter status is success
    if (authstate.addrecruiter.status === 0) {
      setOpen(true);
      setsnacktext("New recruiter added");
      let add_id = authstate?.addrecruiter?.data?.uid;
      setUseraddid(add_id);
      setAddview(true);
      setTimeout(() => {
        // setSavebtn(false);
      }, 1000);
      dispatch(auth.recruiterDetails(add_id));
    } else if (authstate.addrecruiter.status === 1) {
      setOpen(true);
      setsnacktext("Already Created");
      // setSavebtn(false);
    }
    // eslint-disable-next-line
  }, [authstate?.addrecruiter?.status]);

  // Checking if the addview is true and if the authstate has a recruiterdetails and if the uid is true then it will navigate to the path
  useEffect(() => {
    if (addview && authstate?.recruiterdetails?.uid) {
      navigate(generatePath("/userview/:id", { id: useraddid }));
    }
    // eslint-disable-next-line
  }, [addview && authstate?.recruiterdetails?.uid]);

  // navigate to diffrent page if status is true
  useEffect(() => {
    //// run after edit recruiter status is success
    if (authstate?.editrecruiter?.status === 0) {
      setOpen(true);
      setsnacktext("Saved profile changes");
      // let edit_id = authstate?.editrecruiter?.data?.uid
      setTimeout(() => {
        navigate(generatePath("/userview/:id", { id }));
        // setSavebtn(false);
      }, 1000);
    } else if (authstate?.editrecruiter?.status === 1) {
      setOpen(true);
      setsnacktext("Already Created");
      // setSavebtn(false);
    }
  }, [authstate?.editrecruiter?.status]);

  // setting select role
  const HandleChangeRole = (value) => {
    setSelectrole(value);
  };

  useEffect(() => {
    const recruiterTypes = transformOptions(roleState?.referencelist?.recruiterTypes);
    const matchedType = recruiterTypes?.find(
      (type) =>
        type?.id === authstate?.recruiterdetails?.roleSubType ||
        type?.displayName === authstate?.recruiterdetails?.roleSubType
    );
    setRecruiterType(matchedType);
  }, [authstate?.recruiterdetails?.roleSubType, roleState?.referencelist?.recruiterTypes]);

  // initial values of the form.
  const initialValues = {
    givenName: pathName === editpathName ? authstate?.recruiterdetails?.user?.firstName : "",
    familyName: pathName === editpathName ? authstate?.recruiterdetails?.user?.lastName : "",
    email: pathName === editpathName ? authstate?.recruiterdetails?.user?.email : "",
    textAreaValue: "",
    selectrole: pathName === editpathName ? recruiterType : "",
  };
  // console.log("jskdfj", editList)
  const validationSchema = Yup.object().shape({
    givenName: Yup.string().required("This field cannot be empty"),
    familyName: Yup.string().required("This field cannot be empty"),
    email: Yup.string().email("Invalid email format").required("This field cannot be empty"),
  });
  const formik = useFormik({
    initialValues,
    // onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  //  If the pathname is equal to the editpathname, then navigate to the recruiter view page. Otherwise, navigate to the recruiter list page.
  const cancelRecruiter = () => {
    if (pathName === editpathName) {
      // dispatch(auth.recruiterDetails(id))
      navigate(generatePath("/userview/:id", { id }));
    } else {
      navigate("/userlist");
    }
  };
  let regEmail =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // checking email field
  const checkAvailabilityEmail = (e) => {
    let payloadSearchbyemail = {
      searchTxt: formik.values.email,
      type: "Recruiter",
    };
    if (formik.values.email === "") {
      setEmailErrMsg("This field cannot be empty");
    } else if (formik.values.email !== "" && regEmail.test(formik.values.email)) {
      dispatch(auth.candidateSearchByEmail(payloadSearchbyemail));
    } else if (!regEmail.test(formik.values.email) && formik.values.email !== "") {
      // return 'Invalid Email Address';
      setEmailErrMsg("Invalid Email Address");
    } else {
      setEmailErrMsg("");
    }
  };
  // Checking if the email address is already in use with an existing user.
  useEffect(() => {
    if (authstate?.recruiterdetails?.user?.email === authstate?.searchByEmail?.email) {
      setEmailErrMsg("");
    } else if (authstate?.searchByEmail?.error) {
      setEmailErrMsg(authstate?.searchByEmail?.message);
    } else {
      setEmailErrMsg("");
    }
    //return emailErrMsg;
    // eslint-disable-next-line
  }, [authstate?.searchByEmail?.email]);

  useEffect(() => {
    if (pathName === editpathName) {
      setSelectrole(authstate?.recruiterdetails?.roleSubType);
      checkAvailabilityEmail(authstate?.recruiterdetails?.user?.email);
    }
    // eslint-disable-next-line
  }, [authstate?.recruiterdetails]);

  const isMaxLimitZero = authstate?.checkSubscriptionStatusUserData?.maxLimit === 0;

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDelete = () => {
    let payload = {
      uid: authstate?.recruiterdetails?.uid,
    };
    dispatch(auth.deleteInvitedUser(payload));
  };

  useEffect(() => {
    if (authstate?.deleteInvitedUserSuccess && pathName === editpathName) {
      navigate("/userlist");
    }
  }, [authstate?.deleteInvitedUserSuccess]);

  // useEffect(()=> {
  //   if (authstate?.checkSubscriptionStatusUserData.trialExpiryApproaching) {
  //     localStorage.setItem("trialExpiryApproaching", authstate?.checkSubscriptionStatusUserData.trialExpiryApproaching);
  //   }
  //   if (authstate?.checkSubscriptionStatusUserData.expiryDate) {
  //     localStorage.setItem("expiryDate", authstate?.checkSubscriptionStatusUserData.expiryDate);
  //   }
  // }, [authstate?.checkSubscriptionStatusUserData])

  return (
    <>
      {isMaxLimitZero ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <NavBar />
          <Container maxWidth="xl" className={classes.container}>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ width: "50%", margin: "auto", textAlign: "center" }}
            >
              <Grid item className={classes.messageContainer}>
                {userDetails.roleSubType === "TeamAdmin" ? (
                  <Typography>
                    {" "}
                    You have reached your usage limit for inviting a user or your subscription is not active. Please
                    upgrade your subscription.
                  </Typography>
                ) : (
                  <Typography>
                    You have reached your usage limit for inviting a user or your subscription is not active. Please
                    contact Team Admin to upgrade your subscription.
                  </Typography>
                )}
              </Grid>
              <Grid item style={{ marginTop: "30px" }} className={classes.buttonContainer}>
                <Typography sx={{ mr: "30px" }} className={classes.textview}>
                  <a
                    href="/userlist"
                    // variant="contained"
                    // color="btn"
                    // // onClick={() => navigate('/userlist')}
                    // text='Back to Team page'
                  >
                    Back to Team page
                  </a>
                </Typography>
                {userDetails.roleSubType === "TeamAdmin" ? (
                  <Button
                    variant="contained"
                    color="btn"
                    onClick={() => navigate("/accountSettings", { state: { selectedSection: "billing" } })}
                    text="Upgrade"
                  ></Button>
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <div>
          <NavBar />
          <Container maxWidth="xl" className={classes.container}>
            <Grid style={{ marginTop: "130px" }}>
              <Grid style={{ display: "flex" }}>
                {/* <Typography className={classes.viewText}>
                  {" "}
                  Home / Team /{" "}
                  <span className={classes.viewText} style={{ fontWeight: 700 }}>
                    {pathName === editpathName ? "Edit User" : "Invite New User"}
                  </span>
                </Typography> */}
                <CustomBreadcrumbs
                  links={[
                    { name: "Home", path: "/home" },
                    { name: "Team", path: "/userlist" },
                    {
                      name: pathName === editpathName ? `${authstate?.recruiterdetails?.uid || ""}` : "Invite New User",
                    },
                  ]}
                />
              </Grid>
              <Grid container className="Home-hero" alignItems="center">
                <Grid item xs={1}></Grid>
                <Grid item xs={10} style={{ marginTop: "20px" }}>
                  <Grid className={classes.spaceflex}>
                    <Typography className={classes.roletext}>
                      {pathName === editpathName ? "Edit User" : "Invite New User"}
                    </Typography>
                    <Typography className={classes.roletext}>
                      {" "}
                      {pathName === editpathName ? (
                        <span>User ID {authstate?.recruiterdetails?.uid}</span>
                      ) : (
                        <span></span>
                      )}
                    </Typography>
                  </Grid>
                  <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid style={{ marginTop: "10px" }} item xs={6}>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          First Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextInput
                          classes={{ root: classes.customTextField }}
                          name="givenName"
                          placeholder="Ex. John, Mary, Alice"
                          className={formik.values.givenName === "" ? classes.field : classes.field1}
                          id="givenName"
                          onChange={formik.handleChange}
                          // onKeyDown={handleKeyDown}
                          error={
                            formik.touched.givenName && formik.errors.givenName
                              ? Boolean(formik.errors.givenName)
                              : null
                          }
                          onBlur={formik.handleBlur}
                          value={formik.values.givenName}
                          disabled={isTeamAdmin}
                        ></TextInput>
                        {formik.touched.givenName && formik.errors.givenName ? (
                          <div className="error">
                            <Typography className={classes.errorty} style={theme.typography.error}>
                              {formik.errors.givenName}
                            </Typography>
                          </div>
                        ) : null}
                      </Grid>

                      <Grid style={{ marginTop: "10px" }} item xs={6}>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          Last Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextInput
                          name="familyName"
                          placeholder="Ex. Jones, Kumar, Cheng"
                          // className={classes.field}
                          className={formik.values.familyName === "" ? classes.field : classes.field1}
                          id="familyName"
                          onChange={formik.handleChange}
                          // onKeyDown={handleKeyDown}
                          error={
                            formik.touched.familyName && formik.errors.familyName
                              ? Boolean(formik.errors.familyName)
                              : null
                          }
                          onBlur={formik.handleBlur}
                          value={formik.values.familyName}
                          //  style={{ borderRadius: "5px", width: '533px',border:'1px solid' }}
                          disabled={isTeamAdmin}
                        ></TextInput>
                        {formik.touched.familyName && formik.errors.familyName ? (
                          <div className="error">
                            <Typography className={classes.errorty} style={theme.typography.error}>
                              {formik.errors.familyName}
                            </Typography>
                          </div>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid style={{ marginTop: "10px" }} item xs={6}>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          Email Address <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextInput
                          name="email"
                          placeholder="email123@email.com"
                          className={formik.values.email === "" ? classes.field : classes.field1}
                          id="email"
                          onChange={formik.handleChange}
                          error={formik.touched.email && formik.errors.email ? Boolean(formik.errors.email) : null}
                          onBlur={checkAvailabilityEmail}
                          value={formik.values.email}
                          style={{ borderRadius: "5px" }}
                          disabled={pathName === editpathName && authstate?.recruiterdetails?.status !== "invited"}
                        ></TextInput>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error">
                            <Typography className={classes.errorty} style={theme.typography.error}>
                              {formik.errors.email}
                            </Typography>
                          </div>
                        ) : null}
                        {authstate?.searchByEmail?.email === formik.values.email && (
                          <div className="error">
                            <Typography className={classes.errorty} style={theme.typography.error}>
                              {emailErrMsg}
                            </Typography>
                          </div>
                        )}
                        {formik.values.email === "" && (
                          <div className="error">
                            <Typography className={classes.errorty} style={theme.typography.error}>
                              {emailErrMsg}
                            </Typography>
                          </div>
                        )}
                        {!regEmail.test(formik.values.email) && formik.values.email !== "" && (
                          <div className="error">
                            <Typography className={classes.errorty} style={theme.typography.error}>
                              {emailErrMsg}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                      <Grid style={{ marginTop: "10px" }} item xs={6}>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F", marginLeft: "3px" }}>
                          Select a Role Type <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectMenu
                          selectvalue={roleState?.referencelist?.recruiterTypes?.sort((a, b) =>
                            a?.displayName?.localeCompare(b?.displayName)
                          )}
                          selectHandleChange={(value) => HandleChangeRole(value)}
                          prevSelectedValue={pathName === editpathName ? recruiterType : null}
                          selectText={"Recruiter"}
                          disabled={isTeamAdmin}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        Internal Notes (optional)
                      </Typography>
                      <textarea
                        style={{ resize: "none" }}
                        value={formik.values.textAreaValue}
                        id="textAreaValue"
                        onChange={formik.handleChange}
                        placeholder="Enter your notes here"
                        rows={5}
                        cols={5}
                        className={formik.values.textAreaValue === "" ? classes.textareasty : classes.textareasty1}
                        maxLength="500"
                      />
                    </Grid>

                    <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                    <Grid style={{ display: "flex", justifyContent: "space-between", paddingTop: "12px" }}>
                      <Button
                        data-rt-recruiter-add="cancel"
                        color="cancel_btn"
                        text="Cancel"
                        // type="submit"
                        onClick={cancelRecruiter}
                        width={112}
                      ></Button>
                      <div>
                        {pathName === editpathName && authstate?.recruiterdetails?.status === "invited" && (
                          <Button
                            // color="btn"
                            sx={{ mr: 2 }}
                            color="btnDelete"
                            width={199}
                            text="Delete User"
                            onClick={handleClickOpen}
                          ></Button>
                        )}
                        <Button
                          //  disabled={pathName === editpathName ? '' : !(formik.isValid && formik.dirty && formik.values && selectrole == '')}
                          data-rt-recruiter-add={pathName === editpathName ? "savechanges" : "createnewrecruiter"}
                          // color="btn"
                          sx={{ color: "#FFFF !important" }}
                          color={
                            selectrole === "" || !formik.isValid || emailErrMsg !== "" || isTeamAdmin
                              ? "disablebtn"
                              : "btn"
                          }
                          width={199}
                          text={pathName === editpathName ? "Save Changes" : "Invite User"}
                          onClick={
                            selectrole === "" || !formik.isValid || emailErrMsg !== "" ? null : AddRecruiterChange
                          }
                          type="submit"
                          disabled={isTeamAdmin}
                        ></Button>
                      </div>
                    </Grid>
                  </form>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>
          </Container>
          <Footer />
          <SimpleSnackbar open={open} setOpen={setOpen} text={snacktext} />
          <ReusableDialog
            open={openDialog}
            onClose={handleClose}
            title="Are you sure?"
            content="This action will delete all the information associated with this email id."
            actions={[
              { onClick: handleClose, text: "Cancel", color: "btn1" },
              { onClick: handleDelete, text: "Delete", color: "btn1Delete" },
            ]}
          />
          {/* <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Are you sure ?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action will delete all the information associated with this email id.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button text="Cancel" color="btn1" onClick={handleClose}></Button>
              <Button text="Delete" color="btn1Delete" onClick={handleDelete}></Button>
            </DialogActions>
          </Dialog> */}
        </div>
      )}
    </>
  );
}
