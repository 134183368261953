import React, { useState, useEffect } from "react";
// import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import "../../home/home.css";
import CodingContainer from "../../../components/quiz/CodingContainer/CodingContainer";
import { useSelector, useDispatch } from "react-redux";
import quiz from "../../../redux/actions/quiz";
import AppConfig from "../../../constants/AppConfig";
import OnAppBlurDialog from "../../../components/OnAppBlurDialog";
import role from "../../../redux/actions/role";

// FK cleanup unused variable july 2023
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.primary.light,
//   },
//   center: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

function CodingQuestions(props) {
  const [value, setState] = useState({
    quesObject: {},
  });

  const routeParams = useParams();
  // const history = useNavigate();

  const dispatch = useDispatch();
  const quesRes = useSelector((state) => state.quiz.questions);
  //const quizState = useSelector((state) => state.quiz);

  const [onAppFocus, setAppFocus] = useState(true);
  const [appFocusCount, setAppFocusCOunt] = useState(0);
  const [appUnexpectedBehaviourSubmit, setAppUnexpectedBehaviourSubmit] = useState(false);
  // const proctoringDetails = JSON.parse(localStorage.getItem("proctoring"));
  const proctoringDetails = useSelector((state) => state.role.candidateDashboardDetails.proctoring);
  const uid = localStorage.getItem("uid");

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleContextmenu = (e) => e.preventDefault();

  useEffect(() => {
    if (!proctoringDetails) {
      dispatch(role.candidateDashboardDetails(uid));
    }
    document.addEventListener("contextmenu", handleContextmenu);
    document.onkeydown = function (e) {
      // disable F12 key
      if (e.keyCode === 123) {
        return false;
      }

      // disable I key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
        return false;
      }

      // disable J key
      if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
        return false;
      }

      // disable U key
      if (e.ctrlKey && e.keyCode === 85) {
        return false;
      }
      // disable A key
      if (e.ctrlKey && e.keyCode === 65) {
        return false;
      }
    };
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  // useEffect(() => {
  //   // console.log("state", value);
  // }, [quesRes]);

  useEffect(() => {
    // console.log("Did Mounted", routeParams);
    if (+routeParams.qIndex - 1 === 0) {
      dispatch(quiz.updateLevel(AppConfig.LEVEL3));
    }

    if (quesRes.length !== 0) {
      var idx = +routeParams.qIndex - 1;
      let quesObject = quesRes[idx];

      setState({
        ...value,
        quesObject: { ...quesObject },
      });
    }

    return () => console.log("Will Unmounted");
    // eslint-disable-next-line
  }, [routeParams.qIndex]);

  useEffect(() => {
    if (quesRes) {
      // console.log("Did Mounted", quesRes);

      let quesObject = quesRes[+routeParams.qIndex - 1];
      setState({
        ...value,
        quesObject: { ...quesObject },
      });
    }
    return () => console.log("Will Unmounted");
    // eslint-disable-next-line
  }, [quesRes]);

  // User has switched back to the tab
  const handleFocusApp = () => {
    // console.log("Tab is in focus");
  };

  // User has switched away from the tab (AKA tab is hidden)
  const handleBlurApp = () => {
    // console.log(appFocusCount)
    setAppFocusCOunt((cnt) => cnt + 1);
    setAppFocus(false);
  };

  useEffect(() => {
    window.addEventListener("focus", handleFocusApp);
    window.addEventListener("blur", handleBlurApp);
    return () => {
      window.removeEventListener("focus", handleFocusApp);
      window.removeEventListener("blur", handleBlurApp);
    };
  }, []);

  // useEffect(() => {
  //    if(appFocusCount>4) {
  //     setAppUnexpectedBehaviourSubmit(true);
  //    }
  // }, [appFocusCount]);

  const handleCLickContinue = () => {
    setAppFocus(true);
  };

  const handleExitFullscreen = () => {
    // Check if the document is currently in fullscreen mode
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const handleAutoSubmit = () => {
    // setAppFocus(true);
    // console.log('auto submit');
    setAppUnexpectedBehaviourSubmit(true);
    handleExitFullscreen();
  };

  const onBackButtonEvent = (e) => {
    window.history.pushState(null, null, window.location.pathname);
    e.preventDefault();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  function handleOnSubmit(val) {
    setIsSubmitted(val);
  }

  return (
    <div style={{ userSelect: "none" }}>
      <CodingContainer
        quizProp={value}
        routeParams={routeParams}
        qIndex={+routeParams.qIndex}
        onUnexpectedBehaviour={appUnexpectedBehaviourSubmit}
        onAppFocusOut={onAppFocus}
        isSubmitted={handleOnSubmit}
      ></CodingContainer>

      {!onAppFocus && !isSubmitted ? (
        <OnAppBlurDialog
          open={!onAppFocus}
          isTimerOn={true}
          text={
            appFocusCount > proctoringDetails?.tabSwitchingLimit - 1
              ? "You are doing unexpected behaviour. This is last warning. Your quiz will submit automatically."
              : ""
          }
          action=""
          onCLickContinue={handleCLickContinue}
          onAutoSubmit={handleAutoSubmit}
          appFocusCount={appFocusCount}
        ></OnAppBlurDialog>
      ) : (
        <></>
      )}
    </div>
  );
}
export default CodingQuestions;
