import React from "react";
import { makeStyles } from "@mui/styles";
import { theme } from "../styles/theme";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "#ECF0F1",
    [theme.breakpoints.up("tablet")]: {},
  },
});

function RoundedLoadingIndicator(props) {
  const classes = useStyles();

  return (
    <div className={`${classes.root}`}>
      <div className="roundedLoad">
        <hr />
        <hr />
        <hr />
        <hr />
      </div>
    </div>
  );
}
export default RoundedLoadingIndicator;
