import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Typography, Link, Card, useMediaQuery, Snackbar, Modal, Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useNavigate } from "react-router-dom";
import { theme } from "../../styles/theme";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { isMaintenanceHappening } from "../../services/globalService";
import auth from "../../redux/actions/auth";
import { UAParser } from "ua-parser-js";
import ReusableDialog from "../controls/ReusableDialog";
import randomString from "random-string";
import quiz from "../../redux/actions/quiz";

const useStyles = makeStyles({
  scoreCard: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    height: "100%",
  },
  scoreCard1: {
    backgroundColor: `#F8F8F8 !important`,
    height: "100%",
  },
  sectionDisable: {
    backgroundColor: `${theme.palette.black.gray} !important`,
    height: "100%",
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontWeight: "500 !important",
    fontSize: "14px !important",
  },
  disablePrimaryText: {
    color: theme.palette.black.light,
    fontWeight: "500 !important",
    fontSize: "14px !important",
  },
  link: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    // fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
    textDecoration: "none !important",
    "& .css-1ps4owl-MuiTypography-root-MuiLink-root": {
      textDecoration: "none !important",
      color: "#1789FC !important",
    },
  },
  disabledLink: {
    color: "#787878 !important",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
    textDecoration: "none !important",
    // cursor: 'none',
  },
  disabledCardLink: {
    cursor: "default",
    pointerEvents: "none",
    //color: "#787878 !important",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
  },
  scoresCalculatedByCardTitle: {
    marginBottom: "12px !important",
  },
  sectionTitle: {
    //marginBottom: 6,
    padding: "4px",
    // fontSize: "16px",
    // lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "24px",
      // lineHeight: "32px"
      marginBottom: 2,
      padding: "2px",
    },
  },
  grayText: {
    color: "#787878",
  },
  grayText1: {
    color: "#C4C4C4",
  },
  subTitle: {
    marginBottom: "6px",
    padding: "4px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3px",
      padding: "3px",
    },
  },
  incompleteTxt: {
    fontWeight: "normal",
  },
  linkCursor: {
    cursor: "pointer",
  },
  sectionChildContainer: {
    display: "flex",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
  },
  roundedCircle: {
    height: "12px",
    width: "12px",
    margin: "2px 3px 2px 14px",
    border: "1px solid #C4C4C4",
    borderRadius: "50%",
  },
  roundedText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#787878",
  },
  sectionMainContainer: {
    height: "20px",
    display: "flex",
    margin: "0px 0px 14px -10px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 14px -10px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "0px",
  },
  textSty: theme.typography.b2Regular,
  linksty: theme.typography.link2,
  mediumsty: theme.typography.b2Medium,
  h4sty: theme.typography.h4,
  dotFlashing: {
    "&::after": {
      display: "inline-block",
      marginLeft: "4px",
      content: '""',
      animation: `$dots 1.5s infinite`,
    },
  },
  "@keyframes dots": {
    "0%": { content: '""' },
    "25%": { content: '"."' },
    "50%": { content: '".."' },
    "75%": { content: '"..."' },
    "100%": { content: '"."' }, // Back to one dot, restart animation
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  outline: "none",
  width: "300px",
};

function ScoreCalculationDimension(props) {
  // const dispatch = useDispatch();
  const classes = useStyles();
  const history = useNavigate();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  // const auth = useSelector((state) => state.auth); // FK cleanup july 2023
  const roleState = useSelector((state) => state.role);
  // const quizState = useSelector((state) => state.quiz);
  // const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  const [maintenanceTime, setMaintenanceTime] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const [browserName, setBrowserName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [updateLink, setLink] = useState("");
  const [dialogOpenQuiz, setDialogOpenQuiz] = useState(false);
  // const proctoringDetails = useSelector((state) => state?.role?.candidateDashboardDetails?.proctoring);
  // const [browserDetails, setBrowserDetails] = useState(null);
  // const [openModal, setOpenModal] = useState(false);

  // console.log("is not mobile", isNotMobile);
  // console.log("current browser height", window.innerHeight);
  // console.log("current browser width", window.innerWidth);
  // console.log("platform", navigator?.userAgentData?.platform);

  useEffect(() => {
    // if (proctoringDetails?.ipAddress) {
    //   dispatch(auth.getIpAddress());
    // }
    let parser = new UAParser();
    let result = parser.getResult();
    setBrowserName(result?.browser?.name);
    // setBrowserDetails({
    //   browserName: result?.browser?.name,
    //   browserVersion: result?.browser?.version,
    //   platform: result?.os?.name,
    //   deviceName: result?.device?.type,
    // });
  }, []);

  useEffect(() => {
    let maintenanceData = authState?.getMaintenanceDetailsSuccess?.data;

    if (maintenanceData) {
      sessionStorage.setItem("maintenanceData", JSON.stringify(maintenanceData));
    } else if (sessionStorage.getItem("maintenanceData")) {
      maintenanceData = JSON.parse(sessionStorage.getItem("maintenanceData"));
    }

    if (maintenanceData) {
      const isMaintenance = isMaintenanceHappening(maintenanceData);
      setMaintenanceTime(isMaintenance);
    }
  }, [authState?.getMaintenanceDetailsSuccess]);

  const onStartCodingChallenge = function () {
    if (maintenanceTime) {
      sessionStorage.removeItem("maintenanceData");
      setToastMessage("We are currently undergoing maintenance. Please try again later.");
      setOpenToast(true);
      dispatch(auth.getMaintenanceDetails());
    } else {
      if (browserName === "Chrome") {
        handleContinue();
      } else {
        setDialogOpen(true);
      }
      // if (navigator.onLine) {
      //   if (isNotMobile || navigator?.userAgentData?.platform !== "Android") {
      //     history("/L3CodingOpeningScreen");
      //   } else {
      //     history("/CodingSectionUnavailable");
      //   }
      // } else {
      //   setOpenSnackBar(true);
      // }
    }
  };

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleContinue = () => {
    setDialogOpen(false);
    if (navigator.onLine) {
      if (isNotMobile || navigator?.userAgentData?.platform !== "Android") {
        history("/L3CodingOpeningScreen");
      } else {
        history("/CodingSectionUnavailable");
      }
    } else {
      setOpenSnackBar(true);
    }
  };

  const handleCloseDialogQuiz = () => {
    setDialogOpenQuiz(false);
  };

  const handleContinueQuiz = (link) => {
    setDialogOpenQuiz(false);
    if (navigator.onLine) {
      history(link);
    } else {
      setOpenSnackBar(true);
    }
  };

  const handleContinueQuizModal = () => {
    setDialogOpenQuiz(false);
    if (navigator.onLine) {
      history(updateLink);
    } else {
      setOpenSnackBar(true);
    }
  };

  const handleLink = (link) => {
    if (maintenanceTime) {
      sessionStorage.removeItem("maintenanceData");
      setToastMessage("We are currently undergoing maintenance. Please try again later.");
      setOpenToast(true);
      dispatch(auth.getMaintenanceDetails());
    } else {
      setLink(link);
      if (browserName === "Chrome") {
        handleContinueQuiz(link);
      } else {
        setDialogOpenQuiz(true);
      }
      // if (navigator.onLine) {
      //   history(link);
      // } else {
      //   setOpenSnackBar(true);
      // }
    }
  };

  // const handleBehaviorVideo = () => {
  //   if (maintenanceTime) {
  //     sessionStorage.removeItem("maintenanceData");
  //     setToastMessage("We are currently undergoing maintenance. Please try again later.");
  //     setOpenToast(true);
  //     dispatch(auth.getMaintenanceDetails());
  //   } else {
  //     setOpenModal(true);
  //     let code = randomString();
  //     let body = {
  //       level: "3",
  //       code,
  //       uid: roleState?.candidateDashboardDetails?.candidate?.user?.email || "notregistered",
  //       email: roleState?.candidateDashboardDetails
  //         ? roleState?.candidateDashboardDetails?.candidate?.user?.email
  //         : "notregistered",
  //       levelInfo: "presentation",
  //       inviteId: roleState?.candidateDashboardDetails?.uid,

  //       deviceMetadata:
  //         proctoringDetails?.deviceTracking === false
  //           ? {
  //               ipMetaData: "",
  //               ipAddress: "",
  //               deviceName: "",
  //               browser: "",
  //               browserVersion: "",
  //               platform: "",
  //               userAgent: "",
  //               isMobile: "",
  //               isScreenExtended: "",
  //               fullScreenMode: "",
  //               geoLocation: {
  //                 latitude: "",
  //                 longitude: "",
  //               },
  //               geoLocationPermission: "",
  //               cameraPermission: "",
  //               micPermission: "",
  //               viewHeight: "",
  //               viewWidth: "",
  //               browserScreenWidth: "",
  //               browserScreenHeight: "",
  //             }
  //           : {
  //               ipMetaData: authState?.getIpAddressSuccess,
  //               ipAddress: authState?.getIpAddressSuccess?.ipAddress || "",
  //               deviceName: browserDetails?.deviceName,
  //               browser: browserDetails?.browserName,
  //               browserVersion: browserDetails?.browserVersion,
  //               platform: browserDetails?.platform,
  //               userAgent: navigator?.userAgent,
  //               isMobile: navigator?.userAgentData?.mobile,
  //               isScreenExtended: window.screen.isExtended,
  //               fullScreenMode: !isExceedHeight && !isExceedWidth,
  //               geoLocation: {
  //                 latitude: "",
  //                 longitude: "",
  //               },
  //               geoLocationPermission: "",
  //               cameraPermission: "",
  //               micPermission: "",
  //               viewHeight: window.screen.availHeight,
  //               viewWidth: window.screen.availWidth,
  //               browserScreenWidth: window.innerWidth,
  //               browserScreenHeight: window.innerHeight,
  //             },
  //     };
  //     dispatch(quiz.createSessionRequested(JSON.stringify(body)));
  //   }
  // };

  // useEffect(() => {
  //   if (quizState?.behavioralLink) {
  //     const currentPath = window.location.href;
  //     const baseUrl = quizState?.behavioralLink;
  //     const urlWithParams = `${baseUrl}?rp=${currentPath}&gl=${proctoringDetails?.geoLocation}`;

  //     window.open(urlWithParams, "_self");
  //     // console.log(urlWithParams, 12345);
  //     setOpenModal(false);
  //   }
  // }, [quizState?.sessionByLevel]);

  const displayToastMSg = (msg) => {
    setToastMessage(msg);
    setOpenToast(true);
  };

  function handleCloseSnackBar() {
    setOpenToast(false);
  }

  const combinevalues = roleState?.candidateDashboardDetails?.selectedTestingRequirement?.reduce(
    (accumulator, select, index) => {
      return accumulator.concat({
        testingRequired: select,
      });
    },
    []
  );

  // const handleCloseMatchModal = () => {
  //   setOpenModal(false);
  // };

  // // function for height
  // function isInnerHeightLessThan90Percent() {
  //   // Get the available height and inner height of the browser
  //   const availableHeight = window.screen.availHeight;
  //   const innerHeight = window.innerHeight;

  //   // Calculate 90% of the available height
  //   const nintyPercentOfHeight = 0.9 * availableHeight;

  //   return innerHeight < nintyPercentOfHeight;
  // }

  // // functtion for width
  // function isInnerWidthLessThan90Percent() {
  //   // Get the available height and inner height of the browser
  //   const availableWidth = window.screen.availWidth;
  //   const innerWidth = window.innerWidth;

  //   // Calculate 95% of the available Width
  //   const nintyPercentOfWidth = 0.95 * availableWidth;

  //   return innerWidth < nintyPercentOfWidth;
  // }

  // const isExceedHeight = isInnerHeightLessThan90Percent();
  // const isExceedWidth = isInnerWidthLessThan90Percent();

  return (
    <div className={classes.scoresCalculatedBy}>
      <Typography variant2="h4" className={`${classes.sectionTitle} ${classes.h4sty}`} align="left">
        You’ve been invited to complete a screening assessment
      </Typography>

      <Typography variant2="b2Regular" className={`${classes.grayText} ${classes.subTitle} ${classes.textSty}`}>
        For accurate results, complete all required sections before the deadline. Technical and Coding sections can be
        attempted only once.
      </Typography>

      <ImageList rowHeight={"auto"} cols={isNotMobile ? 2 : 1} gap={16}>
        {/* Profile Section */}
        <ImageListItem>
          <Card
            className={
              props.disabled === true
                ? `${classes.sectionDisable}`
                : combinevalues?.[0]?.testingRequired === "Profile" ||
                  combinevalues?.[1]?.testingRequired === "Profile" ||
                  combinevalues?.[2]?.testingRequired === "Profile" ||
                  combinevalues?.[3]?.testingRequired === "Profile"
                ? classes.scoreCard
                : classes.scoreCard1
            }
            elevation={0}
          >
            <CardContent>
              <Typography
                // component={Text}
                variant2="b2Medium"
                className={`${classes.scoresCalculatedByCardTitle} ${classes.mediumsty}`}
                align="left"
              >
                Profile Section:{" "}
                {roleState?.candidateDashboardDetails && roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate && (
                  <span
                    className={
                      props.disabled === true ||
                      (combinevalues?.[0]?.testingRequired !== "Profile" &&
                        combinevalues?.[1]?.testingRequired !== "Profile" &&
                        combinevalues?.[2]?.testingRequired !== "Profile" &&
                        combinevalues?.[3]?.testingRequired !== "Profile")
                        ? `${classes.disablePrimaryText}`
                        : `${classes.primaryText}`
                    }
                  >
                    Last Updated{" "}
                    {moment(roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate).format("MMM DD, YYYY")}
                  </span>
                )}
                {!roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Profile" ||
                    combinevalues?.[1]?.testingRequired === "Profile" ||
                    combinevalues?.[2]?.testingRequired === "Profile" ||
                    combinevalues?.[3]?.testingRequired === "Profile") && (
                    <>
                      <span className={classes.roletype}>Required</span>
                    </>
                  )}
                {!roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate &&
                  combinevalues?.[0]?.testingRequired !== "Profile" &&
                  combinevalues?.[1]?.testingRequired !== "Profile" &&
                  combinevalues?.[2]?.testingRequired !== "Profile" &&
                  combinevalues?.[3]?.testingRequired !== "Profile" && (
                    <span className={classes.roletype}>Not Required</span>
                  )}
              </Typography>
              <Typography
                variant2="b2Regular"
                className={`${
                  combinevalues?.[0]?.testingRequired === "Profile" ||
                  combinevalues?.[1]?.testingRequired === "Profile" ||
                  combinevalues?.[2]?.testingRequired === "Profile" ||
                  combinevalues?.[3]?.testingRequired === "Profile"
                    ? classes.grayText
                    : classes.grayText1
                } ${classes.textSty}`}
                align="left"
              >
                This section evaluates the overall digital profile of a candidate, including the resume, professional
                network, endorsements, recommendations, online contributions & achievements.{" "}
                {roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Profile" ||
                    combinevalues?.[1]?.testingRequired === "Profile" ||
                    combinevalues?.[2]?.testingRequired === "Profile" ||
                    combinevalues?.[3]?.testingRequired === "Profile") &&
                  (roleState?.candidateDashboardDetails.sectionsStatus.profile !== "submitted" &&
                  roleState?.candidateDashboardDetails.sectionsStatus.profile !== "started" ? (
                    <>
                      <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                        {props.disabled === true || userDetails.role === "Recruiter" ? (
                          <Link></Link>
                        ) : (
                          <Link
                            className={`${classes.link} ${classes.linkCursor}`}
                            onClick={() => handleLink("/L3ProfileOpeningScreen")}
                            data-rt-div-updateprofile="updateprofile"
                          >
                            Update Profile
                          </Link>
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span variant2="link2">
                        <Link
                          className={classes.disabledLink}
                          onClick={() =>
                            displayToastMSg("You will be able to update the Profile section after some time.")
                          }
                        >
                          Update Profile
                        </Link>
                      </span>
                    </>
                  ))}
                {!roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Profile" ||
                    combinevalues?.[1]?.testingRequired === "Profile" ||
                    combinevalues?.[2]?.testingRequired === "Profile" ||
                    combinevalues?.[3]?.testingRequired === "Profile") &&
                  (roleState?.candidateDashboardDetails.sectionsStatus.profile !== "submitted" &&
                  roleState?.candidateDashboardDetails.sectionsStatus.profile !== "started" ? (
                    <>
                      <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                        {props.disabled === true || userDetails.role === "Recruiter" ? (
                          <Link></Link>
                        ) : (
                          <Link
                            className={`${classes.link} ${classes.linkCursor}`}
                            onClick={() => handleLink("/L3ProfileOpeningScreen")}
                            data-rt-div-updateprofile="updateprofile"
                          >
                            Update Profile
                          </Link>
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span variant2="link2">
                        <Link
                          className={classes.disabledLink}
                          onClick={() =>
                            displayToastMSg("You will be able to update the Profile section after some time.")
                          }
                        >
                          Update Profile
                        </Link>
                      </span>
                    </>
                  ))}
              </Typography>
            </CardContent>
          </Card>
        </ImageListItem>

        {/* Technical Section */}
        <ImageListItem>
          <Card
            className={
              props.disabled === true
                ? `${classes.sectionDisable}`
                : combinevalues?.[0]?.testingRequired === "Technical" ||
                  combinevalues?.[1]?.testingRequired === "Technical" ||
                  combinevalues?.[2]?.testingRequired === "Technical" ||
                  combinevalues?.[3]?.testingRequired === "Technical"
                ? classes.scoreCard
                : classes.scoreCard1
            }
            elevation={0}
          >
            <CardContent>
              <Typography
                variant2="b2Medium"
                className={`${classes.scoresCalculatedByCardTitle} ${classes.mediumsty}`}
                align="left"
              >
                Technical Section:{" "}
                {roleState?.candidateDashboardDetails &&
                  roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate && (
                    <span
                      className={props.disabled === true ? `${classes.disablePrimaryText}` : `${classes.primaryText}`}
                    >
                      Last Updated{" "}
                      {moment(roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate).format("MMM DD, YYYY")}
                    </span>
                  )}
                {!roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Technical" ||
                    combinevalues?.[1]?.testingRequired === "Technical" ||
                    combinevalues?.[2]?.testingRequired === "Technical" ||
                    combinevalues?.[3]?.testingRequired === "Technical") && (
                    <>
                      <span className={classes.roletype}>Required</span>
                    </>
                  )}
                {combinevalues?.[0]?.testingRequired !== "Technical" &&
                  combinevalues?.[1]?.testingRequired !== "Technical" &&
                  combinevalues?.[2]?.testingRequired !== "Technical" &&
                  combinevalues?.[3]?.testingRequired !== "Technical" && (
                    <span className={classes.roletype}>Not Required</span>
                  )}
              </Typography>
              <Typography
                variant2="b2Regular"
                className={`${
                  combinevalues?.[0]?.testingRequired === "Technical" ||
                  combinevalues?.[1]?.testingRequired === "Technical" ||
                  combinevalues?.[2]?.testingRequired === "Technical" ||
                  combinevalues?.[3]?.testingRequired === "Technical"
                    ? classes.grayText
                    : classes.grayText1
                } ${classes.textSty}`}
                align="left"
                style={theme.typography.b2Regular}
              >
                A series of multiple-choice questions test the technical knowledge of the candidate. These questions are
                customized based on the job requirements. Only one attempt is permitted.{" "}
                {roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate && (
                  <>
                    <span variant2="link2" className={`${classes.link} ${classes.linksty}`}></span>
                  </>
                )}
                {!roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Technical" ||
                    combinevalues?.[1]?.testingRequired === "Technical" ||
                    combinevalues?.[2]?.testingRequired === "Technical" ||
                    combinevalues?.[3]?.testingRequired === "Technical") && (
                    <>
                      <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                        {props.disabled === true || userDetails.role === "Recruiter" ? (
                          <Link></Link>
                        ) : (
                          <Link
                            className={`${classes.link} ${classes.linkCursor}`}
                            onClick={() => handleLink("/L2OpeningScreen")}
                            data-rt-div-takequiztechnical="takequiztechnical"
                          >
                            Start Quiz Now
                          </Link>
                        )}
                      </span>
                    </>
                  )}
              </Typography>
            </CardContent>
          </Card>
        </ImageListItem>

        {/* Coding Section */}
        <ImageListItem>
          <Card
            className={
              props.disabled === true
                ? `${classes.sectionDisable}`
                : combinevalues?.[0]?.testingRequired === "Coding" ||
                  combinevalues?.[1]?.testingRequired === "Coding" ||
                  combinevalues?.[2]?.testingRequired === "Coding" ||
                  combinevalues?.[3]?.testingRequired === "Coding"
                ? classes.scoreCard
                : classes.scoreCard1
            }
            elevation={0}
          >
            <CardContent>
              <Typography
                variant2="b2Medium"
                className={`${classes.scoresCalculatedByCardTitle} ${classes.mediumsty}`}
                align="left"
                style={theme.typography.b2Medium}
              >
                Coding Section:{" "}
                {roleState?.candidateDashboardDetails && roleState?.candidateDashboardDetails?.L3CodingUpdatedDate && (
                  <span
                    className={props.disabled === true ? `${classes.disablePrimaryText}` : `${classes.primaryText}`}
                  >
                    Last Updated{" "}
                    {moment(roleState?.candidateDashboardDetails?.L3CodingUpdatedDate).format("MMM DD, YYYY")}
                  </span>
                )}
                {!roleState?.candidateDashboardDetails?.L3CodingUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Coding" ||
                    combinevalues?.[1]?.testingRequired === "Coding" ||
                    combinevalues?.[2]?.testingRequired === "Coding" ||
                    combinevalues?.[3]?.testingRequired === "Coding") && (
                    <>
                      <span className={classes.roletype} data-rt-div-completecode="completecode">
                        Required
                      </span>
                    </>
                  )}
                {combinevalues?.[0]?.testingRequired !== "Coding" &&
                  combinevalues?.[1]?.testingRequired !== "Coding" &&
                  combinevalues?.[2]?.testingRequired !== "Coding" &&
                  combinevalues?.[3]?.testingRequired !== "Coding" && (
                    <span className={classes.roletype}>Not Required</span>
                  )}
              </Typography>
              <Typography
                variant2="b2Regular"
                className={`${
                  combinevalues?.[0]?.testingRequired === "Coding" ||
                  combinevalues?.[1]?.testingRequired === "Coding" ||
                  combinevalues?.[2]?.testingRequired === "Coding" ||
                  combinevalues?.[3]?.testingRequired === "Coding"
                    ? classes.grayText
                    : classes.grayText1
                } ${classes.textSty}`}
                align="left"
                style={theme.typography.b2Regular}
              >
                This section evaluates skills in algorithms and programming through a series of coding problems of
                progressive difficulty. A coding IDE is provided, and only one attempt is permitted.{" "}
                {roleState?.candidateDashboardDetails?.L3CodingUpdatedDate && (
                  <>
                    <span variant2="link2" className={classes.link} style={theme.typography.link2}></span>
                  </>
                )}
                {!roleState?.candidateDashboardDetails?.L3CodingUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Coding" ||
                    combinevalues?.[1]?.testingRequired === "Coding" ||
                    combinevalues?.[2]?.testingRequired === "Coding" ||
                    combinevalues?.[3]?.testingRequired === "Coding") && (
                    <>
                      <span variant2="link2" className={classes.link} style={theme.typography.link2}>
                        {props.disabled === true || userDetails.role === "Recruiter" ? (
                          <Link></Link>
                        ) : (
                          <Link
                            className={`${classes.link} ${classes.linkCursor}`}
                            onClick={onStartCodingChallenge}
                            data-rt-div-startcode="startcode"
                          >
                            Start Coding Challenge
                          </Link>
                        )}
                      </span>
                    </>
                  )}
              </Typography>
            </CardContent>
          </Card>
        </ImageListItem>

        {/* Presentation Section */}
        <ImageListItem>
          <Card
            className={
              props.disabled === true
                ? `${classes.sectionDisable}`
                : combinevalues?.[0]?.testingRequired === "Presentation" ||
                  combinevalues?.[1]?.testingRequired === "Presentation" ||
                  combinevalues?.[2]?.testingRequired === "Presentation" ||
                  combinevalues?.[3]?.testingRequired === "Presentation"
                ? classes.scoreCard
                : classes.scoreCard1
            }
            elevation={0}
          >
            <CardContent>
              <Typography
                variant2="b2Medium"
                className={`${classes.scoresCalculatedByCardTitle} ${classes.mediumsty}`}
                align="left"
                style={theme.typography.b2Medium}
              >
                Behavioral Section:{" "}
                {roleState?.candidateDashboardDetails &&
                  roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate && (
                    <span
                      className={
                        props.disabled === true ||
                        (combinevalues?.[0]?.testingRequired !== "Presentation" &&
                          combinevalues?.[1]?.testingRequired !== "Presentation" &&
                          combinevalues?.[2]?.testingRequired !== "Presentation" &&
                          combinevalues?.[3]?.testingRequired !== "Presentation")
                          ? `${classes.disablePrimaryText}`
                          : `${classes.primaryText}`
                      }
                    >
                      Last Updated{" "}
                      {moment(roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate).format("MMM DD, YYYY")}
                    </span>
                  )}
                {!roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Presentation" ||
                    combinevalues?.[1]?.testingRequired === "Presentation" ||
                    combinevalues?.[2]?.testingRequired === "Presentation" ||
                    combinevalues?.[3]?.testingRequired === "Presentation") && (
                    <>
                      <span className={classes.roletype}>Required</span>
                    </>
                  )}
                {!roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate &&
                  combinevalues?.[0]?.testingRequired !== "Presentation" &&
                  combinevalues?.[1]?.testingRequired !== "Presentation" &&
                  combinevalues?.[2]?.testingRequired !== "Presentation" &&
                  combinevalues?.[3]?.testingRequired !== "Presentation" && (
                    <span className={classes.roletype}>Not Required</span>
                  )}
              </Typography>
              <Typography
                variant2="b2Regular"
                className={`${
                  combinevalues?.[0]?.testingRequired === "Presentation" ||
                  combinevalues?.[1]?.testingRequired === "Presentation" ||
                  combinevalues?.[2]?.testingRequired === "Presentation" ||
                  combinevalues?.[3]?.testingRequired === "Presentation"
                    ? classes.grayText
                    : classes.grayText1
                } ${classes.textSty}`}
                align="left"
                style={theme.typography.b2Regular}
              >
                With a series of scenario-based questions, the candidate is evaluated in multiple areas like
                presentation skills, role awareness, work culture, work ethics, and job readiness.{" "}
                {/* 
                ************ DISABLED RETAKE PRESENTATION ************
                {roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Presentation" ||
                    combinevalues?.[1]?.testingRequired === "Presentation" ||
                    combinevalues?.[2]?.testingRequired === "Presentation" ||
                    combinevalues?.[3]?.testingRequired === "Presentation") &&
                  (roleState?.candidateDashboardDetails.sectionsStatus.presentation !== "submitted" &&
                  roleState?.candidateDashboardDetails.sectionsStatus.presentation !== "started" ? (
                    <>
                      <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                        {props.disabled === true || userDetails.role === "Recruiter" ? (
                          <Link></Link>
                        ) : (
                          <Link
                            className={`${classes.link} ${classes.linkCursor}`}
                            // onClick={() => handleLink("/L3PresentationOpeningScreen")}
                            onClick={
                              roleState?.candidateDashboardDetails.behaviorVideo
                                ? () => handleBehaviorVideo()
                                : () => handleLink("/L3PresentationOpeningScreen")
                            }
                            data-rt-div-retakepresentation="retakepresentation"
                          >
                            Start Quiz Now
                          </Link>
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span variant2="link2">
                        <Link
                          className={classes.disabledLink}
                          onClick={() =>
                            displayToastMSg("You will be able to attempt the Behavioral section after some time.")
                          }
                        >
                          Start Quiz Now
                        </Link>
                      </span>
                    </>
                  ))} */}
                {!roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate &&
                  (combinevalues?.[0]?.testingRequired === "Presentation" ||
                    combinevalues?.[1]?.testingRequired === "Presentation" ||
                    combinevalues?.[2]?.testingRequired === "Presentation" ||
                    combinevalues?.[3]?.testingRequired === "Presentation") &&
                  (roleState?.candidateDashboardDetails.sectionsStatus.presentation !== "submitted" &&
                  roleState?.candidateDashboardDetails.sectionsStatus.presentation !== "started" ? (
                    <>
                      <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                        {props.disabled === true || userDetails.role === "Recruiter" ? (
                          <Link></Link>
                        ) : (
                          <Link
                            className={
                              props.disabled === true
                                ? `${classes.disabledCardLink}`
                                : `${classes.link} ${classes.linkCursor}`
                            }
                            onClick={
                              roleState?.candidateDashboardDetails.behaviorVideo
                                ? () => handleLink("/L3VideoBehavioralOpeningScreen")
                                : () => handleLink("/L3PresentationOpeningScreen")
                            }
                            data-rt-div-takepresentation="takepresentation"
                          >
                            Start Quiz Now
                          </Link>
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span variant2="link2">
                        {/* <Link
                          className={classes.disabledLink}
                          onClick={() =>
                            displayToastMSg("You will be able to attempt the Behavioral section after some time.")
                          }
                        >
                          Start Quiz Now
                        </Link> */}
                      </span>
                    </>
                  ))}
              </Typography>
            </CardContent>
          </Card>
        </ImageListItem>
      </ImageList>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openToast}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        message={toastMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <ReusableDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title="Alert"
        content="While you may continue the test in this browser, we recommend using the latest version of Chrome for optimal test experience."
        actions={[
          { onClick: handleCloseDialog, text: "Cancel", color: "btnCls1", variant: "outlined" },
          { onClick: handleContinue, text: "Continue anyway", color: "btn1" },
        ]}
      />
      <ReusableDialog
        open={dialogOpenQuiz}
        onClose={handleCloseDialogQuiz}
        title="Alert"
        content="While you may continue the test in this browser, we recommend using the latest version of Chrome for optimal test experience."
        actions={[
          { onClick: handleCloseDialogQuiz, text: "Cancel", color: "btnCls1", variant: "outlined" },
          { onClick: handleContinueQuizModal, text: "Continue anyway", color: "btn1" },
        ]}
      />
      {/* <Modal open={openModal} onClose={handleCloseMatchModal}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">Redirecting please wait</Typography>
            <span className={classes.dotFlashing}></span>
          </Box>
        </Box>
      </Modal> */}
    </div>
  );
}

export default ScoreCalculationDimension;
