import React, { useEffect } from "react";

const Plot = ({ data, layout, style, config, displaylogo, responsive, modeBarButtonsToRemove, containerId }) => {
  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = 'https://cdn.plot.ly/plotly-latest.min.js';
    // // script.src = "https://cdn.plot.ly/plotly-2.27.0.min.js";
    // // script.charset="utf-8";
    // script.async = true;

    // script.onload = () => {
    // Plotly.js has loaded, create your plot
    window.Plotly.newPlot(containerId, data, layout, config);
    // };

    // document.body.appendChild(script);

    // return () => {
    //   // Cleanup: remove the script when the component unmounts
    //   document.body.removeChild(script);
    // };
  }, [data, layout, style, config, displaylogo, responsive, modeBarButtonsToRemove, containerId]);

  return (
    <div
      id={containerId}
      style={style}
      displaylogo={displaylogo}
      responsive={responsive}
      modeBarButtonsToRemove={modeBarButtonsToRemove}
      config={config}
    />
  );
};

export default Plot;
