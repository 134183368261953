import React, { useEffect, useMemo, useState } from "react";
import { useParams, generatePath, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, Box, IconButton, Modal, Link } from "@mui/material";
import { Button as BaseButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
// import { useFormik } from "formik";
import { theme } from "../../styles/theme";
import NavBar from "../../components/NavBar";
import Button from "../../components/controls/Button";
import Footer from "../../components/Footer";
import DeactivateModel from "../../components/controls/DeactiviteModel";
import ReactiveModel from "../../components/controls/ReactiveModel";
import SimpleSnackbar from "../../components/controls/ToastMessage";
import GridTable from "../../components/Table";
import UploadProfile from "../../assets/svg/profile.svg";
import auth from "../../redux/actions/auth";
import role from "../../redux/actions/role";
import { ImagebaseUrl, TimeZonePT, setOptValue } from "../../services/globalService";
import axios from "../../services/axios";
import ColoredLinearProgress from "../../components/controls/coloredLoadingProgress";
import { sortJson } from "../../services/globalService";
// import fullSkills from "../../config";
import TreemapChart from "./chart/TreemapChart";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import ViewListIcon from "@mui/icons-material/ViewList";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ExplainationModal from "./ExplainationModal";
import Tooltip from "@mui/material/Tooltip";
import { Button as MuiButton } from "@mui/material";
import EducationCards from "../../components/WorkExperienceandEducation/EducationCards";
import ExperienceCards from "../../components/WorkExperienceandEducation/ExperienceCards";
import SearchBar from "../../components/controls/search";
import CheckSelectMenu from "../../components/controls/Checkmenu";
import CloseIcon from "@mui/icons-material/Close";
import CustomPagination from "../../components/controls/CustomPagination";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { applySearch, transformOptions } from "../../services/globalService";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SelectWithCheckbox from "../../components/controls/SelectWithCheckbox";
// import AlertDialog from "../../components/AlertDialog";
import ReusableDialog from "../../components/controls/ReusableDialog";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";
import ErrorSnackbar from "../../components/controls/Errormessage";

const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "30px",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    paddingTop: "10px",
  },

  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "16px",
    //paddingRight: '395px'
    paddingLeft: "10px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
  },
  spaceflexTable: {
    display: "flex",
    justifyContent: "space-between",
  },
  field: {
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  fileInput: {
    opacity: 0.0,
    position: "absolute",
    top: "24%",
    left: "14%",
    bottom: "69%",
    right: "67%",

    "&:hover": {
      cursor: "pointer",
    },
  },
  textareasty: {
    width: "533px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
  loader: {
    position: "fixed",
    top: "60px",
    width: "100%",
    zIndex: 1400,
    [theme.breakpoints.up("tablet")]: {
      top: "80px",
      zIndex: 1,
    },
  },
  profileImg: {
    marginBottom: 16,
    width: 100,
    height: 100,
    borderRadius: 50,
    cursor: "pointer",
  },
  textbreak: {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  CandidateProfileBtns: {
    marginTop: "10px",
  },

  profileDPInputBtn: {
    padding: "0 !important",
    textTransform: "unset !important",
    color: "#1789FC !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
  },

  dotFlashing: {
    "&::after": {
      display: "inline-block",
      marginLeft: "4px",
      content: '""',
      animation: `$dots 1.5s infinite`,
    },
  },
  muiBtn: {
    textTransform: "none !important",
    color: "gray !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    borderRadius: "4px !important",
    // '&:hover': {
    //   backgroundColor: '#1789FC !important',
    // },
  },
  muiBtnActive: {
    textTransform: "none !important",
    color: "primary !important",
    fontSize: "20px !important",
    fontWeight: "500 !important",
    borderRadius: "4px !important",
  },
  boldText: theme.typography.b1Medium,

  // Keyframes for the dots animation
  "@keyframes dots": {
    "0%": { content: '""' },
    "25%": { content: '"."' },
    "50%": { content: '".."' },
    "75%": { content: '"..."' },
    "100%": { content: '"."' }, // Back to one dot, restart animation
  },
  hintIconCls: {
    marginLeft: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const addedByMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      padding: 12,
      marginLeft: 46,
    },
  },
};
const circleChildCls = {
  height: "12px",
  width: "12px",
  borderRadius: "50%",
  border: "1px solid #C4C4C4",
  display: "inline-block",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  outline: "none",
  width: "300px",
};

const categoryMenuProps = {
  PaperProps: {
    style: {
      //width: 194,
      // height: 188,
      padding: 12,
      marginLeft: 105,
      //left:"795px !important"
    },
  },
};

const degreeMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 43,
    },
  },
};

const featureNotAccesMessage = "This feature is not available in your subscription plan.";

const CandidateView = () => {
  // const classesForGridTable = useStylesGridTable()
  const classes = useStyles();

  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const isAuthLoading = useSelector((state) => state.auth.isauthLoading);
  const [opendialog, setOpendialog] = useState(false);
  const [reactiveopen, setReactiveopen] = useState(false);
  // const [deactiveopen, setDeactiveopen] = useState(false);
  const [snacktext, setsnacktext] = useState("");
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [deactive, setDeactive] = useState(false);
  // const [fileName, setFirstName] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userid, setUserid] = useState(null);
  const [inviteview, setInviteview] = useState(false);
  const [download, setDownload] = useState({});
  const [showLinearLoading, setShowLinearLoading] = useState(false);
  const [deactivebtn, setDeactivebtn] = useState(false);
  const [activebtn, setActivebtn] = useState(false);
  const [candidateInviteList, setCandidateInviteList] = useState(roleState?.candidateinvitelist || []);
  // const [sortModel, setSortModel] = useState([]);
  // const [sortModel, setSortModel] = useState({uid:-1});
  const [uiSortModel, setUiSortModel] = useState([
    {
      field: "roleId",
      sort: "desc",
    },
  ]);
  const [sortModelSkill, setSortModelSkill] = useState([
    {
      field: "monthsUsed",
      sort: "desc",
    },
  ]);
  const [rowData, setRowData] = useState();
  // const [invitesTableRows, setInvitesTableRows] = useState([]);
  const [treeMapData, setTreeMapData] = useState([]);
  const [candidateProfileToken, setCandidateProfileToken] = useState([]);
  const [candidateWeSummaryProfileToken, setCandidateWeSummaryProfileToken] = useState("");
  const [candidatedetails, setCandidatedetails] = useState([]);
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [candidateMatchingListForJobroleData, setCandidateMatchingListForJobroleData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categorylength, setCategorylength] = useState(0);
  const [categoryBColor, setCategoryBColor] = useState("");
  const [degree, setDegree] = useState([]);
  const [degreelength, setDegreelength] = useState(0);
  const [degreeBColor, setDegreeBColor] = useState("");

  const [count, setCount] = useState(0);
  const [showExperienceAndEducation, setShowExperienceAndEducation] = useState(false);
  const [showInvitedCandidates, setShowInvitedCandidates] = useState(false);
  const [showMatchAndSearchCandidates, setShowMatchAndSearchCandidates] = useState(false);

  const [showReset, setShowReset] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [addedBy, setAddedBy] = useState([]);
  const [addedBylength, setAddedBylength] = useState(0);
  const [addedBColor, setAddedBColor] = useState("");
  const [addedByFilter, setAddedByFilter] = useState([]);
  // const [matchingRows, setMatchingRows] = useState([]);
  const [matchUiSortModel, setMatchUiSortModel] = useState([
    {
      field: "matchLevel",
      sort: "desc",
    },
  ]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sentInvites, setSentInvites] = useState([]);
  const [openMatchModal, setOpenMatchModal] = useState(false);

  // eslint-disable-next-line
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [tablePaginationPageNumber, setTablePaginationPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [isGridVisible, setIsGridVisible] = useState(false);

  // const [fieldsData, setFieldsData] = useState({});

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [openDialogText, setOpenDialogText] = useState(false);
  const [DialogText, setDialogText] = useState("");
  const [featureAccess, setFeatureAccess] = useState({});

  const handleIconClick = () => {
    setIsGridVisible(!isGridVisible);
  };

  // for show data in Grid table with compare to config file and rest are display as it is
  useEffect(() => {
    const candidateList = authState?.candidatedetails?.skills || [];
    setTreeMapData(authState?.candidatedetails?.skills || []);
    setCandidateProfileToken(authState?.candidatedetails?.profileToken);
    setCandidatedetails(authState?.candidatedetails);
    const configList = Object.entries(authState?.skillsConfig);
    const updatedSkillSet = new Set();

    const updatedSkills = candidateList.map((skill) => {
      const matchingConfig = configList.find(([configKey]) => configKey === skill.skill);

      if (matchingConfig) {
        return { ...skill, skill: matchingConfig[1].fullName };
      }

      return skill;
    });

    updatedSkills.forEach((skill) => {
      updatedSkillSet.add(skill);
    });

    setRowData(Array.from(updatedSkillSet));
    // eslint-disable-next-line
  }, [authState?.candidatedetails?.skills?.length, isGridVisible]);

  const rows = (rowData || []).map((skill, index) => ({
    id: index,
    skills: skill.skill,
    monthsUsed: skill.monthsUsed,
    lastUsed: skill.lastUsed,
  }));

  const sortedRows = rows.slice().sort((a, b) => {
    for (const sort of sortModelSkill) {
      const aValue = sort.field === "lastUsed" ? new Date(a[sort.field]) : a[sort.field];
      const bValue = sort.field === "lastUsed" ? new Date(b[sort.field]) : b[sort.field];

      if (aValue < bValue) {
        return sort.sort === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sort.sort === "asc" ? 1 : -1;
      }
    }

    return 0;
  });

  // const initialValues = {
  //   givenName: "",
  //   familyName: "",
  //   email: "",
  // };
  // const formik = useFormik({
  //   // Fk cleanup unused variable july 2023
  //   initialValues,
  //   // onSubmit,
  //   // validationSchema,
  // });

  useEffect(() => {
    // validate feature access
    dispatch(auth.validateFeatureAccess("skillTreeMap,aiSmartProfiles,advanceReporting"));
    dispatch(auth.resetCandidateDPUrl());

    return () => {
      dispatch(auth.getCandidateWeSummarySuccess({}));
    };
  }, []);

  useEffect(() => {
    setFeatureAccess(authState?.validateFeatureAccessSuccess?.data?.access);
    setIsGridVisible(!authState?.validateFeatureAccessSuccess?.data?.access?.skillTreeMap);
  }, [authState?.validateFeatureAccessSuccess]);

  useEffect(() => {
    if (roleState?.invitefilter !== undefined) {
      dispatch(role.candidateinviteList({ candidateId: id }));
    }
  }, []);
  useEffect(() => {
    setCandidateInviteList(roleState?.candidateinvitelist);
  }, [roleState?.candidateinvitelist]);

  useEffect(() => {
    if (id) {
      dispatch(auth.candidateDetails(id));
    }
  }, [id]);

  // run updates once candidateDetails are populated
  useEffect(() => {
    if (authState.candidatedetails) {
      checkAndDisplayProfilePicture(authState.candidatedetails);
    }
  }, [authState?.candidatedetails]);

  // run updates once candidateDetails are populated
  useEffect(() => {
    if (authState?.candidatedetails?.photoURL !== undefined) {
      if (authState.candidateDPUrl !== null && authState.candidateDPUrl !== authState?.candidatedetails?.photoURL) {
        setProfilePic(authState.candidateDPUrl);
      } else {
        setProfilePic(null);
      }
    } else {
      setProfilePic(null);
      dispatch(auth.resetCandidateDPUrl());
    }
  }, [authState?.candidateDPUrl]);

  // check profile picture exists for candidate and map candidate profile picture on UI
  const checkAndDisplayProfilePicture = (candidateProfileData) => {
    if (candidateProfileData?.photoURL && authState.candidateDPUrl !== candidateProfileData?.photoURL) {
      // check if signed image already exists
      if (authState?.candidateDPUrl === null || profilePic === null) {
        // updates signed s3 url to display profile picture on UI
        dispatch(auth.getCandidateDPUrl(candidateProfileData.photoURL));
      }
    }
  };

  const candidateEdit = () => {
    navigate(generatePath("/editcandidate/:id", { id }));
  };

  const OpenDeleteCandidate = () => {
    setOpendialog(true);
  };
  const DeleteCandidate = (values) => {
    let deletepayload = {
      status: "inactive",
      note: values,
    };
    let payload2 = {
      id: id,
      data: deletepayload,
    };
    //  dispatch(auth.deleteCandidate(deletepayload))
    dispatch(auth.activateCandidate(payload2));
    setDeactive(true);
    setDeactivebtn(true);
  };
  const ReactiveCandidate = () => {
    let payloaddata = {
      status: "active",
      // reason:values
    };
    let payload1 = {
      id: id,
      data: payloaddata,
    };
    dispatch(auth.activateCandidate(payload1));
    setDeactive(false);
    setActivebtn(true);
  };

  useEffect(() => {
    if (deactive === true && authState?.candidateActive === 0) {
      setOpendialog(false);
      setOpen(true);
      setsnacktext("Candidate has been deactivated");
      // setDeactiveopen(false);
      setTimeout(() => {
        setDeactivebtn(false);
      }, 1000);
    } else if (deactive === false && authState?.candidateActive === 0) {
      setReactiveopen(false);
      setOpen(true);
      setTimeout(() => {
        setActivebtn(false);
      }, 1000);

      setsnacktext("Candidate has been Activated");
    } else if (authState?.candidateActive === 1) {
      setDeactivebtn(false);
      setActivebtn(false);
    }
    // eslint-disable-next-line
  }, [authState?.candidateActive]);

  const InviteCandidateView = () => {
    if (authState?.candidatedetails?.status !== "active") {
      return;
    }
    navigate("/inviteaddcandidates", {
      state: { candidateid: authState?.candidatedetails?.uid },
    });
  };
  const handleReactive = () => {
    setReactiveopen(true);
  };

  const getFileSize = (inBytes) => {
    var inKb = inBytes / 1024;
    if (inKb.toFixed(1) < 1000.0) {
      return inKb.toFixed(1) + " KB";
    }

    var inMb = inKb / 1024;
    return inMb.toFixed(1) + "MB";
  };

  function uploadProfileImage({ target: { files } }) {
    const MAX_SIZE = 500 * 1024; // 500KB in bytes

    if (files && files[0]) {
      let uploadedFile = files[0];

      if (uploadedFile.size > MAX_SIZE) {
        setOpenDialogText(true);
        setDialogText("File size exceeds 500KB. Please upload a smaller file.");
        document.getElementById("upload-profiledp-file").value = ""; // Reset the input value
        return;
      }
      let data = new FormData();
      let dpUploadedFileName = files[0].name;
      dpUploadedFileName = dpUploadedFileName.replaceAll(" ", "_");
      data.append("file", files[0], dpUploadedFileName);
      const payload = {
        data: data,
        id: id,
        email: authState?.candidatedetails?.user?.email,
      };
      if (data) {
        dispatch(auth.uploadProfile(payload));
        // setFirstName(files[0]);
        setProfilePic(null);
      }
    }
  }

  const handleSortChange = (sortInfo) => {
    setUiSortModel([sortInfo]);
    let sortedOrder = sortJson(
      [...roleState?.candidateinvitelist],
      sortInfo.sort,
      sortInfo.field,
      sortInfo.field === "createdAt" || sortInfo.field === "updatedAt" ? "date" : ""
    );
    setCandidateInviteList(sortedOrder);
  };

  const headerdata = [
    {
      field: "roleId",
      headerName: "Role Id",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <div>{params.row.roleId}</div>;
      },
    },
    {
      field: "roleName",
      headerName: "Role Name",
      sortable: true,
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{params.row.roleName}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Invited On",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div>{moment(params.row.createdAt).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "inviteCreatedBy",
      headerName: "Invited By",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // let inviteby = typeof params.row.invitedBy === "string" ? JSON.parse(params.row.invitedBy) : params.row.invitedBy
        return <div>{params.row.inviteCreatedBy}</div>;
      },
    },

    {
      field: "updatedAt",
      headerName: "Last Login",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div>{moment(params.row.updatedAt).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "targetScore",
      headerName: "Target",
      sortable: true,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      flex: 0.8,
      renderCell: (params) => {
        return <div>{params.row.targetScore}</div>;
      },
    },
    {
      field: "proportionalScore",
      headerName: "Score",
      disableColumnMenu: true,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.testsTakenCount === 0 && params.row.proportionalScore === 0) {
          params.row["totalScore"] = -1;
        }
        if (params.row.testsTakenCount === 0 || params.row.proportionalScore === undefined) {
          return <div>--</div>;
        } else if (params.row.proportionalScore < params.row.targetScore) {
          return <div style={{ color: "#FF941C" }}>{params.row.proportionalScore}</div>;
        } else if (params.row.proportionalScore >= params.row.targetScore) {
          return <div style={{ color: "#28BB3F" }}>{params.row.proportionalScore}</div>;
        }
      },
    },
    {
      field: "testsTakenCount",
      headerName: "Completed",
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            {params.row.testsTakenCount} / {params.row.selectedTestingRequirement.length}
          </div>
        );
      },
    },
  ];

  const Downloadresume = () => {
    setShowLinearLoading(true);
    if (authState?.candidatedetails?.resumeName) {
      let payloaddownload = authState?.candidatedetails?.resumeUrl;

      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      };
      axios(`/candidate/downloadResume?resumeUrl=${payloaddownload}`, config).then((res) => {
        setDownload(res);
        setShowLinearLoading(false);
      });
    }
  };
  useEffect(() => {
    if (download?.status === 0) {
      window.open(download?.data, "_self");
    }
    // eslint-disable-next-line
  }, [download?.status]);

  // handle row click of invite list of candidate
  const loadClickedInviteDetail = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setUserid(id);
      setInviteview(true);
      dispatch(role.inviteDetails(id));
      //navigate(generatePath("/invitecandidateview/:id", { id }))
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (inviteview && roleState?.invitedetails?.uid) {
      navigate(generatePath("/invitecandidateview/:id", { id: userid }));
    }
    // eslint-disable-next-line
  }, [inviteview && roleState?.invitedetails?.uid]);

  const handleSortChangeSkill = (sortInfo) => {
    let fieldType, field;
    fieldType = sortInfo.field;
    if (sortInfo.field === "skills") {
      field = "skill";
    } else {
      field = sortInfo.field;
    }

    setSortModelSkill([sortInfo]);

    let sortedOrder;
    if (field === "lastUsed") {
      sortedOrder = sortJson([...rowData], sortInfo.sort, field, "date", "lastUsed");
    } else {
      sortedOrder = sortJson([...rowData], sortInfo.sort, field, fieldType);
    }

    setRowData(sortedOrder);
  };

  useEffect(() => {
    let interval;
    if (featureAccess?.aiSmartProfiles) {
      interval = setInterval(() => {
        if (
          count < 3 &&
          (((!authState?.candidatedetails?.weSummary ||
            authState?.candidatedetails?.weSummary === "NA" ||
            authState?.candidatedetails?.weSummary === "processing") &&
            (!authState?.getCandidateWeSummarySuccess?.weSummary ||
              authState?.getCandidateWeSummarySuccess?.weSummary === "NA")) ||
            (!authState?.getCandidateWeSummarySuccess?.profileToken && !authState?.candidatedetails?.profileToken))
        ) {
          dispatch(auth.getCandidateWeSummary(id));
          setCount((prevCount) => prevCount + 1);
        } else {
          clearInterval(interval);
        }
      }, 10000);
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [id, count, authState?.getCandidateWeSummarySuccess, authState?.candidatedetails]);

  const weSummary = useMemo(() => {
    return (
      authState?.getCandidateWeSummarySuccess?.weSummary || authState?.candidatedetails?.weSummary || "Not Available"
    );
  }, [authState?.getCandidateWeSummarySuccess?.weSummary, authState?.candidatedetails?.weSummary]);

  useEffect(() => {
    if (authState?.getCandidateWeSummarySuccess) {
      setCandidateWeSummaryProfileToken(authState?.getCandidateWeSummarySuccess?.profileToken);
    }
  }, [authState?.getCandidateWeSummarySuccess]);

  const copyDownloadLink = () => {
    if (!featureAccess?.aiSmartProfiles) {
      setOpenToast(true);
      setToastMessage(featureNotAccesMessage);
      return;
    }

    const textToCopy = candidatedetails?.candidateProfile;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard:", textToCopy);
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard:", err);
        // Handle errors here
      });
    setOpen(true);
    setsnacktext("Link Copied Successfully");
  };
  function openDownloadLink() {
    if (featureAccess?.aiSmartProfiles) {
      window.open(candidatedetails?.candidateProfile, "_blank");
    } else {
      setOpenToast(true);
      setToastMessage(featureNotAccesMessage);
    }
  }

  const handlehintClick = () => {
    setShowExplanationModal(true);
    setOpenPopup(true);
  };

  const handleCandidateComparison = () => {
    setShowExperienceAndEducation(true);
    setShowInvitedCandidates(false);
    setShowMatchAndSearchCandidates(false);
  };

  const handleInvitedCandidates = () => {
    setShowExperienceAndEducation(false);
    setShowInvitedCandidates(true);
    setShowMatchAndSearchCandidates(false);
  };

  const handleMatchAndSearchCandidates = () => {
    setShowExperienceAndEducation(false);
    setShowInvitedCandidates(false);
    setShowMatchAndSearchCandidates(true);
  };

  const handleCloseMatchModal = () => {
    setOpenMatchModal(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(auth.candidateMatchingListForJobrole(id));
    }
  }, [id]);

  useEffect(() => {
    //
    if (authState?.candidateMatchingListForJobroleSuccess) {
      setCandidateMatchingListForJobroleData(authState?.candidateMatchingListForJobroleSuccess);
      setDegree(authState?.candidatefilter?.degree);
    }
  }, [authState?.candidateMatchingListForJobroleSuccess, authState?.candidatefilter?.degree]);

  useEffect(() => {
    const dataArray = authState?.candidateMatchingListForJobroleSuccess;
    const uniqueAddedBy = [...new Set(dataArray?.map((i) => i.createdByDisplayName))];
    const sortedUniqueAddedBy = uniqueAddedBy.sort((a, b) => a?.localeCompare(b));
    setAddedByFilter(sortedUniqueAddedBy);
    // setCandidateCategory(["Never Invited to This Role", "Never Invited to Any Role", "Achieved Target Score"]);
  }, [authState?.candidateMatchingListForJobroleSuccess]);

  // search functionality
  const handleSearch = (value) => {
    let searchValue = value.target.value;
    searchValue = searchValue.replace(/,/g, "");
    setSearchText(searchValue);
    setShowReset(true);
  };

  useEffect(() => {
    setCandidateMatchingListForJobroleData(authState.candidateMatchingListForJobroleSuccess);
  }, [authState.candidateMatchingListForJobroleSuccess]);

  useEffect(() => {
    if (searchText !== "") {
      setShowReset(true);
    } else {
      setShowReset(false);
    }
  }, [searchText]);

  // search filter
  const applySearchFilter = (rawData) => {
    let visibleKeys = [
      "candidateId",
      "roleId",
      "matchLevel",
      "roleName",
      "roleCategory",
      "targetScore",
      "createdBy",
      "createdByDisplayName",
      "createdAt",
    ];
    let filteredData = applySearch(rawData, searchText, visibleKeys);
    return filteredData;
  };

  const dataFilter = (rawData, filterData, key) => {
    // Make sure all filters are in lowercase
    filterData = filterData.map((i) => i.toLowerCase());
    return rawData.filter(
      // eslint-disable-next-line
      (item) => eval(`item.${key}`) && filterData.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
    );
  };

  const applyFilterOnData = () => {
    // Raw Data
    let rawData = [...(authState?.candidateMatchingListForJobroleSuccess ?? [])];

    if (rawData.length !== 0) {
      // AddedBy Filter
      rawData = dataFilter(rawData, addedBy, "createdByDisplayName");

      // Role Catagory Filter
      rawData = dataFilter(rawData, category, "roleCategory");

      // degree filter
      rawData = dataFilter(rawData, degree, "degree");

      const filters = [];

      // Apply filters
      if (filters.length > 0) {
        rawData = rawData.filter((item) => filters.every((filter) => filter(item)));
      }
    }

    return rawData;
  };

  // Set Candidate filtered List
  const setCandidateFilteredList = () => {
    let filteredData = applyFilterOnData();
    filteredData = applySearchFilter(filteredData);

    let fieldType, field;

    fieldType = matchUiSortModel[0].field === "createdAt" ? "date" : "";
    field = matchUiSortModel[0].field;

    filteredData = sortJson([...filteredData], matchUiSortModel[0]?.sort, field, fieldType);

    setCandidateMatchingListForJobroleData(filteredData);
  }; //End

  // Search Value side effect
  useEffect(() => {
    if (authState?.candidateMatchingListForJobroleSuccess?.length !== 0) {
      setCandidateFilteredList();
    }
    setTablePaginationPageNumber(0);
  }, [searchText, authState?.candidateMatchingListForJobroleSuccess, addedBy, category, degree]);

  const handleSendInviteClick = (row) => {
    dispatch(auth.checkCandidateInviteForRole({ roleId: row.roleId, candidateId: id }));
    setSelectedRow(row);
    setShowLinearLoading(true);
  };

  useEffect(() => {
    if (authState?.checkCandidateInviteForRoleSuccess) {
      setShowLinearLoading(false);

      if (authState?.candidatedetails?.status === "active") {
        setOpenConfirm(true);
      } else {
        handleAlertModal(selectedRow, "invite");
      }
    }
  }, [authState?.checkCandidateInviteForRoleSuccess, authState?.candidatedetails?.status]);

  useEffect(() => {
    if (authState?.checkCandidateInviteForRoleFailure) {
      setShowLinearLoading(false);
    }
  }, [authState?.checkCandidateInviteForRoleFailure]);

  const handleAlertModal = (value, type) => {
    setOpenAlertDialog(true);
    if (type === "invite") {
      setAlertText(<>This is an Inactive candidate. Please activate the candidate to send the invite.</>);
    } else {
      setAlertText(<>This is an inactive candidate. Please activate the candidate to generate the report.</>);
    }
  };

  const handleChangeAddedBy = (value) => {
    // console.log(value, "value123");
    setAddedBy(value);
    setShowReset(true);
    if (value.length === addedBy?.length) {
      setAddedBylength(0);
    } else {
      setAddedBylength(value.length);
    }
    setAddedBColor("#EBEFF8");
  };

  const onSelectAllAddedBy = () => {
    setAddedBy(addedByFilter);
    setAddedBylength(0);
    setAddedBColor("");
    setShowReset(false);
  };

  const onClearAllAddedBy = () => {
    setAddedBy([]);
    setAddedBylength(0);
    setAddedBColor("");
    setShowReset(true);
  };

  // reset all filters
  const onResetAllFilters = () => {
    const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
    setCategory(roleCategories);
    setAddedBy(addedByFilter);
    setSearchText("");
    setShowReset(false);
    setAddedBColor("");
    setAddedBylength(0);
    setCategorylength(0);
    setCategoryBColor("");
    setDegreeBColor("");
    setDegreelength(0);
    setDegree(authState?.candidatefilter?.degree);
  };

  // table header data for role match
  const headerDataRoleMatch = [
    {
      field: "roleId",
      headerName: "Role ID",
      flex: 0.8,
      disableColumnMenu: true,
    },
    {
      field: "roleName",
      headerName: "Role Name",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "yoe",
      headerName: "Required Experience",
      flex: 0.8,
      disableColumnMenu: true,
      valueGetter: ({ value }) => value && `${parseInt(value) >= 0 ? value + " years" : "--"}`,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      align: "center",
      flex: 1,
      disableColumnMenu: true,
      headerAlign: "center",
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{moment(params.row.createdAt).format("DD MMM 'YY")}</div>;
      },
    },

    {
      field: "createdByDisplayName",
      headerName: "Created By",
      flex: 1,
      disableColumnMenu: true,
    },

    {
      field: "targetScore",
      headerName: "Target Score",
      flex: 1,
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "matchLevel",
      headerName: "Skill Match",
      align: "center",
      headerAlign: "center",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        const matchLevel = params.value;

        // Function to create an array of 7 circle elements based on matchLevel
        const createCircles = (matchLevel) => {
          const circles = Array.from({ length: 7 }, (_, index) => {
            const circleStyle = {
              ...circleChildCls,
              backgroundColor: getCircleColor(index, matchLevel),
              marginRight: 4, // for gap
            };

            return <div key={index} style={circleStyle} />;
          });
          return circles;
        };

        // Function to determine circle color based on matchLevel
        const getCircleColor = (index, matchLevel) => {
          if (index < matchLevel) {
            if (matchLevel === 1) {
              return "#ff0000"; // Red for match level 1
            } else if (matchLevel >= 2 && matchLevel <= 4) {
              return "#ffa500"; // Orange for match levels 2, 3, 4
            } else {
              return "#6aa84f"; // Green for match levels 5, 6, 7
            }
          } else {
            return "transparent"; // Transparent for unfilled circles
          }
        };

        return <div style={{ display: "flex", justifyContent: "center" }}>{createCircles(matchLevel)}</div>;
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleSendInviteClick(params.row);
              }}
              disabled={sentInvites.includes(params.row.roleId)}
              title="Quick Send Invite"
            >
              <MailOutlineIcon
                sx={
                  authState?.candidatedetails?.status === "active"
                    ? sentInvites.includes(params.row.roleId)
                      ? { color: "grey !important", cursor: "not-allowed" }
                      : { color: "#1976d2 !important" }
                    : { color: "grey !important", cursor: "not-allowed" }
                }
                fontSize="large"
              />
            </IconButton>
            <IconButton
              size="small"
              onClick={(event) => {
                // setLoadingCursor(true);
                event.stopPropagation();
                if (authState?.candidatedetails?.status === "active") {
                  handleOpenJobProfile(params.row);
                } else {
                  handleAlertModal(params.row, "report");
                }
              }}
              // disabled={loadingCursor}
              title="Generate Profile Match Report"
            >
              <OpenInNewOutlinedIcon
                sx={{
                  color:
                    authState?.candidatedetails?.status === "active" && featureAccess.advanceReporting
                      ? "#1976d2 !important"
                      : "grey !important",
                  cursor:
                    authState?.candidatedetails?.status === "active" && featureAccess.advanceReporting
                      ? "pointer"
                      : "not-allowed",

                  // cursor: loadingCursor ? "wait" : "pointer"
                }}
                fontSize="large"
              />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleAlertDailog = () => {
    setOpenAlertDialog(false);
  };

  const handleRowClickMatch = (row) => {
    // console.log(row, "row");
    const roleId = row.roleId;
    // Navigate to the candidate view with the roleId
    window.open(`/newRoleView/${roleId}`, "_blank");
  };

  const handleSortChangeMatch = (sortInfo) => {
    setMatchUiSortModel([sortInfo]);

    let sortedOrder = [...candidateMatchingListForJobroleData];

    if (sortInfo.sort) {
      const fieldType = sortInfo.field === "createdAt" ? "date" : "";
      sortedOrder = sortJson([...candidateMatchingListForJobroleData], sortInfo.sort, sortInfo.field, fieldType);
    }

    setCandidateMatchingListForJobroleData(sortedOrder);
  };

  useEffect(() => {
    if (roleState?.getCandidateJobRoleProfileTokenSuccess?.data?.profile) {
      window.open(roleState?.getCandidateJobRoleProfileTokenSuccess?.data?.profile);
      setOpenMatchModal(false);
    }
  }, [roleState?.getCandidateJobRoleProfileTokenSuccess?.data]);

  // API call getCandidateJobRoleProfileToken
  const handleOpenJobProfile = (row) => {
    if (!featureAccess.advanceReporting) {
      setOpenToast(true);
      setToastMessage(featureNotAccesMessage);
      return;
    }
    let payload = {
      candidateId: id,
      jobRoleId: row.roleId,
    };
    setOpenMatchModal(true);
    // console.log(row.roleId, id, "row.roleId,id");
    dispatch(role.getCandidateJobRoleProfileToken(payload));
  };

  useEffect(() => {
    if (
      roleState?.getCandidateJobRoleProfileTokenFailure?.error === true ||
      roleState?.getCandidateJobRoleProfileTokenFailure.message
    ) {
      // setLoadingCursor(false);
      setOpenMatchModal(false);
    }
  }, [roleState?.getCandidateJobRoleProfileTokenFailure]);

  useEffect(() => {
    setAddedBy(addedByFilter);
  }, [addedByFilter]);

  useEffect(() => {
    const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
    setCategory(roleCategories);
    setDegree(authState?.candidatefilter?.degree);
  }, [roleState?.roleCategories, authState?.candidatefilter?.degree]);

  const handleChangePage = (_, newPage) => {
    setPaginationPageNumber(newPage);
    setTablePaginationPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePaginationPageNumber(0);
    setPaginationPageNumber(1);
  };

  // set default tab
  useEffect(() => {
    setShowExperienceAndEducation(true);
    setShowMatchAndSearchCandidates(false);
    setShowInvitedCandidates(false);
  }, []);

  const handleClose = () => {
    setOpenConfirm(false);
  };

  const handleConfirm = () => {
    let payload = {
      candidates: [id],
      jobRole: { uid: selectedRow?.roleId },
      selectedTestingRequirement: selectedRow?.testingRequirement,
      useExistingInfoForProfileAndPresentation: true,
      shareResultsWithCandidates: false,
      testExpiresOn: moment().add(7, "days").hours(11).minutes(59).seconds(59).milliseconds(0).toISOString(),
      noteForCandidate: "",
      note: "",
    };

    dispatch(role.addInvite(payload));
    setSentInvites([...sentInvites, selectedRow?.roleId]);
    setOpenConfirm(false);
    setOpen(true);
    setsnacktext("Invite sent successfully");
  };

  const handleChangeCategory = (value) => {
    setCategory(value);
    if (transformOptions(roleState?.roleCategories).length === value.length) {
      setCategorylength(0);
    } else {
      setCategorylength(value.length);
    }

    setCategoryBColor("#EBEFF8");
    if (value) {
      setShowReset(true);
    }
    setTablePaginationPageNumber(0);
  };

  const onSelectAllCategory = () => {
    const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
    setCategory(roleCategories);
    setCategorylength(0);
    setCategoryBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const onClearAllCategory = () => {
    setCategory([]);
    setCategorylength(0);
    setCategoryBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };

  const handleChangeDegree = (value) => {
    setDegree(value);
    setShowReset(true);

    if (value.length === degree?.length) {
      setDegreelength(0);
    } else {
      setDegreelength(value.length);
    }

    setDegreeBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const onSelectAllDegree = () => {
    setDegree(authState?.candidatefilter?.degree);
    setDegreelength(0);
    setDegreeBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  }; //End

  const onClearAllDegree = () => {
    setDegree([]);
    setDegreelength(0);
    setDegreeBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
    // setShowReset(true);
  }; //End

  // pageNotFound
  useEffect(() => {
    if (authState?.candidatedetailsFailure) {
      navigate("/pageNotFound", { state: { data: authState.candidatedetailsFailure } });
    }
  }, [authState?.candidatedetailsFailure]);

  const handleDailog = () => {
    setOpenDialogText(false);
  };

  function setOpenFunc() {
    setOpenToast(false);
  }

  return (
    <div>
      <NavBar />
      {showLinearLoading === true && (
        <div className={classes.loader}>
          <ColoredLinearProgress />
        </div>
      )}
      <Container maxWidth="xl" className={classes.container}>
        <Grid style={{ marginTop: "130px" }}>
          <CustomBreadcrumbs
            links={[
              { name: "Home", path: "/home" },
              { name: "Candidates", path: "/candidatelist" },
              {
                name: `${authState?.candidatedetails?.user?.firstName || ""} ${
                  authState?.candidatedetails?.user?.lastName || ""
                }`,
              },
            ]}
          />
          <Grid container className="Home-hero" alignItems="center">
            <Grid item xs={1}></Grid>
            <Grid item xs={10} style={{ marginTop: "25px" }}>
              <Grid className={classes.spaceflex}>
                <Grid style={{ display: "flex" }}>
                  <img
                    className={classes.profileImg}
                    alt="profile"
                    src={
                      !authState?.candidatedetails?.photoURL && profilePic === null
                        ? UploadProfile
                        : authState?.candidateDPUrl && profilePic !== null
                        ? profilePic
                        : UploadProfile
                    }
                  />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <Typography style={{ minWidth: "185px" }} className={classes.roletext}>
                        Candidate Profile
                        <br />
                        <>
                          <input
                            style={{ display: "none" }}
                            id="upload-profiledp-file"
                            type="file"
                            onChange={uploadProfileImage}
                          />
                          <label htmlFor="upload-profiledp-file">
                            <BaseButton component="span" className={classes.profileDPInputBtn}>
                              {profilePic !== null ? "Change Profile Picture" : "Upload Profile Picture"}
                            </BaseButton>
                          </label>
                        </>
                      </Typography>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-End", marginTop: "18px", marginLeft: "20px" }}>
                      <Grid item>
                        <Typography className={classes.linksty}>
                          <span onClick={(candidateProfileToken || candidateWeSummaryProfileToken) && copyDownloadLink}>
                            <Tooltip title="Copy Candidate Profile Link">
                              <ContentCopyOutlinedIcon
                                style={{
                                  verticalAlign: "bottom",
                                  color:
                                    featureAccess?.aiSmartProfiles &&
                                    (candidateProfileToken || candidateWeSummaryProfileToken)
                                      ? "#1789FC"
                                      : "gray",
                                  cursor:
                                    featureAccess?.aiSmartProfiles &&
                                    (candidateProfileToken || candidateWeSummaryProfileToken)
                                      ? "pointer"
                                      : "",
                                }}
                              />
                            </Tooltip>
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item sx={{ marginLeft: "12px" }}>
                        <Typography className={classes.linksty}>
                          <span onClick={(candidateProfileToken || candidateWeSummaryProfileToken) && openDownloadLink}>
                            <Tooltip title="Open Candidate Profile Link">
                              <OpenInNewOutlinedIcon
                                style={{
                                  verticalAlign: "bottom",
                                  color:
                                    featureAccess?.aiSmartProfiles &&
                                    (candidateProfileToken || candidateWeSummaryProfileToken)
                                      ? "#1789FC"
                                      : "gray",
                                  cursor:
                                    featureAccess?.aiSmartProfiles &&
                                    (candidateProfileToken || candidateWeSummaryProfileToken)
                                      ? "pointer"
                                      : "",
                                }}
                              />
                            </Tooltip>
                          </span>
                        </Typography>
                      </Grid>
                    </div>
                  </div>
                </Grid>
                <div>
                  <Grid>
                    <Grid style={{ display: "flex", gap: "14px", marginTop: "10px" }}>
                      <Button
                        data-rt-candidate-view="deactivate"
                        color="btnCls"
                        text={
                          authState?.candidatedetails?.status === "active"
                            ? "Deactivate Candidate"
                            : "Activate Candidate"
                        }
                        type="submit"
                        onClick={
                          authState?.candidatedetails?.status === "active" ? OpenDeleteCandidate : handleReactive
                        }
                        width={199}
                        variant="outlined"
                      ></Button>
                      <Button
                        data-rt-candidate-view="editcandidate"
                        color="btn"
                        // width="100%"
                        text="Edit Candidate"
                        width={199}
                        onClick={candidateEdit}
                      ></Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <p style={{ borderBottom: "1px solid #C4C4C4" }} />

              {isAuthLoading ? null : (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Status</Typography>
                          <Typography
                            className={classes.roletype}
                            style={{
                              color: authState?.candidatedetails?.status === "active" ? "#2F2F2F" : "#DD2E2E",
                            }}
                          >
                            {authState?.candidatedetails?.status === "active" ? "Active" : "Inactive"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Candidate ID</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {authState?.candidatedetails?.uid}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>First Name</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {authState?.candidatedetails?.user?.firstName}{" "}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Last Name</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {authState?.candidatedetails?.user?.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Location</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {setOptValue(authState?.candidatedetails?.location)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Job Title</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {setOptValue(authState?.candidatedetails?.jobTitle)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Degree</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {setOptValue(authState?.candidatedetails?.degree)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Experience</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {setOptValue(authState?.candidatedetails?.yoe)}
                            {authState?.candidatedetails?.yoe ? " years" : ""}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Phone</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {authState?.candidatedetails?.user?.phone}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Email</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {authState?.candidatedetails?.user?.email}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>LinkedIn</Typography>
                          <Typography
                            className={classes.roletype}
                            style={{
                              color: "#2F2F2F",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {setOptValue(authState?.candidatedetails?.linkedInProfile)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={0.6}></Grid>

                      <Grid item xs={3}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Resume</Typography>
                          <Typography
                            className={classes.roletype}
                            style={{
                              color: "#2F2F2F",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {authState?.candidatedetails?.resumeName ? (
                              <div onClick={Downloadresume} style={{ cursor: "pointer" }}>
                                <div>{authState?.candidatedetails?.resumeName}</div>
                                <span className={classes.roletype}>
                                  ({getFileSize(authState?.candidatedetails?.resumeSize)})
                                </span>
                                <span>
                                  <DownloadIcon
                                    style={{
                                      verticalAlign: "bottom",
                                      color: "#1789FC",
                                    }}
                                  />{" "}
                                </span>
                              </div>
                            ) : (
                              <div>{setOptValue(authState?.candidatedetails?.resumeName)}</div>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={0.6}></Grid>

                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Added By</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {authState?.candidatedetails?.createdBy?.displayName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={2.4}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Added On</Typography>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            {moment(authState?.candidatedetails?.createdAt).format("MMM DD, YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {isAuthLoading ? null : (
                <>
                  <Grid container spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Grid item xs={6}>
                      <Typography sx={{ mt: 4 }} className={classes.roletype1}>
                        Technical Skills
                        {authState?.candidatedetails?.skills?.length ? (
                          <span>
                            <img
                              alt="hintImg"
                              src={`${ImagebaseUrl}assets/questionsInformationIcon.svg`}
                              className={classes.hintIconCls}
                              onClick={handlehintClick}
                            />
                          </span>
                        ) : null}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ mt: 3, mr: 1, textAlign: "end" }} className={classes.roletype1}>
                        {isGridVisible ? (
                          <Grid container sx={{ display: "flex", justifyContent: "flex-end", textAlign: "end" }}>
                            <Typography sx={{ mr: 1 }}>View Chart</Typography>
                            <ViewQuiltIcon
                              sx={{
                                fontSize: "26px",
                                width: "auto",
                                mr: 1,
                                // marginLeft: "10px",
                                marginRight: "0px",
                                color: "#1789fc",
                              }}
                              onClick={handleIconClick}
                            />
                          </Grid>
                        ) : (
                          <Grid container sx={{ display: "flex", justifyContent: "flex-end", textAlign: "end" }}>
                            <Typography sx={{ mr: 1 }}>View Data</Typography>
                            <ViewListIcon
                              sx={{
                                fontSize: "26px",
                                width: "auto",
                                mr: 1,
                                // marginLeft: "10px",
                                marginRight: "0px",
                                color: "#1789fc",
                              }}
                              onClick={handleIconClick}
                            />
                          </Grid>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {!isGridVisible ? (
                    <Box
                      style={{
                        border: "solid 1px rgba(224, 224, 224, 1)",
                        borderRadius: "5px",
                        height: "515px",
                        width: "97%",
                        padding: "20px",
                        margin: "20px",
                        marginLeft: "0px",
                        // display: isGridVisible ? "none" : "block",
                      }}
                    >
                      {featureAccess?.skillTreeMap ? (
                        <TreemapChart treeMapData={treeMapData} />
                      ) : (
                        <Typography
                          className={classes.roletype}
                          style={{
                            color: "red",
                            paddingTop: "0px",
                            textAlign: "center",
                            marginTop: "250px",
                          }}
                        >
                          {featureNotAccesMessage}
                        </Typography>
                      )}
                    </Box>
                  ) : (
                    <Grid
                      sx={{ mt: 3 }}
                      className={classes.textbreak}
                      // style={{ display: isGridVisible ? "block" : "none" }}
                    >
                      <GridTable
                        columns={[
                          {
                            field: "skills",
                            headerName: "Skills",
                            flex: 2,
                            minWidth: 743,
                            sortable: true,
                            disableColumnMenu: true,
                          },
                          {
                            field: "monthsUsed",
                            headerName: "Months Used",
                            // headerClassName: classesForGridTable.monthsHeader,
                            headerAlign: "left",
                            align: "left",
                            flex: 1,
                            minWidth: 251,
                            sortable: true,
                            disableColumnMenu: true,
                          },
                          {
                            field: "lastUsed",
                            headerName: "Last Used",
                            headerAlign: "left",
                            align: "left",
                            flex: 1,
                            sortable: true,
                            disableColumnMenu: true,
                          },
                        ]}
                        height={520}
                        rows={sortedRows || []}
                        getRowId={(row) => row.id}
                        checkbox={false}
                        sortModel={sortModelSkill}
                        onSortChange={handleSortChangeSkill}
                      />
                    </Grid>
                  )}
                </>
              )}
              {isAuthLoading ? null : (
                <>
                  {/* <p
                    style={{
                      borderBottom: "1px solid #C4C4C4",
                      paddingTop: "10px",
                    }}
                  /> */}
                  {/* <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Experience Summary</Typography>
                          <Typography sx={{ textAlign: "justify", color: "#2F2F2F" }} className={classes.roletype}>
                            {weSummary === "processing" ? (
                              <span>
                                Processing<span className={classes.dotFlashing}></span>
                              </span>
                            ) : weSummary === "NA" ? (
                              "Not Available"
                            ) : (
                              weSummary
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  <p
                    style={{
                      borderBottom: "1px solid #C4C4C4",
                      paddingTop: "10px",
                    }}
                  />
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                          <Typography className={classes.roletype}>Internal Notes</Typography>
                          {(authState?.candidatedetails?.notesLog || []).map((note, i) => {
                            return (
                              <Grid>
                                {note.msg && (
                                  <Typography
                                    className={classes.roletype}
                                    style={{
                                      color: "#2F2F2F",
                                      paddingTop: "0px",
                                    }}
                                  >
                                    {note.msg}
                                  </Typography>
                                )}
                                <Typography
                                  className={classes.roletype}
                                  style={{
                                    color: "#2F2F2F",
                                    paddingTop: "0px",
                                  }}
                                >
                                  {note.what} by {note.who.displayName} on {TimeZonePT(note.when)}
                                </Typography>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                  </Grid>

                  <p
                    style={{
                      borderBottom: "1px solid #C4C4C4",
                      paddingTop: "10px",
                    }}
                  />
                  <Grid sx={{ display: "flex", justifyContent: "space-around" }}>
                    <MuiButton
                      className={showExperienceAndEducation ? classes.muiBtnActive : classes.muiBtn}
                      onClick={handleCandidateComparison}
                    >
                      Work Experience and Education
                    </MuiButton>
                    <MuiButton
                      className={showInvitedCandidates ? classes.muiBtnActive : classes.muiBtn}
                      onClick={handleInvitedCandidates}
                    >
                      All Invited Roles
                    </MuiButton>
                    <MuiButton
                      className={showMatchAndSearchCandidates ? classes.muiBtnActive : classes.muiBtn}
                      onClick={handleMatchAndSearchCandidates}
                    >
                      Matching Roles
                    </MuiButton>
                  </Grid>
                  {/* )} */}
                  {showMatchAndSearchCandidates && (
                    <>
                      <Grid sx={{ mt: 4 }}>
                        <Grid
                          style={{
                            display: "flex",
                            gap: "14px",
                            paddingBottom: "20px",
                            paddingTop: "15px",
                          }}
                        >
                          <SearchBar
                            placeholdertitle="Search Role"
                            onChange={(value) => handleSearch(value)}
                            value={searchText}
                            width="310px"
                          />
                          <CheckSelectMenu
                            Title={"Created By"}
                            Data={addedByFilter}
                            HandleChangeValue={(value) => handleChangeAddedBy(value)}
                            MenuProps={addedByMenuProps}
                            width={addedBylength === 0 ? 122 : 144}
                            text={"createdBy"}
                            checkLength={addedBylength}
                            BgColor={addedBylength !== 0 && addedBColor}
                            selectValue={addedBy}
                            OnSelectAll={onSelectAllAddedBy}
                            OnClearAll={onClearAllAddedBy}
                          />

                          <CheckSelectMenu
                            Title={"Degree"}
                            Data={authState?.candidatefilter?.degree}
                            HandleChangeValue={(value) => handleChangeDegree(value)}
                            MenuProps={degreeMenuProps}
                            width={degreelength === 0 ? 111 : 136}
                            checkLength={degreelength}
                            BgColor={degreelength !== 0 && degreeBColor}
                            selectValue={degree}
                            OnSelectAll={onSelectAllDegree}
                            OnClearAll={onClearAllDegree}
                          />
                          <SelectWithCheckbox
                            title={"Role Category"}
                            options={roleState?.roleCategories}
                            handleSelectOption={(value) => handleChangeCategory(value)}
                            MenuProps={categoryMenuProps}
                            width={categorylength === 0 ? 148 : 165}
                            checkLength={categorylength}
                            BgColor={categorylength !== 0 && categoryBColor}
                            defaultValue={category}
                            handleSelectAll={onSelectAllCategory}
                            handleClearAll={onClearAllCategory}
                          />

                          {showReset === true && (
                            <div style={{ minWidth: "140px" }}>
                              <Button
                                sx={{
                                  float: "right",
                                  textTransform: "none",
                                  color: "#2F2F2F",
                                }}
                                variant="text"
                                endIcon={<CloseIcon />}
                                text="Reset Filters"
                                onClick={onResetAllFilters}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid sx={{ mt: 1 }}>
                          <GridTable
                            columns={headerDataRoleMatch}
                            rows={candidateMatchingListForJobroleData}
                            checkbox={false}
                            getRowId={(candidateMatchingListForJobroleData) =>
                              candidateMatchingListForJobroleData.roleId
                            }
                            onResetFilters={onResetAllFilters}
                            height="calc(100vh - 450px)"
                            handleRowClick={handleRowClickMatch}
                            onSortChange={handleSortChangeMatch}
                            sortModel={matchUiSortModel}
                            showPagination={true}
                            tablePaginationPageNumber={tablePaginationPageNumber}
                            setTablePaginationPageNumber={setTablePaginationPageNumber}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            handleChangePage={handleChangePage}
                          />
                          <div style={{ display: "flex", marginTop: "-5px", marginLeft: "-10px" }}>
                            <CustomPagination
                              TotalCount={candidateMatchingListForJobroleData.length}
                              rows={candidateMatchingListForJobroleData || []}
                              tablePaginationPageNumber={tablePaginationPageNumber}
                              setTablePaginationPageNumber={setTablePaginationPageNumber}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                              rowsPerPage={rowsPerPage}
                              setRowsPerPage={setRowsPerPage}
                              handleChangePage={handleChangePage}
                              nextIconButtonProps={{ style: { display: "none" } }}
                              backIconButtonProps={{ style: { display: "none" } }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showInvitedCandidates && (
                    <>
                      {" "}
                      <Grid className={classes.spaceflexTable} sx={{ marginTop: "35px", marginBottom: "25px" }}>
                        <Typography className={classes.roletype1} style={{ color: "#2F2F2F" }}>
                          {/* All Invited Roles */}
                        </Typography>
                        <Grid
                          onClick={InviteCandidateView}
                          style={{
                            cursor: authState?.candidatedetails?.status === "active" ? "pointer" : "default",
                          }}
                        >
                          <Button color="btnCls3" variant="outlined" text="Invite Candidate to a New Test" />
                        </Grid>
                      </Grid>
                      {roleState?.candidateinvitelist && roleState?.candidateinvitelist?.length === 0 ? (
                        <Grid
                          container
                          className="Home-hero"
                          alignItems="center"
                          style={{
                            marginTop: "20px",
                            background: "#FFFBF6",
                            padding: "30px",
                            height: "calc(100vh - 400px)",
                          }}
                        >
                          <Grid item xs={4}></Grid>
                          <Grid item xs={4} style={{ textAlign: "center" }}>
                            <Typography
                              className={classes.roletype}
                              style={{ color: "#2F2F2F", paddingBottom: "15px" }}
                            >
                              Invite your candidate to take a test for a role today. No role invited yet.
                            </Typography>
                            <Button
                              data-rt-candidate-view="invitecandidate"
                              color={authState?.candidatedetails?.status === "active" ? "btnCls" : "disablebtn"}
                              // color="btnCls"
                              disabled={authState?.candidatedetails?.status !== "active"}
                              text="Invite Candidate"
                              // type="submit"
                              onClick={InviteCandidateView}
                              variant="outlined"
                              width={199}
                            ></Button>
                          </Grid>
                          <Grid item xs={4}></Grid>
                        </Grid>
                      ) : (
                        <Grid container className="Home-hero" alignItems="center" style={{ marginTop: "20px" }}>
                          <Grid item xs={12}>
                            <GridTable
                              columns={headerdata}
                              rows={candidateInviteList || []}
                              getRowId={(row) => row._id || 0}
                              checkbox={false}
                              handleRowClick={loadClickedInviteDetail}
                              onSortChange={handleSortChange}
                              sortModel={uiSortModel}
                              height="calc(100vh - 400px)"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                  {showExperienceAndEducation && (candidateProfileToken || candidateWeSummaryProfileToken) && (
                    <>
                      {featureAccess?.aiSmartProfiles ? (
                        <>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Grid style={{ marginTop: "10px" }} className={classes.textbreak}>
                                  <Typography
                                    sx={{ textAlign: "justify", color: "#2F2F2F" }}
                                    className={classes.roletype}
                                  >
                                    {weSummary === "processing" ? (
                                      <span>
                                        Processing<span className={classes.dotFlashing}></span>
                                      </span>
                                    ) : weSummary === "NA" ? (
                                      "Not Available"
                                    ) : (
                                      weSummary
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            {/* <Grid item xs={1}></Grid> */}
                            <Grid item xs={12}>
                              {/* <p style={{ borderBottom: "1px solid #C4C4C4", width: "100%" }} /> */}
                              {/* Work Experience */}
                              <ExperienceCards
                                weDetails={candidatedetails?.weDetails}
                                skillsMaster={authState?.skillsConfig}
                              />
                            </Grid>
                            <Grid item xs={1}></Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            {/* <Grid item xs={1}></Grid> */}
                            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
                              <p style={{ borderBottom: "1px solid #C4C4C4", width: "100%", padding: "0px" }} />
                              {/* Work Experience */}
                              <EducationCards education={candidatedetails?.education} />
                              <p
                                style={{
                                  borderBottom: "1px solid #C4C4C4",
                                  width: "100%",
                                  marginTop: "40px",
                                  // marginBottom: "0px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}></Grid>
                          </Grid>
                        </>
                      ) : (
                        <Typography
                          className={classes.roletype}
                          style={{
                            color: "red",
                            paddingTop: "0px",
                            textAlign: "center",
                            marginTop: "300px",
                            marginBottom: "300px",
                          }}
                        >
                          {featureNotAccesMessage}
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </Grid>
      </Container>
      {isAuthLoading ? null : <Footer></Footer>}
      <DeactivateModel
        open={opendialog}
        setOpen={setOpendialog}
        DeleteListData={(values) => DeleteCandidate(values)}
        // onClose={() => setDeactiveopen(false)}
        Title={"Deactivate Candidate"}
        Text={
          " An inactive candidate will not show up on the invite list and cannot be invited to take new tests. To continue, please provide a reason below."
        }
        Deactivebtn={deactivebtn}
      />
      <ReactiveModel
        open={reactiveopen}
        OnReactive={ReactiveCandidate}
        onClose={() => setReactiveopen(false)}
        Text={"Setting a candidate as active allows recruiters to send invites. Are you sure you want to activate?"}
        Activatebtn={activebtn}
      />
      <SimpleSnackbar open={open} setOpen={setOpen} text={snacktext} />
      <ErrorSnackbar
        open={openToast}
        setOpen={() => setOpenFunc()}
        vertical={"bottom"}
        horizontal={"left"}
        severity="error"
        message={toastMessage}
      />
      <ReusableDialog
        open={openConfirm}
        onClose={handleClose}
        title={authState?.checkCandidateInviteForRoleSuccess?.length > 0 ? "Warning" : "Confirm"}
        content={
          <>
            {authState?.checkCandidateInviteForRoleSuccess?.length > 0 && (
              <Typography sx={{ mb: 2 }}>
                There is an existing invite{" "}
                <span className={classes.boldText}>
                  {" "}
                  <Link
                    href={`/invitecandidateview/${authState?.checkCandidateInviteForRoleSuccess[0]?.inviteId}`}
                    target="_blank"
                    rel="noreferrer"
                    underline="none"
                  >
                    {authState?.checkCandidateInviteForRoleSuccess[0]?.inviteId}
                  </Link>{" "}
                </span>{" "}
                created on{" "}
                <span className={classes.boldText}>
                  {moment(authState?.checkCandidateInviteForRoleSuccess[0]?.createdAt).format("DD MMM, YYYY")}
                </span>{" "}
                for this candidate and role.
              </Typography>
            )}
            <Typography>
              {authState?.checkCandidateInviteForRoleSuccess?.length > 0
                ? "Do you still want to send the assessment invite to"
                : "Are you sure you want to send assessment invite to"}{" "}
              <span className={classes.boldText}>
                {authState?.candidatedetails?.user?.firstName} {authState?.candidatedetails?.user?.lastName} (
                {authState?.candidatedetails?.user?.uid})
              </span>{" "}
              for{" "}
              <span className={classes.boldText}>
                {selectedRow?.roleName} ({selectedRow?.roleId})
              </span>
              ?
            </Typography>
          </>
        }
        actions={[
          { onClick: handleClose, color: "btnCls1", variant: "outlined", text: "Cancel" },
          { onClick: handleConfirm, color: "btn1", autoFocus: true, text: "Confirm" },
        ]}
      />
      <ReusableDialog
        open={openAlertDialog}
        onClose={handleAlertDailog}
        title="Inactive Candidate"
        content={<Typography>{alertText}</Typography>}
        actions={[{ onClick: handleAlertDailog, color: "btn1", autoFocus: true, text: "Ok" }]}
      />
      <Modal open={openMatchModal} onClose={handleCloseMatchModal}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AutoAwesomeIcon sx={{ color: "#1976d2 !important", mr: 2 }} className={classes.flashingIcon} />
            <Typography variant="body1">Generating Profile Match Report</Typography>
            <span className={classes.dotFlashing}></span>
          </Box>
        </Box>
      </Modal>
      <ReusableDialog
        open={openDialogText}
        onClose={handleDailog}
        // title="Inactive Candidate"
        content={<Typography>{DialogText}</Typography>}
        actions={[{ onClick: handleDailog, color: "btn1", autoFocus: true, text: "Ok" }]}
      />
      {showExplanationModal && (
        <ExplainationModal
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          treeMapData={treeMapData}
          candidateDetails={authState?.candidatedetails?.user}
        />
      )}
    </div>
  );
};

export default CandidateView;
