import { Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../styles/theme";
import { makeStyles } from "@mui/styles";
import TextInput from "../../components/controls/TextField";
import Button from "../../components/controls/Button";
import { Formik, Form, Field } from "formik";
import { resendEmailSchema } from "../../schemas/resendEmail";
import { useDispatch } from "react-redux";
import auth from "../../redux/actions/auth";
import { useState } from "react";

const useStyles = makeStyles({
  centerContainer: {
    marginTop: "40px",
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    justifyContent: "center",
  },
  field: {
    minHeight: 48,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "10px",
  },
  cardFeature: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
  },
  logocls: {
    padding: "15px",
    borderBottom: "1px solid #ddd",
    background: `${theme.palette.mainColor}`,
  },
  textsty: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: `${theme.palette.black.dark}`,
    // textAlign: 'center',
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  textview: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  textviewLink: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    // paddingTop: '20px',
    paddingBottom: "20px",
  },
  submit: {
    marginTop: "16px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "20px",
  },
  errorty: {
    color: "#DD2E2E",
  },
});

const ResendEmailValidation = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [emailResent, setEmailResent] = useState(false);
  const matches = useMediaQuery("(max-width:850px)");

  const initialValues = {
    email: props.emailValues || "", // Set the initial value for the email field
  };

  const handleSubmit = (values, { setSubmitting }) => {
    let payload = {
      email: values.email,
    };
    dispatch(auth.resendEmailValidation(payload));
    // setSubmitting(false);
    setEmailResent(true);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={resendEmailSchema} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <div style={matches ? { marginTop: 0 } : {}} className={classes.centerContainer}>
            <Typography align="center" sx={matches ? { pt: 10 } : {}} className={classes.textsty}>
              Verify your email
            </Typography>
            <Typography style={{ textAlign: "center", marginTop: "20px" }}>
              We've sent you an email with a link to verify your email address.
            </Typography>
          </div>
          <Typography style={{ textAlign: "center" }}>
            You might need to check your spam folder if you don't see it in your inbox.
          </Typography>
          <div
            style={
              matches
                ? { margin: "1% 15%" }
                : {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1% 35%",
                  }
            }
          >
            <Field as={TextInput} disabled type="email" name="email" className={classes.field} id="email" />
          </div>
          <div
            style={
              matches
                ? {
                    display: "flex",
                    justifyContent: "center",
                  }
                : {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "1% 35%",
                  }
            }
          >
            {" "}
            {emailResent ? (
              "Verification email resent."
            ) : (
              <Button
                type="submit"
                text="Resend Email"
                color={isSubmitting ? "disablebtn1" : "btn1"}
                disabled={isSubmitting}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResendEmailValidation;
